<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-refresh"></i> Filtrer les commandes
    </div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Date de début</label>
          <datepicker
            v-model="filters.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Date de fin</label>
          <datepicker
            v-model="filters.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4" v-if="concepts">
          <label>Pack en cours</label>
          <select class="form-control" v-model="filters.completed">
            <option :value="false">Oui</option>
            <option :value="true">Non</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4" v-if="merchantsOptions">
          <label>Société</label>
          <v-select
            :options="merchantsOptions"
            v-model="filters.merchantId"
            :reduce="({ value }) => value"
            @click.native="checkIfEmpty"
            @input="handleMerchantChange"
          ></v-select>
        </div>
        <div class="form-group col-sm-4" v-if="concepts">
          <label>Concept</label>
          <v-select :options="concepts" v-model="filters.concept">
            <span slot="no-options">Sélectionnez une option pour Société.</span>
          </v-select>
        </div>
        <div class="form-group col-sm-4">
          <label>Statut paiement</label>
          <multiselect
            v-model="filters.paiementStatus"
            :options="orderStatus"
            :multiple="true"
            group-values="status"
            group-label="type"
            :group-select="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
            @input="statusInput"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label>Statut suivi</label>
          <multiselect
            v-model="filters.statusFollowed"
            :options="orderStatusFollowed"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label
            >Statut problématique
            <span
              class="fa fa-info-circle ml-05"
              v-tooltip="{
                content: problematicStatusTooltip,
                placement: 'right',
              }"
            ></span>
          </label>
          <v-select
            :options="orderStatusProblematic"
            v-model="filters.statusProblematic"
            :reduce="({ value }) => value"
            @input="problematicStatusInput"
          ></v-select>
        </div>
        <div class="form-group col-sm-4">
          <label>Client</label>
          <v-select
            v-model="filters.client"
            :options="clients"
            @search="searchClients"
          ></v-select>
        </div>
        <div class="form-group col-sm-3">
          <label>Commercial</label>
          <v-select
            :options="commercials"
            v-model="filters.commercial"
          ></v-select>
        </div>
        <div class="form-group col-sm-3">
          <label>Coach manager</label>
          <v-select
            :options="coachManagers"
            v-model="filters.coachManager"
          ></v-select>
        </div>
        <div class="form-group col-sm-3">
          <label>Département</label>
          <multiselect
            v-model="filters.zipcodes"
            :options="zipcodes"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-3">
          <label>Menu / Statistique</label>
          <v-select
            :options="menuStatsOptions"
            v-model="filters.menuStats"
            :reduce="({ value }) => value"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="card-header">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import debounce from "lodash.debounce";
import { mapGetters } from "vuex";

import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  components: {
    vSelect,
    Datepicker,
    Multiselect,
  },
  data() {
    return {
      concepts: [],
      clients: [],
      coachs: [],
      merchantsOptions: [],
      commercials: [],
      coachManagers: [],
      filters: {
        begin: moment().subtract(1, "months").toDate(),
        end: moment().toDate(),
        completed: false,
        merchantId: undefined,
        concept: undefined,
        paiementStatus: [
          { label: "Payée", value: "payed" },
          { label: "Partiel", value: "partial" },
        ],
        statusFollowed: undefined,
        statusProblematic: undefined,
        client: undefined,
        commercial: undefined,
        zipcodes: undefined,
        menuStats: undefined,
      },
    };
  },

  async created() {
    await this.fetchCommercials();
    await this.fetchCoachManagers();
    await this.fetchmerchants();

    if (!this.filters.commercial) {
      const currentCommercial = this.commercials.find((c) => {
        if (this.$store.state.user.email === c.value) {
          return c;
        }
      });

      if (currentCommercial) {
        this.filters.commercial = currentCommercial;
      }
    }

    if (!this.filters.coachManager) {
      const currentCoachManager = this.coachManagers.find((c) => {
        if (this.$store.state.user.email === c.value) {
          return c;
        }
      });

      if (currentCoachManager) {
        this.filters.coachManager = currentCoachManager;
      }
    }

    this.applyFilters();
  },

  methods: {
    applyFilters() {
      this.$emit("change", {
        ...this.filters,
        begin: moment(this.filters.begin).toDate(),
        end: moment(this.filters.end).toDate(),
        menuStats: this.filters.menuStats,
        zipcodes: this.filters.zipcodes?.map((item) => item.value),
        client: this.filters.client?.value,
        commercial: this.filters.commercial?.value,
        coachManager: this.filters.coachManager?.value,
        paiementStatus: this.filters.paiementStatus?.map((item) => item.value),
        statusFollowed: this.filters.statusFollowed?.map((item) => item.value),
        statusProblematic: this.filters.statusProblematic,
      });
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter`, {
        params: {
          q: search,
        },
      });

      vm.clients = data.map((c) => ({
        label: `${c.name} (${c.usedEmail})`,
        firstName: c.firstName,
        lastName: c.lastName,
        company: c.company,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));

      this.commercials.push({
        label: "Aucun",
        value: "none",
      });
    },

    async fetchCoachManagers() {
      const { data } = await this.$api.get("/users/coach-managers");

      this.coachManagers = data.map((c) => ({
        value: c.email,
        label: c.coachManager.pseudo,
      }));

      this.coachManagers.push({
        label: "Aucun",
        value: "none",
      });
    },

    async fetchmerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantsOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },

    checkIfEmpty() {
      if (!this.filters.merchantId) {
        this.concepts = [];
        this.filters.concept = null;
      }
    },

    async handleMerchantChange(merchant) {
      const DISABLED_FROM_CREATION = ["Just Coaching Live"];

      if (merchant) {
        const params = { merchantId: merchant.value };
        const { data } = await this.$api.get("/concepts", { params });

        this.concepts = [];
        this.concepts = data
          .filter((concept) => {
            if (!DISABLED_FROM_CREATION.includes(concept.name)) {
              return concept.name;
            }
          })
          .map(({ name }) => name);
      }
    },

    statusInput(status) {
      if (status.some(({ value }) => value === "payed" || value === "unpaid")) {
        this.filters.statusProblematic = null;
      }
    },

    problematicStatusInput(input) {
      if (input) {
        this.filters.paiementStatus = this.filters.paiementStatus.filter(
          ({ value }) => value !== "payed" && value !== "unpaid"
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      orderStatus: "getOrderStatusGroups",
      orderStatusFollowed: "getOrderStatusFollowedGroups",
      orderStatusProblematic: "getOrderStatusProblematic",
      menuStatsOptions: "getMenuStatsOptions",
      departments: "getDepartments",
    }),

    zipcodes() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    problematicStatusTooltip() {
      return 'Attention ! Ce filtre exclut d\'office les commandes avec le statut "Payé" ou "Impayé (traité)"';
    },
  },
};
</script>

<style lang="scss">
.custom-multiselect {
  &.is-problematic {
    .multiselect__tag,
    .multiselect__tag-icon {
      background: #d9534f;

      &:hover {
        background: #d9534f;
      }
    }
  }
}
</style>

<template>
  <modal
    :title="`${
      edit ? 'Mofifier le contenu dynamique' : 'Ajouter un contenu dynamique'
    }`"
    v-model="show"
    cancelText="Annuler"
    :okText="`${edit ? 'Mettre à jour' : 'Ajouter'}`"
    @ok="edit ? editContent() : addContent()"
    @cancel="closeModal"
    effect="fade/zoom"
    large
  >
    <Loader :is-visible="loading" />

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Type de contenu *</label>
          <v-select
            v-model="type"
            :options="typeOptions"
            :disabled="edit"
            :reduce="({ value }) => value"
            @input="changeType"
          />
          <small v-if="errors && errors.type" class="form-text text-danger">
            {{ errors.type }}</small
          >
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label>Nom *</label>
          <input type="text" class="form-control" v-model="name" />
          <small v-if="errors && errors.name" class="form-text text-danger">
            {{ errors.name }}</small
          >
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <el-switch
            v-model="isPaginatedContent"
            active-text="Pages"
            inactive-text="Contenu"
            :disabled="edit"
            @change="toggleIsPaginatedContent"
          />
        </div>
      </div>
      <div v-if="isPaginatedContent" class="col-sm-12">
        <div
          v-for="(page, index) in pages"
          :key="`dynamic-content-page-${index}`"
          class="form-group"
        >
          <label
            >Page {{ index + 1 }} {{ index === 0 ? "*" : "" }}
            <button
              v-if="index > 0"
              type="button"
              class="btn btn-sm btn-secondary ml-05"
              @click="removeContentPage(index)"
            >
              <i class="fa fa-minus"></i>
            </button>
          </label>
          <quill-editor
            v-model="page.rawContent"
            @change="onPageEditorChange(index, $event)"
          >
          </quill-editor>
          <small
            v-if="errors && errors.pages && errors.pages[index]"
            class="form-text text-danger"
          >
            {{ errors.pages[index] }}</small
          >
        </div>

        <div class="form-group text-center">
          <button
            type="button"
            class="btn btn-secondary w-100"
            @click="addContentPage"
          >
            <i class="fa fa-plus"></i>
          </button>

          <small v-if="errors && errors.noPage" class="form-text text-danger">
            {{ errors.noPage }}</small
          >
        </div>
      </div>
      <div v-else class="col-sm-12">
        <div class="form-group">
          <label>Contenu *</label>
          <quill-editor v-model="rawContent" @change="onEditorChange($event)">
          </quill-editor>
          <small v-if="errors && errors.content" class="form-text text-danger">
            {{ errors.content }}</small
          >
        </div>
      </div>
      <div class="col-sm-12">
        <label>Métadonnées</label>

        <p v-if="metadata.length === 0" class="text-center text-muted">
          Aucune métadonnée requise
        </p>
        <div
          v-else
          v-for="(item, index) in metadata"
          :key="`metadata-${index}`"
          class="row align-items-center"
        >
          <div class="col-sm-4">
            <div class="form-group">
              <label><small>Clé</small></label>
              <p class="form-control">{{ item.key }}</p>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group">
              <label><small>Valeur</small></label>
              <input type="text" class="form-control" v-model="item.value" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    Modal,
    Loader,
    quillEditor,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    types: {
      type: Array,
      default: () => [],
    },

    close: {
      type: Function,
      required: true,
    },

    dynamicContent: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      type: null,
      isPaginatedContent: false,
      name: "",
      rawContent: "",
      content: "",
      pages: [{ rawContent: "", content: "" }],
      metadata: [],
      errors: {},
    };
  },

  computed: {
    edit() {
      return !!this.dynamicContent?.id;
    },

    typeOptions() {
      return this.types.map(({ type }) => ({
        label: type,
        value: type,
      }));
    },
  },

  watch: {
    dynamicContent() {
      if (this.edit) {
        this.type = this.dynamicContent.type;
        this.name = this.dynamicContent.name;
        this.rawContent = this.dynamicContent.content || null;
        this.content = this.dynamicContent.content || null;
        this.pages = this.dynamicContent.pages
          ? this.dynamicContent.pages.map((page) => ({
              rawContent: page,
              content: page,
            }))
          : null;
        this.isPaginatedContent = this.dynamicContent.pages?.length > 0;

        if (this.dynamicContent.metadata) {
          for (const [key, value] of Object.entries(
            this.dynamicContent.metadata
          )) {
            this.metadata.push({ key, value });
          }
        } else {
          this.metadata = [];
        }

        const type = this.types.find(({ type }) => type === this.type);

        if (
          type.metadata?.filter(
            (key) => !this.metadata.some((item) => item.key === key)
          ).length > 0
        ) {
          type.metadata.forEach((key) => {
            if (!this.metadata.some((item) => item.key === key)) {
              this.metadata.push({ key, value: "" });
            }
          });
        }
      } else {
        this.resetData();
      }
    },
  },

  methods: {
    resetData() {
      this.type = null;
      this.name = "";
      this.isPaginatedContent = false;
      this.rawContent = "";
      this.content = "";
      this.pages = [{ rawContent: "", content: "" }];
      this.metadata = [];
      this.errors = {};
    },

    closeModal() {
      this.resetData();
      this.close();
    },

    onEditorChange({ html }) {
      this.content = html;
    },

    checkFields() {
      this.errors = {};

      if (!this.type) {
        this.errors.type = "Veuillez choisir un type de contenu";
      } else if (!this.types.some(({ type }) => type === this.type)) {
        this.errors.type = "Ce type de contenu n'existe pas";
      }

      if (!this.name) {
        this.errors.name = "Veuillez saisir un nom";
      }

      if (!this.isPaginatedContent && !this.content) {
        this.errors.content = "Veuillez saisir un contenu";
      }

      if (this.isPaginatedContent) {
        if (this.pages.length === 0) {
          this.errors.noPage = "Veuillez saisir au moins une page";
        }

        if (this.pages.some((p) => !p.content)) {
          this.errors.pages = this.pages.map((p, index) => {
            if (!p.content) {
              return index === 0
                ? "Veuillez saisir un contenu"
                : "Veuillez saisir un contenu ou supprimer la page";
            }

            return null;
          });
        }
      }
    },

    async addContent() {
      this.checkFields();

      if (Object.values(this.errors).filter((error) => error).length > 0) {
        return;
      }

      try {
        this.loading = true;

        await this.$api.post("/dynamic-contents", {
          type: this.type,
          name: this.name,
          content: !this.isPaginatedContent ? this.content : null,
          pages: this.isPaginatedContent
            ? this.pages?.map(({ content }) => content)
            : null,
          metadata: this.metadata,
        });

        this.closeModal();
        this.$successToast(
          "Contenu dynamique créé, rechargez pour voir les modifications"
        );
      } catch (e) {
        this.$errorToast("Impossible d'ajouter le contenu dynamique");
      } finally {
        this.loading = false;
      }
    },

    async editContent() {
      if (!this.dynamicContent?.id) {
        return;
      }

      this.checkFields();

      if (Object.values(this.errors).filter((error) => error).length > 0) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$api.put(
          `/dynamic-contents/${this.dynamicContent.id}`,
          {
            name: this.name,
            content: this.content,
            pages: this.pages?.map(({ content }) => content),
            metadata: this.metadata,
          }
        );

        this.$emit("updated", data);
        this.closeModal();
        this.$successToast("Contenu mis à jour");
      } catch (e) {
        this.$errorToast("Impossible de modifier le contenu dynamique");
      } finally {
        this.loading = false;
      }
    },

    changeType() {
      const type = this.types.find(({ type }) => type === this.type);

      this.metadata = type.metadata
        ? type.metadata.map((key) => ({ key, value: "" }))
        : [];
    },

    toggleIsPaginatedContent(isPaginatedContent) {
      this.isPaginatedContent = isPaginatedContent;

      if (isPaginatedContent) {
        this.pages = [
          { rawContent: this.rawContent || "", content: this.content || "" },
        ];
        this.rawContent = "";
        this.content = "";
      } else {
        this.rawContent = this.pages[0]?.rawContent || "";
        this.content = this.pages[0]?.content || "";
        this.pages = [{ rawContent: "", content: "" }];
      }
    },

    onPageEditorChange(index, { html }) {
      this.pages[index].content = html;
    },

    addContentPage() {
      this.pages.push({ rawContent: "", content: "" });
    },

    removeContentPage(index) {
      this.pages.splice(index, 1);

      if (this.errors?.pages?.length > 0) {
        this.errors.pages.splice(index, 1);
      }
    },
  },
};
</script>

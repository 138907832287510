<template>
  <modal
    :title="'Créer'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="'Créer'"
    @cancel="() => closeModal()"
    @ok="handleAddInvitationCodeCategory()"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">Créer une categorie</h4>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Nom</label>
          <div class="input-group">
            <input type="text" v-model="form.name" class="form-control" />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    form: {
      name: null,
    },
    modalOpened: false,
    formError: false,
    loading: false,
  }),

  mounted() {
    this.modalOpened = this.modalShow;
  },

  methods: {
    async handleAddInvitationCodeCategory() {
      if (this.loading) {
        return false;
      }

      this.formError = false;
      this.loading = true;

      try {
        this.validateForm();

        if (!this.formError) {
          await this.$api.post("/invitation-code-categories", this.form);

          this.closeModal();
        }
      } catch (e) {
        this.formError = `Une erreur s'est produite, veuillez réessayer`;
      }

      this.loading = false;
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;

      this.$emit("close");
    },

    validateForm() {
      if (!this.form.name) {
        this.formError = "Tous les champs sont obligatoires";
        return;
      }
    },
  },

  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
        this.modalOpened = newVal;
      }
    },
  },
};
</script>

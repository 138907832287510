<template>
  <div class="animated fadeIn">
    <el-popover trigger="click" placement="top" :width="700">
      <div
        class="row mt-1 align-items-center"
        v-if="getConverted.length !== 0 || getNotConverted.length !== 0"
      >
        <div class="col-md-12">
          <h4>{{ name }}</h4>
          <hr />
        </div>

        <div class="col-md-2 text-center">
          <strong v-if="row.result">{{ row.result }}%</strong>
          <Doughnut :options="options" :chart-data="chartData" />
        </div>
        <div class="col-md-3 d-flex flex-column">
          <div>
            <span class="pills blue">{{ getConverted.length }} Client(s)</span>
            <p>Convertis</p>
          </div>
          <div>
            <span class="pills green"
              >{{ getNotConverted.length }} Client(s)</span
            >
            <p>Perdu</p>
          </div>
        </div>
        <div class="col-md-7 text-center">
          <div v-if="row.result" class="row align-items-center">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12">
                  100 *
                  <span class="pills blue"
                    >{{ getConverted.length }} converti(s)</span
                  >
                </div>
                <hr class="divider" />
                <div class="col-md-12">
                  (<span class="pills blue"
                    >{{ getConverted.length }} converti(s)</span
                  >
                  +
                  <span class="pills green"
                    >{{ getNotConverted.length }} perdu</span
                  >)
                </div>
              </div>
            </div>
            <div class="col-md-4 text-left">
              <span>= {{ row.result }}%</span>
            </div>
          </div>
          <h6 v-else>Aucun</h6>
        </div>
        <hr class="divider" />
      </div>
      <div
        class="row mt-2 text-center"
        style="max-height: 200px; overflow: auto"
      >
        <div class="col-md-4">
          <h5>Converti(s) ({{ getConverted.length }})</h5>
          <ul class="list-unstyled mt-2">
            <li
              v-for="converted in getConverted"
              :key="converted.id"
              class="mt-1"
            >
              <a
                @click="(e) => goToClient(e, converted.id)"
                class="user-pills-link blue"
                href="#"
              >
                <i class="icon-user"></i> {{ converted.fullname }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <h5>Non converti(s) ({{ getNotConverted.length }})</h5>
          <ul class="list-unstyled mt-2">
            <li
              v-for="notConverted in getNotConverted"
              :key="notConverted.id"
              class="mt-1"
            >
              <a
                @click="(e) => goToClient(e, notConverted.id)"
                class="user-pills-link green"
                href="#"
              >
                <i class="icon-user"></i> {{ notConverted.fullname }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <h5>Exclu ({{ getExcluded.length }})</h5>
          <ul class="list-unstyled mt-2">
            <li v-for="excluded in getExcluded" :key="excluded.id" class="mt-1">
              <a
                @click="(e) => goToClient(e, excluded.id)"
                class="user-pills-link orange"
                href="#"
              >
                <i class="icon-user"></i> {{ excluded.fullname }} <br />
                <span v-if="excluded.code === 'no_conversion'">
                  (Le coach n'a pas fait la conversion)
                </span>
                <span v-else-if="excluded.code === 'no_discovery'">
                  (Le coach n'a pas fait la découverte)
                </span>
                <span v-else>{{ excluded.code }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div slot="reference" class="name-wrapper">
        <div v-if="row.result">
          <span> #{{ row.rank }} - </span>
          {{ row.result }}%
        </div>
        <span v-else>Aucun</span>
      </div>
    </el-popover>
  </div>
</template>

<script>
import Doughnut from "./Doughnut.vue";
export default {
  components: {
    Doughnut,
  },

  data() {
    return {
      options: {
        responsive: true,
        animation: {
          animateRotate: false,
        },
        legend: {
          display: false,
        },
        cutoutPercentage: 75,
      },
    };
  },

  props: {
    row: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  computed: {
    getConverted() {
      return this.row.details.filter((d) => d.code === "converted");
    },

    getNotConverted() {
      return this.row.details.filter((d) => d.code === "not_converted");
    },

    getExcluded() {
      return this.row.details.filter(
        (d) => d.code === "no_discovery" || d.code === "no_conversion"
      );
    },

    chartData() {
      return {
        labels: ["Convertis", "Perdu"],
        datasets: [
          {
            backgroundColor: ["#255dc8", "#18bdb4"],
            data: [this.getConverted.length, this.getNotConverted.length],
          },
        ],
      };
    },
  },

  methods: {
    goToClient(e, id) {
      e.preventDetault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.pills {
  border-radius: 100px;
  background-color: #f5f5f5;
  padding: 3px 20px;
  word-break: break-word;
  display: inline-block;
}

.blue {
  background-color: #255dc8;
  color: white;
}

.green {
  background-color: #18bdb4;
  color: white;
}

.orange {
  background-color: #f97516;
  color: white;
}

.divider {
  width: 100%;
  border-top: 1px solid #131721;
}

.user-pills-link {
  padding: 5px 15px;
  border-radius: 100px;
  text-decoration: none;
  display: block;
  word-break: break-word;
}

.user-pills-link:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
</style>

<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-camera"></i> Accès gratuit
      <button
        type="button"
        class="btn btn-sm btn-primary float-right"
        @click="liveFreeAccessModal.show = true"
        :disabled="!hasPermission($store.state.user, 'CLIENTS_WRITE')"
      >
        <i class="fa fa-plus"></i>&nbsp; Ajouter
      </button>
    </div>

    <div class="card-block">
      <div v-if="liveFreeAccess.length === 0" class="text-muted text-center">
        Aucun accès Live gratuit
      </div>

      <el-table
        v-else
        :data="liveFreeAccess"
        :default-sort="{ prop: 'validUntil', order: 'ascending' }"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          prop="createdAt"
          label="Crée le"
          min-width="95"
        >
          <template slot-scope="scope">
            {{ formatDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="validUntil"
          label="Validité"
          min-width="95"
        >
          <template slot-scope="scope">
            {{ formatDate(scope.row.validUntil) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="type" label="Type" min-width="95">
          <template slot-scope="scope">
            {{ scope.row.type === "live" ? "Live" : "Visio & Live" }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="hasUsedInvitationCode"
          align="center"
          prop="invitationCode.code"
          label="Code"
          min-width="130"
        >
          <template slot-scope="scope">
            {{
              (scope.row.invitationCode && scope.row.invitationCode.code) || "-"
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="revoked" label="Révoqué">
          <template slot-scope="scope">
            {{ scope.row.revoked ? "Oui" : "Non" }}
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot-scope="scope">
            <button
              v-if="!scope.row.revoked"
              class="btn btn-sm btn-danger mr-1"
              @click="revokeAccess(scope.row.id)"
              :disabled="!hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
            >
              <i class="fa fa-times" />
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <LiveFreeAccessModal
      :show="liveFreeAccessModal.show"
      :loading="liveFreeAccessModal.loading"
      :error="liveFreeAccessModal.error"
      :types="liveFreeAccessModal.types"
      :close="closeLiveFreeAccessModal"
      @create="createLiveFreeAccess"
    />
  </div>
</template>

<script>
import LiveFreeAccessModal from "../components/LiveFreeAccessModal";

export default {
  components: {
    LiveFreeAccessModal,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      liveFreeAccess: [],
      liveFreeAccessModal: {
        show: false,
        loading: false,
        error: null,
        types: [
          {
            label: "Live",
            value: "live",
          },
          {
            label: "Visio & Live",
            value: "small",
          },
        ],
      },
    };
  },

  mounted() {
    this.liveFreeAccess = [...this.client.liveFreeAccess];
  },

  computed: {
    hasUsedInvitationCode() {
      return this.liveFreeAccess.some((access) => access.invitationCode);
    },
  },

  methods: {
    revokeAccess(accessId) {
      this.$confirm({
        message: "Révoquer l'accès ?",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/live-free-access/${accessId}`);

            this.liveFreeAccess = this.liveFreeAccess.filter((a) => {
              if (a.id === accessId) {
                a.revoked = true;
              }

              return a;
            });
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    closeLiveFreeAccessModal() {
      this.liveFreeAccessModal.show = false;
    },

    async createLiveFreeAccess(form) {
      this.liveFreeAccessModal.error = null;

      form = { ...form, clientEmail: this.client.email };

      this.checkLiveFreeAccessFormFields(form);

      if (!this.liveFreeAccessModal.error) {
        try {
          this.liveFreeAccessModal.loading = true;

          const { data } = await this.$api.post("/live-free-access", form);

          this.liveFreeAccess.push(data);
          this.client.liveFreeAccess = this.liveFreeAccess;
          this.liveFreeAccessModal.show = false;
        } catch (e) {
          this.liveFreeAccessModal.error =
            "Une erreur est survenue lors de l'envoi du formulaire";
        } finally {
          this.liveFreeAccessModal.loading = false;
        }
      }
    },

    checkLiveFreeAccessFormFields(form) {
      if (form.useInvitationCode) {
        if (!form.invitationCode) {
          this.liveFreeAccessModal.error =
            "Veuillez renseigner un code d'invitation";
        }
      } else if (
        !form.type ||
        !this.liveFreeAccessModal.types.some(({ value }) => value === form.type)
      ) {
        this.liveFreeAccessModal.error =
          "Veuillez sélectionner une option pour Type";
      } else if (!form.duration) {
        this.liveFreeAccessModal.error =
          "Veuillez renseigner une date de fin de validité";
      }
    },
  },
};
</script>

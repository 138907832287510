<template>
  <div class="wrapper">
    <DocumentsFilters :default="filters" @applyFilters="applyFilters" />
    <div class="animated fadeIn display-tab">
      <tabs>
        <tab :header="coachsHeader">
          <documents :coachs="coachs" />
        </tab>
        <tab :header="sharedHeader">
          <div
            style="margin-bottom: 25px"
            v-if="hasPermission($store.state.user, 'DOCUMENTS_WRITE')"
          >
            <dropzone
              :url="apiUploads()"
              :headers="apiAuthorization()"
              id="shared"
              v-on:vdropzone-success="sharedUploaded"
            >
              <div class="dz-default dz-message">
                <span
                  ><i class="icon-cloud-upload text-danger"></i><br />Ajouter un
                  document <u class="text-danger">partagé</u></span
                >
              </div>
            </dropzone>
          </div>
          <documents :documents="shared" />
        </tab>
        <tab :header="privateHeader">
          <div
            style="margin-bottom: 25px"
            v-if="hasPermission($store.state.user, 'DOCUMENTS_WRITE')"
          >
            <dropzone
              :url="apiUploads()"
              :headers="apiAuthorization()"
              id="priv"
              v-on:vdropzone-success="privateUploaded"
            >
              <div class="dz-default dz-message">
                <span
                  ><i class="icon-cloud-upload text-primary"></i><br />Ajouter
                  un document <u class="text-primary">privé</u></span
                >
              </div>
            </dropzone>
          </div>
          <documents :documents="priv" />
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Documents from "../components/Documents";
import DocumentsFilters from "../components/DocumentsFilters.vue";

import Dropzone from "vue2-dropzone";

export default {
  components: {
    Documents,
    Tabs,
    Tab,
    Dropzone,
    DocumentsFilters,
  },
  data() {
    return {
      filters: {
        commercials: [],
        coachManagers: [],
      },
      coachs: [],
      shared: [],
      priv: [],
    };
  },
  computed: {
    coachsHeader() {
      return "Coachs &mdash; documents à valider (" + this.coachs.length + ")";
    },
    sharedHeader() {
      return "Partagés (" + this.shared.length + ")";
    },
    privateHeader() {
      return "Privés (" + this.priv.length + ")";
    },
  },

  async created() {
    await this.loadDocuments();

    this.$api.get("/users/documents").then((res) => {
      this.priv = res.data.filter((d) => !d.shared);
      this.shared = res.data.filter((d) => !!d.shared);

      /* load shared documents !user.id */
    });
  },
  methods: {
    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },
    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    async loadDocuments() {
      const { data } = await this.$api.get("/users/coachs/documents", {
        params: {
          commercials: this.filters.commercials.map((c) => c.value),
          coachManagers: this.filters.coachManagers.map((c) => c.value),
        },
      });

      this.coachs = [];
      data.forEach((user) => {
        if (user.documents) {
          user.documents.forEach((doc) => {
            if (!doc.validatedAt) {
              this.coachs.push({
                document: doc,
                coachId: user.id,
                coachEmail: user.email,
                coachName: user.coach.name,
                coachManagerEmail: user.refCoachManagerEmail,
                coachManager: user.refCoachManager,
              });
            }
          });
        }
      });
    },

    async applyFilters(form) {
      this.filters = {
        ...this.filters,
        ...form,
      };

      await this.loadDocuments();
    },

    privateUploaded(file, response) {
      this.$api.put("/users/document/" + response.id).then((res) => {
        this.priv = res.data.filter((d) => !d.shared);
      });
    },
    sharedUploaded(file, response) {
      this.$api
        .put("/users/document/" + response.id, {
          shared: true,
        })
        .then((res) => {
          this.shared = res.data.filter((d) => !!d.shared);
        });
    },
  },
};
</script>

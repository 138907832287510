<template>
  <div class="animated fadeIn">
    <Loader :is-visible="loading" />

    <ClientsGroupTrackingFilters
      :default-dates="defaultDates"
      :type="type"
      @apply="applyFilters"
      @onExport="onExport"
    />

    <div class="card">
      <div class="card-header">
        <i class="icon-graph"></i> Suivi {{ group.name }}
      </div>

      <div class="card-block">
        <ClientsGroupTrackingTable :data="stats" :type="type" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DownloadFile from "../mixin/DownloadFile";

import Loader from "../components/Loader";
import ClientsGroupTrackingFilters from "../components/ClientsGroupTrackingFilters";
import ClientsGroupTrackingTable from "../components/ClientsGroupTrackingTable";

export default {
  components: {
    Loader,
    ClientsGroupTrackingFilters,
    ClientsGroupTrackingTable,
  },

  mixins: [DownloadFile],

  props: {
    group: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: "completed",
    },
  },

  data() {
    return {
      loading: false,
      stats: [],
      defaultDates: {
        begin: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
      },
      filters: {
        begin: null,
        end: null,
        filterClients: null,
      },
    };
  },

  mounted() {
    this.filters = {
      ...this.filters,
      begin: this.defaultDates.begin,
      end: this.defaultDates.end,
      filterClients: this.type === "uncompleted" && "withUncompleted",
    };

    this.fetchGroupStats();
  },

  methods: {
    async fetchGroupStats(toExport) {
      try {
        this.loading = true;

        const { begin, end, filterClients } = this.filters;

        const { data } = await this.$api.get(
          `/groups/${this.group.id}/tracking`,
          { params: { type: this.type, begin, end, filterClients, toExport } }
        );

        if (toExport) {
          const fileName = `Groupe_${this.group.name}_${moment(begin).format(
            "DD-MM-YYYY"
          )}_${moment(end).format("DD-MM-YYYY")}`;

          this.downloadXLSX(
            data,
            `${fileName}${
              this.type === "uncompleted" ? "_seances-non-completees" : ""
            }`
          );
        } else {
          this.stats = data;
        }
      } catch (e) {
        this.stats = [];
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };

      this.fetchGroupStats();
    },

    onExport(filters) {
      this.filters = { ...this.filters, ...filters };

      this.fetchGroupStats(true);
    },
  },
};
</script>

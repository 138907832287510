<template>
  <div class="row">
    <div class="col-12">
      <ClientSapCard :client="client" />
    </div>
  </div>
</template>

<script>
import ClientSapCard from "../components/ClientSapCard";

export default {
  components: {
    ClientSapCard,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

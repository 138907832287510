<template>
  <button
    v-if="newNotes"
    class="btn btn-comment"
    @click.stop="showComment()"
    :disabled="isNotAllowed"
    :key="newNotes"
    v-tooltip="{ content: newNotes, show: !!newNotes }"
  >
    {{ displayNoteAdminButtonLabel() }}
  </button>
  <button
    v-else
    class="btn btn-comment"
    @click.stop="showComment()"
    :disabled="isNotAllowed"
  >
    {{ displayNoteAdminButtonLabel() }}
  </button>
</template>

<script>
export default {
  props: {
    notes: {
      type: String,
      default: "",
    },
    permission: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      newNotes: null,
    };
  },

  computed: {
    isNotAllowed() {
      return (
        this.permission !== null &&
        !this.hasPermission(this.$store.state.user, this.permission)
      );
    },
  },

  watch: {
    notes(val) {
      this.newNotes = val || null;
    },
  },

  mounted() {
    this.newNotes = this.notes;
  },

  methods: {
    displayNoteAdminButtonLabel() {
      if (!this.notes || this.notes.length == 0) {
        return "";
      }

      return this.notes.length > 25
        ? this.notes.substr(0, 25) + "..."
        : this.notes;
    },

    showComment() {
      this.$emit("showComment");
    },
  },
};
</script>
<style lang="css" scoped>
.btn-comment {
  background-color: white;
  border: 1px solid #bebebe;
  min-height: 30px;
  min-width: 200px;
  padding: 0.5rem 0.1rem;
}
</style>

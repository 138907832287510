<template>
  <div class="card">
    <Loader :is-visible="loading" />

    <div class="card-header d-flex align-items-center">
      <i class="icon-refresh"></i> Filtrer
    </div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Type</label>
          <div class="d-flex">
            <v-select
              v-model="filters.type"
              :options="typeOptions"
              class="w-100"
              :reduce="({ value }) => value"
            />
            <button
              v-if="hasPermission($store.state.user, 'DYNAMIC_CONTENT_WRITE')"
              type="button"
              class="btn btn-sm btn-primary ml-05"
              @click="typeModal = true"
            >
              <i class="icon-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
      <button
        v-if="
          typeOptions.length > 0 &&
          hasPermission($store.state.user, 'DYNAMIC_CONTENT_WRITE')
        "
        type="button"
        class="btn btn-sm btn-primary ml-05"
        @click="dynamicContentModal = true"
      >
        <i class="fa fa-plus"></i>&nbsp; Ajouter du contenu
      </button>
    </div>

    <ModalAddDynamicContentType
      :show="typeModal"
      :types="types"
      :close="closeAddTypeModal"
      @added="addedContentType"
    />
    <ModalDynamicContent
      :show="dynamicContentModal"
      :types="types"
      :close="closeDynamicContentModal"
    />
  </div>
</template>

<script>
import Loader from "../components/Loader";
import ModalDynamicContent from "./ModalDynamicContent";
import ModalAddDynamicContentType from "./ModalAddDynamicContentType";

export default {
  components: {
    Loader,
    ModalDynamicContent,
    ModalAddDynamicContentType,
  },

  props: {
    types: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      filters: {
        type: null,
      },
      typeModal: false,
      dynamicContentModal: false,
    };
  },

  computed: {
    typeOptions() {
      return this.types.map(({ type, metadata }) => ({
        label: type,
        value: type,
        metadata,
      }));
    },
  },

  methods: {
    applyFilters() {
      this.$emit("apply", this.filters);
    },

    closeAddTypeModal() {
      this.typeModal = false;
    },

    closeDynamicContentModal() {
      this.dynamicContentModal = false;
    },

    addedContentType(type) {
      this.types.push(type);
    },
  },
};
</script>

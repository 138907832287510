<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-12">
          <el-radio-group v-model="filters.paymentStatus">
            <el-radio
              :label="item.value"
              :key="index"
              v-for="(item, index) in labelStatus"
              >{{ item.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="form-group col-sm-4">
          <label>{{ dateLabel }}</label>
          <datepicker
            v-model="filters.date.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Date de fin</label>
          <datepicker
            v-model="filters.date.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <div class="label-amount">
            <label>Montant</label>
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 switch-amount"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.amountFilter.status"
                @change="toggleAmount"
              />
              <span class="switch-label" data-on="< >" data-off="="></span>
              <span class="switch-handle"></span>
            </label>
          </div>

          <div class="container-amount">
            <div v-show="!filters.amountFilter.status" class="container-strict">
              <input
                class="input-amount strict-amount"
                v-model="filters.amountFilter.amount"
                type="number"
              />
            </div>
            <div v-show="filters.amountFilter.status" class="container-minmax">
              <input
                class="input-amount amount amount-min"
                v-model="filters.amountFilter.min"
                type="number"
                placeholder="Min."
              />
              <input
                class="input-amount amount"
                v-model="filters.amountFilter.max"
                type="number"
                placeholder="Max."
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Concept</label>
          <multiselect
            v-model="filters.concepts"
            :options="optsConcepts"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Code</label>
          <multiselect
            v-model="filters.codes"
            :options="invoiceSeries"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Société</label>
          <multiselect
            v-model="filters.merchants"
            :options="optsMerchants"
            label="label"
            track-by="label"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Mode de paiement</label>
          <multiselect
            v-model="filters.types"
            :options="labelTypes"
            label="label"
            track-by="label"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Commercial</label>

          <multiselect
            v-model="filters.commercials"
            :options="commercials"
            label="label"
            track-by="label"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Filtrer par client</label>
          <v-select
            v-model="filters.client"
            :options="clients"
            @search="searchClients"
          ></v-select>
        </div>
        <div class="form-group col-sm-4">
          <label>Filtrer par payeur</label>
          <v-select
            v-model="filters.payer"
            :options="payers"
            @search="searchPayers"
          ></v-select>
        </div>
        <div class="form-group col-sm-4">
          <div class="row">
            <div class="form-group col-sm-6">
              <label>Date de dépot début</label>
              <datepicker
                v-model="filters.depositDate.begin"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
            <div class="form-group col-sm-6">
              <label>Fin</label>
              <datepicker
                v-model="filters.depositDate.end"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-4">
          <label>Avance immédiate</label>

          <multiselect
            v-model="filters.aiStatus"
            :options="aiStatus"
            label="label"
            track-by="value"
            :multiple="false"
            selectLabel=""
            deselectLabel=""
            selectedLabel="Séléctionné"
            placeholder=""
          />
        </div>
      </div>

      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import debounce from "lodash.debounce";

import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Multiselect,
    Datepicker,
  },

  data() {
    return {
      filters: {
        concepts: null,
        merchants: null,
        codes: null,
        types: null,
        client: null,
        payer: null,
        aiStatus: null,
        date: {
          begin: moment().subtract(1, "M").toDate(),
          end: moment().toDate(),
        },
        depositDate: {
          begin: null,
          end: null,
        },
        paymentStatus: "captured",
        commercials: [],
        amountFilter: {
          status: false,
          amount: null,
          min: null,
          max: null,
        },
      },
      aiStatus: [
        { label: "En attente", value: "pending" },
        { label: "Envoyé", value: "send" },
        { label: "Refusé", value: "denied" },
      ],
      labelStatus: [
        { label: "Payé", value: "captured" },
        { label: "Paiement échoué", value: "failed" },
        { label: "Prélèvement en attente", value: "autoCapture" },
        { label: "Paiement en attente", value: "pending" },
        { label: "Remboursé", value: "refunded" },
        { label: "Remboursement", value: "refund" },
        { label: "Tous*", value: undefined },
      ],

      labelTypes: [],
      commercials: [],
      clients: [],
      payers: [],
    };
  },

  async created() {
    await this.getPaymentMethods();
    await this.getCommercials();

    if (
      this.commercials &&
      this.commercials.filter((c) => c.value === this.user.email).length > 0 &&
      this.user.commercial
    ) {
      this.filters.commercials.push({
        value: this.user.email,
        label: this.user.commercial.pseudo || this.user.firstName,
      });
    }
  },

  computed: {
    optsConcepts() {
      if (this.concepts) {
        return this.concepts.map((c) => c.name);
      }

      return [];
    },

    optsMerchants() {
      if (this.merchants) {
        return this.merchants.map((m) => ({ label: m.name, value: m.id }));
      }
      return [];
    },

    dateLabel() {
      switch (this.filters.paymentStatus) {
        case "captured":
          return "Date de paiement";
        case "failed":
          return "Date de l'échec";
        case "pending":
        case "autoCapture":
          return "Date de programmation";
        case "refunded":
          return "Date de remboursement";
        case "refund":
          return "Date de remboursement";
        default:
          return "Date de création";
      }
    },

    ...mapState({
      concepts: (state) => state.concepts,
      merchants: (state) => state.merchants,
      user: (state) => state.user,
      invoiceSeries: (state) =>
        state.invoiceSeries.map((iSerie) => iSerie.code),
    }),
  },

  methods: {
    applyFilters() {
      const merchants = this.filters.merchants?.map((m) => m.value);
      const types = this.filters.types?.map((c) => c.value);
      const commercials = this.filters.commercials?.map((c) => c.value);
      const { begin, end } = this.filters.date;
      const client = this.filters.client?.value;
      const payer = this.filters.payer?.value;
      const depositDateBegin = this.filters.depositDate.begin;
      const depositDateEnd = this.filters.depositDate.end;
      const aiStatusValue = this.filters.aiStatus?.value;

      this.$emit("update", {
        ...this.filters,
        client,
        payer,
        merchants,
        types,
        commercials,
        begin,
        end,
        depositDateBegin,
        depositDateEnd,
        aiStatusValue,
      });
    },

    excelExport() {
      const merchants = this.filters.merchants?.map((m) => m.value);
      const types = this.filters.types?.map((c) => c.value);
      const commercials = this.filters.commercials?.map((c) => c.value);
      const { begin, end } = this.filters.date;

      this.$emit("export", {
        ...this.filters,
        merchants,
        types,
        commercials,
        begin,
        end,
      });
    },

    async getCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    async getPaymentMethods() {
      const { data } = await this.$api.get("/payment-methods");

      this.labelTypes = data.map((c) => ({
        value: c.name,
        label: c.name,
      }));

      this.labelTypes.push({
        value: "CB",
        label: "CB",
      });
    },

    toggleAmount() {
      this.filters.amountFilter.amount = null;
      this.filters.amountFilter.min = null;
      this.filters.amountFilter.max = null;
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter?q=${search}`);

      vm.clients = data.map((c) => ({
        label: c.name + " (" + c.email + ")",
        value: c.email,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async searchPayers(search, loading) {
      if (search !== "") {
        await this.fetchPayers(search, loading, this);
      }
    },

    fetchPayers: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(
        `/payments/payers/search?terms=${search}`
      );

      for (const p of data) {
        if (
          vm.payers.filter((d) => d.value === p.payer && d.client === p.client)
            .length !== 0
        ) {
          continue;
        }

        vm.payers.push({
          label: p.payer + " (" + p.client + ")",
          value: p.payer,
          client: p.client,
        });
      }

      loading(false);

      return vm.payers;
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .reset-amount {
    margin-right: 25px !important;
  }
  .amount {
    width: 47%;
  }
}

.container-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.input-amount {
  outline: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  padding: 0 4px 4px 4px;
  height: 31.59px;
  border-radius: 4px;
}

.reset-amount {
  fill: rgba(60, 60, 60, 0.5);
  cursor: pointer;
  margin-right: 4px;
  z-index: 50;
  position: absolute;
  right: 0;
  margin-right: 27px;
}

.strict-amount {
  width: 100%;
}

.amount {
  width: 50%;
}

.label-amount {
  display: flex;
  justify-content: flex-start;
}

.container-minmax {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container-strict {
  padding: 0;
  margin: 0;
  width: 100%;
}

.switch-amount {
  margin-left: 4px !important;
  margin: 0px;
}

.amount-min {
  margin-right: 4px;
}
</style>

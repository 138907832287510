<template>
  <div class="row pt-1 animated fadeIn">
    <div class="col-12 mb-3">
      <el-radio-group v-model="statsCategory" @change="changeStatsCategory">
        <el-radio label="subscribers">Abonnés</el-radio>
        <el-radio label="trialing">En cours d'essai</el-radio>
        <el-radio label="acquisition">CA</el-radio>
      </el-radio-group>
    </div>
    <div v-if="!loading" class="col-12">
      <StatsFranceMap
        :data="mapData"
        :color-scheme="mapColor"
        :stats="geoStatsCategory"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsFranceMap from "./StatsFranceMap";

export default {
  components: {
    StatsFranceMap,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: null,
    },

    geoStatsCategory: {
      type: String,
      default: "subscribers",
    },
  },

  data() {
    return {
      statsCategory: "subscribers",
      defaultData: {
        total: 0,
        totalPercentage: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
    }),

    displayedDepartments() {
      const domTom = ["971", "972", "973", "974", "976"];
      const metroDepartments = this.departments.filter(
        (dpt) => !domTom.includes(dpt.code) && dpt.code !== "all"
      );

      return metroDepartments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    mapData() {
      let departments = [
        ...this.displayedDepartments.map((dpt) => ({
          ...dpt,
          data: this.defaultData,
        })),
      ];

      if (this.data && !this.loading) {
        switch (this.geoStatsCategory) {
          case "subscribers":
          case "trialing":
            departments.forEach((dpt) => {
              dpt.data = this.departmentSubsInfo(dpt.value);
            });
            break;
          case "acquisition":
            departments.forEach((dpt) => {
              dpt.data = this.departmentCaInfo(dpt.value);
            });
            break;
        }
      }

      return departments;
    },

    mapColor() {
      switch (this.geoStatsCategory) {
        case "subscribers":
          return "orange";
        case "trialing":
          return "purple";
        case "acquisition":
          return "green";
        default:
          return null;
      }
    },

    totalClients() {
      return this.geoStatsCategory !== "acquisition" && this.data.length;
    },

    totalCA() {
      return (
        this.data &&
        this.geoStatsCategory === "acquisition" &&
        this.getCA(this.data)
      );
    },
  },

  mounted() {
    const categoryValue = this.geoStatsCategory;

    this.statsCategory = categoryValue;
  },

  methods: {
    departmentSubsInfo(dpt) {
      const departmentsSubs = this.data.filter((client) => {
        if (client.zipcode && typeof client.zipcode === "string") {
          return client.zipcode.slice(0, 2) === dpt;
        }
      });

      const total = departmentsSubs.length;
      let totalPercentage = (total / this.totalClients) * 100;

      if (isNaN(totalPercentage)) {
        totalPercentage = 0;
      }

      return { total, totalPercentage };
    },

    departmentCaInfo(dpt) {
      const departmentOrders = this.data.filter((order) => {
        if (
          order.invoice.zipcode &&
          typeof order.invoice.zipcode === "string"
        ) {
          return order.invoice.zipcode.slice(0, 2) === dpt;
        }
      });

      const total = this.getCA(departmentOrders);
      let totalPercentage = (total / this.totalCA) * 100;

      if (isNaN(totalPercentage)) {
        totalPercentage = 0;
      }

      return { total, totalPercentage };
    },

    changeStatsCategory(category) {
      this.$emit("changeGeoStatsCategory", category);
    },

    getCA(orders) {
      let ca = 0;

      orders.forEach((order) => {
        if (order.status === "refunded") {
          ca -= order.price;
        } else {
          ca += order.price;
        }
      });

      return ca;
    },
  },
};
</script>

<template>
  <div class="d-flex mb-1">
    <div class="d-flex">
      <!-- Processed -->
      <div v-if="processed">
        <button
          class="btn btn-warning btn-sm"
          @click="unprocessedAllDisbursement()"
          v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
        >
          <i class="fa fa-exclamation-triangle"></i> Annuler les traitements
          (passer en attente)
        </button>
        <button
          class="btn btn-success btn-sm ml-1"
          @click="payAll()"
          v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
        >
          <i class="fa fa-check-circle"></i> Marquer les factures séléctionnées
          comme payées
        </button>
        <button
          v-if="selected.length > 1"
          class="btn btn-primary btn-sm ml-1"
          @click="xmlBankingFile('company')"
        >
          <i class="fa fa-cloud-download"></i> XML COMP
        </button>
        <button
          v-if="selected.length > 1"
          class="btn btn-primary btn-sm ml-1"
          @click="xmlBankingFile('corporate')"
        >
          <i class="fa fa-cloud-download"></i> XML CORP
        </button>
      </div>
      <!-- Payed -->
      <div v-else-if="payed">
        <button
          class="btn btn-warning btn-sm"
          @click="unprocessedAllDisbursement()"
          v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
        >
          <i class="fa fa-exclamation-triangle"></i> Annuler (passer en attente)
        </button>
        <button
          class="btn btn-outline-danger btn-sm ml-1"
          @click="unpayedAllDisbursement()"
          v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
        >
          <i class="fa fa-exclamation-triangle"></i> Annuler les paiements
          (passer en traité)
        </button>
        <button
          class="btn btn-info btn-sm ml-1"
          @click="archivedAll()"
          v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
        >
          <i class="fa fa-check-circle"></i> Archiver les factures séléctionnées
        </button>
      </div>
      <!-- Blocked -->
      <div v-else-if="blocked">
        <button
          class="btn btn-warning btn-sm"
          @click="unprocessedAllDisbursement()"
          v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
        >
          <i class="fa fa-check-circle"></i> Annuler le refus (passer "En
          attente")
        </button>
        <button
          class="btn btn-info btn-sm ml-1"
          @click="archivedAll()"
          v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
        >
          <i class="fa fa-check-circle"></i> Archiver les factures
        </button>
      </div>
      <!-- Not processed -->
      <div v-else>
        <button
          class="btn btn-success btn-sm ml-1"
          @click="processedAllDisbursement()"
          v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
        >
          <i class="fa fa-check-circle"></i> Factures traitées
        </button>
        <button
          v-if="selected.length > 1"
          class="btn btn-primary btn-sm ml-1"
          @click="sendEmails()"
        >
          <i class="fa fa-envelope"></i> Emails demande IBAN
        </button>
      </div>
      <!-- Global Actions -->
      <button
        v-if="selected.length > 1"
        class="btn btn-default btn-sm ml-1"
        @click="downloadInvoices()"
      >
        <i class="fa fa-cloud-download"></i> Télécharger une archive des
        factures
      </button>
      <button
        v-if="selected.length > 1"
        class="btn btn-warning btn-sm ml-1"
        @click="downloadInvoices('merged-pdf')"
      >
        <i class="fa fa-cloud-download"></i> Télécharger un pdf combiné
      </button>
    </div>
  </div>
</template>

<script>
import DownloadFile from "../mixin/DownloadFile";

export default {
  props: {
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    processed: {
      type: Boolean,
      required: false,
      default: false,
    },
    payed: {
      type: Boolean,
      required: false,
      default: false,
    },
    blocked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [DownloadFile],
  methods: {
    async downloadInvoices(group = false) {
      try {
        const invoiceIds = this.selected.map((d) => d.invoice);

        const { data } = await this.$api.post("/uploads/download", {
          invoiceIds,
          group,
        });

        this.downloadSecuredFile(`${data.archive}`);
      } catch (e) {
        console.error(e);
      }

      this.isLoading = false;
    },

    xmlBankingFile(type) {
      this.$confirm({
        message: "Créer un fichier XML pour le transfert bancaire ?",
        onConfirm: async () => {
          try {
            const { data } = await this.$api.post(
              `/disbursements/xml-banking-export`,
              {
                disbursementIds: this.selected.map((d) => d.id),
                type,
              }
            );

            this.downloadSecuredFile(`${data}`);
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    processedAllDisbursement() {
      this.$confirm({
        message:
          "Marquer toutes les factures comme traitées ? (Seulement si l'iban est présent)",
        onConfirm: async () => {
          const selectedWithIban = this.selected.filter(
            (d) => !!d.coach.coach.iban
          );
          for (const { id } of selectedWithIban) {
            this.$emit("process", id);
          }
        },
      });
    },

    unprocessedAllDisbursement() {
      this.$confirm({
        message: `Rebasculer toutes les factures dans la section "En attente" ?`,
        onConfirm: async () => {
          for (const { id } of this.selected) {
            this.$emit("unprocess", id);
          }
        },
      });
    },

    payAll() {
      this.$confirm({
        message: "Marquer toutes les factures comme payées ?",
        onConfirm: async () => {
          for (const { id } of this.selected) {
            this.$emit("manual-pay", id);
          }
        },
      });
    },

    unpayedAllDisbursement() {
      this.$confirm({
        message: "Annuler le paiement de toutes les factures séléctionnées ?",
        onConfirm: async () => {
          for (const { id } of this.selected) {
            this.$emit("unpay", id);
          }
        },
      });
    },

    archivedAll() {
      this.$confirm({
        message:
          "Archiver toutes les factures ? Cette action est irréversible.",
        onConfirm: async () => {
          for (const { id } of this.selected) {
            this.$emit("archive", id);
          }
        },
      });
    },

    sendEmails() {
      this.$confirm({
        message:
          "Envoyer des emails de demande d'IBAN ? (les utilisateurs disposants déjà d'un IBAN ne seront pas impactés)",
        onConfirm: async () => {
          for (const { coach } of this.selected) {
            this.$emit("iban", coach);
          }
        },
      });
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div :class="`form-group col-sm-${showTypeFilter ? '4' : '6'}`">
          <label>Date de début</label>
          <datepicker
            v-model="filter.start"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div :class="`form-group col-sm-${showTypeFilter ? '4' : '6'}`">
          <label>Date de fin</label>
          <datepicker
            v-model="filter.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div v-if="showTypeFilter" class="form-group col-sm-4">
          <label>Type d'abonnement</label>
          <multiselect
            v-model="filter.type"
            :options="typeOptions"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Datepicker,
    Multiselect,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },

    showTypeFilter: {
      type: Boolean,
      default: false,
    },

    noSolo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filter: {},
      types: [
        {
          label: "Live",
          value: "live",
        },
        {
          label: "Visio & Live",
          value: "small",
        },
        {
          label: "Solo",
          value: "solo",
        },
      ],
    };
  },

  computed: {
    typeOptions() {
      return this.noSolo
        ? this.types.filter(({ value }) => value !== "solo")
        : this.types;
    },
  },

  mounted() {
    this.filter = this.default;
  },

  methods: {
    applyFilters() {
      this.$emit("apply", this.filter);
    },
  },
};
</script>

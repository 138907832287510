<template>
  <div class="stars-wrapper">
    <i v-for="i in maxRating" :key="i" class="fa fa-star inactive-stars"></i>

    <div class="active-stars" :style="`width: ${activePercentage}%`">
      <i v-for="i in maxRating" :key="i" class="fa fa-star"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      default: 0,
    },

    maxRating: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    activePercentage() {
      return (this.rating * 100) / this.maxRating;
    },
  },
};
</script>

<style lang="scss" scoped>
.stars-wrapper {
  position: relative;
  min-width: max-content;
  width: max-content;
  max-width: max-content;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.inactive-stars {
  color: #e1e6ef;
}

.active-stars {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  color: #fae54d;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
}
</style>

<template>
  <div class="input-group" :class="hasError ? 'has-error' : ''">
    <input
      :value="value"
      :type="showPassword ? 'text' : 'password'"
      class="form-control"
      autocomplete="off"
      v-on:input="updateValue($event.target.value)"
    />
    <span class="input-group-btn">
      <button
        class="btn btn-default reveal"
        type="button"
        @click="tooglePassword"
      >
        <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
      </button>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showPassword: false,
    };
  },

  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },

    tooglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="css" scoped>
.has-error {
  border: 1px solid #ff5454 !important;
  border-radius: 4px;
}
</style>

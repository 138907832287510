<template>
  <div
    class="card"
    :class="customClass"
    v-show="
      departmentData && departmentData.data && departmentData.data.length !== 0
    "
  >
    <div class="card-header font-weight-bold">
      {{ departmentData.label }}
    </div>

    <div class="card-body p-1 font-weight-bold" v-if="departmentData.resume">
      <p v-if="departmentData.resume.nbDiscoveryAttributed" class="m-0">
        Nb attributions:
        {{ departmentData.resume.nbDiscoveryAttributed }} pack{{
          departmentData.resume.nbDiscoveryAttributed > 1 ? "s" : ""
        }}
      </p>
      <p v-if="departmentData.resume.nbDiscoveryDone" class="m-0">
        Nb découverte faites:
        {{ departmentData.resume.nbDiscoveryDone }} séance{{
          departmentData.resume.nbDiscoveryDone > 1 ? "s" : ""
        }}
      </p>
      <p v-if="departmentData.resume.nbPackPostDiscovery" class="m-0">
        Nb packs post découverte:
        {{ departmentData.resume.nbPackPostDiscovery }} pack{{
          departmentData.resume.nbPackPostDiscovery > 1 ? "s" : ""
        }}
      </p>
      <p v-if="departmentData.resume.conversionRate" class="m-0">
        % de conversion: {{ departmentData.resume.conversionRate }}%
      </p>
      <p v-if="departmentData.resume.clientPackAverage" class="m-0">
        Pack moyen: {{ departmentData.resume.clientPackAverage }} séance{{
          departmentData.resume.clientPackAverage > 1 ? "s" : ""
        }}
      </p>
      <p class="text-danger m-0" v-if="departmentData.resume.lostDiscovery">
        Nb découverte perdues: <br />{{
          departmentData.resume.lostDiscovery
        }}
        pack{{ departmentData.resume.lostDiscovery > 1 ? "s" : "" }}
      </p>
      <p class="text-danger m-0" v-if="departmentData.resume.lostPercent">
        % de perte: {{ departmentData.resume.lostPercent }}%
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    departmentData: {
      type: Object,
      default: null,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>

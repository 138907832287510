<template>
  <modal
    title="Changer le mot de passe"
    v-model="passwordModal.show"
    effect="fade/zoom"
    :backdrop="false"
  >
    <form autocomplete="off" class="mt-2">
      <div class="form-group">
        <label>Mot de passe actuel</label>
        <PasswordInput
          v-model="passwordModal.form.currentPassword"
          :hasError="passwordModal.formError.currentNotValid"
        />
      </div>
      <div
        class="alert alert-danger"
        role="alert"
        v-if="passwordModal.formError.currentEmpty"
      >
        Vous devez renseigner votre mot de passe actuel
      </div>
      <div
        class="alert alert-danger"
        role="alert"
        v-if="passwordModal.formError.currentWrong"
      >
        Le mot de passe est incorrect, merci de renseigner votre mot de passe actuel
      </div>
      <div class="form-group">
        <label>Nouveau mot de passe</label>
        <PasswordInput
          v-model="passwordModal.form.password"
          :hasError="passwordModal.formError.password"
          @input="checkStrength()"
        />
      </div>
      <div
        class="alert alert-danger"
        role="alert"
        v-if="passwordModal.formError.notValid"
      >
        Le mot de passe n'est pas assez sécurisé, il doit avoir minimum 8
        caractères, 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial.
      </div>
      <ul class="text-left text-sm pl-1" style="list-style-type: none">
        <li
          :class="
            passwordModal.formError.minimal ? 'text-success' : 'text-error'
          "
        >
          <i
            :class="
              passwordModal.formError.minimal
                ? 'fa fa-check-circle'
                : 'fa fa-minus-circle'
            "
          />
          8 caractères minimum
        </li>
        <li
          :class="passwordModal.formError.case ? 'text-success' : 'text-error'"
        >
          <i
            :class="
              passwordModal.formError.case
                ? 'fa fa-check-circle'
                : 'fa fa-minus-circle'
            "
          />
          Au moins une majuscule et une minuscule
        </li>
        <li
          :class="
            passwordModal.formError.hasNumber ? 'text-success' : 'text-error'
          "
        >
          <i
            :class="
              passwordModal.formError.hasNumber
                ? 'fa fa-check-circle'
                : 'fa fa-minus-circle'
            "
          />
          Un chiffre minimum
        </li>
        <li
          :class="
            passwordModal.formError.hasSpecialChar
              ? 'text-success'
              : 'text-error'
          "
        >
          <i
            :class="
              passwordModal.formError.hasSpecialChar
                ? 'fa fa-check-circle'
                : 'fa fa-minus-circle'
            "
          />
          Un caractère spécial
        </li>
      </ul>

      <div class="form-group">
        <label>Confirmer le nouveau mot de passe</label>
        <PasswordInput
          v-model="passwordModal.form.passwordCheck"
          :hasError="passwordModal.formError.passwordCheck"
          @input="checkStrength()"
        />
      </div>
      <div
        class="alert alert-danger"
        role="alert"
        v-if="passwordModal.formError.notSamePassword"
      >
        Les mots de passe ne sont pas identique.
      </div>
      <ul class="text-left text-sm pl-1" style="list-style-type: none">
        <li
          :class="passwordModal.formError.same ? 'text-success' : 'text-error'"
        >
          <i
            style="margin-right: 5px"
            :class="
              passwordModal.formError.same
                ? 'fa fa-check-circle'
                : 'fa fa-minus-circle'
            "
          />
          <span v-if="passwordModal.formError.same"
            >Les mots de passe sont identique.</span
          >
          <span v-else>Les mots de passe ne sont pas identique.</span>
        </li>
      </ul>
    </form>
    <div
      slot="modal-footer"
      class="modal-footer d-flex justify-content-between"
    >
      <div>
        <loader :isVisible="isLoading" size="small" />
      </div>
      <div>
        <button type="button" class="btn btn-default mr-1" @click="close">
          Fermer
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="handleChangePassword()"
        >
          Changer
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import PasswordInput from "../form/PasswordInput";
import Loader from "../Loader";

export default {
  components: {
    Modal,
    PasswordInput,
    Loader,
  },

  data: () => ({
    passwordModal: {
      show: false,
      form: {
        currentPassword: "",
        password: "",
        passwordCheck: null,
      },
      formError: {
        currentNotValid: false,
        currentEmpty: false,
        currentWrong: false,
        password: false,
        passwordCheck: false,
        notSamePassword: false,
        minimal: false,
        case: false,
        hasNumber: false,
        hasSpecialChar: false,
        same: false,
      },
    },
    regex: {
      caseDiff: /(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u,
      number: /[0-9]/,
      specialChar: /[^\p{Ll}\p{Lu}\p{L}\p{N}]/u,
    },
    isLoading: false,
  }),

  props: {
    email: {
      type: String,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.passwordModal.show = !!this.$store.state.changePassword;
  },

  methods: {
    async handleChangePassword() {
      this.passwordModal.formError = {
        password: false,
        passwordCheck: false,
        notSamePassword: false,
        notValid: false,
      };

      if (this.passwordModal.form.currentPassword === "") {
        this.passwordModal.formError = {
          ...this.passwordModal.formError,
          currentNotValid: true,
          currentEmpty: true
        };

        return;
      }

      if (
        this.passwordModal.form.password !==
        this.passwordModal.form.passwordCheck
      ) {
        this.passwordModal.formError = {
          ...this.passwordModal.formError,
          password: true,
          passwordCheck: true,
          notSamePassword: true,
        };

        return;
      }

      if (
        !this.passwordModal.form.password.match(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-+/]).{8,}$"
        )
      ) {
        this.passwordModal.formError = {
          ...this.passwordModal.formError,
          password: true,
          notValid: true,
        };

        return;
      }

      this.isLoading = true;

      try {
        await this.$api.put("/users/password", {
          email: this.email,
          password: this.passwordModal.form.currentPassword,
          newPassword: this.passwordModal.form.password,
        });

        this.passwordModal.form.password = null;
        this.passwordModal.show = false;
        this.$store.commit("AUTH_CHANGE_PASSWORD", false);
      } catch (e) {
        if (e.response.status === 400 && e.response.data === "WRONG_PASSWORD") {
          this.passwordModal.formError = {
            ...this.passwordModal.formError,
            currentNotValid: true,
            currentWrong: true,
          };
        }
      }

      this.isLoading = false;
    },

    checkStrength() {
      this.passwordModal.formError.minimal =
        this.passwordModal.form.password.length >= 8;

      this.passwordModal.formError.case = this.regex.caseDiff.test(
        String(this.passwordModal.form.password)
      );

      this.passwordModal.formError.hasNumber = this.regex.number.test(
        String(this.passwordModal.form.password)
      );

      this.passwordModal.formError.hasSpecialChar = this.regex.specialChar.test(
        String(this.passwordModal.form.password)
      );

      this.passwordModal.formError.same =
        this.passwordModal.form.password ==
        this.passwordModal.form.passwordCheck;
    },

    close() {
      this.passwordModal.show = false;
    },
  },

  watch: {
    open(val) {
      this.passwordModal.show = val;
    },
  },
};
</script>

<style scoped>
.has-error {
  border: 1px solid #ff5454 !important;
  border-radius: 4px;
}
</style>

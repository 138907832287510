const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export default {
  methods: {
    downloadXLSX(data, name) {
      var blob = new Blob([s2ab(data)], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
    },

    async downloadSecuredFile(url) {
      const { data, headers } = await this.$api.get(url);

      const linkSource = `data:${headers["content-type"]};base64,${data.content}`;
      const downloadLink = document.createElement("a");
      const fileName = `${data.filename}`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },
};

<template>
  <div class="animated fadeIn">
    <div class="card" v-if="paymentMethod">
      <div class="card-header">
        <i class="icon-puzzle"></i> Moyen de paiement : {{ paymentMethod.name }}
        <button
          type="button"
          class="btn btn-sm btn-danger float-right"
          @click="removePaymentMethod()"
          v-if="
            hasPermission($store.state.user, 'SETTINGS_PAYMENTS_TYPE_WRITE')
          "
        >
          Supprimer
        </button>
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Concepts</dt>
          <dd class="col-sm-9">
            <div class="checkboxes" v-if="paymentMethod.concepts">
              <div class="type" v-for="(concept, key) in concepts" :key="key">
                <input
                  v-model="paymentMethod.concepts"
                  :value="concept.name"
                  type="checkbox"
                  class="form-control"
                  :id="concept.name"
                  v-on:change="editConcepts"
                  :contenteditable="true"
                  v-if="
                    hasPermission(
                      $store.state.user,
                      'SETTINGS_PAYMENTS_TYPE_WRITE'
                    )
                  "
                />
                <label v-else class="mr-1">
                  {{
                    paymentMethod.concepts.includes(concept.name)
                      ? "Oui"
                      : "Non"
                  }}
                </label>
                <label :for="concept.name">{{ concept.name }}</label>
              </div>
            </div>
          </dd>
        </dl>
      </div>
    </div>

    <div class="card" v-if="paymentMethod">
      <div class="card-header">
        <i class="icon-paper-plane"></i> Réglages des mails Info Paiement
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Type de paiement</dt>
          <dd
            class="col-sm-9 editable"
            edit="type"
            v-if="
              hasPermission($store.state.user, 'SETTINGS_PAYMENTS_TYPE_WRITE')
            "
            :contenteditable="true"
            v-on:blur="edited"
          >
            <span>{{ paymentMethod.type }}</span>
          </dd>
          <dd v-else>
            {{ paymentMethod.type }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            Comment régler : information contenue dans les emails client
            (commande, info paiement, etc...)
          </dt>
          <dd
            class="col-sm-9 editable"
            edit="howto"
            v-if="
              hasPermission($store.state.user, 'SETTINGS_PAYMENTS_TYPE_WRITE')
            "
            :contenteditable="true"
            v-on:blur="edited"
          >
            <span>{{ paymentMethod.howto }}</span>
          </dd>
          <dd v-else>
            {{ paymentMethod.type }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      concepts: [],
      paymentMethod: null,
    };
  },
  created() {
    this.$api
      .get("/payment-methods/" + this.$route.params.id)
      .catch(() => {
        this.$router.push({
          name: "paymentMethods",
        });
      })
      .then((res) => {
        this.paymentMethod = res.data;
        this.paymentMethod.concepts = this.paymentMethod.concepts.map(
          (concept) => concept.name
        );
      });
    this.$api.get("/concepts").then((res) => {
      this.concepts = res.data;
    });
  },
  methods: {
    edited: function (event) {
      var data = {
        id: this.paymentMethod.id,
      };
      var edit = event.target.getAttribute("edit");
      data[edit] = event.target.innerHTML.replace(/(<([^>]+)>)/gi, "");
      event.target.innerHTML = data[edit];

      this.$api.put("/payment-methods", { paymentMethod: data }).then((res) => {
        this.paymentMethod[edit] = res.data[edit];
      });
    },
    editConcepts: function () {
      this.$api.put("/payment-methods", {
        paymentMethod: this.paymentMethod,
        concepts: this.paymentMethod.concepts,
      });
    },
    removePaymentMethod() {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.delete("/payment-methods", {
            data: {
              id: this.paymentMethod.id,
            },
          });

          this.$router.push({
            name: "payment-methods",
          });

          window.location.reload(true);
        },
      });
    },
  },
};
</script>
<style scoped>
.type {
  display: flex;
  align-items: center;
}
.type input {
  width: auto;
  margin: 0 6px 6px 0;
}
</style>

import { events } from "./events";
import ConfirmModal from "./ConfirmModal.vue";

const ConfirmModalPlugin = {
  install(Vue) {
    Vue.prototype.$confirm = ({ message, onConfirm, onRefuse }) => {
      events.$emit("confirm", { message, onConfirm, onRefuse });
    };

    Vue.component("ConfirmModal", ConfirmModal);
  },
};

export default ConfirmModalPlugin;

<template>
  <el-table :data="data" @row-click="select">
    <el-table-column prop="createdAt" label="Créée le" width="120">
      <template slot-scope="scope">
        {{ formatDate(scope.row.createdAt) }}
      </template>
    </el-table-column>
    <el-table-column prop="user.coach.name" label="Coach" width="200">
    </el-table-column>
    <el-table-column prop="user.id" label="Fiche" width="80" align="center">
      <template slot-scope="scope">
        <a href="#" @click.stop.prevent="goToUser(scope.row.user.id)"
          ><i class="icon-user"></i
        ></a>
      </template>
    </el-table-column>
    <el-table-column prop="note" label="Message">
      <template slot-scope="scope">
        {{
          scope.row.note.length > 70
            ? scope.row.note.substring(0, 67) + "..."
            : scope.row.note
        }}
      </template>
    </el-table-column>
    <el-table-column
      prop="merchant.name"
      label="Société"
      width="200"
      align="left"
    ></el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    goToUser(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    select(row) {
      this.$emit("select", row);
    },
  },
};
</script>

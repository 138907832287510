<template>
  <div class="wrapper">
    <div class="card" v-if="substitutions">
      <div class="card-header">
        <i class="icon-refresh"></i> Remplacements à traiter
      </div>
      <div class="card-block">
        <substitutions :substitutions="pending" :pending="true" @showComment="displayComment" />
      </div>
    </div>
    <div class="card" v-if="substitutions">
      <div class="card-header">
        <i class="icon-briefcase"></i> Archive
      </div>
      <div class="card-block">
        <substitutions :substitutions="completed" @showComment="displayComment"/>
      </div>
    </div>
    <modal
      v-if="modalUpdateComment.commentaireSubstitution"
      title="Commentaire"
      v-model="modalUpdateComment.showComment"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateComment()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateComment.commentaireSubstitution.adminNotes"
        cols="30"
        rows="10"
        class="form-control form-normalization">
      </textarea>
    </modal>
  </div>
</template>

<script>
import Substitutions from '../components/Substitutions'
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Substitutions,
    Modal
  },
  data () {
    return {
      substitutions: null,
      modalUpdateComment: {
        showComment: false,
        commentaireSubstitution: null,
      }
    }
  },
  computed: {
    pending () {
      return this.substitutions.filter((s) => !s.completed)
    },
    completed () {
      return this.substitutions.filter((s) => s.completed)
    }
  },
  created () {
    this.$api.get('/substitutions/from-clients').then(res => {
      this.substitutions = res.data
    })
  },
  methods: {
    displayComment(substitution) {
      this.modalUpdateComment.showComment = true;
      this.modalUpdateComment.commentaireSubstitution = substitution;
    },
    async updateComment() {
      await this.$api
        .put("/substitutions/" + this.modalUpdateComment.commentaireSubstitution.id, {
          adminNotes: this.modalUpdateComment.commentaireSubstitution.adminNotes
        })
        .catch(err => {
          console.log(err);
        });

        this.modalUpdateComment.showComment = false;
    },
  },
}

</script>

<template>
  <div class="d-flex com-wrapper">
    <div class="com-img-container">
      <img :src="photo" />
    </div>
    <div class="d-flex flex-column justify-content-center text-left px-1">
      <p class="font-weight-bold h6 com-name">
        {{ name }}
      </p>
      <p class="mb-0 text-muted">{{ job }}</p>
      <a :href="`mailto:${email}`">{{ email }}</a>
      <p class="mb-0">{{ phone }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    phone: {
      type: String,
      required: true,
    },

    job: {
      type: String,
      required: true,
    },

    photo: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.com-wrapper {
  background: #f2f4f8;
  border-radius: 10rem 2rem 2rem 10rem;
  max-width: 375px;
  margin: auto;
}

.com-img-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: solid 4px #fff;
  overflow: hidden;
  background: #f2f4f8;
  & > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

.com-name {
  position: relative;
  margin-bottom: 0.75rem;
  &::after {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 75%;
    height: 1px;
    background-color: #c0cadd;
  }
}
</style>

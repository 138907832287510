<template>
  <div
    class="text-white d-flex flex-column align-items-stretch"
    :style="`
      background-color: ${label.color};
      padding: .5rem;
      padding-left: 1rem;
      border-radius: 1rem;
      margin-bottom: .3rem;
      margin-top: .3rem;
    `"
  >
    <div class="d-flex justify-content-between align-items-center">
      {{ label.name }}
      <template v-if="label.details">
        <i
          v-if="!expanded"
          class="fa fa-caret-down px-1"
          style="cursor: pointer"
          @click="expanded = 1"
        ></i>
        <i
          v-else
          class="fa fa-caret-up px-1"
          style="cursor: pointer"
          @click="expanded = 0"
        ></i>
      </template>
    </div>

    <div
      v-if="label.details"
      :class="`label-details px-1 pb-${expanded}`"
      :style="`transform: scaleY(${expanded}); max-height: ${
        expanded ? '600px' : '0'
      }`"
    >
      <template v-if="label.details.sources">
        <div class="font-weight-bold my-1">UTM Sources</div>
        <div
          v-for="source in label.details.sources"
          :key="source.label"
          class="text-right"
        >
          dont ({{ source.value }}) {{ source.label }} ({{
            source.percentage
          }}%)
        </div>
      </template>
      <template v-if="label.details.campaigns">
        <div class="font-weight-bold my-1">UTM Campaigns</div>
        <div
          v-for="campaign in label.details.campaigns"
          :key="campaign.label"
          class="text-right"
        >
          dont ({{ campaign.value }}) {{ campaign.label }} ({{
            campaign.percentage
          }}%)
        </div>
      </template>
      <template v-if="label.details.contents">
        <div class="font-weight-bold my-1">UTM Contents</div>
        <div
          v-for="content in label.details.contents"
          :key="content.label"
          class="text-right"
        >
          dont ({{ content.value }}) {{ content.label }} ({{
            content.percentage
          }}%)
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      expanded: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.label-details {
  overflow-y: auto;
  transform-origin: top;
  transition: transform 300ms ease-in-out, max-height 200ms ease-in-out;
}
</style>

<template>
  <div class="card coach-manager-relation">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <span
          class="font-weight-bold"
          v-tooltip="{
            content:
              'Coach manager de la commande. Il est attribué lors de l\'attribution du coach à la commande et peut être different des coachs manager attribués aux prestataires lors du recrutement.',
            placement: 'top',
          }"
        >
          Coach manager
          <i class="fa fa-info-circle mr-05"></i>
        </span>
        <el-select
          v-if="hasPermission($store.state.user, 'ORDERS_COACH_MANAGER_WRITE')"
          value=""
          size="mini"
          @change="addCoachManager($event)"
        >
          <el-option
            v-for="coachManager in available"
            :key="coachManager.value"
            :value="coachManager.value"
            >{{ coachManager.label }}</el-option
          >
        </el-select>
      </div>
      <div v-if="related.length > 0" style="padding: 0.5rem 0">
        <div
          v-for="(coachManager, index) in related"
          :key="`coach-manager-${coachManager.value}`"
          :class="`d-flex justify-content-between ${
            index === 0 ? 'my-1' : 'mb-1'
          }`"
        >
          <span
            class="text-white rounded-3 mr-1 d-flex align-items-center"
            style="padding: 7px"
            :style="
              coachManager.color && `background-color:${coachManager.color}`
            "
            :class="!coachManager.color && 'bg-primary'"
          >
            <span>{{ coachManager.label }}</span>
            <span
              v-if="
                hasPermission($store.state.user, 'ORDERS_COACH_MANAGER_WRITE')
              "
              class="ml-1"
              style="cursor: pointer"
              @click="removeCoachManager(coachManager.value)"
              ><i class="icon-close"></i
            ></span>
          </span>

          <datepicker
            v-if="
              hasPermission($store.state.user, 'ORDERS_COACH_MANAGER_WRITE')
            "
            v-model="coachManager.relatedSince"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="newCoachManagerRelationDate($event, coachManager.value)"
            :disabled="disabledDates"
          />
          <span v-else>{{ formatDate(coachManager.relatedSince) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      coachManagers: [],
      disabledDates: {
        to: moment().toDate(),
        from: moment().toDate(),
      },
    };
  },

  computed: {
    related() {
      return this.coachManagers
        .filter((com) =>
          this.order.coachManagers?.some(
            ({ userEmail, value }) =>
              userEmail === com.value || value === com.value
          )
        )
        .map((com) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { relatedSince } = this.order.coachManagers?.find(
            ({ userEmail, value }) =>
              userEmail === com.value || value === com.value
          );

          return { ...com, relatedSince };
        });
    },

    available() {
      return this.coachManagers.filter(
        ({ value }) => !this.related.some((com) => com.value === value)
      );
    },
  },

  mounted() {
    this.fetchCoachManagers();

    if (
      this.hasPermission(
        this.$store.state.user,
        "ORDERS_COACH_MANAGER_WRITE"
      ) ||
      this.isAdmin(this.$store.state.user)
    ) {
      this.disabledDates = {
        to: moment(this.order.createdAt)
          .endOf("day")
          .subtract(1, "days")
          .toDate(),
        from: moment().endOf("day").toDate(),
      };
    }
  },

  methods: {
    async fetchCoachManagers() {
      try {
        const { data } = await this.$api.get("/users/coach-managers");

        this.coachManagers = data.map((user) => ({
          label: user.coachManager.pseudo,
          value: user.email,
          mobile: user.coachManager.mobile,
          color: user.coachManager.color,
        }));
      } catch (e) {
        this.coachManagers = [];
      }
    },

    async addCoachManager(coachManager) {
      try {
        await this.$api.post("/orders/coach-managers", {
          orderId: this.order.id,
          coachManager,
        });

        const currentCoachManager = this.coachManagers.find(
          ({ value }) => value === coachManager
        );

        this.order.coachManagers.push({
          ...currentCoachManager,
          relatedSince: new Date(),
        });
      } catch (e) {
        this.$errorToast(
          "Impossible d'ajouter le coach manager, recharger la page pour vérifier qu'il n'est pas déjà attribué."
        );
      }
    },

    removeCoachManager(coachManager) {
      this.$confirm({
        message: "Supprimer le lien coach manager ?",
        onConfirm: async () => {
          await this.$api.delete("/orders/coach-managers", {
            data: {
              orderId: this.order.id,
              coachManager,
            },
          });

          const currentCoachManager = this.coachManagers.find(
            ({ value }) => value === coachManager
          );

          this.order.coachManagers = this.order.coachManagers.filter(
            ({ userEmail, value }) => {
              if (value) {
                return value !== currentCoachManager.value;
              }

              if (userEmail) {
                return userEmail !== currentCoachManager.value;
              }
            }
          );
        },
      });
    },

    async newCoachManagerRelationDate(relatedSince, coachManager) {
      if (relatedSince < moment(this.order.createdAt).startOf("day")) {
        this.$errorToast(
          `Impossible d'attribuer la commande au coachManager à une date antérieure à la création de la commande (${moment(
            this.order.createdAt
          ).format("DD-MM-YYYY")})`
        );
      } else if (relatedSince > moment().endOf("day")) {
        this.$errorToast(
          "Impossible d'attribuer la commande au coachManager à une date supérieure à aujourd'hui"
        );
      } else {
        await this.$api.put(`/orders/${this.order.id}/coach-managers`, {
          coachManager,
          relatedSince,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.coach-manager-relation {
  .vdp-datepicker__calendar {
    right: 0;
  }
}
</style>

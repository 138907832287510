<template>
  <div>
    <h4 class="text-center">
      Pour la période du {{ result.begin }} au {{ result.end }}
    </h4>
    <hr />
    <div class="row mt-2">
      <div class="col-md-6">
        <h5 class="discovery">Découverte</h5>
        <h6 class="ml-1">Facturées</h6>
        <ul>
          <li>
            {{ result.nbSessionDiscovery }} séance<template
              v-if="result.nbSessionDiscovery > 1"
              >s</template
            >
          </li>
          <li>
            CA :
            <span class="text-bold"
              >{{ localizedPrice(result.discoveryOrderPrice) }} ttc</span
            >
          </li>
          <li v-if="result.nbSessionDiscovery > 0">
            Soit
            <span class="text-bold"
              >{{
                localizedPrice(
                  result.discoveryOrderPrice / result.nbSessionDiscovery
                )
              }}
              ttc</span
            >
            / séance(s)
          </li>
        </ul>
        <h6 class="ml-1">Facturées coachs</h6>
        <ul>
          <li>
            {{ result.nbSessionCoachDiscovery }} séance<template
              v-if="result.nbSessionCoachDiscovery > 1"
              >s</template
            >
          </li>
          <li>
            Dont
            <span class="text-bold"
              >{{ localizedPrice(result.discoveryOrderCoachPrice) }} ttc</span
            >
            valeur de credit total
          </li>
          <li v-if="result.nbSessionCoachDiscovery > 0">
            Soit
            <span class="text-bold"
              >{{
                localizedPrice(
                  result.discoveryOrderCoachPrice /
                    result.nbSessionCoachDiscovery
                )
              }}
              ttc</span
            >
            dût au coachs/séance
          </li>
        </ul>
        <h6 class="ml-1">
          Séances facturées et non payés aux coachs le {{ result.end }}
        </h6>
        <ul>
          <li>
            {{ result.nbSessionDiscoveryLeft }} séance<template
              v-if="result.nbSessionDiscoveryLeft > 1"
              >s</template
            >
          </li>
          <li>
            CA :
            <span class="text-bold"
              >{{ localizedPrice(result.discoverySessionLeftPrice) }} ttc</span
            >
          </li>
          <li v-if="result.nbSessionDiscoveryLeft > 0">
            Soit
            <span class="text-bold"
              >{{
                localizedPrice(
                  result.discoverySessionLeftPrice /
                    result.nbSessionDiscoveryLeft
                )
              }}
              ttc</span
            >
            / séance(s)
          </li>
          <li class="text-danger">
            Montant du aux coachs :
            <span class="text-bold"
              >{{ localizedPrice(result.discoverySessionCreditLeft) }} ttc</span
            >
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <h5 class="pack">Pack (non découverte)</h5>
        <h6 class="ml-1">Facturées</h6>
        <ul>
          <li>
            {{ result.nbSessionNotDiscovery }} séance<template
              v-if="result.nbSessionNotDiscovery > 1"
              >s</template
            >
          </li>
          <li>CA : {{ localizedPrice(result.notDiscoveryOrderPrice) }} ttc</li>
          <li v-if="result.nbSessionNotDiscovery > 0">
            Soit
            {{
              localizedPrice(
                result.notDiscoveryOrderPrice / result.nbSessionNotDiscovery
              )
            }}
            ttc / séance(s)
          </li>
        </ul>
        <h6 class="ml-1">Facturées coachs</h6>
        <ul>
          <li>
            {{ result.nbSessionCoachNotDiscovery }} séance<template
              v-if="result.nbSessionCoachNotDiscovery > 1"
              >s</template
            >
          </li>
          <li>
            Dont
            <span class="text-bold"
              >{{
                localizedPrice(result.notDiscoveryOrderCoachPrice)
              }}
              ttc</span
            >
            valeur de credit total
          </li>
          <li v-if="result.nbSessionCoachNotDiscovery > 0">
            Soit
            <span class="text-bold"
              >{{
                localizedPrice(
                  result.notDiscoveryOrderCoachPrice /
                    result.nbSessionCoachNotDiscovery
                )
              }}
              ttc</span
            >
            dût au coachs/séance
          </li>
        </ul>
        <h6 class="ml-1">
          Séances facturées et non payés aux coachs le {{ result.end }}
        </h6>
        <ul>
          <li>
            {{ result.nbSessionNotDiscoveryLeft }} séance<template
              v-if="result.nbSessionNotDiscoveryLeft > 1"
              >s</template
            >
          </li>
          <li>
            CA : {{ localizedPrice(result.notDiscoverySessionLeftPrice) }} ttc
          </li>
          <li v-if="result.nbSessionNotDiscoveryLeft > 0">
            Soit
            {{
              localizedPrice(
                result.notDiscoverySessionLeftPrice /
                  result.nbSessionNotDiscoveryLeft
              )
            }}
            ttc / séance(s)
          </li>
          <li class="text-danger">
            Montant du aux coachs :
            <span class="text-bold">{{
              localizedPrice(result.notDiscoverySessionCreditLeft)
            }}</span>
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <div class="row mt-2">
      <div class="col-md-3"></div>
      <div class="col-md-6 col-md-offset-3">
        <h5 class="total">Total</h5>
        <h6 class="ml-1">Facturées</h6>
        <ul>
          <li>
            {{ result.nbSessionsGlobal }} séance<template
              v-if="result.nbSessionsGlobal > 1"
              >s</template
            >
          </li>
          <li>CA : {{ localizedPrice(result.sessionsTotalPrices) }} ttc</li>
          <li v-if="result.nbSessionsGlobal > 0">
            Soit
            {{
              localizedPrice(
                result.sessionsTotalPrices / result.nbSessionsGlobal
              )
            }}
            ttc / séance(s)
          </li>
        </ul>
        <h6 class="ml-1">Facturées coachs</h6>
        <ul>
          <li>
            {{ result.nbSessionsCoachGlobal }} séance<template
              v-if="result.nbSessionsCoachGlobal > 1"
              >s</template
            >
          </li>
          <li>
            Dont
            <span class="text-bold"
              >{{ localizedPrice(result.sessionsTotalCoachPrices) }} ttc</span
            >
            valeur de credit total
          </li>
          <li v-if="result.nbSessionsCoachGlobal > 0">
            Soit
            <span class="text-bold"
              >{{
                localizedPrice(
                  result.sessionsTotalCoachPrices / result.nbSessionsCoachGlobal
                )
              }}
              ttc</span
            >
            dût au coachs/séance
          </li>
        </ul>
        <h6 class="ml-1">
          Facturées et non payés aux coachs le {{ result.end }}
        </h6>
        <ul>
          <li>
            {{ result.nbSessionsGlobaleft }} séance<template
              v-if="result.nbSessionsGlobaleft > 1"
              >s</template
            >
          </li>
          <li>CA : {{ localizedPrice(result.sessionTotalLeftPrice) }} ttc</li>
          <li v-if="result.nbSessionsGlobaleft > 0">
            Soit
            {{
              localizedPrice(
                result.sessionTotalLeftPrice / result.nbSessionsGlobaleft
              )
            }}
            ttc / séance(s)
          </li>
          <li class="text-danger">
            Montant du aux coachs :
            <span class="text-bold"
              >{{
                localizedPrice(
                  result.discoverySessionCreditLeft +
                    result.notDiscoverySessionCreditLeft
                )
              }}
              ttc</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    localizedPrice(price) {
      return parseFloat(price / 100)
        .toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        })
        ?.replace(",", ".");
    },
  },
};
</script>

<style>
.discovery {
  color: #36ad70;
}

.pack {
  color: #255dc8;
}

.total {
  color: #18bdb4;
}
</style>

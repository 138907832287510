<template>
  <modal
    title="Créer un accès gratuit au Live"
    v-model="show"
    cancelText="Fermer"
    :okText="useInvitationCode ? 'Utiliser' : 'Créer'"
    @ok="createLiveFreeAccess"
    @cancel="close"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div v-if="error" class="alert alert-danger">{{ error }}</div>

    <div class="row">
      <div class="col-12 mb-1">
        <el-switch
          v-model="useInvitationCode"
          active-text="Code d'invitation"
          inactive-text="Manuel"
        />
      </div>

      <template v-if="useInvitationCode">
        <div class="col-12">
          <label>Code *</label>
          <input type="text" v-model="invitationCode" class="form-control" />
        </div>
      </template>

      <template v-else>
        <div class="col-12 mb-1">
          <label>Type *</label>
          <div class="input-group w-100">
            <v-select
              class="w-100"
              :options="types"
              v-model="selectedType"
              :reduce="({ value }) => value"
            ></v-select>
          </div>
        </div>
        <div class="col-12">
          <label>Valide jusqu'au *</label>
          <datepicker
            v-model="validUntil"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            :disabled="disabledDates"
          ></datepicker>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import moment from "moment-timezone";

import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";
import Loader from "../components/Loader";

export default {
  components: {
    Modal,
    Datepicker,
    Loader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    close: {
      type: Function,
      required: true,
    },

    types: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      useInvitationCode: false,
      validUntil: moment().add(1, "months").toDate(),
      disabledDates: {
        to: moment().toDate(),
      },
      selectedType: null,
      invitationCode: null,
    };
  },

  computed: {
    duration() {
      return (
        (this.validUntil &&
          moment(this.validUntil).diff(moment().startOf("day"), "days")) ||
        0
      );
    },
  },

  methods: {
    createLiveFreeAccess() {
      this.$emit("create", {
        type: this.selectedType,
        duration: this.duration,
        useInvitationCode: this.useInvitationCode,
        invitationCode: this.invitationCode?.trim(),
      });
    },
  },
};
</script>

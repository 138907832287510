<template>
  <div class="animated fadeIn">
    <div class="card" v-if="concept">
      <div class="card-header">
        <i class="icon-puzzle"></i> Concept : {{ concept.name }}
        <button
          type="button"
          class="btn btn-sm btn-danger float-right"
          @click="removeConcept()"
          v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
        >
          Supprimer
        </button>
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Description</dt>
          <dd
            class="col-sm-9 editable"
            edit="description"
            v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ concept.description }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ concept.description }}
          </dd>
          <dt class="col-sm-3">Logo</dt>
          <dd class="col-sm-9">
            <div v-if="concept.logo">
              <img :src="logo()" height="64" v-if="concept.file" />
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="removeLogo()"
                v-if="
                  hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')
                "
              >
                Supprimer
              </button>
            </div>
            <template v-else>
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="file"
                v-on:vdropzone-success="fileUploaded"
                v-if="
                  hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')
                "
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'image
                    ici</span
                  >
                </div>
              </dropzone>
            </template>
          </dd>

          <dt class="col-sm-3">Logo espace client</dt>
          <dd class="col-sm-9">
            <div v-if="concept.logoClientId">
              <img :src="logoClient()" height="64" v-if="concept.logoClient" />
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="removeLogoClient()"
                v-if="
                  hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')
                "
              >
                Supprimer
              </button>
            </div>
            <template v-else>
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="logoClient"
                v-on:vdropzone-success="logoClientUploaded"
                v-if="
                  hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')
                "
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'image
                    ici</span
                  >
                </div>
              </dropzone>
            </template>
          </dd>
          <dt class="col-sm-3">Icône</dt>
          <dd class="col-sm-9">
            <div v-if="concept.iconId">
              <img :src="icon()" height="32" v-if="concept.icon" />
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="removeIcon()"
                v-if="
                  hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')
                "
              >
                Supprimer
              </button>
            </div>
            <template v-else>
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="icon"
                v-on:vdropzone-success="iconUploaded"
                v-if="
                  hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')
                "
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'icône ici
                    - format 32x32</span
                  >
                </div>
              </dropzone>
            </template>
          </dd>
          <dt class="col-sm-3">Couleur Stripe landing page</dt>
          <dd class="col-sm-9">
            <template
              v-if="!hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            >
              <div
                :style="{
                  width: '20px',
                  height: '20px',
                  backgroundColor: concept.color,
                  border: '1px solid #eee',
                }"
              />
            </template>
            <compact
              :value="concept.color"
              @input="colorEdited"
              :read-only="
                !hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')
              "
              v-else
            />
          </dd>
          <dt class="col-sm-3">Couleur manager</dt>
          <dd class="col-sm-9">
            <template
              v-if="!hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            >
              <div
                :style="{
                  width: '20px',
                  height: '20px',
                  backgroundColor: concept.managerColor,
                  border: '1px solid #eee',
                }"
              />
            </template>
            <compact
              :value="concept.managerColor"
              @input="colorManagerEdited"
              :read-only="
                !hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')
              "
              v-else
            />
          </dd>
          <dt class="col-sm-3">Email (pour la facturation)</dt>
          <dd
            class="col-sm-9 editable"
            edit="email"
            v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ concept.email }}
          </dd>
          <dt class="col-sm-3">Lien vers le site</dt>
          <dd
            class="col-sm-9 editable"
            edit="websiteUrl"
            v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ concept.websiteUrl }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ concept.description }}
          </dd>
          <dt class="col-sm-3">Lien vers les CGV</dt>
          <dd
            class="col-sm-9 editable"
            edit="termsUrl"
            v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ concept.termsUrl }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ concept.description }}
          </dd>
        </dl>
      </div>
      <div class="card-footer">
        Identifiant API : <strong>{{ concept.name }}</strong>
      </div>
    </div>

    <div class="card" v-if="concept">
      <div class="card-header">
        <i class="icon-paper-plane"></i> Réglages des templates Sendgrid
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Mot de passe perdu</dt>
          <dd
            class="col-sm-9 editable"
            edit="reset"
            v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            :contenteditable="true"
            v-on:blur="sendgridEdited"
          >
            <span v-if="concept.sendgrid">{{ concept.sendgrid.reset }}</span>
          </dd>
          <dd class="col-sm-9" v-else>
            {{ concept.description }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Nouvelle inscription</dt>
          <dd
            class="col-sm-9 editable"
            edit="welcome"
            v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            :contenteditable="true"
            v-on:blur="sendgridEdited"
          >
            <span v-if="concept.sendgrid">{{ concept.sendgrid.welcome }}</span>
          </dd>
          <dd class="col-sm-9" v-else>
            {{ concept.description }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Facture</dt>
          <dd
            class="col-sm-9 editable"
            edit="invoice"
            v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
            :contenteditable="true"
            v-on:blur="sendgridEdited"
          >
            <span v-if="concept.sendgrid">{{ concept.sendgrid.invoice }}</span>
          </dd>
          <dd class="col-sm-9" v-else>
            {{ concept.description }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Dropzone from "vue2-dropzone";
import { Compact } from "vue-color";

export default {
  components: {
    Dropzone,
    Compact,
  },
  data() {
    return {
      concept: null,
      form: {},
    };
  },
  async created() {
    await this.loadConcept();
  },

  methods: {
    edited: async function (event) {
      const data = {
        name: this.concept.name,
      };

      if (event.target && event.target.hasAttribute("edit")) {
        var edit = event.target.getAttribute("edit");
        data[edit] = event.target.innerHTML.replace(/(<([^>]+)>)/gi, "");
        event.target.innerHTML = data[edit];
      }

      await this.updateConcept(data);
      await this.loadConcept();
    },
    sendgridEdited: async function (event) {
      let data = {
        sendgrid: {},
      };
      let edit = event.target.getAttribute("edit");
      data.sendgrid[edit] = event.target.innerHTML.replace(/(<([^>]+)>)/gi, "");
      event.target.innerHTML = data.sendgrid[edit];

      await this.updateConcept(data);
      await this.loadConcept();
    },
    colorEdited: async function (event) {
      await this.updateConcept({ color: event.hex });
    },

    colorManagerEdited: async function (event) {
      await this.updateConcept({ managerColor: event.hex });
    },

    logo() {
      return `data:${this.concept.file.mimetype};base64,${this.concept.file.data}`;
    },

    logoClient() {
      return `data:${this.concept.file.mimetype};base64,${this.concept.logoClient.data}`;
    },

    icon() {
      return `data:${this.concept.icon.mimetype};base64,${this.concept.icon.data}`;
    },

    removeLogo() {
      this.concept.logo = null;
    },

    removeLogoClient() {
      this.concept.logoClientId = null;
    },

    removeIcon() {
      this.concept.iconId = null;
    },

    apiUploads() {
      return `${axios.defaults.baseURL}uploads`;
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    async fileUploaded(file, response) {
      await this.updateConcept({ logo: response.id });
      await this.loadConcept();
    },

    async logoClientUploaded(file, response) {
      await this.updateConcept({ logoClientId: response.id });
      await this.loadConcept();
    },

    async iconUploaded(file, response) {
      await this.updateConcept({ iconId: response.id });
      await this.loadConcept();
    },

    async updateConcept(data) {
      data.name = this.concept.name;

      try {
        await this.$api.put(`/concepts`, data);
      } catch (e) {
        console.log(e);
      }
    },

    async loadConcept() {
      if (!this.$route.params.id) {
        return;
      }

      const { data } = await this.$api
        .get(`/concepts/${this.$route.params.id}`)
        .catch(() => {
          this.$router.push({
            name: "concepts",
          });
        });

      this.concept = data;

      this.concept.color = this.concept.color || "#000000";
      this.concept.managerColor = this.concept.managerColor || "#000000";
    },

    removeConcept() {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.delete("/concepts", {
            data: {
              name: this.concept.name,
            },
          });

          this.$router.push({
            name: "concepts",
          });

          window.location.reload(true);
        },
      });
    },
  },
};
</script>

<template>
  <el-table :data="courses" @sort-change="sortChange" style="width: 100%">
    <el-table-column
      align="center"
      prop="name"
      label="Cours"
      width="180"
      sortable
    >
    </el-table-column>
    <el-table-column align="center" label="Photo">
      <template slot-scope="scope">
        <img class="course-image" :src="getPictureImage(scope.row.id)" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="type" label="Mode" sortable>
      <template slot-scope="scope">
        {{ scope.row.type === "small" ? "Visio" : "Live" }}
      </template>
    </el-table-column>
    <el-table-column align="center" prop="date" label="Départ" sortable>
      <template slot-scope="scope">
        <span class="date-cell">
          <div>
            <strong>{{ getDay(scope.row.date) }}</strong>
          </div>
          {{ dateFormat(scope.row.date) }}</span
        >
      </template>
    </el-table-column>
    <el-table-column align="center" label="Fin">
      <template slot-scope="scope">
        <span class="date-cell" v-if="scope.row.recurrence != 1">{{
          dateFormat(scope.row.endDate)
        }}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="hour" label="Horaire">
      <template slot-scope="scope">
        {{ scope.row.hour }}:{{ displayMinutes(scope.row.minutes) }}
      </template>
    </el-table-column>
    <el-table-column align="center" prop="duration" label="Durée">
      <template slot-scope="scope"> {{ scope.row.duration }}min </template>
    </el-table-column>
    <el-table-column align="center" label="Coachs">
      <template slot-scope="scope">
        <div v-for="(coach, index) of showCoachs(scope.row)" :key="index">
          {{ coach }}
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="recurrence" label="Récurrence">
    </el-table-column>
    <el-table-column align="center" prop="group.name" label="Groupe">
    </el-table-column>
    <el-table-column align="center" prop="quotas" label="Quotas">
    </el-table-column>
    <el-table-column
      align="center"
      label="Publié"
      :filters="[
        { text: 'Publié', value: true },
        { text: 'Non publié', value: false },
      ]"
      :filter-method="filterPublished"
    >
      <template slot-scope="scope">
        <label 
          class="switch switch-sm switch-text switch-info mb-0"
          v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
        >
          <input
            type="checkbox"
            class="switch-input"
            v-model="scope.row.published"
            @click="publishCourse(scope.row)"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>
        <span v-else>{{scope.row.published ? "Oui" : "Non"}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center">
      <template slot-scope="scope">
        <div class="no-ellipsis">
          <router-link
            v-if="hasPermission($store.state.user, 'ORDERS_READ')"
            target="_blank"
            :to="{ name: 'order-details', params: { id: scope.row.orderId } }"
          >
            <button class="btn btn-info btn-sm">
              <i class="icon-basket"></i>
            </button>
          </router-link>
          <button
            class="btn btn-default btn-sm"
            @click="editCourse(scope.row)"
            v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
          >
            <i class="fa fa-pencil"></i>
          </button>
          <button
            v-if="
              !isArchive && hasPermission($store.state.user, 'LIVE_MENU_WRITE')
            "
            class="btn btn-warning btn-sm"
            @click="archiveCourse(scope.row)"
          >
            <i class="fa fa-inbox"></i>
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteCourse(scope.row.id)"
            v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import moment from "moment";

export default {
  props: {
    courses: {
      type: Array,
      default: () => [],
    },

    isArchive: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getPictureImage(id) {
      return `${process.env.VUE_APP_API_URL}lives/${id}/photo`;
    },

    getDay(date) {
      const options = { weekday: "long" };

      return new Date(date).toLocaleString("fr-FR", options);
    },

    dateFormat(date, recurence = null) {
      const objDate = new Date(date);

      if (null !== recurence) {
        objDate.setDate(objDate.getDate() + parseInt(7) * recurence);
      }

      return moment(String(objDate)).format("DD/MM/YYYY");
    },

    showCoachs(live) {
      const coachs = [];
      const ids = [];

      if (live.order && live.order.sessions) {
        live.order.sessions.forEach((session) => {
          if (session.coachs && session.coachs.length > 0) {
            session.coachs.map((c) => {
              if (!ids.includes(c.id)) {
                coachs.push(c.coach.name);
                ids.push(c.id);
              }
            });
          }
        });
      }

      return coachs;
    },

    async publishCourse(live) {
      if (live.published) {
        await this.$api.post("/lives/unpublish", { id: live.id });
        live.published = false;
      } else {
        await this.$api.post("/lives/publish", { id: live.id });
        live.published = true;
      }
    },

    editCourse(course) {
      this.$emit("edit", course);
    },

    deleteCourse(id) {
      this.$emit("delete", id);
    },

    archiveCourse(course) {
      this.$emit("archive", course.id);
    },

    filterPublished(value, row) {
      return row.published === value;
    },

    sortChange(sortBy) {
      this.$emit("sortChange", sortBy);
    },
  },
};
</script>

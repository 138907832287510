<template>
  <div class="wrapper">
    <Tabs v-model="activeTab" @input="changeTab" class="empty-tab">
      <Tab
        :key="`tab-${index}`"
        v-for="(item, index) of tabs"
        :header="item.label"
      >
      </Tab>
    </Tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import { mapState } from "vuex";

export default {
  components: {
    Tabs,
    Tab,
  },

  data() {
    return {
      activeTab: 0,
      tabs: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },

  async created() {
    if (await this.hasPermission(this.user, "INVOICES_READ")) {
      this.tabs.push({ label: "Factures", name: "invoices" });
    }

    if (this.hasPermission(this.user, "PAYMENTS_READ")) {
      this.tabs.push({ label: "Paiements", name: "payments" });
    }

    if (this.hasPermission(this.user, "PAYOUTS_READ")) {
      this.tabs.push({ label: "Stripe", name: "payouts" });
    }

    if (this.hasPermission(this.user, "UNPAID_READ")) {
      this.tabs.push({ label: "Impayés", name: "unpaid" });
    }

    if (this.$route.name === "billing" && this.tabs.length > 0) {
      this.$router.push({ name: this.tabs[0].name });
    }

    this.setCurrentTab(this.$route.name);
  },
  methods: {
    changeTab(index) {
      this.$router.push({ name: this.tabs[index].name });
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.tabs.findIndex((tab) => tab.name === currentPath);
    },
  },
  watch: {
    $route(current) {
      if (current.name === "billing") {
        this.$router.push({ name: this.tabs[0].name });
      }

      this.setCurrentTab(current.name);
    },
  },
};
</script>

<style lang="scss">
.empty-tab {
  .nav.nav-tabs {
    margin-bottom: 15px;
  }

  & > .tab-content {
    display: none;
  }
}
</style>

<template>
  <modal
    :title="modalTitle"
    v-model="show"
    cancelText="Fermer"
    okText="Enregistrer"
    @cancel="close"
    @ok="editGroupReferent"
    effect="fade/zoom"
  >
    <div v-if="error" class="alert alert-danger">{{ error }}</div>

    <div class="form-group">
      <label>Référent</label>
      <v-select
        v-model="referent"
        :options="clientOptions"
        :reduce="({ value }) => value"
      ></v-select>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    group: {
      type: Object,
      required: true,
    },

    clients: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      referent: null,
      error: null,
    };
  },

  computed: {
    clientOptions() {
      return this.clients.map(({ client }) => ({
        label: `${client.fullName} (${client.email})`,
        value: client.email,
      }));
    },

    modalTitle() {
      return `Référent ${this.group ? this.group.name : ""}`;
    },
  },

  watch: {
    show(opened) {
      if (opened && this.group.referent) {
        const referentEmail = this.group.referentEmail;

        this.referent = referentEmail;
      }
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async editGroupReferent() {
      if (!this.group) return;

      this.error = null;

      if (!this.referent) {
        this.error = "Veuillez sélectionner un client";
        return;
      }

      if (this.referent === this.group.referentEmail) {
        this.close();
        return;
      }

      try {
        await this.$api.put(`/groups/${this.group.id}/referent`, {
          referent: this.referent,
        });

        this.$emit("edited", this.referent);
      } catch (e) {
        this.error = "Impossible de modifier le client référent";
      }
    },
  },
};
</script>

<template>
  <div class="animated fadeIn">
    <StatsSponsorshipFilters :defaultDates="dates" @updateDate="updateDates" />

    <div class="card">
      <div class="card-header"><i class="fa fa-bullhorn"></i> Parrainages</div>

      <div
        v-if="concepts.length === 0"
        class="card-block text-center text-muted"
      >
        Aucun parrainage au cours de cette période
      </div>
      <div v-else class="card-block pt-3">
        <div class="row d-flex justify-content-around flex-wrap">
          <div
            v-for="concept in concepts"
            :key="concept.name"
            class="card col-10 col-md-5 col-xl-3 text-center p-2"
          >
            <div class="font-weight-bold mb-1">{{ concept.name }}</div>
            <div>
              Parrainages:
              <span class="font-weight-bold">{{
                concept.sponsorshipsTotal
              }}</span>
            </div>
            <div>
              CA généré:
              <span class="font-weight-bold">{{
                formatPrice(concept.sponsorshipsCA, true)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import StatsSponsorshipFilters from "../components/StatsSponsorshipFilters";

export default {
  components: {
    StatsSponsorshipFilters,
  },

  data() {
    return {
      concepts: [],
      dates: {
        begin: moment().startOf("M").format(),
        end: moment().endOf("M").format(),
      },
    };
  },

  async created() {
    await this.loadSponsorshipsStats();
  },

  methods: {
    async loadSponsorshipsStats() {
      const { begin, end } = this.dates;

      try {
        const { data } = await this.$api.get("/sponsorships/stats", {
          params: { begin, end },
        });

        this.concepts = data;
      } catch (e) {
        console.error(e);
      }
    },

    async updateDates(dates) {
      this.dates = dates;

      await this.loadSponsorshipsStats();
    },
  },
};
</script>

<template>
  <div>
    <el-table :data="currentTags" style="width: 100%">
      <el-table-column prop="label" label="Label" sortable> </el-table-column>
      <el-table-column prop="code" label="Code" sortable> </el-table-column>

      <el-table-column
        align="center"
        width="150"
        v-if="hasPermission($store.state.user, 'FAQ_WRITE')"
      >
        <template slot-scope="scope">
          <button
            class="btn btn-default btn-sm"
            @click="handleEditTag(scope.row)"
          >
            <i class="fa fa-pencil"></i>
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="handleDeleteTag(scope.row)"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentTags: [],
    };
  },

  mounted() {
    this.currentTags = this.tags;
  },

  methods: {
    handleDeleteTag(tag) {
      this.$confirm({
        message: "Êtes vous sûr de vouloir supprimer cette catégorie ?",
        onConfirm: async () => {
          await this.$api.delete(`/faq/tags/${tag.id}`);

          this.$emit("delete");
        },
      });
    },

    async handleEditTag(tag) {
      this.$emit("edit", tag);
    },
  },

  watch: {
    tags: {
      deep: true,
      handler() {
        this.currentTags = this.tags;
      },
    },
  },
};
</script>

<style></style>

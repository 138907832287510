<template>
  <div>
    <div class="card">
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-3">
            <label>Date de début</label>
            <el-popover trigger="hover" placement="top" :width="500">
              <div class="p-1 text-break">
                <p>
                  L'intervalle de date permet de filtrer uniquement les coachs
                  étant référent sur au moins 1 commande payée dans l'intervalle
                  de date.
                </p>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
            <datepicker
              v-model="filters.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>Date de fin</label>
            <datepicker
              v-model="filters.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>Société</label>
            <multiselect
              v-model="filters.merchants"
              :options="merchantsOptions"
              label="label"
              track-by="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-3">
            <label>Concept</label>
            <multiselect
              v-model="filters.concepts"
              :options="conceptOptions"
              label="label"
              track-by="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-2">
            <label>Statut(s)</label>
            <multiselect
              v-model="filters.status"
              :options="statusOptions"
              label="label"
              track-by="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-2">
            <label>Prestation(s)</label>
            <multiselect
              v-model="filters.prestations"
              :options="prestationsOptions"
              group-values="libs"
              group-label="groupeName"
              :group-select="true"
              track-by="label"
              label="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-2">
            <label>Département(s)</label>
            <multiselect
              v-model="filters.departments"
              :options="departmentsOptions"
              group-values="libs"
              group-label="groupeName"
              :group-select="true"
              track-by="label"
              label="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-2">
            <label>Langue(s)</label>
            <multiselect
              v-model="filters.languages"
              :options="languagesOptions"
              group-values="libs"
              group-label="groupeName"
              :group-select="true"
              track-by="label"
              label="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-2">
            <label>Tag(s)</label>
            <multiselect
              v-model="filters.tags"
              :options="tagsOptions"
              group-values="libs"
              group-label="groupeName"
              :group-select="true"
              track-by="label"
              label="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-2">
            <label>Genre(s)</label>
            <multiselect
              v-model="filters.genders"
              :options="gendersOptions"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-2">
            <label>Rechercher un prestataire</label>
            <input
              class="form-control"
              v-model="filters.search"
              placeholder="Nom, Prénom"
              @input="$emit('update-dynamic', filters)"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button class="btn btn-secondary" @click="openSettingModal">
              <i class="icon-settings"></i> Paramètres
            </button>
            <button class="btn btn-primary ml-2" @click="applyFilters">
              <i class="icon-refresh"></i> Calculer
            </button>
          </div>
          <div class="col-sm-8 text-right">
            <p class="mb-0">
              <i class="fa fa-info-circle mr-05"></i> Les filtres par défaut
              ci-dessus détermine la pertinence des résultats du classement.
            </p>
            <p class="mb-0">
              <i class="fa fa-info-circle mr-05"></i> Les coachs listés
              ci-dessous sont les coachs référent d'au moins 1 commande dans la
              période sélectionnée.
            </p>
            <p>
              Ils sont donc différent de ceux présent dans le menu prestataire
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
  components: {
    Datepicker,
    Multiselect,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: [],
      conceptOptions: [],
      merchantsOptions: [],
      prestationsOptions: [],
      departmentsOptions: [],
      languagesOptions: [],
      tagsOptions: [],
      gendersOptions: ["Homme", "Femme"],
      statusOptions: [
        { label: "Actif", value: "active" },
        { label: "Inactif", value: "inactive" },
        { label: "Bloqué", value: "blocked" },
      ],
    };
  },

  async created() {
    this.filters = this.default;

    await this.getConcepts();
    await this.getMerchants();
    await this.loadSettings();
    await this.loadDepartments();
    await this.loadLanguages();
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
      languages: "getLanguages",
    }),
  },

  methods: {
    applyFilters() {
      this.$emit("update", { ...this.filters });
    },

    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.conceptOptions = data.map((c) => {
        return { label: c.name, value: c.name };
      });
    },

    async getMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantsOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },

    async loadSettings() {
      const { data } = await this.$api.get("/settings");

      this.prestationsOptions = [
        {
          groupeName: "Tous",
          libs: data["intervenants_prestations"].map((p) => {
            return { label: p, value: p };
          }),
        },
      ];

      this.tagsOptions = [
        {
          groupeName: "Tous",
          libs: data["intervenants_tags"].map((p) => {
            return { label: p, value: p };
          }),
        },
      ];
    },

    async loadDepartments() {
      this.departmentsOptions = [
        {
          groupeName: "Tous",
          libs: this.departments
            .map(({ code, name }) =>
              code !== "all"
                ? { label: code + " - " + name, value: code }
                : null
            )
            .filter((d) => d),
        },
      ];
    },

    async loadLanguages() {
      this.languagesOptions = [
        {
          groupeName: "Tous",
          libs: this.languages.map(({ code, name }) => {
            return { label: code + " - " + name, value: name };
          }),
        },
      ];
    },

    openSettingModal() {
      this.$emit("open-settings");
    },
  },
};
</script>

<style lang="scss" scoped>
.control-label {
  width: 100%;

  span {
    float: right;
  }
}
</style>

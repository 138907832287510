<template>
  <div class="animated fadeIn">
    <Loader :is-visible="loading" />

    <LostFilters
      :commercials="commercials"
      :lostReasons="lostReasons"
      show-sources
      filter-commercials
      select-client-type
      @apply="applyFilters"
    />

    <tabs v-if="!loading && (clients || prospects)">
      <tab header="Prospects / Clients">
        <StatsFranceMap :data="mapData" stats="lost" color-scheme="red" />
      </tab>
      <tab header="Par source">
        <StatsLostMapSource :data="lostMapData" color-scheme="red" />
      </tab>
    </tabs>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import LostFilters from "../components/LostFilters";
import StatsFranceMap from "../components/StatsFranceMap";
import StatsLostMapSource from "../components/StatsLostMapSource";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

import { mapGetters } from "vuex";

export default {
  components: {
    Loader,
    LostFilters,
    StatsFranceMap,
    StatsLostMapSource,
    Tabs,
    Tab,
  },

  data() {
    return {
      loading: false,
      clients: null,
      prospects: null,
      commercials: [],
      filters: {
        orderType: null,
        dates: {
          begin: null,
          end: null,
        },
        commercials: null,
        utm_sources: null,
        utm_campaigns: null,
        utm_contents: null,
      },
      defaultData: {
        total: 0,
        totalPercentage: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
      lostReasons: "getLostReasons",
    }),

    lost() {
      let lost = [];

      if (this.clients) {
        lost = [
          ...this.clients.map((client) => {
            if (client.lostReason) {
              const reason = this.lostReasons.find(
                ({ value }) => client.lostReason === value
              );

              if (reason) {
                client.lostReason = reason;
              } else {
                client.lostReason = {
                  value: "no-reason",
                  label: "Aucune raison",
                  color: "#d3d3d3",
                };
              }
            } else {
              client.lostReason = {
                value: "no-reason",
                label: "Aucune raison",
                color: "#d3d3d3",
              };
            }

            return client;
          }),
        ];
      }

      if (this.prospects) {
        lost = [
          ...lost,
          ...this.prospects.map((prospect) => {
            if (prospect.prospect.lostReason) {
              const reason = this.lostReasons.find(
                ({ value }) => prospect.prospect.lostReason === value
              );

              if (reason) {
                prospect.lostReason = reason;
              } else {
                prospect.lostReason = {
                  value: "no-reason",
                  label: "Aucune raison",
                  color: "#d3d3d3",
                };
              }
            } else {
              prospect.lostReason = {
                value: "no-reason",
                label: "Aucune raison",
                color: "#d3d3d3",
              };
            }

            return prospect;
          }),
        ];
      }

      return lost;
    },

    displayedDepartments() {
      const domTom = ["971", "972", "973", "974", "976"];
      const metroDepartments = this.departments.filter(
        (dpt) => !domTom.includes(dpt.code) && dpt.code !== "all"
      );

      return metroDepartments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    lostMapData() {
      const departments = [
        ...this.displayedDepartments.map((dpt) => ({
          ...dpt,
          data: this.defaultData,
        })),
      ];

      if (!this.loading) {
        departments.forEach((dpt) => {
          dpt.data = this.getDepartmentSourceLostInfo(dpt.value);
        });
      }

      return departments;
    },

    mapData() {
      const departments = [
        ...this.displayedDepartments.map((dpt) => ({
          ...dpt,
          data: this.defaultData,
        })),
      ];

      if (!this.loading) {
        departments.forEach((dpt) => {
          dpt.data = this.getDepartmentLostInfo(dpt.value);
        });
      }

      return departments;
    },
  },

  created() {
    this.getCommercials();
  },

  methods: {
    async loadLostClients() {
      const { begin, end } = this.filters.dates;
      const {
        orderType,
        commercials,
        lostReasons,
        utm_sources,
        utm_campaigns,
        utm_contents,
      } = this.filters;

      try {
        const { data } = await this.$api.get("/clients/lost", {
          params: {
            begin,
            end,
            orderType,
            commercials,
            lostReasons,
            utm_sources,
            utm_campaigns,
            utm_contents,
          },
        });

        this.clients = data.lostClients;
      } catch (e) {
        this.clients = [];
      }
    },

    async loadLostProspects() {
      const { begin, end } = this.filters.dates;
      const {
        orderType,
        commercials,
        lostReasons,
        utm_sources,
        utm_campaigns,
        utm_contents,
      } = this.filters;

      try {
        const { data } = await this.$api.get("/prospects/lost", {
          params: {
            begin,
            end,
            orderType,
            commercials,
            lostReasons,
            utm_sources,
            utm_campaigns,
            utm_contents,
          },
        });

        this.prospects = data.lostProspects;
      } catch (e) {
        this.prospects = [];
      }
    },

    async getCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((c) => ({
          value: c.email,
          label: c.commercial?.pseudo || c.firstName,
          color: c.commercial?.color,
        }));
      } catch (e) {
        this.commercials = [];
      }
    },

    async applyFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters,
      };

      this.loading = true;

      if (!this.filters.clientType || this.filters.clientType === "clients") {
        await this.loadLostClients();
      } else {
        this.clients = [];
      }

      if (!this.filters.clientType || this.filters.clientType === "prospects") {
        await this.loadLostProspects();
      } else {
        this.prospects = [];
      }

      this.loading = false;
    },

    getDepartmentSourceLostInfo(dpt) {
      const departmentLost = this.lost.filter((client) => {
        if (client.zipcode && typeof client.zipcode === "string") {
          return client.zipcode.slice(0, 2) === dpt;
        }
      });

      const total = departmentLost.length;
      let totalPercentage = (total / this.lost.length) * 100;

      if (isNaN(totalPercentage)) {
        totalPercentage = 0;
      }

      const reasons = departmentLost.reduce((a, p) => {
        if (!p.marketing || !p.marketing.utm_source) {
          a["no-utm"] = a["no-utm"] || [];
          a["no-utm"].push(p);

          return a;
        }
        a[p.marketing.utm_source] = a[p.marketing.utm_source] || [];
        a[p.marketing.utm_source].push(p);

        return a;
      }, Object.create(null));

      return { total, totalPercentage, reasons };
    },

    getDepartmentLostInfo(dpt) {
      const departmentLost = this.lost.filter((client) => {
        if (client.zipcode && typeof client.zipcode === "string") {
          return client.zipcode.slice(0, 2) === dpt;
        }
      });

      const total = departmentLost.length;
      let totalPercentage = (total / this.lost.length) * 100;

      if (isNaN(totalPercentage)) {
        totalPercentage = 0;
      }

      const reasons = [];

      for (const lost of departmentLost) {
        const reason = reasons.find(
          ({ value }) => value === lost.lostReason.value
        );

        if (reason) {
          reason.total = reason.total ? ++reason.total : 1;
        } else {
          reasons.push({ ...lost.lostReason, total: 1 });
        }
      }

      for (const reason of reasons) {
        let percentage = (reason.total / total) * 100;

        if (isNaN(percentage)) {
          percentage = 0;
        }

        reason.percentage = percentage.toFixed(2);
        reason.name = `${reason.label} (${reason.total}) (${reason.percentage}%)`;
      }

      return { total, totalPercentage, reasons };
    },
  },
};
</script>

<template>
  <div class="animated fadeIn">
    <el-popover trigger="click" placement="top" :width="700">
      <div class="row">
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-12">
              <h4>{{ name }}</h4>
              <hr />
            </div>
            <div class="col-md-3 text-center">
              <strong>{{ row.result }} pack(s)</strong>
              <Doughnut :options="options" :chart-data="chartData" />
            </div>
            <div class="col-md-4 d-flex flex-column">
              <div>
                <span class="pills blue">
                  {{ getNbConversion }} Conversion(s)
                </span>
              </div>
              <div class="mt-2">
                <span class="pills green">
                  {{ getNbFidelisation }} Fidélisation(s)
                </span>
              </div>
            </div>
            <div class="col-md-4 d-flex flex-column">
              <div>
                <span class="pills red"
                  >{{ getNbOneShotEvent }} One shot(s)</span
                >
              </div>
              <div class="mt-2">
                <span class="pills violet"> {{ getNbOther }} Autre(s) </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="divider" />
      <div class="row">
        <div class="col-md-12">
          <el-table :data="row.details" style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="row">
                  <div
                    class="col-md-6 mt-1"
                    v-if="scope.row.conversions.length > 0"
                  >
                    <h5>Conversion</h5>
                    <span
                      class="pills blue mr-1"
                      v-for="conversion in scope.row.conversions"
                      :key="conversion.id"
                      @click="goToOrder(conversion.id)"
                    >
                      {{ formatDate(conversion.date) }}
                    </span>
                  </div>
                  <div
                    class="col-md-6 mt-1"
                    v-if="scope.row.oneShotsEvents.length > 0"
                  >
                    <h5>OneShot</h5>
                    <span
                      class="pills red mr-1"
                      v-for="oneShotEvent in scope.row.oneShotsEvents"
                      :key="oneShotEvent.id"
                      @click="goToOrder(oneShotEvent.id)"
                    >
                      {{ formatDate(oneShotEvent.date) }}
                    </span>
                  </div>
                  <div
                    class="col-md-6 mt-1"
                    v-if="scope.row.fidelisations.length > 0"
                  >
                    <h5>Fidélisation</h5>
                    <span
                      class="pills green mr-1"
                      v-for="fidelisation in scope.row.fidelisations"
                      :key="fidelisation.id"
                      @click="goToOrder(fidelisation.id)"
                    >
                      {{ formatDate(fidelisation.date) }}
                    </span>
                  </div>
                  <div class="col-md-6 mt-1" v-if="scope.row.others.length > 0">
                    <h5>Autre</h5>
                    <span
                      class="pills violet mr-1"
                      v-for="other in scope.row.others"
                      :key="other.id"
                      @click="goToOrder(other.id)"
                    >
                      {{ formatDate(other.date) }}
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="fullname">
              <template slot-scope="scope">
                <a href="#" @click="(e) => goToClient(e, scope.row.id)">{{
                  scope.row.fullname
                }}</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="reference" class="name-wrapper">
        <span v-if="row.result"> #{{ row.rank }} - </span>
        {{ row.result }} pack<template v-if="row.result > 1">s</template>
      </div>
    </el-popover>
  </div>
</template>

<script>
import Doughnut from "./Doughnut.vue";
export default {
  components: {
    Doughnut,
  },

  props: {
    row: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      options: {
        responsive: true,
        animation: {
          animateRotate: false,
        },
        legend: {
          display: false,
        },
        cutoutPercentage: 75,
      },
    };
  },

  computed: {
    getNbConversion() {
      return this.row.details
        .map((d) => d.conversions.length)
        .reduce((a, b) => a + b, 0);
    },

    getNbFidelisation() {
      return this.row.details
        .map((d) => d.fidelisations.length)
        .reduce((a, b) => a + b, 0);
    },

    getNbOneShotEvent() {
      return this.row.details
        .map((d) => d.oneShotsEvents.length)
        .reduce((a, b) => a + b, 0);
    },

    getNbOther() {
      return this.row.details
        .map((d) => d.others.length)
        .reduce((a, b) => a + b, 0);
    },

    chartData() {
      return {
        labels: ["Convertis", "Perdu"],
        datasets: [
          {
            backgroundColor: ["#255dc8", "#18bdb4", "#dc2626", "#7c3aed"],
            data: [
              this.getNbConversion,
              this.getNbFidelisation,
              this.getNbOneShotEvent,
              this.getNbOther,
            ],
          },
        ],
      };
    },
  },

  methods: {
    goToClient(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },

    goToOrder(id) {
      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: id,
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.pills {
  border-radius: 100px;
  background-color: #f5f5f5;
  padding: 3px 20px;
  cursor: pointer;
  word-break: break-word;
  display: inline-block;
}

.blue {
  background-color: #255dc8;
  color: white;
}

.green {
  background-color: #18bdb4;
  color: white;
}

.red {
  background-color: #dc2626;
  color: white;
}

.violet {
  background-color: #7c3aed;
  color: white;
}

.divider {
  width: 100%;
  border-top: 1px solid #131721;
}
</style>

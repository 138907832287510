<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-refresh"></i> Filtrer
      <div class="float-right">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          @click="openModal()"
        >
          <i class="icon-plus"></i> Ajouter
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-md-2 col-sm-4">
          <label class="control-label">Année</label>
          <select
            class="form-control"
            v-model="filters.year"
            @change="applyFilters"
          >
            <option :value="null"></option>
            <option :key="year" v-for="year of filterYears" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label class="control-label">Mois</label>
          <select
            class="form-control"
            v-model="filters.month"
            @change="applyFilters"
          >
            <option :value="null"></option>
            <option
              :key="month.value"
              v-for="month of filterMonths"
              :value="month.value"
            >
              {{ month.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label class="control-label">
            Type de dépense
            <span
              v-if="filters.type"
              v-tooltip="'Supprimer le type selectionné'"
              class="fa fa-trash-o"
              @click="deleteType()"
            ></span>
          </label>
          <select
            class="form-control"
            v-model="filters.type"
            @change="applyFilters"
          >
            <option :value="null"></option>
            <option
              :key="expenseType.id"
              v-for="expenseType of filterTypes"
              :value="expenseType.type"
            >
              {{ expenseType.type }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label class="control-label">Société</label>
          <v-select
            v-model="filters.company"
            :options="filterCompanys"
            @click.native="checkIfEmpty"
            @input="updateConcepts"
          ></v-select>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>Concept</label>
          <v-select v-model="filters.concept" :options="filterConcepts">
            <span slot="no-options">Sélectionnez une option pour Société.</span>
          </v-select>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label class="control-label">
            Nom de dépense
            <span
              v-if="filters.name"
              v-tooltip="'Supprimer le nom selectionné'"
              class="fa fa-trash-o"
              @click="deleteName()"
            ></span
          ></label>
          <select
            class="form-control"
            v-model="filters.name"
            @change="applyFilters"
          >
            <option :value="null"></option>
            <option
              :key="name.id"
              v-for="name of filterNames"
              :value="name.name"
            >
              {{ name.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label class="control-label">Montant</label>
          <div class="row">
            <div class="col-sm-6">
              <input
                type="text"
                v-model="filters.min"
                placeholder="Min."
                class="form-control"
                @input="applyFilters"
              />
            </div>
            <div class="col-sm-6">
              <input
                type="text"
                v-model="filters.max"
                placeholder="Max."
                class="form-control"
                @input="applyFilters"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-sm btn-secondary"
          @click="applyFilters"
        >
          <i class="icon-eye"></i> Visualiser
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    default: {
      type: Object,
      required: true,
    },
    filterTypes: {
      type: Array,
      required: true,
    },
    filterCompanys: {
      type: Array,
      required: true,
    },
    filterConcepts: {
      type: Array,
      required: true,
    },
    filterNames: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      filters: {},
      filterYears: [],
      filterMonths: [
        {
          value: 1,
          name: "Janvier",
        },
        {
          value: 2,
          name: "Février",
        },
        {
          value: 3,
          name: "Mars",
        },
        {
          value: 4,
          name: "Avril",
        },
        {
          value: 5,
          name: "Mai",
        },
        {
          value: 6,
          name: "Juin",
        },
        {
          value: 7,
          name: "Juillet",
        },
        {
          value: 8,
          name: "Aout",
        },
        {
          value: 9,
          name: "Septembre",
        },
        {
          value: 10,
          name: "Octobre",
        },
        {
          value: 11,
          name: "Novembre",
        },
        {
          value: 12,
          name: "Decembre",
        },
      ],
      filterAmounts: [],
      concepts: [],
    };
  },

  async created() {
    this.filters = this.default;

    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 10;

    for (let year = startingYear; year <= currentYear; year++) {
      this.filterYears.push(year);
    }

    this.filterYears.sort((a, b) => b - a);
  },

  methods: {
    openModal() {
      this.$emit("openModal");
    },

    applyFilters() {
      this.$emit("update", { ...this.filters });
    },

    deleteType() {
      this.$emit("deleteType", this.filters.type);
    },

    deleteName() {
      this.$emit("deleteName", this.filters.name);
    },

    checkIfEmpty() {
      if (!this.filters.company) {
        this.concepts = [];
        this.filters.concept = null;
      }
    },

    async updateConcepts() {
      this.$emit("updateConcepts", this.filters.company);
    },
  },
};
</script>

<style lang="scss" scoped>
.control-label {
  width: 100%;

  span {
    float: right;
  }
}
</style>

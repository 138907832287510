<template>
  <div class="row">

    <div class="col-12 col-md-6 mt-2">
      <div class="mt-2">
        <h2 class="font-weight-bold">Winner</h2>
        <div class="d-flex flex-wrap justify-content-around mt-2">
          <div 
            class="d-flex flex-column align-items-center"
            v-for="(coach) of getTopThree()"
            :key="coach.id"
          >
            <div class="pictureContent">
              <div id="userPicture" v-if="coach.picture">
                <img
                  :src="getPictureUrl(coach.id)"
                  class="rounded"
                />
              </div>
              <div v-else class="defaultPicture">
                <i class="fa fa-user"></i>
              </div>
              <span class="ranking d-flex align-items-center justify-content-center">{{ coach.rank }}</span>
            </div>
            
            <span class="mt-2 font-weight-bold">{{ coach.name }}</span>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <h2 class="font-weight-bold">Moins bon</h2>
        <div class="d-flex flex-wrap justify-content-around mt-2">
          <div 
            class="d-flex flex-column align-items-center"
            v-for="(coach) of getBottomThree()"
            :key="coach.id"
          >
          <div class="pictureContent">
              <div id="userPicture" v-if="coach.picture">
                <img
                  :src="getPictureUrl(coach.id)"
                  class="rounded"
                />
              </div>
              <div v-else class="defaultPicture">
                <i class="fa fa-user"></i>
              </div>
              <span class="ranking d-flex align-items-center justify-content-center">{{ coach.rank }}</span>
            </div>
            <span class="mt-2 font-weight-bold">{{ coach.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <el-table :data="data" stripe max-height="800">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="row">
              <div class="col-md-12">
                <el-table :data="scope.row.comments" style="width: 100%">
                  <el-table-column prop="type" width="50">
                    <template slot-scope="comment">
                      <i v-if="comment.row.type == -1" class="fa fa-thumbs-down" />
                      <i v-if="comment.row.type == 1" class="fa fa-thumbs-up" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Label" prop="label" />
                  <el-table-column label="Commentaire" prop="text" />
                </el-table>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Classement" prop="points" sortable>
          <template slot-scope="scope">
            <span>
              #{{scope.row.rank}}/{{data.length}} ({{ scope.row.points }} point{{scope.row.points > 1 ? 's' : ''}})
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Nom" prop="name" sortable>
          <template slot-scope="scope">
            <span class="user-link" @click="goToCoach(scope.row.id)">
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Note">
          <template slot-scope="scope">
            <div class="d-fex">
              <span v-if="getPositiveComments(scope.row.comments).length > 0">
                <i class="fa fa-thumbs-up ml-2" /> {{getPositiveComments(scope.row.comments).length}}
              </span>
              <span v-if="getNegativeComments(scope.row.comments).length > 0">
                <i class="fa fa-thumbs-down ml-2" /> {{getNegativeComments(scope.row.comments).length}}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {

    };
  },

  props: {
    data: { type: Array, required: true },
    filters: {
      required: true,
      type: Object,
    },
  },

  mounted() {
  },

  computed: {

  },

  methods: {
    getPositiveComments(comments) {
      return comments.filter(comment => comment.type == 1);
    },

    getNegativeComments(comments) {
      return comments.filter(comment => comment.type == -1);
    },

    getTopThree() {
      return this.data.slice(0, 3);
    },

    getBottomThree() {
      return this.data.slice(-3).reverse();
    },

    getPictureUrl(id) {
      return `${process.env.VUE_APP_API_URL}users/${id}/picture`;
    }
  },
};
</script>

<style scoped>
i.fa-thumbs-up {
  color: #79c447;
}

i.fa-thumbs-down {
  color: #ff5454;
}

#userPicture {
  width: 150px;
  height: 150px;
  z-index: 99;
  cursor: pointer;
}

#userPicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  border: 4px solid #FAF532;
}

.defaultPicture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #ccc;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #fff;
  border: 4px solid #FAF532;
}

.pictureContent {
  position: relative;
}

.ranking {
  position: absolute;
  top: -16px;
  right: 15px;
  border-radius: 100%;
  background-color: #1E1E1E;
  color: white;
  width: 40px;
  height: 40px;
}

</style>
<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Date de début</label>
          <datepicker
            v-model="filters.dates.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="updateDate()"
          ></datepicker>
        </div>
        <div class="form-group col-sm-6">
          <label>Date de fin</label>
          <datepicker
            v-model="filters.dates.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="updateDate()"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label
            >Commercial
            <span
              v-if="!filterCommercials"
              class="fa fa-info-circle ml-05"
              v-tooltip="{
                content:
                  'Pas un filtre, permet seulement d\'afficher les stats des commerciaux en-dessous des stats globales.',
                placement: 'right',
              }"
            ></span>
          </label>
          <multiselect
            v-model="selectedCommercials"
            :options="commercials"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
            @input="displayCommercials"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-6">
          <label>Type de commande</label>
          <v-select
            v-model="filters.orderType"
            :options="orderTypes"
            placeholder="Tout"
            :reduce="({ value }) => value"
          ></v-select>
        </div>
      </div>
      <div v-if="showSources" class="row">
        <div class="form-group col-sm-4">
          <label
            >UTM Sources
            <span
              class="fa fa-info-circle ml-05"
              v-tooltip="{
                content:
                  'Options mises à jour lorsque les dates sélectionnées changent.',
                placement: 'right',
              }"
            ></span>
          </label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.utm_sources"
            :options="utm_sources"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label
            >UTM Campaigns
            <span
              class="fa fa-info-circle ml-05"
              v-tooltip="{
                content:
                  'Options mises à jour lorsque les dates sélectionnées changent.',
                placement: 'right',
              }"
            ></span>
          </label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.utm_campaigns"
            :options="utm_campaigns"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label
            >UTM Contents
            <span
              class="fa fa-info-circle ml-05"
              v-tooltip="{
                content:
                  'Options mises à jour lorsque les dates sélectionnées changent.',
                placement: 'right',
              }"
            ></span>
          </label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.utm_contents"
            :options="utm_contents"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
          >
          </multiselect>
        </div>
      </div>
      <div class="row" v-if="selectClientType">
        <div class="form-group col-sm-6">
          <label>Clients / Prospects</label>
          <v-select
            v-model="filters.clientType"
            :options="clientTypes"
            placeholder="Tous"
            class="custom-multiselect"
            :reduce="({ value }) => value"
          ></v-select>
        </div>
        <div class="form-group col-sm-6">
          <label>Cause pertes</label>
          <multiselect
            v-model="selectedLostReasons"
            :options="lostReasons"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
            @input="displayLostReasons"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import { mapGetters } from "vuex";

export default {
  components: {
    Datepicker,
    Multiselect,
    vSelect,
  },

  props: {
    lostReasons: {
      type: Array,
      default: () => [],
    },

    commercials: {
      type: Array,
      default: () => [],
    },

    showSources: {
      type: Boolean,
      default: false,
    },

    filterCommercials: {
      type: Boolean,
      default: false,
    },

    filterLostReasons: {
      type: Boolean,
      default: false,
    },

    selectClientType: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filters: {
        dates: {
          begin: moment().startOf("M").format(),
          end: moment().endOf("M").format(),
        },
        orderType: null,
        utm_sources: null,
        utm_campaigns: null,
        utm_contents: null,
        clientType: null,
      },
      selectedCommercials: [],
      selectedLostReasons: [],
      utm_sources: [],
      utm_campaigns: [],
      utm_contents: [],
      clientTypes: [
        { label: "Clients", value: "clients" },
        { label: "Prospects", value: "prospects" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      orderTypes: "getOrderTypes",
    }),
  },

  mounted() {
    if (this.showSources) {
      this.fetchMarketingFilters();
    }
  },

  methods: {
    updateDate() {
      if (this.showSources) {
        this.fetchMarketingFilters();
      }
    },

    displayCommercials() {
      if (!this.filterCommercials) {
        this.$emit("displayCommercials", this.selectedCommercials);
      }
    },

    displayLostReasons() {
      if (!this.filterLostReasons) {
        this.$emit("displayLostReasons", this.selectedLostReasons);
      }
    },

    applyFilters() {
      const filters = {
        ...this.filters,
        utm_sources: this.filters.utm_sources?.map(({ value }) => value),
        utm_campaigns: this.filters.utm_campaigns?.map(({ value }) => value),
        utm_contents: this.filters.utm_contents?.map(({ value }) => value),
        lostReasons: this.selectedLostReasons.map(({ value }) => value),
      };

      if (this.filterCommercials) {
        filters.commercials = this.selectedCommercials.map(
          ({ value }) => value
        );
      }

      if (this.filterLostReasons) {
        filters.lostReasons = this.selectedLostReasons.map(
          ({ value }) => value
        );
      }

      this.$emit("apply", filters);
    },

    async fetchMarketingFilters() {
      const expenses = await this.fetchExpenses();
      const clients = await this.fetchClients();

      const sources = expenses.filter((e) => e.source).map((e) => e.source);

      sources.push(
        ...clients
          .filter((e) => e.marketing && e.marketing.utm_source)
          .map((e) => e.marketing.utm_source)
      );

      this.utm_sources = sources
        .filter((item, index) => {
          return sources.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      const campaigns = expenses
        .filter((e) => e.campaign)
        .map((e) => e.campaign);

      campaigns.push(
        ...clients
          .filter((e) => e.marketing && e.marketing.utm_campaign)
          .map((e) => e.marketing.utm_campaign)
      );

      this.utm_campaigns = campaigns
        .filter((item, index) => {
          return campaigns.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      const contents = expenses.filter((e) => e.content).map((e) => e.content);

      contents.push(
        ...clients
          .filter((e) => e.marketing && e.marketing.utm_content)
          .map((e) => e.marketing.utm_content)
      );

      this.utm_contents = contents
        .filter((item, index) => {
          return contents.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));
    },

    async fetchExpenses() {
      try {
        const { begin, end } = this.filters.dates;

        const { data } = await this.$api.get("/expenses", {
          params: { begin, end },
        });

        return data.data;
      } catch (e) {
        return [];
      }
    },

    async fetchClients() {
      try {
        const { begin, end } = this.filters.dates;

        const { data } = await this.$api.get("/marketings/clients", {
          params: { begin, end },
        });

        return data;
      } catch (e) {
        return [];
      }
    },
  },
};
</script>

<template>
  <modal
    :title="form.id ? 'Éditer' : 'Ajouter'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="form.id ? 'Éditer' : 'Ajouter'"
    @cancel="() => closeModal()"
    @ok="form.id ? handleEditTag() : handleAddTag()"
    style="padding-top:100px"
    effect="fade/zoom"
  >
    <div class="form-group">
      <label>Label</label>
      <input type="text" class="form-control" v-model="form.label" />
    </div>
    <div class="form-group">
      <label>Code</label>
      <input type="text" class="form-control" v-model="form.code" />
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true
    },
    tag: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    form: {
      label: null,
      code: null
    },
    newType: false,
    newName: false,
    formError: false,
    modalOpened: false
  }),

  mounted() {
    this.form = { ...this.tag };

    this.modalOpened = this.modalShow;
  },

  methods: {
    async handleAddTag() {
      if (this.loading) {
        return false;
      }

      if (!this.form.label || !this.form.code) {
        this.formError = `Tous les champs sont obligatoires`;

        return false;
      }

      this.loading = true;

      try {
        await this.$api.post("/faq/tags", this.form);
      } catch (e) {
        this.formError = `Une erreur s'est produite`;
      }

      this.closeModal();

      this.loading = false;
    },

    async handleEditTag() {
      this.loading = true;

      if (!this.form.label) {
        this.formError = `Tous les champs sont obligatoires`;

        return false;
      }

      await this.$api.put(`/faq/tags/${this.form.id}`, this.form);

      this.closeModal();

      this.loading = false;
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;
      this.form = {};

      this.$emit("close");
    }
  },

  watch: {
    modalShow: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;

        if (newVal) {
          this.form = this.tag;
        }
      }
    }
  }
};
</script>

<style scoped>
.has-error {
  border: 1px solid #ff5454 !important;
  border-radius: 4px;
}
</style>

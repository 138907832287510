<template>
  <modal
    title="Lieu d'intervention"
    v-model="isVisible"
    @cancel="closeModal"
    effect="fade/zoom"
  >
    <div slot="modal-body" class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <Geoloc
            :geoloc="geoloc"
            :header="null"
            :range="displayedRange"
            :address="formatAddress(form)"
            :displayAddress="true"
            placeholder="Lieu d'intervention"
            @placeChanged="handleGmapInput"
            :disabled="!canEdit"
          />
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <label>Rayon d'action</label>
            <el-select
              v-if="canEdit"
              v-model="form.range"
              class="w-100"
              @change="changeRange"
            >
              <el-option
                v-for="range in coachRanges"
                :key="range.value"
                :value="range.value"
                :label="range.label"
              />
            </el-select>
            <p v-else class="form-control">{{ form.range }} km</p>
          </div>
        </div>

        <div v-if="errors.length" class="col-sm-12">
          <div class="alert alert-danger mb-0">
            <ul style="margin-bottom: 0">
              <li
                v-for="(error, index) in errors"
                :key="`localisation-form-error-${index}`"
              >
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <button type="button" class="btn btn-default" @click="closeModal">
        Fermer
      </button>
      <button
        v-if="canEdit"
        type="button"
        class="btn btn-primary"
        @click="submit"
      >
        {{ form.id ? "Modifier" : "Ajouter" }}
      </button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";

import Modal from "vue-strap/src/Modal";
import Geoloc from "./Geoloc";

export default {
  components: {
    Modal,
    Geoloc,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    userLocalisation: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isVisible: false,

      form: {
        id: undefined,
        localisationId: undefined,
        address: "",
        zipcode: "",
        city: "",
        country: "France",
        range: 15,
      },

      displayedRange: 15,
      geoloc: undefined,

      errors: [],
    };
  },

  computed: {
    ...mapGetters({
      coachRanges: "getCoachRanges",
    }),

    canEdit() {
      return (
        this.$store.state.user.coach?.isCoach ||
        this.hasPermission(this.$store.state.user, "USERS_WRITE")
      );
    },
  },

  watch: {
    userLocalisation(userLoc) {
      if (userLoc) {
        this.form = {
          id: userLoc.id,
          localisationId: userLoc.localisationId,
          address: userLoc.localisation.address,
          zipcode: userLoc.localisation.zipcode,
          city: userLoc.localisation.city,
          country: userLoc.localisation.country,
          range: userLoc.range,
        };
        this.displayedRange = userLoc.range;

        const { latitude, longitude } = userLoc.localisation.geoloc;
        this.geoloc = { lat: latitude, lng: longitude };
      } else {
        this.reinitializeData();
      }
    },

    show(value) {
      this.isVisible = value;
    },
  },

  methods: {
    submit() {
      this.checkFields();

      if (this.errors.length) return;

      if (this.form.id) {
        this.$emit("edit", this.form);
      } else {
        this.$emit("add", this.form);
      }
    },

    checkFields() {
      this.errors = [];

      if (!this.form.address) {
        this.errors.push("L'adresse est obligatoire");
      }

      if (!this.form.zipcode) {
        this.errors.push("Le code postal est obligatoire");
      }

      if (!this.form.city) {
        this.errors.push("La ville est obligatoire");
      }

      if (!this.form.range) {
        this.errors.push("Le rayon d'action est obligatoire");
      }
    },

    reinitializeData() {
      this.form = {
        id: undefined,
        localisationId: undefined,
        address: "",
        zipcode: "",
        city: "",
        country: "France",
        range: 15,
      };
      this.displayedRange = 15;
      this.geoloc = undefined;
    },

    closeModal() {
      this.$emit("close");
      this.reinitializeData();
    },

    changeRange(range) {
      this.displayedRange = range;
    },

    handleGmapInput(data) {
      const { address, city, zipcode, country, geoloc } = data;

      this.form = {
        ...this.form,
        address,
        city,
        zipcode,
        country,
      };

      this.geoloc = { lat: geoloc[1], lng: geoloc[0] };
    },
  },
};
</script>

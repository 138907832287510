<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Coach manager</label>
          <multiselect
            placeholder="Choisir un ou des coach managers"
            v-model="filters.coachManagers"
            :options="coachManagers"
            label="label"
            track-by="label"
            class="custom-multiselect"
            :multiple="true"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="applyFilters"
          >
            <i class="icon-eye"></i>&nbsp; Visualiser
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
  components: {
    Multiselect,
  },
  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: {
        coachManagers: [],
      },
      coachManagers: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },

  async created() {
    this.filters = this.default;

    await this.fetchCoachManagers();

    if (
      this.filters.coachManagers.length === 0 &&
      this.coachManagers &&
      this.coachManagers.filter((c) => c.value === this.user.email).length >
        0 &&
      this.user.coachManager &&
      this.hasPermission(this.$store.state.user, "DOCUMENTS_READ")
    ) {
      this.filters.coachManagers.push({
        value: this.user.email,
        label: this.user.coachManager.pseudo || this.user.firstName,
      });
    }

    this.applyFilters();
  },

  methods: {
    applyFilters() {
      this.$emit("applyFilters", { ...this.filters });
    },

    async fetchCoachManagers() {
      const { data } = await this.$api.get("/users/coach-managers");

      this.coachManagers = data.map((c) => ({
        value: c.email,
        label: c.coachManager.pseudo,
      }));
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-people"></i>
      Pack en groupe
      <button
        v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
        type="button"
        class="btn btn-sm btn-primary float-right"
        @click="showParticipantModal = true"
      >
        <i class="fa fa-plus"></i>&nbsp; Ajouter
      </button>
    </div>
    <div class="card-block">
      <dl class="row mb-0">
        <dt class="col-sm-3">Participants</dt>
        <dd class="col-sm-9 d-flex flex-wrap">
          <span
            class="text-white rounded-3 mr-1 d-flex align-items-center bg-info mb-1"
            style="padding: 7px"
          >
            <span @click="goToClient(client.id)" style="cursor: pointer">
              <span v-if="client.name">{{ client.name }}</span>
              <span v-else>{{ client.email }}</span>
            </span>
          </span>
          <span
            v-for="participant in otherParticipants"
            :key="participant.email"
            class="text-white rounded-3 mr-1 d-flex align-items-center bg-info mb-1"
            style="padding: 7px"
          >
            <span @click="goToClient(participant.id)" style="cursor: pointer">
              <span v-if="participant.name">{{ participant.name }}</span>
              <span v-else>{{ participant.email }}</span>
            </span>
            <span
              class="ml-1"
              style="cursor: pointer"
              @click="removeParticipant(participant)"
              ><i class="icon-close"></i
            ></span>
          </span>
        </dd>
      </dl>
    </div>

    <ParticipantModal
      :show="showParticipantModal"
      @ok="(orderId, client) => addNewParticipant(orderId, client)"
      @cancel="showParticipantModal = false"
    />
  </div>
</template>

<script>
import ParticipantModal from "../components/ParticipantModal";

export default {
  components: {
    ParticipantModal,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      otherParticipants: [],
      showParticipantModal: false,
    };
  },

  mounted() {
    if (this.order.orderGroup) {
      const joinedOrders = this.order.orderGroup.orders.filter(
        (order) => order.id !== this.order.id
      );

      for (const joinedOrder of joinedOrders) {
        this.otherParticipants.push({
          ...joinedOrder.refClient,
          joinedOrderId: joinedOrder.id,
        });
      }
    }
  },

  methods: {
    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },

    async addNewParticipant(orderId, client) {
      try {
        await this.$api.post(`/orders/${this.order.id}/participant`, {
          orderId,
        });

        client.joinedOrderId = orderId;
        this.otherParticipants.push(client);
      } catch (e) {
        if (e.response.data === "Relation already exists") {
          this.$errorToast("Ces commandes sont déjà reliées");
        } else if (
          e.response.data === "Added order already belongs to an other group"
        ) {
          this.$errorToast(
            "La commande que vous essayez de joindre est déjà reliée à un autre groupe"
          );
        } else {
          this.$errorToast("L'ajout du nouveau participant a échoué");
        }
      }

      this.showParticipantModal = false;
    },

    removeParticipant(client) {
      this.$confirm({
        message: `Voulez-vous vraiment supprimer le participant ${
          client.name || client.email
        } ?`,
        onConfirm: async () => {
          try {
            await this.$api.delete(`/orders/participant`, {
              data: {
                orderId: client.joinedOrderId,
              },
            });

            this.otherParticipants = this.otherParticipants.filter(
              (participant) =>
                participant.email !== client.email &&
                participant.joinedOrderId !== client.joinedOrderId
            );
          } catch (e) {
            this.$errorToast("La suppression du participant a échoué");
          }
        },
      });
    },
  },
};
</script>

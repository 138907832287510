<template>
  <modal
    :title="title"
    v-model="show"
    :cancelText="onRefuse ? 'Refuser' : 'Annuler'"
    okText="Confirmer"
    @cancel="close"
    @ok="confirm"
    effect="fade/zoom"
  >
    {{ message }}
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import { events } from "./events";

export default {
  components: {
    Modal,
  },

  data() {
    return {
      show: false,
      title: "Confirmer",
      message: "",
      onConfirm: this.close,
      onRefuse: null,
    };
  },

  created() {
    events.$on("confirm", this.openModal);
  },

  methods: {
    close(afterConfirm) {
      if (this.onRefuse && !afterConfirm) {
        this.onRefuse();
      }

      this.show = false;
      this.message = "";
      this.onConfirm = this.close;
      this.onRefuse = null;
    },

    confirm() {
      this.onConfirm();
      this.close(true);
    },

    openModal({ message, onConfirm, onRefuse }) {
      this.message = message;
      this.onConfirm = onConfirm;
      this.onRefuse = onRefuse;
      this.show = true;
    },
  },
};
</script>

<template>
  <div v-if="!loading" class="row pt-1 animated fadeIn">
    <div class="col-lg-5">
      <StatsLiveParticipantsDoughnut :data="statsByGender" class="mb-2" />
      <div class="d-flex flex-column align-items-center">
        <div
          v-for="label in statsByGenderLabels"
          :key="label.value"
          :style="`
                  background-color: ${label.color};
                  padding: .5rem;
                  padding-left: 1rem;
                  border-radius: 1rem;
                  margin-bottom: .3rem;
                  margin-top: .3rem;
                `"
          class="text-white col-12 col-sm-6"
        >
          {{ label.name }}
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <StatsLiveParticipantsBars :data="statsByAgeAndGender" :colors="colors" />
    </div>
  </div>
</template>

<script>
import StatsLiveParticipantsDoughnut from "./StatsLiveParticipantsDoughnut";
import StatsLiveParticipantsBars from "./StatsLiveParticipantsBars";

export default {
  components: {
    StatsLiveParticipantsDoughnut,
    StatsLiveParticipantsBars,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      colors: {
        female: "#ffa600",
        male: "#a05195",
        other: "#D3D3D3",
      },
      labels: {
        female: "Femmes",
        male: "Hommes",
        other: "Autre",
      },
    };
  },

  computed: {
    statsByGender() {
      if (this.data?.byGender) {
        let stats = {};

        for (const [key, value] of Object.entries(this.data.byGender)) {
          stats[key] = { value, color: this.colors[key] };
        }

        return stats;
      }

      return null;
    },

    statsByGenderLabels() {
      let labels = [];

      if (this.data?.byGender) {
        const total = Object.values(this.data.byGender).reduce(
          (a, b) => a + b,
          0
        );

        for (const [key, value] of Object.entries(this.data.byGender)) {
          if (value) {
            const percentage = ((value / total) * 100)
              .toFixed(2)
              .replace(".", ",");

            const label = {
              value: key,
              name: `${percentage}% ${this.labels[key]} (${value})`,
              color: this.colors[key],
              percentage,
            };

            labels.push(label);
          }
        }
      }

      return labels.sort(
        (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
      );
    },

    statsByAgeAndGender() {
      return this.data?.byAgeAndGender;
    },
  },
};
</script>

<template>
  <div class="card" v-if="isAdmin($store.state.user) || commercialUser">
    <div class="card-header">
      <i class="fa fa-align-justify"></i> En retard / Impayés
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-4">
          <span class="text-bold">Répartition du CA : </span>
          {{ formatPrice(total) }}
        </div>
        <div class="col-md-4">
          <span class="text-bold">En retard : </span>
          {{ formatPrice(delaysTotal) }}
        </div>
        <div class="col-md-4">
          <span class="text-bold">Critique : </span>
          {{ formatPrice(unpaidsTotal) }}
        </div>
      </div>
      <div
        class="row mt-1"
        :key="index"
        v-for="(commercial, index) of commercials"
      >
        <div class="col-md-12">{{ commercial.label }}</div>
        <div class="col-md-4">
          <span class="text-bold">Répartition du CA : </span>
          {{ formatPrice(totalByCommercial(commercial)) }}
        </div>
        <div class="col-md-4">
          <span class="text-bold">En retard : </span>
          {{ formatPrice(delaysTotalByCommercial(commercial)) }}
        </div>
        <div class="col-md-4">
          <span class="text-bold">Critique : </span
          >{{ formatPrice(unpaidsTotalByCommercial(commercial)) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      required: true
    },
    commercials: {
      type: Array,
      required: true
    }
  },
  computed: {
    total() {
      let price = 0;

      for (const order of this.orders) {
        price += order.price;
      }

      return price;
    },

    delaysTotal() {
      let price = 0;

      for (const order of this.orders.filter(o => o.delay && !o.unpaid)) {
        price += order.price;
      }

      return price;
    },

    unpaidsTotal() {
      let price = 0;

      for (const order of this.orders.filter(o => o.unpaid)) {
        price += order.price;
      }

      return price;
    }
  },
  methods: {
    totalByCommercial(commercial) {
      let price = 0;

      const commercialOrders = this.orders.filter(order => {
        if (order.commercials) {
          return order.commercials.find(c => commercial.value === c.userEmail);
        }
      });

      for (const order of commercialOrders) {
        price += order.price;
      }

      return price;
    },

    delaysTotalByCommercial(commercial) {
      let price = 0;

      const commercialOrders = this.orders
        .filter(o => o.delay && !o.unpaid)
        .filter(order => {
          if (order.commercials) {
            return order.commercials.find(
              c => commercial.value === c.userEmail
            );
          }
        });

      for (const order of commercialOrders) {
        price += order.price;
      }

      return price;
    },

    unpaidsTotalByCommercial(commercial) {
      let price = 0;

      const commercialOrders = this.orders
        .filter(o => o.unpaid)
        .filter(order => {
          if (order.commercials) {
            return order.commercials.find(
              c => commercial.value === c.userEmail
            );
          }
        });

      for (const order of commercialOrders) {
        price += order.price;
      }

      return price;
    }
  }
};
</script>

<style scoped>
.text-bold {
  font-weight: 700;
}
</style>

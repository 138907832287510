import moment from "moment-timezone";

export default {
  methods: {
    frequency(sessions) {
      let dateFirst = null;
      let dateLast = null;

      if (!sessions) return null;

      let sessionsFiltered = sessions.filter(
        s =>
          Object.prototype.hasOwnProperty.call(s, "scheduledAt") &&
          s.scheduledAt
      );

      if (sessionsFiltered.length <= 1) return null;
      sessionsFiltered.forEach(s => {
        let scheduledAt = moment(s.scheduledAt);
        if (!dateFirst || dateFirst > scheduledAt) {
          dateFirst = scheduledAt;
        }
        if (!dateLast || dateLast < scheduledAt) {
          dateLast = scheduledAt;
        }
      });

      if (!dateFirst || !dateLast) return null;

      return (
        Math.abs(dateFirst.diff(dateLast, "days")) /
        (sessionsFiltered.length - 1)
      );
    },

    averageClientFrequency(orders, coachId = null, fixed = true) {
      if (!orders || orders.length === 0) {
        return false;
      }

      const frequencies = orders.map(order => {
        if (coachId) {
          return this.frequency(
            order.sessions.filter(
              session =>
                !!session.scheduledCoachId &&
                session.scheduledCoachId === coachId
            )
          );
        }

        return this.frequency(order.sessions);
      });

      return this.averageFrequency(frequencies, fixed);
    },

    prestataireClientFrequency(clients, coachId = null, fixed = true) {
      if (!clients || clients.length === 0) {
        return false;
      }

      const frequencies = clients.map(client =>
        this.averageClientFrequency(client.orders, coachId, false)
      );

      return this.averageFrequency(frequencies, fixed);
    },

    prestataireFrequency(coachs) {
      const frequencies = coachs
        .filter(c => c.coach && c.coach.status === "active")
        .map(coach =>
          this.prestataireClientFrequency(coach.clients, coach.id, false)
        );

      return this.averageFrequency(frequencies);
    },

    averageFrequency(frequencies, fixed = true) {
      frequencies = frequencies.filter(
        f => f !== null && f !== false && f !== 0
      );

      if (frequencies.length === 0) {
        return null;
      }

      const result =
        frequencies.reduce((total, num) => {
          return total + num;
        }, 0) / frequencies.length;

      if (fixed) {
        return result.toFixed(2);
      }

      return result;
    }
  }
};

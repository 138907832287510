<template>
  <div class="rank-custom-wrapper">
    <Tabs v-model="activeTab">
      <Tab header="Coachs prospectés (scrapping)">
        <RankProspectedCoach />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import RankProspectedCoach from "../components/rank/coach-manager/RankProspectedCoach";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    RankProspectedCoach,
    Tabs,
    Tab,
  },

  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>

<template>
  <span v-if="order">
    <span
      v-if="order.delay || order.unpaid"
      class="payment-warning"
      :class="order.unpaid ? 'unpaid' : order.delay && 'delay'"
      v-tooltip="`Retard de ${order.daysInterval} jours`"
    >
      <span class="fa fa-exclamation-triangle"></span>
    </span>
    <span
      v-if="missingPayment"
      class="payment-missing"
      v-tooltip="'Paiements manquants'"
    >
      <span class="fa fa-info-circle"></span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      missingPayment: false
    };
  },
  created() {
    if (!this.order.status === "refunded" && this.order.price) {
      let totalPayment = 0;

      for (const p of this.order.payments) {
        totalPayment += p.price;
      }

      if (this.order.price !== totalPayment) {
        this.missingPayment = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-warning {
  margin-left: 5px;

  &.delay {
    span {
      color: #fabb3d;
    }
  }

  &.unpaid {
    span {
      color: #ff5454;
    }
  }
}

.payment-missing {
  margin-left: 5px;
}
</style>

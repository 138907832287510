<template>
  <div>
    <div class="d-flex flex-wrap">
      <div id="map-wrapper" class="col-12 col-lg-8" style="position: relative">
        <FranceMapSvg :default-color="defaultColor" />

        <ConversionCard
          id="data-card"
          custom-class="data-card"
          v-show="currentHoveredId && hoveredDepartmentData"
          :department-data="hoveredDepartmentData"
        />
      </div>
      <div class="col-md-4">
        <h5>Nb attributions:</h5>
        <p>
          Correspond au nombre de commande découverte dont les attributions ont
          été terminés dans la periode saisi et dont les coachs étant sur la
          sessions sont dans le département.
        </p>
        <h5>Nb découverte faites:</h5>
        <p>
          Dans les attributions précédente, correspond au nombre de découverte
          completé par le coach.
        </p>
        <h5>Nb packs post découverte:</h5>
        <p>
          Correspond au nombre de pack pris (or commande découverte) par les
          clients ayant fait une découverte dont l'attribution à été terminé
          dans la période.
        </p>
        <h5>% Conversion:</h5>
        <p>
          Correspond à la moyenne des taux de conversion des coachs ayant des
          clients qui ont un pack découverte (dont l'attribution à été terminé
          dans l'intervale de date).
        </p>
        <h5>Pack moyen:</h5>
        <p>
          Correspond a la moyenne des séances des packs (or pack découverte) des
          clients ayant fait une découverte et pris un pack par la suite.
        </p>
        <h5>Nb packs découverte perdu:</h5>
        <p>
          Correspond au nombre cumulé de pack découverte marqué en `Perdu` ou
          `Perdu coach` dans le menu conversion, dont l'attribution à été
          terminé dans l'intervale de date
        </p>
        <h5>%s de perte:</h5>
        <p>
          Correspond au ratio entre le nombre de pack découverte perdu et le
          nombre de pack découverte attribué.
        </p>
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-3"
        v-for="(data, index) of dataWithRatioPercentage"
        :key="index"
      >
        <ConversionCard v-if="data.data.length > 0" :department-data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import FranceMapSvg from "../../FranceMapSvg";
import ConversionCard from "./ConversionCard";

export default {
  components: {
    FranceMapSvg,
    ConversionCard,
  },

  data() {
    return {
      currentHoveredId: null,
      defaultColor: "#E1E6EF",
      dataCardPositionIsSet: false,
    };
  },

  props: {
    data: { type: Array, required: true },
    filters: {
      required: true,
      type: Object,
    },
    colorScheme: {
      type: String,
      default: "blue",
    },
  },

  mounted() {
    this.setPathsListeners();
  },

  computed: {
    hoveredDepartmentData() {
      if (this.currentHoveredId) {
        return this.data.find(
          (departmentData) => departmentData.value === this.currentHoveredId
        );
      }

      return {};
    },

    dataWithRatioPercentage() {
      const data = [...this.data];

      data.forEach((departmentData) => {
        if (departmentData.data && departmentData.data.length > 0) {
          departmentData.ratioPercentage =
            departmentData.data.reduce(
              (a, d) => a + parseFloat(d.conversionRate),
              0
            ) / departmentData.data.length;

          departmentData.resume = {
            nbDiscoveryAttributed: this.getSumforData(
              departmentData.data,
              "nbDiscoveryAttributed"
            ),
            nbDiscoveryDone: this.getSumforData(
              departmentData.data,
              "nbDiscoveryDone"
            ),
            nbPackPostDiscovery: this.getSumforData(
              departmentData.data,
              "nbPackPostDiscovery"
            ),
            lostDiscovery: this.getSumforData(
              departmentData.data,
              "lostDiscovery"
            ),
            clientPackAverage: this.getAverageforData(
              departmentData.data,
              "clientPackAverage"
            ),
            conversionRate: this.getAverageforData(
              departmentData.data,
              "conversionRate"
            ),
          };

          departmentData.resume.lostPercent = (
            (departmentData.resume.lostDiscovery /
              departmentData.resume.nbDiscoveryAttributed) *
            100
          ).toFixed(2);
        } else {
          departmentData.ratioPercentage = 0;
        }
      });

      return data.sort((a, b) => b.ratioPercentage - a.ratioPercentage);
    },
  },

  methods: {
    getSumforData(data, key) {
      return data.reduce((a, d) => a + parseFloat(d[key]), 0);
    },
    getAverageforData(data, key) {
      return data.reduce((a, d) => a + parseFloat(d[key]), 0) / data.length;
    },

    setPathsListeners() {
      const paths = document.querySelectorAll("#map-wrapper path");

      paths.forEach((path) => {
        let id;
        let isIdf = false;

        if (path.id.startsWith("IDF")) {
          id = path.id.replace("IDF-", "");
          isIdf = true;
        } else {
          id = path.id.replace("FR-", "");
        }

        const correspondingData = this.dataWithRatioPercentage.find(
          (departmentData) => departmentData.value === id
        );

        if (correspondingData?.ratioPercentage > 0) {
          path.setAttribute(
            "fill",
            this.getColor(correspondingData.ratioPercentage)
          );
        }

        path.addEventListener("mouseover", () => {
          if (!path.hasAttribute("stroke-width")) {
            path.setAttribute("stroke-width", isIdf ? ".6" : "3");
          }

          this.currentHoveredId = id;
        });

        path.addEventListener("mouseout", () => {
          if (path.hasAttribute("stroke-width")) {
            path.removeAttribute("stroke-width");
          }

          if (this.currentHoveredId === id) {
            this.currentHoveredId = null;
          }
        });
      });
    },

    getColor(percentage) {
      if (percentage < 33) {
        return "#e9c3c6";
      }

      if (percentage < 66) {
        return "#f1997a";
      }

      if (percentage >= 66) {
        return "#0a7015";
      }
    },

    setDataCardPosition() {
      const mapWrapper = document.querySelector("#map-wrapper");
      const dataCard = document.querySelector("#data-card");

      if (mapWrapper && dataCard) {
        mapWrapper.addEventListener("mousemove", (e) => {
          const wrapperInPage = mapWrapper.getBoundingClientRect();
          dataCard.style.left = e.x - wrapperInPage.x + "px";
          dataCard.style.top = e.y - wrapperInPage.y + "px";
        });

        this.dataCardPositionIsSet = true;
      }
    },
  },

  watch: {
    currentHoveredId(value) {
      if (value && !this.dataCardPositionIsSet) {
        this.setDataCardPosition();
      }
    },
  },
};
</script>

<style scoped>
.data-card {
  position: absolute;
  width: 300px;
  height: auto;
  transform: translate(-110%, -110%);
}
</style>

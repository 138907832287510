<template>
  <div>
    <Loader :is-visible="loading" />

    <el-table :data="data" @sort-change="sortChange">
      <el-table-column prop="title" label="Nom"></el-table-column>
      <el-table-column prop="type" label="Type" align="center">
        <template slot-scope="scope">
          <span class="badge badge-info">{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="Créé le" align="center">
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="lastSentAt"
        label="Dernier envoi le"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.lastSentAt) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="hasPermission($store.state.user, 'DYNAMIC_CONTENT_WRITE')"
        align="right"
      >
        <template slot-scope="scope">
          <button
            class="btn btn-primary btn-sm mr-05"
            @click.stop="confirmSendEvent(scope.row)"
          >
            Envoyer
          </button>
          <button
            class="btn btn-primary btn-sm mr-05"
            @click.stop="editContent(scope.row)"
          >
            <i class="fa fa-edit"></i>
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click.stop="handleDelete(scope.row)"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <ModalUserEvent
      :show="editModal"
      :close="closeEditContent"
      :user-event="selectedContent"
      @updated="updatedContent"
    />

    <ModalConfirmSendEvent
      :visible="confirmSendEventModal"
      :close="closeConfirmSendEvent"
      :user-event="userEvent"
      @added="addContent"
    />
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import ModalUserEvent from "../components/ModalUserEvent.vue";
import ModalConfirmSendEvent from "../components/ModalConfirmSendEvent.vue";

export default {
  components: {
    Loader,
    ModalUserEvent,
    ModalConfirmSendEvent,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,

      editModal: false,
      selectedContent: null,

      confirmSendEventModal: false,
      userEvent: null,

      sort: {
        prop: "lastSentAt",
        order: "descending",
      },
    };
  },

  methods: {
    editContent(content) {
      if (this.hasPermission(this.$store.state.user, "DYNAMIC_CONTENT_WRITE")) {
        this.selectedContent = { ...content };
        this.editModal = true;
      }
    },

    closeEditContent() {
      this.editModal = false;
      this.selectedContent = null;
    },

    confirmSendEvent(content) {
      this.userEvent = { ...content };
      this.confirmSendEventModal = true;
    },

    closeConfirmSendEvent() {
      this.userEvent = null;
      this.confirmSendEventModal = false;
    },

    addContent() {
      this.$emit("added");
    },

    updatedContent(content) {
      this.$emit("updated", content);
    },

    handleDelete(content) {
      if (!content || !content.id) {
        return;
      }

      this.$confirm({
        message: `Êtes-vous sûr.e de vouloir supprimer le contenu "${content.title}" ?`,
        onConfirm: () => this.deleteContent(content.id),
      });
    },

    async deleteContent(contentId) {
      try {
        this.loading = true;

        await this.$api.delete(`/events/${contentId}`);

        this.$emit("deleted", contentId);
        this.$successToast("Contenu supprimé");
      } catch (e) {
        this.$errorToast("Impossible de supprimer ce contenu");
      } finally {
        this.loading = false;
      }
    },

    sortChange({ prop, order }) {
      this.sort = {
        ...this.sort,
        prop,
        order,
      };

      this.$emit("sorted", this.sort);
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table table-condensed"},[_vm._m(0),_c('tbody',_vm._l((_vm.users),function(user){return _c('tr',{key:user.id},[_c('td',[_vm._v(_vm._s(user.firstName))]),_c('td',[_vm._v(_vm._s(user.lastName))]),_c('td',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.openMemberDetails(user)}}},[_vm._v(_vm._s(user.email))])]),_c('td',{staticClass:"text-center"},[_c('span',{staticStyle:{"margin-right":"20px"}},[_vm._v("Équipier")]),_c('el-switch',{attrs:{"value":_vm.hasRole(user, 'admin'),"disabled":!_vm.hasRole(_vm.$store.state.user, 'admin')},on:{"change":function($event){return _vm.toggleRole(user, $event)}}}),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v("Admin")])],1),_c('td',{staticClass:"text-center"},[(user.commercial)?_c('el-switch',{attrs:{"value":user.isLeader,"disabled":!_vm.hasRole(_vm.$store.state.user, 'admin')},on:{"change":function($event){return _vm.toggleLeader(user, $event)}}}):_vm._e()],1),_c('td',{staticClass:"text-center"},[(user.coachManager)?_c('el-switch',{attrs:{"value":user.isCoachManagerLeader,"disabled":!_vm.hasRole(_vm.$store.state.user, 'admin')},on:{"change":function($event){return _vm.toggleCoachManagerLeader(user, $event)}}}):_vm._e()],1),_c('td',[(
              _vm.hasPermission(_vm.$store.state.user, 'SETTINGS_TEAM_WRITE') &&
              !user.archived
            )?_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.archived(user)}}},[_vm._v(" Archiver ")]):_vm._e(),(
              _vm.hasPermission(_vm.$store.state.user, 'SETTINGS_TEAM_WRITE') &&
              user.archived === true
            )?_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.unarchived(user)}}},[_vm._v(" Désarchiver ")]):_vm._e(),(_vm.hasPermission(_vm.$store.state.user, 'SETTINGS_TEAM_WRITE'))?_c('button',{staticClass:"btn btn-danger btn-sm ml-1",on:{"click":function($event){return _vm.setDefaultRole(user)}}},[_vm._v(" Supprimer ")]):_vm._e()])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Prénom")]),_c('th',[_vm._v("Nom de famille")]),_c('th',[_vm._v("Identifiant")]),_c('th',{staticClass:"text-center"},[_vm._v("Rôle")]),_c('th',{staticClass:"text-center"},[_vm._v("Référent commercial")]),_c('th',{staticClass:"text-center"},[_vm._v("Référent coach manager")]),_c('th')])])
}]

export { render, staticRenderFns }
<template>
  <div class="card" v-if="cancelation">
    <div class="card-header">
      <i class="fa fa-thumbs-o-down"></i> Annulation
    </div>
    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">Mode</dt>
        <dd class="col-sm-8">
          {{
            cancelation.subscription ? cancelation.subscription.type : "Inconnu"
          }}
        </dd>
        <dt class="col-sm-4">Statut</dt>
        <dd class="col-sm-8">
          <span v-if="cancelation.plan.status === 'trialing'"
            >Résilié Essai</span
          >
          <span v-else>Résilié Abonné</span>
        </dd>
        <dt class="col-sm-4">Résilié le</dt>
        <dd class="col-sm-8">{{ formatDate(cancelation.date) }}</dd>
        <template v-if="cancelation.reason">
          <dt class="col-sm-4">Note</dt>
          <dd class="col-sm-8 d-flex">
            <StarRating :rating="cancelation.reason.rating_value" /> ({{
              cancelation.reason.rating_value
            }}/5)
          </dd>
          <dt class="col-sm-4">Raisons</dt>
          <dd class="col-sm-8">
            <span
              v-for="(reason, index) in cancelation.reason.unsubscribe_reasons"
              :key="index"
              >{{ reason }}
              <br />
            </span>
            <span v-if="cancelation.reason.other_reason">{{
              cancelation.reason.other_reason
            }}</span>
          </dd>
          <dt class="col-sm-4">Amélioration possible</dt>
          <dd class="col-sm-8">
            {{ cancelation.reason.how_to_improve || "-" }}
          </dd>
        </template>
      </dl>
    </div>
  </div>
</template>

<script>
import StarRating from "../components/StarRating";

export default {
  components: {
    StarRating,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },

    subscriptions: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    cancelation() {
      if (this.client.subscriptionCancellation[0]) {
        return {
          ...this.client.subscriptionCancellation[0],
          subscription: this.subscriptions.find(
            ({ id }) =>
              id === this.client.subscriptionCancellation[0].subscriptionId
          ),
        };
      }

      return null;
    },
  },
};
</script>

<template>
  <section class="mt-2" style="scroll-margin-top: 133px">
    <h2 class="h4 px-2">{{ title }}</h2>
    <hr />

    <slot />
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.imgSrc)?_c('div',{staticClass:"user-picture",style:(`border: ${_vm.borderStyle}; cursor: ${
        _vm.noPicture ? 'unset' : 'pointer'
      }`),on:{"click":_vm.openEditModal}},[_c('img',{attrs:{"src":_vm.imgSrc},on:{"error":_vm.handlePictureError}})]):_vm._e()]),_c('modal',{attrs:{"title":"Photo","effect":"fade/zoom"},model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center mb-2"},[(_vm.imgSrc)?_c('div',{staticClass:"user-picture user-picture--modal"},[_c('img',{attrs:{"src":_vm.imgSrc}})]):_vm._e()]),_c('div',{staticClass:"col-12 mb-1"},[_c('span',{class:`d-block text-center h6 ${_vm.textClass}`},[_vm._v(" "+_vm._s(_vm.statusText)+" ")]),(_vm.photoUploadedAt)?_c('span',{staticClass:"d-block text-center text-muted"},[_vm._v(" Chargée le "+_vm._s(_vm.photoUploadedAt)+" ")]):_vm._e()]),_c('div',{staticClass:"col-12 d-flex justify-content-center"},[(_vm.photoValidityStatus !== 'rejected')?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'Refuser la photo de profil',
            placement: 'top',
          }),expression:"{\n            content: 'Refuser la photo de profil',\n            placement: 'top',\n          }"}],staticClass:"btn btn-danger ml-05 mr-05",on:{"click":_vm.openRejectModal}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e(),(_vm.photoValidityStatus !== 'pending')?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: `Mettre la photo de profil 'En attente'`,
            placement: 'top',
          }),expression:"{\n            content: `Mettre la photo de profil 'En attente'`,\n            placement: 'top',\n          }"}],staticClass:"btn btn-primary ml-05 mr-05",on:{"click":function($event){return _vm.editValidityStatus('pending')}}},[_c('i',{staticClass:"fa fa-rotate-left"})]):_vm._e(),(_vm.photoValidityStatus !== 'validated')?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'Valider la photo de profil',
            placement: 'top',
          }),expression:"{\n            content: 'Valider la photo de profil',\n            placement: 'top',\n          }"}],staticClass:"btn btn-success ml-05 mr-05",on:{"click":function($event){return _vm.editValidityStatus('validated')}}},[_c('i',{staticClass:"fa fa-check"})]):_vm._e()])]),_c('div',{staticClass:"modal-footer",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":function($event){_vm.showEditModal = false}}},[_vm._v(" Fermer ")])])]),_c('ModalRejectCoachPicture',{attrs:{"show":_vm.showRejectModal,"close":() => (_vm.showRejectModal = false)},on:{"confirm":_vm.handleReject}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="live-custom-wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <template>
          <div class="card-header">
            <p>Suggestions de cours</p>
          </div>
        </template>
        <div class="card-block custom-table">
          <el-table :data="suggestions" style="width: 100%">
            <template>
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-between">
                    <div style="width: 30%">
                      <el-table
                        class="cb-schedules-table"
                        :show-header="false"
                        :data="suggestions[scope.$index].scheduleChoices"
                        stripe
                      >
                        <el-table-column prop="day"></el-table-column>
                        <el-table-column prop="time"></el-table-column>
                      </el-table>
                    </div>
                    <div v-if="scope.row.text" class="cbst-text-container">
                      <b>Précisions :</b>
                      <p>{{ scope.row.text }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="sender.firstname"
                label="Client"
                sortable
              ></el-table-column>
              <el-table-column
                prop="sender.email"
                label="Email"
              ></el-table-column>
              <el-table-column prop="courses" label="Cour(s)"></el-table-column>
              <el-table-column prop="mode" label="Mode"></el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <button
                    class="btn btn-danger btn-sm"
                    @click="deleteSuggestion(scope.row.id)"
                  >
                    <i class="fa fa-trash-o"></i>
                  </button>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      suggestions: [],
      keyTable: 0,
    };
  },

  mounted() {
    this.getSuggestionsAndFormatSchedules();
  },

  methods: {
    async getSuggestionsAndFormatSchedules() {
      const response = await this.$api.get("/suggestions");
      response.data.forEach((suggestion) => {
        this.suggestions = [
          ...this.suggestions,
          {
            id: suggestion.id,
            courses: suggestion.courses,
            createdAt: suggestion.createdAt,
            mode: suggestion.mode,
            sender: suggestion.sender,
            text: suggestion.text,
            scheduleChoices: [
              { day: suggestion.dayOne, time: suggestion.timeOne },
              { day: suggestion.dayTwo, time: suggestion.timeTwo },
              { day: suggestion.dayThree, time: suggestion.timeThree },
            ],
          },
        ];
      });
    },

    deleteSuggestion(suggestionId) {
      this.$confirm({
        message: "Supprimer cette suggestion ?",
        onConfirm: () => {
          this.$api.delete(`/suggestions/${suggestionId}`);
        },
      });
    },
  },
};
</script>
<style lang="postcss" scoped>
.cbst-text-container {
  width: 40%;
  box-sizing: border-box;
  padding: 15px 0;
}
</style>

<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <router-view></router-view>

      <div class="card" v-if="['payment-methods'].indexOf($route.name) > -1">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Liste des moyens de paiement
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="newPaymentMethod = true"
            v-if="
              hasPermission($store.state.user, 'SETTINGS_PAYMENTS_TYPE_WRITE')
            "
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Concepts</th>
                <th>Type de paiement</th>
                <th>
                  Comment régler
                  <span
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content:
                        'Information contenue dans les emails client (commande, info paiement, etc...)',
                      placement: 'right',
                    }"
                  ></span>
                </th>
              </tr>
            </thead>
            <tbody v-if="paymentMethods.length > 0">
              <tr
                v-for="(paymentMethod, index) in paymentMethods"
                :key="index"
                @click="goToPaymentMethod(paymentMethod)"
                class="click"
              >
                <td>{{ paymentMethod.name }}</td>
                <td>
                  <span
                    v-for="(concept, key) in paymentMethod.concepts"
                    :key="key"
                    >{{
                      concept.name +
                      (paymentMethod.concepts[key + 1] ? ", " : "")
                    }}</span
                  >
                </td>
                <td>{{ paymentMethod.type }}</td>
                <td>{{ paymentMethod.howto }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal
      title="Ajouter un moyen de paiement"
      large
      v-model="newPaymentMethod"
      @ok="handleNewPaymentMethod()"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ajouter un moyen de paiement</h4>
      </div>

      <div class="form-group has-danger">
        <label>Nom</label>
        <input
          v-model="form.name"
          type="text"
          class="form-control"
          placeholder="exemple : Chèque"
        />
      </div>

      <div class="form-group has-danger">
        <label>Concepts</label>
        <div class="checkboxes">
          <div class="type" v-for="(concept, key) in concepts" :key="key">
            <input
              v-model="form.concepts"
              :value="concept.name"
              type="checkbox"
              class="form-control"
              :id="concept.name"
            />
            <label :for="concept.name">{{ concept.name }}</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Type de paiement</label>
        <input
          type="text"
          v-model="form.type"
          class="form-control"
          placeholder="Paiement par chèque"
        />
      </div>

      <div class="form-group">
        <label
          >Comment régler : information contenue dans les emails client
          (commande, info paiement, etc...)</label
        >
        <textarea
          v-model="form.howto"
          rows="2"
          class="form-control"
          placeholder="À envoyer à ..."
        ></textarea>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      paymentMethods: [],
      newPaymentMethod: false,
      concepts: [],
      form: {
        name: null,
        concepts: [],
        type: null,
        howto: null,
      },
    };
  },
  created() {
    this.$api
      .get("/payment-methods")
      .catch(() => {
        this.$router.push({
          name: "dashboard",
        });
      })
      .then((res) => {
        this.paymentMethods = res.data;
      });
    this.$api.get("/concepts").then((res) => {
      this.concepts = res.data;
    });
  },
  methods: {
    handleNewPaymentMethod() {
      if (!this.form.name) {
        return false;
      }

      this.$api
        .post("/payment-methods", {
          form: this.form,
          concepts: this.form.concepts,
        })
        .then((res) => {
          this.newPaymentMethod = false;
          this.paymentMethods.unshift(res.data.form);
        });
    },
    goToPaymentMethod(paymentMethod) {
      this.$router.push({
        name: "payment-method-details",
        params: {
          id: paymentMethod.id,
        },
      });
    },
  },
};
</script>
<style scoped>
.type {
  display: flex;
  align-items: center;
}
.type input {
  width: auto;
  margin: 0 6px 6px 0;
}
</style>

<template>
  <gmap-autocomplete
    v-if="gmapIsLoaded"
    id="geocoding-address"
    class="form-control"
    placeholder="Selectionner une adresse"
    :componentRestrictions="geocodeRestrictions"
    :selectFirstOnEnter="true"
    @place_changed="placeChangedHandler"
    :disabled="disableGmapfilter"
  >
  </gmap-autocomplete>
</template>

<script>
export default {
  props: {
    disableGmapfilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gmapIsLoaded: false,
    };
  },
  computed: {
    markers() {
      return [{ position: this.geoloc }];
    },
    geocodeRestrictions() {
      return { country: "fr" };
    },
  },
  async mounted() {
    try {
      const google = await this.$gmapApiPromiseLazy();

      this.gmapIsLoaded =
        google?.maps && Object.keys(google.maps).includes("places");
    } catch (e) {
      this.gmapIsLoaded = false;
    }
  },
  methods: {
    placeChangedHandler(place) {
      this.$emit("placeChangedHandler", place);
    },
  },
};
</script>

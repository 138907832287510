<template>
  <modal
    :title="'Créer un avoir'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="'Créer'"
    @cancel="() => closeModal('cancel')"
    @ok="handleAddRefund()"
    style="padding-top: 100px"
    effect="fade/zoom"
  >
    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">Créer un avoir</h4>
    </div>

    <div class="row">
      <div class="col-md-8 form-group">
        <label>Description de l'avoir</label>
        <div class="input-group">
          <input v-model="form.description" type="text" class="form-control" />
        </div>
      </div>

      <div class="form-group col-md-4">
        <label>Montant de l'avoir</label>
        <div class="input-group">
          <input v-model="form.price" type="text" class="form-control" />
          <span class="input-group-addon"><i class="fa fa-euro"></i></span>
        </div>
      </div>
    </div>

    <div v-if="formError" class="alert alert-danger">
      {{ formError }}
    </div>

    <div class="row">
      <div class="col-sm-6 text-danger">Reste à régler</div>
      <div class="col-sm-6 text-danger">
        <strong>{{ amountDiff }}</strong>
      </div>
      <template v-if="disbursementAmount">
        <div class="col-sm-6 text-success">Remboursement</div>
        <div class="col-sm-6 text-success">
          <strong>{{ disbursementAmount }}</strong>
        </div>
      </template>
      <div class="col-sm-6 text-primary">Montant de l'avoir</div>
      <div class="col-sm-6 text-primary">
        <strong>{{ refundAmount }} €</strong>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      form: {
        orderId: null,
        description: null,
        price: null,
      },
      formError: "",
      modalOpened: false,
    };
  },

  mounted() {
    this.form = {
      orderId: this.order?.id,
      description: `AVOIR de la facture  : ${this.order?.invoice?.serial}`,
      price: this.refundAmount,
    };

    this.modalOpened = this.modalShow;
  },

  methods: {
    handleAddRefund() {
      if (!this.form.orderId || !this.form.description || !this.form.price) {
        return (this.formError = "Tous les champs sont obligatoires.");
      } else {
        this.formError = "";
      }

      if (this.form.price > this.refundAmount) {
        return (this.formError = `L'avoir ne peux pas dépasser ${this.refundAmount} €`);
      } else {
        this.formError = "";
      }

      this.$confirm({
        message: `Un avoir sera créé et lié à la commande, la commande passera en statut "impayé (traité)". Confirmer ?`,
        onConfirm: async () => {
          if (this.loading) {
            return false;
          }

          await this.$api.post("/orders/unpaid-refund", {
            ...this.form,
          });

          this.closeModal("success");

          this.loading = false;
        },
      });
    },

    closeModal(status) {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;
      this.form = {};

      this.$emit("close", status);
    },
  },

  computed: {
    amountDiff() {
      if (!this.order || !this.order.payments) {
        return 0;
      }

      let payed = 0;

      for (let payment of this.order.payments) {
        if (payment.captured) {
          payed += payment.price;
        }
      }

      return this.formatPrice(this.order.price - payed);
    },

    refundAmount() {
      if (!this.order || !this.order.payments) {
        return 0;
      }

      let payed = 0;
      let disbursed = 0;

      for (let payment of this.order.payments) {
        if (payment.captured) {
          payed += payment.price;
        }

        if (payment.captured && payment.price < 0) {
          disbursed += payment.price;
        }
      }

      return ((this.order.price - payed + disbursed) / 100).toFixed(2);
    },

    disbursementAmount() {
      if (!this.order || !this.order.payments) {
        return 0;
      }

      let disbursed = 0;

      for (let payment of this.order.payments) {
        if (payment.captured && payment.price < 0) {
          disbursed += payment.price;
        }
      }

      return this.formatPrice(disbursed);
    },
  },

  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
      }

      if (newVal) {
        this.form = {
          orderId: this.order?.id,
          description: `AVOIR de la facture : ${this.order?.invoice?.serial}`,
          price: this.refundAmount,
        };
      }
    },
  },
};
</script>

<style scoped>
.has-error {
  border: 1px solid #ff5454 !important;
  border-radius: 4px;
}
</style>

<template>
  <div>
    <div v-for="(data, index) of formExpenseData" :key="index" class="card">
      <div class="card-header d-flex justify-content-between">
        <div>{{ data.period }}</div>
        <div v-if="data.utms.length > 0">
          <strong
            >{{ calculateFormByExpense(data.total) }}€
            <span v-if="data.total.nbForms > 0">/ formulaires</span></strong
          >
          - {{ data.total.nbForms }} formulaire<span
            v-if="data.total.nbForms > 1"
            >s</span
          >
          - {{ data.total.expenses.toFixed(2) }} €
        </div>
      </div>
      <div class="card-body">
        <div v-if="data.utms.length > 0" class="p-1 row">
          <div
            v-for="(utm, key) of data.utms"
            :key="key"
            class="col-2 flex justify-content-center my-1"
          >
            <h4>
              {{ utm.name }}
            </h4>
            <span
              >{{ calculateFormByExpense(utm.formExpense) }} €
              <span v-if="utm.formExpense.nbForms > 0">/ formulaires</span>
            </span>
            <br />
            <span class="text-muted">
              {{ utm.formExpense.nbForms }} formulaire<span
                v-if="utm.formExpense.nbForms > 1"
                >s</span
              >
              - {{ utm.formExpense.expenses.toFixed(2) }} €
            </span>
          </div>
        </div>
        <div v-else class="m-2 text-center">
          <h5>Aucune dépense n'a été faite ce mois ci.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formExpenseData: {
      type: Array,
      required: true,
    },
  },

  methods: {
    calculateFormByExpense(formExpense) {
      if (formExpense.nbForms > 0 && formExpense.expenses > 0) {
        return (formExpense.expenses / formExpense.nbForms).toFixed(2);
      }

      if (formExpense.nbForms === 0) {
        return formExpense.expenses.toFixed(2);
      }

      return 0;
    },
  },
};
</script>

import { render, staticRenderFns } from "./ClientLiveSponsorship.vue?vue&type=template&id=461406f3&scoped=true"
import script from "./ClientLiveSponsorship.vue?vue&type=script&lang=js"
export * from "./ClientLiveSponsorship.vue?vue&type=script&lang=js"
import style0 from "./ClientLiveSponsorship.vue?vue&type=style&index=0&id=461406f3&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461406f3",
  null
  
)

export default component.exports
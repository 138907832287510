<template>
  <div class="row">
    <div class="col-sm-5">
      <ClientLiveCurrentStatusCard
        :client="client"
        :subscription="currentSubscription"
      />

      <ClientLiveSponsorship :client="client" />

      <ClientLiveFreeAccess :client="client" />

      <ClientBanishment :client="client" :banishments="client.banishments" />
    </div>
    <div class="col-sm-7">
      <ClientSubscriptionsCard :subscriptions="subscriptions" />

      <ClientBookings
        :bookings-live="bookingsLive"
        :bookings-small="bookingsSmall"
        :bookings-live-pagination="bookingsLivePagination"
        :bookings-small-pagination="bookingsSmallPagination"
        @changePageLive="changePageLive"
        @changePageSmall="changePageSmall"
      />

      <ClientCancelationCard :client="client" :subscriptions="subscriptions" />
    </div>
  </div>
</template>

<script>
import ClientLiveSponsorship from "../components/ClientLiveSponsorship";
import ClientLiveFreeAccess from "../components/ClientLiveFreeAccess";
import ClientBookings from "../components/ClientBookings";
import ClientBanishment from "../components/ClientBanishment";
import ClientCancelationCard from "../components/ClientCancelationCard";
import ClientLiveCurrentStatusCard from "../components/ClientLiveCurrentStatusCard";
import ClientSubscriptionsCard from "../components/ClientSubscriptionsCard";

export default {
  components: {
    ClientLiveSponsorship,
    ClientLiveFreeAccess,
    ClientBookings,
    ClientBanishment,
    ClientCancelationCard,
    ClientLiveCurrentStatusCard,
    ClientSubscriptionsCard,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },

    currentSubscription: {
      type: Object,
      default: null,
    },

    subscriptions: {
      type: Array,
      default: () => [],
    },

    bookingsLive: {
      type: Array,
      default: () => [],
    },

    bookingsSmall: {
      type: Array,
      default: () => [],
    },

    bookingsLivePagination: {
      type: Object,
      required: true,
    },

    bookingsSmallPagination: {
      type: Object,
      required: true,
    },
  },

  methods: {
    changePageLive(page) {
      this.$emit("changePageLive", page);
    },

    changePageSmall(page) {
      this.$emit("changePageSmall", page);
    },
  },
};
</script>

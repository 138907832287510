<template>
  <div>
    <div class="card">
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-6">
            <label>
              Date de début
              <el-popover trigger="hover" placement="top" :width="400">
                <div class="p-1 text-justify">
                  <p>
                    L'intervalle de date permet de filtrer uniquement les
                    clients créés dans cette periode.
                  </p>
                </div>
                <span slot="reference">
                  <i class="fa fa-info-circle ml-05"></i>
                </span>
              </el-popover>
            </label>
            <datepicker
              v-model="filters.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-6">
            <label>Date de fin</label>
            <datepicker
              v-model="filters.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button class="btn btn-default" @click="applyFilters">
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: [],
    };
  },

  async created() {
    this.filters = this.default;
  },

  methods: {
    applyFilters() {
      this.$emit("update", { ...this.filters });
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex justify-content-end">
      <div
        v-if="imgSrc"
        class="user-picture"
        :style="`border: ${borderStyle}; cursor: ${
          noPicture ? 'unset' : 'pointer'
        }`"
        @click="openEditModal"
      >
        <img :src="imgSrc" @error="handlePictureError" />
      </div>
    </div>

    <modal v-model="showEditModal" title="Photo" effect="fade/zoom">
      <div class="row">
        <div class="col-12 d-flex justify-content-center mb-2">
          <div v-if="imgSrc" class="user-picture user-picture--modal">
            <img :src="imgSrc" />
          </div>
        </div>

        <div class="col-12 mb-1">
          <span :class="`d-block text-center h6 ${textClass}`">
            {{ statusText }}
          </span>
          <span v-if="photoUploadedAt" class="d-block text-center text-muted">
            Chargée le {{ photoUploadedAt }}
          </span>
        </div>

        <div class="col-12 d-flex justify-content-center">
          <button
            v-if="photoValidityStatus !== 'rejected'"
            class="btn btn-danger ml-05 mr-05"
            v-tooltip="{
              content: 'Refuser la photo de profil',
              placement: 'top',
            }"
            @click="openRejectModal"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            v-if="photoValidityStatus !== 'pending'"
            class="btn btn-primary ml-05 mr-05"
            v-tooltip="{
              content: `Mettre la photo de profil 'En attente'`,
              placement: 'top',
            }"
            @click="editValidityStatus('pending')"
          >
            <i class="fa fa-rotate-left"></i>
          </button>
          <button
            v-if="photoValidityStatus !== 'validated'"
            class="btn btn-success ml-05 mr-05"
            v-tooltip="{
              content: 'Valider la photo de profil',
              placement: 'top',
            }"
            @click="editValidityStatus('validated')"
          >
            <i class="fa fa-check"></i>
          </button>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="showEditModal = false"
        >
          Fermer
        </button>
      </div>
    </modal>

    <ModalRejectCoachPicture
      :show="showRejectModal"
      :close="() => (showRejectModal = false)"
      @confirm="handleReject"
    />
  </div>
</template>

<script>
import axios from "axios";

import Modal from "vue-strap/src/Modal";
import ModalRejectCoachPicture from "./ModalRejectCoachPicture";

const UserDefaultAvatar = require("@/assets/user-default-avatar.png");

export default {
  components: {
    Modal,
    ModalRejectCoachPicture,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      imgSrc: undefined,
      noPicture: false,
      showEditModal: false,
      showRejectModal: false,
    };
  },

  computed: {
    borderStyle() {
      if (this.noPicture) {
        return "solid 4px #ff5454";
      }

      switch (this.user.coach?.photoValidityStatus) {
        case "pending":
          return "solid 4px #20a8d8";
        case "validated":
          return "solid 4px #79c447";
        case "rejected":
          return "solid 4px #ff5454";
        default:
          return "unset";
      }
    },

    textClass() {
      switch (this.user.coach?.photoValidityStatus) {
        case "pending":
          return "text-primary";
        case "validated":
          return "text-success";
        case "rejected":
          return "text-danger";
        default:
          return "";
      }
    },

    photoValidityStatus() {
      return this.user.coach?.photoValidityStatus;
    },

    statusText() {
      switch (this.photoValidityStatus) {
        case "pending":
          return "En attente de validation";
        case "validated":
          return "Photo validée";
        case "rejected":
          return "Photo refusée";
        default:
          return "";
      }
    },

    photoUploadedAt() {
      return this.user.coach?.photoUploadedAt
        ? this.formatDate(this.user.coach.photoUploadedAt)
        : "";
    },
  },

  mounted() {
    this.imgSrc = `${axios.defaults.baseURL}users/${this.user.id}/picture`;
  },

  methods: {
    handlePictureError() {
      this.imgSrc = UserDefaultAvatar;
      this.noPicture = true;
    },

    openEditModal() {
      if (!this.noPicture) {
        this.showEditModal = true;
      }
    },

    editValidityStatus(status) {
      this.$emit("editValidityStatus", { status });

      this.showEditModal = false;
    },

    openRejectModal() {
      this.showEditModal = false;
      this.showRejectModal = true;
    },

    handleReject(reason) {
      this.$emit("editValidityStatus", { status: "rejected", reason });

      this.showRejectModal = false;
    },
  },
};
</script>

<style scoped>
.user-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #faf532;
}

.user-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-picture.user-picture--modal {
  width: 400px;
  height: 400px;
}
</style>

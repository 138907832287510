<template>
  <modal
    :title="form.id ? 'Éditer' : 'Ajouter'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="form.id ? 'Éditer' : 'Ajouter'"
    @cancel="() => closeModal()"
    @ok="form.id ? handleEditFaq() : handleAddFaq()"
    style="padding-top: 100px"
    effect="fade/zoom"
    large
  >
    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">
        {{ form.id ? "Éditer" : "Ajouter" }} une question/réponse
      </h4>
    </div>
    <Loader :isVisible="loading" />
    <div class="modal-body">
      <div class="form-group">
        <el-checkbox v-model="newTag">Ajouter une catégorie ?</el-checkbox>
      </div>
      <div class="form-group">
        <label>Catégorie(s)</label>
        <multiselect
          v-if="!newTag"
          placeholder="Choisir des catégories"
          v-model="form.tags"
          :options="tags"
          label="label"
          track-by="label"
          class="custom-multiselect"
          :multiple="true"
        >
        </multiselect>
        <div class="row" v-else>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="newTagValue" />
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-sm btn-block btn-success"
              @click="addNewTag()"
            >
              Ajouter
            </button>
          </div>
        </div>

        <div class="alert alert-danger" v-show="formErrors.tags" role="alert">
          Ce champ est obligatoire
        </div>
      </div>
      <div class="form-group">
        <label>Question</label>
        <input v-model="form.question" type="text" class="form-control" />
        <div
          class="alert alert-danger"
          v-show="formErrors.question"
          role="alert"
        >
          Ce champ est obligatoire
        </div>
      </div>
      <div class="form-group">
        <label>Réponse</label>
        <quill-editor
          v-model="content"
          ref="myQuillEditor"
          @change="onEditorChange($event)"
          :disabled="!hasPermission($store.state.user, 'FAQ_WRITE')"
        >
        </quill-editor>
        <div
          class="alert alert-danger"
          v-show="formErrors.response"
          role="alert"
        >
          Ce champ est obligatoire
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Loader from "../Loader";

import { quillEditor } from "vue-quill-editor";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Modal,
    quillEditor,
    Multiselect,
    Loader,
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    faq: {
      type: Object,
      default: undefined,
    },
    tags: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    form: {
      tags: [],
      question: "",
      response: "",
    },
    formErrors: {
      question: false,
      response: false,
      tags: false,
    },
    content: "",
    modalOpened: false,
    newTag: false,
    newTagValue: "",
    loading: false,
  }),

  mounted() {
    this.modalOpened = this.modalShow;
  },

  methods: {
    async handleAddFaq() {
      if (this.loading) {
        return false;
      }

      if (this.checkFormsErrors()) {
        return false;
      }

      this.loading = true;
      this.form.tags = this.form.tags.map((t) => t.id);

      try {
        await this.$api.post("/faq", this.form);
      } catch (e) {
        this.formError = `Une erreur s'est produite`;
      }

      this.closeModal();
    },

    async handleEditFaq() {
      if (this.loading) {
        return false;
      }

      this.loading = true;

      if (this.checkFormsErrors()) {
        return false;
      }

      this.form.tags = this.form.tags.map((t) => t.id);

      try {
        await this.$api.put(`/faq/${this.form.id}`, this.form);
      } catch (e) {
        this.formError = `Une erreur s'est produite`;
      }

      this.closeModal();
    },

    checkFormsErrors() {
      this.formErrors.question = !this.form.question;
      this.formErrors.response = !this.form.response;
      this.formErrors.tags = !this.form.tags || this.form.tags.length === 0;

      return (
        Object.values(this.formErrors).filter((error) => error).length !== 0
      );
    },

    onEditorChange({ html }) {
      this.form.response = html;
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formErrors = {};
      this.content = "";
      this.form = {
        question: "",
        response: "",
        tags: [],
      };

      this.$emit("close");
    },

    async addNewTag() {
      if (!this.newTagValue || this.newTagValue.length === 0) {
        this.formErrors.tags = true;
        return;
      }

      this.formErrors.tags = false;

      const { data } = await this.$api.post(`/faq/tags`, {
        label: this.newTagValue,
      });

      this.tags.push(data);
      if (!this.form.tags) {
        this.form.tags = [];
      }

      this.form.tags.push(data);
      this.newTag = false;
      this.newTagValue = null;
    },
  },

  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
      }
    },
    faq: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal.length !== 0 && newVal.id !== undefined) {
        this.form.question = newVal.question;
        this.form.id = newVal.id;
        this.content = newVal.response;

        for (const faqTag of newVal.faqTags) {
          this.form.tags.push(faqTag.refTag);
        }
      }
    },
  },
};
</script>

<style scoped>
.has-error {
  border: 1px solid #ff5454 !important;
  border-radius: 4px;
}
</style>

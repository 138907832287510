<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-graph"></i> Statistiques
      <button
        type="button"
        class="btn btn-sm btn-primary pull-right"
        @click="exportStats()"
        :disabled="loading"
      >
        <i class="icon-calculator"></i> Export Excel
      </button>
    </div>
    <div class="card-block">
      <MarketingStatsArray
        label="Source"
        :cols="cols.utm_sources"
      ></MarketingStatsArray>
      <MarketingStatsArray
        label="Campaign"
        :cols="cols.utm_campaigns"
      ></MarketingStatsArray>
      <MarketingStatsArray
        label="Medium"
        :cols="cols.utm_mediums"
      ></MarketingStatsArray>
      <MarketingStatsArray
        label="Term"
        :cols="cols.utm_terms"
      ></MarketingStatsArray>
      <MarketingStatsArray
        label="Content"
        :cols="cols.utm_contents"
      ></MarketingStatsArray>
      <MarketingStatsArray
        label="Landing page"
        :cols="cols.sourceUrls"
      ></MarketingStatsArray>
      <MarketingStatsArray
        label="Referer"
        :cols="cols.referers"
      ></MarketingStatsArray>
    </div>
  </div>
</template>

<script>
import MarketingStatsArray from "../components/MarketingStatsArray";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    MarketingStatsArray,
  },

  mixins: [DownloadFile],

  props: {
    cols: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async exportStats() {
      this.loading = true;

      const { data } = await this.$api.post("/marketings/export-stats", {
        cols: this.cols,
      });

      this.downloadSecuredFile(data.archive);

      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Statut</label>
          <multiselect
            v-model="form.status"
            :options="statusOptions"
            label="label"
            track-by="label"
            :multiple="true"
            @input="checkStatus"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label>Mode</label>
          <multiselect
            v-model="form.types"
            :options="typesOptions"
            label="label"
            track-by="label"
            :multiple="true"
            :disabled="onlyStatusIsAdmin"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label>Récurrence</label>
          <multiselect
            v-model="form.recurrence"
            :options="recurrenceOptions"
            label="label"
            track-by="label"
            :multiple="true"
            :disabled="recurrenceDisabled"
          >
          </multiselect>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Client</label>
          <input
            class="form-control"
            v-model="form.client"
            placeholder="Rechercher un client"
          />
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        @click="exportClients"
        :disabled="!hasRole($store.state.user, 'admin')"
      >
        <i class="fa fa-cloud-download"></i> Exporter
      </button>
      <button type="button" class="btn btn-sm btn-secondary" @click="apply">
        <i class="fa fa-eye"></i> Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },

  props: {
    default: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      form: {
        client: null,
        status: [],
        types: [],
        recurrence: [],
      },
      statusOptions: [
        {
          value: "subscribed",
          label: "Abonné",
          recurrence: true,
        },
        {
          value: "trialing",
          label: "Essai",
          recurrence: true,
        },
        {
          value: "canceled",
          label: "Résilié",
          recurrence: true,
        },
        {
          value: "noSubscription",
          label: "Sans abonnement",
        },
        {
          value: "freeAccess",
          label: "Offert",
        },
        {
          value: "admin",
          label: "Admin",
        },
      ],
      typesOptions: [
        {
          value: "live",
          label: "Live",
        },
        {
          value: "small",
          label: "Visio & Live",
        },
      ],
      recurrenceOptions: [
        {
          value: "monthly",
          label: "Mensuel",
        },
        {
          value: "yearly",
          label: "Annuel",
        },
      ],
    };
  },

  computed: {
    recurrenceDisabled() {
      return (
        this.form.status.length > 0 &&
        !this.form.status.some((s) => s.recurrence)
      );
    },

    onlyStatusIsAdmin() {
      return (
        this.form.status.some(({ value }) => value === "admin") &&
        this.form.status.length === 1
      );
    },
  },

  mounted() {
    if (this.default) {
      this.form = {
        ...this.form,
        ...this.default,
      };
    }
  },

  methods: {
    apply() {
      this.$emit("apply", this.form);
    },

    exportClients() {
      this.$emit("export", this.form);
    },

    checkStatus() {
      if (this.recurrenceDisabled) {
        this.form.recurrence = [];
      }

      if (this.onlyStatusIsAdmin) {
        this.form.types = [];
      }
    },
  },
};
</script>

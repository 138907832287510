<template>
  <div class="card" v-if="promoCodes.length">
    <div class="card-header"><i class="icon-trophy"></i> Codes Promo</div>

    <div class="card-block">
      <tabs>
        <tab :header="activeHeader">
          <PromoCodesTable
            :promo-codes="activePromoCodes"
            :total-sales="activeTotalSales"
            :total-ca="activeTotalCA"
            :total-commission="activeTotalCommission"
            :can-withdraw="false"
          />
        </tab>
        <tab :header="inactiveHeader">
          <PromoCodesTable
            :promo-codes="inactivePromoCodes"
            :total-sales="inactiveTotalSales"
            :total-ca="inactiveTotalCA"
            :total-commission="inactiveTotalCommission"
            :can-withdraw="false"
          />
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import PromoCodesTable from "../components/PromoCodesTable";

export default {
  components: {
    Tabs,
    Tab,
    PromoCodesTable
  },

  props: {
    promoCodes: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      promoTotalSales: 0,
      promoTotalCA: 0,
      promoTotalCommission: 0
    };
  },

  computed: {
    activePromoCodes() {
      return this.promoCodes.filter(promo => promo.active);
    },

    inactivePromoCodes() {
      return this.promoCodes.filter(promo => !promo.active);
    },

    activeHeader() {
      return `Actifs (${this.activePromoCodes.length})`;
    },

    inactiveHeader() {
      return `Inactifs (${this.inactivePromoCodes.length})`;
    },

    activeTotalSales() {
      let totalSales = 0;

      this.activePromoCodes.forEach(promo => {
        if (promo.stripe?.coupon?.times_redeemed) {
          totalSales += promo.stripe.coupon.times_redeemed;
        }
      });

      return totalSales;
    },

    inactiveTotalSales() {
      let totalSales = 0;

      this.inactivePromoCodes.forEach(promo => {
        if (promo.stripe?.coupon?.times_redeemed) {
          totalSales += promo.stripe.coupon.times_redeemed;
        }
      });

      return totalSales;
    },

    activeTotalCA() {
      let totalCA = 0;

      this.activePromoCodes.forEach(promo => {
        if (promo.totalCA) {
          totalCA += promo.totalCA;
        }
      });

      return totalCA;
    },

    inactiveTotalCA() {
      let totalCA = 0;

      this.inactivePromoCodes.forEach(promo => {
        if (promo.totalCA) {
          totalCA += promo.totalCA;
        }
      });

      return totalCA;
    },

    activeTotalCommission() {
      let totalCommission = 0;

      this.activePromoCodes.forEach(promo => {
        if (promo.influencerDiscount?.euros) {
          totalCommission +=
            promo.influencerDiscount.euros *
            (promo.stripe?.coupon?.times_redeemed || 0);
        } else if (promo.influencerDiscount?.percentage) {
          totalCommission +=
            (promo.influencerDiscount.percentage / 100) * (promo.totalCA || 0);
        }
      });

      return totalCommission;
    },

    inactiveTotalCommission() {
      let totalCommission = 0;

      this.inactivePromoCodes.forEach(promo => {
        if (promo.influencerDiscount?.euros) {
          totalCommission +=
            promo.influencerDiscount.euros *
            (promo.stripe?.coupon?.times_redeemed || 0);
        } else if (promo.influencerDiscount?.percentage) {
          totalCommission +=
            (promo.influencerDiscount.percentage / 100) * (promo.totalCA || 0);
        }
      });

      return totalCommission;
    }
  }
};
</script>

<template>
  <div class="wrapper">
    <loader :isVisible="loading" />

    <div class="animated fadeIn">
      <InvitationCodeFilters
        :default="filters"
        :types="types"
        :categories="categories"
        @update="handleFilters"
        @export="handleExport"
      />

      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Codes d'invitations ({{
            codes.length
          }})

          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modalCodeShow = !modalCodeShow"
            v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Créer des codes
          </button>

          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modalCatShow = !modalCatShow"
            v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Créer une categorie
          </button>
        </div>

        <div class="card-block custom-table">
          <el-table
            :data="paginatedInvitationCodes"
            :default-sort="{ prop: 'startDate', order: 'ascending' }"
            style="width: 100%"
          >
            <el-table-column align="center" prop="code" label="Code">
            </el-table-column>
            <el-table-column
              align="center"
              prop="startDate"
              label="Date de départ"
            >
              <template slot-scope="scope">
                {{ formatDate(scope.row.startDate) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="endDate" label="Date de fin">
              <template slot-scope="scope">
                {{ formatDate(scope.row.endDate) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="type" label="Type">
              <template slot-scope="scope">
                {{ scope.row.type === "live" ? "Live" : "Visio & Live" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="category.name"
              label="Catégorie"
            >
            </el-table-column>
            <el-table-column align="center" prop="groupId" label="Groupe">
              <template slot-scope="scope">
                {{ scope.row.group ? scope.row.group.name : "" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="duration"
              label="Durée (jours)"
            >
            </el-table-column>
            <el-table-column
              v-if="showSentAt"
              align="center"
              label="Envoyé le"
              prop="sentAt"
            >
              <template v-if="scope.row.sentAt" slot-scope="scope">
                {{ formatDate(scope.row.sentAt) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="used" label="Utilisé">
              <template slot-scope="scope">
                {{ scope.row.used ? "Oui" : "Non" }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="showUsedBy"
              align="center"
              prop="liveAccess.client.name"
              label="Utilisé par"
            >
              <template
                v-if="scope.row.liveAccess && scope.row.liveAccess.client"
                slot-scope="scope"
              >
                <a
                  :href="clientHref(scope.row.liveAccess.client.id)"
                  target="_blank"
                  >{{ scope.row.liveAccess.client.name }}
                  <i class="icon-user"></i
                ></a>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <button
                  class="btn btn-sm btn-danger mr-1"
                  @click="deleteInvoiceCode(scope.row.id)"
                  v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
                >
                  <i class="fa fa-times" />
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <paginate
          v-model="currentPage"
          :page-count="pageCount"
          :click-handler="handleCurrentChange"
          prev-text="Précédent"
          next-text="Suivant"
          container-class="pagination"
          style="padding: 0 1.25rem"
        ></paginate>
      </div>
    </div>

    <InvitationCodeModal
      :modalShow="modalCodeShow"
      @close="onModalCodeClose"
      :categories="categories"
      :types="types"
    />
    <InvitationCodeCategoryModal
      :modalShow="modalCatShow"
      @close="onModalCatClose"
    />
  </div>
</template>

<script>
import Loader from "../components/Loader";
import Paginate from "vuejs-paginate";

import moment from "moment-timezone";

import InvitationCodeFilters from "../components/InvitationCodeFilters";
import InvitationCodeModal from "../components/InvitationCodeModal";
import InvitationCodeCategoryModal from "../components/InvitationCodeCategoryModal";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Paginate,
    Loader,
    InvitationCodeFilters,
    InvitationCodeModal,
    InvitationCodeCategoryModal,
  },

  mixins: [DownloadFile],

  data() {
    return {
      filters: {
        used: false,
        sent: false,
        endAfterDate: moment().toDate(),
        category: null,
        type: null,
      },
      loading: false,
      modalCodeShow: false,
      modalCatShow: false,
      codes: [],
      currentPage: 1,
      limit: 10,
      categories: [],
      types: [
        {
          label: "Live",
          value: "live",
        },
        {
          label: "Visio & Live",
          value: "small",
        },
      ],
      showSentAt: false,
      showUsedBy: false,
    };
  },

  computed: {
    paginatedInvitationCodes() {
      return this.codes.slice(
        this.currentPage * this.limit - this.limit,
        this.currentPage * this.limit
      );
    },

    pageCount() {
      return Math.ceil(this.codes.length / this.limit);
    },
  },

  async mounted() {
    await this.fetchInvitationCodes();
    await this.fetchCategories();
  },

  methods: {
    async fetchInvitationCodes() {
      this.loading = true;
      try {
        const { data } = await this.$api.get("/invitation-codes", {
          params: { ...this.filters, categoryId: this.filters.category?.id },
        });

        this.codes = data;
        this.showSentAt = this.filters.sent;
        this.showUsedBy = this.filters.used;
      } catch (e) {
        console.warn(e);
      }

      this.loading = false;
    },

    async fetchCategories() {
      try {
        const { data } = await this.$api.get("/invitation-code-categories");

        this.categories = data;
      } catch (e) {
        console.warn(e);
      }
    },

    handleCurrentChange(val) {
      this.currentPage = val;
    },

    deleteInvoiceCode(id) {
      this.$confirm({
        message: "Attention, cette action est définitive.",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/invitation-codes/${id}`);

            this.codes = this.codes.filter((c) => !(c.id === id));
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    async handleExport(newFilters) {
      this.filters = newFilters;

      try {
        const { data } = await this.$api.get("/invitation-codes/export", {
          params: { ...this.filters, categoryId: this.filters.category?.id },
        });

        this.downloadSecuredFile(data.archive);
      } catch (e) {
        console.warn(e);
      }
    },

    async handleFilters(newFilters) {
      this.filters = newFilters;

      this.fetchInvitationCodes();
    },

    async onModalCodeClose() {
      this.modalCodeShow = false;
      await this.fetchInvitationCodes();
    },

    async onModalCatClose() {
      this.modalCatshow = false;
      await this.fetchCategories();
    },

    clientHref(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      return route.href;
    },
  },
};
</script>

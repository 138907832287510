<template>
  <div>
    <el-table
      ref="table"
      :data="data"
      row-key="id"
      emptyText="Aucune séance durant cette période"
      @row-click="toggleRowExpand"
    >
      <el-table-column prop="name" label="Nom"></el-table-column>
      <el-table-column
        prop="totalSessions"
        label="Total séances (période)"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalCompleted"
        label="Séances validées"
        align="center"
      ></el-table-column>

      <el-table-column
        v-if="type === 'uncompleted'"
        prop="totalUncompleted"
        label="Séances non validées"
        align="center"
      >
        <template slot-scope="scope">
          <span :class="{ 'text-danger': !!scope.row.totalUncompleted }">{{
            scope.row.totalUncompleted
          }}</span>
        </template>
      </el-table-column>

      <template v-else>
        <el-table-column
          prop="totalParticipants"
          label="Nb de participants cumulés"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="averageParticipants"
          label="Participants / séance"
          align="center"
        ></el-table-column>
      </template>

      <el-table-column
        prop="totalLeft"
        label="Séances restantes"
        align="center"
      ></el-table-column>
    </el-table>

    <el-table
      v-if="data.length > 0"
      :show-header="false"
      :data="total"
      class="font-weight-bold"
    >
      <el-table-column prop="name" align="right">
        <template slot-scope="scope">{{
          scope.row.name.toUpperCase()
        }}</template>
      </el-table-column>
      <el-table-column prop="totalSessions" align="center"></el-table-column>
      <el-table-column prop="totalCompleted" align="center"></el-table-column>

      <el-table-column
        v-if="type === 'uncompleted'"
        prop="totalUncompleted"
        align="center"
      >
        <template slot-scope="scope">
          <span :class="{ 'text-danger': !!scope.row.totalUncompleted }">{{
            scope.row.totalUncompleted
          }}</span>
        </template>
      </el-table-column>

      <template v-else>
        <el-table-column
          prop="totalParticipants"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="averageParticipants"
          align="center"
        ></el-table-column>
      </template>

      <el-table-column prop="totalLeft" align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },

    type: {
      type: String,
      default: "completed",
    },
  },

  computed: {
    total() {
      if (this.data.length > 0) {
        const sessions = this.data.map(({ totalSessions }) => totalSessions);
        const totalSessions = sessions.reduce((a, b) => a + b, 0);

        const completed = this.data.map(({ totalCompleted }) => totalCompleted);
        const totalCompleted = completed.reduce((a, b) => a + b, 0);

        const uncompleted = this.data.map(
          ({ totalUncompleted }) => totalUncompleted
        );
        const totalUncompleted = uncompleted.reduce((a, b) => a + b, 0);

        const participants = this.data.map(
          ({ totalParticipants }) => totalParticipants
        );
        const totalParticipants = participants.reduce((a, b) => a + b, 0);

        const averageParticipants = totalSessions
          ? Math.round(totalParticipants / totalSessions)
          : 0;

        const sessionsLeft = this.data.map(({ totalLeft }) => totalLeft);
        const totalLeft = sessionsLeft.reduce((a, b) => a + b, 0);

        return [
          this.type === "uncompleted"
            ? {
                name: "total",
                totalSessions,
                totalCompleted,
                totalUncompleted,
                totalLeft,
              }
            : {
                name: "total",
                totalSessions,
                totalCompleted,
                totalParticipants,
                averageParticipants,
                totalLeft,
              },
        ];
      }

      return [];
    },
  },

  methods: {
    toggleRowExpand(row) {
      this.$refs.table.toggleRowExpansion(row);
    },
  },
};
</script>

<style lang="scss">
.el-table__row--level-1 {
  background-color: #f9f9fa !important;
}
</style>

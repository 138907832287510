<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-graph"></i> Prospects / Clients perdus
    </div>

    <div class="card-block">
      <div class="row">
        <div class="col-12 col-lg-6">
          <span class="font-weight-bold d-block mb-1"
            >Prospects ({{ prospectsTotal }})</span
          >
          <div
            v-if="prospectsTotal > 0"
            class="row d-flex flex-column justify-content-center"
          >
            <div
              class="col-md-12"
              v-for="(prospectList, index) in prospects"
              :key="index"
            >
              <div class="row d-flex justify-content-around align-items-center">
                <div class="col-md-3">
                  <UtmLabel
                    :index="index"
                    :total="prospectsTotal"
                    :quantity="prospectList.length"
                  />
                </div>
                <div class="col-md-9">
                  <LostChart
                    :clients="getChartProspectsData(prospectList)"
                    :total="prospectsTotal"
                    :lostReasons="lostReasons"
                  />
                </div>
              </div>
              <div class="col-12 mb-2 d-flex align-items-end flex-column">
                <ChartLabel
                  v-for="(label, index) in getSourceProspectsData(prospectList)"
                  :key="`${label.value}-${index}`"
                  :label="label"
                  class="col-9"
                />
              </div>
            </div>
          </div>
          <div v-else class="alert alert-info">
            Aucun prospect perdu pour cette période
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <span class="font-weight-bold d-block mb-1"
            >Clients ({{ clientsTotal }})</span
          >
          <div
            v-if="clientsTotal > 0"
            class="row d-flex flex-column justify-content-center"
          >
            <div
              class="col-md-12"
              v-for="(clientList, index) in clients"
              :key="index"
            >
              <div class="row d-flex justify-content-around align-items-center">
                <div class="col-md-3">
                  <UtmLabel
                    :index="index"
                    :quantity="clientList.length"
                    :total="clientsTotal"
                  />
                </div>
                <div class="col-md-9">
                  <LostChart
                    :clients="getChartClientsData(clientList)"
                    :total="prospectsTotal"
                    :lostReasons="lostReasons"
                  />
                </div>
              </div>
              <div class="col-12 mb-2 d-flex align-items-end flex-column">
                <ChartLabel
                  v-for="(label, index) in getSourceClientsData(clientList)"
                  :key="`${label.value}-${index}`"
                  :label="label"
                  class="col-9"
                />
              </div>
            </div>
          </div>
          <div v-else class="alert alert-info">
            Aucun client perdu pour cette période
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LostChart from "../components/LostChart";
import ChartLabel from "../components/ChartLabel";
import UtmLabel from "../components/UtmLabel";

import { mapGetters } from "vuex";

export default {
  components: {
    LostChart,
    ChartLabel,
    UtmLabel,
  },

  props: {
    clients: {
      type: Object,
      default: null,
    },

    prospects: {
      type: Object,
      default: null,
    },

    clientsTotal: {
      type: Number,
      default: 0,
    },

    prospectsTotal: {
      type: Number,
      default: 0,
    },

    showSources: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
      commonUtm: "getCommonUtm",
    }),
  },

  methods: {
    getLabels(clients, total) {
      const labels = [];

      for (const [key, value] of Object.entries(clients)) {
        const percentage = ((value.length / total) * 100)
          .toFixed(2)
          .replace(".", ",");

        if (key === "no-reason") {
          const label = {
            value: key,
            name: `Aucune raison (${value.length}) (soit ${percentage}%)`,
            color: "#d3d3d3",
            percentage,
          };

          if (this.showSources) {
            label.details = this.getSourcesDetails(value);
          }

          labels.push(label);
        } else {
          const lostReason = this.lostReasons.find(
            (reason) => reason.value === key
          );

          const label = {
            value: key,
            name: `${lostReason.label} (${value.length}) (soit ${percentage}%)`,
            color: lostReason.color,
            percentage,
          };

          if (this.showSources) {
            label.details = this.getSourcesDetails(value);
          }

          labels.push(label);
        }
      }

      return labels.sort(
        (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
      );
    },

    getSourceProspectsData(prospects) {
      let sorted = {};

      prospects.forEach((prospect) => {
        if (prospect.prospect.lostReason) {
          if (
            sorted[prospect.prospect.lostReason] &&
            Array.isArray(sorted[prospect.prospect.lostReason])
          ) {
            sorted[prospect.prospect.lostReason].push(prospect);
          } else {
            sorted[prospect.prospect.lostReason] = [prospect];
          }
        } else {
          if (sorted["no-reason"] && Array.isArray(sorted["no-reason"])) {
            sorted["no-reason"].push(prospect);
          } else {
            sorted["no-reason"] = [prospect];
          }
        }
      });

      let total = prospects.length;
      let labels = [];

      if (sorted) {
        labels = this.getLabels(sorted, total);
      }

      return labels;
    },

    getChartProspectsData(prospects) {
      return prospects.reduce((a, p) => {
        const key = p.prospect.lostReason;

        a[key] = a[key] || [];
        a[key].push(p);

        return a;
      }, Object.create(null));
    },

    getSourceClientsData(clients) {
      let sorted = {};

      clients.forEach((client) => {
        if (client.lostReason) {
          if (
            sorted[client.lostReason] &&
            Array.isArray(sorted[client.lostReason])
          ) {
            sorted[client.lostReason].push(client);
          } else {
            sorted[client.lostReason] = [client];
          }
        } else {
          if (sorted["no-reason"] && Array.isArray(sorted["no-reason"])) {
            sorted["no-reason"].push(client);
          } else {
            sorted["no-reason"] = [client];
          }
        }
      });

      let total = clients.length;
      let labels = [];

      if (sorted) {
        return this.getLabels(sorted, total);
      }

      return labels;
    },

    getSourcesDetails(clients) {
      const sourcesValues = [];
      const campaignsValues = [];
      const contentsValues = [];

      for (const client of clients) {
        const { utm_source, utm_campaign, utm_content } = client.marketing;

        if (utm_source && !sourcesValues.includes(utm_source)) {
          sourcesValues.push(utm_source);
        }

        if (utm_campaign && !campaignsValues.includes(utm_campaign)) {
          campaignsValues.push(utm_campaign);
        }

        if (utm_content && !contentsValues.includes(utm_content)) {
          contentsValues.push(utm_content);
        }
      }

      const sources = sourcesValues.map((source) => {
        const sourceClients = clients.filter(
          (client) => client.marketing.utm_source === source
        );

        const percentage = ((sourceClients.length / clients.length) * 100)
          .toFixed(2)
          .replace(".", ",");

        return { label: source, value: sourceClients.length, percentage };
      });

      const campaigns = campaignsValues.map((campaign) => {
        const campaignClients = clients.filter(
          (client) => client.marketing.utm_campaign === campaign
        );

        const percentage = ((campaignClients.length / clients.length) * 100)
          .toFixed(2)
          .replace(".", ",");

        return { label: campaign, value: campaignClients.length, percentage };
      });

      const contents = contentsValues.map((content) => {
        const contentClients = clients.filter(
          (client) => client.marketing.utm_content === content
        );

        const percentage = ((contentClients.length / clients.length) * 100)
          .toFixed(2)
          .replace(".", ",");

        return { label: content, value: contentClients.length, percentage };
      });

      return { sources, campaigns, contents };
    },

    getChartClientsData(clients) {
      return clients.reduce((a, p) => {
        const key = p.lostReason || "no-reason";

        a[key] = a[key] || [];
        a[key].push(p);

        return a;
      }, Object.create(null));
    },
  },
};
</script>

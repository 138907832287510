<template>
  <div class="d-flex flex-wrap">
    <div class="col-12 col-lg-4">
      <OrdersCaList
        :data="data.map((dpt) => dpt.data).flat()"
        :others="others.map((dpt) => dpt.data).flat()"
      />
    </div>
    <div id="map-wrapper" class="col-12 col-lg-8" style="position: relative">
      <FranceMapSvg :default-color="defaultColor" />

      <div
        v-show="currentHoveredId && hoveredDepartmentData"
        id="data-card"
        class="card data-card"
      >
        <template v-if="hoveredDepartmentData && hoveredDepartmentData.data">
          <div class="card-header font-weight-bold">
            {{ hoveredDepartmentData.label }}
          </div>

          <div class="card-body" v-if="hoveredDepartmentData.data.length > 0">
            <OrdersCaList
              :data="hoveredDepartmentData.data"
              :others="hoveredDepartmentData.others"
              :grid="false"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FranceMapSvg from "../../../FranceMapSvg";
import OrdersCaList from "./OrdersCaList";

export default {
  components: {
    FranceMapSvg,
    OrdersCaList,
  },

  data() {
    return {
      currentHoveredId: null,
      defaultColor: "#E1E6EF",
      colorSets: {
        blue: {
          one: "#c1e7ff",
          two: "#86b0cc",
          three: "#4c7c9b",
          four: "#004c6d",
        },
        orange: {
          one: "#ffcebb",
          two: "#f1997a",
          three: "#de6140",
          four: "#c50d07",
        },
        green: {
          one: "#c1ebb8",
          two: "#8ac180",
          three: "#53984b",
          four: "#0a7015",
        },
        purple: {
          one: "#e1b0e8",
          two: "#a577ab",
          three: "#6c4371",
          four: "#37123c",
        },
        red: {
          one: "#e9c3c6",
          two: "#f49091",
          three: "#f15952",
          four: "#e00000",
        },
      },
      dataCardPositionIsSet: false,
    };
  },

  props: {
    data: { type: Array, required: true },
    others: { type: Array, required: true },
    filters: {
      required: true,
      type: Object,
    },
    colorScheme: {
      type: String,
      default: "blue",
    },
  },

  mounted() {
    this.setPathsListeners();
  },

  computed: {
    total() {
      return this.data.reduce((acc, departmentData) => {
        if (departmentData.data) {
          return acc + departmentData.data.length;
        }

        return acc;
      }, 0);
    },

    hoveredDepartmentData() {
      if (this.currentHoveredId) {
        const others =
          this.others.find(
            (departmentData) => departmentData.value === this.currentHoveredId
          )?.data || [];
        return {
          ...this.data.find(
            (departmentData) => departmentData.value === this.currentHoveredId
          ),
          others: others.map((o) => o.orders).flat(),
        };
      }

      return false;
    },

    dataWithRatioPercentage() {
      const data = [...this.data];

      data.forEach((departmentData) => {
        if (departmentData.data) {
          departmentData.ratioPercentage =
            (departmentData.data.length / this.total) * 100;
        } else {
          departmentData.ratioPercentage = 0;
        }
      });

      return data.sort((a, b) => b.ratioPercentage - a.ratioPercentage);
    },

    colorSet() {
      return this.colorSets[this.colorScheme] || this.colorSets.blue;
    },
  },

  methods: {
    setPathsListeners() {
      const paths = document.querySelectorAll("#map-wrapper path");

      paths.forEach((path) => {
        let id;
        let isIdf = false;

        if (path.id.startsWith("IDF")) {
          id = path.id.replace("IDF-", "");
          isIdf = true;
        } else {
          id = path.id.replace("FR-", "");
        }

        const correspondingData = this.dataWithRatioPercentage.find(
          (departmentData) => departmentData.value === id
        );

        if (correspondingData?.ratioPercentage > 0) {
          path.setAttribute(
            "fill",
            this.getColor(correspondingData.ratioPercentage)
          );
        }

        path.addEventListener("mouseover", () => {
          if (!path.hasAttribute("stroke-width")) {
            path.setAttribute("stroke-width", isIdf ? ".6" : "3");
          }

          this.currentHoveredId = id;
        });

        path.addEventListener("mouseout", () => {
          if (path.hasAttribute("stroke-width")) {
            path.removeAttribute("stroke-width");
          }

          if (this.currentHoveredId === id) {
            this.currentHoveredId = null;
          }
        });
      });
    },

    getColor(percentage) {
      if (percentage < 25) {
        return this.colorSet.one;
      }

      if (percentage < 50) {
        return this.colorSet.two;
      }

      if (percentage < 75) {
        return this.colorSet.three;
      }

      if (percentage >= 75) {
        return this.colorSet.four;
      }

      return this.defaultColor;
    },

    setDataCardPosition() {
      const mapWrapper = document.querySelector("#map-wrapper");
      const dataCard = document.querySelector("#data-card");

      if (mapWrapper && dataCard) {
        mapWrapper.addEventListener("mousemove", (e) => {
          const wrapperInPage = mapWrapper.getBoundingClientRect();
          dataCard.style.left = e.x - wrapperInPage.x + "px";
          dataCard.style.top = e.y - wrapperInPage.y + "px";
        });

        this.dataCardPositionIsSet = true;
      }
    },
  },

  watch: {
    currentHoveredId(value) {
      if (value && !this.dataCardPositionIsSet) {
        this.setDataCardPosition();
      }
    },
  },
};
</script>

<style scoped>
.data-card {
  position: absolute;
  width: 300px;
  height: auto;
  transform: translate(-110%, -110%);
}
</style>

<template>
  <div class="wrapper animated fadeIn">
    <Loader :is-visible="loading" />

    <CoachsPicturesFilters @apply="applyFilters" />

    <Tabs v-model="activeTab" @input="changeTab">
      <Tab :header="headerPending">
        <CoachsPicturesGrid
          :data="pending"
          status="pending"
          :total="pendingTotal"
          :rows="limit"
          :page="page"
          @pictureRemoved="updateTotals"
          @pageChange="changePage"
          @reload="fetchPictures"
        />
      </Tab>
      <Tab :header="headerValidated">
        <CoachsPicturesGrid
          :data="validated"
          status="validated"
          :total="validatedTotal"
          :rows="limit"
          :page="page"
          @pictureRemoved="updateTotals"
          @pageChange="changePage"
          @reload="fetchPictures"
        />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

import Loader from "../components/Loader";
import CoachsPicturesFilters from "../components/CoachsPicturesFilters";
import CoachsPicturesGrid from "../components/CoachsPicturesGrid";

export default {
  components: {
    Loader,
    CoachsPicturesFilters,
    Tabs,
    Tab,
    CoachsPicturesGrid,
  },

  data() {
    return {
      activeTab: 0,
      loading: false,
      count: true,
      page: 1,
      limit: 20,

      filters: {
        coachManagers: [],
        name: "",
      },

      pending: [],
      pendingTotal: 0,

      validated: [],
      validatedTotal: 0,
    };
  },

  computed: {
    headerPending() {
      return `En attente (${this.pendingTotal})`;
    },

    headerValidated() {
      return `Validées (${this.validatedTotal})`;
    },
  },

  methods: {
    async fetchPictures() {
      try {
        this.loading = true;

        const params = {
          page: this.page,
          limit: this.limit,
          count: this.count,
          totals: {
            pending: this.pendingTotal,
            validated: this.validatedTotal,
          },
          filters: {
            ...this.filters,
            coachManagers: this.filters.coachManagers.map((c) => c.value),
            status: this.activeTab === 0 ? "pending" : "validated",
          },
        };

        const { data } = await this.$api.get("/users/coachs-pictures", {
          params,
        });

        const { data: pictures, totals } = data;

        if (this.activeTab === 0) {
          this.pending = pictures;
        } else {
          this.validated = pictures;
        }

        this.pendingTotal = totals.pending;
        this.validatedTotal = totals.validated;
        this.count = false;
      } catch (e) {
        this.$errorToast("Impossible de charger les photos");
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      this.count = true;

      this.changePage(1);
    },

    changePage(page) {
      this.page = page;

      this.fetchPictures();
    },

    changeTab(tab) {
      this.activeTab = tab;

      this.changePage(1);
    },

    updateTotals(pictureUpdatedStatus) {
      this.count = true;

      if (this.activeTab === 0) {
        this.pendingTotal -= 1;

        if (pictureUpdatedStatus === "validated") {
          this.validatedTotal += 1;
        }
      } else {
        this.validatedTotal -= 1;

        if (pictureUpdatedStatus === "pending") {
          this.pendingTotal += 1;
        }
      }
    },
  },
};
</script>

<template>
  <div v-if="!loading" class="pt-2 animated fadeIn">
    <div class="row">
      <div class="col-12 h5 mb-0">Abonnement sur la période</div>
      <hr class="w-100 mb-2" />
      <StatsSmallCard
        v-for="stat in subsStats"
        :key="stat.name"
        :stat="stat"
        class="col-sm-3 mb-3"
      />
    </div>

    <div class="row">
      <div class="col-12 h5 mb-0">Essai sur la période</div>
      <hr class="w-100 mb-2" />
      <StatsSmallCard
        v-for="stat in trialStats"
        :key="stat.name"
        :stat="stat"
        class="col-sm-3 mb-3"
      />
    </div>

    <div class="row">
      <div class="col-12 h5 mb-0">Séances sur la période</div>
      <hr class="w-100 mb-2" />
      <StatsSmallCard
        v-for="stat in sessionsStats"
        :key="stat.name"
        :stat="stat"
        class="col-sm-3 mb-3"
      />
    </div>

    <div class="row">
      <div class="col-12 h5 mb-0">Autres informations</div>
      <hr class="w-100 mb-2" />
      <StatsSmallCard
        v-for="stat in caStats"
        :key="stat.name"
        :stat="stat"
        class="col-sm-3 mb-3"
      />

      <StatsSmallCard
        v-for="stat in otherStats"
        :key="stat.name"
        :stat="stat"
        class="col-sm-3 mb-3"
      />
    </div>
  </div>
</template>

<script>
import StatsSmallCard from "../components/StatsSmallCard";

export default {
  components: {
    StatsSmallCard,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      cards: [
        {
          name: "subscribers",
          label: "Abonnement actifs",
          icon: "fa-users",
          category: "subs",
        },

        {
          name: "newSubscribers",
          label: "Nouveaux abonnés",
          icon: "fa-user-plus",
          category: "subs",
        },

        {
          name: "lostSubscribers",
          label: "Abonnés perdus",
          icon: "fa-user-times",
          category: "subs",
        },
        {
          name: "remainingSubscribers",
          label: "Abonnés restants",
          icon: "fa-users",
          category: "subs",
        },
        {
          name: "trialing",
          label: "En cours d'essai",
          icon: "fa-eye",
          category: "trial",
        },
        {
          name: "lostTrials",
          label: "Perdus période d'essai",
          icon: "fa-eye-slash",
          category: "trial",
        },
        {
          name: "prospectsWithoutSubscriptions",
          label: "Prospect sans abonnement",
          icon: "fa-user-times",
          category: "other",
        },
        {
          name: "sessions",
          label: "Séances",
          icon: "fa-line-chart",
          category: "sessions",
        },
        {
          name: "ca",
          label: "CA",
          icon: "fa-money",
          category: "ca",
        },
        {
          name: "coachs",
          label: "Coachs",
          icon: "fa-user",
          category: "sessions",
        },
      ],
    };
  },

  computed: {
    dataSets() {
      let dataSets = [];

      if (this.data) {
        for (const [key, value] of Object.entries(this.data)) {
          const card = this.cards.find((c) => c.name === key);

          if (card) {
            dataSets.push({ ...card, value });
          }
        }
      }

      return dataSets;
    },

    subsStats() {
      return this.dataSets.filter(({ category }) => category === "subs");
    },

    trialStats() {
      return this.dataSets.filter(({ category }) => category === "trial");
    },

    sessionsStats() {
      return this.dataSets.filter(({ category }) => category === "sessions");
    },

    caStats() {
      return this.dataSets.filter(({ category }) => category === "ca");
    },

    otherStats() {
      return this.dataSets.filter(({ category }) => category === "other");
    },
  },
};
</script>

<template>
  <div class="card payments-custom-wrapper">
    <div class="card-header"><i class="icon-credit-card"></i> Paiements</div>

    <div class="card-block">
      <payments
        :payments.sync="payments"
        :client="client"
        show-order
        @captured="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>
import Payments from "../components/Payments";

export default {
  components: {
    Payments,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      payments: [],
    };
  },

  mounted() {
    this.payments = this.client.payments;
  },
};
</script>

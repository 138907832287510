<template>
  <navbar>
    <button
      class="navbar-toggler mobile-sidebar-toggler hidden-lg-up"
      type="button"
      @click="mobileSidebarToggle"
    >
      &#9776;
    </button>

    <a
      class="navbar-brand"
      href="#"
      :style="`background-color: ${brandColor}`"
    ></a>

    <ul class="nav navbar-nav hidden-md-down">
      <li class="nav-item">
        <a
          class="nav-link navbar-toggler sidebar-toggler"
          href="#"
          @click="sidebarToggle"
          >&#9776;</a
        >
      </li>
    </ul>

    <SearchBar
      v-if="
        isTeam(user) &&
        (hasPermission($store.state.user, 'CLIENTS_READ') ||
          hasPermission($store.state.user, 'USERS_MENU_READ') ||
          hasPermission($store.state.user, 'PAYMENTS_READ') ||
          hasPermission($store.state.user, 'ORDERS_READ'))
      "
    ></SearchBar>

    <ul class="nav navbar-nav ml-auto position-relative" v-if="user">
      <li class="nav-item px-1 d-none d-lg-block">{{ user.coach.name }}</li>
      <li class="nav-item">
        <a class="nav-link nav-pill" @click="logout"
          ><i class="icon-logout"></i
        ></a>
      </li>
    </ul>
  </navbar>
</template>
<script>
import navbar from "./Navbar";
import SearchBar from "./SearchBar";
import { mapState } from "vuex";

export default {
  components: {
    navbar,
    SearchBar,
  },
  methods: {
    sidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-hidden");
    },
    mobileSidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-mobile-show");
    },
    asideToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("aside-menu-hidden");
    },
    logout() {
      this.$store.commit("AUTH_LOGOUT");
      this.$router.push({
        name: "login",
        query: {
          redirect: this.$route.fullPath,
        },
      });
    },
  },
  computed: {
    brandColor() {
      if (
        process.env.VUE_APP_API_URL.startsWith("https://api.justcoaching.fr")
      ) {
        return "black";
      }

      return "#20a8d8";
    },
    merchantLogo: function () {
      return this.$store.state.merchant.logo;
    },
    ...mapState({
      merchants: (state) => state.merchants,
      merchant: (state) => state.merchant,
      invoiceSerie: (state) => state.invoiceSerie,
      invoiceSeries: (state) => state.invoiceSeries,
      user: (state) => state.user,
    }),
  },
};
</script>

<style lang="scss">
.sidebar-mobile-show {
  .sidebar {
    z-index: 9999;
  }
}
</style>

<template>
  <div class="animated fadeIn">
    <el-popover trigger="click" placement="top" :width="700">
      <div class="row">
        <div class="col-md-12">
          <h4>{{ name }}</h4>
          <hr />
        </div>
        <div class="col-md-12 text-center">
          (
          <span v-for="(detail, index) in row.details" :key="detail.id">
            (<span v-for="(order, index) in detail.orders" :key="order.id">
              <a
                href="#"
                class="user-link"
                @click="(e) => goToOrder(e, order.id)"
                >{{ order.session }}</a
              >
              <span v-if="index != detail.orders.length - 1"> + </span>
            </span>
            ) / {{ detail.orders.length }}
            <span v-if="index != row.details.length - 1"> + </span>
          </span>
          ) / {{ row.details.length }} =
          <strong>{{ row.result }}</strong>
        </div>
        <div class="col-md-12">
          <el-table :data="row.details" style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="row">
                  <div class="col-md-12">
                    <el-table :data="scope.row.orders" style="width: 100%">
                      <el-table-column label="Séances" prop="session">
                        <template slot-scope="order">
                          <a
                            href="#"
                            @click="(e) => goToOrder(e, order.row.id)"
                            target="_blank"
                            >{{ order.row.session }} séance(s)</a
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="fullname">
              <template slot-scope="scope">
                <a href="#" @click="(e) => goToClient(e, scope.row.id)">{{
                  scope.row.fullname
                }}</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="reference" class="name-wrapper">
        <span v-if="row.result"> #{{ row.rank }} - </span>
        {{ row.result }}
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    row: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    getNbOfOrders() {
      return this.row.details.reduce((acc, detail) => {
        return acc + detail.orders.length;
      }, 0);
    },
  },

  methods: {
    goToClient(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },

    goToOrder(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: id,
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.pills {
  border-radius: 100px;
  background-color: #f5f5f5;
  padding: 3px 20px;
  cursor: pointer;
  word-break: break-word;
  display: inline-block;
}

.blue {
  background-color: #255dc8;
  color: white;
}

.green {
  background-color: #18bdb4;
  color: white;
}

.red {
  background-color: #dc2626;
  color: white;
}

.violet {
  background-color: #7c3aed;
  color: white;
}

.divider {
  width: 100%;
  border-top: 1px solid #131721;
}
</style>

<template>
  <div class="animated fadeIn">
    <Loader :is-visible="loading" />

    <StatsAttributionFilters
      :commercials="commercials"
      :concepts="concepts"
      :default="defaultFilters"
      @apply="applyFilters"
    />

    <StatsAttributionTable
      :commercials-attributions="commercialsAttributions"
      :end-date="filters.end"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";

import Loader from "../components/Loader";
import StatsAttributionFilters from "../components/StatsAttributionFilters";
import StatsAttributionTable from "../components/StatsAttributionTable";

export default {
  components: {
    Loader,
    StatsAttributionFilters,
    StatsAttributionTable,
  },

  data() {
    return {
      loading: false,
      attributions: [],
      commercials: [],
      concepts: [],

      filters: {
        begin: moment().startOf("M").format(),
        end: moment().endOf("M").format(),
        concepts: [],
        commercials: [],
        orderType: null,
      },
      defaultFilters: null,
    };
  },

  computed: {
    commercialsAttributions() {
      const commercials = [];

      this.attributions.forEach((attribCom) => {
        const { email } = attribCom;

        const existing = commercials.find(({ value }) => value === email);

        if (existing) {
          existing.attributions = existing.attributions
            ? [...existing.attributions, attribCom]
            : [attribCom];
        } else {
          const commercial = this.commercials.find(
            ({ value }) => value === email
          );

          if (commercial) {
            commercials.push({ ...commercial, attributions: [attribCom] });
          }
        }
      });

      return commercials;
    },
  },

  async mounted() {
    await this.getCommercials();
    await this.fetchConcepts();

    this.setDefaultFilters();
    this.getAttributions();
  },

  methods: {
    async getCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data
          .map((user) => ({
            label: user.commercial.pseudo,
            value: user.email,
            color: user.commercial.color,
            active: user.commercial.active,
            photo: `${axios.defaults.baseURL}users/${user.id}/commercial-picture`,
          }))
          .sort((a, b) => {
            if (!a.active && !b.active) return 0;

            if (!a.active) return 1;

            if (!b.active) return -1;

            return 0;
          });

        this.filters.commercials = this.commercials.filter((c) => c.active);
      } catch (e) {
        this.commercials = [];
      }
    },

    async fetchConcepts() {
      try {
        const { data } = await this.$api.get("/concepts");

        this.concepts = data.map(({ name }) => ({ label: name, value: name }));

        this.filters.concepts = this.concepts.filter(
          ({ value }) => value === "justcoaching.fr"
        );
      } catch (e) {
        this.concepts = [];
      }
    },

    setDefaultFilters() {
      this.defaultFilters = {
        ...this.filters,
      };
    },

    async getAttributions() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/attributions/commercials", {
          params: {
            ...this.filters,
            commercials: this.filters.commercials.map(({ value }) => value),
            concepts: this.filters.concepts.map(({ value }) => value),
          },
        });

        this.attributions = data;
      } catch (e) {
        this.attributions = [];
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters,
      };

      this.getAttributions();
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">Disponibilités</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.disponibility"
            type="textarea"
            :class="{ 'has-error': !client.disponibility }"
            @change="editClient('disponibility', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{client.disponibility}}</span>
        </dd>
        <dt class="col-sm-4">Objectif</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.objectif"
            type="textarea"
            :class="{ 'has-error': !client.objectif }"
            @change="editClient('objectif', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{client.objectif}}</span>
        </dd>
        <dt class="col-sm-4">Détails objectif</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.clientNotes"
            type="textarea"
            :class="{ 'has-error': !client.clientNotes }"
            @change="editClient('clientNotes', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{client.clientNotes}}</span>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  computed: {
    checkPermission() {
      return this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") && !this.client.deletedAt;
    },
  },

  methods: {
    editClient(name, value) {
      this.$emit("edit", { name, value });
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row mt-1">
        <div class="form-group col-sm-3">
          <label>Nom de l'entreprise</label>
          <input class="form-control" v-model="filters.companyName" />
        </div>
        <div class="form-group col-sm-3">
          <label>Commerciaux</label>
          <multiselect
            placeholder="Choisir un ou des commerciaux"
            v-model="filters.commercials"
            :options="commercials"
            label="label"
            track-by="label"
            class="custom-multiselect"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-6">
          <label>Statuts</label>
          <multiselect
            placeholder="Choisir un ou des commerciaux"
            v-model="filters.statuts"
            :options="companyStatuts"
            label="label"
            track-by="label"
            class="custom-multiselect"
            :multiple="true"
          />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      filters: {
        statuts: [],
        companyName: "",
        commercials: [],
      },
    };
  },

  props: {
    commercials: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      companyStatuts: "getCompanyStatuts",
    }),
  },

  methods: {
    applyFilters() {
      this.$emit("apply-filters", {
        ...this.filters,
        statuts: this.filters.statuts.map((statut) => statut.value),
      });
    },
  },
};
</script>
<style type="text/css" scoped>
.checkboxes {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
}

.type {
  display: flex;
  align-items: center;
}

.type label {
  margin: 0;
}

.type input {
  height: 14px;
  width: 30px;
}
</style>

<template>
  <div>
    <FaqFilters
      :filterTags="tags"
      :default="filters"
      @openModal="openModal = true"
      @update="handleFilters"
    />

    <Loader :isVisible="isLoading" />
    <FaqTable
      :faqs="faqs"
      :tag="currentTag"
      @edit="handleEditFaq"
      @delete="reloadFaqs"
      @reload="reloadFaqs"
    />

    <FaqModal
      :modalShow="openModal"
      @close="onModalClose"
      :tags="tags"
      :faq="currentFaq"
    />
  </div>
</template>

<script>
import Loader from "../Loader";
import FaqFilters from "../settings/FaqFilters";
import FaqModal from "../settings/FaqModal";
import FaqTable from "../settings/FaqTable";

export default {
  components: {
    FaqFilters,
    FaqModal,
    FaqTable,
    Loader,
  },

  props: {
    tags: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      faqs: [],
      form: {
        question: null,
        response: null,
        tags: [],
      },
      filters: {
        tag: "",
      },
      openModal: false,
      currentFaq: {},
      isLoading: false,
    };
  },

  computed: {
    currentTag() {
      return this.tags.find((tag) => tag.code === this.filters.tag);
    },
  },

  methods: {
    async loadFaqs(tagCode) {
      if (!tagCode) {
        this.faqs = [];

        return;
      }

      try {
        this.isLoading = true;

        const { data } = await this.$api.get(`/faq/tags/${tagCode}`);

        this.faqs = data;
      } catch (e) {
        this.$router.push({
          name: "dashboard",
        });
      }

      this.isLoading = false;
    },

    handleEditFaq(faq) {
      this.currentFaq = faq;
      this.openModal = true;
    },

    reloadFaqs() {
      this.currentFaq = {};

      this.loadFaqs(this.filters.tag);
    },

    async handleFilters(newFilters) {
      this.filters = newFilters;

      await this.loadFaqs(this.filters.tag);
    },

    async onModalClose() {
      this.openModal = false;
      this.currentFaq = {};

      await this.reloadTags();
      await this.loadFaqs(this.filters.tag);
    },

    reloadTags() {
      this.$emit("reload-tags");
    },
  },
};
</script>

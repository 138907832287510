<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,

  props: {
    height: {
      type: Number,
      default: 230,
    },

    data: {
      type: Object,
      default: null,
    },

    colors: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      options: {
        datasets: {
          bar: {
            barThickness: 30,
            barPercentage: 0.2,
            categoryPercentage: 0.5,
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
            },
          ],
        },
        tooltips: {
          enabled: true,
          callbacks: {
            title: (items) => {
              const title = items[0].label.split("(")[0];

              return title;
            },
            label: (tooltipItem, data) => {
              const { label } = data.datasets[tooltipItem.datasetIndex];
              const value = tooltipItem.yLabel;
              const percentage = (
                (value * 100) /
                this.getTotalByIndex(tooltipItem.index)
              )
                .toFixed(2)
                .replace(".", ",");

              return `${label}: ${value} (${percentage}%)`;
            },
          },
        },
      },
    };
  },

  computed: {
    dataSets() {
      const dataSets = [
        {
          label: "Femmes",
          backgroundColor: this.colors.female,
          data: [0, 0, 0],
        },
        {
          label: "Hommes",
          backgroundColor: this.colors.male,
          data: [0, 0, 0],
        },
        {
          label: "Autre",
          backgroundColor: this.colors.other,
          data: [0, 0, 0],
        },
      ];

      if (this.data) {
        for (const [key, value] of Object.entries(this.data)) {
          const index = this.getIndexForData(key);

          if (index !== null) {
            dataSets.forEach((dataSet) => {
              const valueKey = this.getValueKeyForDataSet(dataSet);

              if (valueKey) {
                dataSet.data[index] = value[valueKey];
              }
            });
          }
        }
      }

      return dataSets;
    },

    total() {
      return this.youngTotal + this.midTotal + this.oldTotal;
    },

    youngTotal() {
      if (this.data && this.data["young"]) {
        return (
          Object.values(this.data["young"]).reduce((a, b) => a + b, 0) || 0
        );
      }

      return 0;
    },

    youngPercentage() {
      return ((this.youngTotal * 100) / this.total)
        .toFixed(2)
        .replace(".", ",");
    },

    midTotal() {
      if (this.data && this.data["mid"]) {
        return Object.values(this.data["mid"]).reduce((a, b) => a + b, 0) || 0;
      }

      return 0;
    },

    midPercentage() {
      return ((this.midTotal * 100) / this.total).toFixed(2).replace(".", ",");
    },

    oldTotal() {
      if (this.data && this.data["old"]) {
        return Object.values(this.data["old"]).reduce((a, b) => a + b, 0) || 0;
      }

      return 0;
    },

    oldPercentage() {
      return ((this.oldTotal * 100) / this.total).toFixed(2).replace(".", ",");
    },

    chartLabels() {
      const young = `Moins de 30 ans (${this.youngTotal} / ${this.youngPercentage}%)`;
      const mid = `30-49 ans (${this.midTotal} / ${this.midPercentage}%)`;
      const old = `50 ans et plus (${this.oldTotal} / ${this.oldPercentage}%)`;

      return [young, mid, old];
    },

    computedChartData() {
      return {
        labels: this.chartLabels,
        datasets: this.dataSets,
      };
    },
  },

  mounted() {
    this.renderChart(this.computedChartData, this.options);
  },

  methods: {
    getIndexForData(key) {
      switch (key) {
        case "young":
          return 0;
        case "mid":
          return 1;
        case "old":
          return 2;
        default:
          return null;
      }
    },

    getValueKeyForDataSet(dataset) {
      switch (dataset.label) {
        case "Femmes":
          return "female";
        case "Hommes":
          return "male";
        case "Autre":
          return "other";
        default:
          return null;
      }
    },

    getTotalByIndex(index) {
      switch (index) {
        case 0:
          return this.youngTotal;
        case 1:
          return this.midTotal;
        case 2:
          return this.oldTotal;
      }
    },
  },
};
</script>

<template>
  <span
    class="badge"
    v-if="displayCommercialRelation"
    :style="`background-color:${displayCommercialRelation.color}`"
  >
    {{ displayCommercialRelation.pseudo }}
  </span>
</template>

<script>
export default {
  props: {
    relation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayCommercialRelation() {
      return this.relation?.commercial?.commercial || this.relation;
    },
  },
};
</script>

<template>
  <div class="rank-custom-wrapper">
    <RankLostFilters :default="filters" @update="handleFilters" />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>
    <div class="card text-center" v-else-if="data.length === 0">
      <div class="card-block">
        <blockquote class="card-blockquote">
          Aucune données, appliquer ou modifier les filtres
        </blockquote>
      </div>
    </div>

    <div v-else>
      <Tabs v-model="activeTab">
        <Tab header="Départements">
          <RankLostSector :data="mapData" :filters="filters" />
        </Tab>
        <Tab header="Global">
          <RankLostGlobal :data="data" :filters="filters" />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import RankLostFilters from "../components/rank/lost/RankLostFilters";
import RankLostSector from "../components/rank/lost/RankLostSector";
import RankLostGlobal from "../components/rank/lost/RankLostGlobal";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import { mapGetters } from "vuex";

export default {
  components: {
    RankLostFilters,
    RankLostSector,
    RankLostGlobal,
    Tabs,
    Tab,
  },
  data() {
    return {
      loading: false,
      data: [],
      activeTab: 0,
      filters: this.defaultFilters(),
    };
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
    }),

    zipcodes() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    mapData() {
      const domTom = ["971", "972", "973", "974", "976"];
      const departments = [...this.zipcodes].filter(
        (dpt) => !domTom.includes(dpt.value) && dpt.value !== "all"
      );

      departments.forEach((dpt) => {
        dpt.data = this.departmentClientInfo(dpt.value);
      });

      return departments;
    },
  },

  methods: {
    handleFilters(newFilters) {
      this.filters = newFilters;

      this.loadClients();
    },

    async loadClients() {
      try {
        this.loading = true;

        const { data } = await this.$api.get(`/rank/lost`, {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
            concepts: this.filters.concepts.map((s) => s.value),
            lostReasons: this.filters.lostReasons.map((s) => s.value),
            genders: this.filters.genders,
          },
        });

        this.data = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    departmentClientInfo(dpt) {
      let departmentClients = this.data.filter((client) => {
        if (client.department && typeof client.department === "string") {
          return client.department === dpt;
        }
      });

      return departmentClients;
    },

    defaultFilters() {
      return {
        begin: null,
        end: null,
        lostReasons: [
          {
            label: "Hors Secteur (dans un secteur pas ciblé, pas habituel)",
            value: "no-sector",
          },
          {
            label: "Pas de coach (aucun coach sur l’attribution)",
            value: "problem-coach",
          },
          {
            label: "Perdu coach (problème du au coach)",
            value: "lost-coach",
          },
          {
            label: "Pas de coach femmes",
            value: "lost-women-coach",
          },
        ],
        concepts: [],
        genders: [],
      };
    },
  },
};
</script>

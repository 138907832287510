<template>
  <div class="live-custom-wrapper">
    <div class="animated fadeIn">
      <div class="card" v-if="courses">
        <template>
          <div class="card-header">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <el-checkbox-group v-model="filter.visioAndLiveCheckList">
                <el-checkbox-button name="live" label="live"
                  >Live</el-checkbox-button
                >
                <el-checkbox-button name="small" label="small"
                  >Visio</el-checkbox-button
                >
              </el-checkbox-group>
              <el-checkbox-group v-model="filter.coursesNotDoneOnly">
                <el-checkbox name="courseDone" :disabled="isArchivedTab"
                  >Masquer les cours terminés</el-checkbox
                >
              </el-checkbox-group>
              <el-select
                v-model="filter.liveOriginChosen"
                multiple
                placeholder="Cours"
              >
                <el-option
                  v-for="liveOrigin in liveOrigins"
                  :key="liveOrigin.name"
                  :label="liveOrigin.name"
                  :value="liveOrigin.name"
                ></el-option>
              </el-select>
              <el-select
                v-model="filter.filterCoachs"
                multiple
                placeholder="Coachs"
              >
                <el-option
                  v-for="item in formattedCoachs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="filter.chosenDays"
                multiple
                placeholder="Jour"
              >
                <el-option value="1" label="Lundi"></el-option>
                <el-option value="2" label="Mardi"></el-option>
                <el-option value="3" label="Mercredi"></el-option>
                <el-option value="4" label="Jeudi"></el-option>
                <el-option value="5" label="Vendredi"></el-option>
                <el-option value="6" label="Samedi"></el-option>
                <el-option value="7" label="Dimanche"></el-option>
              </el-select>

              <el-date-picker
                v-model="filter.chosenDates"
                type="daterange"
                unlink-panels
                range-separator="à"
                start-placeholder="Date de début"
                end-placeholder="Date de fin"
                format="dd/MM/yyyy"
              >
              </el-date-picker>
            </div>
          </div>
        </template>
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Programmation
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modalShow = !modalShow"
            v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block custom-table">
          <loader :isVisible="isLoading" />
          <tabs v-if="!isLoading" v-model="activeTab" @input="changeTab">
            <tab :header="headerCourses">
              <CourseTable
                :courses="courses"
                @edit="editCourse"
                @delete="deleteCourse"
                @archive="archiveCourse"
                @sortChange="sortChange"
              />
            </tab>
            <tab :header="headerArchived">
              <CourseTable
                isArchive
                :courses="archivedCourses"
                @edit="editCourse"
                @delete="deleteCourse"
                @sortChange="sortChange"
              />
            </tab>
          </tabs>
        </div>
        <paginate
          v-model="page"
          :key="paginationReRender"
          :page-count="count"
          :click-handler="changePage"
          prev-text="Précédent"
          next-text="Suivant"
          container-class="pagination"
          style="padding: 0 1.25rem"
        ></paginate>
      </div>
    </div>

    <CourseModal
      :modalShow="modalShow"
      :course="selectedCourse"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import CourseModal from "./CourseModal";
import moment from "moment";
import mixin from "../../mixin";
import CourseTable from "../../components/CourseTable";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Loader from "../../components/Loader";

export default {
  mixins: [mixin],
  components: {
    CourseModal,
    Paginate,
    CourseTable,
    Tabs,
    Tab,
    Loader,
  },
  data() {
    return {
      isLoading: false,
      activeTab: 0,
      courses: [],
      coursesTotal: 0,
      archivedCourses: [],
      archivedCoursesTotal: 0,
      filter: {
        liveOriginChosen: [],
        visioAndLiveCheckList: ["live", "small"],
        chosenDates: null,
        chosenDays: [],
        filterCoachs: [],
        coursesNotDoneOnly: true,
        orderBy: {
          order: "descending",
          prop: "date",
        },
      },
      page: 1,
      offset: 0,
      limit: 20,
      countLives: true,
      paginationReRender: false,
      liveOrigins: [],
      coachs: [],
      selectedCourse: {
        hour: new Date().getHours(),
        minutes: 0,
      },
      modalShow: false,
    };
  },
  async mounted() {
    await this.fetchLiveOrigins();

    try {
      await this.fetchCoachs();
    } catch (e) {
      console.log(e);
    }

    await this.fetchCourses(this.offset);
  },

  computed: {
    headerCourses() {
      return `Cours (${this.coursesTotal})`;
    },

    headerArchived() {
      return `Archives (${this.archivedCoursesTotal})`;
    },

    isArchivedTab() {
      return this.activeTab === 1;
    },

    count() {
      return this.isArchivedTab
        ? Math.ceil(this.archivedCoursesTotal / this.limit)
        : Math.ceil(this.coursesTotal / this.limit);
    },

    formattedCoachs() {
      return this.coachs.map((coach) => ({
        label: coach.coach.name,
        value: coach.id,
      }));
    },
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        this.countLives = true;

        this.fetchCourses(this.offset);
        this.togglePaginationReRender();
      },
    },

    "filter.coursesNotDoneOnly": {
      handler() {
        this.fetchCoachs();
      },
    },
  },

  methods: {
    async fetchCourses(offset) {
      this.isLoading = true;

      const isArchive = this.isArchivedTab;

      const { data } = await this.$api.get("/lives", {
        params: {
          filter: {
            ...this.filter,
            isArchive,
          },
          offset,
          limit: this.limit,
          count: this.countLives,
          currentCoursesTotal: this.coursesTotal,
          currentArchivedCoursesTotal: this.archivedCoursesTotal,
        },
      });

      if (isArchive) {
        this.archivedCourses = data.data;
      } else {
        this.courses = data.data;
      }

      this.coursesTotal = data.livesTotal;
      this.archivedCoursesTotal = data.archivedLivesTotal;

      this.countLives = false;

      this.isLoading = false;
    },

    async fetchLiveOrigins() {
      const { data } = await this.$api.get("/live-origins");

      this.liveOrigins = data;
    },

    async fetchCoachs() {
      const isArchive = this.isArchivedTab;
      const coursesNotDoneOnly = this.filter.coursesNotDoneOnly;

      try {
        this.isLoading = true;

        const { data } = await this.$api.get("/lives/coachs", {
          params: {
            isArchive,
            coursesNotDoneOnly,
          },
        });

        this.coachs = data;
      } catch (e) {
        this.coachs = [];
      } finally {
        this.isLoading = false;
      }
    },

    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },

    changePage(page) {
      this.page = page;

      const offset = this.limit * page - this.limit;

      this.fetchCourses(offset);
    },

    changeTab(index) {
      this.activeTab = index;
      this.page = 1;

      if (index === 0) {
        this.countLives = true;
      }

      this.fetchCoachs();
      this.fetchCourses(this.offset);
      this.togglePaginationReRender();
    },

    togglePaginationReRender() {
      this.paginationReRender = !this.paginationReRender;
    },

    async onModalClose() {
      this.modalShow = false;
      this.filter.coursesNotDoneOnly = true;
      this.countLives = true;

      await this.fetchCourses(this.offset);

      this.selectedCourse = {
        hour: new Date().getHours(),
        minutes: 0,
      };
    },

    getBackgroundImage(id) {
      return `${process.env.VUE_APP_API_URL}lives/${id}/background`;
    },

    editCourse(course) {
      this.selectedCourse = course;
      this.modalShow = true;
    },

    deleteCourse(id) {
      this.$confirm({
        message: "Supprimer le cours ?",
        onConfirm: async () => {
          await this.$api.delete(`/lives/${id}`);

          if (this.isArchivedTab) {
            this.archivedCourses = this.archivedCourses.filter(
              (course) => course.id !== id
            );
            this.archivedCoursesTotal -= 1;
          } else {
            this.courses = this.courses.filter((course) => course.id !== id);
            this.coursesTotal -= 1;
          }
        },
      });
    },

    archiveCourse(courseId) {
      this.$confirm({
        message: "Archiver le cours ?",
        onConfirm: async () => {
          await this.$api.post(`/lives/${courseId}/archive`);

          this.courses = this.courses.filter(
            (course) => course.id !== courseId
          );
          this.coursesTotal -= 1;
          this.archivedCoursesTotal += 1;
        },
      });
    },

    sortChange({ order, prop }) {
      this.filter.orderBy = {
        ...this.filter.orderBy,
        order,
        prop,
      };
    },
  },
};
</script>

<style lang="scss">
.live-custom-wrapper {
  .custom-table .cell {
    white-space: nowrap;
  }

  .date-cell {
    text-transform: capitalize;
  }

  .no-ellipsis {
    text-overflow: inherit;
  }

  .course-image {
    max-height: 60px;
    width: auto;
  }
}

/*[TODO] May we improved */
.live-custom-wrapper {
  max-width: calc(100vw - 315px);
}

@media (max-width: 991px) {
  .live-custom-wrapper {
    max-width: calc(100vw - 65px);
  }
}

.aside-menu-fixed.sidebar-hidden {
  .live-custom-wrapper {
    max-width: calc(100vw - 65px);
  }
}
</style>

<template>
  <div class="wrapper">
    <router-view></router-view>
    <loader :isVisible="isLoading" />

    <div v-if="['clients'].indexOf($route.name) > -1" class="animated fadeIn">
      <LiveClientsFilters
        :default="filter"
        @apply="applyFilters"
        @export="exportClients"
      />

      <div class="card">
        <div class="card-header">
          <i class="icon-people"></i> Clients Live
          <span v-if="clients">({{ clients.length }})</span>
        </div>
        <div class="card-block" v-if="clients">
          <ClientsList
            :clients="clients"
            :rows="limit"
            :status-displayed="statusDisplayed"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../../components/Loader";
import LiveClientsFilters from "../../components/LiveClientsFilters";
import ClientsList from "../../components/ClientsList";
import DownloadFile from "../../mixin/DownloadFile";

export default {
  components: {
    ClientsList,
    LiveClientsFilters,
    Loader,
  },
  mixins: [DownloadFile],
  data() {
    return {
      clients: [],
      isLoading: false,
      filter: {
        client: null,
        status: [
          {
            value: "subscribed",
            label: "Abonné",
            recurrence: true,
          },
          {
            value: "trialing",
            label: "Essai",
            recurrence: true,
          },
        ],
        types: [],
        recurrence: [],
      },
      limit: 50,
      statusDisplayed: [],
    };
  },

  async created() {
    await this.getClients(this.offset);
  },

  methods: {
    async getClients() {
      this.isLoading = true;

      const { data } = await this.$api
        .get("/clients/live-clients", {
          params: {
            filter: {
              ...this.filter,
              status: this.filter.status.map(({ value }) => value),
              types: this.filter.types.map(({ value }) => value),
              recurrence: this.filter.recurrence.map(({ value }) => value),
            },
          },
        })
        .catch(() => {
          this.$router.push({
            name: "dashboard",
          });
        });

      this.clients = data.map((client) => this.mapClientData(client));
      this.statusDisplayed = this.filter.status?.map(({ value }) => value);

      this.isLoading = false;
    },

    async exportClients(filter) {
      this.isLoading = true;

      const { data } = await this.$api.get("/clients/live-clients/exports", {
        params: {
          filter: {
            ...filter,
            status: filter.status?.map(({ value }) => value),
            types: filter.types?.map(({ value }) => value),
            recurrence: filter.recurrence?.map(({ value }) => value),
          },
        },
      });

      this.downloadXLSX(data, "Export.xlsx");
      this.isLoading = false;
    },

    applyFilters(form) {
      this.filter = { ...this.filter, ...form };

      this.getClients();
    },

    mapClientData(client) {
      // live status
      if (client.admin) {
        client.liveStatus = "Admin";
      } else if (client.freeAccess) {
        client.liveStatus = "Accès offert";
      } else if (client.subscription) {
        const { status } = client.subscription;

        if (status === "canceled") {
          if (
            client.subscription.trial_end &&
            client.subscription.trial_end > client.subscription.canceled_at
          ) {
            client.liveStatus = "Résilié essai";
          } else {
            client.liveStatus = "Résilié";
          }
        } else if (status === "active") {
          client.liveStatus = "Abonné";
        } else if (status === "trialing") {
          client.liveStatus = "Essai";
        }
      } else {
        client.liveStatus = "Inscrit.e";
      }

      // mode
      if (client.freeAccess) {
        client.mode =
          client.freeAccess.type === "small" ? "Visio & Live" : "Live";
      } else if (client.subscription) {
        client.mode =
          client.subscription.type === "small" ? "Visio & Live" : "Live";
      }

      // recurrence
      if (client.subscription) {
        client.recurrence =
          client.subscription.recurrence === "yearly" ? "Annuel" : "Mensuel";
      }

      // next invoice
      if (client.subscription?.status === "active") {
        client.nextInvoice = client.subscription.current_period_end * 1000;
      }

      // trial end
      if (client.subscription?.status === "trialing") {
        client.trialEnd = client.subscription.trial_end * 1000;
      }

      // canceled at
      if (client.subscription?.status === "canceled") {
        client.canceledAt = client.subscription.canceled_at * 1000;
      }

      return client;
    },
  },
};
</script>

<style type="text/css">
.card-header .float-right {
  margin-left: 10px;
}
.switch.float-right {
  margin-left: 0;
  margin-right: 25px;
}
</style>

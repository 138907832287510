<template>
  <div class="d-flex flex-wrap">
    <div class="col-12 col-lg-4 d-flex flex-wrap">
      <div v-if="topThirty && topThirty.length" class="col-12">
        <div
          class="text-white text-center font-weight-bold"
          :style="`border-radius: 1rem; padding: .5rem; background-color: ${colorSet.four}; `"
        >
          Top {{ topThirty.length }}
        </div>
        <ul class="m-0 px-0 pt-2 dpt-list">
          <li
            v-for="(departmentData, index) in topThirty"
            :key="departmentData.value"
            :id="`list-item-${departmentData.value}`"
            class="d-flex flex-column text-center text-lg-left mb-1"
            style="cursor: pointer"
          >
            <template v-if="departmentData && departmentData.data">
              <span class="font-weight-bold"
                >{{ ++index }} - {{ departmentData.label.substring(4) }}</span
              >
              <span v-if="stats"
                >(Total :
                {{
                  statIsInEuros
                    ? localizedPrice(departmentData.data.total) || "0,00€"
                    : departmentData.data.total
                }}
                /
                {{
                  departmentData.data.totalPercentage
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})</span
              >
            </template>
          </li>
        </ul>
      </div>
    </div>
    <div id="map-wrapper" class="col-12 col-lg-8" style="position: relative">
      <FranceMapSvg :default-color="defaultColor" />

      <div
        v-show="currentHoveredId && hoveredDepartmentData"
        id="data-card"
        class="card data-card"
      >
        <template v-if="hoveredDepartmentData && hoveredDepartmentData.data">
          <div class="card-header font-weight-bold">
            {{ hoveredDepartmentData.label }}
          </div>

          <template v-if="stats">
            <template v-if="stats === 'acquisition'">
              <div class="card-block">
                <span class="font-weight-bold"> Total </span> :
                {{
                  localizedPrice(hoveredDepartmentData.data.total) || "0,00€"
                }}
                ({{
                  hoveredDepartmentData.data.totalPercentage
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
              <div
                v-if="hoveredDepartmentData.data.acquisition !== undefined"
                class="card-block"
              >
                <span class="font-weight-bold"> Acquisition </span>:
                {{
                  localizedPrice(hoveredDepartmentData.data.acquisition) ||
                  "0,00€"
                }}
                ({{
                  hoveredDepartmentData.data.acquisitionPercentage
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
              <div
                v-if="hoveredDepartmentData.data.fidelisation !== undefined"
                class="card-block"
              >
                <span class="font-weight-bold"> Fidélisation </span>:
                {{
                  localizedPrice(hoveredDepartmentData.data.fidelisation) ||
                  "0,00€"
                }}
                ({{
                  hoveredDepartmentData.data.fidelisationPercentage
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
            </template>
            <template v-if="stats === 'subscribers' || stats === 'trialing'">
              <div class="card-block">
                <span class="font-weight-bold">
                  Nombre
                  {{ stats === "trialing" ? "d'essais en cours" : "d'abonnés" }}
                </span>
                :
                {{ hoveredDepartmentData.data.total }}
                ({{
                  hoveredDepartmentData.data.totalPercentage
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
            </template>
            <template v-if="stats === 'lost'">
              <div class="card-block">
                <div>
                  <span class="font-weight-bold">Perdus</span> :
                  {{ hoveredDepartmentData.data.total }} ({{
                    hoveredDepartmentData.data.totalPercentage
                      .toFixed(2)
                      .replace(".", ",") + "%"
                  }})
                </div>
                <ChartLabel
                  v-for="(reason, index) in hoveredDepartmentData.data.reasons"
                  :key="reason.value"
                  :label="reason"
                  :class="{ 'mt-1': index === 0 }"
                />
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FranceMapSvg from "./FranceMapSvg";
import ChartLabel from "../components/ChartLabel";

export default {
  components: {
    FranceMapSvg,
    ChartLabel,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    colorScheme: {
      type: String,
      default: "blue",
    },

    stats: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      currentHoveredId: null,
      defaultColor: "#E1E6EF",
      colorSets: {
        blue: {
          one: "#c1e7ff",
          two: "#86b0cc",
          three: "#4c7c9b",
          four: "#004c6d",
        },
        orange: {
          one: "#ffcebb",
          two: "#f1997a",
          three: "#de6140",
          four: "#c50d07",
        },
        green: {
          one: "#c1ebb8",
          two: "#8ac180",
          three: "#53984b",
          four: "#0a7015",
        },
        purple: {
          one: "#e1b0e8",
          two: "#a577ab",
          three: "#6c4371",
          four: "#37123c",
        },
        red: {
          one: "#e9c3c6",
          two: "#f49091",
          three: "#f15952",
          four: "#e00000",
        },
      },
      dataCardPositionIsSet: false,
    };
  },

  computed: {
    ratioMax() {
      let max = 0;

      this.data.forEach((departmentData) => {
        const percentageValue = departmentData.data?.totalPercentage;

        if (percentageValue && percentageValue > max) {
          max = percentageValue;
        }
      });

      return max;
    },

    dataWithRatioPercentage() {
      const data = [...this.data];

      data.forEach((departmentData) => {
        if (departmentData.data?.totalPercentage) {
          departmentData.ratioPercentage =
            (departmentData.data.totalPercentage / this.ratioMax) * 100;
        } else {
          departmentData.ratioPercentage = 0;
        }
      });

      return data.sort((a, b) => b.ratioPercentage - a.ratioPercentage);
    },

    colorSet() {
      return this.colorSets[this.colorScheme] || this.colorSets.blue;
    },

    hoveredDepartmentData() {
      if (this.currentHoveredId) {
        return this.data.find(
          (departmentData) => departmentData.value === this.currentHoveredId
        );
      }

      return false;
    },

    topThirty() {
      const topThirty = this.dataWithRatioPercentage.slice(0, 30);

      return topThirty.filter(
        (departmentData) => departmentData.ratioPercentage !== 0
      );
    },

    statIsInEuros() {
      return this.stats && this.stats === "acquisition";
    },
  },

  mounted() {
    this.setPathsListeners();
    this.setListItemsListeners();
  },

  methods: {
    setPathsListeners() {
      const paths = document.querySelectorAll("#map-wrapper path");

      paths.forEach((path) => {
        let id;
        let isIdf = false;

        if (path.id.startsWith("IDF")) {
          id = path.id.replace("IDF-", "");
          isIdf = true;
        } else {
          id = path.id.replace("FR-", "");
        }

        const correspondingData = this.dataWithRatioPercentage.find(
          (departmentData) => departmentData.value === id
        );

        if (correspondingData?.ratioPercentage > 0) {
          path.setAttribute(
            "fill",
            this.getColor(correspondingData.ratioPercentage)
          );
        }

        path.addEventListener("mouseover", () => {
          if (!path.hasAttribute("stroke-width")) {
            path.setAttribute("stroke-width", isIdf ? ".6" : "3");
          }

          this.currentHoveredId = id;
        });

        path.addEventListener("mouseout", () => {
          if (path.hasAttribute("stroke-width")) {
            path.removeAttribute("stroke-width");
          }

          if (this.currentHoveredId === id) {
            this.currentHoveredId = null;
          }
        });
      });
    },

    setListItemsListeners() {
      const listItems = document.querySelectorAll(".dpt-list li");

      listItems.forEach((item) => {
        const id = item.id.replace("list-item-", "");
        const correspondingPath = document.querySelector(`#FR-${id}`);
        const correspondingIdfPath = document.querySelector(`#IDF-${id}`);

        if (correspondingPath) {
          item.addEventListener("mouseover", () => {
            if (!correspondingPath.hasAttribute("stroke-width")) {
              correspondingPath.setAttribute("stroke-width", "3");
            }

            if (
              correspondingIdfPath &&
              !correspondingIdfPath.hasAttribute("stroke-width")
            ) {
              correspondingIdfPath.setAttribute("stroke-width", ".6");
            }
          });

          item.addEventListener("mouseout", () => {
            if (correspondingPath.hasAttribute("stroke-width")) {
              correspondingPath.removeAttribute("stroke-width");
            }

            if (correspondingIdfPath?.hasAttribute("stroke-width")) {
              correspondingIdfPath.removeAttribute("stroke-width");
            }
          });
        }
      });
    },

    getColor(percentage) {
      if (percentage < 25) {
        return this.colorSet.one;
      }

      if (percentage < 50) {
        return this.colorSet.two;
      }

      if (percentage < 75) {
        return this.colorSet.three;
      }

      if (percentage >= 75) {
        return this.colorSet.four;
      }

      return this.defaultColor;
    },

    localizedPrice(price) {
      return (parseFloat(price) / 100).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
  },
};
</script>

<style scoped>
.data-card {
  position: absolute;
  left: -30%;
  top: 6.5%;
  width: 300px;
  height: auto;
}
</style>

<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <div>
        <i class="icon-list" style="margin-right: 0.5rem"></i> Notifications
        <span v-if="total">({{ total }})</span>
      </div>

      <button
        v-if="hasPermission($store.state.user, 'DYNAMIC_CONTENT_WRITE')"
        type="button"
        class="btn btn-sm btn-primary"
        @click="userEventModal = true"
      >
        <i class="fa fa-plus"></i>&nbsp; Ajouter du contenu
      </button>
    </div>

    <div class="card-block">
      <CustomUserEventTable
        :data="data"
        @updated="updatedContent"
        @deleted="deletedContent"
        @sorted="sortChange"
        @refresh="refreshData"
        @added="addContent"
      />

      <Paginate
        v-if="count"
        v-model="page"
        ref="paginate"
        :page-count="count"
        :click-handler="paginationHandler"
        prev-text="Précédent"
        next-text="Suivant"
        container-class="pagination"
        class="mt-1"
      ></Paginate>
    </div>

    <ModalUserEvent
      :show="userEventModal"
      :close="closeUserEventModal"
      @added="addContent"
    />
  </div>
</template>

<script>
import ModalUserEvent from "../components/ModalUserEvent.vue";
import CustomUserEventTable from "./CustomUserEventTable.vue";
import Paginate from "vuejs-paginate";

export default {
  components: {
    ModalUserEvent,
    CustomUserEventTable,
    Paginate,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    total: {
      type: Number,
      default: 0,
    },

    page: {
      type: Number,
      default: 1,
    },

    rows: {
      type: Number,
      default: 15,
    },
  },

  data() {
    return {
      userEventModal: false,
      pageNumber: null,
    };
  },

  computed: {
    count() {
      return Math.ceil(this.total / this.rows);
    },
  },

  watch: {
    page() {
      if (this.page - 1 !== this.$refs.paginate.selected) {
        this.$refs.paginate.selected = this.page - 1;
      }
    },
  },

  methods: {
    paginationHandler(page) {
      this.$emit("changePage", page);
    },

    closeUserEventModal() {
      this.userEventModal = false;
    },

    addContent() {
      this.$emit("added");
    },

    updatedContent(contentId) {
      this.$emit("updated", contentId);
    },

    deletedContent(contentId) {
      this.$emit("deleted", contentId);
    },

    sortChange(sortBy) {
      this.$emit("sorted", sortBy);
    },

    refreshData() {
      this.$emit("refresh");
    },
  },
};
</script>

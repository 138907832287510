<template>
  <modal
    title="Confirmer"
    v-model="isVisible"
    okText="Envoyer"
    cancelText="Annuler"
    @ok="sendContent()"
    @cancel="closeModal"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <p>
            Voulez-vous envoyer le contenu
            {{ this.userEvent?.title }} avec une notification ?
          </p>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label
            @click.stop=""
            class="switch switch-sm switch-text switch-info"
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="withNotification"
            />
            <span class="switch-label" data-on="Oui" data-off="Non"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal.vue";
import Loader from "../components/Loader.vue";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    close: {
      type: Function,
      required: true,
    },

    userEvent: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      withNotification: false,
      isVisible: false,
    };
  },

  watch: {
    visible(value) {
      this.isVisible = value;
    },
  },

  methods: {
    closeModal() {
      this.close();
    },

    async sendContent() {
      try {
        this.loading = true;

        await this.$api.put(`/events/${this.userEvent.id}/send/coach-app`, {
          withNotification: this.withNotification,
        });

        this.$emit("added");
        this.withNotification = false;
        this.closeModal();
        this.$successToast("Le message à bien été envoyé");
      } catch (e) {
        this.$errorToast("Impossible d'envoyer ce contenu");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div class="row animated fade-in">
    <div class="col-md-4">
      <paginate
        v-model="page"
        :page-count="count"
        :click-handler="paginationHandler"
        v-if="total > limit"
        prev-text="Précédent"
        next-text="Suivant"
        container-class="pagination"
      ></paginate>
      <button
        type="button"
        v-for="stat in statsCommercials"
        :key="stat.id"
        class="btn commercial-stat-btn"
        :class="stat.id === currentStat.id ? 'btn-primary' : 'btn-default'"
        style="width: 100%"
        @click="switchStat(stat)"
      >
        <span>{{ stat.libelle }} <span v-if="!stat.id"> (Nouveau)</span></span>
      </button>
    </div>
    <div class="col-md-8 right-divider">
      <Loader :isVisible="isLoading" />
      <StatsCommercialsBlock
        :defaultStat="currentStat"
        :form="form"
        @create="create"
        @update="update"
        @updateForm="updateForm"
        @remove="remove"
        @reset="reset"
      />
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import Loader from "../components/Loader";
import StatsCommercialsBlock from "../components/StatsCommercialsBlock";
import moment from "moment-timezone";

export default {
  components: {
    Paginate,
    Loader,
    StatsCommercialsBlock,
  },

  data() {
    return {
      page: 1,
      isLoading: false,
      offset: 0,
      limit: 20,
      total: 0,
      statsCommercials: [],
      currentStat: {},
      form: {
        begin: moment().startOf("month").startOf("day").format("YYYY-MM-DD"),
        end: moment().endOf("month").endOf("day").format("YYYY-MM-DD"),
        commercials: [],
        commercialsOptions: [],
      },
    };
  },

  async created() {
    await this.fetchCommercials();
    this.form.commercialsOptions = this.form.commercials;
    await this.fetchOldStatCommercial();
  },

  computed: {
    count() {
      return this.total / this.limit;
    },
  },

  methods: {
    async paginationHandler(page) {
      this.page = page;

      this.offset = this.limit * page - this.limit;

      await this.getStatCommercials();
    },

    async fetchOldStatCommercial() {
      this.isLoading = true;

      const { data } = await this.$api.post("/stats/commercials/old", {
        offset: this.offset,
        limit: this.limit,
      });

      this.total = data.total;
      this.statsCommercials = [{ libelle: "" }].concat(data.data);
      this.isLoading = false;
    },

    async getStatCommercials() {
      this.isLoading = true;

      const { data } = await this.$api.post("/stats/commercials/all", {
        offset: this.offset,
        limit: this.limit,
        ...this.form,
      });

      this.total = data.total;
      this.statsCommercials = data.data;
      this.currentStat = this.statsCommercials[0];
      this.isLoading = false;
    },

    async create(form) {
      try {
        this.isLoading = true;
        const { data } = await this.$api.post("/stats/commercials", {
          libelle: form.stat.libelle,
          begin: form.stat.begin,
          end: form.stat.end,
          statCommercialDatas: form.stat.statCommercialDatas,
        });
        await this.getStatCommercials();
        await this.switchStat(data);
      } catch (e) {
        console.log(e);
      }
    },

    async update(form) {
      try {
        if (!form.id) {
          return;
        }
        this.isLoading = true;

        const { data } = await this.$api.put(`/stats/commercials/${form.id}`, {
          libelle: form.libelle,
          statCommercialDatas: form.statCommercialDatas,
        });

        await this.getStatCommercials();
        await this.switchStat(data);
      } catch (e) {
        console.log(e);
      }
    },

    async updateForm(form) {
      this.form = form;

      await this.getStatCommercials();
    },

    async remove(id) {
      try {
        this.isLoading = true;
        await this.$api.delete(`/stats/commercials/${id}`);
        await this.getStatCommercials();
        this.currentStat = this.statsCommercials[0];
      } catch (e) {
        console.log(e);
      }
    },

    async reset() {
      await this.getStatCommercials();
    },

    async switchStat(stat) {
      this.currentStat = stat;
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.form.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
        color: c.commercial.color,
      }));
    },
  },
};
</script>

<style type="text/css">
.commercial-stat-btn {
  margin-top: 2px;
}

.right-divider {
  border-left: 1px solid #d3d3d3;
}
</style>

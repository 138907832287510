<template>
  <div class="col-md-3">
    <div class="commercials-tab">
      <span class="pills" :style="`background-color: ${commercialColor}`">{{
        commercialName
      }}</span>
    </div>
    <div class="commercials-tab">{{ commercial.nbProspects || 0 }}</div>
    <div class="commercials-tab">
      <p>{{ commercial.sendDiscovery || 0 }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.sendDiscoveryPercent || 0 }} %</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.payedDiscovery || 0 }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.unpayedDiscovery || 0 }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.payedDiscoveryPercent || 0 }} %</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.discoveryCa || 0, true) }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.conversionsPack || 0 }} pack(s)</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.packMoyenConversion || 0 }} séance(s)</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.conversionSignaturePercent || 0 }} %</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.conversionsRate || 0 }} %</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.conversionCa || 0, true) }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.conversionPaiement || 0, true) }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.fidelisationPack || 0 }} pack(s)</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.packMoyenFidelisation || 0 }} séances(s)</p>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.fidelisationSignaturePercent || 0 }} %</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.fidelisationCa || 0, true) }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.fidelisationPaiement || 0, true) }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.totalCa || 0, true) }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.totalPaiement || 0, true) }}</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.averageBasket || 0, true) }}</p>
    </div>
    <div class="commercials-tab">
      <div class="input-group">
        <input
          :value="newCallTime"
          @input="newCallTime = $event.target.value"
          type="text"
          class="form-control"
        />
        <span class="input-group-btn">
          <button
            class="btn btn-default"
            type="button"
            @click="updateCommercialCallTime()"
          >
            <i class="fa fa-check" aria-hidden="true"></i>
          </button>
        </span>
      </div>
      /h
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.caByHours || 0, true) }}/h</p>
    </div>
    <div class="commercials-tab d-flex justify-content-between">
      <span>
        {{
          parseFloat(commercial.nbGoogleNotice || 0) +
          parseFloat(commercial.nbTrustpilotNotice || 0)
        }}
        avis
      </span>
      <span>
        <span>
          <i class="fa fa-google" aria-hidden="true"></i>
          {{ commercial.nbGoogleNotice || 0 }}
        </span>
        <span>
          <i class="fa fa-star" aria-hidden="true"></i>
          {{ commercial.nbTrustpilotNotice || 0 }}
        </span>
      </span>
    </div>
    <div class="commercials-tab">
      <p>{{ commercial.unpayedPacks || 0 }} pack(s)</p>
    </div>
    <div class="commercials-tab">
      <p>{{ formatPrice(commercial.unpayedPacksCa || 0, true) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    commercialStat: {
      type: Object,
    },
    edit: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },

  data() {
    return {
      newCallTime: 0,
    };
  },

  mounted() {
    this.newCallTime = this.commercial.callTime;
  },

  computed: {
    commercial() {
      return this.commercialStat;
    },

    commercialName() {
      if (this.commercial?.refCommercial?.commercial?.pseudo) {
        return this.commercial.refCommercial.commercial.pseudo;
      }

      return "Pseudo non défini";
    },

    commercialColor() {
      if (this.commercial?.refCommercial?.commercial?.color) {
        return this.commercial.refCommercial.commercial.color;
      }

      return "#000000";
    },
  },

  methods: {
    async updateCommercialCallTime() {
      this.commercial.callTime = this.newCallTime;
      this.commercial.caByHours = 0;

      if (this.newCallTime && this.newCallTime != 0 && this.commercial.totalCa && this.commercial.totalCa != 0) {
        this.commercial.caByHours = (
          this.commercial.totalCa / this.newCallTime
        ).toFixed(2);
      }

      if (this.id) {
        try {
          await this.$api.patch(`/stats/commercials/${this.id}/call-time`, {
            callTime: this.commercial.callTime,
          });

          this.$successToast("Mise à jour du temps d'appel effectuée.");
        } catch (e) {
          this.$errorToast(
            "Une erreur est survenu, veuillez réessayer plus tard."
          );
        }
      }
    },
  },

  watch: {
    commercial: {
      deep: true,
      handler() {
        this.newCallTime = this.commercial.callTime;
      },
    },
  },
};
</script>

<style type="text/css" scoped>
.commercials-tab {
  display: flex;
  align-items: center;
  min-height: 50px;
  border: 1px solid black;
  padding: 0 10px;
}

.commercials-tab > p {
  margin-bottom: 0;
}

.pills {
  color: white;
  border-radius: 100px;
  background-color: #ffffff;
  padding: 3px 20px;
  display: block;
  display: inline-block;
}
</style>

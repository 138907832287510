<template>
  <modal
    :title="title"
    large
    v-model="show"
    cancelText="Fermer"
    okText="Enregistrer"
    effect="fade/zoom"
    @ok="handleNewClient"
    @cancel="close"
  >
    <loader :is-visible="loading" />

    <div v-if="error.create" class="alert alert-danger">
      {{ error.create }}
    </div>

    <template v-if="form">
      <div class="form-group">
        <label>Email</label>
        <input
          v-model="form.email"
          type="text"
          class="form-control"
          :class="{ 'has-error': error.email }"
          placeholder="exemple : michael@justcoaching.fr"
          autocomplete="off"
          @input="error.email = null"
        />
        <div v-if="error.email" class="text-danger">
          {{ error.email }}
        </div>
      </div>

      <div class="form-group">
        <label>Commercial</label>
        <v-select :options="commercials" v-model="form.commercial"></v-select>
      </div>

      <div class="form-group">
        <label>Concepts</label>
        <multiselect
          v-model="form.concepts"
          :options="concepts"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          label="name"
          track-by="name"
          placeholder="Choisir"
          class="custom-multiselect"
        >
        </multiselect>
      </div>

      <div class="form-group">
        <label>Statut</label>
        <v-select
          v-if="form.prospect"
          :options="prospectStatus"
          :reduce="(status) => status.value"
          v-model="form.prospect.type"
        ></v-select>
        <v-select
          v-else
          :options="clientStatus"
          :reduce="(status) => status.value"
          v-model="form.status"
        ></v-select>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Multiselect from "vue-multiselect";
import Loader from "../components/Loader";
import { mapGetters } from "vuex";
import isEmail from "isemail";

export default {
  components: {
    Modal,
    Multiselect,
    Loader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    default: {
      type: Object,
      required: true,
    },

    commercials: {
      type: Array,
      default: () => [],
    },

    concepts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      error: {
        email: null,
        create: null,
      },

      form: {},
    };
  },

  computed: {
    ...mapGetters({
      prospectStatus: "getProspectStatus",
      clientStatus: "getClientStatus",
    }),

    title() {
      return this.form?.prospect ? "Ajouter un prospect" : "Ajouter un client";
    },
  },

  mounted() {
    this.form = { ...this.default };
  },

  methods: {
    async handleNewClient() {
      this.error = { ...this.error, email: null, create: null };

      if (!this.form.email) {
        this.error.email = "Veuillez renseigner une adresse email";

        return;
      }

      if (!isEmail.validate(this.form.email.trim())) {
        this.error.email = "Le format de l'adresse email n'est pas valide";

        return;
      }

      this.loading = true;

      try {
        const { data } = await this.$api.post("/clients", this.form);

        this.$router.push({
          name: "client-details",
          params: {
            id: data.id,
          },
        });
      } catch (e) {
        this.error.create = `Une erreur est survenue lors de la création du ${
          this.form.prospect ? "prospect" : "client"
        }`;
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.form = { ...this.default };
      this.error = {
        email: null,
        create: null,
      };

      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.has-error {
  border: 1px solid #ff5454;
  border-radius: 4px;
}
</style>

<template>
  <div class="animated fadeIn">
    <ExpenseFilters
      :filterTypes="types"
      :filterCompanys="companys"
      :filterNames="names"
      :filterConcepts="concepts"
      :default="filters"
      @openModal="openModal = true"
      @update="handleFilters"
      @deleteType="deleteSelectedType"
      @deleteName="deleteSelectedName"
      @updateConcepts="updateConcepts"
    />

    <ExpenseTable
      v-if="expenses"
      :expenses="expenses"
      @edit="handleEditExpense"
      @delete="handleDeleteExpense"
    />

    <Pagination
      :total="total"
      :limit="limit"
      :offset="offset"
      @changePage="handleChangePage"
    />

    <ExpenseModal
      :types="types"
      :companys="companys"
      :names="names"
      :modalShow="openModal"
      :expense="currentExpense"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import ExpenseFilters from "../components/ExpenseFilters";
import ExpenseModal from "../components/ExpenseModal";
import ExpenseTable from "../components/ExpenseTable";
import Pagination from "../components/Pagination";

import moment from "moment";

export default {
  components: {
    ExpenseFilters,
    ExpenseModal,
    ExpenseTable,
    Pagination,
  },
  data() {
    return {
      expenses: undefined,
      currentExpense: {},
      openModal: false,
      types: [],
      names: [],
      companys: [],
      concepts: [],
      modalConcepts: [],
      filters: {
        year: "",
        month: "",
        type: "",
        company: "",
        concept: "",
        name: "",
        min: undefined,
        max: undefined,
      },
      total: 0,
      offset: 0,
      limit: 0,
    };
  },
  async created() {
    // Set default filters
    this.filters.year = moment().format("YYYY").toString();
    this.filters.month = moment().format("M").toString();

    await this.loadData();
  },

  methods: {
    async loadData() {
      await this.getTypes();
      await this.getNames();
      await this.getMerchant();
      await this.fetchExpenses();
    },

    async getTypes() {
      const { data } = await this.$api.get("/expenses/types");

      this.types = data;
    },

    async getNames() {
      const { data } = await this.$api.get("/expenses/names");

      this.names = data;
    },

    async getMerchant() {
      const { data } = await this.$api.get("/merchants");

      this.companys = data.map((merchant) => {
        return {
          value: merchant.id,
          label: merchant.name,
        };
      });
    },

    async updateConcepts(company) {
      this.concepts = [];

      if (company && company.value) {
        const params = { merchantId: company.value };
        const { data } = await this.$api.get("/concepts", { params });

        this.concepts = data.map((concept) => {
          return {
            value: concept.id,
            label: concept.name,
          };
        });
      }
    },

    async fetchExpenses() {
      const { data } = await this.$api.get("/expenses", {
        params: {
          ...this.filters,
          company: this.filters.company ? this.filters.company.label : null,
          concept: this.filters.concept ? this.filters.concept.label : null,
          offset: this.offset,
        },
      });

      this.expenses = data.data;
      this.total = data.total;
      this.offset = data.offset;
      this.limit = data.limit;
    },

    handleEditExpense(expense) {
      this.currentExpense = expense;
      this.openModal = true;
      this.fetchExpenses();
    },

    handleDeleteExpense(expense) {
      this.currentExpense = {};

      this.expenses = this.expenses.filter((e) => e.id !== expense.id);
    },

    onModalClose() {
      this.openModal = false;
      this.currentExpense = {};

      this.fetchExpenses();
      this.getTypes();
      this.getNames();
    },

    handleFilters(newFilters) {
      this.filters = newFilters;

      this.fetchExpenses();
    },

    handleChangePage(page) {
      this.offset = (page - 1) * this.limit;

      this.fetchExpenses();
    },

    deleteSelectedType(type) {
      this.$confirm({
        message: `Supprimer le filtre/catégorie type de dépense : "${type}" ?`,
        onConfirm: async () => {
          try {
            await this.$api.delete(`/expenses/type/${type}`);

            await this.getTypes();
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    deleteSelectedName(name) {
      this.$confirm({
        message: `Supprimer le filtre/catégorie nom de dépense : "${name}" ?`,
        onConfirm: async () => {
          try {
            await this.$api.delete(`/expenses/name/${name}`);

            await this.getNames();
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <span> <i class="fa fa-star" /> Notes et commentaires des clients </span>
      Total ({{ totalRatings }})
    </div>
    <div class="card-block">
      <el-table
        :data="paginatedRatings"
        max-height="600"
        :default-sort="sort"
        @sort-change="sortChangeHandler"
      >
        <el-table-column label="Date" width="100" prop="createdAt" sortable>
          <template slot-scope="scope">
            <span class="small">{{ formatDate(scope.row.createdAt) }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Note" width="100" prop="rating" sortable>
          <template slot-scope="scope">
            <span class="text-primary">
              <i
                v-for="star in parseInt(scope.row.rating)"
                :key="star"
                class="fa fa-star"
              />
            </span>
          </template>
        </el-table-column>

        <el-table-column label="Commentaire">
          <template slot-scope="scope">
            <div :key="scope.row.id">
              <span class="font-weight-bold underline">
                <u>{{ scope.row.clientEmail }}</u>
              </span>

              <p class="font-italic mt-1">
                <i class="fa fa-quote-left text-sm" />
                {{ scope.row.comment }}
                <i class="fa fa-quote-right text-sm" />
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="card-footer">
      <paginate
        v-if="totalRatings > 0 && pageCount > 1"
        :page-count="pageCount"
        :click-handler="paginationHandler"
        prev-text="Précédent"
        next-text="Suivant"
        container-class="pagination"
      ></paginate>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  components: {
    Paginate,
  },

  props: {
    coach: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      paginatedRatings: [],
      totalRatings: 0,
      currentPage: 1,
      limit: 1,
      offset: 0,
      sort: { prop: "createdAt", order: "descending" },
    };
  },

  async created() {
    await this.fetchClientsRatings();
  },

  computed: {
    pageCount() {
      return Math.ceil(this.totalRatings / this.limit);
    },
  },

  methods: {
    paginationHandler(currentPage) {
      this.currentPage = currentPage;

      this.offset = currentPage * this.limit - this.limit;

      this.fetchClientsRatings();
    },

    sortChangeHandler(sort) {
      this.sort = sort;
      this.fetchClientsRatings();
    },

    async fetchClientsRatings() {
      try {
        const { data } = await this.$api.get(
          `/users/ratings/${this.coach.id}`,
          {
            params: {
              offset: this.offset,
              limit: this.limit,
              sortBy: this.sort.prop,
              sortOrder: this.sort.order,
            },
          }
        );

        const { ratings, totalRatings } = data;

        this.paginatedRatings = ratings;
        this.totalRatings = totalRatings;
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

import { render, staticRenderFns } from "./UserLocalisationsCard.vue?vue&type=template&id=523b4320"
import script from "./UserLocalisationsCard.vue?vue&type=script&lang=js"
export * from "./UserLocalisationsCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
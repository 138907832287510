<template>
  <div>
    <el-table
      :data="clientsInPage"
      :default-sort="{ prop: 'createdAt', order: 'descending' }"
      @sort-change="sortChange"
    >
      <el-table-column label="Prénom" prop="firstName" sortable="custom">
        <template v-if="!scope.row.company" slot-scope="scope">
          {{ scope.row.firstName }}
        </template>
      </el-table-column>

      <el-table-column label="Nom" prop="lastName" sortable="custom">
        <template slot-scope="scope">
          {{ scope.row.company ? scope.row.company : scope.row.lastName }}
        </template>
      </el-table-column>

      <el-table-column
        label="Ville"
        prop="city"
        align="center"
        sortable="custom"
      >
        <template v-if="scope.row.city" slot-scope="scope">
          {{ scope.row.city }}
          <span v-if="scope.row.zipcode"
            >({{ scope.row.zipcode.substr(0, 2) }})</span
          >
        </template>
      </el-table-column>

      <el-table-column label="Email" prop="email" align="center">
        <template slot-scope="scope">
          {{ scope.row.email }}
          <a :href="clientHref(scope.row.id)" target="_blank"
            ><i class="icon-user"></i
          ></a>
        </template>
      </el-table-column>

      <el-table-column
        label="Téléphone"
        prop="phone"
        align="center"
      ></el-table-column>

      <el-table-column
        label="Statut"
        prop="liveStatus"
        align="center"
        sortable="custom"
      ></el-table-column>

      <el-table-column
        v-if="!adminOnly"
        label="Mode"
        prop="mode"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.mode">{{ scope.row.mode }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="showRecurrence"
        label="Récurrence"
        prop="recurrence"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.recurrence">{{ scope.row.recurrence }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <template
        v-if="!statusDisplayed.length || statusDisplayed.includes('subscribed')"
      >
        <el-table-column
          label="Proch. échéance"
          prop="nextInvoice"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.nextInvoice">{{
              formatDate(scope.row.nextInvoice)
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </template>

      <template
        v-if="!statusDisplayed.length || statusDisplayed.includes('trialing')"
      >
        <el-table-column
          label="Fin essai"
          prop="trialEnd"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.trialEnd">{{
              formatDate(scope.row.trialEnd)
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </template>

      <template
        v-if="!statusDisplayed.length || statusDisplayed.includes('canceled')"
      >
        <el-table-column
          label="Résilié le"
          prop="canceledAt"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.canceledAt">{{
              formatDate(scope.row.canceledAt)
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="Raison" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.lastCancel && scope.row.lastCancel.reason">
              <span
                v-for="(reason, index) in scope.row.lastCancel.reason
                  .unsubscribe_reasons"
                :key="index"
                >{{ reason }}<br />
              </span>
              <span v-if="scope.row.lastCancel.reason.other_reason">
                {{ scope.row.lastCancel.reason.other_reason }}
              </span>
            </div>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Note"
          prop="ratingAfterCancel"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.lastCancel && scope.row.lastCancel.reason">
              <div
                v-if="scope.row.lastCancel.reason.rating_value"
                class="d-flex flex-wrap justify-content-center"
              >
                <StarRating
                  :rating="scope.row.lastCancel.reason.rating_value"
                  class="mr-0"
                />
                <div>({{ scope.row.lastCancel.reason.rating_value }}/5)</div>
              </div>
              <div v-if="scope.row.lastCancel.reason.how_to_improve">
                {{ scope.row.lastCancel.reason.how_to_improve }}
              </div>
            </div>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </template>

      <template
        v-if="!statusDisplayed.length || statusDisplayed.includes('freeAccess')"
      >
        <el-table-column
          label="Offert jusqu'au"
          prop="freeAccess.validUntil"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.freeAccess">{{
              formatDate(scope.row.freeAccess.validUntil)
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </template>

      <el-table-column
        label="Créé le"
        prop="createdAt"
        align="right"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
    </el-table>

    <paginate
      v-if="count"
      v-model="page"
      :key="renderPaginate"
      :page-count="count"
      :click-handler="paginationHandler"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
      class="mt-1"
    ></paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import StarRating from "./StarRating";

import { mapState } from "vuex";

export default {
  components: {
    Paginate,
    StarRating,
  },

  props: {
    clients: {
      type: Array,
      default: null,
    },

    overridePage: {
      type: Number,
      default: 1,
    },

    rows: {
      type: Number,
      default: 20,
    },

    statusDisplayed: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      page: this.$route.query.page || this.overridePage,
      renderPaginate: 0,
      sort: {
        prop: "createdAt",
        order: "descending",
      },
    };
  },

  computed: {
    ...mapState({
      concepts: (state) => state.concepts,
    }),

    count() {
      return Math.ceil(this.clients.length / this.rows);
    },

    sortedClients() {
      const clients = [...this.clients];
      const { prop, order } = this.sort;

      switch (prop) {
        case "firstName":
          return clients.sort((a, b) => {
            const firstNameA = a.firstName.toLowerCase();
            const firstNameB = b.firstName.toLowerCase();

            if (firstNameA === firstNameB) return 0;

            if (firstNameB > firstNameA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "lastName":
          return clients.sort((a, b) => {
            const lastNameA = a.company
              ? a.company.toLowerCase()
              : a.lastName.toLowerCase();
            const lastNameB = b.company
              ? b.company.toLowerCase()
              : b.lastName.toLowerCase();

            if (lastNameA === lastNameB) return 0;

            if (lastNameB > lastNameA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "city":
          return clients.sort((a, b) => {
            const cityA = a.city.toLowerCase();
            const cityB = b.city.toLowerCase();

            if (cityA === cityB) return 0;

            if (cityB > cityA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "liveStatus":
          return clients.sort((a, b) => {
            const liveStatusA = a.liveStatus.toLowerCase();
            const liveStatusB = b.liveStatus.toLowerCase();

            if (liveStatusA === liveStatusB) return 0;

            if (liveStatusB > liveStatusA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "mode":
          return clients.sort((a, b) => {
            const modeA = a.mode?.toLowerCase();
            const modeB = b.mode?.toLowerCase();

            if (!modeA && !modeB) return 0;

            if (!modeA) return 1;

            if (!modeB) return -1;

            if (modeA === modeB) return 0;

            if (modeB > modeA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "recurrence":
          return clients.sort((a, b) => {
            const recurrenceA = a.recurrence?.toLowerCase();
            const recurrenceB = b.recurrence?.toLowerCase();

            if (!recurrenceA && !recurrenceB) return 0;

            if (!recurrenceA) return 1;

            if (!recurrenceB) return -1;

            if (recurrenceA === recurrenceB) return 0;

            if (recurrenceB > recurrenceA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "nextInvoice":
          return clients.sort((a, b) => {
            const nextInvoiceA = a.nextInvoice;
            const nextInvoiceB = b.nextInvoice;

            if (!nextInvoiceA && !nextInvoiceB) return 0;

            if (!nextInvoiceA) return 1;

            if (!nextInvoiceB) return -1;

            if (nextInvoiceA === nextInvoiceB) return 0;

            if (nextInvoiceB > nextInvoiceA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "trialEnd":
          return clients.sort((a, b) => {
            const trialEndA = a.trialEnd;
            const trialEndB = b.trialEnd;

            if (!trialEndA && !trialEndB) return 0;

            if (!trialEndA) return 1;

            if (!trialEndB) return -1;

            if (trialEndA === trialEndB) return 0;

            if (trialEndB > trialEndA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "canceledAt":
          return clients.sort((a, b) => {
            const canceledAtA = a.canceledAt;
            const canceledAtB = b.canceledAt;

            if (!canceledAtA && !canceledAtB) return 0;

            if (!canceledAtA) return 1;

            if (!canceledAtB) return -1;

            if (canceledAtA === canceledAtB) return 0;

            if (canceledAtB > canceledAtA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "ratingAfterCancel":
          return clients.sort((a, b) => {
            const ratingA = a.lastCancel?.reason?.rating_value;
            const ratingB = b.lastCancel?.reason?.rating_value;

            if (!ratingA && !ratingB) return 0;

            if (!ratingA) return 1;

            if (!ratingB) return -1;

            if (ratingA === ratingB) return 0;

            if (ratingB > ratingA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "freeAccess.validUntil":
          return clients.sort((a, b) => {
            const freeUntilA = a.freeAccess?.validUntil;
            const freeUntilB = b.freeAccess?.validUntil;

            if (!freeUntilA && !freeUntilB) return 0;

            if (!freeUntilA) return 1;

            if (!freeUntilB) return -1;

            if (freeUntilA === freeUntilB) return 0;

            if (freeUntilB > freeUntilA) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        case "createdAt":
          return clients.sort((a, b) => {
            if (b.createdAt > a.createdAt) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
        default:
          return clients.sort((a, b) => {
            if (b.createdAt > a.createdAt) {
              return order === "ascending" ? -1 : 1;
            } else {
              return order === "ascending" ? 1 : -1;
            }
          });
      }
    },

    clientsInPage() {
      return this.sortedClients.slice(
        this.page * this.rows - this.rows,
        this.page * this.rows
      );
    },

    adminOnly() {
      return (
        this.statusDisplayed.includes("admin") &&
        this.statusDisplayed.length === 1
      );
    },

    showRecurrence() {
      return (
        !this.statusDisplayed.length ||
        this.statusDisplayed.includes("subscribed") ||
        this.statusDisplayed.includes("trialing") ||
        this.statusDisplayed.includes("canceled")
      );
    },
  },

  methods: {
    paginationHandler(page) {
      this.page = page;
    },

    sortChange({ prop, order }) {
      this.sort = {
        ...this.sort,
        prop,
        order,
      };
      this.page = 1;
      this.renderPaginate++;
    },

    clientHref(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: { id },
      });

      return route.href;
    },

    active(client) {
      let sessions = 0;
      let completed = 0;

      if (client.orders) {
        client.orders.forEach((order) => {
          if (order.completed) {
            return;
          }
          sessions += order.session;
          completed += order.sessions.filter((s) => s.completed).length;
        });
        if (sessions !== completed) {
          return completed + "/" + sessions;
        }
      }
    },
  },

  watch: {
    clients() {
      this.page = 1;
      this.renderPaginate++;
    },
  },
};
</script>

<style type="text/css">
.tableCell-scroll {
  max-width: 180px;
}
</style>

<template>
  <div class="animated fadeIn">
    <PaymentsStatsFilters
      :filters="filters"
      :concepts="concepts"
      :merchants-options="merchantsOptions"
      :commercials="commercials"
      @load="(form) => loadPayments(form)"
    />

    <div class="card card-inverse card-info text-center" v-if="isLoading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>

    <PaymentsStatsResults
      v-if="!isLoading"
      :commercials="filters.commercials"
      :payments="payments"
    />
  </div>
</template>

<script>
import PaymentsStatsFilters from "../components/PaymentsStatsFilters";
import PaymentsStatsResults from "../components/PaymentsStatsResults";

import moment from "moment-timezone";

export default {
  components: {
    PaymentsStatsFilters,
    PaymentsStatsResults,
  },

  data() {
    return {
      isLoading: false,
      payments: [],
      concepts: [],
      merchantsOptions: [],
      commercials: [],
      filters: {
        begin: moment().startOf("month").startOf("day").format("YYYY-MM-DD"),
        end: moment().endOf("month").startOf("day").format("YYYY-MM-DD"),
        orderCreatedAfter: moment("2022-07-01").format("YYYY-MM-DD"),
        concept: null,
        commercials: [],
      },
    };
  },

  async created() {
    await this.getConcepts();
    await this.getMerchants();
    await this.getCommercials();

    const currentCommercial = this.commercials.find((c) => {
      if (this.$store.state.user.email === c.value) {
        return c;
      }
    });

    if (currentCommercial) {
      this.filters.commercials.push(currentCommercial);
    }

    await this.loadPayments();
  },

  methods: {
    async loadPayments(form) {
      this.isLoading = true;

      if (form) {
        this.filters = form;
      }

      try {
        const { data } = await this.$api.get(`/payments/for-stats`, {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
            orderCreatedAfter: this.filters.orderCreatedAfter,
            conceptName: this.filters.concept,
            merchantId: this.filters.merchant
              ? this.filters.merchant.value
              : null,
            commercials:
              this.filters.commercials &&
              this.filters.commercials.map((c) => c.value),
          },
        });

        this.payments = data;
      } catch (e) {
        console.log(e);
      }

      this.isLoading = false;
    },

    async getCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((c) => ({
          value: c.email,
          label: c.commercial.pseudo,
          color: c.commercial?.color,
        }));
      } catch (e) {
        console.log(e);
      }
    },

    async getConcepts() {
      try {
        const { data } = await this.$api.get("/concepts");

        this.concepts = data.map((c) => c.name);
      } catch (e) {
        console.log(e);
      }
    },

    async getMerchants() {
      try {
        const { data } = await this.$api.get("/merchants");

        this.merchantsOptions = data.map((merchant) => {
          return { label: merchant.name, value: merchant.id };
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

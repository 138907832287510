<template>
  <div class="payments-custom-wrapper">
    <PaymentsFilters @update="loadPayments" />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>

    <div class="card" v-else-if="payments && payments.length > 0">
      <div class="card-header hidden-print">
        <i class="icon-credit-card"></i>
        <span v-if="payments"
          >{{ payments.length }} - Total : {{ totalCounts }}</span
        >
      </div>

      <div class="card-block">
        <payments :payments.sync="payments" show-order show-client />
      </div>
    </div>

    <div class="card text-center" v-else>
      <div class="card-block">
        <blockquote class="card-blockquote">
          Aucun paiements à afficher
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentsFilters from "../components/PaymentsFilters";
import Payments from "../components/Payments";

export default {
  components: {
    PaymentsFilters,
    Payments,
  },
  data() {
    return {
      loading: false,
      payments: null,
    };
  },
  computed: {
    totalCounts() {
      let total = 0;

      if (this.payments) {
        for (const p of this.payments) {
          total += p.price;
        }
      }

      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(total / 100);
    },
  },
  methods: {
    async loadPayments(filters) {
      this.loading = true;

      const { min, max, amount } = filters.amountFilter;

      // Sanitarize
      delete filters.amountFilter;

      try {
        const { data } = await this.$api.get("/payments/v2", {
          params: { ...filters, min, max, amount },
        });

        this.payments = data;
      } catch (e) {
        console.warn(e);
      }

      this.loading = false;
    },
  },

  async created() {},
};
</script>

<style lang="css">
/*[TODO] May we improved */
.payments-custom-wrapper {
  max-width: calc(100vw - 320px);
}

@media (max-width: 991px) {
  .payments-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}

@media (max-width: 1200px) {
  .reset-amount {
    margin-right: 25px !important;
  }
  .amount {
    width: 47%;
  }
}

.aside-menu-fixed.sidebar-hidden .payments-custom-wrapper {
  max-width: calc(100vw - 70px);
}
</style>

<template>
  <div
    class="card"
    v-if="
      hasPermission($store.state.user, 'STATS_ACQUISITION_READ') ||
      commercialUser
    "
  >
    <div class="card-header">
      <i class="fa fa-align-justify"></i> Acquisition / Fidélisation
    </div>
    <div class="card-block">
      <div class="row">
        <div :class="`col-12 col-md-${colSize} d-flex align-items-stretch`">
          <div class="card w-100">
            <div class="card-header text-bold">Répartition totale CA</div>

            <div class="card-block">
              <div>
                <span class="text-bold"> Chiffre d'affaire </span> :
                {{ localizedPrice(total) || "0.00€" }}
                ({{
                  ((total / totalInvoices) * 100).toFixed(2).replace(".", ",") +
                  "%"
                }})
              </div>
              <div class="text-muted">
                Total factures:
                {{ localizedPrice(totalInvoices) || "0.00€" }}
              </div>
              <div class="text-muted">
                Avoirs: {{ localizedPrice(totalRefund) || "0.00€" }} ({{
                  ((totalRefund / (total + totalRefund)) * 100)
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
            </div>
            <div class="card-block">
              <span class="text-bold"> Acquisition </span>:
              {{ localizedPrice(totalAcquisition) || "0.00€" }}
              ({{
                ((totalAcquisition / total) * 100)
                  .toFixed(2)
                  .replace(".", ",") + "%"
              }})
            </div>
            <div class="card-block">
              <span class="text-bold"> Fidélisation </span>:
              {{ localizedPrice(totalFidelisation) || "0.00€" }}
              ({{
                ((totalFidelisation / total) * 100)
                  .toFixed(2)
                  .replace(".", ",") + "%"
              }})
            </div>
          </div>
        </div>

        <div
          :class="`col-12 col-md-${colSize}`"
          v-for="(com, index) in commercials"
          :key="index"
        >
          <div class="card">
            <div
              :class="`card-header ${com.color ? 'text-white' : ''}`"
              :style="`background-color: ${com.color ? com.color : '#F9F9FA'}`"
            >
              <span class="text-bold">{{ com.label }}</span>
            </div>

            <div class="card-block">
              <div>
                <span class="text-bold">Chiffre d'affaire</span> :
                {{ localizedPrice(totalCommercial(com)) || "0.00€" }}
                ({{
                  ((totalCommercial(com) / total) * 100)
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
              <div class="text-muted">
                Total factures:
                {{ localizedPrice(totalCommercialInvoices(com)) || "0.00€" }}
              </div>
              <div class="text-muted">
                Avoirs:
                {{ localizedPrice(totalRefundCommercial(com)) || "0.00€" }} ({{
                  (
                    (totalRefundCommercial(com) /
                      (totalCommercial(com) + totalRefundCommercial(com))) *
                    100
                  )
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
                <p class="mb-0">
                  Panier moyen:
                  <span
                    >{{
                      averageCart(
                        totalCommercial(com),
                        getCommercialOrders(com).length
                      )
                    }}
                    €
                  </span>
                </p>
              </div>
            </div>

            <div class="card-block">
              <div>
                <span class="text-bold">Acquisition</span> :
                {{ localizedPrice(acquisitionCommercial(com)) || "0.00€" }} ({{
                  ((acquisitionCommercial(com) / totalCommercial(com)) * 100)
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
              <div>
                <p class="mb-0">
                  Séance découverte:
                  <span v-html="seanceDecouverte(com)"></span>
                </p>
                <p class="mb-0">
                  Pack conversion: <span v-html="packConversion(com)"></span>
                </p>
                <p class="mb-0">
                  Pack one shot/event:
                  <span v-html="packOneShotEvent(com)"></span>
                </p>
                <p class="mb-0">
                  Pack autres: <span v-html="packAutres(com)"></span>
                </p>
                <p class="mb-0">
                  Panier moyen:
                  <span
                    >{{
                      averageCart(
                        acquisitionCommercial(com),
                        acquisitionCommercialOrders(com).length
                      )
                    }}
                    €</span
                  >
                </p>
              </div>
            </div>

            <div class="card-block">
              <div>
                <span class="text-bold">Fidélisation</span> :
                {{ localizedPrice(fidelisationCommercial(com)) || "0.00€" }}
                ({{
                  ((fidelisationCommercial(com) / totalCommercial(com)) * 100)
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
              <div>
                <p class="mb-0">
                  Pack Fidélisation:
                  <span v-html="packFidelisation(com)"></span>
                </p>

                <p class="mb-0">
                  Panier moyen:
                  <span
                    >{{
                      averageCart(
                        fidelisationCommercial(com),
                        fidelisationCommercialOrders(com).length
                      )
                    }}
                    €
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr v-if="departmentsToDisplay && departmentsToDisplay.length > 0" />

      <div
        v-if="departmentsToDisplay && departmentsToDisplay.length > 0"
        class="row pt-1"
      >
        <div class="col-12 text-bold mb-1">Départements</div>

        <tabs class="col-12">
          <tab header="Liste">
            <div class="d-flex flex-wrap">
              <div
                class="col-md-4"
                v-for="dpt in departmentsToDisplay"
                :key="dpt.value"
              >
                <div class="card">
                  <div class="card-header text-bold">
                    {{ dpt.label }}
                  </div>

                  <div class="card-block">
                    <span class="text-bold"> Total </span> :
                    {{ localizedPrice(dpt.data.total) || "0.00€" }}
                    ({{
                      dpt.data.totalPercentage.toFixed(2).replace(".", ",") +
                      "%"
                    }})
                  </div>
                  <div class="card-block">
                    <span class="text-bold"> Acquisition </span>:
                    {{ localizedPrice(dpt.data.acquisition) || "0.00€" }}
                    ({{
                      dpt.data.acquisitionPercentage
                        .toFixed(2)
                        .replace(".", ",") + "%"
                    }})
                  </div>
                  <div class="card-block">
                    <span class="text-bold"> Fidélisation </span>:
                    {{ localizedPrice(dpt.data.fidelisation) || "0.00€" }}
                    ({{
                      dpt.data.fidelisationPercentage
                        .toFixed(2)
                        .replace(".", ",") + "%"
                    }})
                  </div>
                </div>
              </div>
            </div>
          </tab>
          <tab header="Carte">
            <StatsFranceMap :data="mapData" stats="acquisition" class="pt-2" />
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import StatsFranceMap from "../components/StatsFranceMap";

export default {
  components: {
    Tabs,
    Tab,
    StatsFranceMap,
  },

  props: {
    orders: {
      type: Array,
      required: true,
    },
    commercials: {
      type: Array,
      required: true,
    },
    selectedDepartments: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    totalInvoices() {
      return this.sumInvoicesWithoutRefunded(this.orders);
    },

    total() {
      const total = this.sumInvoices(this.orders);

      return total;
    },

    totalRefund() {
      const refundedOrders = this.orders.filter(
        (order) => order.status === "refunded"
      );
      const total = this.sumInvoices(refundedOrders);

      return Math.abs(total);
    },

    totalAcquisition() {
      const acquisitionOrders = this.orders.filter(
        (order) => order.isAcquisition
      );
      const total = this.sumInvoices(acquisitionOrders);

      return total;
    },

    totalFidelisation() {
      const fidelisationOrders = this.orders.filter(
        (order) => !order.isAcquisition
      );
      const total = this.sumInvoices(fidelisationOrders);

      return total;
    },

    colSize() {
      if (this.commercials?.length === 1) {
        return "6";
      }

      if (this.commercials?.length > 1) {
        return "4";
      }

      return "12";
    },

    commercialUser() {
      if (
        this.$store.state.user.commercial &&
        this.$store.state.user.commercial.pseudo
      ) {
        return {
          value: this.$store.state.user.email,
          label: this.$store.state.user.commercial.pseudo,
        };
      }

      return false;
    },

    currentDepartments() {
      let currentDepartments = [];
      this.orders.forEach((order) => {
        if (
          order.refClient.zipcode &&
          typeof order.refClient.zipcode === "string"
        ) {
          const dptCode = order.refClient.zipcode.slice(0, 2);

          if (!currentDepartments.includes(dptCode)) {
            currentDepartments.push(dptCode);
          }
        }
      });

      return this.departments.filter((dpt) =>
        currentDepartments.includes(dpt.value)
      );
    },

    departmentsToDisplay() {
      let departments = [];

      if (this.selectedDepartments.some((dpt) => dpt.value === "all")) {
        departments = [...this.currentDepartments];
      } else {
        departments = [...this.selectedDepartments];
      }

      departments.forEach((dpt) => {
        dpt.data = this.departmentCAInfo(dpt.value);
      });

      return departments.sort((a, b) => b.data.total - a.data.total);
    },

    mapData() {
      const domTom = ["971", "972", "973", "974", "976"];
      const departments = [...this.departments].filter(
        (dpt) => !domTom.includes(dpt.value) && dpt.value !== "all"
      );

      departments.forEach((dpt) => {
        dpt.data = this.departmentCAInfo(dpt.value);
      });

      return departments;
    },
  },

  methods: {
    getCommercialOrders(commercial) {
      const commercialOrders = this.orders.filter((order) => {
        if (order.commercials) {
          const orderCommercial = order.commercials.find(
            (c) => commercial.value === c.userEmail
          );

          if (orderCommercial) {
            return (
              !orderCommercial.relatedSince ||
              (orderCommercial.relatedSince &&
                moment(orderCommercial.relatedSince).isSame(
                  order.createdAt,
                  "day"
                ))
            );
          }
        }
      });

      return commercialOrders;
    },

    sumInvoicesWithoutRefunded(orders) {
      let total = 0;

      orders.forEach((order) => {
        if (order.status !== "refunded") {
          total += order.price;
        }
      });

      return total;
    },

    sumInvoices(orders) {
      let total = 0;

      orders.forEach((order) => {
        if (order.status === "refunded") {
          total -= order.price;
        } else {
          total += order.price;
        }
      });

      return total;
    },

    totalCommercial(commercial) {
      const commercialOrders = this.getCommercialOrders(commercial);
      const total = this.sumInvoices(commercialOrders);

      return total;
    },

    totalCommercialInvoices(commercial) {
      const commercialOrders = this.getCommercialOrders(commercial);
      const total = this.sumInvoicesWithoutRefunded(commercialOrders);

      return total;
    },

    totalRefundCommercial(commercial) {
      const commercialOrders = this.getCommercialOrders(commercial);
      const filteredCommercialOrders = commercialOrders.filter(
        (order) => order.status === "refunded"
      );
      const total = this.sumInvoices(filteredCommercialOrders);

      return Math.abs(total);
    },

    acquisitionCommercial(commercial) {
      const commercialOrders = this.getCommercialOrders(commercial);
      const acquisitionOrders = commercialOrders.filter(
        (order) => order.isAcquisition
      );
      const total = this.sumInvoices(acquisitionOrders);

      return total;
    },

    fidelisationCommercial(commercial) {
      const commercialOrders = this.getCommercialOrders(commercial);
      const fidelisationOrders = commercialOrders.filter(
        (order) => !order.isAcquisition
      );
      const total = this.sumInvoices(fidelisationOrders);

      return total;
    },

    seanceDecouverte(com) {
      const commercialOrders = this.getCommercialOrders(com);
      const acquisitionOrders = commercialOrders
        .filter((order) => order.isConversion)
        .filter((order) => !order.isFidelisation)
        .filter((order) => order.isAcquisition);

      if (acquisitionOrders.length === 0) {
        return "<strong>0</strong>";
      }

      const total = this.sumInvoices(acquisitionOrders);

      return (
        "<strong>" +
        acquisitionOrders.length +
        "</strong> | " +
        this.localizedPrice(total) +
        " (" +
        (
          parseFloat(total) /
          (parseFloat(this.acquisitionCommercial(com)) / 100)
        )
          .toFixed(2)
          .replace(".", ",") +
        "%)"
      );
    },
    packConversion(com) {
      const commercialOrders = this.getCommercialOrders(com);
      const acquisitionOrders = commercialOrders
        .filter((order) => !order.isConversion)
        .filter((order) => order.isFidelisation)
        .filter((order) => order.isAcquisition);

      if (acquisitionOrders.length === 0) {
        return "<strong>0</strong>";
      }

      const total = this.sumInvoices(acquisitionOrders);

      return (
        "<strong>" +
        acquisitionOrders.length +
        "</strong> | " +
        this.localizedPrice(total) +
        " (" +
        (
          parseFloat(total) /
          (parseFloat(this.acquisitionCommercial(com)) / 100)
        )
          .toFixed(2)
          .replace(".", ",") +
        "%)"
      );
    },
    packOneShotEvent(com) {
      const commercialOrders = this.getCommercialOrders(com);
      const acquisitionOrders = commercialOrders
        .filter((order) => order.isConversion)
        .filter((order) => order.isFidelisation)
        .filter((order) => order.isAcquisition);

      if (acquisitionOrders.length === 0) {
        return "<strong>0</strong>";
      }

      const total = this.sumInvoices(acquisitionOrders);

      return (
        "<strong>" +
        acquisitionOrders.length +
        "</strong> | " +
        this.localizedPrice(total) +
        " (" +
        (
          parseFloat(total) /
          (parseFloat(this.acquisitionCommercial(com)) / 100)
        )
          .toFixed(2)
          .replace(".", ",") +
        "%)"
      );
    },
    packFidelisation(com) {
      const commercialOrders = this.getCommercialOrders(com);
      const fidelisationOrders = commercialOrders
        .filter((order) => !order.isConversion)
        .filter((order) => order.isFidelisation)
        .filter((order) => !order.isAcquisition);

      return "<strong>" + fidelisationOrders.length + "</strong>";
    },
    packAutres(com) {
      const commercialOrders = this.getCommercialOrders(com);
      const acquisitionOrders = commercialOrders
        .filter((order) => !order.isConversion)
        .filter((order) => !order.isFidelisation)
        .filter((order) => order.isAcquisition);

      if (acquisitionOrders.length === 0) {
        return "<strong>0</strong>";
      }

      const total = this.sumInvoices(acquisitionOrders);

      return (
        "<strong>" +
        acquisitionOrders.length +
        "</strong> | " +
        this.localizedPrice(total) +
        " (" +
        (
          parseFloat(total) /
          (parseFloat(this.acquisitionCommercial(com)) / 100)
        )
          .toFixed(2)
          .replace(".", ",") +
        "%)"
      );
    },

    averageCart(total, count) {
      if (count === 0) {
        return "0.00";
      }

      return (total / count / 100).toFixed(2);
    },

    acquisitionCommercialOrders(commercial) {
      const commercialOrders = this.getCommercialOrders(commercial);
      return commercialOrders.filter((order) => order.isAcquisition);
    },

    fidelisationCommercialOrders(commercial) {
      const commercialOrders = this.getCommercialOrders(commercial);
      return commercialOrders.filter((order) => !order.isAcquisition);
    },

    departmentCAInfo(dpt) {
      let CAInfo = {
        total: 0,
        totalPercentage: 0,
        acquisition: 0,
        acquisitionPercentage: 0,
        fidelisation: 0,
        fidelisationPercentage: 0,
      };

      let departmentOrders = this.orders.filter((order) => {
        if (
          order.refClient.zipcode &&
          typeof order.refClient.zipcode === "string"
        ) {
          return order.refClient.zipcode.slice(0, 2) === dpt;
        }
      });

      departmentOrders.forEach((order) => {
        if (order.isAcquisition) {
          if (order.status === "refunded") {
            CAInfo.acquisition -= order.price;
          } else {
            CAInfo.acquisition += order.price;
          }
        } else {
          if (order.status === "refunded") {
            CAInfo.fidelisation -= order.price;
          } else {
            CAInfo.fidelisation += order.price;
          }
        }

        if (order.status === "refunded") {
          CAInfo.total -= order.price;
        } else {
          CAInfo.total += order.price;
        }
      });

      CAInfo.totalPercentage = (CAInfo.total / this.total) * 100;
      CAInfo.acquisitionPercentage = (CAInfo.acquisition / CAInfo.total) * 100;
      CAInfo.fidelisationPercentage =
        (CAInfo.fidelisation / CAInfo.total) * 100;

      if (isNaN(CAInfo.totalPercentage)) {
        CAInfo.totalPercentage = 0;
      }

      if (isNaN(CAInfo.acquisitionPercentage)) {
        CAInfo.acquisitionPercentage = 0;
      }

      if (isNaN(CAInfo.fidelisationPercentage)) {
        CAInfo.fidelisationPercentage = 0;
      }

      return CAInfo;
    },

    localizedPrice(price) {
      return (parseFloat(price) / 100)
        .toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        })
        ?.replace(",", ".");
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: 700;
}
</style>

<template>
  <el-table
    :data="data"
    :row-class-name="tableRowClassName"
    max-height="250"
    @row-click="editRow"
  >
    <el-table-column v-if="canEdit" prop="active" align="center" width="60">
      <template slot-scope="scope">
        <el-switch
          v-model="scope.row.active"
          size="mini"
          @click.stop=""
          @change="toggleActive(scope.row)"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="localisation.address"
      label="Adresse"
      min-width="200"
    >
      <template slot-scope="scope">
        <i
          v-if="scope.row.isDefault"
          class="fa fa-star"
          style="color: #fae54d"
        ></i>
        {{ scope.row.localisation.address }}
      </template>
    </el-table-column>
    <el-table-column
      prop="localisation.zipcode"
      label="CP"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="localisation.city"
      label="Ville"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="localisation.country"
      label="Pays"
      align="center"
    ></el-table-column>
    <el-table-column prop="range" label="Rayon" align="center">
      <template slot-scope="scope">{{ scope.row.range }} km </template>
    </el-table-column>
    <el-table-column
      prop="updatedAt"
      label="Mis à jour le"
      width="120"
      align="center"
    >
      <template slot-scope="scope">
        {{ formatDate(scope.row.updatedAt) }}
      </template>
    </el-table-column>
    <el-table-column align="center">
      <template slot-scope="scope">
        <button
          v-if="!scope.row.isDefault"
          type="button"
          class="btn btn-sm btn-danger"
          @click.stop="deleteRow(scope.row)"
        >
          <i class="fa fa-times"></i>
        </button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    canEdit() {
      return (
        this.$store.state.user.coach?.isCoach ||
        this.hasPermission(this.$store.state.user, "USERS_WRITE")
      );
    },
  },

  methods: {
    tableRowClassName({ row }) {
      return row.isDefault ? "default-localisation-row" : "";
    },

    editRow(row) {
      if (row.isDefault) return;

      this.$emit("edit", row);
    },

    deleteRow(row) {
      if (row.isDefault) return;

      this.$confirm({
        message: "Êtes-vous sûr de vouloir supprimer cette localisation ?",
        onConfirm: () => {
          this.$emit("delete", row);
        },
      });
    },

    toggleActive(row) {
      this.$emit("activate", row);
    },
  },
};
</script>

<style lang="postcss">
.el-table {
  .default-localisation-row {
    background-color: #f7f7f7;
    cursor: default;
  }
}
</style>

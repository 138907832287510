var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card coach-manager-relation"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content:
            'Coach manager de la commande. Il est attribué lors de l\'attribution du coach à la commande et peut être different des coachs manager attribués aux prestataires lors du recrutement.',
          placement: 'top',
        }),expression:"{\n          content:\n            'Coach manager de la commande. Il est attribué lors de l\\'attribution du coach à la commande et peut être different des coachs manager attribués aux prestataires lors du recrutement.',\n          placement: 'top',\n        }"}],staticClass:"font-weight-bold"},[_vm._v(" Coach manager "),_c('i',{staticClass:"fa fa-info-circle mr-05"})]),(_vm.hasPermission(_vm.$store.state.user, 'ORDERS_COACH_MANAGER_WRITE'))?_c('el-select',{attrs:{"value":"","size":"mini"},on:{"change":function($event){return _vm.addCoachManager($event)}}},_vm._l((_vm.available),function(coachManager){return _c('el-option',{key:coachManager.value,attrs:{"value":coachManager.value}},[_vm._v(_vm._s(coachManager.label))])}),1):_vm._e()],1),(_vm.related.length > 0)?_c('div',{staticStyle:{"padding":"0.5rem 0"}},_vm._l((_vm.related),function(coachManager,index){return _c('div',{key:`coach-manager-${coachManager.value}`,class:`d-flex justify-content-between ${
          index === 0 ? 'my-1' : 'mb-1'
        }`},[_c('span',{staticClass:"text-white rounded-3 mr-1 d-flex align-items-center",class:!coachManager.color && 'bg-primary',staticStyle:{"padding":"7px"},style:(coachManager.color && `background-color:${coachManager.color}`)},[_c('span',[_vm._v(_vm._s(coachManager.label))]),(
              _vm.hasPermission(_vm.$store.state.user, 'ORDERS_COACH_MANAGER_WRITE')
            )?_c('span',{staticClass:"ml-1",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.removeCoachManager(coachManager.value)}}},[_c('i',{staticClass:"icon-close"})]):_vm._e()]),(
            _vm.hasPermission(_vm.$store.state.user, 'ORDERS_COACH_MANAGER_WRITE')
          )?_c('datepicker',{attrs:{"format":"dd.MM.yyyy","language":"fr","input-class":"form-control","disabled":_vm.disabledDates},on:{"input":function($event){return _vm.newCoachManagerRelationDate($event, coachManager.value)}},model:{value:(coachManager.relatedSince),callback:function ($$v) {_vm.$set(coachManager, "relatedSince", $$v)},expression:"coachManager.relatedSince"}}):_c('span',[_vm._v(_vm._s(_vm.formatDate(coachManager.relatedSince)))])],1)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
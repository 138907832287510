<template>
  <div class="wrapper">
    <Loader :is-visible="loading" />

    <div class="animated fadeIn">
      <DynamicContentFilters :types="types" @apply="applyFilters" />
      <DynamicContentList
        :data="contents"
        :types="types"
        :current-type="currentType"
        :total="total"
        :page="page"
        :rows="limit"
        @changePage="changePage"
        @updated="updatedContent"
        @deleted="deletedContent"
      />
    </div>
  </div>
</template>

<script>
import DynamicContentFilters from "../components/DynamicContentFilters";
import DynamicContentList from "../components/DynamicContentList";
import Loader from "../components/Loader";

export default {
  components: {
    DynamicContentFilters,
    DynamicContentList,
    Loader,
  },

  data() {
    return {
      loading: false,
      filters: {
        type: undefined,
      },

      types: [],
      currentType: null,
      contents: [],
      total: 0,
      page: 1,
      offset: 0,
      limit: 15,
      count: true,
    };
  },

  mounted() {
    this.fetchSettings();
  },

  methods: {
    async fetchSettings() {
      const { data } = await this.$api.get("/settings");

      this.types = data["dynamic_content_types"] || [];
    },

    async fetchContents() {
      if (!this.filters.type) {
        this.$errorToast("Veuillez sélectionner un type de contenu");
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$api.get("/dynamic-contents", {
          params: {
            filters: this.filters,
            offset: this.offset,
            limit: this.limit,
            currentTotal: this.total,
            count: this.count,
          },
        });

        this.contents = data.data;
        this.total = data.total;
        this.currentType = this.filters.type;
        this.count = false;
      } catch (e) {
        this.contents = [];
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.count = this.filters !== filters;
      this.filters = { ...this.filters, ...filters };

      this.changePage(1);
    },

    changePage(page) {
      this.page = page;
      this.offset = page * this.limit - this.limit;

      this.fetchContents();
    },

    updatedContent(content) {
      const contents = [...this.contents];
      const index = contents.findIndex(({ id }) => id === content.id);

      if (index !== -1) {
        contents[index] = { ...this.contents[index], ...content };

        this.contents = contents;
      }
    },

    deletedContent(contentId) {
      this.contents = this.contents.filter(({ id }) => id !== contentId);
      this.total--;
      this.count = true;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-3">
          <label>Filtrer par client</label>
          <v-select
            v-model="filters.client"
            :options="clients"
            @search="searchClients"
          ></v-select>
        </div>
        <div class="form-group col-sm-2">
          <label>Année</label>
          <el-input
            v-model="filters.year"
            size="mini"
            placeholder="ex: 2024"
          ></el-input>
        </div>
        <div class="form-group col-sm-2">
          <label>Numéros de l'attestation</label>
          <el-input
            v-model="filters.sapNumber"
            size="mini"
            placeholder="ex: 2024AT00001"
          ></el-input>
        </div>
        <div class="form-group col-sm-3">
          <label>Envoyé par email</label>
          <el-radio-group v-model="filters.emailSended">
            <el-radio :label="'all'">Tous</el-radio>
            <el-radio :label="'send'">Envoyé</el-radio>
            <el-radio :label="'notSend'">Non envoyé</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="card-footer-custom d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
      <span>
        <i class="fa fa-info-circle mr-05"></i> Les attestations SAP sont
        uniquement présente pour la société
        <strong>Just Coaching Company</strong>
      </span>
      <SapCreateModal @addSap="addSap" />
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import SapCreateModal from "../modal/SapCreateModal";

export default {
  components: {
    SapCreateModal,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: {},
      clients: [],
    };
  },

  async created() {
    this.filters = this.default;
  },

  methods: {
    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter?q=${search}`);

      vm.clients = data.map((c) => ({
        label: c.name + " (" + c.email + ")",
        value: c.email,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    applyFilters() {
      this.$emit("update", { ...this.filters });
    },

    addSap() {
      this.$emit("sapAdd");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-footer-custom {
  background-color: #f9f9fa;
  padding: 10px 20px;
}
</style>

<template>
  <div>
    <table class="table table-condensed el-table">
      <thead>
        <tr>
          <th v-if="coachs">Coach</th>
          <th v-if="documents">Partagé par</th>
          <th>Nom du fichier</th>
          <th>Date de création</th>
          <th v-if="coachs">Coach manager</th>
          <th></th>
        </tr>
      </thead>
      <tbody id="document-table" v-if="docsInPage">
        <tr v-for="(doc, index) in docsInPage" :key="index">
          <td v-if="coachs">
            <DisplayCoachName
              :coach="{ coach: { name: doc.coachName }, id: doc.coachId }"
              cssClass="user-link"
            ></DisplayCoachName>
          </td>
          <td v-if="documents">{{ doc.user }}</td>
          <td>
            <span v-if="doc.document">{{
              doc.document.tag || doc.document.name
            }}</span>
            <span v-else-if="doc.name">{{ doc.tag || doc.name }}</span>
          </td>
          <td v-if="coachs">
            {{
              doc.document && doc.document.createdAt
                ? formatDateTime(doc.document.createdAt)
                : formatDateTime(doc.createdAt)
            }}
          </td>
          <td v-if="coachs">
            <CoachManagerRel v-if="doc.coachManager" :relation="doc" />
          </td>
          <td class="text-right" v-if="coachs">
            <span
              @click="
                downloadSecuredFile(`uploads/document/${doc.document.fileId}`)
              "
              class="btn btn-primary"
            >
              <i class="icon-cloud-download"></i>
              Télécharger
            </span>
            <button
              class="btn btn-success"
              @click="validDocument(doc, index)"
              v-if="hasPermission($store.state.user, 'DOCUMENTS_WRITE')"
            >
              <i class="icon-check"></i>
              Valider
            </button>
            <button
              class="btn btn-danger"
              @click="rejectDocument(doc, index)"
              v-if="hasPermission($store.state.user, 'DOCUMENTS_WRITE')"
            >
              <i class="icon-close"></i>
              Refuser
            </button>
          </td>

          <td class="text-right" v-if="documents">
            <span
              @click="downloadSecuredFile(`uploads/document/${doc.id}`)"
              class="btn btn-primary"
            >
              <i class="icon-cloud-download"></i>
              Télécharger
            </span>
            <button
              class="btn btn-danger"
              @click="removeDocument(doc, index)"
              v-if="
                doc.user === user.email &&
                hasPermission($store.state.user, 'DOCUMENTS_WRITE')
              "
            >
              <i class="icon-close"></i>
              Supprimer
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <paginate
      :page-count="count"
      :click-handler="paginationHandler"
      v-if="count"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
    ></paginate>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Paginate from "vuejs-paginate";
import DisplayCoachName from "../components/coach/displayCoachName";
import CoachManagerRel from "../components/CoachManagerRel";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Paginate,
    DisplayCoachName,
    CoachManagerRel,
  },
  mixins: [DownloadFile],
  props: {
    documents: {
      type: Array,
      default: null,
    },
    coachs: {
      type: Array,
      default: null,
    },
    overridePage: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      page: this.$route.query.page || this.overridePage,
      orderBy: null,
      orderByReverse: false,
    };
  },
  computed: {
    docs() {
      if (this.coachs) {
        let coachs = this.coachs;

        if (this.orderBy) {
          coachs = coachs.sort((a, b) => {
            return a[this.orderBy] < b[this.orderBy]
              ? -1
              : a[this.orderBy] > b[this.orderBy]
              ? 1
              : 0;
          });

          if (this.orderByReverse) {
            coachs = coachs.reverse();
          }
        }

        return coachs;
      } else if (this.documents) {
        return this.documents;
      } else {
        return [];
      }
    },
    docsInPage() {
      if (this.docs) {
        return this.docs.slice(
          this.page * this.rows - this.rows,
          this.page * this.rows
        );
      }

      return [];
    },
    count() {
      if (this.docs) {
        return Math.ceil(this.docs.length / this.rows);
      }

      return undefined;
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    sort(value) {
      if (this.orderBy === value) {
        this.orderByReverse = !this.orderByReverse;
      }

      this.orderBy = value;
    },
    paginationHandler(page) {
      this.page = page;
    },
    validDocument(doc, index) {
      this.$confirm({
        message: "Valider le document ?",
        onConfirm: async () => {
          await this.$api.put(`/users/coachs/document/${doc.document.fileId}`, {
            email: doc.coachEmail,
          });

          this.coachs.splice(index, 1);
        },
      });
    },
    rejectDocument(doc, index) {
      this.$confirm({
        message: "Supprimer le document ? Cette action est irréversible.",
        onConfirm: async () => {
          await this.$api.delete(
            `/users/coachs/document/${doc.document.fileId}`,
            {
              data: {
                email: doc.coachEmail,
              },
            }
          );

          this.coachs.splice(index, 1);
        },
      });
    },
    removeDocument(doc, index) {
      this.$confirm({
        message: "Supprimer le document ? Cette action est irréversible.",
        onConfirm: async () => {
          await this.$api.delete(`/users/document/${doc.id}`);

          this.documents.splice(index, 1);
        },
      });
    },
    goToUser(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>

<template>
  <div>
    <div class="mb-1">
      <strong v-if="session.counter">{{ session.counter }}. </strong>Séance du
      <strong>{{ formatDateTime(session.scheduledAt) }}</strong>
      <span v-if="coachName">
        avec
        <strong>{{ coachName }}</strong>
      </span>
    </div>

    <el-table :data="sessionRatings">
      <el-table-column prop="clientName" label="Client">
        <template slot-scope="scope">
          <a :href="clientHref(scope.row.clientId)" target="_blank">{{
            scope.row.clientName
          }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="rating" label="Note">
        <template slot-scope="scope">
          <div class="d-flex">
            <StarRating :rating="scope.row.rating" />
            <span> {{ scope.row.rating }}/5</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="comment" label="Commentaire">
        <template slot-scope="scope">
          <i v-if="scope.row.comment">{{ scope.row.comment }}</i>
          <i v-else class="d-block text-center">-</i>
        </template>
      </el-table-column>
    </el-table>

    <hr v-if="!isLastItem" />
  </div>
</template>

<script>
import StarRating from "../components/StarRating";

export default {
  components: {
    StarRating,
  },

  props: {
    session: {
      type: Object,
      required: true,
    },

    isLastItem: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sessionRatings() {
      if (this.session.rating?.ratings) {
        return this.session.rating.ratings;
      }

      return [];
    },

    coachName() {
      return this.session.coachs
        ? this.session.coachs[0]?.coach?.name
        : this.session.coach?.name || this.session.coach?.coach?.name;
    },
  },

  methods: {
    clientHref(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      return route.href;
    },
  },
};
</script>

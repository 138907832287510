<template>
  <span class="d-block text-white label" :style="getCss">
    {{ label }} <span v-if="quantity">{{ quantity }}</span
    ><br />
    <template v-if="ratios">(Soit {{ ratios }}%)</template>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    index: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      default: null,
    },
    total: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      utm: null,
    };
  },

  created() {
    const utm = this.commonUtm.find((u) => u.value === this.index);

    if (utm) {
      this.utm = utm;
    }
  },

  computed: {
    ...mapGetters({
      commonUtm: "getCommonUtm",
    }),

    label() {
      if (this.utm) {
        return this.utm.label;
      } else if (this.index) {
        return this.index;
      }

      return "Aucun UTM";
    },

    color() {
      if (this.utm) {
        return this.utm.color;
      }

      return "#000000";
    },

    getCss() {
      return `background-color: ${this.color};`;
    },

    ratios() {
      if (!this.total) {
        return null;
      }

      return ((100 * this.quantity) / this.total).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 15px;
  border-radius: 20px;
  padding: 7px 20px;
}
</style>

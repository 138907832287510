<template>
  <div>
    <div v-if="img && !edit" class="col-sm-12 picture-wrapper">
      <img :src="src" class="rounded img-responsive" />
      <div
        v-if="hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
        class="picture-actions"
      >
        <button class="btn btn-sm btn-info mr-05" @click="edit = true">
          <i class="fa fa-edit"></i>
        </button>
        <button class="btn btn-sm btn-danger" @click="onDelete">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>

    <div
      class="col-sm-12"
      v-else-if="hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
    >
      <button v-if="img && edit" class="btn btn-sm my-1" @click="edit = false">
        <i class="fa fa-rotate-left"></i> Annuler
      </button>

      <dropzone
        :id="dropzoneId"
        :url="apiUploads"
        :headers="apiAuthorization"
        v-on:vdropzone-success="onSuccess"
      >
        <div class="dz-default dz-message">
          <span
            ><i class="icon-cloud-upload"></i><br />Glissez l'image ici</span
          >
        </div>
      </dropzone>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Dropzone from "vue2-dropzone";

export default {
  components: {
    Dropzone,
  },

  props: {
    dropzoneId: {
      type: String,
      required: true,
    },

    img: {
      type: String,
      default: undefined,
    },

    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      edit: false,
      src: undefined,
    };
  },

  computed: {
    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },
  },

  watch: {
    img() {
      this.src = undefined;
      this.src = this.url + "?timestamp=" + new Date().getTime();
    },
  },

  mounted() {
    this.src = this.url;
  },

  methods: {
    onSuccess(file, response) {
      this.$emit("update", response.id);

      this.edit = false;
    },

    onDelete() {
      this.$confirm({
        message: "Voulez-vous vraiment supprimer la photo ?",
        onConfirm: () => {
          this.$emit("update", null);
        },
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.rounded {
  border-radius: 50%;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.picture-wrapper:hover .picture-actions {
  opacity: 1;
}

.picture-actions {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 150ms;
}
</style>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-3">
          <label>Date de début</label>
          <datepicker
            v-model="filters.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
        <div class="form-group col-sm-3">
          <label>Date de fin</label>
          <datepicker
            v-model="filters.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
        <div class="form-group col-sm-2">
          <label>Statut(s)</label>
          <multiselect
            v-model="filters.status"
            :options="statusOptions"
            label="label"
            track-by="label"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-2">
          <label>Prestation(s)</label>
          <multiselect
            v-model="filters.prestations"
            :options="prestationOptions"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-2">
          <label>Département(s)</label>
          <multiselect
            v-model="filters.department"
            :options="dptOptions"
            label="label"
            track-by="value"
          />
        </div>
        <div class="form-group col-sm-2">
          <label>Langue(s)</label>
          <multiselect
            v-model="filters.langs"
            :options="langOptions"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-2">
          <label>Tag(s)</label>
          <multiselect
            v-model="filters.tags"
            :options="tagOptions"
            :multiple="true"
          />
        </div>
        <div class="form-group col-sm-2">
          <label>Genre(s)</label>
          <multiselect
            v-model="filters.gender"
            :options="genderOptions"
            :multiple="true"
            label="label"
            track-by="value"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-3">
          <label for="geocoding-address"
            >Distance
            <i
              class="fa fa-exclamation-circle ml-05"
              v-tooltip="{ content: distanceFilterWarning }"
            ></i>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              style="margin-left: 0.5rem"
              @click="clearDistanceFilter"
            >
              Supprimer le filtre
            </button></label
          >
          <google-adresse-input
            @placeChangedHandler="placeChangedHandler"
          ></google-adresse-input>
        </div>
        <div class="form-group col-sm-3">
          <label>Coach Manager</label>
          <multiselect
            v-model="filters.coachManagers"
            :options="coachManagers"
            :multiple="true"
            label="label"
            track-by="label"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-3">
          <label>Rechercher un prestataire</label>
          <input
            class="form-control"
            v-model="filters.coach"
            placeholder="Rechercher un prestataire"
          />
        </div>
        <div class="form-group col-sm-4 d-flex flex-column">
          <label class="mb-1">Imports</label>
          <el-radio-group v-model="filters.displayImports">
            <el-radio :label="'hidden'">Masqués</el-radio>
            <el-radio :label="'displayed'">Affichés</el-radio>
            <el-radio :label="'importsOnly'">Imports uniquement</el-radio>
          </el-radio-group>
        </div>
        <div class="form-group col-sm-3 d-flex flex-column">
          <label class="mb-1">Plus de données</label>
          <el-checkbox v-model="displayEnrichedData"
            >Afficher toutes les données</el-checkbox
          >
        </div>
        <div class="form-group col-sm-2 d-flex flex-column">
          <label class="mb-1">Totaux</label>
          <el-checkbox v-model="displaySummary"
            >Afficher les totaux</el-checkbox
          >
        </div>
        <div class="form-group col-sm-2" v-if="displayEnrichedData">
          <label>Concept(s)</label>
          <multiselect
            v-model="filters.concept"
            :options="conceptOptions"
            :multiple="true"
          />
        </div>
      </div>
    </div>

    <div class="card-footer d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>

      <button
        type="button"
        class="btn btn-sm btn-primary"
        @click="newCoach"
        v-if="hasPermission($store.state.user, 'USERS_WRITE')"
      >
        <i class="fa fa-plus"></i>&nbsp; Ajouter un nouveau prestataire
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import GoogleAdresseInput from "../components/form/GoogleAdresseInput";
import Langs from "../assets/json/langs.json";

export default {
  components: {
    Datepicker,
    Multiselect,
    GoogleAdresseInput,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },

    coachManagers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      filters: {},
      distance: null,
      displayEnrichedData: false,
      displaySummary: false,
      statusOptions: [],
      prestationOptions: [],
      conceptOptions: [],
      tagOptions: [],
    };
  },

  created() {
    this.filters = { ...this.default };

    this.statusOptions = this.coachStatus.sort((a, b) =>
      a.label > b.label ? 1 : -1
    );

    this.getSettings();
    this.getConcepts();
  },
  computed: {
    ...mapGetters({
      coachStatus: "getCoachStatus",
      genderOptions: "getGenders",
      departments: "getDepartments",
    }),

    langOptions() {
      let options = [];

      Object.keys(Langs).forEach((key) => {
        const { name } = Langs[key];

        options.push(name);
      });

      return options;
    },

    dptOptions() {
      return this.departments.map(({ code, name }) => ({
        label: code === "all" ? name : `${code} - ${name}`,
        value: code,
      }));
    },

    distanceFilterWarning() {
      return "ATTENTION: utiliser ce filtre implique de récupérer plus de données et le temps de réponse sera donc plus long. Pensez-bien à cliquer sur 'Supprimer le filtre' pour effacer votre recherche. Ce filtre va chercher tous les coachs dans un rayon de 30 km OU tous les coachs ayant un rayon d'action supérieur ou égale à la distance et inférieur à 250km.";
    },
  },

  methods: {
    applyFilters() {
      this.$emit("apply", {
        filters: {
          ...this.filters,
          department:
            this.filters.department?.value !== "all"
              ? this.filters.department?.value
              : null,
          gender: this.filters.gender?.map(({ value }) => value),
          status: this.filters.status?.map(({ value }) => value),
        },
        distance: this.distance,
        displayEnrichedData: this.displayEnrichedData,
        displaySummary: this.displaySummary,
      });
    },

    placeChangedHandler(place) {
      if (place.geometry) {
        this.distance = {
          longitude: place.geometry.location.lng(),
          latitude: place.geometry.location.lat(),
        };
      } else {
        this.clearDistanceFilter();
      }
    },

    clearDistanceFilter() {
      const gmapInput = document.getElementById("geocoding-address");

      gmapInput.value = null;

      this.distance = null;
    },

    async getSettings() {
      const { data } = await this.$api.get("/settings");

      if (data["intervenants_prestations"]) {
        this.prestationOptions = data["intervenants_prestations"];
      }

      if (data["intervenants_tags"]) {
        this.tagOptions = data["intervenants_tags"];
      }
    },

    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.conceptOptions = data.map(({ name }) => name);
    },

    newCoach() {
      this.$emit("newCoach");
    },
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <tabs v-model="activeTab" @input="changeTab" class="empty-tab">
        <tab
          :key="`tab-${index}`"
          v-for="(tab, index) of authorizedTabs"
          :header="tab.label"
        >
        </tab>
      </tabs>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Tabs,
    Tab,
  },

  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "Prospects - Clients",
          name: "lost-prospects-clients",
          requiredPermission: "STATS_LOST_PROSPECTS_CLIENTS_READ",
        },
        {
          label: "Sources",
          name: "lost-sources",
          requiredPermission: "STATS_LOST_SOURCES_READ",
        },
        {
          label: "Carte",
          name: "lost-map",
          requiredPermission: "STATS_LOST_MAP_READ",
        },
      ],
    };
  },

  computed: {
    authorizedTabs() {
      return this.tabs.filter((tab) => {
        return this.hasPermission(
          this.$store.state.user,
          tab.requiredPermission
        );
      });
    },
  },

  created() {
    if (this.$route.name === "lost") {
      this.$router.push({ name: this.authorizedTabs[0].name });
    }

    this.setCurrentTab(this.$route.name);
  },

  methods: {
    changeTab(index) {
      this.$router.push({ name: this.authorizedTabs[index].name });
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.authorizedTabs.findIndex(
        (tab) => tab.name === currentPath
      );
    },
  },

  watch: {
    $route(current) {
      if (current.name === "lost") {
        this.$router.push({ name: this.authorizedTabs[0].name });
      }

      this.setCurrentTab(current.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-tab {
  .nav.nav-tabs {
    margin-bottom: 15px;
  }

  .tab-content {
    display: none;
  }
}
</style>

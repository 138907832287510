<template>
  <span
    class="badge"
    v-if="displayCoachManagerRelation"
    :style="`background-color:${displayCoachManagerRelation.color}`"
  >
    {{ displayCoachManagerRelation.pseudo }}
  </span>
</template>

<script>
export default {
  props: {
    relation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayCoachManagerRelation() {
      return this.relation?.coachManager?.coachManager || this.relation;
    },
  },
};
</script>

<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,

  props: {
    height: {
      type: Number,
      default: 200,
    },

    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      options: {
        events: [],
        cutoutPercentage: 70,
      },
      keys: ["female", "male", "other"],
    };
  },

  computed: {
    dataSets() {
      let dataSets = [];
      let dataSet = {
        backgroundColor: [],
        data: [],
      };

      if (this.data) {
        for (const [key, value] of Object.entries(this.data)) {
          if (this.keys.includes(key)) {
            dataSet.backgroundColor.push(value.color);
            dataSet.data.push(value.value);
          }
        }
      }

      dataSets.push(dataSet);

      return dataSets;
    },

    computedChartData() {
      return {
        datasets: this.dataSets,
      };
    },
  },

  mounted() {
    this.renderChart(this.computedChartData, this.options);
  },
};
</script>

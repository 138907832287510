<template>
  <div>
    <!-- Direction -->
    <span class="text-bold" v-if="direction === 'in'">Appel entrant</span>
    <span v-else>Appel sortant</span>

    <br />

    <!-- State -->
    <span class="text-danger" v-if="state === 'CANCELLED'">Annulée</span>
    <span class="text-success" v-if="state === 'ANSWERED'">Répondu</span>
    <span class="text-warning" v-if="state === 'MISSED'">Manqué</span>
    <span class="text-primary" v-if="state === 'VOICEMAIL'">Répondeur</span>
    <span class="text-danger" v-if="state === 'FAILED'">Echec</span>
    <span class="text-warning" v-if="state === 'QUEUE_TIMEOUT'"></span>
    <span class="text-warning" v-if="state === 'BLIND_TRANSFERED'">Tr.</span>
    <span class="text-warning" v-if="state === 'PERMANENT_TRANSFERED'"
      >Tr.</span
    >
    <span class="text-warning" v-if="state === 'NOANSWER_TRANSFERED'"
      >No Rep. Tr.</span
    >
    <span class="text-warning" v-if="state === 'FAX_RECEIVED'">Fax reçu</span>
    <span class="text-warning" v-if="state === 'FAX_OUT_SENT'">Fax Out</span>
    <span class="text-warning" v-if="state === 'INCORRECT_PINCODE'"
      >Erreur code pin</span
    >
    <span class="text-warning" v-if="state === 'FAX_OUT_NOT_SENT'"
      >Fax Out</span
    >
    <span class="text-warning" v-if="state === 'ANNOUNCE'">Annonce</span>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      required: true,
      type: String
    },

    direction: {
      required: true,
      type: String
    }
  }
};
</script>

<style scoped>
.text-bold {
  font-weight: 700;
}
</style>

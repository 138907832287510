<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header">
          Statistiques
        </div>
        <div class="card-block">
          <sessions-calendar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
      //
    },

    data () {
      return {
        //
      }
    }
  }
</script>

<template>
  <div v-if="!loading">
    <div class="row pt-2">
      <div v-for="card in cards" :key="card.name" class="col-sm-4">
        <div class="card">
          <div class="card-header">
            <i class="fa" :class="card.icon"></i> {{ card.label }}
          </div>
          <div class="card-block text-center">
            <span v-if="card.data">
              <p v-for="item in card.data" :key="item.label">
                <span class="font-weight-bold">{{ item.label }}</span>
                ({{ item.value }} réservations)
              </p>
            </span>
            <span v-else>Aucun</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-2">
      <div class="card-header">
        <i class="fa fa-list"></i> Séances ({{ sessions.length }})
      </div>

      <div class="card-block">
        <el-table
          :data="sessionsInPage"
          :default-sort="{ prop: 'session.scheduledAt', order: 'descending' }"
          @sort-change="sortChange"
        >
          <el-table-column
            label="Cours"
            prop="live.name"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Prévu le"
            prop="session.scheduledAt"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ formatDate(scope.row.session.scheduledAt) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Jour"
            prop="day"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Horaire"
            prop="time"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Type"
            prop="live.type"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.live.type === "small" ? "Visio" : "Live" }}
            </template>
          </el-table-column>
          <el-table-column
            label="Réservations"
            prop="bookings"
            align="center"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            label="Coach"
            prop="coach.name"
            align="center"
            sortable="custom"
          >
            <template v-if="scope.row.coach" slot-scope="scope">
              <a :href="coachHref(scope.row.coach.id)" target="_blank">
                {{ scope.row.coach.name || scope.row.coach.firstName }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            label="Avis"
            prop="rating.average"
            align="center"
            sortable="custom"
          >
            <template v-if="scope.row.rating" slot-scope="scope">
              <div
                class="d-flex flex-wrap justify-content-center"
                @click="openRatingsModal(scope.row)"
              >
                <StarRating :rating="scope.row.rating.average" />
                <div style="min-width: min-content">
                  {{ scope.row.rating.average }}/5 ({{
                    scope.row.rating.ratings.length
                  }}
                  avis)
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <paginate
          v-if="count"
          v-model="page"
          :key="paginationKey"
          :page-count="count"
          :click-handler="paginationHandler"
          prev-text="Précédent"
          next-text="Suivant"
          container-class="pagination"
          class="mt-1"
        ></paginate>
      </div>
    </div>

    <SessionRatingsModal
      :show="ratingsModal.show"
      :session="ratingsModal.session"
      @close="closeRatingsModal"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";

import StarRating from "../components/StarRating";
import SessionRatingsModal from "../components/SessionRatingsModal";
import Paginate from "vuejs-paginate";

export default {
  components: {
    StarRating,
    SessionRatingsModal,
    Paginate,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      cardTemplates: [
        {
          name: "topLives",
          label: "Cours les plus réservés",
          icon: "fa-video-camera",
        },
        {
          name: "topDays",
          label: "Jours les plus réservés",
          icon: "fa-calendar",
        },
        {
          name: "topTimes",
          label: "Horaires les plus réservés",
          icon: "fa-clock-o",
        },
      ],

      sort: {
        prop: "session.scheduledAt",
        order: "descending",
      },
      page: 1,
      rows: 20,
      paginationKey: 0,

      ratingsModal: {
        show: false,
        session: null,
      },
    };
  },

  computed: {
    cards() {
      let cards = [];

      if (this.data) {
        for (const [key, value] of Object.entries(this.data)) {
          const card = this.cardTemplates.find(({ name }) => name === key);

          if (card) {
            cards.push({ ...card, data: value });
          }
        }
      }

      return cards;
    },

    sessions() {
      const sessions = [];

      if (this.data?.sessionsLives) {
        for (const sessionLive of this.data.sessionsLives) {
          const { sessionBookings } = sessionLive.session;

          sessionLive.bookings = sessionBookings.length;

          const coach = sessionLive.session.coachs[0];

          if (coach) {
            const { id, firstName } = coach;
            const { name } = coach.coach;

            sessionLive.coach = { id, firstName, name };
          }

          sessionLive.rating = this.getRatingsData(sessionLive);

          sessions.push(sessionLive);
        }
      }

      return sessions;
    },

    sortedSessions() {
      const { prop, order } = this.sort;
      const sessions = [...this.sessions];

      switch (prop) {
        case "live.name":
          return sessions.sort((a, b) => {
            const { name: nameA } = a.live;
            const { name: nameB } = b.live;

            if (order === "ascending") {
              if (nameA < nameB) return -1;

              if (nameA > nameB) return 1;

              return 0;
            }

            if (nameA < nameB) return 1;

            if (nameA > nameB) return -1;

            return 0;
          });
        case "session.scheduledAt":
          return sessions.sort((a, b) => {
            const { scheduledAt: scheduledAtA } = a.session;
            const { scheduledAt: scheduledAtB } = b.session;

            if (order === "ascending") {
              if (moment(scheduledAtA) < moment(scheduledAtB)) return -1;

              if (moment(scheduledAtA) > moment(scheduledAtB)) return 1;

              return 0;
            }

            if (moment(scheduledAtA) < moment(scheduledAtB)) return 1;

            if (moment(scheduledAtA) > moment(scheduledAtB)) return -1;

            return 0;
          });
        case "day":
          return sessions.sort((a, b) => {
            if (order === "ascending") {
              if (a.day < b.day) return -1;

              if (a.day > b.day) return 1;

              return 0;
            }

            if (a.day < b.day) return 1;

            if (a.day > b.day) return -1;

            return 0;
          });
        case "time":
          return sessions.sort((a, b) => {
            if (order === "ascending") {
              if (a.time < b.time) return -1;

              if (a.time > b.time) return 1;

              return 0;
            }

            if (a.time < b.time) return 1;

            if (a.time > b.time) return -1;

            return 0;
          });
        case "live.type":
          return sessions.sort((a, b) => {
            const { type: typeA } = a.live;
            const { type: typeB } = b.live;

            if (order === "ascending") {
              if (typeA < typeB) return -1;

              if (typeA > typeB) return 1;

              return 0;
            }

            if (typeA < typeB) return 1;

            if (typeA > typeB) return -1;

            return 0;
          });
        case "bookings":
          return sessions.sort((a, b) => {
            if (order === "ascending") {
              return a.bookings - b.bookings;
            }

            return b.bookings - a.bookings;
          });
        case "coach.name":
          return sessions.sort((a, b) => {
            if (!a.coach && !b.coach) return 0;

            if (!a.coach) return 1;

            if (!b.coach) return -1;

            const { name: coachNameA } = a.coach;
            const { name: coachNameB } = b.coach;

            if (order === "ascending") {
              if (coachNameA < coachNameB) return -1;

              if (coachNameA > coachNameB) return 1;

              return 0;
            }

            if (coachNameA < coachNameB) return 1;

            if (coachNameA > coachNameB) return -1;

            return 0;
          });
        case "rating.average":
          return sessions.sort((a, b) => {
            if (!a.rating && !b.rating) return 0;

            if (!a.rating) return 1;

            if (!b.rating) return -1;

            const { average: averageA } = a.rating;
            const { average: averageB } = b.rating;

            if (order === "ascending") {
              return averageA - averageB;
            }

            return averageB - averageA;
          });
        default:
          return sessions;
      }
    },

    count() {
      return Math.ceil(this.sortedSessions.length / this.rows);
    },

    sessionsInPage() {
      return this.sortedSessions.slice(
        this.page * this.rows - this.rows,
        this.page * this.rows
      );
    },
  },

  methods: {
    getRatingsData(sessionLive) {
      const { sessionBookings } = sessionLive.session;
      const bookingsWithRating = sessionBookings.filter((sb) => sb.rating);

      if (bookingsWithRating.length == 0) return null;

      const ratings = [];

      for (const booking of bookingsWithRating) {
        const { rating, comment } = booking;
        const { name, email, id } = booking.client;

        ratings.push({
          rating,
          comment,
          clientName: name || email,
          clientId: id,
        });
      }

      const ratingValues = ratings.map(({ rating }) => rating);

      const average = parseFloat(
        (ratingValues.reduce((a, b) => a + b, 0) / ratingValues.length).toFixed(
          1
        )
      );

      return { average, ratings };
    },

    sortChange({ order, prop }) {
      this.sort = {
        ...this.sort,
        prop,
        order,
      };

      this.page = 1;
      this.paginationKey++;
    },

    paginationHandler(page) {
      this.page = page;
    },

    coachHref(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      return route.href;
    },

    openRatingsModal(sessionLive) {
      this.ratingsModal.session = {
        ...sessionLive,
        scheduledAt: sessionLive.session.scheduledAt,
      };
      this.ratingsModal.show = true;
    },

    closeRatingsModal() {
      this.ratingsModal.show = false;
      this.ratingsModal.session = null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <ClientDiscountUsages :client="client" />

      <ClientPaymentsCard :client="client" @reload="reload" />
    </div>
  </div>
</template>

<script>
import ClientPaymentsCard from "../components/ClientPaymentsCard";
import ClientDiscountUsages from "../components/ClientDiscountUsages";

export default {
  components: {
    ClientPaymentsCard,
    ClientDiscountUsages,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  methods: {
    reload() {
      this.$emit("reload");
    },
  },
};
</script>

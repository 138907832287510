<template>
  <div class="d-flex flex-column justify-content-start align-items-center">
    <div v-if="imgSrc" class="user-picture">
      <img :src="imgSrc" @error="() => $emit('error', { id: coach.id })" />

      <div
        v-if="hasPermission($store.state.user, 'COACHS_PICTURES_WRITE')"
        class="overlay"
      >
        <button
          v-if="coach.validityStatus !== 'rejected'"
          class="btn btn-sm btn-danger ml-05 mr-05"
          v-tooltip="{
            content: 'Refuser la photo de profil',
            placement: 'top',
          }"
          :disabled="loading"
          @click="showRejectModal = true"
        >
          <i class="fa fa-times"></i>
        </button>
        <button
          v-if="coach.validityStatus !== 'pending'"
          class="btn btn-sm btn-primary ml-05 mr-05"
          v-tooltip="{
            content: `Mettre la photo de profil 'En attente'`,
            placement: 'top',
          }"
          :disabled="loading"
          @click="editValidityStatus('pending')"
        >
          <i class="fa fa-rotate-left"></i>
        </button>
        <button
          v-if="coach.validityStatus !== 'validated'"
          class="btn btn-sm btn-success ml-05 mr-05"
          v-tooltip="{
            content: 'Valider la photo de profil',
            placement: 'top',
          }"
          :disabled="loading"
          @click="editValidityStatus('validated')"
        >
          <i class="fa fa-check"></i>
        </button>
      </div>
    </div>
    <div class="text-center" style="position: relative">
      <input
        v-if="hasPermission($store.state.user, 'COACHS_PICTURES_WRITE')"
        ref="checkbox"
        type="checkbox"
        v-model="checked"
        style="cursor: pointer; position: absolute; left: -1.5rem; top: 4px"
        @change="() => $emit('select', coach.id)"
      />

      <a :href="coachHref" target="_blank" class="d-block">{{ coach.name }}</a>
      <span v-if="coach.uploadedAt" class="d-block text-muted">{{
        formatDate(coach.uploadedAt)
      }}</span>
    </div>

    <ModalRejectCoachPicture
      :show="showRejectModal"
      :close="() => (showRejectModal = false)"
      @confirm="handleReject"
    />
  </div>
</template>

<script>
import axios from "axios";

import ModalRejectCoachPicture from "./ModalRejectCoachPicture";

export default {
  components: {
    ModalRejectCoachPicture,
  },

  props: {
    coach: {
      type: Object,
      required: true,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      imgSrc: "",
      checked: false,
      showRejectModal: false,
    };
  },

  computed: {
    coachHref() {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id: this.coach.id,
        },
      });

      return route.href;
    },
  },

  watch: {
    selected() {
      this.checked = this.selected;
    },
  },

  mounted() {
    this.imgSrc = `${axios.defaults.baseURL}users/${this.coach.id}/picture`;
  },

  methods: {
    editValidityStatus(status, reason) {
      this.$emit("editValidityStatus", {
        email: this.coach.email,
        status,
        reason,
      });
    },

    handleReject(reason) {
      this.editValidityStatus("rejected", reason);

      this.showRejectModal = false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.user-picture {
  width: 150px;
  aspect-ratio: 1/1;
  max-width: 100%;
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
  background-color: #faf532;
  margin-bottom: 0.5rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(250, 250, 250, 0.5);
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover .overlay {
    opacity: 1;
  }
}
</style>

<template>
  <div class="w-100 mw-100 overflow-x-auto">
    <table class="table table-condensed">
      <thead>
        <tr>
          <th v-if="hasPermission($store.state.user, 'SESSIONS_WRITE')">
            <i
              class="fa fa-info-circle"
              v-tooltip="
                `Lorsque cette option est désactivé, les séances ne sont pas planifiables par le coach dans l'app mobile.`
              "
            ></i>
          </th>
          <th v-if="hasPermission($store.state.user, 'SESSIONS_WRITE')">
            <input
              type="checkbox"
              v-if="sessions.length > 1"
              @click="toggleSelected"
            />
          </th>
          <th>Séance</th>
          <th v-if="!coach">Coach</th>
          <th v-if="client">Client</th>
          <th>Credit</th>
          <th>Durée</th>
          <th>Attribuée le</th>
          <th>Programmée le</th>
          <th>Statut</th>
          <th v-if="live">Participants</th>
          <th v-if="live">Note</th>
          <th v-if="live">Liens Coach</th>
          <th v-if="live">
            <button
              class="pl-4 btn btn-warning"
              v-if="liveSessionsNotDoneYet && liveSessionsNotDoneYet.length > 0"
              @click="cancelAllComingLiveSessions()"
            >
              Annuler toutes les sessions à venir
            </button>
          </th>
          <th v-if="showNbOfParticipants">Pointage</th>
          <th class="text-center"><i class="icon-refresh"></i></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(session, index) in sessions"
          :key="index"
          class="click"
          @click="clickHandler(session)"
        >
          <td width="25">
            <i
              v-if="session.sessionCoachBlocked"
              class="text-danger fa fa-exclamation-circle"
              @click="unlockSessionCoach(session)"
            />
            <i v-else class="text-success fa fa-check-circle" />
          </td>
          <td v-if="hasPermission($store.state.user, 'SESSIONS_WRITE')">
            <input
              type="checkbox"
              :value="session.id"
              v-if="sessions.length > 1"
              v-model="selected"
              :disabled="session.completed && !isAdmin($store.state.user)"
            />
          </td>
          <td @click="editCoachs = null">#{{ session.counter }}</td>
          <td
            class="editable"
            @click="editCoachs = session.id"
            v-if="
              (hasPermission($store.state.user, 'SESSIONS_WRITE') ||
                isAdmin($store.state.user)) &&
              !client
            "
          >
            <span
              v-if="editCoachs !== session.id"
              v-html="coachsLinks(session)"
            ></span>
            <v-select
              :options="coachsId"
              :value="sessionCoachs(editCoachs)"
              @input="saveCoachs"
              v-else-if="coachsId"
              multiple
            />
          </td>
          <td v-else-if="!coach" v-html="coachsLinks(session)"></td>
          <td v-if="client">
            <router-link
              v-if="session.client"
              :to="'/clients/' + session.client.id"
              >{{ session.client.fullName }}</router-link
            >
            <div v-else>erreur : {{ session.id }}</div>
          </td>
          <td
            class="editable"
            @click="editCredit = session.id"
            v-if="
              !session.completed &&
              hasPermission($store.state.user, 'SESSIONS_WRITE')
            "
          >
            <div v-if="session.assignedAt">
              <span v-if="editCredit !== session.id">{{
                formatPrice(session.credit, true)
              }}</span>
              <input
                type="text"
                class="form-control"
                v-on:keyup.enter="saveCredit($event)"
                :value="formatPrice(session.credit, true, true)"
                v-else
              />
            </div>
          </td>
          <td v-else>{{ formatPrice(session.credit, true) }}</td>
          <td
            class="editable"
            v-if="
              !session.completed &&
              hasPermission($store.state.user, 'SESSIONS_WRITE')
            "
            @click="
              (editDuration = session.id),
                (form.duration = formatDuration(session.duration, false))
            "
          >
            <span v-if="editDuration !== session.id">{{
              formatDuration(session.duration)
            }}</span>
            <el-time-select
              v-else
              v-model="form.duration"
              @change="saveDuration"
              size="mini"
              :clearable="false"
              :picker-options="{
                start: '00:10',
                step: '00:05',
                end: '02:00',
              }"
            />
          </td>
          <td v-else>{{ formatDuration(session.duration) }}</td>
          <td>
            <span
              v-if="hasPermission($store.state.user, 'SESSIONS_WRITE')"
              @click="editAssignedDate(session)"
              >{{ formatDate(session.assignedAt) }}</span
            >
            <span v-else>{{ formatDate(session.assignedAt) }}</span>
          </td>
          <td>
            <span
              v-if="hasPermission($store.state.user, 'SESSIONS_WRITE')"
              @click="editScheduledDate(session)"
              >{{ formatDateTime(session.scheduledAt) }}</span
            >
            <span v-else>{{ formatDate(session.scheduledAt) }}</span>
          </td>
          <td>
            <span
              class="badge badge-success"
              v-if="session.completed && session.disbursed"
              @click="saveDone(session)"
            >
              <i class="icon-check"></i>
              Séance payée<span v-if="session.scheduledBy">
                &ndash; effectuée par {{ session.scheduledBy.coach.name }}</span
              >
            </span>
            <div v-else-if="session.completed">
              <span class="badge badge-success" @click="saveDone(session)">
                Terminée<span v-if="session.scheduledBy">
                  et validée par {{ session.scheduledBy.coach.name }}</span
                >
              </span>
              <span
                v-if="session.userMark && session.userMark.notCompleted"
                class="ml-1 text-danger border-danger"
              >
                Contesté
                <span v-if="session.userMark.markedAt"
                  >le {{ formatDate(session.userMark.markedAt) }}</span
                >
              </span>
            </div>
            <span
              class="badge badge-info"
              v-else-if="session.scheduledAt"
              @click="saveDone(session)"
            >
              Programmée<span v-if="session.scheduledBy">
                par {{ session.scheduledBy.coach.name }}</span
              >
            </span>
            <span
              class="badge badge-warning"
              v-else-if="session.assignedAt"
              @click="saveDone(session)"
              >Non programmée</span
            >
            <span class="badge badge-danger" v-else>Non attribuée</span>
          </td>
          <td
            v-if="live && session.sessionBookings"
            class="text-center text-primary"
          >
            <span @click="openBookingsModal(session.sessionBookings)">
              {{ numberOfBookings(session) }}
              <span v-if="sessionQuotas(session)"
                >/ {{ sessionQuotas(session) }}</span
              >
            </span>
          </td>
          <td v-if="live">
            <div
              v-if="session.rating"
              class="d-flex flex-wrap"
              @click="openRatingsModal(session)"
            >
              <StarRating :rating="session.rating.average" />
              <span>
                {{ session.rating.average }}/5 ({{
                  session.rating.ratings.length
                }}
                avis)</span
              >
            </div>
            <span v-else class="d-block text-center"> - </span>
          </td>
          <td v-if="live && session.sessionLive">
            <a :href="displayRoomLink(session)">Rejoindre la séance</a>
          </td>
          <td v-if="session.sessionLiveSolo">
            <a :href="displayRoomLinkSolo(session)">Rejoindre la séance</a>
          </td>
          <td v-if="live" class="text-center">
            <template
              v-if="
                session.sessionLive.startedAt &&
                hasPermission($store.state.user, 'SESSIONS_WRITE')
              "
            >
              <button
                class="pl-4 btn btn-default"
                v-if="!session.sessionLive.closedAt"
                @click="closeSession(session)"
              >
                Mettre fin à la session
              </button>
              <button
                class="pl-4 btn btn-default"
                v-else
                @click="reOpenSession(session)"
              >
                Relancer la session
              </button>
            </template>
            <button
              class="pl-4 btn btn-default"
              v-else-if="
                !session.sessionLive.canceledAt &&
                hasPermission($store.state.user, 'SESSIONS_WRITE')
              "
              @click="cancelSession(session)"
            >
              Annuler la session
            </button>

            <button
              v-else
              class="pl-4 btn btn-default"
              @click="uncancelSession(session)"
            >
              Désannuler la session
            </button>
          </td>
          <td v-if="showNbOfParticipants" style="width: 100px">
            <input
              class="form-control"
              type="number"
              v-model="session.nbOfParticipants"
              min="0"
              step="1"
              @change="
                (e) => changeSessionNbOfParticipants(e.target.value, session)
              "
              v-if="hasPermission($store.state.user, 'SESSIONS_WRITE')"
              :disabled="!session.scheduledAt"
            />
          </td>
          <td class="text-center">
            <label
              class="switch switch-sm switch-text switch-info mb-0"
              v-if="hasPermission($store.state.user, 'SESSIONS_WRITE')"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="session.attribution"
                @click="handleAttribution($event, session)"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span v-else>{{ session.attribution ? "Oui" : "Non" }}</span>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="selected.length">
        <tr>
          <td colspan="8">
            <button
              v-if="!disableEdition"
              class="btn btn-link text-danger btn-sm float-right"
              @click="handleRemoveSession"
            >
              Supprimer les séances non programmées du pack
            </button>
            <button
              class="btn btn-secondary btn-sm"
              @click="editSelected = true"
              v-if="hasPermission($store.state.user, 'SESSIONS_WRITE')"
            >
              Modifier {{ selected.length === 1 ? "la séance" : "les séances" }}
            </button>
          </td>
        </tr>
      </tfoot>
    </table>

    <modal
      title="Attribuer"
      v-model="editDone"
      cancelText="Annuler"
      okText="Valider"
      @ok="handleSaveDone"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Marquer la séance comme</h4>
        <el-select v-model="form.type">
          <el-option key="validee" value="validee" label="Validée" />
          <el-option key="planifiee" value="planifiee" label="Planifiée" />
          <el-option
            key="non-programmee"
            value="non-programmee"
            label="Non programmée"
          />
        </el-select>
      </div>
      <div
        class="row"
        v-if="form.type === 'validee' || form.type === 'planifiee'"
      >
        <div class="form-group col-sm-6">
          <label v-if="form.type === 'validee'"
            >La séance a été faite à la date</label
          >
          <label v-else>Séance planifiée pour le</label>
          <datepicker
            v-model="form.doneAt"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-3">
          <label>Heures</label>
          <select class="form-control" v-model="form.doneHour">
            <option v-for="hour in 22" :key="hour - 1" :value="hour - 1">
              {{ 10 > hour - 1 ? `0${hour - 1}` : hour - 1 }}
            </option>
          </select>
        </div>
        <div class="form-group col-sm-3">
          <label>Minutes</label>
          <select class="form-control" v-model="form.doneMinute">
            <option v-for="min in 60" :key="min - 1" :value="min - 1">
              {{ 10 > min - 1 ? `0${min - 1}` : min - 1 }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-1" v-if="form.type === 'validee'">
        <div class="form-group col-sm-12">
          <label class="switch switch-sm switch-text switch-info mb-0">
            <input
              type="checkbox"
              class="switch-input"
              v-model="form.clientEmail"
            />
            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>
          Envoyer un email pour prévenir le client ?
        </div>
      </div>
    </modal>

    <modal
      title="Modifier"
      v-model="editDate"
      cancelText="Annuler"
      okText="Modifier"
      @ok="handleEditDate"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title" v-if="editDateType === 'assignedAt'">
          Modifier la date d'attribution
        </h4>
        <h4 class="modal-title" v-if="editDateType === 'scheduledAt'">
          Modifier la date de programmation
        </h4>
      </div>
      <div class="row">
        <div class="form-group col-sm-8">
          <label v-if="editDateType === 'assignedAt'"
            >La séance a été attribuée à la date</label
          >
          <label v-if="editDateType === 'scheduledAt'"
            >La séance a été programmée à la date</label
          >
          <datepicker
            v-model="form.doneAt"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-2">
          <label>Heures</label>
          <input class="form-control" type="text" v-model="form.doneHour" />
        </div>
        <div class="form-group col-sm-2">
          <label>Minutes</label>
          <input class="form-control" type="text" v-model="form.doneMinute" />
        </div>
      </div>
      <div>
        <button
          class="btn btn-danger btn-sm"
          @click="saveUnschedule(editSession)"
          v-if="editDateType === 'scheduledAt' && !editSession.completed"
        >
          Annuler la programmation de la séance
        </button>
        <button
          class="btn btn-danger btn-sm"
          @click="saveUnassign(editSession)"
          v-if="editDateType === 'assignedAt' && !editSession.scheduledAt"
        >
          Annuler l'attribution de la séance
        </button>
      </div>
    </modal>

    <modal
      title="Modifier"
      v-model="editSelected"
      cancelText="Annuler"
      okText="Modifier"
      @ok="handleEditSelected"
      effect="fade/zoom"
      class="bulk-edit-modal"
      large
      :backdrop="false"
    >
      <div slot="modal-header" class="modal-header">
        <h4 v-if="selected.length === 1">Modifier la séance</h4>
        <h4 v-else>Modifier les {{ selected.length }} séances</h4>
      </div>

      <div v-if="remainingSessions" class="row">
        <div class="col-sm-12">
          <div class="alert alert-warning">
            Il reste {{ remainingSessions }} séance(s) à attribuer
          </div>
        </div>
      </div>

      <div v-if="errors.groups" class="row">
        <div class="col-sm-12">
          <div class="alert alert-warning">
            {{ errors.groups }}
          </div>
        </div>
      </div>

      <div
        :key="`form-groups-${index}`"
        v-for="(group, index) of form.bulkEditGroups"
        class="row mb-1"
      >
        <hr v-if="index !== 0" style="width: 95%" />
        <div class="col-sm-4">
          <p><strong>Coachs</strong></p>
          <v-select
            :options="coachsOpt"
            v-model="group.coachs"
            v-if="coachsOpt"
            multiple
          ></v-select>
        </div>
        <div class="col-sm-3">
          <p><strong>Avoir</strong></p>
          <input class="form-control" type="text" v-model="group.credit" />
        </div>
        <div class="col-sm-2">
          <p>
            <strong>Nb. de séances</strong>
          </p>
          <input
            class="form-control"
            type="number"
            v-model="group.countSession"
            min="0"
            :max="selected.length"
            step="1"
            @change="checkSessionNumber(index)"
          />
        </div>
        <div class="col-sm-2 d-flex flex-column align-items-center">
          <p><strong>Modifier statut</strong></p>
          <label
            class="switch switch-sm switch-text switch-info"
            style="margin-top: 0.5rem"
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="group.editStatus"
            />
            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
        <div
          class="col-sm-1"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <button class="btn-no-style text-primary" @click="addFormGroup">
            <i class="fa fa-plus-circle"></i>
          </button>
          <button
            class="btn-no-style text-danger"
            @click="removeFormGroup(index)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <template v-if="group.editStatus">
          <div class="col-sm-4 mt-1">
            <p><strong>Statut</strong></p>
            <v-select
              :options="typeOpts"
              v-model="group.type"
              :reduce="(option) => option.value"
            ></v-select>
          </div>
          <template v-if="group.type && group.type !== 'non-programmee'">
            <div class="form-group col-sm-3 mt-1">
              <p>
                <strong>{{
                  group.type === "validee" ? "Fait le" : "Pour le"
                }}</strong>
              </p>
              <datepicker
                v-model="group.doneAt"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
            <div class="form-group col-sm-2 mt-1">
              <p><strong>Heure</strong></p>
              <select class="form-control" v-model="group.doneHour">
                <option v-for="hour in 22" :key="hour - 1" :value="hour - 1">
                  {{ 10 > hour - 1 ? `0${hour - 1}` : hour - 1 }}
                </option>
              </select>
            </div>
            <div class="form-group col-sm-2 mt-1">
              <p><strong>Minute</strong></p>
              <select class="form-control" v-model="group.doneMinute">
                <option v-for="min in 60" :key="min - 1" :value="min - 1">
                  {{ 10 > min - 1 ? `0${min - 1}` : min - 1 }}
                </option>
              </select>
            </div>
          </template>
        </template>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <p>
            <small>
              <em> Attention, un avoir ou coachs vide = suppression </em>
            </small>
          </p>
        </div>
      </div>
    </modal>

    <SessionBookingsModal
      :show="bookingsModal.show"
      :bookings="bookingsModal.bookings"
      @close="closeBookingsModal"
    />

    <SessionRatingsModal
      :show="ratingsModal.show"
      :session="ratingsModal.session"
      @close="closeRatingsModal"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";
import SessionBookingsModal from "../components/SessionBookingsModal";
import StarRating from "../components/StarRating";
import SessionRatingsModal from "../components/SessionRatingsModal";

import moment from "moment-timezone";

const BOOKING_STATUS = {
  CANCELED: "Canceled",
  BOOKED: "Booked",
};

export default {
  components: {
    vSelect,
    Modal,
    Datepicker,
    SessionBookingsModal,
    StarRating,
    SessionRatingsModal,
  },
  props: {
    sessions: {
      type: Array,
      default: null,
    },
    live: {
      type: Boolean,
      default: false,
    },
    coachs: {
      type: Object,
      default: null,
    },
    coach: {
      type: Object,
      default: null,
    },
    client: {
      type: Boolean,
      default: false,
    },
    disableEdition: {
      type: Boolean,
      default: false,
    },
    showNbOfParticipants: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      coachsId: null,
      coachsOpt: null,
      editCoachs: false,
      editCoachsTimeout: null,
      editCredit: false,
      editDuration: false,
      editDone: false,
      editDate: false,
      editSelected: false,
      editSession: null,
      editDateType: null,
      form: {
        type: "validee",
        doneAt: null,
        doneHour: null,
        doneMinute: null,
        bulkEditGroups: [
          {
            coachs: null,
            credit: "0",
            countSession: 0,
            editStatus: false,
            type: null,
            doneAt: null,
            doneHour: null,
            doneMinute: null,
          },
        ],
        clientEmail: false,
        duration: "01:00",
        multiple: false,
      },
      errors: {
        groups: null,
      },
      selected: [],
      bookingsModal: {
        show: false,
        bookings: [],
      },
      ratingsModal: {
        show: false,
        session: null,
      },
      typeOpts: [
        {
          label: "Validée",
          value: "validee",
        },
        {
          label: "Planifiée",
          value: "planifiee",
        },
        {
          label: "Non programmée",
          value: "non-programmee",
        },
      ],
    };
  },

  async created() {
    if (!this.coachs) {
      const { data } = await this.$api.get("/users/coachs");

      this.coachsId = data.map((c) => ({
        label: c.coach.name,
        value: c.id,
      }));
      this.coachsOpt = data.map((c) => ({
        label: c.coach.name,
        value: c.email,
      }));
    } else {
      this.coachsId = this.coachs.map((c) => ({
        label: c.coach.name,
        value: c.id,
      }));
      this.coachsOpt = this.coachs.map((c) => ({
        label: c.coach.name,
        value: c.email,
      }));
    }
  },

  computed: {
    remainingSessions() {
      let remainingSessions = this.selected.length;

      this.form.bulkEditGroups.forEach((c) => {
        remainingSessions = remainingSessions - c.countSession;
      });

      return remainingSessions;
    },

    liveSessionsNotDoneYet() {
      if (this.sessions && this.sessions.length > 0) {
        return this.sessions.filter(
          (session) =>
            session.sessionLive &&
            !session.sessionLive.canceledAt &&
            !session.sessionLive.startedAt &&
            !session.sessionLive.closedAt &&
            moment(session.scheduledAt) > moment()
        );
      } else {
        return null;
      }
    },
  },

  methods: {
    sessionCoachs(sessionId) {
      const names = [];

      this.sessions
        .find((s) => s.id === sessionId)
        .coachs.forEach((coach) => {
          names.push({
            label: coach.coach.name,
            value: coach.id,
          });
        });

      return names;
    },

    toggleSelected(event) {
      if (this.selected.length && !event.target.checked) {
        this.selected = [];
      } else {
        this.selected = this.sessions
          .filter((s) => !s.completed)
          .map((s) => s.id);
      }
    },

    handleRemoveSession() {
      this.selected.forEach((sessionId, index) => {
        const session = this.sessions.find((s) => s.id === sessionId);

        if (session.completed || session.scheduledAt) {
          this.selected.splice(index, 1);
        }
      });

      if (this.selected.length === 0) {
        return false;
      }

      this.$confirm({
        message: `Attention, ${this.selected.length} séance${
          this.selected.length > 1
            ? "s vont être supprimées"
            : " va être supprimée"
        } définitivement de la commande !`,
        onConfirm: async () => {
          const session = this.sessions.find((s) => s.id === this.selected[0]);

          await this.$api.delete(`/sessions/${session.orderId}`, {
            data: {
              sessions: this.selected,
            },
          });

          window.location.reload(true);
        },
      });
    },

    editScheduledDate(session) {
      if (session.scheduledAt) {
        this.editSession = session;
        this.editDate = true;
        this.editDateType = "scheduledAt";
        this.form.doneAt = moment(session.scheduledAt).format("YYYY-MM-DD");
        this.form.doneHour = moment(session.scheduledAt).format("HH");
        this.form.doneMinute = moment(session.scheduledAt).format("mm");
      }
    },

    editAssignedDate(session) {
      if (session.assignedAt) {
        this.editSession = session;
        this.editDate = true;
        this.editDateType = "assignedAt";
        this.form.doneAt = moment(session.assignedAt).format("YYYY-MM-DD");
        this.form.doneHour = moment(session.scheduledAt).format("HH");
        this.form.doneMinute = moment(session.scheduledAt).format("mm");
      }
    },

    handleEditDate() {
      this.$api
        .put("/sessions/date/" + this.editSession.id, {
          dateAt: this.form.doneAt,
          dateHour: this.form.doneHour + ":" + this.form.doneMinute,
          dateType: this.editDateType,
        })
        .then(() => {
          window.location.reload(true);
        });
    },

    handleEditSelected() {
      if (this.errors.groups) {
        this.errors.groups = null;
      }

      const editGroups = this.form.bulkEditGroups
        .filter((group) => group.coachs?.length > 0)
        .filter((group) => group.credit && group.credit !== "")
        .filter((group) => group.countSession > 0)
        .filter((group) => {
          if (group.editStatus) {
            return (
              group.type &&
              (group.type === "non-programmee" ||
                ((group.type === "validee" || group.type === "planifiee") &&
                  group.doneAt &&
                  (group.doneHour || group.doneHour === 0) &&
                  (group.doneMinute || group.doneMinute === 0) &&
                  Number.isInteger(group.doneHour) &&
                  Number.isInteger(group.doneMinute)))
            );
          }

          return true;
        })
        .map((group) => ({
          credit: parseInt(
            parseFloat(group.credit.trim().replace(",", ".")) * 100
          ),
          coachs: group.coachs.map((c) => c.value),
          countSession: group.countSession,
          editStatus: group.editStatus,
          type: group.type,
          doneAt: group.doneAt,
          doneHour:
            (group.doneHour || group.doneHour === 0) &&
            (group.doneMinute || group.doneMinute === 0) &&
            `${group.doneHour}:${group.doneMinute}`,
        }));

      if (editGroups.length === 0) {
        this.errors.groups = "Tous les champs doivent être remplis";

        return false;
      }

      this.$confirm({
        message: "Confirmer la modification des séances ?",
        onConfirm: async () => {
          await this.$api.put("/sessions/bulk", {
            sessions: this.selected,
            editGroups,
          });

          window.location.reload();
        },
      });
    },

    async saveCoachs(values) {
      let session = this.sessions.find((s) => s.id === this.editCoachs);

      if (!session || values.length === session.coachs.length) {
        return;
      }

      await this.$api
        .put(`/sessions/${session.id}/coachs`, {
          coachs: values.map((opt) => opt.value),
        })
        .then((res) => {
          this.$set(session, "coachs", res.data);

          if (res.data && res.data.length > 0 && !session.assignedAt) {
            this.$set(session, "assignedAt", moment().format());
          }
        });

      this.$emit("change-coach");
    },

    saveDone(session) {
      if (!this.hasPermission(this.$store.state.user, "SESSIONS_WRITE")) {
        return;
      }

      if (
        session.coachs.length !== 1 &&
        !this.isAdmin(this.$store.state.user)
      ) {
        this.$errorToast(
          "Pour valider manuellement une séance, un seul coach doit être assigné"
        );

        return;
      }

      if (session.scheduledAt) {
        this.form.doneHour = parseInt(moment(session.scheduledAt).format("HH"));
        this.form.doneMinute = parseInt(
          moment(session.scheduledAt).format("mm")
        );
        this.form.doneAt = moment(session.scheduledAt).format("YYYY-MM-DD");
      }

      this.editSession = session;
      this.editDone = true;
      this.form.multiple = false;
      this.form.type = session.completed
        ? "validee"
        : session.scheduledAt
        ? "planifiee"
        : "non-programmee";
    },

    saveUnschedule(session) {
      this.$confirm({
        message: "Annuler la programmation de la séance ?",
        onConfirm: async () => {
          await this.$api.put(`/sessions/undone/${session.id}`);

          window.location.reload(true);
        },
      });
    },

    saveUnassign(session) {
      this.$confirm({
        message: "Annuler l'attribution de la séance ?",
        onConfirm: async () => {
          await this.$api.put(`/sessions/undone/${session.id}`);

          window.location.reload(true);
        },
      });
    },

    saveCredit(e) {
      if (this.editCredit) {
        const session = this.sessions.find((s) => s.id === this.editCredit);

        const value = this.formatFormPrice(e.target.value.trim());

        if (isNaN(parseFloat(value))) {
          return;
        }

        session.credit = parseInt(parseFloat(value) * 100);

        if (session.credit >= 7000) {
          this.$confirm({
            message:
              "Attention, le crédit saisi est anormalement élevé. Confirmer ?",
            onConfirm: async () => {
              this.$api
                .put(`/sessions/${session.id}/credit`, {
                  credit: session.credit,
                })
                .then(() => {
                  this.editCredit = false;
                  this.$successToast("Crédit mis à jour");
                });
            },
          });
        } else {
          this.$api
            .put(`/sessions/${session.id}/credit`, {
              credit: session.credit,
            })
            .then(() => {
              this.editCredit = false;
              this.$successToast("Crédit mis à jour");
            });
        }
      }
    },

    saveDuration(e) {
      if (this.editDuration) {
        let date = moment(e, "HH:mm");

        const session = this.sessions.find((s) => s.id === this.editDuration);
        session.duration =
          parseInt(date.format("HH")) * 60 + parseInt(date.format("mm"));

        this.$api
          .put("/sessions/" + session.id + "/duration", {
            duration: session.duration,
          })
          .then(() => {
            this.editDuration = false;
          });
      }
    },

    handleAttribution: function (event, session) {
      this.$api.put("/sessions/attribution/" + session.id, {
        attribution: event.target.checked,
      });
    },

    async handleSaveDone() {
      if (
        (this.form.type === "validee" || this.form.type === "planifiee") &&
        (!Number.isInteger(this.form.doneHour) ||
          !Number.isInteger(this.form.doneMinute) ||
          !this.form.doneAt)
      ) {
        this.$errorToast("Tous les champs sont obligatoires.");

        return;
      }

      await this.$api.put("/sessions/done/" + this.editSession.id, {
        type: this.form.type,
        doneAt: this.form.doneAt,
        doneHour: this.form.doneHour + ":" + this.form.doneMinute,
        clientEmail: this.form.clientEmail,
      });

      window.location.reload(true);
    },

    coachsLinks(session) {
      const links = [];

      if (session.coachs) {
        session.coachs.forEach((coach) => {
          links.push(coach.coach.name);
        });
      }

      return links.join("<br>");
    },

    cancelSession(session) {
      if (session.sessionLive) {
        this.$confirm({
          message:
            "Voulez-vous réellement annuler cette session ? \nLes clients inscrits seront informés par email de l'annulation",
          onConfirm: async () => {
            try {
              const { data } = await this.$api.post(
                `/sessions/cancel-session-live`,
                {
                  id: session.sessionLive.id,
                }
              );

              session.sessionLive = data;
            } catch (err) {
              console.error(err);
            }
          },
        });
      }
    },

    uncancelSession(session) {
      if (session.sessionLive) {
        this.$confirm({
          message: "Voulez-vous réellement désannuler cette session ?",
          onConfirm: async () => {
            try {
              const { data } = await this.$api.post(
                `/sessions/uncancel-session-live`,
                {
                  id: session.sessionLive.id,
                }
              );

              session.sessionLive = data;
            } catch (err) {
              console.error(err);
            }
          },
        });
      }
    },

    closeSession(session) {
      if (session.sessionLive) {
        this.$confirm({
          message:
            "Voulez-vous réellement mettre fin à cette session en cours ?",
          onConfirm: async () => {
            try {
              await this.$api.post(
                `/sessions/${session.id}/close-live-session`
              );

              session.sessionLive = {
                ...session.sessionLive,
                closedAt: new Date(),
              };
            } catch (err) {
              console.error(err);
            }
          },
        });
      }
    },

    async reOpenSession(session) {
      if (session.sessionLive) {
        try {
          await this.$api.post(`/sessions/${session.id}/re-open`);

          session.sessionLive = {
            ...session.sessionLive,
          };
        } catch (err) {
          console.error(err);
        }
      }
    },

    clickHandler(session) {
      this.$emit("click", session);
    },

    addFormGroup() {
      this.form.bulkEditGroups.push({
        coachs: null,
        credit: 0,
        countSession: 0,
      });
    },

    removeFormGroup(index) {
      this.form.bulkEditGroups = this.form.bulkEditGroups.filter(
        (item, idx) => {
          return idx !== index;
        }
      );

      if (this.form.bulkEditGroups.length === 0) {
        this.addFormGroup();
      }
    },

    displayRoomLink(session) {
      const clientAppHost =
        process.env.VUE_APP_CLIENT_APP_URL || "https://app.justcoaching.fr/";

      return `${clientAppHost}live/rooms/${session.id}?secret=${session.sessionLive?.coachSecret}`;
    },

    displayRoomLinkSolo(session) {
      const clientAppHost =
        process.env.VUE_APP_CLIENT_APP_URL || "https://app.justcoaching.fr/";

      return `${clientAppHost}live/solo/${session.id}?secret=${session.sessionLiveSolo?.coachSecret}`;
    },

    cancelAllComingLiveSessions() {
      if (this.liveSessionsNotDoneYet?.length > 0) {
        this.$confirm({
          message: `Etes-vous sûr.e de vouloir annuler ${this.liveSessionsNotDoneYet.length} sessions à venir ? \nLes client.e.s inscrit.e.s seront informé.e.s par email de l'annulation.`,
          onConfirm: () => {
            this.liveSessionsNotDoneYet.forEach(async (session) => {
              try {
                const { data } = await this.$api.post(
                  `/sessions/cancel-session-live`,
                  {
                    id: session.sessionLive.id,
                  }
                );

                session.sessionLive = data;
              } catch (e) {
                console.error(e);
              }
            });
          },
        });
      }
    },

    numberOfBookings(session) {
      return (
        session.sessionBookings?.filter(
          (booking) => booking.status === BOOKING_STATUS.BOOKED
        )?.length || 0
      );
    },

    sessionQuotas(session) {
      return session.sessionLive?.live?.quotas;
    },

    openBookingsModal(bookings) {
      this.bookingsModal.bookings = bookings;
      this.bookingsModal.show = true;
    },

    closeBookingsModal() {
      this.bookingsModal.show = false;
      this.bookingsModal.bookings = [];
    },

    checkSessionNumber(index) {
      if (parseInt(this.form.bulkEditGroups[index].countSession) < 0) {
        this.form.bulkEditGroups[index].countSession = 0;
      } else {
        const otherGroups = this.form.bulkEditGroups.filter(
          (group, i) => i !== index
        );

        let alreadyAttributed = 0;

        otherGroups.forEach((group) => {
          alreadyAttributed += parseInt(group.countSession);
        });

        const max = this.selected.length - alreadyAttributed;

        if (parseInt(this.form.bulkEditGroups[index].countSession) > max) {
          this.form.bulkEditGroups[index].countSession = max;
        } else {
          this.form.bulkEditGroups[index].countSession = parseInt(
            this.form.bulkEditGroups[index].countSession
          );
        }
      }
    },

    openRatingsModal(session) {
      this.ratingsModal.session = session;
      this.ratingsModal.show = true;
    },

    closeRatingsModal() {
      this.ratingsModal.show = false;
      this.ratingsModal.session = null;
    },

    async changeSessionNbOfParticipants(value, session) {
      if (isNaN(parseInt(value))) {
        session.nbOfParticipants = null;

        return;
      }

      try {
        const nbOfParticipants = parseInt(value);

        await this.$api.put(`/sessions/${session.id}/nb-of-participants`, {
          nbOfParticipants,
        });

        session.nbOfParticipants = nbOfParticipants;
      } catch (e) {
        session.nbOfParticipants = null;
      }
    },

    async unlockSessionCoach(session) {
      this.$confirm({
        message:
          "Voulez-vous débloquer cette séance ? Attention cette action est irréversible.",
        onConfirm: async () => {
          const { status } = await this.$api.patch(
            `/sessions/${session.id}/unlock-coach-session`
          );

          if (status === 200) {
            session.sessionCoachBlocked = false;
            this.$successToast("La séance a bien été débloquée.");
          }
        },
      });
    },
  },
};
</script>

<style>
.btn-no-style {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: 0;
}

.btn-no-style:focus-visible,
.btn-no-style:focus {
  outline: 0;
}

.border-danger {
  border: 1px solid red;
  border-radius: 5px;
  padding: 2px 4px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.bulk-edit-modal .v-select input[type="search"] {
  width: 0 !important;
}
</style>

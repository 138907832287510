<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <router-view></router-view>

      <div class="card" v-if="['concepts'].indexOf($route.name) > -1">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Liste des concepts
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="newConcept = true"
            v-if="hasPermission($store.state.user, 'SETTINGS_CONCEPT_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody v-if="concepts.length > 0">
              <tr
                v-for="(concept, index) in concepts"
                :key="index"
                @click="goToConcept(concept)"
                class="click"
              >
                <td>{{ concept.name }}</td>
                <td>{{ concept.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal
      title="Ajouter un concept"
      large
      v-model="newConcept"
      @ok="handleNewConcept()"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ajouter un concept</h4>
      </div>

      <div class="form-group has-danger">
        <label>Nom</label>
        <input
          v-model="form.name"
          type="text"
          class="form-control"
          placeholder="exemple : justcoaching.fr"
        />
      </div>

      <div class="form-group">
        <label>Description</label>
        <textarea
          v-model="form.description"
          rows="2"
          class="form-control"
        ></textarea>
      </div>

      <div class="form-group">
        <label>Logo</label>
        <dropzone
          :url="apiUploads()"
          :headers="apiAuthorization()"
          id="file"
          v-on:vdropzone-success="fileUploaded"
        >
          <div class="dz-default dz-message">
            <span
              ><i class="icon-cloud-upload"></i><br />Glissez l'image ici</span
            >
          </div>
        </dropzone>
      </div>
    </modal>
  </div>
</template>
<style type="text/css">
.icon-cloud-upload {
  font-size: 40px;
}
</style>

<script>
import axios from "axios";
import Modal from "vue-strap/src/Modal";
import Dropzone from "vue2-dropzone";

export default {
  components: {
    Modal,
    Dropzone,
  },
  data() {
    return {
      concepts: [],
      newConcept: false,
      form: {
        name: null,
        logo: null,
        description: null,
        prestations: [],
      },
    };
  },
  created() {
    this.$api
      .get("/concepts")
      .catch(() => {
        this.$router.push({
          name: "dashboard",
        });
      })
      .then((res) => {
        this.concepts = res.data;
      });
  },
  methods: {
    prestations(prestations) {
      return prestations.join(", ");
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    fileUploaded(file, response) {
      this.form.logo = response.id;
    },

    handleChange(prestations) {
      this.form.prestations = prestations;
    },

    handleNewConcept() {
      if (!this.form.name) {
        return false;
      }

      this.$api.post("/concepts", this.form).then((res) => {
        this.newConcept = false;
        this.concepts.unshift(res.data);
      });
    },

    goToConcept(concept) {
      this.$router.push({
        name: "concept-details",
        params: {
          id: concept.id,
        },
      });
    },
  },
};
</script>

import { render, staticRenderFns } from "./NewClientModal.vue?vue&type=template&id=af767ffa&scoped=true"
import script from "./NewClientModal.vue?vue&type=script&lang=js"
export * from "./NewClientModal.vue?vue&type=script&lang=js"
import style0 from "./NewClientModal.vue?vue&type=style&index=0&id=af767ffa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af767ffa",
  null
  
)

export default component.exports
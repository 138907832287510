<template>
  <div class="attrib-custom-wrapper">
    <loader :isVisible="isLoading" />
    <router-view></router-view>
    <div
      v-if="['attributions'].indexOf($route.name) > -1"
      class="animated fadeIn"
    >
      <AttributionListFilters
        :default="filters"
        :pushLoading="pushLoading"
        @applyFilters="applyFilters"
        @loadLists="loadLists"
        @pushAll="pushAll"
      />
      <tabs v-model="activeTab" @input="changeTab">
        <tab :header="ordersHeader">
          <attributions
            :attributions="orders"
            :orders="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="notPayedHeader">
          <attributions
            :attributions="notPayed"
            :orders="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="clientsHeader">
          <attributions
            :attributions="clients"
            :clients="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="prospectsHeader">
          <attributions
            :attributions="prospects"
            :clients="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="paymentsHeader">
          <attributions
            :attributions="payments"
            :payments="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="recallsHeader">
          <attributions
            :attributions="recalls"
            :orders="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>

        <tab :header="livesHeader">
          <AttributionsLive
            :attributions="lives"
            :orders="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
      </tabs>
    </div>
    <loader :isVisible="isLoading" />
    <modal
      v-if="modalSendAttribution.attribution"
      title="Envoyer les attributions"
      v-model="modalSendAttribution.allowType"
      okText="Fermer"
      @ok="modalSendAttribution.allowType = false"
      effect="fade/zoom"
      large
      :backdrop="false"
    >
      <div v-if="modalFilter">
        <h4>Filtres utilisés:</h4>
        <ul>
          <li>Par appli: {{ modalFilter.appli ? "Oui" : "Non" }}</li>
          <li v-if="modalFilter.gender">Par genre: {{ modalFilter.gender }}</li>
          <li v-if="modalFilter.status && modalFilter.status.length !== 0">
            Par status: {{ getCoachStatus(modalFilter.status) }}
          </li>
          <li
            v-if="
              modalFilter.prestations && modalFilter.prestations.length !== 0
            "
          >
            Par prestations: {{ getCoachPrestations(modalFilter.prestations) }}
          </li>
          <li v-if="modalFilter.tags && modalFilter.tags.length !== 0">
            Par tags: {{ getCoachTags(modalFilter.tags) }}
          </li>
          <li v-if="modalFilter.lang">Par langue: {{ modalFilter.lang }}</li>
          <li>Par live activé: {{ modalFilter.live ? "Oui" : "Non" }}</li>
          <li>
            Par disponibilité: {{ modalFilter.availability ? "Oui" : "Non" }}
          </li>
          <li>Par rayon d'action: {{ modalFilter.range ? "Oui" : "Non" }}</li>
          <li>Pour un crédit de: {{ formatPrice(modalFilter.credit) }}</li>
        </ul>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-3">
          <button
            v-if="
              modalSendAttribution.coachs.queue &&
              modalSendAttribution.coachs.queue.length > 0
            "
            class="btn btn-warning"
            @click="pushNotification()"
          >
            Envoyer une notification
          </button>
        </div>
        <div class="col-md-3">
          <button
            v-if="
              modalSendAttribution.coachs.available &&
              modalSendAttribution.coachs.available.length > 0
            "
            class="btn btn-primary"
            @click="sendAttribution()"
          >
            Envoyer les attributions
          </button>
        </div>
      </div>
      <div class="row modal-coachs">
        <div class="col-md-3">
          <div
            v-if="
              modalSendAttribution.coachs.accepted &&
              modalSendAttribution.coachs.accepted.length > 0
            "
          >
            <p class="text-success">
              {{ modalSendAttribution.coachs.accepted.length }} coach{{
                modalSendAttribution.coachs.accepted.length > 1 ? "s ont" : " à"
              }}
              accepté{{
                modalSendAttribution.coachs.accepted.length > 1 ? "s" : ""
              }}.
            </p>
            <ul>
              <li
                v-for="coach of modalSendAttribution.coachs.accepted"
                :key="coach.id"
              >
                <a @click="(e) => goToClient(e, coach.id)" href="#">
                  <i class="icon-user"></i> {{ coach.name }}
                </a>
              </li>
            </ul>
          </div>
          <div v-else>Aucuns coachs accepté</div>
        </div>
        <div class="col-md-3">
          <div
            v-if="
              modalSendAttribution.coachs.rejected &&
              modalSendAttribution.coachs.rejected.length > 0
            "
          >
            <p class="text-danger">
              {{ modalSendAttribution.coachs.rejected.length }} coach{{
                modalSendAttribution.coachs.rejected.length > 1 ? "s ont" : " à"
              }}
              rejeté{{
                modalSendAttribution.coachs.accepted.length > 1 ? "s" : ""
              }}.
            </p>
            <ul>
              <li
                v-for="coach of modalSendAttribution.coachs.rejected"
                :key="coach.id"
              >
                <a @click="(e) => goToClient(e, coach.id)" href="#">
                  <i class="icon-user"></i> {{ coach.name }}
                </a>
              </li>
            </ul>
          </div>
          <div v-else>Aucuns coachs rejeté</div>
        </div>
        <div class="col-md-3">
          <div
            v-if="
              modalSendAttribution.coachs.queue &&
              modalSendAttribution.coachs.queue.length > 0
            "
          >
            <p class="text-warning">
              {{ modalSendAttribution.coachs.queue.length }} coach{{
                modalSendAttribution.coachs.queue.length > 1 ? "s" : ""
              }}
              en attente.
            </p>
            <ul>
              <li
                v-for="coach of modalSendAttribution.coachs.queue"
                :key="coach.id"
              >
                <a @click="(e) => goToClient(e, coach.id)" href="#">
                  <i class="icon-user"></i> {{ coach.name }}
                </a>
              </li>
            </ul>
          </div>
          <div v-else>Aucuns coachs en attente</div>
        </div>
        <div class="col-md-3">
          <div
            v-if="
              modalSendAttribution.coachs.available &&
              modalSendAttribution.coachs.available.length > 0
            "
          >
            <p>
              {{ modalSendAttribution.coachs.available.length }} coach{{
                modalSendAttribution.coachs.available.length > 1
                  ? "s disponibles"
                  : " disponible"
              }}.
            </p>
            <ul>
              <li
                v-for="coach of modalSendAttribution.coachs.available"
                :key="coach.id"
              >
                <a @click="(e) => goToClient(e, coach.id)" href="#">
                  <i class="icon-user"></i> {{ coach.name }}
                </a>
              </li>
            </ul>
          </div>
          <div v-else>Aucuns coachs en attente</div>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-primary mr-1"
          @click="modalSendAttribution.allowType = false"
        >
          Fermer
        </button>
      </div>
    </modal>
    <modal
      v-if="modalUpdateComment.commentaireOrder"
      title="Commentaire"
      v-model="modalUpdateComment.showComment"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateComment()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateComment.commentaireOrder.adminNotes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Attributions from "../components/Attributions";
import AttributionListFilters from "../components/AttributionListFilters";
import AttributionsLive from "../components/AttributionsLive";
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";
import { mapGetters } from "vuex";

export default {
  components: {
    Attributions,
    AttributionListFilters,
    AttributionsLive,
    Tabs,
    Tab,
    Modal,
    Loader,
  },
  data() {
    return {
      filters: {
        commercials: [],
        coachManagers: [],
      },
      activeTab: 0,
      isLoading: false,
      Loader: false,
      pushLoading: false,
      clients: [],
      prospects: [],
      orders: [],
      notPayed: [],
      payments: [],
      recalls: [],
      lives: [],
      modalSendAttribution: {
        allowType: false,
        attribution: null,
        coachs: [],
      },
      modalUpdateComment: {
        showComment: false,
        commentaireOrder: null,
      },
      prestations: [],
      tags: [],
    };
  },
  computed: {
    ...mapGetters({
      coachStatus: "getCoachStatus",
    }),

    ordersHeader() {
      return `Commandes (${this.orders.length})`;
    },
    notPayedHeader() {
      return `Non payées (${this.notPayed.length})`;
    },
    clientsHeader() {
      return `Clients (${this.clients.length})`;
    },
    prospectsHeader() {
      return `Prospects (${this.prospects.length})`;
    },
    paymentsHeader() {
      return `Paiements (${this.payments.length})`;
    },
    recallsHeader() {
      return `Rappels (${this.recalls.length})`;
    },
    livesHeader() {
      return `Lives & Visio (${this.lives.length})`;
    },

    modalFilter() {
      if (
        !this.modalSendAttribution ||
        !this.modalSendAttribution.attribution ||
        !this.modalSendAttribution.attribution.filters
      ) {
        return null;
      }

      return this.modalSendAttribution.attribution.filters;
    },
  },

  async created() {
    let activeTab = sessionStorage.getItem("attributionsTab");
    if (activeTab) {
      this.activeTab = parseInt(activeTab);
    }

    const { data } = await this.$api.get("/settings");

    if (data["intervenants_prestations"]) {
      this.prestations = data["intervenants_prestations"].map((prestation) => ({
        label: prestation,
        value: prestation,
      }));
    }

    if (data["intervenants_tags"]) {
      this.tags = data["intervenants_tags"].map((tag) => ({
        label: tag,
        value: tag,
      }));
    }
  },

  methods: {
    async loadLists() {
      this.isLoading = true;

      const commercials = this.filters.commercials.map((c) => c.value);
      const coachManagers = this.filters.coachManagers.map((c) => c.value);

      const data = {
        params: { commercials, coachManagers },
      };

      await this.$api.get("/orders/attribution", data).then((res) => {
        this.orders = res.data.filter(
          (attr) =>
            !attr.recall &&
            !attr.isNewPayment &&
            attr.order.status !== "pending" &&
            !attr.order.live
        );

        this.notPayed = res.data.filter(
          (attr) =>
            !attr.recall && attr.order.status === "pending" && !attr.order.live
        );
        this.recalls = res.data.filter((attr) => attr.recall);
        this.lives = res.data.filter((attr) => attr.order.live);

        this.payments = res.data.filter(
          (attr) => attr.isNewPayment && !attr.recall
        );
      });

      await this.$api.get("/clients/attribution", data).then((res) => {
        this.clients = res.data;
      });

      await this.$api.get("/prospects/attribution", data).then((res) => {
        this.prospects = res.data;
      });

      this.isLoading = false;
    },

    deleteAttribution(attributionId, type) {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.delete(`/attributions/${attributionId}`);

          this[type] = this[type].filter((v) => v.id !== attributionId);
        },
      });
    },

    changeTab(index) {
      sessionStorage.setItem("attributionsTab", index);
    },

    goToDetails(attribution, details) {
      const route = this.$router.resolve({
        name: "attributions-" + details,
        params: {
          id: attribution.id,
        },
      });

      window.open(route.href, "_blank");
    },

    moveAttribution(attribution) {
      if (
        !attribution.recall &&
        !attribution.isNewPayment &&
        attribution.order.status !== "pending"
      ) {
        this.orders.push(attribution);
        this.recalls = this.recalls.filter(
          (attr) => attribution.id !== attr.id
        );
      } else if (!attribution.recall && attribution.isNewPayment) {
        this.payments.push(attribution);
        this.recalls = this.recalls.filter(
          (attr) => attribution.id !== attr.id
        );
      } else if (
        !attribution.recall &&
        attribution.order.status === "pending"
      ) {
        this.notPayed.push(attribution);
        this.recalls = this.recalls.filter(
          (attr) => attribution.id !== attr.id
        );
      } else {
        this.recalls.push(attribution);
        this.orders = this.orders.filter((attr) => attribution.id !== attr.id);
        this.notPayed = this.notPayed.filter(
          (attr) => attribution.id !== attr.id
        );
        this.payments = this.payments.filter(
          (attr) => attribution.id !== attr.id
        );
      }
    },

    pushAll() {
      let targets;
      this.pushLoading = true;

      switch (this.activeTab) {
        case 0:
          targets = this.orders;
          break;
        case 1:
          targets = this.notPayed;
          break;
        case 2:
          targets = this.clients;
          break;
        case 3:
          targets = this.recalls;
          break;
      }

      this.$confirm({
        message: "Envoyer un rappel à tous les coachs qui n'ont pas répondu ?",
        onConfirm: async () => {
          for (const attribution of targets) {
            await this.$api.put(`/attributions/notification/${attribution.id}`);
          }
        },
      });

      this.pushLoading = false;
    },

    async allowAttribution(attribution) {
      this.modalSendAttribution.allowType = true;
      this.modalSendAttribution.attribution = attribution;

      const { data } = await this.$api.get(
        `/attributions/${attribution.id}/coachs`,
        {
          params: {
            attributionId: attribution.id,
            email: attribution.order.client,
            appli: attribution.filters?.appli ? "1" : "0",
            gender: attribution.filters?.gender || null,
            status: attribution.filters?.status || [],
            prestations: attribution.filters?.prestations || [],
            tags: attribution.filters?.tags || [],
            lang: attribution.filters?.lang || null,
            range: attribution.filters?.range ? "1" : "0",
            credit: attribution.filters?.credit || 2000,
            live: attribution.filters?.live ? "1" : "0",
            availability: attribution.filters?.availability ? "1" : "0",
            periods: attribution.filters?.periods || null,
          },
        }
      );

      this.modalSendAttribution.coachs = data;
    },

    async sendAttribution() {
      const availables = this.modalSendAttribution.coachs.available;

      this.$confirm({
        message: `Êtes vous sûr de vouloir envoyer ${
          availables.length > 1
            ? "ces " + availables.length + " attributions"
            : "cette attribution"
        }.`,
        onConfirm: async () => {
          this.isLoading = true;
          let coachs = [];

          availables.forEach((c) => {
            coachs.push(c.email);
          });

          await this.$api.post(
            `/attributions/coachs/${this.modalSendAttribution.attribution.id}`,
            {
              emails: coachs,
            }
          );
          this.modalSendAttribution = {
            allowType: false,
            attribution: null,
            coachs: [],
          };
          this.isLoading = false;
          this.$toast.success("Attribution envoyée");
        },
      });
    },

    async pushNotification() {
      const queue = this.modalSendAttribution.coachs.queue;

      this.$confirm({
        message: `Êtes vous sûr de vouloir envoyer ${
          queue.length > 1
            ? "ces " + queue.length + " notifications"
            : "cette notification"
        }.`,
        onConfirm: async () => {
          this.isLoading = true;

          await this.$api.put(
            `/attributions/notification/${this.modalSendAttribution.attribution.id}`
          );

          this.modalSendAttribution = {
            allowType: false,
            attribution: null,
            coachs: [],
          };
          this.isLoading = false;
          this.$toast.success("Notifications envoyée");
        },
      });
    },

    displayComment(attribution) {
      this.modalUpdateComment.showComment = true;
      this.modalUpdateComment.commentaireOrder = attribution;
    },

    async updateComment() {
      await this.$api
        .put("/orders/" + this.modalUpdateComment.commentaireOrder.id, {
          adminNotes: this.modalUpdateComment.commentaireOrder.adminNotes,
        })
        .catch((err) => {
          console.log(err);
        });

      this.modalUpdateComment.showComment = false;
    },

    async applyFilters(form) {
      this.filters = {
        ...this.filters,
        ...form,
      };

      await this.loadLists();
    },

    getCoachStatus(dataStatus) {
      const status = this.coachStatus.filter((s) =>
        dataStatus.includes(s.value)
      );

      if (status.length === 0) {
        return "";
      }

      return status.map((s) => s.label).join(", ");
    },

    getCoachPrestations(dataPrestations) {
      const prestations = this.prestations.filter((p) =>
        dataPrestations.includes(p.value)
      );

      if (prestations.length === 0) {
        return "";
      }

      return prestations.map((p) => p.label).join(", ");
    },

    getCoachTags(dataTags) {
      const tags = this.tags.filter((p) => dataTags.includes(p.value));

      if (tags.length === 0) {
        return "";
      }

      return tags.map((p) => p.label).join(", ");
    },

    goToClient(e, id) {
      e.preventDetault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="css">
/*[TODO] May we improved */
.attrib-custom-wrapper {
  max-width: calc(100vw - 320px);
}

@media (max-width: 991px) {
  .attrib-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}

.aside-menu-fixed.sidebar-hidden .attrib-custom-wrapper {
  max-width: calc(100vw - 70px);
}

.modal-coachs {
  overflow-x: auto;
  max-height: 200px;
}
</style>

<template>
  <div
    style="border-bottom: solid 1px #e1e6ef"
    :style="isCurrentOrderNote && 'background:#efefef; padding:10px'"
    class="py-1"
  >
    <div
      class="d-flex justify-content-between align-items-center mb-1"
      style="gap: 10px"
    >
      <div>
        <small class="text-muted" style="max-width: 60px">{{
          formatDateTime(note.createdAt)
        }}</small>
      </div>

      <div>
        <CommercialRel v-if="commercial" :relation="commercial" />
        <a
          v-else-if="coach"
          :href="coachHref"
          target="_blank"
          class="d-block text-center"
          ><small>{{ coach.name }}</small></a
        >
      </div>

      <div>
        <div
          v-if="admin"
          class="badge text-center"
          style="background-color: #faf532; color: #1e1e1e"
        >
          {{ admin.name }} (admin)
        </div>
        <br />
      </div>
    </div>

    <div v-if="editing && canEdit">
      <input
        v-model="note.title"
        type="text"
        class="form-control mb-1"
        placeholder="Titre (optionnel)"
      />

      <div class="mb-1">
        <textarea
          v-model="note.note"
          rows="5"
          :class="`form-control ${errors.note ? 'has-error' : ''}`"
          placeholder="Votre note ou commentaire ici..."
        ></textarea>
        <small v-if="errors.note" class="text-danger">{{ errors.note }}</small>
      </div>

      <div
        :class="`d-flex align-items-center ${
          note.id ? 'justify-content-between' : 'justify-content-end'
        }`"
      >
        <button
          v-if="note.id"
          @click="deleteNote"
          class="btn btn-sm btn-link text-danger"
        >
          <i class="fa fa-trash"></i>
        </button>

        <div class="d-flex align-items-center">
          <button @click="cancelEdit" class="btn btn-sm btn-secondary mr-05">
            <i class="fa fa-times"></i> Annuler
          </button>
          <button
            @click="note.id ? updateNote() : addNote()"
            class="btn btn-sm btn-primary"
          >
            <i class="fa fa-check"></i> {{ note.id ? "Modifier" : "Ajouter" }}
          </button>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="mb-1">
        <small v-if="note.clientEmail && !order" class="text-sm text-muted">
          Information client
        </small>
        <div v-if="order">
          <a :href="orderHref" target="_blank">
            <small v-if="order.label" class="font-weight-bold">{{
              order.label
            }}</small>
            <small class="d-block"
              >{{
                order.discovery
                  ? "Séance découverte"
                  : `${order.session} séance${order.session > 1 ? "s" : ""}`
              }}
              ({{ formatDate(order.createdAt) }})</small
            >
          </a>
        </div>
      </div>

      <span v-if="note.title" class="d-block font-weight-bold m-0">{{
        note.title
      }}</span>
      <p class="m-0">{{ note.note }}</p>
      <div v-if="canEdit" class="text-right">
        <button
          @click="editing = true"
          class="btn btn-sm btn-link text-primary"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CommercialRel from "./CommercialRel";

export default {
  components: {
    CommercialRel,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    isCurrentOrderNote: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      note: undefined,
      order: undefined,
      coach: undefined,
      commercial: undefined,
      admin: undefined,
      editing: false,
      errors: {},
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),

    canEdit() {
      return (
        this.isAdmin(this.user) ||
        (this.hasPermission(this.user, "CLIENTS_WRITE") &&
          this.user.email === this.note.userEmail)
      );
    },

    coachHref() {
      const route = this.$router.resolve({
        name: "user-details",
        params: { id: this.coach?.id || "" },
      });

      return route.href;
    },

    orderHref() {
      const route = this.$router.resolve({
        name: "order-details",
        params: { id: this.order?.id || this.note?.orderId || "" },
      });

      return route.href;
    },
  },

  created() {
    const { order, coach, commercial, admin, ...note } = this.item;

    this.note = note;
    this.editing = !note.id;
    this.order = order;

    this.coach = coach;
    this.commercial = commercial;
    this.admin = admin;
  },

  methods: {
    cancelEdit() {
      this.note = this.item;
      this.editing = false;

      this.$emit("cancel");
    },

    checkFields() {
      this.errors = {};

      if (!this.note.note || this.note.note.trim().length === 0) {
        this.errors.note = "Le champ est obligatoire";
      }

      return Object.keys(this.errors).length === 0;
    },

    addNote() {
      const isValid = this.checkFields();

      if (!isValid) {
        return;
      }

      this.$emit("add", {
        ...this.note,
        title: this.note.title ? this.note.title.trim() : null,
        note: this.note.note.trim(),
      });

      this.editing = false;
    },

    updateNote() {
      const isValid = this.checkFields();

      if (!isValid) {
        return;
      }

      this.$emit("update", {
        ...this.note,
        title: this.note.title ? this.note.title.trim() : null,
        note: this.note.note.trim(),
      });

      this.editing = false;
    },

    deleteNote() {
      this.$confirm({
        message: "Êtes-vous sûr de vouloir supprimer cette note ?",
        onConfirm: () => {
          this.$emit("delete", this.note.id);

          this.editing = false;
        },
      });
    },
  },
};
</script>

<style scoped>
.has-error {
  border-color: #ff5454;
}
</style>

<template>
  <div class="animated fadeIn">
    <Loader :is-visible="loading" />

    <LostFilters
      :commercials="commercials"
      @displayCommercials="displayCommercials"
      @apply="applyFilters"
    />

    <LostStats
      v-if="!loading && clients && prospects"
      :clients="sortedClients"
      :lostClientsTotal="totalLostClients"
      :clientsTotal="totalClients"
      :prospects="sortedProspects"
      :lostProspectsTotal="totalLostProspects"
      :prospectsTotal="totalProspects"
      :commercials="selectedCommercials"
    />
  </div>
</template>

<script>
import Loader from "../components/Loader";
import LostFilters from "../components/LostFilters";
import LostStats from "../components/LostStats";

export default {
  components: {
    Loader,
    LostFilters,
    LostStats,
  },

  data() {
    return {
      loading: false,
      clients: null,
      nbrClients: null,
      prospects: null,
      nbrProspects: null,
      commercials: [],
      selectedCommercials: [],
      filters: {
        orderType: null,
        dates: {
          begin: null,
          end: null,
        },
      },
    };
  },

  computed: {
    totalLostClients() {
      return this.clients.length;
    },

    totalClients() {
      return this.nbrClients;
    },

    totalLostProspects() {
      return this.prospects.length;
    },

    totalProspects() {
      return this.nbrProspects;
    },

    sortedClients() {
      let sorted = {};

      this.clients.forEach((client) => {
        if (client.lostReason) {
          if (
            sorted[client.lostReason] &&
            Array.isArray(sorted[client.lostReason])
          ) {
            sorted[client.lostReason].push(client);
          } else {
            sorted[client.lostReason] = [client];
          }
        } else {
          if (sorted["no-reason"] && Array.isArray(sorted["no-reason"])) {
            sorted["no-reason"].push(client);
          } else {
            sorted["no-reason"] = [client];
          }
        }
      });

      return sorted;
    },

    sortedProspects() {
      let sorted = {};

      this.prospects.forEach((prospect) => {
        if (prospect.prospect.lostReason) {
          if (
            sorted[prospect.prospect.lostReason] &&
            Array.isArray(sorted[prospect.prospect.lostReason])
          ) {
            sorted[prospect.prospect.lostReason].push(prospect);
          } else {
            sorted[prospect.prospect.lostReason] = [prospect];
          }
        } else {
          if (sorted["no-reason"] && Array.isArray(sorted["no-reason"])) {
            sorted["no-reason"].push(prospect);
          } else {
            sorted["no-reason"] = [prospect];
          }
        }
      });

      return sorted;
    },
  },

  created() {
    this.getCommercials();
  },

  methods: {
    async loadLostClients() {
      const { begin, end } = this.filters.dates;
      const { orderType } = this.filters;

      try {
        const { data } = await this.$api.get("/clients/lost", {
          params: { begin, end, orderType },
        });

        this.clients = data.lostClients;
        this.nbrClients = data.nbrClients;
      } catch (e) {
        this.clients = [];
      }
    },

    async loadLostProspects() {
      const { begin, end } = this.filters.dates;
      const { orderType } = this.filters;

      try {
        const { data } = await this.$api.get("/prospects/lost", {
          params: { begin, end, orderType },
        });

        this.prospects = data.lostProspects;
        this.nbrProspects = data.nbrProspects;
      } catch (e) {
        this.prospects = [];
      }
    },

    async getCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((c) => ({
          value: c.email,
          label: c.commercial?.pseudo || c.firstName,
          color: c.commercial?.color,
        }));
      } catch (e) {
        this.commercials = [];
      }
    },

    displayCommercials(commercials) {
      this.selectedCommercials = commercials;
    },

    async applyFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters,
      };

      this.loading = true;

      await this.loadLostClients();
      await this.loadLostProspects();

      this.loading = false;
    },
  },
};
</script>

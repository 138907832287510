<template>
  <div>
    <Loader :isVisible="loading" />
    <el-table :data="saps" style="width: 100%">
      <template slot="empty">
        <p class="w-100 p-1 text-center">Aucune attestation SAP trouvée.</p>
      </template>
      <el-table-column label="Année" prop="year" />
      <el-table-column label="Numéro de l'attestation" prop="filename" />
      <el-table-column label="Date de création">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column label="Montant" prop="amount">
        <template slot-scope="scope">
          {{ formatPrice(scope.row.amount) }}
        </template>
      </el-table-column>
      <el-table-column label="Date dernier envoie d'email">
        <template slot-scope="scope">
          <span v-if="scope.row.lastEmailSendedAt">
            {{ formatDateTime(scope.row.lastEmailSendedAt) }}
          </span>
          <span v-else> Non envoyé </span>
        </template>
      </el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <a class="btn btn-primary" download :href="downloadUrl(scope.row.id)">
            Télécharger
          </a>
          <button
            class="btn btn-success"
            @click="openSendEmailModal(scope.row.id)"
          >
            Envoyer par mail
          </button>
        </template>
      </el-table-column>
    </el-table>
    <modal
      title="Envoyer un email"
      v-model="modalSendEmailOpened"
      cancelText="Fermer"
      okText="Envoyer"
      @ok="sendEmail()"
      style="padding-top: 100px"
      effect="fade/zoom"
      :backdrop="false"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Envoyer l'attestation</h4>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Email</label>
          <input type="text" class="form-control" v-model="email" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Loader from "../Loader";
import Modal from "vue-strap/src/Modal";
import axios from "axios";

export default {
  components: {
    Modal,
    Loader,
  },
  props: {
    sapsData: {
      type: Array,
      required: true,
    },
    clientEmail: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      saps: [],
      modalSendEmailOpened: false,
      email: "",
      currentId: undefined,
      loading: false,
    };
  },

  created() {
    this.email = this.clientEmail;
    this.saps = this.sapsData;
  },

  methods: {
    openSendEmailModal(id) {
      this.currentId = id;
      this.modalSendEmailOpened = true;
    },

    async sendEmail() {
      this.loading = true;

      try {
        const { data } = await this.$api.patch(
          `/saps/send-email/${this.currentId}`,
          { email: this.email }
        );
        this.saps = this.saps.map((s) => {
          if (s.id === data.id) {
            s = data;
          }

          return s;
        });
        this.$toast.success("L'email à bien été envoyé");
      } catch (e) {
        this.$toast.error("Une erreur s'est produite");
      }

      this.loading = false;
      this.modalSendEmailOpened = false;
    },

    downloadUrl(id) {
      return `${axios.defaults.baseURL}saps/download/${id}`;
    },
  },

  watch: {
    clientEmail: function (newVal) {
      this.email = newVal;
    },

    sapsData: function (newVal) {
      this.saps = newVal;
    },
  },
};
</script>

<template>
  <div>
    <table class="table table-condensed">
      <thead>
        <tr>
          <th>Date</th>
          <th>Action</th>
          <th v-if="!user">Par</th>
          <th>Informations</th>
        </tr>
      </thead>
      <tbody v-if="logsInPage">
        <tr v-for="(log, index) in logsInPage" :key="index">
          <td>{{ formatDateTime(log.createdAt) }}</td>
          <td>
            <span class="badge badge-danger" v-if="log.action === 'delete'"
              >Suppression</span
            >
            <span
              class="badge badge-primary"
              v-else-if="log.action === 'create'"
              >Création</span
            >
            <span class="badge badge-info" v-else-if="log.action === 'update'"
              >Mise à jour</span
            >
            <span
              class="badge badge-danger"
              v-else-if="log.action === 'auth_failed'"
            >
              <i class="icon-screen-smartphone"></i> Mobile
            </span>
            <span
              class="badge badge-success"
              v-else-if="log.action === 'auth_connected'"
            >
              <i class="icon-screen-smartphone"></i> Mobile
            </span>
            <span
              class="badge badge-success"
              v-else-if="log.action === 'auth_app_connected'"
            >
              Manager
            </span>
            <span
              class="badge badge-danger"
              v-else-if="log.action === 'auth_app_failed'"
            >
              Manager
            </span>
          </td>
          <td v-if="!user">
            <span class="click" v-if="log.user" @click="goToUser(log.user)">
              {{ displayUserName(log) }}
            </span>
          </td>
          <td>
            <strong v-if="log.action.match(/auth/)">{{ log.content }}</strong>
            <template v-else>
              <strong v-if="log.clientId">Fiche client</strong>
              <strong v-else-if="log.coachId">Fiche coach</strong>
              <strong v-else-if="log.orderId">Commande</strong>
              <br />
              {{ log.content }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <paginate
      :page-count="count"
      :click-handler="paginationHandler"
      v-if="count"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
    ></paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  components: {
    Paginate,
  },
  props: {
    client: {
      type: Object,
      default: null,
    },
    order: {
      type: Object,
      default: null,
    },
    coach: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    overridePage: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 10,
    },
  },
  async created() {
    await this.loadLogs();
  },
  data() {
    return {
      logs: [],
      page: this.$route.query.page || this.overridePage,
    };
  },
  computed: {
    logsInPage() {
      return this.logs.slice(
        this.page * this.rows - this.rows,
        this.page * this.rows
      );
    },
    count() {
      return Math.ceil(this.logs.length / this.rows);
    },
    scope() {
      if (this.client) {
        return "clientId/" + this.client.id;
      } else if (this.order) {
        return "orderId/" + this.order.id;
      } else if (this.coach) {
        return "coachId/" + this.coach.id;
      } else if (this.user) {
        return "userId/" + this.user.id;
      } else {
        return "";
      }
    },
  },
  methods: {
    paginationHandler(page) {
      this.page = page;
    },
    async loadLogs() {
      const { data } = await this.$api.get(`/logs/${this.scope}`);

      this.logs = data;
    },
    goToUser(user) {
      this.$router.push({
        name: "user-details",
        params: {
          id: user.id,
        },
      });
    },
    displayUserName(log) {
      if (log.user.coach) {
        return log.user.coach.name;
      }

      if (log.user.firstName && log.user.lastName) {
        return log.user.firstName + " " + log.user.lastName;
      }

      return log.user.email;
    },
  },
};
</script>

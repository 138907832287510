<template>
  <span>
    <i
      :key="index"
      v-for="(star, index) in stars"
      :class="`fa ${star} ${size}`"
    ></i>
    <span v-if="displayRatingValue">{{ `(${value}/5)` }}</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    displayRatingValue: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "fa-lg"
    }
  },
  computed: {
    stars() {
      const half = !!(this.value % 1);

      const stars = [
        "fa-star-o",
        "fa-star-o",
        "fa-star-o",
        "fa-star-o",
        "fa-star-o"
      ];

      for (let i = 0; this.value > i; i++) {
        stars[i] = "fa-star";

        if (i + 1 > this.value && half) {
          stars[i] = "fa-star-half-o";
        }
      }

      return stars;
    }
  }
};
</script>

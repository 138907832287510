<template>
  <div class="animated fadeIn">
    <el-popover trigger="click" placement="top" :width="700">
      <div class="row align-items-center">
        <div class="col-md-12">
          <p>
            <span class="h4">{{ name }}</span> - {{ row.result }} / 5
          </p>
          <hr />
        </div>
        <div class="col-md-6 text-center my-2">
          <Rates :value="row.result" :displayRatingValue="false" size="fa-2x" />
        </div>
        <div class="col-md-6 text-center my-2">
          <span class="text-resume-label">{{ row.details.length }} avis</span>
        </div>
      </div>
      <hr class="divider" />

      <div class="rank-content">
        <div
          class="row text-center my-2"
          v-for="(rank, index) in row.details"
          :key="index"
        >
          <div class="col-md-3">
            <a
              @click="(e) => goToClient(e, rank.client.id)"
              class="user-pills-link orange"
              href="#"
            >
              <i class="icon-user"></i> {{ rank.client.fullname }}
            </a>
          </div>
          <div class="col-md-3">
            <Rates
              :value="rank.average"
              :displayRatingValue="false"
              size="fa-2x"
            />
          </div>
          <div class="col-md-6 text-left">
            {{ rank.comment }}
          </div>
        </div>
      </div>

      <div slot="reference" class="name-wrapper">
        <span v-if="row.result">
          #{{ row.rank }} -
          <Rates :value="row.result" />
        </span>
      </div>
    </el-popover>
  </div>
</template>

<script>
import Rates from "../Rates";

export default {
  components: {
    Rates,
  },

  props: {
    row: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  methods: {
    goToClient(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.pills {
  border-radius: 100px;
  background-color: #f5f5f5;
  padding: 3px 20px;
  cursor: pointer;
  word-break: break-word;
  display: inline-block;
}

.blue {
  background-color: #255dc8;
  color: white;
}

.green {
  background-color: #18bdb4;
  color: white;
}

.red {
  background-color: #dc2626;
  color: white;
}

.violet {
  background-color: #7c3aed;
  color: white;
}

.divider {
  width: 100%;
  border-top: 1px solid #131721;
}

.rank-content {
  max-height: 200px;
  overflow: auto;
}
</style>

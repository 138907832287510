<template>
  <div style="position: relative">
    <div
      v-if="loading"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.5;
        z-index: 99;
      "
    ></div>

    <el-table
      :data="data"
      :default-sort="{ prop: 'session.scheduledAt', order: 'descending' }"
      :row-class-name="tableRowClassName"
      :empty-text="noData"
    >
      <el-table-column label="Type" width="80">
        <template slot-scope="scope">
          {{ displayLiveType(scope.row.live.type) }}
        </template>
      </el-table-column>

      <el-table-column label="Cours" prop="live.name"> </el-table-column>

      <el-table-column label="Séance prévue le" prop="session.scheduledAt">
        <template slot-scope="scope">
          {{ formatSessionDate(scope.row.session.scheduledAt) }}
        </template>
      </el-table-column>

      <el-table-column label="Coach">
        <template slot-scope="scope">
          <a
            v-if="sessionCoach(scope.row)"
            @click="(e) => goToCoach(e, scope.row)"
            href="#"
          >
            {{ displaySessionCoach(scope.row) }}
          </a>
        </template>
      </el-table-column>

      <el-table-column label="Niveau">
        <template slot-scope="scope">
          {{ displayLiveLevel(scope.row.live.lvl) }}
        </template>
      </el-table-column>

      <el-table-column label="Statut (réservation)">
        <template slot-scope="scope">
          <span :class="scope.row.status === 'Canceled' ? 'text-muted' : ''">
            {{ bookingStatus(scope.row.status) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="Modifié le">
        <template slot-scope="scope">
          {{ formatDate(scope.row.updatedAt) }}
        </template>
      </el-table-column>
    </el-table>

    <paginate
      v-if="count"
      :page-count="count"
      :click-handler="paginationHandler"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
      class="mt-1 mb-0"
    ></paginate>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Paginate from "vuejs-paginate";

const BOOKING_STATUS = {
  CANCELED: "Canceled",
  BOOKED: "Booked",
};

export default {
  components: {
    Paginate,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },

    small: {
      type: Boolean,
      default: false,
    },

    total: {
      type: Number,
      default: 0,
    },

    page: {
      type: Number,
      default: 1,
    },

    rows: {
      type: Number,
      default: 20,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    noData() {
      return `Aucune réservation de cours ${this.small ? "VISIO" : "LIVE"}`;
    },

    count() {
      return Math.ceil(this.total / this.rows);
    },
  },

  watch: {
    data() {
      this.loading = false;
    },
  },

  methods: {
    formatSessionDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },

    displayLiveType(type) {
      return type === "live" ? "LIVE" : "VISIO";
    },

    sessionCoach(row) {
      return row.session?.coachs?.length && row.session.coachs[0];
    },

    displaySessionCoach(row) {
      return this.sessionCoach(row)?.fullName || "";
    },

    goToCoach(e, row) {
      e.preventDefault();

      const id = this.sessionCoach(row)?.id;

      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },

    displayLiveLevel(lvl) {
      switch (lvl) {
        case 0:
          return "Débutant";
        case 1:
          return "Intermédiaire";
        case 2:
          return "Confirmé";
        default:
          return "Tous niveaux";
      }
    },

    bookingStatus(status) {
      switch (status) {
        case BOOKING_STATUS.BOOKED:
          return "Réservé";
        case BOOKING_STATUS.CANCELED:
          return "Annulé";
        default:
          return "";
      }
    },

    paginationHandler(page) {
      this.loading = true;

      this.$emit("changePage", page);
    },

    tableRowClassName({ row }) {
      if (row.session?.status === "Done") {
        return "done-row";
      }
    },
  },
};
</script>

<style lang="css">
.el-table .done-row {
  background: #f7f7f7;
}
</style>

<template>
  <div>
    <table class="table table-condensed">
      <thead>
        <th>Date</th>
        <th>Coach</th>
        <th>Type de remplacement</th>
        <th>Remarques</th>
        <th>Note admin</th>
        <th>Attributions</th>
        <th v-if="hasPermission($store.state.user, 'SUBSTITUTIONS_WRITE')"></th>
      </thead>
      <tbody v-if="substitutions">
        <tr v-for="(substitution, index) in substitutions" :key="index">
          <td>{{ formatDate(substitution.createdAt) }}</td>
          <td>
            <DisplayCoachName
              :coach="substitution.coach"
              cssClass="user-link"
            ></DisplayCoachName>
          </td>
          <td v-if="substitution.client">
            <strong>Client</strong><br />
            <router-link :to="'/clients/' + substitution.client.id">{{
              substitution.client.fullName
            }}</router-link>
          </td>
          <td v-else-if="substitution.session">
            <strong
              >Séance {{ substitution.session.counter }}/{{
                substitution.session.order.session
              }}</strong
            ><br />
            <router-link :to="'/clients/' + substitution.session.client.id">
              {{ substitution.session.client.fullName }}
            </router-link>
          </td>
          <td v-else>
            <strong>Période de vacances</strong><br />
            {{ formatDate(substitution.start) }} &rarr;
            {{ formatDate(substitution.end) }}
          </td>
          <td v-if="substitution.session">
            <router-link :to="'/orders/' + substitution.session.order.id">
              Voir la Commande {{ substitution.session.order.description }}
            </router-link>
            <p>{{ substitution.notes }}</p>
          </td>
          <td v-else-if="substitution.clientNotes">
            {{ substitution.clientNotes }}
          </td>
          <td v-else>
            {{ substitution.notes }}
          </td>
          <td>
            <NoteModal
              :notes="substitution.adminNotes"
              :key="substitution.id"
              permission="SUBSTITUTIONS_WRITE"
              @showComment="showComment(substitution)"
              v-if="hasPermission($store.state.user, 'SUBSTITUTIONS_WRITE')"
            />
            <span v-else>{{ substitution.adminNotes }}</span>
          </td>
          <td v-if="substitution.client">
            <label
              class="switch switch-lg switch-text switch-primary mb-0"
              v-if="hasPermission($store.state.user, 'SUBSTITUTIONS_WRITE')"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="substitution.clientAttribution"
                @click="
                  clientAttribution($event, substitution.client, substitution)
                "
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span v-else>{{
              substitution.sessionAttribution ? "Oui" : "Non"
            }}</span>
          </td>
          <td v-else-if="substitution.session">
            <label
              class="switch switch-lg switch-text switch-primary mb-0"
              v-if="hasPermission($store.state.user, 'SUBSTITUTIONS_WRITE')"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="substitution.sessionAttribution"
                @click="sessionAttribution($event, substitution.session)"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span v-else>{{
              substitution.sessionAttribution ? "Oui" : "Non"
            }}</span>
          </td>
          <td v-else>
            <button
              class="btn btn-secondary"
              @click="openClients(substitution)"
              :disabled="
                !hasPermission($store.state.user, 'SUBSTITUTIONS_WRITE')
              "
            >
              <i class="icon-people"></i>
              {{ substitution.clients.length }} clients
            </button>
          </td>
          <td>
            <button
              class="btn btn-success"
              @click="treated(substitution)"
              v-if="
                pending &&
                hasPermission($store.state.user, 'SUBSTITUTIONS_WRITE')
              "
            >
              <i class="icon icon-briefcase"> Traiter</i>
            </button>
            <button
              class="btn btn-danger"
              @click="remove(substitution)"
              v-else-if="
                !pending &&
                hasPermission($store.state.user, 'SUBSTITUTIONS_WRITE')
              "
            >
              <i class="icon icon-close"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <modal
      title="Clients"
      v-model="showClients"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="showClients = false"
      effect="fade/zoom"
    >
      <div slot="modal-header"></div>
      <div class="form-group" v-if="clients">
        <table class="table">
          <thead>
            <th>Client</th>
            <th>Attribution</th>
          </thead>
          <tbody>
            <tr v-for="(client, index) in clients" :key="index">
              <td>
                <router-link :to="'/clients/' + client.id">
                  {{ client.fullName }}
                </router-link>
              </td>
              <td>
                <label class="switch switch-lg switch-text switch-primary mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="client.attribution"
                    @click="clientAttribution($event, client)"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import DisplayCoachName from "../components/coach/displayCoachName";
import NoteModal from "./form/NoteModal";

export default {
  components: {
    Modal,
    DisplayCoachName,
    NoteModal,
  },
  props: {
    substitutions: {
      type: Array,
      default: null,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSubstitution: null,
      showClients: false,
      clients: null,
    };
  },
  methods: {
    openClients(substitution) {
      this.clients = substitution.clients;
      this.showSubstitution = substitution;
      this.showClients = true;
    },
    clientAttribution(event, client, substitution) {
      if (!substitution) {
        substitution = this.showSubstitution;
      }

      let data = {
        attribution: event.target.checked,
        email: client.email,
      };

      if (substitution.start || substitution.end) {
        data.temporary = true;
        data.startAt = substitution.start;
        data.endAt = substitution.end;
      }

      this.$api.put("/clients/attribution", data);
    },
    sessionAttribution(event, session) {
      this.$api.put("/sessions/attribution/" + session.id, {
        attribution: event.target.checked,
        temporary: true,
      });
    },
    treated(substitution) {
      this.$confirm({
        message: "Traiter la demande de remplacement ?",
        onConfirm: async () => {
          await this.$api.put(`/substitutions/${substitution.id}`, {
            treated: true,
          });

          window.location.reload(true);
        },
      });
    },
    remove(substitution) {
      this.$confirm({
        message:
          "Supprimer la demande de remplacement ? Cette action est irréversible.",
        onConfirm: async () => {
          await this.$api.delete(`/substitutions/${substitution.id}`);

          window.location.reload(true);
        },
      });
    },
    goToUser(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },
    showComment(substitution) {
      this.$emit("showComment", substitution);
    },
  },
};
</script>

<template>
  <button
    type="button"
    class="btn btn-sm"
    :class="btnClass"
    @click="$emit('ok')"
    :disabled="disabled || loading"
  >
    <slot> <i v-if="icon" :class="icon"></i> {{ name }} </slot>
    <div v-if="loading" class="fa fa-spinner fa-spin"></div>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    btnClass: {
      type: String,
      default: "btn-primary",
    },
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <loader :is-visible="loading" />
      <tabs v-if="!loading" v-model="activeTab">
        <tab :header="'Clients'">
          <el-table style="width: 100%" :data="clientsInPage">
            <el-table-column sortable prop="firstName" label="Prénom" />
            <el-table-column sortable prop="lastName" label="Nom" />
            <el-table-column sortable prop="company" label="Société" />
            <el-table-column sortable prop="city" label="Ville" />
            <el-table-column sortable prop="email" label="Email">
              <template slot-scope="{ row }">
                <a @click="goToClient(row.id)">{{ row.email }}</a>
              </template>
            </el-table-column>
            <el-table-column sortable prop="phone" label="Téléphone" />
          </el-table>
          <paginate
            v-if="pageClientCount"
            :page-count="pageClientCount"
            :click-handler="clientPaginationHandler"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
          ></paginate>
        </tab>
        <tab :header="'Coachs'">
          <el-table style="width: 100%" :data="coachsInPage">
            <el-table-column sortable prop="coach.firstName" label="Prénom" />
            <el-table-column sortable prop="coach.lastName" label="Nom" />
            <el-table-column sortable prop="email" label="Email">
              <template slot-scope="{ row }">
                <a @click="goToCoach(row.id)">{{ row.email }}</a>
              </template>
            </el-table-column>
            <el-table-column sortable prop="coach.phone" label="Téléphone" />
          </el-table>
          <paginate
            v-if="pageCoachCount"
            :page-count="pageCoachCount"
            :click-handler="coachPaginationHandler"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
          ></paginate>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Paginate from "vuejs-paginate";
import Loader from "../components/Loader";

export default {
  components: {
    Tabs,
    Tab,
    Paginate,
    Loader,
  },

  data() {
    return {
      loading: false,
      activeTab: 0,
      clients: [],
      clientsInPage: [],
      pageClientCount: 0,
      clientPage: 1,
      coachs: [],
      coachsInPage: [],
      pageCoachCount: 0,
      coachPage: 1,
      limit: 20,
    };
  },

  async created() {
    const query = this.$route.query.q;

    if (query) {
      this.loading = true;

      await this.searchClients(query);
      await this.searchCoachs(query);

      this.loading = false;
    }
  },

  methods: {
    async searchClients(query) {
      try {
        const { data } = await this.$api.post("/clients/search", {
          query,
          noLimit: true,
        });

        this.clients = data;
        this.pageClientCount = Math.ceil(this.clients.length / this.limit);
        this.clientPaginationHandler(this.clientPage);
      } catch (error) {
        console.error(error);
      }
    },

    async searchCoachs(query) {
      try {
        const { data } = await this.$api.post("/users/coachs/search", {
          query,
          noLimit: true,
        });

        this.coachs = data;
        this.pageCoachCount = Math.ceil(this.coachs.length / this.limit);
        this.coachPaginationHandler(this.coachPage);
      } catch (error) {
        console.error(error);
      }
    },

    clientPaginationHandler(page) {
      this.clientPage = page;

      this.clientsInPage = this.clients.slice(
        this.clientPage * this.limit - this.limit,
        this.clientPage * this.limit
      );
    },

    coachPaginationHandler(page) {
      this.coachPage = page;

      this.coachsInPage = this.coachs.slice(
        this.coachPage * this.limit - this.limit,
        this.coachPage * this.limit
      );
    },

    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },

    goToCoach(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>

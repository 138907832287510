<template>
  <div class="row">
    <div class="col-12">
      <ClientOrdersCard
        :client="client"
        :notebook="notebook"
        @orderLoading="$emit('orderLoading', $event)"
        @newOrder="newOrder"
        @notebookNoteAdded="notebookNoteAdded"
        @notebookNoteUpdated="notebookNoteUpdated"
        @notebookNoteDeleted="notebookNoteDeleted"
      />
    </div>
  </div>
</template>

<script>
import ClientOrdersCard from "../components/ClientOrdersCard";

export default {
  components: {
    ClientOrdersCard,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },

    notebook: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    newOrder() {
      this.$emit("newOrder");
    },

    notebookNoteAdded({ note }) {
      this.$emit("notebookNoteAdded", note);
    },

    notebookNoteUpdated({ note }) {
      this.$emit("notebookNoteUpdated", note);
    },

    notebookNoteDeleted({ noteId }) {
      this.$emit("notebookNoteDeleted", noteId);
    },
  },
};
</script>

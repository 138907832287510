<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header font-weight-bold">NOVA</div>

      <div class="card-block">
        <i>
          Ce menu permet d'obtenir les informations concernant uniquement les
          commandes éligibles aux services à la personne.
        </i>
        <div class="row mt-1">
          <div class="col-md-6">
            <div class="form-group">
              <label for="begin">Date de création de facture entre</label>
              <datepicker
                v-model="begin"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="end">et le :</label>
              <datepicker
                v-model="end"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
          </div>
          <div class="col-sm-12">
            <button class="btn btn-primary float-right" @click="loadNova">
              Visualiser
            </button>
          </div>
        </div>
        <hr />
        <div class="row" v-if="data.nbSessions">
          <div class="col-sm-12">
            <span class="font-weight-bold">
              Nombre de séances facturées :
            </span>
            {{ data.nbSessions }}
          </div>
          <div class="col-sm-12">
            <span class="font-weight-bold">Nombre de clients facturés :</span>
            {{ data.nbClients }}
          </div>
          <div class="col-sm-12">
            <span class="font-weight-bold"
              >Nombre de coachs (de clients facturés) :</span
            >
            {{ data.nbCoachs }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      data: {
        nbSessions: 0,
        nbClients: 0,
        nbCoachs: 0,
      },
      begin: moment().startOf("month").startOf("day").format("YYYY-MM-DD"),
      end: moment().endOf("month").startOf("day").format("YYYY-MM-DD"),
    };
  },
  methods: {
    async loadNova() {
      try {
        const { data } = await this.$api.get("/invoices/nova", {
          params: {
            begin: this.begin,
            end: this.end,
          },
        });

        this.data = data;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<template>
  <div class="origin-custom-wrapper">
    <div class="animated fadeIn">
      <div class="card" v-if="livetypes">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Type de Cours - Lives "Origine"
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modalShow = !modalShow"
            v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block">
          <el-table :data="livetypes" style="width: 100%">
            <el-table-column
              sortable
              prop="name"
              label="Nom"
              width="180"
            ></el-table-column>
            <el-table-column label="Photo">
              <template slot-scope="scope">
                <img
                  class="course-image"
                  :src="getPictureImage(scope.row.id)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="description" label="Description" width="400">
            </el-table-column>
            <el-table-column prop="duration" label="Durée (min)" align="right">
            </el-table-column>
            <el-table-column label="Couleur" align="right">
              <template slot-scope="scope">
                <div
                  class="pullr-right"
                  style="
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: inline-block;
                  "
                  :style="{ backgroundColor: scope.row.colorCalendar }"
                ></div>
              </template>
            </el-table-column>
            <el-table-column prop="quotas" label="Quotas pers." align="right">
            </el-table-column>

            <el-table-column align="right">
              <template slot-scope="scope">
                <button
                  class="btn btn-default btn-sm"
                  @click="editType(scope.row)"
                  v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
                >
                  <i class="fa fa-pencil"></i>
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteType(scope.row.id)"
                  v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
                >
                  <i class="fa fa-trash-o"></i>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <OriginsModal
      :modalShow="modalShow"
      :origin="selectedOrigin"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import OriginsModal from "./OriginsModal";

export default {
  components: {
    OriginsModal,
  },
  data() {
    return {
      livetypes: [],
      selectedOrigin: {},
      modalShow: false,
    };
  },
  methods: {
    fetchOrigins() {
      this.$api.get("/live-origins").then((res) => {
        this.livetypes = res.data;
      });
    },

    onModalClose() {
      this.modalShow = false;
      this.fetchOrigins();
    },

    editType(origin) {
      this.selectedOrigin = origin;
      this.modalShow = true;
    },

    deleteType(id) {
      this.$confirm({
        message: "Supprimer le cours ?",
        onConfirm: async () => {
          await this.$api.delete(`/live-origins/${id}`);

          this.fetchOrigins();
        },
      });
    },

    getPictureImage(id) {
      return `${process.env.VUE_APP_API_URL}live-origins/${id}/photo`;
    },

    getBackgroundImage(id) {
      return `${process.env.VUE_APP_API_URL}live-origins/${id}/background`;
    },
  },
  mounted() {
    this.fetchOrigins();
  },
};
</script>

<style lang="scss">
.origin-custom-wrapper {
  width: 100%;
  max-width: calc(100vw - 315px);

  .custom-table .cell {
    white-space: nowrap;
  }

  .date-cell {
    text-transform: capitalize;
  }

  .no-ellipsis {
    text-overflow: inherit;
  }

  .course-image {
    max-height: 60px;
    width: auto;
  }
}

@media (max-width: 991px) {
  .origin-custom-wrapper {
    max-width: calc(100vw - 65px);
  }
}

.aside-menu-fixed.sidebar-hidden {
  .origin-custom-wrapper {
    max-width: calc(100vw - 65px);
  }
}
</style>

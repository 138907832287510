<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="float-right">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="openModal = true"
            v-if="hasPermission($store.state.user, 'FAQ_WRITE')"
          >
            <i class="icon-plus"></i> Ajouter
          </button>
        </div>
      </div>
    </div>
    <FaqTagsTable
      v-if="tags"
      :tags="tags"
      @edit="handleEditTag"
      @delete="handleDeleteTag"
    />

    <FaqTagsModal
      :modalShow="openModal"
      @close="onModalClose"
      :tag="currentTag"
    />
  </div>
</template>

<script>
import FaqTagsModal from "../settings/FaqTagsModal";
import FaqTagsTable from "../settings/FaqTagsTable";

export default {
  components: {
    FaqTagsModal,
    FaqTagsTable,
  },
  data() {
    return {
      currentTag: {},
      openModal: false,
    };
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },

  methods: {
    handleChangePage(page) {
      this.offset = (page - 1) * this.limit;

      this.reloadTags();
    },

    handleEditTag(tag) {
      this.currentTag = tag;
      this.openModal = true;
    },

    async handleDeleteTag() {
      this.currentTag = {};
      await this.reloadTags();
    },

    async onModalClose() {
      this.openModal = false;
      this.currentTag = {};

      await this.reloadTags();
    },
    async reloadTags() {
      await this.$emit("reload-tags");
    },
  },
};
</script>

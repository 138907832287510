<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Expire après le</label>
          <datepicker
            v-model="filters.endDate"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="form-group col-sm-4">
          <label>Categories</label>

          <v-select
            v-model="filters.category"
            :options="categories"
            label="name"
          />
        </div>

        <div class="form-group col-sm-4">
          <label>Types d'accès</label>

          <v-select
            v-model="filters.type"
            :options="types"
            :reduce="({ value }) => value"
          />
        </div>
      </div>

      <button
        type="button"
        class="btn btn-sm btn-primary"
        @click="exportWithFilters()"
      >
        <i class="icon-calculator"></i> Export Excel
      </button>

      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>

      <div class="form-group float-right">
        <label class="switch switch-sm switch-text switch-info ml-1 mb-0">
          <input type="checkbox" v-model="filters.used" class="switch-input" />
          <span class="switch-label" data-on="Oui" data-off="Non"></span>
          <span class="switch-handle"></span>
        </label>
        Voir les codes utilisés
      </div>
      <div class="form-group float-right">
        <label class="switch switch-sm switch-text switch-info ml-1 mb-0">
          <input type="checkbox" v-model="filters.sent" class="switch-input" />
          <span class="switch-label" data-on="Oui" data-off="Non"></span>
          <span class="switch-handle"></span>
        </label>
        Voir les codes envoyés
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.filters = this.default;
  },

  data() {
    return {
      filters: {
        used: false,
        sent: false,
        endDate: null,
        category: null,
        type: null,
      },
    };
  },

  methods: {
    applyFilters() {
      this.$emit("update", this.filters);
    },

    exportWithFilters() {
      this.$emit("export", this.filters);
    },
  },
};
</script>

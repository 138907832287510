<template>
  <div>
    <el-table :data="features">
      <el-table-column label="Nom" prop="label" width="220"></el-table-column>
      <el-table-column label="Activée" prop="value" align="center" width="150">
        <template slot-scope="scope">
          <el-switch
            :value="groupHasFeature(group, scope.row.value)"
            @change="toggleFeature(scope.row.value)"
            v-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
          />
          <span v-else>{{
            groupHasFeature(group, scope.row.value) ? "Oui" : "Non"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Coach" prop="coachInfo"></el-table-column>
      <el-table-column label="Manager" prop="managerInfo"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      features: [
        {
          label: "Pointage des participants",
          value: "SESSION_USER_COUNT",
          coachInfo:
            "Demande au coach de préciser le nombre de participants avant de valider une séance",
          managerInfo:
            "Affiche la possibilité d'activer / désactiver la fonctionnalité de Pointage dans les fiches commandes des clients appartenant au groupe",
        },
      ],
    };
  },

  methods: {
    async toggleFeature(feature) {
      let features = this.group.features ? [...this.group.features] : [];

      if (features.includes(feature)) {
        features = features.filter((ele) => ele !== feature);
      } else {
        features.push(feature);
      }

      try {
        await this.$api.put(`/groups/${this.group.id}/features`, { features });

        this.$emit("updated", features);
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn-primary ml-1"
      @click="modalAddShow = true"
    >
      <i class="fa fa-plus"></i>&nbsp; Créer une attestation
    </button>
    <modal
      v-model="modalAddShow"
      style="padding-top: 100px"
      effect="fade/zoom"
      :backdrop="false"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ajouter une attestation SAP</h4>
      </div>
      <div class="modal-body">
        <div class="form-group" v-if="!client">
          <label>Client</label>
          <v-select
            v-model="form.clientSearch"
            :options="clients"
            @search="searchClients"
            @input="updateClient"
          ></v-select>
          <div class="alert alert-danger" v-if="formErrors.client" role="alert">
            Ce champ est obligatoire
          </div>
        </div>
        <div class="form-group">
          <label>Année</label>
          <div class="input-group">
            <select v-model="form.year" class="form-control">
              <option v-for="year in years" :key="year">{{ year }}</option>
            </select>
            <span class="input-group-btn">
              <LoaderButton
                class="mx-1"
                @ok="checkPreview()"
                :loading="isPreviewLoading"
                name="Prévisuliser"
                icon="icon-eye"
                :disabled="!form.client || !form.year || form.year.length !== 4"
              />
            </span>
          </div>
          <div class="alert alert-danger" v-if="formErrors.year" role="alert">
            Ce champ est obligatoire
          </div>
        </div>
        <div
          v-if="
            preview &&
            form.client &&
            form.year &&
            preview &&
            preview.SAPAmount != 0
          "
        >
          <div class="alert alert-info" role="alert">
            <strong>Montant total: {{ formatPrice(preview.SAPAmount) }}</strong>
          </div>
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Type</th>
                <th>Payé le</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in preview.payments" :key="payment.id">
                <td>
                  <a
                    href="#"
                    @click="(e) => goToOrder(e, payment.orderId)"
                    target="_blank"
                  >
                    {{ payment.type }}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    @click="(e) => goToOrder(e, payment.orderId)"
                    target="_blank"
                  >
                    {{ formatDate(payment.capturedAt) }}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    @click="(e) => goToOrder(e, payment.orderId)"
                    target="_blank"
                  >
                    {{ formatPrice(payment.price) }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="preview && preview.SAPAmount == 0">
          <div class="alert alert-info" role="alert">
            <strong>Aucun paiement pour cette période.</strong>
          </div>
        </div>
        <div class="form-group mt-1">
          <el-checkbox v-model="form.sendEmail">Envoyer un email</el-checkbox>
        </div>
        <div class="form-group" v-if="form.sendEmail">
          <label>Email</label>
          <input type="text" class="form-control" v-model="form.email" />
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-sm btn-default"
          @click="modalAddShow = false"
        >
          Fermer
        </button>
        <LoaderButton
          class="mx-1"
          @ok="handleAddSap()"
          :loading="isLoading"
          name="Créer"
          icon="icon-plus"
          :disabled="
            !form.client ||
            !form.year ||
            form.year.length !== 4 ||
            (preview && preview.SAPAmount == 0)
          "
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import LoaderButton from "../form/LoaderButton";
import debounce from "lodash.debounce";

export default {
  components: {
    Modal,
    LoaderButton,
  },

  props: {
    client: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: false,
      isPreviewLoading: false,
      modalAddShow: false,
      form: {
        clientSearch: "",
        client: "",
        year: "",
        sendEmail: false,
        email: "",
      },
      formErrors: {
        client: false,
        year: false,
        sendEmail: false,
      },
      preview: null,
      clients: [],
    };
  },

  created() {
    if (this.client) {
      this.form.client = this.client.email;
      this.form.email = this.client.usedEmail;
    }
  },

  computed: {
    years() {
      const currentYear = new Date().getFullYear();

      let years = [];

      for (let i = 2017; i <= currentYear; i++) {
        years.push(i);
      }

      return years;
    },
  },

  methods: {
    async handleAddSap() {
      if (this.checkFormsErrors()) {
        return false;
      }

      this.isLoading = true;

      try {
        const { data } = await this.$api.post("/saps", {
          ...this.form,
          clientEmail: this.form.client,
          email: this.form.sendEmail ? this.form.email : undefined,
        });

        this.$toast.success("Sap créé avec succès.");

        this.$emit("addSap", {
          ...data,
          newlyCreated: true,
        });
        this.modalAddShow = false;
        this.resetValues();
      } catch (e) {
        this.$toast.error("Une erreur s'est produite");
      }

      this.isLoading = false;
    },

    async checkPreview() {
      if (!this.form.client || !this.form.year || this.form.year.length !== 4) {
        return false;
      }

      this.isPreviewLoading = true;

      try {
        const { data } = await this.$api.get(`/saps/year/${this.form.year}`, {
          params: {
            clientEmail: this.form.client,
          },
        });

        this.preview = data;
      } catch (e) {
        this.$toast.error("Une erreur s'est produite");
      }

      this.isPreviewLoading = false;
    },

    checkFormsErrors() {
      this.formErrors.year = !this.form.year;
      this.formErrors.client = !this.form.client;

      return (
        Object.values(this.formErrors).filter((error) => error).length !== 0
      );
    },

    updateClient() {
      if (this.form.clientSearch && this.form.clientSearch.usedEmail) {
        this.form.email = this.form.clientSearch.usedEmail;
        this.form.client = this.form.clientSearch.value;
      } else {
        this.form.email = "";
        this.form.client = "";
        this.preview = null;
      }
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.length > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter?q=${search}`);

      vm.clients = data.map((c) => ({
        label: c.name + " (" + c.email + ")",
        value: c.email,
        usedEmail: c.usedEmail,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    goToOrder(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: id,
        },
      });
      window.open(route.href, "_blank");
    },

    resetValues() {
      this.preview = null;
      this.form = {
        clientSearch: "",
        client: this.client ? this.client.email : "",
        year: "",
        sendEmail: false,
        email: "",
      };

      this.formErrors = {
        client: false,
        year: false,
        sendEmail: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header .btn {
  margin-top: 0;
}
</style>

<template>
  <modal
    title="Assignation des commerciaux"
    v-model="show"
    effect="fade/zoom"
    @cancel="$emit('close')"
  >
    <div v-if="client">
      <div v-for="commercial in clientCommercials" :key="commercial.value">
        <el-checkbox
          v-model="commercial.checked"
          @change="(e) => toggleCommercial(e, commercial)"
        >
          <div
            class="text-white rounded-3 mr-1"
            style="padding: 7px"
            :style="commercial.color && `background-color:${commercial.color}`"
            :class="!commercial.color && 'bg-primary'"
          >
            <span>{{ commercial.label }}</span>
          </div>
        </el-checkbox>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <button type="button" class="btn btn-primary" @click="$emit('close')">
        Fermer
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    client: {
      type: Object,
      default: null,
    },

    commercials: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    clientCommercials() {
      if (this.client) {
        return this.commercials.map((com) => ({
          ...com,
          checked: this.client.commercials?.some(
            (relation) => relation.user === com.value
          ),
        }));
      }

      return [];
    },
  },

  methods: {
    toggleCommercial(event, commercial) {
      if (event) {
        this.addCommercial(commercial);
      } else {
        this.removeCommercial(commercial);
      }
    },

    async addCommercial(commercial) {
      if (this.client) {
        await this.$api.post("/clients/commercial", {
          email: this.client.email,
          commercial: commercial.value,
        });

        this.client.commercials = this.client.commercials
          ? [
              ...this.client.commercials,
              {
                client: this.client.email,
                user: commercial.value,
                commercial: commercial.data,
              },
            ]
          : [
              {
                client: this.client.email,
                user: commercial.value,
                commercial: commercial.data,
              },
            ];
      }
    },

    async removeCommercial(commercial) {
      if (this.client) {
        await this.$api.delete("/clients/commercial", {
          data: {
            email: this.client.email,
            commercial: commercial.value,
          },
        });

        this.client.commercials = this.client.commercials.filter(
          ({ user }) => user !== commercial.value
        );
      }
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-user"></i> Client : {{ client.email }} - A.I.
      <i class="fa fa-info-circle" title="Avance immédiate"></i>
      <span
        v-if="!client.urssafInscription || !client.urssafInscription.valid"
        class="text-warning"
      >
        Non inscrit
      </span>
      <span v-else class="text-success">Inscrit </span>
    </div>
    <div class="card-block">
      <div v-if="client.company">{{ client.company }}</div>
      <div v-if="client.name">{{ client.name }}</div>
      <div v-if="client.address">
        {{ client.address }}
        <span v-if="client.zipcode">{{ client.zipcode }}</span>
        <span v-if="client.city">{{ client.city }}</span>
        <span v-if="client.country">{{ client.country }}</span>
      </div>
    </div>
    <div class="card-block">
      <button
        v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
        @click="goToClient(client.id)"
        type="button"
        class="btn btn-sm btn-primary"
      >
        <i class="fa fa-book"></i>&nbsp; Voir la fiche
      </button>
      <button
        v-if="clients && hasPermission($store.state.user, 'ORDERS_WRITE')"
        @click="moveOrder = true"
        type="button"
        class="btn btn-sm btn-danger"
      >
        <i class="fa fa-link"></i>&nbsp; Changer de client
      </button>
    </div>

    <modal
      title="Déplacer une commande"
      v-model="moveOrder"
      cancelText="Annuler"
      okText="Déplacer"
      @ok="handleMoveOrder()"
      effect="fade/zoom"
    >
      <div class="form-group">
        <label>Client</label>
        <v-select
          v-model="moveClient"
          :options="clients"
          @search="searchClients"
        ></v-select>
      </div>
    </modal>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      moveOrder: false,
      moveClient: null,
      clients: [],
    };
  },

  methods: {
    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter`, {
        params: {
          q: search,
        },
      });

      vm.clients = data.map((c) => ({
        label: `${c.name} (${c.email})`,
        firstName: c.firstName,
        lastName: c.lastName,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    handleMoveOrder() {
      if (!this.moveClient || !this.moveClient.id || !this.moveClient.value) {
        return;
      }

      this.$confirm({
        message: "Attention, déplacement d'une commande vers un autre client !",
        onConfirm: async () => {
          await this.$api.put(`/orders/${this.order.id}`, {
            client: {
              email: this.moveClient.value,
              id: this.moveClient.id,
            },
          });

          this.moveOrder = false;
          this.$emit("reload");
        },
      });
    },
  },
};
</script>

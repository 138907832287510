<template>
  <div class="geoloc" :class="{ 'has-error': !client.zipcode }">
    <geoloc
      :geoloc="geoloc"
      :address="formatAddress(client)"
      :displayAddress="true"
      placeholder="Adresse du client"
      @placeChanged="handleAddress"
      :disabled="!hasPermission($store.state.user, 'CLIENTS_WRITE')"
    />
  </div>
</template>

<script>
import Geoloc from "../components/Geoloc";

export default {
  components: {
    Geoloc,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      geoloc: null,
    };
  },

  mounted() {
    if (this.client.geoloc) {
      this.geoloc = {
        lat: this.client.geoloc.coordinates[1],
        lng: this.client.geoloc.coordinates[0],
      };
    }
  },

  methods: {
    async handleAddress(place) {
      place.email = this.client.email;

      await this.$api.put("/clients", place);

      window.location.reload(true);
    },
  },
};
</script>

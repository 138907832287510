<template>
  <div class="wrapper">
    <router-view></router-view>

    <div class="animated fadeIn">
      <div class="card" v-if="['settings'].indexOf($route.name) > -1">
        <div class="card-block">
          <table class="table table-condensed">
            <tbody>
              <tr v-if="hasPermission(user, 'SETTINGS_PROMO_CODE_READ')">
                <td>
                  <router-link to="/settings/promo-codes"
                    >Codes Promo</router-link
                  >
                </td>
              </tr>
              <tr v-if="hasPermission(user, 'SETTINGS_CONCEPT_READ')">
                <td>
                  <router-link to="/settings/concepts">Concepts</router-link>
                </td>
              </tr>
              <tr v-if="hasPermission(user, 'SETTINGS_TEAM_READ')">
                <td>
                  <router-link to="/settings/teammembers">Equipe</router-link>
                </td>
              </tr>
              <tr v-if="hasPermission(user, 'SETTINGS_CONFIG_READ')">
                <td>
                  <router-link to="/settings/configs"
                    >Configurations</router-link
                  >
                </td>
              </tr>
              <tr v-if="hasPermission(user, 'SETTINGS_PAYMENTS_TYPE_READ')">
                <td>
                  <router-link to="/settings/payment-methods"
                    >Moyens de paiement</router-link
                  >
                </td>
              </tr>
              <tr v-if="hasPermission(user, 'SETTINGS_PRESTA_READ')">
                <td>
                  <router-link to="/settings/intervenants"
                    >Prestataires</router-link
                  >
                </td>
              </tr>
              <tr v-if="hasPermission(user, 'MERCHANTS_READ')">
                <td>
                  <router-link to="/settings/merchants">Sociétés</router-link>
                </td>
              </tr>
              <tr v-if="hasPermission(user, 'EXPORT_READ')">
                <td>
                  <router-link to="/settings/export">Export</router-link>
                </td>
              </tr>
              <tr v-if="hasPermission(user, 'IMPORT_WRITE')">
                <td>
                  <router-link to="/settings/import">Import</router-link>
                </td>
              </tr>
              <tr
                v-if="hasPermission($store.state.user, 'DYNAMIC_CONTENT_READ')"
              >
                <td>
                  <router-link to="/settings/dynamic-content">
                    Contenu pour les applications
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>
                  <router-link
                    to="/settings/sap"
                    v-if="hasPermission($store.state.user, 'SETTINGS_SAP_READ')"
                  >
                    SAP
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>
                  <router-link
                    to="/settings/faq"
                    v-if="hasPermission($store.state.user, 'FAQ_READ')"
                  >
                    FAQ
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Settings",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-refresh"></i> Filtrer les {{ type }}s
    </div>

    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Date de début</label>
          <datepicker
            v-model="form.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Date de fin</label>
          <datepicker
            v-model="form.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4 d-flex flex-column">
          <label class="mb-1">Archives</label>
          <el-radio-group v-model="form.archives">
            <el-radio :label="'hidden'">Masquées</el-radio>
            <el-radio :label="'displayed'">Affichées</el-radio>
            <el-radio :label="'archivesOnly'">Archives uniquement</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Rechercher un {{ type }}</label>
          <input
            class="form-control"
            v-model="form.name"
            placeholder="Nom, Prénom, Email"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Commercial</label>
          <multiselect
            v-model="form.commercials"
            :options="commercials"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label>Filtrer par concept</label>
          <multiselect
            v-model="form.concepts"
            :options="concepts"
            :multiple="true"
            :clear-on-select="false"
            :preserve-search="true"
            label="name"
            track-by="name"
            placeholder="Choisir"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4 d-flex flex-column">
          <label class="mb-1">Avance immédiate</label>
          <el-radio-group v-model="form.aiStatus">
            <el-radio label="all">Tous</el-radio>
            <el-radio label="unregistered">Non inscrit</el-radio>
            <el-radio label="pending">En cours</el-radio>
            <el-radio label="on-error">En erreur</el-radio>
            <el-radio label="registered">Inscrit</el-radio>
          </el-radio-group>
        </div>
        <div class="form-group col-sm-4 d-flex flex-column">
          <label class="mb-1">Client B2B uniquement</label>
          <el-switch
            v-model="form.isB2B"
            active-text="Oui"
            inactive-text="Non"
          />
        </div>
      </div>
    </div>

    <div class="card-footer d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>

      <button
        type="button"
        class="btn btn-sm btn-primary mr-1"
        @click="addNewRow()"
        v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')"
      >
        <i class="fa fa-plus"></i>&nbsp; Ajouter un {{ type }}
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Datepicker,
    Multiselect,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
    defaultCommercials: {
      type: Array,
      default: () => [],
    },
    commercials: {
      type: Array,
      default: () => [],
    },
    concepts: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => ({
        begin: undefined,
        end: undefined,
        archives: "hidden",
        name: undefined,
        concepts: [],
        commercials: [],
        aiStatus: "all",
        isB2B: false,
      }),
    },
  },

  methods: {
    applyFilters() {
      this.$emit("applyFilters", this.form);
    },

    addNewRow() {
      this.$emit("addNew");
    },
  },

  watch: {
    defaultCommercials(newVal) {
      this.form.commercials = newVal;
    },
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="card">
      <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Date de début</label>
            <datepicker
              v-model="form.begin"
              v-on:closed="handleNewFilterDate"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-6">
            <label>Date de fin</label>
            <datepicker
              v-model="form.end"
              v-on:closed="handleNewFilterDate"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
        </div>
      </div>
    </div>
    <loader :isVisible="loading" />
    <tabs v-if="!loading" v-model="activeTab" @input="changeTab">
      <tab :header="archivesHeader">
        <ReplaysTable
          :vonage="true"
          :sessions="archiveReplays"
          @sentToVimeo="replaySentToVimeo"
          @replayDeleted="replayDeleted"
        />
      </tab>
      <tab :header="vimeoHeader">
        <ReplaysTable :sessions="vimeoReplays" @replayDeleted="replayDeleted" />
      </tab>
    </tabs>

    <paginate
      v-model="page"
      :key="paginationReRender"
      :page-count="count"
      :click-handler="changePage"
      v-if="count"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
    ></paginate>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Loader from "../../components/Loader";
import Paginate from "vuejs-paginate";

import ReplaysTable from "../../components/ReplaysTable";

export default {
  components: {
    Datepicker,
    Tabs,
    Tab,
    Loader,
    Paginate,
    ReplaysTable,
  },
  data() {
    return {
      activeTab: 0,
      replays: [],
      archiveReplays: [],
      vimeoReplays: [],
      numberOfArchiveReplays: 0,
      numberOfVimeoReplays: 0,
      page: 1,
      offset: 0,
      limit: 10,
      countReplays: true,
      paginationReRender: true,
      form: {
        begin: moment().subtract(6, "months").toDate(),
        end: moment().toDate(),
      },
      loading: false,
    };
  },
  async mounted() {
    await this.fetchReplays(this.offset);
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
      this.page = 1;

      this.fetchReplays(this.offset);
      this.togglePaginationReRender();
    },

    changePage(page) {
      this.page = page;

      const offset = this.limit * page - this.limit;

      this.fetchReplays(offset);
    },

    async fetchReplays(offset) {
      this.loading = true;

      const status = this.activeTab === 0 ? "pending" : "validated";

      const { data } = await this.$api.get("/replays", {
        params: {
          start: this.form.begin,
          end: this.form.end,
          status,
          offset,
          limit: this.limit,
          count: this.countReplays,
          currentPendingTotal: this.numberOfArchiveReplays,
          currentValidatedTotal: this.numberOfVimeoReplays,
        },
      });

      if (status === "pending") {
        this.archiveReplays = data.data;
      } else {
        this.vimeoReplays = data.data;
      }

      this.numberOfArchiveReplays = data.pendingTotal;
      this.numberOfVimeoReplays = data.validatedTotal;

      this.countReplays = false;

      this.loading = false;
    },

    togglePaginationReRender() {
      this.paginationReRender = !this.paginationReRender;
    },

    replaySentToVimeo() {
      this.countReplays = true;
    },

    replayDeleted() {
      this.countReplays = true;
    },

    handleNewFilterDate() {
      this.countReplays = true;

      this.changePage(1);
      this.togglePaginationReRender();
    },
  },
  computed: {
    archivesHeader() {
      return `En attente "Vonage" (${this.numberOfArchiveReplays})`;
    },

    vimeoHeader() {
      return `Validées "Vimeo" (${this.numberOfVimeoReplays})`;
    },

    count() {
      return this.activeTab === 0
        ? Math.ceil(this.numberOfArchiveReplays / this.limit)
        : Math.ceil(this.numberOfVimeoReplays / this.limit);
    },
  },
};
</script>

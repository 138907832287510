<template>
  <div
    class="card"
    v-if="
      ['sap'].indexOf($route.name) > -1 &&
      hasPermission($store.state.user, 'SETTINGS_SAP_READ')
    "
  >
    <div class="card-header">
      Liste des certificats SAP ({{ total }} client{{ total > 1 ? 's' : '' }})
    </div>
    <div class="card-block">

      <el-table :data="clients" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="clientScope">
            <div class="row">
              <div class="col-md-12">
                <ClientSapTable :sapsData="clientScope.row.saps" :clientEmail="clientScope.row.contactEmail"></ClientSapTable>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Nom du bénéficiaire">
          <template slot-scope="scope">
            <a href="#" @click="(e) => goToClient(e, scope.row.id)">{{
              scope.row.fullname
            }}</a>
          </template>
        </el-table-column>
      </el-table>

      <paginate
        v-model="currentPage"
        :page-count="count"
        :click-handler="handleCurrentChange"
        prev-text="Précédent"
        next-text="Suivant"
        container-class="pagination"
        style="padding: 0 1.25rem"
        class="mt-1"
      ></paginate>
    </div>
  </div>
</template>

<script>

import Paginate from "vuejs-paginate";
import ClientSapTable from "../sap/ClientSapTable";

export default {
  components: {
    Paginate,
    ClientSapTable
  },

  props: {
    clients: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    limit: {
      type: String,
      required: true,
    },
  },

  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL || "";
    },

    count() {
      return Math.ceil(this.total / this.limit);
    },
  },

  methods: {
    goToClient(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },

    handleCurrentChange(val) {
      this.$emit("updatePagination", val);
    },

    sendMail(client) {
      this.$emit("openSendEmailModal", client);
    },
  },
};
</script>

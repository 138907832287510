<template>
  <modal
    :title="form.id ? 'Éditer' : 'Ajouter'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="form.id ? 'Éditer' : 'Ajouter'"
    @cancel="() => closeModal()"
    @ok="form.id ? handleEditPromoCode() : handleAddPromoCode()"
    effect="fade/zoom"
  >
    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">{{ form.id ? "Éditer" : "Ajouter" }}</h4>
    </div>
    <div v-if="formError" class="alert alert-danger">{{ formError }}</div>
    <div class="form-group">
      <label>Société *</label>
      <select
        v-model="form.merchantId"
        class="form-control"
        @change="handleMerchantChange($event.target.value)"
      >
        <option v-for="{ id, name } in merchantsData" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Concept *</label>
      <select v-model="form.type" class="form-control">
        <option v-for="{ id, name } in concepts" :key="id" :value="name">
          {{ name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Nom *</label>
      <input type="text" v-model="form.description" class="form-control" />
    </div>
    <div class="form-group">
      <label>Photo *</label>
      <a
        v-if="form.id && form.photoId"
        class="pull-right"
        target="_blank"
        :href="`${apiUrl}promoCodes/${form.id}/photo`"
        >Voir l'image</a
      >
      <dropzone
        ref="dropzone"
        :url="apiUploads()"
        :headers="apiAuthorization()"
        id="photo"
        v-on:vdropzone-success="photoUploaded"
        v-on:vdropzone-removed-file="photoRemoved"
      >
        <div class="dz-default dz-message">
          <span
            ><i class="icon-cloud-upload"></i><br />Glissez l'image ici</span
          >
        </div>
      </dropzone>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Remise Offre *</label>
          <div class="input-group">
            <input
              type="number"
              v-model="form.discount.percentage"
              class="form-control"
              :disabled="editMode"
            />
            <span class="input-group-addon">%</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label><br /></label>
          <div class="input-group">
            <input
              type="number"
              v-model="form.discount.euros"
              class="form-control"
              :disabled="editMode"
              @input="(e) => formDiscountEurosInput(e.target.value)"
            />
            <span class="input-group-addon">€</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Influenceur</label>
          <el-checkbox
            v-model="form.isInfluencer"
            @input="updateInfluencer"
            :disabled="editMode"
            class="space-6"
          ></el-checkbox>
          <div class="input-group">
            <input
              type="number"
              v-model="form.influencerDiscount.percentage"
              class="form-control"
              :disabled="influencerDisabled"
            />
            <span class="input-group-addon">%</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label><br /></label>
          <div class="input-group">
            <input
              type="number"
              v-model="form.influencerDiscount.euros"
              class="form-control"
              :disabled="influencerDisabled"
              @input="(e) => formInfluDiscountEurosInput(e.target.value)"
            />
            <span class="input-group-addon">€</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label><i class="icon-user"></i></label>
      <select
        v-model="form.influencerId"
        class="form-control select-influs"
        :disabled="influencerDisabled"
      >
        <option
          v-if="influs.length === 0"
          :value="null"
          disabled
          class="text-center"
        >
          Aucun influenceur disponible
        </option>
        <option
          v-else
          v-for="{ value, label } in influs"
          :key="value"
          :value="value"
        >
          {{ label }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label>Code Promo *</label>
      <input
        type="text"
        v-model="form.code"
        class="form-control"
        :disabled="editMode"
      />
    </div>

    <div class="form-group">
      <label>Cible *</label>
      <input type="text" v-model="form.target" class="form-control" />
    </div>

    <div class="form-group">
      <label
        >Dates de validité
        <i
          class="fa fa-info-circle ml-05 h-tooltip"
          v-tooltip="{
            content:
              'Dates de la campagne promotionnelle, pendant laquelle le code promo pourra être utilisé par un client. Exemple : du 1er au 31 janvier.',
            placement: 'top',
          }"
        ></i
      ></label>
      <el-checkbox
        v-model="form.temporary"
        @input="updateDate"
        :disabled="editMode"
        class="space-6"
      ></el-checkbox>
      <div style="datepicker-ctn">
        <div class="d-inline">
          <el-date-picker
            v-model="form.startDate"
            type="date"
            placeholder="Date de début"
            :picker-options="pickerOptions0"
            :disabled="(startDateDisabled && !form.temporary) || editMode"
            format="dd/MM/yyyy"
          >
          </el-date-picker>
        </div>
        <div v-if="!isUniqueDate" class="d-inline">
          <span class="space-6">au</span>
          <el-date-picker
            v-model="form.endDate"
            type="date"
            placeholder="Date de fin"
            :picker-options="pickerOptions1"
            :disabled="(endDateDisabled && !form.temporary) || editMode"
            format="dd/MM/yyyy"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label
        >Application de la remise *
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{
            content:
              'Durée de validité de la promotion, une fois le code promo utilisé. Exemple : -20% pendant 12 mois.',
            placement: 'top',
          }"
        ></i
      ></label>
      <div class="form-group">
        <template>
          <input
            type="radio"
            id="forever"
            value="forever"
            v-model="form.duration"
            :disabled="editMode"
          />
          <label for="forever" class="space-6">A vie</label>
          <br />
          <input
            type="radio"
            id="repeating"
            value="repeating"
            v-model="form.duration"
            :disabled="editMode"
          />
          <label for="repeating" class="space-6">
            <span>Pendant </span>
            <input
              type="number"
              min="1"
              max="24"
              placeholder="12"
              v-model="form.duration_in_months"
              :disabled="editMode"
            />
            <span> mois</span>
          </label>
        </template>
      </div>
    </div>
    <div class="form-group">
      <label>Donne accès au groupe</label>
      <select v-model="form.groupId" class="form-control">
        <option v-for="{ id, name } in groups" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "vue-strap/src/Modal";
import Dropzone from "vue2-dropzone";

const TYPES = [
  {
    label: "Mode live",
    value: "live",
  },
  {
    label: "Mode Visio",
    value: "small",
  },
];

const LEVELS = [
  {
    label: "Tous niveaux",
    value: undefined,
  },
  {
    label: "Débutant",
    value: 0,
  },
  {
    label: "Intermédiaire",
    value: 1,
  },
  {
    label: "Confirmé",
    value: 2,
  },
];

export default {
  components: {
    Modal,
    Dropzone,
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
    promoCode: {
      type: Object,
      default: undefined,
    },
    influs: {
      type: Array,
      default: undefined,
    },
  },

  data: () => ({
    form: {
      id: null,
      client: {
        name: null,
      },
      clientId: null,
      photoId: null,
      discount: {
        percentage: 20,
        euros: null,
      },
      isInfluencer: null,
      influencerId: null,
      influencerDiscount: {
        percentage: 20,
        euros: null,
      },
      duration: "forever",
      duration_in_months: null,
      groupId: null,
    },
    modalOpened: false,
    loading: false,
    formError: false,
    types: TYPES,
    lvls: LEVELS,
    merchantsData: [],
    concepts: [],
    groups: [],
    isUniqueDate: false,
    promoCodeEligibleClients: [],
    endDateDisabled: true,
    startDateDisabled: true,
    influencerDisabled: true,
    clientsListDisabled: true,
  }),

  async mounted() {
    this.form = { ...this.promoCode };

    await this.fetchMerchants();
    await this.getGroups();

    this.modalOpened = this.modalShow;
  },

  methods: {
    async fetchMerchants() {
      try {
        const { data } = await this.$api.get("/merchants");

        this.merchantsData = data;
      } catch {
        this.merchantsData = [];
      }
    },

    async getGroups() {
      const { data } = await this.$api.get("/groups");

      this.groups = data;
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    photoUploaded(file, response) {
      this.form.photoId = response.id;
    },

    photoRemoved() {
      this.form.photId = null;
    },

    async handleAddPromoCode() {
      if (this.loading) {
        return false;
      }

      this.formError = false;

      this.loading = true;

      if (this.invalidForm) {
        // Required field(s) empty
        this.formError =
          "Veuillez renseigner l'ensemble des champs obligatoires (marqués par un *)";
      } else if (this.invalidCode) {
        this.formError =
          "Le code promo doit contenir au moins 6 caractères, uniquement des lettres et des chiffres, aucun caractère spécial n'est autorisé";
      } else if (this.form.duration === "repeating") {
        if (!this.form.duration_in_months) {
          // Duration in months empty
          this.formError =
            "Veuillez renseigner une durée d'application de la remise";
        } else if (
          parseInt(this.form.duration_in_months) < 1 ||
          parseInt(this.form.duration_in_months) > 24
        ) {
          // Duration in months invalid
          this.formError =
            "La durée d'application de la remise doit être comprise entre 1 et 24 mois";
        }
      }

      if (!this.formError) {
        try {
          await this.$api.post("/promo-codes", this.form);

          this.closeModal();
        } catch (e) {
          // Error when saving promo code
          this.formError = `Une erreur s'est produite : ${e.response.data}`;
        }
      }

      this.loading = false;

      if (this.formError) {
        this.$errorToast(this.formError);

        return;
      } else {
        this.$successToast("Code Promo créé avec succès !");

        return;
      }
    },

    async handleEditPromoCode() {
      this.loading = true;

      try {
        await this.$api.put(`/promo-codes/${this.form.id}`, this.form);

        this.closeModal();
      } catch (e) {
        this.formError = `Une erreur s'est produite : ${e.response.data}`;
      }
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;
      this.concepts = [];
      this.$refs.dropzone.removeAllFiles(true);

      this.$emit("close");
    },

    updateDate() {
      if (this.promoCode.temporary) {
        this.startDateDisabled = false;
        this.endDateDisabled = false;
      } else {
        this.startDateDisabled = true;
        this.endDateDisabled = true;
      }
    },
    getPictureImage(id) {
      return `${process.env.VUE_APP_API_URL}live-origins/${id}/photo`;
    },
    updateInfluencer() {
      if (this.promoCode.isInfluencer) {
        this.influencerDisabled = false;
      } else {
        this.influencerDisabled = true;
        this.form.influencerId = null;
      }
    },

    async handleMerchantChange(merchantId) {
      if (merchantId) {
        try {
          const params = { merchantId };
          const { data } = await this.$api.get("/concepts", { params });

          this.concepts = data;

          if (
            this.form.type &&
            !this.concepts.some((concept) => concept.name === this.form.type)
          ) {
            this.form.type = null;
          }
        } catch (e) {
          this.concepts = [];
        }
      }
    },

    formDiscountEurosInput(value) {
      this.form.discount.euros = this.formatFormPrice(value);
    },

    formInfluDiscountEurosInput(value) {
      this.form.influencerDiscount.euros = this.formatFormPrice(value);
    },
  },

  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL || "";
    },
    invalidCode() {
      const stripeCodeRegex = /^[a-zA-Z0-9]{6,}$/;

      return this.form.code && !this.form.code.match(stripeCodeRegex);
    },
    invalidForm() {
      return (
        !this.form.merchantId ||
        !this.form.type ||
        !this.form.description ||
        !this.form.photoId ||
        (!this.form.discount.percentage && !this.form.discount.euros) ||
        (this.form.isInfluencer &&
          (!this.form.influencerId ||
            (!this.form.influencerDiscount.percentage &&
              !this.form.influencerDiscount.euros))) ||
        !this.form.code ||
        !this.form.target ||
        !this.form.duration
      );
    },
    pickerOptions0() {
      return {
        firstDayOfWeek: 1,
      };
    },
    pickerOptions1() {
      return {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.startDate);
        },
      };
    },
  },

  watch: {
    modalShow: function (newVal, oldVal) {
      if (this.form.temporary) {
        this.startDateDisabled = false;
        this.endDateDisabled = false;
      } else {
        this.startDateDisabled = true;
        this.endDateDisabled = true;
      }

      if (this.form.isInfluencer) {
        this.influencerDisabled = false;
      } else {
        this.influencerDisabled = true;
      }
      if (newVal !== oldVal) {
        this.modalOpened = newVal;

        if (newVal) {
          this.form = this.promoCode;
          this.form.id = this.promoCode.id;
          this.form.photoId = this.promoCode.photoId;
          this.form.discount = {
            percentage: this.promoCode.discount?.percentage,
            euros: this.promoCode.discount?.euros,
          };
          this.form.isInfluencer = this.promoCode.isInfluencer;
          this.form.influencerId = this.promoCode.influencerId;
          this.form.influencerDiscount = {
            percentage: this.promoCode.influencerDiscount?.percentage,
            euros: this.promoCode.influencerDiscount?.euros,
          };

          if (this.form.merchantId) {
            this.handleMerchantChange(this.form.merchantId);
          }
        }
      }
    },
    isUniqueDate: {
      handler: function () {
        if (this.isUniqueDate) {
          this.endDate = this.startDate;
        }
      },
    },
    startDate: {
      handler: function () {
        if (this.isUniqueDate) {
          this.endDate = this.startDate;
        }
      },
    },
  },
};
</script>
<style lang="scss">
.datepicker-ctn {
  width: 100%;

  .custom-datepicker {
    border-radius: 0px;

    &.el-date-editor {
      width: 100%;
    }
  }
}

.form-group .el-date-editor .el-range-separator {
  width: auto;
  min-width: 5%;
}

.space-6 {
  margin: 0 6px;
}

.h-tooltip {
  z-index: 99999 !important;
}

.select-influs {
  &:disabled {
    background-color: #e1e6ef !important;
    color: #888;
  }
}
</style>

<template>
  <div class="p-2">
    <h4>Total: {{ data.length }} coach{{ data.length > 1 ? 's' : '' }}.</h4>
    <div class="mt-2">
      <p v-if="actives.length > 0" :style="getStatusColor('active')">
        Actifs: {{ actives.length }}
      </p>
    </div>
    <div>
      <p v-if="inactives.length > 0" :style="getStatusColor('inactive')">
      Inactifs: {{ inactives.length }}
    </p>
    </div>
    <div>
      <p v-if="recruiting.length > 0" :style="getStatusColor('recruiting')">
        Recrutement: {{ recruiting.length }}
      </p>
    </div>
    <div>
      <p v-if="contact.length > 0" :style="getStatusColor('contact')">
        Contactés: {{ contact.length }}
      </p>
    </div>
    <div>
      <p v-if="recruited.length > 0" :style="getStatusColor('recruited')">
        Recrutés: {{ recruited.length }}
      </p>
    </div>
    <div>
      <p v-if="blocked.length > 0" :style="getStatusColor('suspended')">
        Bloqués: {{ blocked.length }}
      </p>
    </div>
    <div>
      <p v-if="imported.length > 0" :style="getStatusColor('imported')">
        Import: {{ imported.length }}
      </p>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
export default {
  props: {
    data: { type: Array, required: true },
  },

  computed: {
    ...mapGetters({
      coachStatus: "getCoachStatus",
    }),
    actives() {
      return this.data.filter((coach) => coach.status === "active");
    },
    inactives() {
      return this.data.filter((coach) => coach.status === "inactive");
    },
    recruiting() {
      return this.data.filter((coach) => coach.status === "recruiting");
    },
    contact() {
      return this.data.filter((coach) => coach.status === "contact");
    },
    recruited() {
      return this.data.filter((coach) => coach.status === "recruited");
    },
    blocked() {
      return this.data.filter((coach) => coach.status === "suspended");
    },
    imported() {
      return this.data.filter((coach) => coach.status === "imported");
    }
  },

  methods: {
    getStatusColor(status) {
      return {
        'display': 'inline-block',
        'border-radius': '100px',
        'padding': '3px 20px',
        'word-break': 'break-word',
        'color': 'white',
        'background-color': this.coachStatus.find((s) => s.value === status).color,
      }
    }
  }
};
</script>

<template>
  <div>
    <el-table
      :data="paginatedClients"
      :default-sort="{ prop: 'relatedSince', order: 'descending' }"
      emptyText="Aucun client n'appartient à ce groupe"
    >
      <el-table-column
        label="Nom"
        prop="client.fullName"
        align="left"
        width="200"
        sortable
      >
        <template slot-scope="scope">
          <i v-if="isReferent(scope.row.clientEmail)" class="fa fa-flag"></i>
          {{ scope.row.client.fullName }}
        </template>
      </el-table-column>
      <el-table-column label="Ville" prop="client.city" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.client.city
          }}{{
            scope.row.client.zipcode
              ? ` (${scope.row.client.zipcode.substr(0, 2)})`
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="Fiche" prop="client.id" align="center" width="60">
        <template slot-scope="scope">
          <a :href="clientHref(scope.row.client.id)" target="_blank"
            ><i class="icon-user"></i
          ></a>
        </template>
      </el-table-column>
      <el-table-column
        label="Depuis le"
        prop="relatedSince"
        align="center"
        width="150"
        sortable
      >
        <template slot-scope="scope">
          {{ formatDate(scope.row.relatedSince) }}
        </template>
      </el-table-column>
      <el-table-column label="Commerciaux" align="center">
        <template slot-scope="scope">
          <CommercialRel
            v-for="(rel, index) in scope.row.client.commercials"
            :key="`com-${index}`"
            :relation="rel"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Créé le"
        prop="client.createdAt"
        align="center"
        width="150"
        sortable
      >
        <template slot-scope="scope">
          {{ formatDate(scope.row.client.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column label="Concepts" align="center">
        <template slot-scope="scope">
          <span
            v-for="(concept, index) in scope.row.client.concepts"
            :key="concept.name"
          >
            <br v-if="index !== 0" />
            {{ concept.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Note" align="center" min-width="100">
        <template slot-scope="scope">
          <NoteModal
            :notes="scope.row.client.commercialNotes"
            :key="scope.row.client.id"
            @showComment="showNote(scope.row.client)"
            v-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
          />
          <span v-else>{{ scope.row.client.commercialNotes }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="150">
        <template slot-scope="scope">
          <button
            v-if="
              !isReferent(scope.row.clientEmail) &&
              hasPermission($store.state.user, 'GROUP_MENU_WRITE')
            "
            type="button"
            class="btn btn-sm btn-default ml-1"
            @click="editReferent(scope.row.clientEmail)"
          >
            <i class="fa fa-flag-o"></i>
          </button>
          <button
            v-else-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
            type="button"
            class="btn btn-sm btn-default ml-1"
            @click="removeGroupReferent"
          >
            <i class="fa fa-flag"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger ml-1"
            @click="removeFromGroup(scope.row.clientEmail)"
            v-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
          >
            <i class="fa fa-times"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <paginate
      v-model="currentPage"
      :page-count="pageCount"
      :click-handler="handleCurrentChange"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
      style="padding: 1.25rem 0"
    ></paginate>

    <ClientNoteModal
      :show="noteModal.show"
      :client="noteModal.client"
      @close="noteModal.show = false"
    />
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import CommercialRel from "../components/CommercialRel";
import NoteModal from "../components/form/NoteModal";
import ClientNoteModal from "../components/ClientNoteModal";

export default {
  components: {
    Paginate,
    CommercialRel,
    NoteModal,
    ClientNoteModal,
  },

  props: {
    clients: {
      type: Array,
      default: () => [],
    },

    referent: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      currentPage: 1,
      limit: 15,
      noteModal: {
        show: false,
        client: null,
      },
    };
  },

  computed: {
    paginatedClients() {
      return this.clients.slice(
        this.currentPage * this.limit - this.limit,
        this.currentPage * this.limit
      );
    },

    pageCount() {
      return Math.ceil(this.clients.length / this.limit);
    },
  },

  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    clientHref(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      return route.href;
    },

    showNote(client) {
      this.noteModal.client = client;
      this.noteModal.show = true;
    },

    isReferent(email) {
      return this.referent?.email === email;
    },

    removeFromGroup(clientEmail) {
      this.$emit("remove", clientEmail);
    },

    editReferent(clientEmail) {
      this.$emit("edit-referent", clientEmail);
    },

    removeGroupReferent() {
      this.$emit("remove-referent");
    },
  },
};
</script>

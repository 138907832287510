<template>
  <div class="card">
    <div class="card-header"><i class="fa fa-bullseye"></i> Statut actuel</div>

    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">Statut</dt>
        <dd class="col-sm-8">
          <span v-if="isLiveAdmin">Administrateur</span>
          <span v-if="isFreeAccess">Accès offert</span>
          <div v-if="isSubscriber">
            <span v-if="subscription.status === 'active'"
              >Abonné.e
              <span v-if="subscription.cancel_at_period_end"
                >(résilié)</span
              ></span
            >
            <span v-if="subscription.status === 'trialing'"
              >En cours d'essai</span
            >
          </div>
          <span v-if="hasSignedUp">Inscrit.e</span>
        </dd>
        <template v-if="isFreeAccess">
          <template v-if="freeAccess.invitationCode">
            <dt class="col-sm-4">Code d'invitation</dt>
            <dd class="col-sm-8">{{ freeAccess.invitationCode.code }}</dd>
          </template>
          <dt class="col-sm-4">Mode</dt>
          <dd class="col-sm-8">
            {{ freeAccess.type === "small" ? "Visio & Live" : "Live" }}
          </dd>
          <dt class="col-sm-4">Depuis le</dt>
          <dd class="col-sm-8">{{ formatDate(freeAccess.createdAt) }}</dd>
          <dt class="col-sm-4">Valide jusqu'au</dt>
          <dd class="col-sm-8">
            {{ formatDate(freeAccess.validUntil) }}
          </dd>
        </template>
        <template v-if="isSubscriber">
          <dt class="col-sm-4">Mode</dt>
          <dd class="col-sm-8">{{ subscription.type }}</dd>
          <dt class="col-sm-4">Depuis le</dt>
          <dd class="col-sm-8">
            {{ formatDate(subscription.created * 1000) }}
          </dd>
          <template v-if="subscription.promoCode">
            <dt class="col-sm-4">Code promo</dt>
            <dd class="col-sm-8">{{ subscription.promoCode.code }}</dd>
          </template>
          <template v-if="subscription.invoices">
            <dt class="col-sm-4">Factures</dt>
            <dd class="col-sm-8">{{ subscription.invoices.length }}</dd>
          </template>
          <dt class="col-sm-4">
            <span v-if="subscription.cancel_at_period_end">Prend fin le</span>
            <span v-else-if="subscription.status === 'active'"
              >Prochaine échéance</span
            >
            <span v-else-if="subscription.status === 'trialing'"
              >Fin d'essai le</span
            >
          </dt>
          <dd class="col-sm-8">
            <span v-if="subscription.cancel_at_period_end">{{
              formatDate(subscription.cancel_at_period_end * 1000)
            }}</span>
            <span v-else-if="subscription.status === 'active'">{{
              formatDate(subscription.current_period_end * 1000)
            }}</span>
            <span v-else-if="subscription.status === 'trialing'">{{
              formatDate(subscription.trial_end * 1000)
            }}</span>
          </dd>
        </template>
      </dl>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    client: {
      type: Object,
      required: true,
    },

    subscription: {
      type: Object,
      default: null,
    },
  },

  computed: {
    freeAccess() {
      const validAccess = this.client.liveFreeAccess.filter(
        (access) =>
          !access.revoked && moment(access.validUntil).isAfter(moment())
      );

      if (validAccess.length > 0) {
        const access = validAccess.reduce((a, b) =>
          a.validUntil > b.validUntil ? a : b
        );

        return access;
      }

      return null;
    },

    currentStatus() {
      if (this.client.admin) {
        return "admin";
      }

      if (this.freeAccess) {
        return "freeAccess";
      }

      if (this.subscription) {
        return "subscriber";
      }

      return "hasAccount";
    },

    isLiveAdmin() {
      return this.currentStatus === "admin";
    },

    isFreeAccess() {
      return this.currentStatus === "freeAccess";
    },

    isSubscriber() {
      return this.currentStatus === "subscriber";
    },

    hasSignedUp() {
      return this.currentStatus === "hasAccount";
    },
  },
};
</script>

<template>
  <el-table :data="data">
    <el-table-column label="Filleul.e(s)">
      <template slot-scope="scope">
        <a :href="clientHref(scope.row.sponsored.id)" target="_blank"
          >{{ scope.row.sponsored.name }} <i class="icon-user"></i
        ></a>
      </template>
    </el-table-column>
    <el-table-column label="Date">
      <template slot-scope="scope">
        {{ formatDate(scope.row.freeSessionsPack.createdAt) }}
      </template>
    </el-table-column>
    <el-table-column label="Commande(s)">
      <template slot-scope="scope">
        {{ scope.row.firstPack.session }} séances
      </template>
    </el-table-column>
    <el-table-column label="Gain(s)">
      <template slot-scope="scope">
        {{ scope.row.freeSessionsPack.session }} séance{{
          scope.row.freeSessionsPack.session > 1 ? "s" : ""
        }}
      </template>
    </el-table-column>
    <el-table-column label="Note">
      <template slot-scope="scope">
        <NoteModal
          :notes="scope.row.notes"
          @showComment="openNoteModal(scope.row)"
          permission="CLIENTS_WRITE"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import NoteModal from "./form/NoteModal";

export default {
  components: {
    NoteModal,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    clientHref(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      return route.href;
    },

    openNoteModal(sponsorship) {
      this.$emit("openNoteModal", sponsorship);
    },
  },
};
</script>

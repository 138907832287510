<template>
  <div class="rank-custom-wrapper">
    <RankConversionFilters :default="filters" @update="handleFilters" />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>
    <div class="card text-center" v-else-if="data.length === 0">
      <div class="card-block">
        <blockquote class="card-blockquote">
          Aucune données, appliquer ou modifier les filtres
        </blockquote>
      </div>
    </div>

    <div v-else>
      <Tabs v-model="activeTab">
        <Tab header="Synthèse">
          <RankConversionResume :data="mapData" :filters="filters" />
        </Tab>
        <Tab header="Données individuelles">
          <RankConversionDetails :data="data" :filters="filters" />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import RankConversionFilters from "../components/rank/conversion/RankConversionFilters";
import RankConversionResume from "../components/rank/conversion/RankConversionResume";
import RankConversionDetails from "../components/rank/conversion/RankConversionDetails";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: {
    RankConversionFilters,
    RankConversionResume,
    RankConversionDetails,
    Tabs,
    Tab,
  },
  data() {
    return {
      loading: false,
      data: [],
      activeTab: 0,
      filters: this.defaultFilters(),
    };
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
    }),

    zipcodes() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    mapData() {
      const domTom = ["971", "972", "973", "974", "976"];
      const departments = [...this.zipcodes].filter(
        (dpt) => !domTom.includes(dpt.value) && dpt.value !== "all"
      );

      departments.forEach((dpt) => {
        dpt.data = this.departmentCoachInfo(dpt.value);
      });

      return departments;
    },
  },

  methods: {
    handleFilters(newFilters) {
      this.filters = newFilters;

      this.loadCoachs();
    },

    async loadCoachs() {
      try {
        this.loading = true;

        const { data } = await this.$api.get(`/rank/conversions`, {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
          },
        });

        this.data = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    departmentCoachInfo(dpt) {
      let departmentCoachs = this.data.filter((coach) => {
        if (coach.department && typeof coach.department === "string") {
          return coach.department.slice(0, 2) === dpt;
        }
      });

      console.log(departmentCoachs);
      return departmentCoachs;
    },

    defaultFilters() {
      return {
        begin: moment().subtract(1, "months").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      };
    },
  },
};
</script>

<template>
  <div class="animated fade-in">
    <div class="row form-group">
      <div v-if="!fileUploadedSuccess" class="col-12 mb-1">
        <div v-if="!showTemplateInfo">
          <span
            class="text-primary pointer"
            @click="showTemplateInfo = !showTemplateInfo"
            >Voir les spécifications pour le document à importer</span
          >
        </div>
        <div v-else>
          Titres de colonne pris en compte :
          <div class="my-1">
            <span v-for="(colTitle, index) in colTitles" :key="colTitle.title">
              {{ colTitle.title }}
              <i
                class="fa fa-info-circle ml-05"
                v-tooltip="{ content: colTitle.info, placement: 'right' }"
                v-if="colTitle.info"
              ></i>
              <span
                v-if="index !== colTitles.length - 1"
                style="padding-left: .5rem; padding-right: .5rem;"
              >
                |
              </span>
            </span>
          </div>
          <span
            class="text-primary pointer"
            @click="showTemplateInfo = !showTemplateInfo"
            >Masquer</span
          >
        </div>
        <div class="mt-1">
          <span class="text-primary pointer" @click="downloadTemplate">
            <i class="fa fa-cloud-download"></i>&nbsp; Télécharger le modèle
          </span>
        </div>
      </div>
      <div v-if="!fileUploadedSuccess" class="col-12">
        <div class="alert alert-info" role="alert">
          Les prestataires ajoutés ici auront le statut 'Import'
        </div>

        <dropzone
          id="file"
          :url="apiImportCoachs"
          :headers="apiAuthorization"
          acceptedFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv"
          v-on:vdropzone-success="fileUploaded"
          v-if="hasPermission($store.state.user, 'IMPORT_WRITE')"
        >
          <div class="dz-default dz-message">
            <span
              ><i class="icon-cloud-upload"></i><br />Glissez un fichier
              contenant des informations de prestataires (format Excel ou
              CSV)</span
            >
          </div>
        </dropzone>
      </div>
      <div
        v-if="fileUploadedSuccess"
        class="col-12 d-flex flex-column align-items-center"
      >
        <span class="text-success">
          <strong>{{ totalImported }}</strong> prestataire(s) importé(s) avec
          succès !
        </span>
        <span class="text-success">
          <strong>{{ fileUploadedSuccess.created }}</strong> prestataire(s)
          créé(s)
        </span>
        <span class="text-success">
          <strong>{{ fileUploadedSuccess.updated }}</strong> prestataire(s) mis
          à jour
        </span>
        <span
          class="text-primary upload-other mt-1"
          @click="fileUploadedSuccess = null"
          >Importer un autre fichier ?</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";

import Dropzone from "vue2-dropzone";

export default {
  components: {
    Dropzone
  },

  data() {
    return {
      fileUploadedSuccess: null,
      colTitles: [
        { title: "firstName", info: "Nom de famille" },
        { title: "lastName", info: "Prénom" },
        { title: "email", info: "Adresse email" },
        { title: "city", info: "Ville" },
        { title: "zipcode", info: "Code postal" },
        { title: "phone", info: "Numéro de téléphone" },
        { title: "gender", info: "Genre ('H' ou 'F')" },
        {
          title: "type",
          info:
            "'influenceur', 'formateur' ou 'coach' (si non renseigné, la valeur par défaut est 'coach')"
        },
        {
          title: "prestations",
          info:
            "Prestations présentes dans la liste renseignée dans les Paramètres (séparées par une virgule et sans accents)"
        },
        {
          title: "tags",
          info:
            "Tags présents dans la liste renseignée dans les Paramètres (séparés par une virgule et sans accents)"
        },
        { title: "langs", info: "Langues parlées (séparées par une virgule)" },
        { title: "notes", info: "Notes concernant le prestataire" }
      ],
      showTemplateInfo: false
    };
  },

  computed: {
    apiImportCoachs() {
      return axios.defaults.baseURL + "users/coachs/import";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token")
      };
    },

    totalImported() {
      const created = this.fileUploadedSuccess?.created
        ? this.fileUploadedSuccess.created
        : 0;
      const updated = this.fileUploadedSuccess?.updated
        ? this.fileUploadedSuccess.updated
        : 0;

      return created + updated;
    }
  },

  methods: {
    fileUploaded(file, response) {
      this.fileUploadedSuccess = {
        created: response.created,
        updated: response.updated
      };
    },

    downloadTemplate() {
      const headersRow = [
        [
          "firstName",
          "lastName",
          "email",
          "city",
          "zipcode",
          "phone",
          "gender",
          "type",
          "prestations",
          "tags",
          "langs",
          "notes"
        ]
      ];

      const ws = XLSX.utils.json_to_sheet(headersRow, { skipHeader: true });
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, "TEMPLATE_IMPORT_PRESTATAIRES");
      XLSX.writeFile(wb, "TEMPLATE_IMPORT_PRESTATAIRES.xlsx");
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-other {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
</style>

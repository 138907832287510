<template>
  <div>
    <div class="d-flex mb-1">
      <div
        v-if="disbursements.length > 1 && selected.length > 0"
        class="d-flex"
      >
        <div v-if="type === 'processed'">
          <button
            class="btn btn-danger btn-sm"
            @click="processedAll()"
            v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
          >
            <i class="fa fa-check-circle"></i> Marquer les factures
            séléctionnées comme payées
          </button>
          <button
            class="btn btn-outline-danger btn-sm ml-1"
            @click="unprocessedAllDisbursement()"
            v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
          >
            <i class="fa fa-exclamation-triangle"></i> Annuler le traitement
          </button>
        </div>
        <div v-else-if="type === 'payed'">
          <button
            class="btn btn-info btn-sm"
            @click="archivedAll()"
            v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
          >
            <i class="fa fa-check-circle"></i> Archiver les factures
            séléctionnées
          </button>
          <button
            class="btn btn-outline-danger btn-sm ml-1"
            @click="unpayedAllDisbursement()"
            v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
          >
            <i class="fa fa-exclamation-triangle"></i> Annuler le paiement
          </button>
        </div>
        <div v-else>
          <button
            class="btn btn-success btn-sm ml-1"
            @click="processedAllDisbursement()"
            v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
          >
            <i class="fa fa-check-circle"></i> Factures traitées
          </button>
        </div>
        <button
          v-if="selected.length > 1"
          class="btn btn-default btn-sm ml-1"
          @click="downloadInvoices()"
        >
          <i class="fa fa-cloud-download"></i> Télécharger une archive des
          factures
        </button>
        <button
          v-if="selected.length > 1"
          class="btn btn-warning btn-sm ml-1"
          @click="downloadInvoices('merged-pdf')"
        >
          <i class="fa fa-cloud-download"></i> Télécharger un pdf combiné
        </button>
      </div>
    </div>
    <table class="table table-condensed">
      <thead>
        <th v-if="disbursements.length > 1">
          <input v-model="allChecked" type="checkbox" @click="toggleSelected" />
        </th>
        <th></th>
        <th>Date</th>
        <th>Société</th>
        <th v-if="coach">Coach</th>
        <th>Description</th>
        <th v-if="type === 'processed'">Traité le</th>
        <th v-if="type === 'payed'">Payé le</th>
        <th>Montant HT</th>
        <th>Statut</th>
      </thead>
      <tbody v-if="disbursements.length === 0">
        <tr class="text-center">
          <td colspan="8">
            <h5 class="text-warning mt-2">Aucune facture disponible</h5>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="!isLoading">
        <tr v-for="(disbursement, index) in disbursements" :key="index">
          <td v-if="disbursements.length > 1">
            <input
              type="checkbox"
              :value="disbursement.id"
              v-model="selected"
              :disabled="coach && coachHasRefundBlocked(disbursement)"
            />
          </td>
          <td>#{{ index + 1 }}</td>
          <td>{{ formatDate(disbursement.createdAt) }}</td>
          <td>
            <span v-if="disbursement.merchant">{{
              disbursement.merchant.name
            }}</span>
          </td>
          <td v-if="coach">
            <DisplayCoachName
              :coach="disbursement.coach"
              cssClass="user-link"
            ></DisplayCoachName>
          </td>
          <td>
            <button
              class="btn btn-link btn-sm"
              v-if="!disbursement.sessions && !disbursement.promoCodes"
              @click="getSessions(disbursement.id)"
            >
              Voir les détails
            </button>
            <div
              v-for="(sessions, clientId) in getClientSessions(disbursement)"
              :key="clientId"
              v-else-if="disbursement.sessions"
            >
              <template v-if="sessions[0].client && sessions[0].client.company"
                ><u>Société</u> : {{ sessions[0].client.company }}</template
              >
              <template v-else-if="sessions[0].client"
                ><u>Client</u> : {{ sessions[0].client.name }}</template
              >
              <template v-else
                ><u>Client</u> :
                <span class="text-danger">n'existe plus</span></template
              >
              <br />
              <ul>
                <li v-for="(session, index) in sessions" :key="index">
                  Séance : {{ session.counter }}/{{ session.order.session
                  }}<br />
                  <template v-if="session.packs"
                    >Pack:
                    <i
                      class="fa fa-cart-plus"
                      v-bind:class="{
                        'text-success': session.packsCaptured > 0,
                      }" />
                    {{ session.packsCaptured }}/{{ session.packs }}<br
                  /></template>
                  Planifiée le : {{ formatDate(session.scheduledAt) }}<br />
                  <span v-if="session.credit"
                    >Tarif : {{ formatPrice(session.credit) }}</span
                  >
                </li>
              </ul>
            </div>
            <div v-else-if="disbursement.promoCodes">
              <b>Code(s) promo : </b>
              <ul>
                <span
                  v-for="(code, index) in disbursement.promoCodes"
                  :key="index"
                >
                  <li v-if="code.commission">
                    {{ code.code }}
                  </li>
                </span>
              </ul>
            </div>
          </td>
          <td v-if="type === 'processed'">
            {{ formatDate(disbursement.processedAt) }}
          </td>
          <td v-if="type === 'payed'">
            {{ formatDate(disbursement.completedAt) }}
          </td>
          <td>{{ formatPrice(disbursement.credit, true) }}</td>
          <td v-if="pending">
            <div>
              <div v-if="type === 'processed'">
                <button
                  class="btn btn-danger btn-sm"
                  @click="process(disbursement.id)"
                  v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
                >
                  <i class="fa fa-check-circle"></i> Marquer la facture comme
                  payée
                </button>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="unprocessedDisbursement(disbursement.id)"
                  v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
                >
                  <i class="fa fa-exclamation-triangle"></i> Annuler le
                  traitement
                </button>
              </div>
              <div v-else-if="type === 'payed'">
                <button
                  class="btn btn-info btn-sm"
                  @click="archive(disbursement.id)"
                  v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
                >
                  <i class="fa fa-check-circle"></i> Archiver la facture
                </button>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="unpayedDisbursement(disbursement.id)"
                  v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
                >
                  <i class="fa fa-exclamation-triangle"></i> Annuler le paiement
                </button>
              </div>
              <div v-else>
                <span
                  v-if="coachHasRefundBlocked(disbursement)"
                  class="alert alert-warning m-0"
                >
                  Les factures pour ce coach sont bloqués
                </span>
                <button
                  class="btn btn-success btn-sm"
                  @click="processedDisbursement(disbursement.id)"
                  v-if="
                    !coachHasRefundBlocked(disbursement) &&
                    hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')
                  "
                >
                  <i class="fa fa-check-circle"></i> Facture traitée
                </button>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="reject(disbursement.id)"
                  v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
                >
                  <i class="fa fa-exclamation-triangle"></i> Refuser la facture
                </button>
              </div>
              <br />
              <a
                @click="
                  downloadSecuredFile(
                    `uploads/invoice/${disbursement.invoice}.pdf`
                  )
                "
                class="btn btn-sm btn-outline-primary"
                v-if="disbursement.invoice"
              >
                <i class="icon-cloud-download"></i>&nbsp; Télécharger
              </a>
            </div>
          </td>
          <td v-else>
            <span class="badge badge-success" v-if="disbursement.completed"
              >Payée le {{ formatDate(disbursement.completedAt) }}</span
            >
            <el-popover
              trigger="hover"
              placement="top"
              :width="400"
              v-else-if="disbursement.blocked && disbursement.blockedMessage"
            >
              <div class="p-1 text-justify">
                <p>
                  {{ disbursement.blockedMessage }}
                </p>
              </div>
              <span slot="reference">
                <span class="badge badge-danger">
                  Bloqué
                  {{
                    disbursement.blockedAt
                      ? `le ${formatDate(disbursement.blockedAt)}`
                      : ""
                  }}
                </span>
              </span>
            </el-popover>
            <span class="badge badge-danger" v-else-if="disbursement.blocked">
              Bloqué
              {{
                disbursement.blockedAt
                  ? `le ${formatDate(disbursement.blockedAt)}`
                  : ""
              }}
            </span>
            <span class="badge badge-warning" v-else
              >En cours de validation</span
            >
            <br />
            <button
              @click="
                downloadSecuredFile(
                  `uploads/invoice/${disbursement.invoice}.pdf`
                )
              "
              class="btn btn-sm btn-outline-primary"
              v-if="disbursement.invoice"
            >
              <i class="icon-cloud-download"></i>&nbsp; Télécharger
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <Loader :isVisible="isLoading" size="small" />
      </tbody>
    </table>
    <modal
      title="Refuser la facture"
      v-model="rejectModal"
      cancelText="Annuler"
      okText="Refuser"
      @ok="handleReject(rejectId)"
      effect="fade/zoom"
      large
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Raison du rejet de la facture</h4>
      </div>
      <textarea class="form-control" v-model="rejectMessage"></textarea>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import moment from "moment-timezone";
import DisplayCoachName from "../components/coach/displayCoachName";
import Loader from "../components/Loader";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Modal,
    DisplayCoachName,
    Loader,
  },
  mixins: [DownloadFile],
  props: {
    disbursementsData: {
      type: Array,
      default: null,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    coach: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showSubstitution: null,
      showClients: false,
      clients: null,
      rejectModal: false,
      rejectId: null,
      rejectMessage: null,
      selected: [],
      isLoading: false,
      disbursements: [],
      allChecked: false,
    };
  },

  created() {
    this.disbursements = this.disbursementsData;
  },

  methods: {
    getClientSessions(disbursement) {
      let clients = {};

      disbursement.sessions.forEach((session) => {
        clients[session.clientId] = clients[session.clientId] || [];
        clients[session.clientId].push(session);
      });

      for (let clientId in clients) {
        clients[clientId] = clients[clientId].sort(
          (a, b) => a.counter - b.counter
        );
      }

      return clients;
    },
    getSessions(disbursementId) {
      this.$api.get("/disbursements/" + disbursementId).then((res) => {
        var disbursement = this.disbursements.find(
          (d) => d.id === disbursementId
        );

        let sessions = res.data.sessions;

        const getPacks = async (orderId) => {
          return this.$api.get("/orders/packs/" + orderId);
        };

        const getAllPacks = async () => {
          return Promise.all(
            sessions.map((session) => getPacks(session.orderId))
          );
        };

        getAllPacks().then((data) => {
          data.forEach((res) => {
            let sessionIndex = sessions.findIndex(
              (session) => session.orderId === res.data.orderId
            );

            sessions[sessionIndex].packs = res.data.packs;
            sessions[sessionIndex].packsCaptured = res.data.packsCaptured;
          });
        });

        this.$set(disbursement, "sessions", sessions);
      });
    },

    async downloadInvoices(group = false) {
      try {
        this.isLoading = true;
        const invoiceIds = this.disbursements
          .filter((d) => this.selected.includes(d.id))
          .map((d) => d.invoice);

        const { data } = await this.$api.post("/uploads/download", {
          invoiceIds,
          group,
        });

        this.downloadSecuredFile(data.archive);
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },

    unprocessedAllDisbursement() {
      this.$confirm({
        message: `Rebasculer toutes les factures dans la section "En attente" ?`,
        onConfirm: async () => {
          for (const select of this.selected) {
            await this.unprocessedDisbursement(select, false, false);
          }

          this.updateData(this.selected);
        },
      });
    },

    async unprocessedDisbursement(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message: `Rebasculer la facture dans la section "En attente" ?`,
          onConfirm: async () => {
            await this.$api.patch(
              `/disbursements/${disbursementId}/processed`,
              {
                processed: 0,
              }
            );

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.patch(`/disbursements/${disbursementId}/processed`, {
          processed: 0,
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    processedAllDisbursement() {
      this.$confirm({
        message: "Marquer toutes les factures comme traitées ?",
        onConfirm: async () => {
          for (const select of this.selected) {
            await this.processedDisbursement(select, false, false);
          }

          this.updateData(this.selected);
        },
      });
    },

    async processedDisbursement(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message: "Marquer la facture comme traitée ?",
          onConfirm: async () => {
            await this.$api.patch(
              `/disbursements/${disbursementId}/processed`,
              {
                processed: 1,
              }
            );

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.patch(`/disbursements/${disbursementId}/processed`, {
          processed: 1,
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    processedAll() {
      this.$confirm({
        message: "Marquer toutes les factures comme payées ?",
        onConfirm: async () => {
          for (const select of this.selected) {
            await this.process(select, false, false);
          }

          this.updateData(this.selected);
        },
      });
    },

    async process(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message: "Marquer la facture comme payée ?",
          onConfirm: async () => {
            await this.$api.put(`/disbursements/${disbursementId}`, {
              completed: true,
              completedAt: moment().format(),
            });

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.put(`/disbursements/${disbursementId}`, {
          completed: true,
          completedAt: moment().format(),
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    unpayedAllDisbursement() {
      this.$confirm({
        message: "Annuler le paiement de toutes les factures séléctionnées ?",
        onConfirm: async () => {
          for (const select of this.selected) {
            await this.unpayedDisbursement(select, false, false);
          }

          this.updateData(this.selected);
        },
      });
    },

    async unpayedDisbursement(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message: "Annuler le paiement ?",
          onConfirm: async () => {
            await this.$api.put(`/disbursements/${disbursementId}`, {
              completed: false,
              completedAt: null,
            });

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.put(`/disbursements/${disbursementId}`, {
          completed: false,
          completedAt: null,
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    archivedAll() {
      this.$confirm({
        message:
          "Archiver toutes les factures ? Cette action est irréversible.",
        onConfirm: async () => {
          for (const select of this.selected) {
            await this.archive(select, false, false);
          }

          this.updateData(this.selected);
        },
      });
    },

    async archive(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message:
            "Marquer la facture comme payée ? Cette action est irréversible.",
          onConfirm: async () => {
            await this.$api.put(`/disbursements/${disbursementId}`, {
              archived: true,
              archivedAt: moment().format(),
            });

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.put(`/disbursements/${disbursementId}`, {
          archived: true,
          archivedAt: moment().format(),
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    reject(disbursementId) {
      this.rejectId = disbursementId;
      this.rejectModal = true;
      this.rejectMessage = null;
    },

    handleReject(disbursementId) {
      this.$confirm({
        message:
          "Refuser la facture et débloquer les séances ? Cette action est irréversible.",
        onConfirm: async () => {
          await this.$api.delete(`/disbursements/${disbursementId}`, {
            data: {
              message: this.rejectMessage || "",
            },
          });

          this.rejectId = null;
          this.rejectModal = false;
          this.rejectMessage = null;

          this.updateData([disbursementId]);
        },
      });
    },

    goToUser(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    coachHasRefundBlocked(disbursement) {
      return disbursement?.coach?.coach?.blockedRefund;
    },

    toggleSelected(event) {
      if (this.selected.length && !event.target.checked) {
        this.selected = [];
      } else {
        this.selected = this.disbursements.map((s) => s.id);
      }
    },

    updateData(selected) {
      this.disbursements = this.disbursements.filter(
        (d) => !selected.includes(d.id)
      );

      this.selected = [];
      this.allChecked = false;
    },
  },
};
</script>

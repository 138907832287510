var render = function render(){var _vm=this,_c=_vm._self._c;return _c('navbar',[_c('button',{staticClass:"navbar-toggler mobile-sidebar-toggler hidden-lg-up",attrs:{"type":"button"},on:{"click":_vm.mobileSidebarToggle}},[_vm._v(" ☰ ")]),_c('a',{staticClass:"navbar-brand",style:(`background-color: ${_vm.brandColor}`),attrs:{"href":"#"}}),_c('ul',{staticClass:"nav navbar-nav hidden-md-down"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link navbar-toggler sidebar-toggler",attrs:{"href":"#"},on:{"click":_vm.sidebarToggle}},[_vm._v("☰")])])]),(
      _vm.isTeam(_vm.user) &&
      (_vm.hasPermission(_vm.$store.state.user, 'CLIENTS_READ') ||
        _vm.hasPermission(_vm.$store.state.user, 'USERS_MENU_READ') ||
        _vm.hasPermission(_vm.$store.state.user, 'PAYMENTS_READ') ||
        _vm.hasPermission(_vm.$store.state.user, 'ORDERS_READ'))
    )?_c('SearchBar'):_vm._e(),(_vm.user)?_c('ul',{staticClass:"nav navbar-nav ml-auto position-relative"},[_c('li',{staticClass:"nav-item px-1 d-none d-lg-block"},[_vm._v(_vm._s(_vm.user.coach.name))]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link nav-pill",on:{"click":_vm.logout}},[_c('i',{staticClass:"icon-logout"})])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
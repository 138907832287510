<template>
  <div class="wrapper">
    <Loader :is-visible="loading" />

    <div class="animated fade-in">
      <div v-if="group" class="card">
        <div class="card-header d-flex justify-content-between">
          <div>
            <i class="icon-people"></i>&nbsp; Groupe:
            <span class="font-weight-bold">{{ group.name }}</span>
          </div>

          <div>
            <span v-if="formSaving">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              {{ "Enregistrement en cours..." }}
            </span>

            <template v-if="group.referent">
              Référent:
              <a :href="referentHref" target="_blank">{{
                group.referent.name
              }}</a>
              <button
                type="button"
                class="btn btn-sm btn-default ml-1"
                @click="modalReferentShow = true"
                v-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
              >
                <i class="fa fa-pencil"></i>
              </button>
            </template>

            <button
              v-else-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
              type="button"
              class="btn btn-sm btn-primary"
              @click="modalReferentShow = true"
            >
              <i class="fa fa-plus"></i>&nbsp; Ajouter un référent
            </button>
          </div>
        </div>

        <div class="row card-block">
          <div class="col-md-3">
            <div class="form-group">
              <label for="note" class="control-label font-weight-bold"
                >Description</label
              >
              <textarea
                id="note"
                name="note"
                class="form-control"
                v-model="group.note"
                @change="updateGroup"
              ></textarea>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="domains" class="control-label font-weight-bold"
                >Nom de domaines associés (séparé d'une virgule ",")</label
              >
              <input
                id="domains"
                name="domains"
                type="text"
                class="form-control"
                v-model="group.domains"
                @change="updateGroup"
                placeholder="justcoaching.fr, justcorporate.fr"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="font-weight-bold"
                >Restreindre par domaine
                <i
                  class="fa fa-info-circle ml-05"
                  v-tooltip="{
                    content:
                      'Seul les utilisateurs avec un email contenant un domaine valide pourront avoir accès au groupe',
                  }"
                ></i
              ></label>
              <el-switch
                style="display: block"
                v-model="group.restrictDomains"
                active-text="Oui"
                inactive-text="Non"
                size="mini"
                @change="updateGroup"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-bold"
                >Accès automatique par domaine
                <i
                  class="fa fa-info-circle ml-05"
                  v-tooltip="{
                    content:
                      'Les utilisateurs avec un email contenant un domaine valide auront accès au groupe',
                  }"
                ></i
              ></label>
              <el-switch
                style="display: block"
                v-model="group.automaticAccess"
                active-text="Oui"
                inactive-text="Non"
                size="mini"
                @change="updateGroup"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-bold"
                >Accès live par défaut
                <i
                  class="fa fa-info-circle ml-05"
                  v-tooltip="{
                    content:
                      'Les membres du groupe auront accès au live par défaut (sans information bancaire)',
                  }"
                ></i
              ></label>
              <el-switch
                style="display: block"
                v-model="group.hasLiveAccess"
                active-text="Oui"
                inactive-text="Non"
                size="mini"
                @change="updateGroup"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-bold"
                >Accès live cours public
                <i
                  class="fa fa-info-circle ml-05"
                  v-tooltip="{
                    content:
                      'Les membres du groupe auront accès au planning payant public (sans information bancaire)',
                  }"
                ></i
              ></label>
              <el-switch
                style="display: block"
                v-model="group.hasLivePublic"
                active-text="Oui"
                inactive-text="Non"
                size="mini"
                @change="updateGroup"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Couleur</label>
              <div>
                <el-color-picker
                  class="picker"
                  v-model="group.color"
                  @change="updateGroup"
                ></el-color-picker>
              </div>
            </div>
          </div>

          <div v-if="getPictureImage(group.id)" class="col-md-2">
            <img class="img-responsive" :src="getPictureImage(group.id)" />

            <div class="from-control">
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="photo"
                v-on:vdropzone-success="logoUploaded"
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'image
                    ici</span
                  >
                </div>
              </dropzone>
            </div>
          </div>
        </div>

        <div class="card-block">
          <tabs>
            <tab :header="clientListHeader">
              <ClientsGroupClientList
                :clients="clients"
                :referent="group.referent"
                @remove="removeClient"
                @edit-referent="editReferent"
                @remove-referent="removeReferent"
              />
            </tab>
            <tab header="Fonctionnalités">
              <ClientsGroupFeatures :group="group" @updated="updateFeatures" />
            </tab>
            <tab header="Suivi pointage">
              <ClientsGroupTracking :group="group" />
            </tab>
            <tab header="Suivi non validées">
              <ClientsGroupTracking :group="group" type="uncompleted" />
            </tab>
          </tabs>
        </div>
      </div>
    </div>

    <GroupReferentModal
      v-if="group"
      :show="modalReferentShow"
      :group="group"
      :clients="clients"
      @close="modalReferentShow = false"
      @edited="referentEdited"
    />
  </div>
</template>

<script>
import axios from "axios";
import Dropzone from "vue2-dropzone";

import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Loader from "../components/Loader";
import ClientsGroupClientList from "../components/ClientsGroupClientList";
import ClientsGroupFeatures from "../components/ClientsGroupFeatures";
import GroupReferentModal from "../components/GroupReferentModal";
import ClientsGroupTracking from "../components/ClientsGroupTracking";

export default {
  components: {
    Tabs,
    Tab,
    Loader,
    ClientsGroupClientList,
    ClientsGroupFeatures,
    GroupReferentModal,
    ClientsGroupTracking,
    Dropzone,
  },

  data() {
    return {
      loading: false,
      formSaving: false,
      group: null,
      modalReferentShow: false,
      modalGroupShow: false,
    };
  },

  computed: {
    clientListHeader() {
      return `Liste des participants ${
        this.group?.clients ? `(${this.group.clients.length})` : ""
      }`;
    },

    clients() {
      if (this.group?.clients) {
        return this.group.clients.filter((client) => !!client.client);
      }

      return [];
    },

    referentHref() {
      if (this.group?.referent) {
        const route = this.$router.resolve({
          name: "client-details",
          params: {
            id: this.group.referent.id,
          },
        });

        return route.href;
      }

      return "";
    },
  },

  async created() {
    const { id } = this.$route.params;

    try {
      this.loading = true;

      const { data } = await this.$api.get(`/groups/${id}`);

      this.group = data;
    } catch (e) {
      this.$errorToast("Impossible de charger le groupe de clients");
    } finally {
      this.loading = false;
    }
  },

  methods: {
    updateFeatures(features) {
      this.group = { ...this.group, features };

      this.updateGroup();
    },

    async updateGroup() {
      const {
        note,
        domains,
        restrictDomains,
        color,
        logoId,
        automaticAccess,
        hasLiveAccess,
        hasLivePublic,
      } = this.group;

      try {
        this.formSaving = true;

        await this.$api.put(`/groups/${this.group.id}`, {
          note,
          domains,
          restrictDomains,
          color,
          logoId,
          automaticAccess,
          hasLiveAccess,
          hasLivePublic,
        });

        this.formSaving = false;
      } catch (e) {
        console.warn(e);
      }
    },

    referentEdited(referentEmail) {
      this.group = {
        ...this.group,
        referentEmail,
        referent: this.clients.find(
          ({ client }) => client.email === referentEmail
        ).client,
      };
      this.updateGroup();

      this.modalReferentShow = false;
    },

    removeClient(clientEmail) {
      if (this.group) {
        this.$confirm({
          message: "Retirer le client du groupe ?",
          onConfirm: async () => {
            try {
              await this.$api.post(`/groups/${this.group.id}/remove-client`, {
                clientEmail,
              });

              this.group = {
                ...this.group,
                clients: this.group.clients.filter(
                  (clientGroup) => clientGroup.clientEmail !== clientEmail
                ),
              };

              if (this.group.referentEmail === clientEmail) {
                this.group = {
                  ...this.group,
                  referentEmail: null,
                  referent: null,
                };
              }

              this.updateGroup();
            } catch (e) {
              console.warn(e);
            }
          },
        });
      }
    },

    editReferent(referent) {
      if (this.group) {
        this.$confirm({
          message: "Modifier le client référent ?",
          onConfirm: async () => {
            try {
              await this.$api.put(`/groups/${this.group.id}/referent`, {
                referent,
              });

              this.referentEdited(referent);
            } catch (e) {
              console.warn(e);
            }
          },
        });
      }
    },

    removeReferent() {
      if (this.group) {
        this.$confirm({
          message: "Supprimer le référent ?",
          onConfirm: async () => {
            try {
              await this.$api.delete(`/groups/${this.group.id}/referent`);

              this.group = {
                ...this.group,
                referentEmail: null,
                referent: null,
              };

              this.updateGroup();
            } catch (e) {
              console.warn(e);
            }
          },
        });
      }
    },

    getPictureImage(id) {
      return `${process.env.VUE_APP_API_URL}groups/${id}/logo`;
    },

    logoUploaded(f, response) {
      this.group.logoId = response.id;

      this.updateGroup();
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },
  },
};
</script>

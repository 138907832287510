<template>
  <div class="card">
    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">Informations admin</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="value"
            type="textarea"
            autosize
            @change="() => (isEditing = true)"
            v-if="checkPermission"
          />
          <span v-else>{{ value }}</span>
        </dd>
        <dt class="col-sm-12">
          <button
            class="btn btn-sm btn-primary float-right"
            :disabled="!isEditing"
            @click="editClient"
          >
            Enregistrer la note
          </button>
        </dt>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      value: "",
      isEditing: false,
    };
  },

  created() {
    this.value = this.client.managerNotes;
  },

  computed: {
    checkPermission() {
      return (
        this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") &&
        !this.client.deletedAt
      );
    },
  },

  methods: {
    editClient() {
      this.isEditing = false;

      this.$emit("edit", { name: "managerNotes", value: this.value });
    },
  },
};
</script>

<template>
  <div>
    <div :class="`custom-card custom-card-${this.type}`">
      <div class="left-icon-btn" @click="displaySubTab = !displaySubTab">
        <i class="fa" :class="displaySubTab ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div class="card-data">
        <div class="card-column">
          <span>{{ nbAttributions }}</span>
          <span>Attributions</span>
        </div>
        <div class="card-column">
          <span>{{ nbPackPostDiscovery }}</span>
          <span>Packs</span>
        </div>
        <div class="card-column">
          <span>{{ conversionRate }}%</span>
          <span>Conversion</span>
        </div>
        <div class="card-column">
          <span>{{ clientPackAverage }}%</span>
          <span>Séances/packs moyen</span>
        </div>
        <div class="card-column">
          <span>{{ lostPercent }}</span>
          <span>Perte</span>
        </div>
        <div class="card-column">
          <span>{{ coachs.length }}</span>
          <span>Coachs</span>
        </div>
      </div>
    </div>
    <div v-if="displaySubTab" :class="`subtab subtab-${this.type}`">
      <el-table :data="coachsSorted">
        <el-table-column label="Nom" prop="name" sortable fixed width="130">
          <template slot-scope="scope">
            <span class="user-link" @click="(e) => goToCoach(e, scope.row.id)">
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="Département"
          prop="name"
          width="140"
          sortable
          fixed
        >
          <template slot-scope="scope">
            {{ scope.row.department.substring(0, 2) }}
          </template>
        </el-table-column>
        <el-table-column
          label="Attributions"
          prop="nbDiscoveryAttributed"
          width="130"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.nbDiscoveryAttributed }} attribution{{
              scope.row.nbDiscoveryAttributed > 1 ? "s" : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="Découvertes faites"
          prop="nbDiscoveryDone"
          sortable
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.nbDiscoveryDone }} Découverte{{
              scope.row.nbDiscoveryDone > 1 ? "s" : ""
            }}
            faite{{ scope.row.nbDiscoveryDone > 1 ? "s" : "" }}
          </template>
        </el-table-column>
        <el-table-column
          label="Packs post découverte"
          prop="nbPackPostDiscovery"
          sortable
          width="225"
        >
          <template slot-scope="scope">
            {{ scope.row.nbPackPostDiscovery }} Pack{{
              scope.row.nbPackPostDiscovery > 1 ? "s" : ""
            }}
            post découverte
          </template>
        </el-table-column>
        <el-table-column
          label="Taux de conversion"
          prop="conversionRate"
          sortable
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.conversionRate }}%
          </template>
        </el-table-column>
        <el-table-column
          label="Séances/packs moyen"
          prop="clientPackAverage"
          sortable
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.clientPackAverage }} séances/packs
          </template>
        </el-table-column>
        <el-table-column
          label="Découvertes perdues"
          prop="lostDiscovery"
          sortable
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.lostDiscovery }} découverte{{
              scope.row.lostDiscovery > 1 ? "s" : ""
            }}
            perdue{{ scope.row.lostDiscovery > 1 ? "s" : "" }}
          </template>
        </el-table-column>
        <el-table-column
          label="Taux découvertes perdues"
          prop="lostPercent"
          sortable
          width="250"
        >
          <template slot-scope="scope"> {{ scope.row.lostPercent }}% </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coachs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      displaySubTab: false,
    };
  },

  computed: {
    coachsSorted() {
      const sorted = this.coachs;

      return sorted.sort((a, b) => {
        return b.conversionRate - a.conversionRate;
      });
    },
    nbAttributions() {
      if (this.coachs.length === 0) return 0;
      return this.coachs.reduce((acc, coach) => {
        return acc + coach.nbDiscoveryAttributed;
      }, 0);
    },
    nbPackPostDiscovery() {
      if (this.coachs.length === 0) return 0;
      return this.coachs.reduce((acc, coach) => {
        return acc + coach.nbPackPostDiscovery;
      }, 0);
    },
    conversionRate() {
      if (this.coachs.length === 0) return 0;
      return (
        this.coachs.reduce((acc, coach) => {
          return acc + parseFloat(coach.conversionRate);
        }, 0) / this.coachs.length
      ).toFixed(2);
    },
    clientPackAverage() {
      if (this.coachs.length === 0) return 0;
      return (
        this.coachs.reduce((acc, coach) => {
          return acc + parseFloat(coach.clientPackAverage);
        }, 0) / this.coachs.length
      ).toFixed(2);
    },
    lostPercent() {
      if (this.coachs.length === 0) return 0;
      return (
        this.coachs.reduce((acc, coach) => {
          return acc + parseFloat(coach.lostPercent);
        }, 0) / this.coachs.length
      ).toFixed(2);
    },
  },

  methods: {
    goToCoach(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>

<style scoped>
.custom-card {
  position: relative;
  display: flex;
  border-radius: 15px;
  align-items: center;
  color: white;
  z-index: 5;
}
.card-data {
  position: relative;
  display: flex;
  border-radius: 15px;
  justify-content: space-around;
  align-items: center;
  color: white;
  z-index: 5;
  padding: 0 20px;
  flex-wrap: wrap;
  flex-grow: 1;
}
.custom-card-success {
  background-color: #28a745;
}

.custom-card-warning {
  background-color: #ffc107;
}

.custom-card-danger {
  background-color: #dc3545;
}

.left-icon-btn {
  border-right: thick solid white;
  padding: 30px 20px;
  cursor: pointer;
}

.subtab {
  position: relative;
  margin-top: -20px;
  padding: 15px;
  padding-top: 35px;
  border-radius: 10px;
  max-height: 400px;
  overflow: auto;
  z-index: 1;
}

.subtab-success {
  border-right: 1px solid #28a745;
  border-left: 1px solid #28a745;
  border-bottom: 1px solid #28a745;
}

.subtab-warning {
  border-right: 1px solid #ffc107;
  border-left: 1px solid #ffc107;
  border-bottom: 1px solid #ffc107;
}

.subtab-danger {
  border-right: 1px solid #dc3545;
  border-left: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;
}

.card-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
}
</style>

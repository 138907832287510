<template>
  <div class="card" style="z-index: 1">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-2">
          <label>Date de début</label>
          <month-picker-input
            lang="fr"
            :default-month="defaultBeginMonth"
            :default-year="defaultBeginYear"
            @change="changeBeginDate"
          />
        </div>
        <div class="form-group col-sm-2">
          <label>Date de fin</label>
          <month-picker-input
            lang="fr"
            :default-month="defaultEndMonth"
            :default-year="defaultEndYear"
            @change="changeEndDate"
          />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="$emit('load')"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import { MonthPickerInput } from "vue-month-picker";
import moment from "moment";

export default {
  components: {
    MonthPickerInput,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: {
        begin: "",
        end: "",
      },
      defaultBeginMonth: 0,
      defaultBeginYear: 0,
      defaultEndMonth: 0,
      defaultEndYear: 0,
    };
  },

  async created() {
    this.filters = this.default;
    this.defaultBeginMonth = parseInt(this.filters.begin.format("MM"));
    this.defaultBeginYear = parseInt(this.filters.begin.format("YYYY"));
    this.defaultEndMonth = parseInt(this.filters.end.format("MM"));
    this.defaultEndYear = parseInt(this.filters.end.format("YYYY"));
  },

  methods: {
    apply() {
      this.$emit("update", this.filters);
    },

    changeBeginDate(date) {
      const { year, monthIndex } = date;

      this.filters.begin = moment()
        .set("year", year)
        .set("month", monthIndex - 1)
        .format("YYYY-MM-DD");
    },

    changeEndDate(date) {
      const { year, monthIndex } = date;

      this.filters.end = moment()
        .set("year", year)
        .set("month", monthIndex - 1)
        .format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss"></style>

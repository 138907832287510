<template>
  <modal
    :title="form.id ? 'Éditer' : 'Ajouter'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="form.id ? 'Éditer' : 'Ajouter'"
    @cancel="() => closeModal()"
    @ok="form.id ? handleEditExpense() : handleAddExpense()"
    style="padding-top: 100px"
    effect="fade/zoom"
  >
    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">{{ form.id ? "Éditer" : "Ajouter" }}</h4>
    </div>
    <div class="form-group">
      <label>Date de la dépense</label>
      <datepicker
        v-model="form.date"
        format="dd.MM.yyyy"
        language="fr"
        input-class="form-control"
        :class="formError && !form.date ? 'has-error' : ''"
      />
    </div>

    <div class="form-group">
      <el-checkbox v-model="newType">Nouveau type de dépense ?</el-checkbox>
    </div>

    <div class="form-group">
      <label>Type de dépense</label>
      <select
        v-model="form.type"
        v-if="!newType"
        class="form-control"
        :class="formError && !form.type ? 'has-error' : ''"
      >
        <option
          :key="expenseType.id"
          v-for="expenseType of types"
          :value="expenseType.type"
        >
          {{ expenseType.type }}
        </option>
      </select>
      <input
        type="text"
        v-else
        class="form-control"
        v-model="form.type"
        :class="formError && !form.type ? 'has-error' : ''"
      />
    </div>

    <div class="form-group">
      <label class="control-label">Société</label>
      <v-select
        :class="formError && !form.company ? 'has-error' : ''"
        v-model="form.company"
        :options="companys"
        @input="updateConcepts"
      ></v-select>
    </div>
    <div class="form-group">
      <label>Concept</label>
      <v-select v-model="form.concept" :options="concepts">
        <span slot="no-options">Sélectionnez une option pour Société.</span>
      </v-select>
    </div>

    <div class="form-group">
      <el-checkbox v-model="newName">Nouveau nom de dépense ?</el-checkbox>
    </div>

    <div class="form-group">
      <label>Nom de dépense</label>
      <select
        v-model="form.name"
        v-if="!newName"
        class="form-control"
        :class="formError && !form.name ? 'has-error' : ''"
      >
        <option :key="name.id" v-for="name of names" :value="name.name">
          {{ name.name }}
        </option>
      </select>
      <input
        type="text"
        v-else
        class="form-control"
        v-model="form.name"
        :class="formError && !form.name ? 'has-error' : ''"
      />
    </div>

    <div class="form-group">
      <label>Montant</label>
      <input
        type="text"
        class="form-control"
        v-model="form.amount"
        :class="formError && !form.amount ? 'has-error' : ''"
      />
    </div>

    <div class="card">
      <div class="card-header"><i class="icon-graph"></i>Utm tracké</div>
      <div class="card-block">
        <div class="form-group">
          <label>Source</label>
          <input type="text" class="form-control" v-model="form.source" />
        </div>

        <div class="form-group">
          <label>Campaign</label>
          <input type="text" class="form-control" v-model="form.campaign" />
        </div>

        <div class="form-group">
          <label>Medium</label>
          <input type="text" class="form-control" v-model="form.medium" />
        </div>

        <div class="form-group">
          <label>Term</label>
          <input type="text" class="form-control" v-model="form.term" />
        </div>

        <div class="form-group">
          <label>Content</label>
          <input type="text" class="form-control" v-model="form.content" />
        </div>

        <div class="form-group">
          <label>Referer</label>
          <input type="text" class="form-control" v-model="form.referer" />
        </div>

        <div class="form-group">
          <label>Source url</label>
          <input type="text" class="form-control" v-model="form.sourceUrl" />
        </div>
      </div>
    </div>
    <div v-if="formError" class="alert alert-danger">{{ formError }}</div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Modal,
    Datepicker,
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    expense: {
      type: Object,
      default: undefined,
    },
    types: {
      type: Array,
      required: true,
    },
    companys: {
      type: Array,
      required: true,
    },
    names: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    form: {
      date: null,
      type: null,
      company: null,
      concept: null,
      name: null,
      amount: null,
    },
    concepts: [],
    newType: false,
    newName: false,
    formError: false,
    modalOpened: false,
  }),

  mounted() {
    this.form = { ...this.expense };

    this.modalOpened = this.modalShow;
  },

  methods: {
    async handleAddExpense() {
      if (this.loading) {
        return false;
      }

      if (
        !this.form.date ||
        !this.form.type ||
        !this.form.company ||
        !this.form.name ||
        !this.form.amount
      ) {
        this.formError = `Tous les champs sont obligatoires`;

        return false;
      }

      this.loading = true;

      try {
        await this.$api.post("/expenses", {
          ...this.form,
          company: this.form.company ? this.form.company.label : null,
          concept: this.form.concept ? this.form.concept.label : null,
        });
      } catch (e) {
        this.formError = `Une erreur s'est produite`;
      }

      this.closeModal();

      this.loading = false;
    },

    async handleEditExpense() {
      this.loading = true;

      if (
        !this.form.date ||
        !this.form.type ||
        !this.form.company ||
        !this.form.name ||
        !this.form.amount
      ) {
        this.formError = `Tous les champs sont obligatoires`;

        return false;
      }

      await this.$api.patch(`/expenses/${this.form.id}`, {
        ...this.form,
        company: this.form.company ? this.form.company.label : null,
        concept: this.form.concept ? this.form.concept.label : null,
      });

      this.closeModal();

      this.loading = false;
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;
      this.form = {};

      this.$emit("close");
    },

    async updateConcepts(company) {
      this.concepts = [];

      if (company === null) {
        this.form.concept = null;
      }

      if (company && company.value) {
        const params = { merchantId: company.value };
        const { data } = await this.$api.get("/concepts", { params });

        this.concepts = data.map((concept) => {
          return {
            value: concept.id,
            label: concept.name,
          };
        });
      }
    },
  },

  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;

        if (newVal) {
          this.form = this.expense;
          if (this.form.company) {
            this.updateConcepts(this.form.company);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.has-error {
  border: 1px solid #ff5454 !important;
  border-radius: 4px;
}
</style>

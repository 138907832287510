<template>
  <div>
    <loader :isVisible="calendarIsLoading" />
    <SessionPopup
      id="popup"
      :class="showPopup || fixPopup ? 'visible' : 'hidden'"
      :displaySession="displaySession"
    />
    <FullCalendar
      ref="calendar"
      class="flex-1 calendar"
      default-view="dayGridMonth"
      :options="config"
      v-click-outside="clickOutside"
    />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import frLocale from "@fullcalendar/core/locales/fr";
import Loader from "../components/Loader";
import SessionPopup from "../components/SessionPopup";
import { createPopper } from "@popperjs/core";

export default {
  components: {
    FullCalendar,
    Loader,
    SessionPopup,
  },
  data() {
    return {
      config: {
        plugins: [dayGridPlugin, listPlugin],
        initialView: "dayGridMonth",
        locale: frLocale,
        events: this.fetchEvents,
        displayEventEnd: true,
        eventMouseEnter: this.eventMouseEnter,
        eventMouseLeave: this.eventMouseLeave,
        eventClick: this.eventMouseClick,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridDay,dayGridWeek,dayGridMonth,listMonth",
        },
      },
      calendarIsLoading: false,
      displaySession: undefined,
      popup: null,
      showPopup: false,
      fixPopup: false,
      popupEl: null,
    };
  },
  methods: {
    async fetchEvents({ start, end }, successCallback) {
      const qStart = new Date(start).toISOString();
      const qEnd = new Date(end).toISOString();

      this.calendarIsLoading = true;

      const { data } = await this.$api.get(
        `/live-app/sessions?start=${qStart}&end=${qEnd}`
      );

      this.calendarIsLoading = false;

      const calendarEvents = data.map((s) => {
        const start = new Date(s.scheduledAt);
        const end = new Date(s.endedAt);
        return {
          backgroundColor: s.live.colorCalendar,
          start,
          end,
          display: "block",
          title: `${s.live.name} [${
            s.live.type === "small" ? `Visio` : "Live"
          }]`,
          allDay: false,
          _data: s,
        };
      });

      successCallback(calendarEvents);
    },

    eventMouseClick() {
      this.fixPopup = true;
    },
    createPopup(el) {
      this.popupEl = document.getElementById("popup");
      this.popupEl.style.display = "block";
      if (!this.popupEl) {
        return;
      }
      this.popup = createPopper(el, this.popupEl, {
        placement: "top-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 0],
            },
          },
        ],
      });
      this.showPopup = true;
      this.popupEl.style.display = "inline-block";
    },

    destroyPopup() {
      if (this.popup && !this.showPopup && !this.fixPopup) {
        this.popup.destroy();
        this.popup = null;
        this.popupEl.style.display = "none";
      }
    },

    clickOutside() {
      this.showPopup = false;
      this.fixPopup = false;
      this.destroyPopup();
    },

    eventMouseEnter(e) {
      if (this.popup) {
        this.popup.destroy();
        this.popup = null;
      }

      this.displaySession = e.event.extendedProps._data;

      this.$nextTick(() => {
        this.createPopup(e.el);
      });
    },

    eventMouseLeave() {
      this.showPopup = false;
      this.destroyPopup();
    },
  },
};
</script>

<style scoped>
.visible {
  display: block;
}

.hidden {
  display: none;
}
</style>

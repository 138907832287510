<template>
  <div class="animated fadeIn">
    <el-popover trigger="click" placement="top" :width="700">
      <div
        class="row mt-1 align-items-center"
        v-if="getRetain !== 0 || getNotRetain !== 0"
      >
        <div class="col-md-12">
          <h4>{{ name }}</h4>
          <hr />
        </div>
        <div class="col-md-2 text-center">
          <strong v-if="row.result">{{ row.result }}%</strong>
          <Doughnut :options="options" :chart-data="chartData" />
        </div>
        <div class="col-md-3 d-flex flex-column">
          <div>
            <span class="pills blue">{{ getRetain }} Client(s)</span>
            <p>Fidélisés</p>
          </div>
          <div>
            <span class="pills green">{{ getNotRetain }} Client(s)</span>
            <p>Perdu</p>
          </div>
        </div>
        <div class="col-md-7 text-center">
          <div v-if="row.result" class="row align-items-center">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12">
                  100 *
                  <span class="pills blue">{{ getRetain }} Fidélisé</span>
                </div>
                <hr class="divider" />
                <div class="col-md-12">
                  (<span class="pills blue">{{ getRetain }} Fidélisé</span>
                  +
                  <span class="pills green">{{ getNotRetain }} perdu</span>)
                </div>
              </div>
            </div>
            <div class="col-md-4 text-left">
              <span>= {{ row.result }}%</span>
            </div>
          </div>
          <h6 v-else>Aucun</h6>
        </div>
        <hr class="divider" />
      </div>
      <div
        class="row mt-2 text-center mx-auto"
        style="max-height: 200px; overflow: auto"
      >
        <div class="col-md-6">
          <h5>Fidélisé ({{ getRetain }})</h5>
          <ul class="list-unstyled mt-2">
            <li v-for="retain in getRetainOrder" :key="retain.id" class="mt-1">
              <a
                @click="(e) => goToClient(e, retain.id)"
                class="user-pills-link blue"
                href="#"
              >
                <i class="icon-user"></i> {{ retain.fullname }}
              </a>
              <a
                @click="(e) => goToOrder(e, retain.orderId)"
                class="user-pills-link blue"
                href="#"
              >
                {{ formatDate(retain.orderDate) }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <h5>Non fidélisé ({{ getNotRetain }})</h5>
          <ul class="list-unstyled mt-2">
            <li
              v-for="notRetain in getNotRetainOrder"
              :key="notRetain.id"
              class="mt-1"
            >
              <a
                @click="(e) => goToClient(e, notRetain.id)"
                class="user-pills-link green"
                href="#"
              >
                <i class="icon-user"></i> {{ notRetain.fullname }}
              </a>
              <a
                @click="(e) => goToOrder(e, notRetain.orderId)"
                class="user-pills-link green"
                href="#"
              >
                {{ formatDate(notRetain.orderDate) }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div slot="reference" class="name-wrapper">
        <div v-if="row.result">
          <span> #{{ row.rank }} - </span>
          {{ row.result }}%
        </div>
        <span v-else>Aucun</span>
      </div>
    </el-popover>
  </div>
</template>

<script>
import Doughnut from "./Doughnut.vue";
export default {
  components: {
    Doughnut,
  },

  data() {
    return {
      options: {
        responsive: true,
        animation: {
          animateRotate: false,
        },
        legend: {
          display: false,
        },
        cutoutPercentage: 75,
      },
    };
  },

  props: {
    row: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  computed: {
    getRetain() {
      return this.row.fidelised;
    },

    getRetainOrder() {
      return this.row.details.filter((o) => o.code === "fidelised");
    },

    getNotRetain() {
      return this.row.lost;
    },

    getNotRetainOrder() {
      return this.row.details.filter((o) => o.code === "lost");
    },

    chartData() {
      return {
        labels: ["Fidélisé", "Perdu"],
        datasets: [
          {
            backgroundColor: ["#255dc8", "#18bdb4"],
            data: [this.getRetain, this.getNotRetain],
          },
        ],
      };
    },
  },

  methods: {
    goToClient(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },

    goToOrder(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.pills {
  border-radius: 100px;
  background-color: #f5f5f5;
  padding: 3px 20px;
  word-break: break-word;
  display: inline-block;
}

.blue {
  background-color: #255dc8;
  color: white;
}

.green {
  background-color: #18bdb4;
  color: white;
}

.orange {
  background-color: #f97516;
  color: white;
}

.divider {
  width: 100%;
  border-top: 1px solid #131721;
}

.user-pills-link {
  padding: 5px 15px;
  border-radius: 100px;
  text-decoration: none;
  word-break: break-word;
}

.user-pills-link:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
</style>

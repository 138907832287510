<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-target"></i> Attribution
      <label
        class="switch switch-sm switch-text switch-info float-right mb-0"
        v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')"
      >
        <input
          type="checkbox"
          class="switch-input"
          v-model="client.attribution"
          @click="handleAttribution"
        />
        <span class="switch-label" data-on="On" data-off="Off"></span>
        <span class="switch-handle"></span>
      </label>
      <span v-else class="float-right">{{
        client.attribution ? "Activé" : "Désactivé"
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleAttribution(event) {
      this.$api.put("/clients/attribution", {
        email: this.client.email,
        attribution: event.target.checked,
      });
    },
  },
};
</script>

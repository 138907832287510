<template>
  <div>
    <concepts
      :concepts="concepts"
      class="chart-wrapper"
      style="height:300px; margin-top:40px;"
      :height="300"
    />
    <div class="row mt-1 text-center">
      <div class="col-sm-12">
        <div
          v-for="(concept, index) in concepts"
          :key="index"
          class="mx-1 mt-1 text-white d-inline-block"
          :style="
            `border-radius: 30px; padding:7px 20px; background-color: ${concept.color}`
          "
        >
          <span>
            {{ index }}
          </span>
          <br />
          <span
            >{{ getConceptNbOrder(index) }} commandes ({{
              getConceptPrice(index)
            }}
            € / {{ getConceptPercent(index) }}%)</span
          >
        </div>
      </div>
      <div class="col-sm-12 mt-1">
        <strong>{{ conceptsOrders }} commandes ({{ conceptsAmount }}€)</strong>
      </div>
    </div>
  </div>
</template>

<script>
import Concepts from "./Concepts";

export default {
  components: {
    Concepts
  },
  props: {
    concepts: {
      type: Object,
      required: true
    }
  },

  computed: {
    conceptsOrders() {
      if (this.concepts) {
        let orders = 0;

        for (let concept in this.concepts) {
          for (let period in this.concepts[concept].dataset) {
            orders += this.concepts[concept].dataset[period].nb;
          }
        }

        return orders;
      }

      return 0;
    },
    conceptsAmount() {
      if (this.concepts) {
        let amount = 0;

        for (let concept in this.concepts) {
          for (let period in this.concepts[concept].dataset) {
            amount += this.concepts[concept].dataset[period].price;
          }
        }

        return (amount / 100).toFixed(2);
      }

      return 0;
    }
  },
  methods: {
    getConceptPrice(concept) {
      if (this.concepts && this.concepts[concept]) {
        let amount = 0;

        for (let period in this.concepts[concept].dataset) {
          amount += this.concepts[concept].dataset[period].price;
        }

        return (amount / 100).toFixed(2);
      }

      return 0;
    },
    getConceptPercent(concept) {
      const totalConcept = this.getConceptPrice(concept);
      const total = this.conceptsAmount;

      if (totalConcept == 0 || total == 0) {
        return 0;
      }

      return ((100 * totalConcept) / total).toFixed(2);
    },
    getConceptNbOrder(concept) {
      if (this.concepts && this.concepts[concept]) {
        let orders = 0;

        for (let period in this.concepts[concept].dataset) {
          orders += this.concepts[concept].dataset[period].nb;
        }

        return orders;
      }

      return 0;
    }
  }
};
</script>

<template>
  <div class="animated fadeIn">
    <StatsLiveFilters
      :default="filters"
      :show-type-filter="showTypeFilter"
      :no-solo="noSoloFilter"
      @apply="applyFilters"
    />

    <Loader :is-visible="loading" />

    <div class="card">
      <div class="card-block">
        <tabs v-model="activeTab" @input="changeTab">
          <tab v-for="(tab, index) in tabs" :key="index" :header="tab.header">
            <component
              :is="tab.component"
              :data="tab.data"
              :loading="loading"
              :geo-stats-category="geoStatsCategory"
              @changeGeoStatsCategory="changeGeoStatsCategory"
            ></component>
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Loader from "../components/Loader";
import StatsLiveFilters from "../components/StatsLiveFilters";
import StatsLiveGlobal from "../components/StatsLiveGlobal";
import StatsLiveFormulas from "../components/StatsLiveFormulas";
import StatsLiveParticipants from "../components/StatsLiveParticipants";
import StatsLivePromo from "../components/StatsLivePromo";
import StatsLiveGeo from "../components/StatsLiveGeo";
import StatsLiveSessions from "../components/StatsLiveSessions";
import StatsLiveCoachs from "../components/StatsLiveCoachs";

export default {
  components: {
    Tabs,
    Tab,
    Loader,
    StatsLiveFilters,
  },

  data() {
    return {
      loading: false,
      activeTab: 0,
      tabs: [
        {
          header: "Données comptables",
          component: StatsLiveGlobal,
          load: this.loadGlobalData,
          data: null,
        },
        {
          header: "Formules",
          component: StatsLiveFormulas,
          load: this.loadFormulasData,
          data: null,
        },
        {
          header: "Participants",
          component: StatsLiveParticipants,
          load: this.loadParticipantsData,
          data: null,
        },
        {
          header: "Promo",
          component: StatsLivePromo,
          load: this.loadPromoData,
          data: null,
        },
        {
          header: "Données géographiques",
          component: StatsLiveGeo,
          load: this.loadGeoData,
          data: null,
        },
        {
          header: "Cours",
          component: StatsLiveSessions,
          load: this.loadSessionsData,
          data: null,
        },
        {
          header: "Coachs",
          component: StatsLiveCoachs,
          load: this.loadCoachsData,
          data: null,
        },
      ],
      filters: {
        start: moment().startOf("month").toDate(),
        end: moment().toDate(),
        type: [
          {
            label: "Live",
            value: "live",
          },
          {
            label: "Visio & Live",
            value: "small",
          },
        ],
      },
      geoStatsCategory: "subscribers",
    };
  },

  computed: {
    showTypeFilter() {
      const noTypeFilterTabs = [1, 3];

      return !noTypeFilterTabs.includes(this.activeTab);
    },

    noSoloFilter() {
      const noSoloFilterTabs = [5, 6];

      return noSoloFilterTabs.includes(this.activeTab);
    },
  },

  created() {
    this.tabs[this.activeTab].load();
  },

  methods: {
    changeTab() {
      if (!this.tabs[this.activeTab].data) {
        this.tabs[this.activeTab].load();
      }
    },

    async loadGlobalData() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/live-stats/global", {
          params: {
            start: this.filters.start,
            end: this.filters.end,
            type: this.filters.type?.map(({ value }) => value),
          },
        });

        this.tabs[0].data = data;
      } catch (e) {
        this.tabs[0].data = null;
      } finally {
        this.loading = false;
      }
    },

    async loadFormulasData() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/live-stats/formulas", {
          params: {
            start: this.filters.start,
            end: this.filters.end,
          },
        });

        this.tabs[1].data = data;
      } catch (e) {
        this.tabs[1].data = null;
      } finally {
        this.loading = false;
      }
    },

    async loadParticipantsData() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/live-stats/participants", {
          params: {
            start: this.filters.start,
            end: this.filters.end,
            type: this.filters.type?.map(({ value }) => value),
          },
        });

        this.tabs[2].data = data;
      } catch (e) {
        this.tabs[2].data = null;
      } finally {
        this.loading = false;
      }
    },

    async loadPromoData() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/live-stats/promo", {
          params: {
            start: this.filters.start,
            end: this.filters.end,
          },
        });

        this.tabs[3].data = data;
      } catch (e) {
        this.tabs[3].data = null;
      } finally {
        this.loading = false;
      }
    },

    async loadGeoData() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/live-stats/geo", {
          params: {
            start: this.filters.start,
            end: this.filters.end,
            type: this.filters.type?.map(({ value }) => value),
            statsCategory: this.geoStatsCategory,
          },
        });

        this.tabs[4].data = data;
      } catch (e) {
        this.tabs[4].data = null;
      } finally {
        this.loading = false;
      }
    },

    async loadSessionsData() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/live-stats/sessions", {
          params: {
            start: this.filters.start,
            end: this.filters.end,
            type: this.filters.type
              ?.filter(({ value }) => value !== "solo")
              .map(({ value }) => value),
          },
        });

        this.tabs[5].data = data;
      } catch (e) {
        this.tabs[5].data = null;
      } finally {
        this.loading = false;
      }
    },

    async loadCoachsData() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/live-stats/coachs", {
          params: {
            start: this.filters.start,
            end: this.filters.end,
            type: this.filters.type
              ?.filter(({ value }) => value !== "solo")
              .map(({ value }) => value),
          },
        });

        this.tabs[6].data = data;
      } catch (e) {
        this.tabs[6].data = null;
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };

      this.resetTabsData();
      this.tabs[this.activeTab].load();
    },

    resetTabsData() {
      this.tabs.forEach((tab, index) => {
        if (index !== this.activeTab) {
          tab.data = null;
        }
      });
    },

    changeGeoStatsCategory(category) {
      this.geoStatsCategory = category;

      this.loadGeoData();
    },
  },
};
</script>

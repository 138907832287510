<template>
  <div class="rank-custom-wrapper">
    <RankFilters
      :default="filters"
      @update="handleFilters"
      @update-dynamic="updateDynamicFilters"
      @open-settings="openSettingModal"
    />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>
    <div class="card text-center" v-else-if="data.length === 0">
      <div class="card-block">
        <blockquote class="card-blockquote">
          Aucune données, appliquer ou modifier les filtres
        </blockquote>
      </div>
    </div>

    <div v-else>
      <Tabs v-model="activeTab">
        <Tab header="Synthèse">
          <RankResume :data="data" :filters="filters" />
        </Tab>
        <Tab header="Données individuelles">
          <RankCoachs :data="data" :filters="filters" />
        </Tab>
      </Tabs>
    </div>

    <RankModal :default="filters" @update="handleFilters" ref="modal" />
  </div>
</template>

<script>
import RankCoachs from "../components/RankCoachs";
import RankResume from "../components/RankResume";
import RankFilters from "../components/RankFilters";
import RankModal from "../components/RankModal";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import moment from "moment-timezone";

export default {
  components: {
    RankCoachs,
    RankResume,
    RankFilters,
    RankModal,
    Tabs,
    Tab,
  },
  data() {
    return {
      loading: false,
      data: [],
      activeTab: 0,
      filters: this.defaultFilters(),
    };
  },

  computed: {},

  methods: {
    handleFilters(newFilters) {
      this.$refs.modal.closeSettingModal();
      this.filters = newFilters;

      this.loadRank();
    },

    updateDynamicFilters(newFilters) {
      this.filters = newFilters;
    },

    async loadRank() {
      try {
        this.loading = true;

        const { data } = await this.$api.get(`/rank`, {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
            display: this.filters.display,
            merchants: this.filters.merchants.map((m) => m.value),
            concepts: this.filters.concepts.map((s) => s.value),
            status: this.filters.status.map((s) => s.value),
            prestations: this.filters.prestations.map((s) => s.value),
            departments: this.filters.departments.map((s) => s.value),
            languages: this.filters.languages.map((s) => s.value),
            tags: this.filters.tags.map((s) => s.value),
            genders: this.filters.genders,
          },
        });

        this.data = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    openSettingModal() {
      this.$refs.modal.openSettingModal();
    },

    defaultFilters() {
      return {
        begin: moment()
          .subtract(12, "months")
          .startOf("month")
          .startOf("d")
          .format(),
        end: moment().format(),
        status: [{ label: "Actif", value: "active" }],
        merchants: [],
        concepts: [
          { label: "justcoaching.fr", value: "justcoaching.fr" },
          { label: "Body Trainers", value: "Body Trainers" },
          { label: "luxurycoaching.fr", value: "luxurycoaching.fr" }
        ],
        display: {
          conversionRate: true,
          fidelisationRate: true,
          resubscribe: true,
          packAverage: true,
          clientSessionLifecycle: true,
          frequency: false,
          rank: true,
          validate: false,
        },
        prestations: [],
        departments: [],
        languages: [],
        tags: [],
        genders: [],
        search: "",
      };
    },
  },
};
</script>

<style lang="css">
/*[TODO] May we improved */
.rank-custom-wrapper {
  max-width: calc(100vw - 320px);
}

@media (max-width: 991px) {
  .rank-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}

.aside-menu-fixed.sidebar-hidden .rank-custom-wrapper {
  max-width: calc(100vw - 70px);
}
</style>

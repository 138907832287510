import Vue from 'vue'

export default {

  loadUser ({ commit }) {
    return Vue.api.get('/users').then(res => {
      commit('AUTH_USER', res.data)
    }).catch(err => {
      console.error(err)
      throw err
    })
  },

  loadConcepts ({ commit }) {
    return Vue.api.get('/concepts').then(res => {
      commit('CONCEPTS', res.data)
    }).catch(err => {
      console.error(err)
      throw err
    })
  },

  loadMerchants ({ commit }) {
    return Vue.api.get('/merchants').then(res => {
      commit('MERCHANTS', res.data)
    }).catch(err => {
      console.error(err)
      throw err
    })
  },

  loadInvoiceSeries({ commit }) {
    return Vue.api.get('/invoice-series').then(res => {
      commit('INVOICE_SERIES', res.data)
    }).catch(err => {
      console.error(err)
      throw err
    })
  },

  saveUserSshKeys ({ dispatch }, payload) {
    return Vue.api.put('/clients', {
      sshKeys: payload
    }).then(() => {
      return dispatch('loadUser')
    }).catch(err => {
      console.error(err)
      throw err
    })
  }

}

<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Date de début</label>
            <datepicker
              v-model="filters.begin"
              v-on:closed="loadData"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-6">
            <label>Date de fin</label>
            <datepicker
              v-model="filters.end"
              v-on:closed="loadData"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div
          class="card card-inverse card-info text-center"
          v-if="attributions.orders === null"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">Chargement ...</blockquote>
          </div>
        </div>
        <div
          class="card card-inverse card-warning text-center"
          v-else-if="attributions.orders"
          v-bind:class="{
            'card-warning': attributions.orders <= 10,
            'card-danger': attributions.orders > 10,
          }"
        >
          <div class="card-block">
            <blockquote class="card-blockquote click" @click="goToAttributions">
              <strong>{{ attributions.orders }} commandes</strong> à attribuer
            </blockquote>
          </div>
        </div>
        <div class="card card-inverse card-success text-center" v-else>
          <div class="card-block">
            <blockquote class="card-blockquote">
              Aucune commande à attribuer
            </blockquote>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div
          class="card card-inverse card-info text-center"
          v-if="attributions.sessions === null"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">Chargement ...</blockquote>
          </div>
        </div>
        <div
          class="card card-inverse card-warning text-center"
          v-else-if="attributions.sessions"
          v-bind:class="{
            'card-warning': attributions.sessions <= 10,
            'card-danger': attributions.sessions > 10,
          }"
        >
          <div class="card-block">
            <blockquote class="card-blockquote click" @click="goToAttributions">
              <strong>{{ attributions.sessions }} séances</strong> à attribuer
            </blockquote>
          </div>
        </div>
        <div class="card card-inverse card-success text-center" v-else>
          <div class="card-block">
            <blockquote class="card-blockquote">
              Aucune séance à attribuer
            </blockquote>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div
          class="card card-inverse card-info text-center"
          v-if="attributions.clients === null"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">Chargement ...</blockquote>
          </div>
        </div>
        <div
          class="card card-inverse card-warning text-center"
          v-else-if="attributions.clients"
          v-bind:class="{
            'card-warning': attributions.clients <= 10,
            'card-danger': attributions.clients > 10,
          }"
        >
          <div class="card-block">
            <blockquote class="card-blockquote click" @click="goToAttributions">
              <strong>{{ attributions.clients }} clients</strong> à attribuer
            </blockquote>
          </div>
        </div>
        <div class="card card-inverse card-success text-center" v-else>
          <div class="card-block">
            <blockquote class="card-blockquote">
              Aucun client à attribuer
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div
          class="card card-inverse card-info text-center"
          v-if="clients.coachEmpty === null"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">Chargement ...</blockquote>
          </div>
        </div>
        <div
          class="card card-inverse card-danger text-center"
          v-else-if="clients.coachEmpty"
          v-bind:class="{
            'card-warning': clients.coachEmpty <= 10,
            'card-danger': clients.coachEmpty > 10,
          }"
        >
          <div class="card-block">
            <blockquote
              class="card-blockquote click"
              @click="goToClientsCoachEmpty"
            >
              <strong>{{ clients.coachEmpty }} clients</strong> sans coach
            </blockquote>
          </div>
        </div>
        <div class="card card-inverse card-success text-center" v-else>
          <div class="card-block">
            <blockquote class="card-blockquote">
              Aucun client sans coach
            </blockquote>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div
          class="card card-inverse card-info text-center"
          v-if="orders.pending === null"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">Chargement ...</blockquote>
          </div>
        </div>
        <div
          class="card card-inverse card-danger text-center"
          v-else-if="orders.pending"
        >
          <div class="card-block">
            <blockquote
              class="card-blockquote click"
              @click="goToOrdersPending"
            >
              <strong>{{ orders.pending }} commandes</strong> non payée
            </blockquote>
          </div>
        </div>
        <div class="card card-inverse card-success text-center" v-else>
          <div class="card-block">
            <blockquote class="card-blockquote">
              Aucun commande non payée
            </blockquote>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div
          class="card card-inverse card-info text-center"
          v-if="orders.partial === null"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">Chargement ...</blockquote>
          </div>
        </div>
        <div
          class="card card-inverse card-warning text-center"
          v-else-if="orders.partial"
        >
          <div class="card-block">
            <blockquote
              class="card-blockquote click"
              @click="goToOrdersPartial"
            >
              <strong>{{ orders.partial }} commandes</strong> partiellement
              payée
            </blockquote>
          </div>
        </div>
        <div class="card card-inverse card-success text-center" v-else>
          <div class="card-block">
            <blockquote class="card-blockquote">
              Aucun commande partiellement payée
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div
          class="card card-inverse card-info text-center"
          v-if="substitutions.pending === null"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">Chargement ...</blockquote>
          </div>
        </div>
        <div
          class="card card-inverse text-center"
          v-else-if="substitutions.pending"
          v-bind:class="{
            'card-warning': substitutions.pending <= 10,
            'card-danger': substitutions.pending > 10,
          }"
        >
          <div class="card-block">
            <blockquote
              class="card-blockquote click"
              @click="goToSubstitutions"
            >
              <strong>{{ substitutions.pending }} remplacements</strong> à
              traiter
            </blockquote>
          </div>
        </div>
        <div class="card card-inverse card-success text-center" v-else>
          <div class="card-block">
            <blockquote class="card-blockquote">Aucun remplacement</blockquote>
          </div>
        </div>
      </div>

      <div class="col-sm-8">
        <div
          class="card card-inverse card-info text-center"
          v-if="coachs.documents === null"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">Chargement ...</blockquote>
          </div>
        </div>
        <div
          class="card card-inverse card-warning text-center"
          v-else-if="coachs.documents"
          v-bind:class="{
            'card-warning': coachs.documents <= 10,
            'card-danger': coachs.documents > 10,
          }"
        >
          <div class="card-block">
            <blockquote class="card-blockquote click" @click="goToDocuments">
              Il y a <strong>{{ coachs.documents }} documents</strong> de coachs
              à valider
            </blockquote>
          </div>
        </div>
        <div class="card card-inverse card-success text-center" v-else>
          <div class="card-block">
            <blockquote class="card-blockquote">
              Il n'y a pas de documents à valider pour les coachs
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="concepts">
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-12 col-md-2">
            <multiselect
              v-model="filters.period"
              :options="periodsOption"
              :multiple="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="label"
              track-by="label"
              placeholder="Choisir"
              class="custom-multiselect"
              @input="loadData"
            >
            </multiselect>
          </div>
        </div>
        <Tabs>
          <Tab header="Chiffre d'affaires" class="text-center pt-1">
            <span class="alert alert-info" role="alert">
              <strong>Informations:</strong>
              "Le chiffre d'affaires" correspond au montant de toutes les
              commandes ayant une facture. (Remboursements et avoirs ne sont pas
              inclus)
            </span>
            <Chart :concepts="caGraphs" />
          </Tab>
          <Tab header="Commandes" class="text-center pt-1">
            <span class="alert alert-info" role="alert">
              <strong>Informations:</strong>
              "Les commandes" correspondent aux montants de TOUTES les
              commandes, payée ou non. (Remboursements et avoirs ne sont pas
              inclus)
            </span>
            <Chart :concepts="concepts" />
          </Tab>
          <Tab header="Paiements" class="text-center pt-1">
            <span class="alert alert-info" role="alert">
              <strong>Informations:</strong>
              Les paiements correspondent au montant de tous les paiements.
              (Remboursements inclus)
            </span>
            <Chart :concepts="payments" />
          </Tab>
          <Tab header="Remboursement" class="text-center pt-1">
            <span class="alert alert-info" role="alert">
              <strong>Informations:</strong>
              Uniquement les remboursements
            </span>
            <Chart :concepts="refunds" />
          </Tab>
          <Tab header="Avoir" class="text-center pt-1">
            <span class="alert alert-info" role="alert">
              <strong>Informations:</strong>
              Uniquement les avoirs
            </span>
            <Chart :concepts="credits" />
          </Tab>
        </Tabs>
      </div>
    </div>
    <!--/.card-->
  </div>
</template>

<script>
import Chart from "./Dashboard/Chart";
import moment from "moment-timezone";
import Datepicker from "vuejs-datepicker";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Datepicker,
    Tabs,
    Tab,
    Chart,
    Multiselect,
  },
  data() {
    return {
      year: moment().format("YYYY"),
      month: moment().format("MMMM YYYY"),
      day: moment().format("DD MMMM YYYY"),
      attributions: {
        clients: null,
        orders: null,
        sessions: null,
      },
      clients: {
        coachEmpty: null,
      },
      orders: {
        partial: null,
        pending: null,
      },
      coachs: {
        documents: null,
      },
      substitutions: {
        pending: null,
      },
      concepts: {},
      caGraphs: {},
      payments: {},
      refunds: {},
      credits: {},
      filters: {
        begin: moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
        period: {
          label: "Jour",
          value: "day",
        },
      },
      periodsOption: [
        {
          label: "Jour",
          value: "day",
        },
        {
          label: "Semaine",
          value: "week",
        },
        {
          label: "Mois",
          value: "month",
        },
        {
          label: "Année",
          value: "year",
        },
      ],
    };
  },
  created() {
    this.loadData();
    this.loadCoachs();
    this.loadSubstitutions();
  },

  methods: {
    loadData() {
      this.loadAttributions();
      this.loadClients();
      this.loadOrders();
      this.loadConcepts();
      this.loadCaGraphs();
      this.loadPayments();
      this.loadRefunds();
      this.loadCredits();
    },

    loadAttributions() {
      this.attributions = {
        clients: null,
        orders: null,
        sessions: null,
      };

      this.$api
        .get("/clients/dashboard/attribution", {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
          },
        })
        .then((res) => {
          this.attributions.clients = res.data;
        });

      this.$api
        .get("/orders/dashboard/attribution", {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
          },
        })
        .then((res) => {
          this.attributions.orders = res.data;
        });

      this.$api
        .get("/sessions/dashboard/attribution", {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
          },
        })
        .then((res) => {
          this.attributions.sessions = res.data;
        });
    },

    async loadClients() {
      this.clients = {
        coachEmpty: null,
      };

      const { data } = await this.$api.get("/clients/dashboard/coachEmpty", {
        params: {
          begin: this.filters.begin,
          end: this.filters.end,
        },
      });
      this.clients.coachEmpty = data;
    },

    loadSubstitutions() {
      this.substitutions = {
        pending: null,
      };

      this.$api.get("/substitutions/dashboard").then((res) => {
        this.substitutions.pending = res.data;
      });
    },

    loadCoachs() {
      this.coachs = {
        documents: null,
      };

      this.$api.get("/users/coachs/documents").then((res) => {
        this.coachs.documents = 0;

        res.data.forEach((user) => {
          if (user.documents) {
            let notValidated = user.documents.filter((d) => !d.validatedAt);
            this.coachs.documents += notValidated.length;
          }
        });
      });
    },

    loadOrders() {
      this.orders = {
        pending: null,
        partial: null,
      };

      this.$api
        .get("/orders/dashboard/pending", {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
          },
        })
        .then((res) => {
          this.orders.pending = res.data;
        });
      this.$api
        .get("/orders/dashboard/partial", {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
          },
        })
        .then((res) => {
          this.orders.partial = res.data;
        });
    },

    async loadConcepts() {
      const { data } = await this.$api.get(`/stats/dashboard/graph/orders`, {
        params: {
          begin: this.filters.begin,
          end: this.filters.end,
          periodFilter: this.filters.period && this.filters.period.value,
        },
      });

      this.concepts = this.checkData(data);
    },

    async loadCaGraphs() {
      const { data } = await this.$api.get(`/stats/dashboard/graph/invoices`, {
        params: {
          begin: this.filters.begin,
          end: this.filters.end,
          periodFilter: this.filters.period && this.filters.period.value,
        },
      });

      this.caGraphs = this.checkData(data);
    },

    async loadPayments() {
      const { data } = await this.$api.get(`/stats/dashboard/graph/payments`, {
        params: {
          begin: this.filters.begin,
          end: this.filters.end,
          periodFilter: this.filters.period && this.filters.period.value,
        },
      });

      this.payments = this.checkData(data);
    },

    async loadRefunds() {
      const { data } = await this.$api.get(`/stats/dashboard/graph/refunds`, {
        params: {
          begin: this.filters.begin,
          end: this.filters.end,
          periodFilter: this.filters.period && this.filters.period.value,
        },
      });

      this.refunds = this.checkData(data);
    },

    async loadCredits() {
      const { data } = await this.$api.get(`/stats/dashboard/graph/credits`, {
        params: {
          begin: this.filters.begin,
          end: this.filters.end,
          periodFilter: this.filters.period && this.filters.period.value,
        },
      });

      this.credits = this.checkData(data);
    },

    goToAttributions() {
      this.$router.push({
        name: "attributions",
      });
    },

    goToFirstCoachSign() {
      this.$router.push({
        name: "user-details",
        params: {
          id: this.coachsSign[0].id,
        },
      });
    },
    goToClientsCoachEmpty() {
      this.$router.push({
        name: "clients",
        query: {
          coachEmpty: true,
        },
      });
    },
    goToOrdersPending() {
      this.$router.push({
        name: "orders",
        query: {
          status: "pending",
        },
      });
    },
    goToOrdersPartial() {
      this.$router.push({
        name: "orders",
        query: {
          status: "partial",
        },
      });
    },
    goToSubstitutions() {
      this.$router.push({
        name: "substitutions",
      });
    },
    goToDocuments() {
      this.$router.push({
        name: "documents",
      });
    },
    checkData(data) {
      if (Object.keys(data).length !== 0) {
        return data;
      }

      return {
        "Aucune donnée": {
          color: "#000000",
          dataset: this.getDefaultDataset(),
          noData: true,
        },
      };
    },

    getDefaultDataset() {
      const begin = moment(this.filters.begin);
      const end = moment(this.filters.end);
      const diff = end.diff(begin, "days");
      const data = [];
      let period = end.format("DD/MM/YY");

      for (let i = 0; i <= diff; i++) {
        data[period] = {
          nb: 0,
          price: 0,
        };
        period = end.subtract(1, "days").format("DD/MM/YY");
      }

      return data;
    },
  },
};
</script>

<template>
  <span :class="`badge badge-${color} mr-1`">
    {{ label }} {{ value && value.toFixed(decimals) }}{{ unit }}
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Array,
      default: () => [0, 100],
    },
    decimals: {
      type: Number,
      default: 0,
    },
  },
  data() {
    let colors = ["danger", "warning", "info", "success"];

    if (this.reversed) {
      colors = colors.reverse();
    }

    return { colors };
  },
  computed: {
    color() {
      const percent =
        (this.value - this.range[0]) / (this.range[1] - this.range[0]);
      const colorIndex = Math.round(percent * (this.colors.length - 1));

      return this.colors[colorIndex];
    },
  },
};
</script>

<style type="text/css" scopped>
.badge {
  font-size: 15px;
}
</style>

<template>
  <div class="rank-custom-wrapper">
    <RankNoteFilters :default="filters" @update="handleFilters" />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>

    <div
      class="card text-center"
      v-else-if="
        (activeTab === 0 && external.length === 0) ||
        (activeTab === 1 && internal.length === 0)
      "
    >
      <div class="card-block">
        <blockquote class="card-blockquote">
          Aucune données, appliquer ou modifier les filtres
        </blockquote>
      </div>
    </div>

    <div v-else>
      <Tabs v-model="activeTab">
        <Tab header="Interne">
          <RankNoteInternal :data="internal" :filters="filters" />
        </Tab>
        <!-- <Tab header="Externe">
          <RankNoteExternal :data="external" :filters="filters" />
        </Tab> -->
      </Tabs>
    </div>
  </div>
</template>

<script>
import RankNoteFilters from "../components/rank/note/RankNoteFilters";
// import RankNoteExternal from "../components/rank/note/RankNoteExternal";
import RankNoteInternal from "../components/rank/note/RankNoteInternal";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import { mapGetters } from "vuex";

export default {
  components: {
    RankNoteFilters,
    // RankNoteExternal,
    RankNoteInternal,
    Tabs,
    Tab,
  },
  data() {
    return {
      loading: false,
      internal: [],
      external: [],
      activeTab: 0,
      filters: this.defaultFilters(),
    };
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
    }),
  },

  methods: {
    handleFilters(newFilters) {
      this.filters = newFilters;

      this.loadCoachs();
    },

    async loadCoachs() {
      try {
        this.loading = true;

        const { data } = await this.$api.get(`/rank/notes`, {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
            status: this.filters.status.map((s) => s.value),
            merchants: this.filters.merchants.map((m) => m.value),
            concepts: this.filters.concepts.map((s) => s.value),
            prestations: this.filters.prestations.map((s) => s.value),
            languages: this.filters.languages.map((s) => s.value),
            tags: this.filters.tags.map((s) => s.value),
            genders: this.filters.genders,
          },
        });

        this.external = data.external;
        this.internal = data.internal;

        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    defaultFilters() {
      return {
        begin: null,
        end: null,
        status: [],
        merchants: [],
        concepts: [],
        prestations: [],
        languages: [],
        tags: [],
        genders: [],
      };
    },
  },
};
</script>

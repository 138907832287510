<template>
  <div>
    <form>
      <div class="card" v-if="session">
        <div class="card-header">
          <button
            class="btn btn-small btn-info float-right"
            @click="showClient"
            v-if="hasPermission($store.state.user, 'CLIENTS_READ')"
          >
            Voir la fiche
          </button>
          <i class="icon-directions"></i> Commande n° {{ order.id }}
        </div>
        <div class="card-block">
          <div class="row">
            <div class="col-sm-7">
              <dl class="row">
                <dt class="col-sm-5">Séance n°</dt>
                <dd class="col-sm-7">{{ session.counter }}</dd>
                <dt class="col-sm-5">Prénom</dt>
                <dd class="col-sm-7" @click="showClient">
                  {{ session.client.firstName }}
                </dd>
                <dt class="col-sm-5">Nom</dt>
                <dd class="col-sm-7" @click="showClient">
                  {{ session.client.lastName }}
                </dd>
                <dt class="col-sm-5">Adresse</dt>
                <dd class="col-sm-7">{{ formatAddress(session.client) }}</dd>
                <dt class="col-sm-5">Email</dt>
                <dd class="col-sm-7">{{ session.client.email }}</dd>
                <dt class="col-sm-5">Téléphone</dt>
                <dd class="col-sm-7">{{ session.client.phone }}</dd>
                <dt class="col-sm-5" v-if="session.client.gender">Sexe</dt>
                <dd class="col-sm-7" v-if="session.client.gender">
                  {{ session.client.gender }}
                </dd>
                <dt class="col-sm-5" v-if="session.client.preferedCoachGender">
                  Préférence coach
                </dt>
                <dd class="col-sm-7" v-if="session.client.preferedCoachGender">
                  {{ session.client.preferedCoachGender }}
                </dd>
                <dt class="col-sm-5">Attribution temporaire</dt>
                <dd class="col-sm-7">
                  <label class="switch switch switch-text switch-warning">
                    <input
                      type="checkbox"
                      class="switch-input"
                      v-model="session.attribution.temporary"
                      @click="handleTemporary"
                    />
                    <span
                      class="switch-label"
                      data-on="On"
                      data-off="Off"
                    ></span>
                    <span class="switch-handle"></span>
                  </label>
                </dd>
                <dt class="col-sm-5" v-if="order.description">Commande</dt>
                <dd class="col-sm-7" v-if="order.description">
                  {{ order.description }}
                </dd>
                <dt class="col-sm-5">Pack</dt>
                <dd class="col-sm-7">{{ formatPack(order.session) }}</dd>
                <dt class="col-sm-5">Participants</dt>
                <dd class="col-sm-7">{{ order.clients }}</dd>
                <dt class="col-sm-5" v-if="label">Remarques</dt>
                <dd class="col-sm-7" v-if="order.label">{{ order.label }}</dd>
              </dl>
              <p><u>Remarques séance</u> :</p>
              <textarea
                v-model="session.notes"
                class="form-control"
                rows="4"
                v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
              ></textarea>
              <span v-else>{{ client.notes }}</span>
              <button
                class="btn btn-secondary btn-sm"
                @click="handlEditNotes"
                v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
              >
                Enregistrer
              </button>
              <p class="badge badge-success" v-if="savedNotes">Enregistré !</p>
            </div>
            <div class="col-sm-5">
              <geoloc :geoloc="order.geoloc" />
            </div>
          </div>
        </div>
      </div>
    </form>

    <filters
      :order="order"
      :client="session.client"
      :attribution="session.attribution"
      :setCredit="true"
      v-if="session"
      @assign="assignCoach"
      ref="attributionFiltersRef"
    />
  </div>
</template>

<script>
import Geoloc from "../components/Geoloc";
import Filters from "../components/AttributionFilters";

export default {
  components: {
    Geoloc,
    Filters,
  },
  data() {
    return {
      session: null,
      order: null,
      savedNotes: false,
    };
  },
  created() {
    this.loadAttribution();
  },
  methods: {
    handlEditNotes() {
      this.$api
        .put("/sessions/" + this.session.id + "/notes", {
          notes: this.session.notes,
        })
        .then(() => {
          this.savedNotes = true;
        });
    },
    handleTemporary() {
      this.$api.put("/attributions/" + this.session.attribution.id, {
        temporary: this.session.attribution.temporary,
      });
    },
    async assignCoach(coach) {
      await this.$api.put(`/sessions/${this.session.id}/coachs`, {
        attributionId: this.session.attribution.id,
        coachs: coach.email,
      });

      await this.loadAttribution();
      this.$refs.attributionFiltersRef.refreshCoachs();
    },
    loadAttribution() {
      this.$api
        .get("/sessions/" + this.$route.params.id)
        .catch(() => {
          this.$router.push({
            name: "attributions",
          });
        })
        .then((res) => {
          this.session = res.data;
          this.order = this.session.order;

          if (this.session.client.geoloc) {
            this.order.geoloc = {
              lat: this.session.client.geoloc.coordinates[1],
              lng: this.session.client.geoloc.coordinates[0],
            };
          }
          if (undefined === this.session.notes) {
            this.$set(this.session, "notes", "");
          }
        });
    },
    showClient() {
      this.$router.push({
        name: "client-details",
        params: {
          id: this.session.client.id,
        },
      });
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-info-circle mr-05"></i> Historique
    </div>
    <div class="card-block">
      <log :client="client" />
    </div>
  </div>
</template>

<script>
import Log from "../components/Log";

export default {
  components: {
    Log,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

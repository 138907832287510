<template>
  <div class="card">
    <div class="card-header"><i class="icon-graph"></i> Marketing Data</div>
    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">Date de création</dt>
        <dd class="col-sm-8">{{ formatDateTime(createdAt) }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_source</dt>
        <dd class="col-sm-4">
          <el-input
            v-model="form.utm_source"
            size="mini"
            @change="updateMarketing()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          />
          <span v-else>{{ form.utm_source }}</span>
        </dd>
        <dd class="col-sm-4">
          <v-select
            v-if="!form.utm_source"
            :options="['Appel entrant', 'Parrainage', 'Contact mail']"
            v-model="form.utm_source"
            size="mini"
            @input="updateMarketing()"
            :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
          ></v-select>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_medium</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="form.utm_medium"
            size="mini"
            @change="updateMarketing()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          />
          <span v-else>{{ form.utm_medium }}</span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_term</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="form.utm_term"
            size="mini"
            @change="updateMarketing()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          />
          <span v-else>{{ form.utm_term }}</span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_content</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="form.utm_content"
            size="mini"
            @change="updateMarketing()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          />
          <span v-else>{{ form.utm_content }}</span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_campaign</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="form.utm_campaign"
            size="mini"
            @change="updateMarketing()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          />
          <span v-else>{{ form.utm_campaign }}</span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">gclid</dt>
        <dd class="col-sm-8 overflow">
          <el-input
            v-model="form.gclid"
            size="mini"
            @change="updateMarketing()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          />
          <span v-else>{{ form.gclid }}</span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">referer</dt>
        <dd class="col-sm-8 overflow">
          <el-input
            v-model="form.referer"
            size="mini"
            @change="updateMarketing()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          />
          <span v-else>{{ form.referer }}</span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">sourceUrl</dt>
        <dd class="col-sm-8 overflow">
          <el-input
            v-model="form.sourceUrl"
            size="mini"
            @change="updateMarketing()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          />
          <span v-else>{{ form.sourceUrl }}</span>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    createdAt: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { form: {} };
  },
  created() {
    this.form = this.user?.marketing || {};
  },
  methods: {
    updateMarketing() {
      this.$emit("updateMarketing", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow {
  overflow: auto;
}
</style>

<template>
  <div class="row">
    <div class="col-md-6" v-for="(stat, index) in data" :key="index">
      <div class="card" style="border-radius: 25px">
        <div class="card-header text-white" :style="getCss(index)">
          <h4 class="card-title">{{ utm(index).label }}</h4>
        </div>
        <div class="card-block">
          <el-table :data="stat" max-height="400">
            <el-table-column label="" prop="label"></el-table-column>
            <el-table-column label="B2B" prop="b2b">
              <template slot-scope="scope">
                <strong
                  v-if="
                    ['ca', 'averageBasket', 'caWithoutDiscovery'].includes(
                      scope.row.key
                    )
                  "
                  >{{ formatPrice(scope.row.b2b, true) }}</strong
                >
                <strong v-else>{{ scope.row.b2b }}</strong>
              </template>
            </el-table-column>
            <el-table-column label="B2C" prop="b2c">
              <template slot-scope="scope">
                <strong
                  v-if="
                    ['ca', 'averageBasket', 'caWithoutDiscovery'].includes(
                      scope.row.key
                    )
                  "
                  >{{ formatPrice(scope.row.b2c, true) }}</strong
                >
                <strong v-else>{{ scope.row.b2c }}</strong>
              </template>
            </el-table-column>
            <el-table-column label="Total" prop="total">
              <template slot-scope="scope">
                <strong
                  v-if="
                    ['ca', 'averageBasket', 'caWithoutDiscovery'].includes(
                      scope.row.key
                    )
                  "
                  >{{ formatPrice(scope.row.total, true) }}</strong
                >
                <strong v-else>{{ scope.row.total }}</strong>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      commonUtm: "getCommonUtm",
    }),
  },

  methods: {
    utm(index) {
      return (
        this.commonUtm.find((utm) => utm.value === index) || {
          label: index,
          color: "#303030",
        }
      );
    },

    getCss(index) {
      const utm = this.utm(index);

      return `background-color: ${utm.color}; border-top-left-radius: 25px; border-top-right-radius: 25px`;
    },
  },
};
</script>

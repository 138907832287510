<template>
  <div class="live-custom-wrapper">
    <div class="animated fadeIn">
      <Loader :is-visible="loading" />

      <div v-if="loadingError" class="alert alert-danger text-center">
        Une erreur est survenue lors du chargement des Codes Promo
      </div>

      <div class="card" v-else-if="promoCodes">
        <template>
          <div class="card-header">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <el-checkbox-group v-model="isInactivePromoCodesAreShown">
                <el-checkbox name="promoCodeInactive"
                  >Afficher offres non actives</el-checkbox
                >
              </el-checkbox-group>
              <el-select v-model="conceptChosen" multiple>
                <el-option
                  v-for="concept in concepts"
                  :key="concept.id"
                  :label="concept.name"
                  :value="concept.name"
                ></el-option>
              </el-select>
              <el-date-picker
                v-model="chosenDates"
                type="daterange"
                unlink-panels
                range-separator="à"
                start-placeholder="Date de début"
                end-placeholder="Date de fin"
                format="dd/MM/yyyy"
              >
              </el-date-picker>
            </div>
          </div>
        </template>
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Codes Promo
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modalShow = !modalShow"
            v-if="hasPermission($store.state.user, 'SETTINGS_PROMO_CODE_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block custom-table">
          <el-table
            :data="paginatedPromoCodes[currentPage - 1]"
            style="width: 100%"
          >
            <el-table-column
              align="center"
              prop="description"
              label="Nom"
              width="180"
              sortable
            >
            </el-table-column>
            <el-table-column align="center" label="Photo">
              <template slot-scope="scope">
                <img
                  class="promoCode-image"
                  :src="getPictureImage(scope.row.id)"
                />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="code"
              label="Code"
              width="150"
              sortable
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="type"
              label="Concept"
              width="150"
              sortable
            >
            </el-table-column>
            <el-table-column align="center" prop="type" label="Groupe" sortable>
              <template slot-scope="scope">
                {{ scope.row.group && scope.row.group.name }}
              </template>
            </el-table-column>

            <el-table-column align="center" label="Validité" width="200">
              <template slot-scope="scope">
                <div>
                  <i
                    v-if="isValid(scope.row)"
                    class="fa fa-check fa-lg text-success"
                  ></i>
                  <i v-else class="fa fa-times fa-lg text-danger"></i>
                </div>
                <div
                  v-if="scope.row.temporary"
                  class="mt-1"
                  v-tooltip="{
                    content: validityTootlip(scope.row),
                    placement: 'top',
                  }"
                >
                  <div v-if="scope.row.endDate">
                    du {{ dateFormat(scope.row.startDate) }} au
                    {{ dateFormat(scope.row.endDate) }}
                  </div>
                  <div v-else>
                    à partir du {{ dateFormat(scope.row.startDate) }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Cible">
              <template slot-scope="scope">
                {{ scope.row.target }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="Promo">
              <template slot-scope="scope">
                <span v-if="scope.row.discount && scope.row.discount.percentage"
                  >{{ scope.row.discount.percentage }} %</span
                >
                <span
                  v-else-if="scope.row.discount && scope.row.discount.euros"
                  >{{ formatPrice(scope.row.discount.euros, true) }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="Durée"
              prop="sortDuration"
              width="120"
              sortable
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.discount && scope.row.duration === 'forever'"
                  v-tooltip="{
                    content:
                      'Promotion appliquée : -' +
                      (scope.row.discount.percentage
                        ? scope.row.discount.percentage + '%'
                        : formatPrice(scope.row.discount.euros, true)) +
                      ' à vie',
                    placement: 'top',
                  }"
                  >A vie</span
                >
                <span
                  v-else-if="scope.row.discount && scope.row.duration_in_months"
                  v-tooltip="{
                    content:
                      'Promotion appliquée : -' +
                      (scope.row.discount.percentage
                        ? scope.row.discount.percentage + '%'
                        : formatPrice(scope.row.discount.euros, true)) +
                      ' pendant ' +
                      scope.row.duration_in_months +
                      ' mois',
                    placement: 'top',
                  }"
                  >{{ scope.row.duration_in_months }} mois</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="Utilisations"
              prop="stripe.coupon.times_redeemed"
              sortable
              width="130"
            >
              <template slot-scope="scope" v-if="scope.row.stripe">
                {{ scope.row.stripe.coupon.times_redeemed }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="Facturations">
              <template slot-scope="scope" v-if="scope.row.discountUsages">
                {{ scope.row.discountUsages.length }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="CA" prop="totalCA" sortable>
              <template slot-scope="scope">
                {{ scope.row.totalCA.toFixed(2) + " €" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="active"
              label="Actif"
              sortable
            >
              <template slot-scope="scope">
                <label
                  class="switch switch-sm switch-text switch-info mb-0"
                  v-if="
                    hasPermission(
                      $store.state.user,
                      'SETTINGS_PROMO_CODE_WRITE'
                    )
                  "
                >
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="scope.row.active"
                    @click="publishPromoCode(scope.row)"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
                <div v-else>
                  <p>{{ scope.row.active ? "Oui" : "Non" }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              v-if="
                hasPermission($store.state.user, 'SETTINGS_PROMO_CODE_WRITE')
              "
            >
              <template slot-scope="scope">
                <div class="no-ellipsis">
                  <button
                    class="btn btn-default btn-sm"
                    @click="editPromoCode(scope.row)"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="deletePromoCode(scope.row.id)"
                  >
                    <i class="fa fa-trash-o"></i>
                  </button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <paginate
          v-model="currentPage"
          :page-count="numberOfPages"
          :click-handler="handleCurrentChange"
          prev-text="Précédent"
          next-text="Suivant"
          container-class="pagination"
          style="padding: 0 1.25rem"
        ></paginate>
      </div>
    </div>

    <PromoCodeModal
      :modalShow="modalShow"
      :editMode="editMode"
      :promoCode="selectedPromoCode"
      :influs="influs"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import Loader from "../components/Loader";
import Paginate from "vuejs-paginate";
import PromoCodeModal from "./Live/PromoCodeModal";
import moment from "moment";

export default {
  components: {
    PromoCodeModal,
    Paginate,
    Loader,
  },
  data() {
    return {
      loading: false,
      loadingError: false,
      selectedPromoCode: {
        id: "",
        client: {
          name: "",
        },
        clientId: "",
        photoId: null,
        discount: {
          percentage: 20,
          euros: null,
        },
        isInfluencer: false,
        influencerId: "",
        influencerDiscount: {
          percentage: 20,
          euros: null,
        },
        duration: "forever",
        duration_in_months: null,
      },
      chosenDates: "",
      modalShow: false,
      editMode: false,
      paginatedPromoCodes: [],
      numberOfPromoCodesByPage: 20,
      currentPage: 2,
      total: 0,
      promoCodes: [],
      filteredPromoCodes: [],
      filteredPromoCodesByDates: [],
      filteredPromoCodesByName: [],
      filteredPromoCodesWithoutInactive: [],
      isInactivePromoCodesAreShown: false,
      concepts: [],
      conceptChosen: [],
      influs: [],
    };
  },
  async mounted() {
    await this.fetchConcepts();
    await this.fetchUsers();
    await this.fetchPromoCodes();
  },

  computed: {
    numberOfPages: function () {
      return this.paginatedPromoCodes.length;
    },
  },

  watch: {
    conceptChosen(newVal, oldVal) {
      if (newVal !== oldVal) this.filterConcept();
    },

    concepts(newVal, oldVal) {
      if (newVal !== oldVal) this.filterConcept();
    },

    chosenDates(newVal, oldVal) {
      if (newVal !== oldVal) this.filterByDates();
    },

    isInactivePromoCodesAreShown(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.isInactivePromoCodesAreShown === true) {
          this.filteredPromoCodes = [...this.promoCodes];
          this.chunkFilteredPromoCodesInMultipleParts();
        } else {
          this.filterInactivePromoCodes();
          this.filteredPromoCodes = [...this.filteredPromoCodesWithoutInactive];
          this.chunkFilteredPromoCodesInMultipleParts();
        }
      }
    },
  },

  methods: {
    async fetchPromoCodes() {
      this.loading = true;

      try {
        const { data } = await this.$api.get("/promo-codes");

        data.map((promoCode) => {
          if (promoCode.discountUsages) {
            promoCode.totalCA =
              promoCode.discountUsages.reduce(
                (sum, usage) => sum + usage.amount,
                0
              ) / 100;
          }

          promoCode.sortDuration =
            promoCode.duration === "forever"
              ? 1000
              : promoCode.duration_in_months;

          return promoCode;
        });

        this.promoCodes = data;
        this.filteredPromoCodes = this.promoCodes.filter(
          (promo) => promo.active
        );

        this.chunkFilteredPromoCodesInMultipleParts();
        this.currentPage = 1;
      } catch (e) {
        this.loadingError = true;
      } finally {
        this.loading = false;
      }
    },

    async fetchUsers() {
      const { data } = await this.$api.get("/users/coachs/influencers");

      this.influs = data.map((coach) => ({
        label: `${coach?.coach?.name} (${coach?.coach?.usedEmail})`,
        value: coach?.id,
      }));
    },

    async fetchConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.concepts = data;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
    },

    filterInactivePromoCodes() {
      if (this.filteredPromoCodes.length > 0) {
        this.filteredPromoCodesWithoutInactive = this.filteredPromoCodes.filter(
          (promoCode) => promoCode.active
        );
      }

      this.currentPage = 1;
      this.filterConcept();
    },

    filterConcept() {
      if (this.conceptChosen.length === 0) {
        this.filteredPromoCodes = [...this.promoCodes];
      } else {
        this.filteredPromoCodes = this.promoCodes.filter((code) =>
          this.conceptChosen.includes(code.type)
        );
      }

      this.chunkFilteredPromoCodesInMultipleParts();
      this.currentPage = 1;
    },

    onModalClose() {
      this.modalShow = false;
      this.editMode = false;
      this.clearFilters();
      this.fetchPromoCodes();
      this.selectedPromoCode = {
        id: "",
        client: {
          name: "",
        },
        clientId: "",
        photoId: null,
        discount: {
          percentage: 20,
          euros: null,
        },
        isInfluencer: false,
        influencerId: "",
        influencerDiscount: {
          percentage: 20,
          euros: null,
        },
      };
    },

    editPromoCode(sps) {
      this.selectedPromoCode = sps;
      this.modalShow = true;
      this.editMode = true;
    },

    deletePromoCode(id) {
      this.$confirm({
        message: "Supprimer le code promo ?",
        onConfirm: async () => {
          await this.$api.delete(`/promo-codes/${id}`);

          this.fetchPromoCodes();
        },
      });
    },

    dateFormat(date, recurence = null) {
      const objDate = new Date(date);

      if (null !== recurence) {
        objDate.setDate(objDate.getDate() + parseInt(7) * recurence);
      }

      return moment(String(objDate)).format("DD/MM/YYYY");
    },

    getPictureImage(id) {
      return `${process.env.VUE_APP_API_URL}promo-codes/${id}/photo`;
    },

    async publishPromoCode(promoCode) {
      if (promoCode.active) {
        try {
          await this.$api.post(`/promo-codes/${promoCode.id}/unpublish`);

          if (!this.isInactivePromoCodesAreShown) {
            this.filteredPromoCodes = this.filteredPromoCodes.filter(
              (promo) => promo.id !== promoCode.id
            );

            this.chunkFilteredPromoCodesInMultipleParts();
          }
        } catch {
          promoCode.active = true;
        }
      } else {
        try {
          await this.$api.post(`/promo-codes/${promoCode.id}/publish`);
        } catch {
          promoCode.active = false;
        }
      }
    },

    chunkFilteredPromoCodesInMultipleParts() {
      let arrayChunked = [];
      let arrayToChunked = [...this.filteredPromoCodes];

      while (arrayToChunked.length) {
        arrayChunked.push(
          arrayToChunked.splice(0, this.numberOfPromoCodesByPage)
        );
      }

      this.paginatedPromoCodes = arrayChunked;
    },

    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },

    filterByDates() {
      this.isInactivePromoCodesAreShown = false;

      if (this.chosenDates) {
        const startDate = moment(this.chosenDates[0]);
        const endDate = moment(this.chosenDates[1]);

        if (startDate && endDate && startDate !== endDate) {
          this.filteredPromoCodes = this.promoCodes.filter((promoCode) => {
            if (promoCode.temporary && promoCode.startDate) {
              if (!promoCode.endDate) {
                return (
                  moment(promoCode.startDate).isBefore(startDate) ||
                  moment(promoCode.startDate).isBetween(
                    startDate.subtract(1, "d"),
                    endDate
                  )
                );
              } else {
                return (
                  (moment(promoCode.startDate).isBefore(startDate) &&
                    moment(promoCode.endDate).isAfter(endDate)) ||
                  moment(promoCode.startDate).isBetween(
                    startDate.subtract(1, "d"),
                    endDate
                  ) ||
                  moment(promoCode.endDate).isBetween(
                    startDate,
                    endDate.add(1, "d")
                  )
                );
              }
            }

            return false;
          });
        } else {
          this.filteredPromoCodes = [...this.promoCodes];
        }
      } else {
        this.filteredPromoCodes = [...this.promoCodes];
      }

      this.chunkFilteredPromoCodesInMultipleParts();
      this.currentPage = 1;
    },

    clearFilters() {
      this.chosenDates = "";
      this.conceptChosen = [];
      this.isInactivePromoCodesAreShown = false;
    },

    isValid(promoCode) {
      if (promoCode.temporary) {
        if (promoCode.startDate && promoCode.endDate) {
          return moment().isBetween(
            moment(promoCode.startDate),
            moment(promoCode.endDate)
          );
        }

        if (promoCode.startDate) {
          return moment().isAfter(moment(promoCode.startDate));
        }

        if (promoCode.endDate) {
          return moment().isBefore(moment(promoCode.endDate));
        }
      }

      return true;
    },

    validityTootlip(promoCode) {
      if (promoCode.startDate && promoCode.endDate) {
        return `Code promo utilisable entre le ${this.dateFormat(
          promoCode.startDate
        )} et le ${this.dateFormat(promoCode.endDate)}`;
      }

      if (promoCode.startDate) {
        return `Code promo utilisable à partir du ${this.dateFormat(
          promoCode.startDate
        )}`;
      }

      if (promoCode.endDate) {
        return `Code promo utilisable jusqu'au ${this.dateFormat(
          promoCode.endDate
        )}`;
      }

      return "";
    },
  },
};
</script>

<style lang="scss">
.live-custom-wrapper {
  .custom-table .cell {
    white-space: nowrap;
  }

  .date-cell {
    text-transform: capitalize;
  }

  .no-ellipsis {
    text-overflow: inherit;
  }

  .promoCode-image {
    max-height: 60px;
    width: auto;
  }
}

/*[TODO] May we improved */
.live-custom-wrapper {
  max-width: calc(100vw - 315px);
}

@media (max-width: 991px) {
  .live-custom-wrapper {
    max-width: calc(100vw - 65px);
  }
}

.aside-menu-fixed.sidebar-hidden {
  .live-custom-wrapper {
    max-width: calc(100vw - 65px);
  }
}
</style>

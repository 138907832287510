<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Rechercher un prestataire</label>
          <input
            class="form-control"
            v-model="filters.name"
            placeholder="Nom, Prénom, Email"
          />
        </div>
        <div class="form-group col-sm-6">
          <label>Coach manager</label>
          <multiselect
            placeholder="Choisir un ou des coach managers"
            v-model="filters.coachManagers"
            :options="coachManagers"
            label="label"
            track-by="label"
            class="custom-multiselect"
            :multiple="true"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      filters: {
        coachManagers: [],
        name: "",
      },
      coachManagers: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },

  async mounted() {
    await this.fetchCoachManagers();

    if (
      this.user.coachManager &&
      this.hasPermission(this.$store.state.user, "DOCUMENTS_READ") &&
      this.coachManagers.some((c) => c.value === this.user.email)
    ) {
      const coachManager = this.coachManagers.find(
        (c) => c.value === this.user.email
      );

      this.filters.coachManagers = [coachManager];
    }

    this.applyFilters();
  },

  methods: {
    applyFilters() {
      this.$emit("apply", this.filters);
    },

    async fetchCoachManagers() {
      try {
        const { data } = await this.$api.get("/users/coach-managers");

        this.coachManagers = data.map((c) => ({
          value: c.email,
          label: c.coachManager.pseudo,
        }));
      } catch (e) {
        this.coachManagers = [];
      }
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-graph"></i> Attributions</div>

    <div class="card-block">
      <div
        v-if="!commercialsAttributions.length"
        class="text-center text-muted"
      >
        Aucune attribution pour cette période
      </div>

      <div v-else class="table-responsive">
        <table class="table table-striped" style="table-layout: fixed">
          <thead>
            <tr>
              <th scope="col"></th>
              <th
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                scope="col"
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center p-1"
                >
                  <div
                    style="
                      width: 100px;
                      height: 100px;
                      border-radius: 50%;
                      overflow: hidden;
                    "
                    class="mb-1"
                  >
                    <img
                      :src="commercial.photo"
                      style="width: 100%; height: 100%; object-fit: cover"
                    />
                  </div>

                  <div
                    class="text-white text-center"
                    :style="`background-color: ${commercial.color}; border-radius: 1rem; padding: .25rem 1rem`"
                  >
                    {{ commercial.label }}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Attributions</th>
              <td
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                class="text-center"
              >
                {{ commercial.nbAttributions }}
              </td>
            </tr>
            <tr>
              <th scope="row">Temps d'attribution</th>
              <td
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                class="text-center"
              >
                <span v-if="commercial.averageFirstSent">{{
                  commercial.averageFirstSent
                }}</span>
                <span v-else class="text-muted">N/A</span>
              </td>
            </tr>
            <tr>
              <th scope="row">Intervalle paiement / attribution</th>
              <td
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                class="text-center"
              >
                <span v-if="commercial.averageFirstPayment">{{
                  commercial.averageFirstPayment
                }}</span>
                <span v-else class="text-muted">N/A</span>
              </td>
            </tr>
            <tr>
              <th scope="row">Intervalle formulaire / attribution</th>
              <td
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                class="text-center"
              >
                <span v-if="commercial.averageProspectForm">{{
                  commercial.averageProspectForm
                }}</span>
                <span v-else class="text-muted">N/A</span>
              </td>
            </tr>
            <tr>
              <th scope="row">Relances App</th>
              <td
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                class="text-center"
              >
                {{ commercial.nbAppReminders }}
              </td>
            </tr>
            <tr>
              <th scope="row">Relances SMS (+ coût)</th>
              <td
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                class="text-center"
              >
                {{ commercial.nbSmsReminders }}
                <span v-if="commercial.nbSmsReminders" class="text-muted"
                  >({{ commercial.smsExpenseTotal.toFixed(2) }}€)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">Relances Email</th>
              <td
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                class="text-center"
              >
                {{ commercial.nbEmailReminders }}
              </td>
            </tr>
            <tr>
              <th scope="row">Ratio payé / non payé</th>
              <td
                v-for="commercial in commercialsStats"
                :key="commercial.value"
                class="text-center"
              >
                <span class="text-success">{{ commercial.nbPayed }}</span> /
                <span class="text-danger">{{ commercial.nbPending }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    commercialsAttributions: {
      type: Array,
      default: () => [],
    },

    endDate: {
      type: String,
      required: true,
    },
  },

  computed: {
    commercialsStats() {
      return this.commercialsAttributions.map((com) => {
        const nbAttributions = com.attributions.length;
        const nbPayed = com.attributions.filter(
          (a) =>
            a.firstPaymentAt &&
            moment(a.firstPaymentAt).isBefore(moment(this.endDate))
        ).length;
        const nbPending = com.attributions.filter(
          (a) =>
            !a.firstPaymentAt ||
            moment(a.firstPaymentAt).isAfter(moment(this.endDate))
        ).length;

        const firstSentIntervals = [];
        const firstPaymentIntervals = [];
        const prospectFormIntervals = [];

        let nbAppReminders = 0;
        let nbSmsReminders = 0;
        let nbEmailReminders = 0;

        let smsExpenseTotal = 0;

        for (const attrib of com.attributions) {
          if (attrib.firstSentAt) {
            const interval = moment(attrib.firstSentAt).diff(
              moment(attrib.createdAt)
            );

            if (interval > 0) firstSentIntervals.push(interval);
          }

          if (attrib.firstPaymentAt) {
            const interval = moment(attrib.firstPaymentAt).diff(
              moment(attrib.createdAt)
            );

            if (interval > 0) firstPaymentIntervals.push(interval);
          }

          if (attrib.prospectFormFilledAt && attrib.firstSentAt) {
            const interval = moment(attrib.firstSentAt).diff(
              moment(attrib.prospectFormFilledAt)
            );

            if (interval > 0) prospectFormIntervals.push(interval);
          }

          if (attrib.appReminders) nbAppReminders += attrib.appReminders;

          if (attrib.smsReminders) nbSmsReminders += attrib.smsReminders;

          if (attrib.emailReminders) nbEmailReminders += attrib.emailReminders;

          if (attrib.smsExpense) smsExpenseTotal += attrib.smsExpense;
        }

        let averageFirstSent;

        if (firstSentIntervals.length) {
          const sum = firstSentIntervals.reduce((a, b) => a + b, 0);
          const average = sum / firstSentIntervals.length;

          averageFirstSent = this.formatInterval(average);
        }

        let averageFirstPayment;

        if (firstPaymentIntervals.length) {
          const sum = firstPaymentIntervals.reduce((a, b) => a + b, 0);
          const average = sum / firstPaymentIntervals.length;

          averageFirstPayment = this.formatInterval(average);
        }

        let averageProspectForm;

        if (prospectFormIntervals.length) {
          const sum = prospectFormIntervals.reduce((a, b) => a + b, 0);
          const average = sum / prospectFormIntervals.length;

          averageProspectForm = this.formatInterval(average);
        }

        return {
          ...com,
          nbAttributions,
          nbPayed,
          nbPending,
          averageFirstSent,
          averageFirstPayment,
          averageProspectForm,
          nbAppReminders,
          nbSmsReminders,
          nbEmailReminders,
          smsExpenseTotal,
        };
      });
    },
  },

  methods: {
    formatInterval(interval) {
      const duration = moment.duration(interval);
      const hours = duration.asHours();
      const minutes = duration.asMinutes();

      return hours > 1
        ? `${Math.round(hours)}h${Math.round(minutes)}min`
        : `${Math.round(minutes)}min`;
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',{staticClass:"row pt-1 animated fadeIn"},[_c('div',{staticClass:"col-lg-5"},[_c('StatsLiveParticipantsDoughnut',{staticClass:"mb-2",attrs:{"data":_vm.statsByGender}}),_c('div',{staticClass:"d-flex flex-column align-items-center"},_vm._l((_vm.statsByGenderLabels),function(label){return _c('div',{key:label.value,staticClass:"text-white col-12 col-sm-6",style:(`
                background-color: ${label.color};
                padding: .5rem;
                padding-left: 1rem;
                border-radius: 1rem;
                margin-bottom: .3rem;
                margin-top: .3rem;
              `)},[_vm._v(" "+_vm._s(label.name)+" ")])}),0)],1),_c('div',{staticClass:"col-lg-7"},[_c('StatsLiveParticipantsBars',{attrs:{"data":_vm.statsByAgeAndGender,"colors":_vm.colors}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="isVisible" :class="overlayCssClass">
    <div :class="loaderCssClass"></div>
    <span class="text" v-html="text"></span>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: "all",
    },
  },
  computed: {
    overlayCssClass() {
      return `${this.size}-loader-overlay`;
    },

    loaderCssClass() {
      return `${this.size}-loader`;
    },
  },
};
</script>

<style scoped>
.all-loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  cursor: pointer;
}

.all-loader {
  animation: loader-animate 1.5s linear infinite;
  clip: rect(0, 80px, 80px, 40px);
  height: 80px;
  width: 80px;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
}

.all-loader:after {
  animation: loader-animate-after 1.5s ease-in-out infinite;
  clip: rect(0, 80px, 80px, 40px);
  content: "";
  border-radius: 50%;
  height: 80px;
  width: 80px;
  position: absolute;
}

@keyframes loader-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(220deg);
  }
}

@keyframes loader-animate-after {
  0% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(-140deg);
  }
  50% {
    box-shadow: inset #fff 0 0 0 2px;
  }
  100% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(140deg);
  }
}

.small-loader-overlay {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 80px;
}

.small-loader {
  width: 48px;
  height: 48px;
  border: 5px solid #808080;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

span.text {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
</style>

<template>
  <div class="app" v-if="user">
    <AppHeader />
    <div class="app-body">
      <Sidebar />
      <main class="main">
        <div
          class="card card-inverse card-success text-center"
          v-if="staging()"
        >
          <div class="card-block">
            <blockquote class="card-blockquote">
              Interface de <strong>test</strong> -
              manager-staging.justcoaching.fr
            </blockquote>
          </div>
        </div>
        <breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </main>
    </div>
    <AppFooter />
    <ChangeCoachPassword :email="user.email" />
    <ConfirmModal />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppHeader from "../components/Header";
import Sidebar from "../components/Sidebar";
import AppFooter from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import ChangeCoachPassword from "../components/modal/ChangeCoachPassword";

export default {
  name: "full",
  components: {
    AppHeader,
    Sidebar,
    AppFooter,
    Breadcrumb,
    ChangeCoachPassword,
  },
  created() {
    this.$store
      .dispatch("loadUser")
      .catch(() => {
        this.logout();
      })
      .then(() => {
        if (this.isTeam(this.$store.state.user)) {
          this.$store.dispatch("loadMerchants");
          this.$store.dispatch("loadConcepts");
          this.$store.dispatch("loadInvoiceSeries");
        }
      });
  },
  methods: {
    logout() {
      this.$store.commit("AUTH_LOGOUT");
      this.$router.push({
        name: "login",
        query: {
          redirect: this.$route.fullPath,
        },
      });
    },
    staging() {
      return window.location.host === "manager-staging.justcoaching.fr";
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    name() {
      return this.$route.name;
    },

    list() {
      return this.$route.matched;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-body {
  overflow-x: hidden !important;

  @media (max-width: 1200px) {
    overflow-x: auto !important;
  }
}
</style>

<template>
  <div class="live-custom-wrapper">
    <loader :isVisible="loading" />

    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Parrainage Live
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modalShow = !modalShow"
            v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>

        <div class="card-block custom-table">
          <el-table
            :data="paginatedDiscounts"
            :default-sort="{ prop: 'discount', order: 'ascending' }"
            style="width: 100%"
          >
            <el-table-column align="center" prop="name" label="Nom" sortable>
            </el-table-column>
            <el-table-column
              align="center"
              prop="discount"
              label="Remise"
              sortable
            >
              <div slot-scope="scope" v-if="scope.row.discount">
                {{ scope.row.discount + "%" }}
              </div>
              <div v-else>
                {{ "Non défini" }}
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              prop="requiredSponsoredClientsCount"
              label="Parrainages requis"
              width="200"
              sortable
            >
              <template
                slot-scope="scope"
                v-if="scope.row.requiredSponsoredClientsCount"
              >
                {{ scope.row.requiredSponsoredClientsCount }}
                <span v-if="scope.row.isMax">+</span>
              </template>
              <template v-else> Non défini </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="clients"
              label="Bénéficiaires"
              width="200"
              sortable
            >
              <template slot-scope="scope" v-if="scope.row.clients">
                {{ scope.row.clients.length }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="stripeId"
              label="Stripe ID"
            ></el-table-column>
            <el-table-column align="center" prop="isMax" label="Remise max">
              <template slot-scope="scope" v-if="scope.row.isMax">
                <span class="fa fa-check"></span>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <div class="no-ellipsis">
                  <button
                    class="btn btn-default btn-sm"
                    @click="editSponsorshipDiscount(scope.row)"
                    v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="deleteSponsorshipDiscount(scope.row.id)"
                    v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
                  >
                    <i class="fa fa-trash-o"></i>
                  </button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <paginate
          v-model="currentPage"
          :page-count="pageCount"
          :click-handler="handleCurrentChange"
          prev-text="Précédent"
          next-text="Suivant"
          container-class="pagination"
          style="padding: 0 1.25rem"
        ></paginate>
      </div>
    </div>

    <SponsorshipDiscountModal
      :modalShow="modalShow"
      :editMode="editMode"
      :discount="selectedSponsorshipDiscount"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import Loader from "../components/Loader";
import Paginate from "vuejs-paginate";
import SponsorshipDiscountModal from "./Live/SponsorshipDiscountModal";

export default {
  components: {
    SponsorshipDiscountModal,
    Paginate,
    Loader,
  },
  data() {
    return {
      loading: false,
      selectedSponsorshipDiscount: {
        id: null,
        name: null,
        requiredSponsoredClientsCount: 0,
        discount: 0,
        stripeId: null,
        isMax: false,
      },
      modalShow: false,
      editMode: false,
      discounts: [],
      currentPage: 1,
      limit: 10,
    };
  },

  computed: {
    paginatedDiscounts() {
      return this.discounts.slice(
        this.currentPage * this.limit - this.limit,
        this.currentPage * this.limit
      );
    },

    pageCount() {
      return Math.ceil(this.discounts.length / this.limit);
    },
  },

  async mounted() {
    await this.fetchLiveSponsorshipDiscounts();
  },

  methods: {
    async fetchLiveSponsorshipDiscounts() {
      this.loading = true;

      try {
        const { data } = await this.$api.get("/live-sponsorships/discounts");

        this.discounts = data;
      } catch (e) {
        console.warn(e);
      }

      this.loading = false;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
    },

    async onModalClose() {
      this.modalShow = false;
      this.editMode = false;
      await this.fetchLiveSponsorshipDiscounts();

      this.selectedSponsorshipDiscount = {
        id: null,
        name: null,
        requiredSponsoredClientsCount: 0,
        discount: 0,
        stripeId: null,
        isMax: false,
      };
    },

    editSponsorshipDiscount(sps) {
      this.selectedSponsorshipDiscount = sps;
      this.modalShow = true;
      this.editMode = true;
    },

    deleteSponsorshipDiscount(id) {
      this.$confirm({
        message: "Supprimer cette remise de parrainage Live ?",
        onConfirm: async () => {
          await this.$api.delete(`/live-sponsorships/discounts/${id}`);

          this.fetchLiveSponsorshipDiscounts();
        },
      });
    },
  },
};
</script>

<style lang="scss">
.live-custom-wrapper {
  .custom-table .cell {
    white-space: nowrap;
  }

  .date-cell {
    text-transform: capitalize;
  }

  .no-ellipsis {
    text-overflow: inherit;
  }

  .sponsorship-image {
    max-height: 60px;
    width: auto;
  }
}

/*[TODO] May we improved */
.live-custom-wrapper {
  max-width: calc(100vw - 315px);
}

@media (max-width: 991px) {
  .live-custom-wrapper {
    max-width: calc(100vw - 65px);
  }
}

.aside-menu-fixed.sidebar-hidden {
  .live-custom-wrapper {
    max-width: calc(100vw - 65px);
  }
}
</style>

<template>
  <div
    v-if="hasPermission($store.state.user, 'DISBURSEMENTS_READ')"
    class="wrapper custom-wrapper"
  >
    <Tabs v-model="activeTab" @input="changeTab" class="empty-tab">
      <Tab
        :key="`tab-${index}`"
        v-for="(item, index) of tabs"
        :header="item.label"
      >
      </Tab>
    </Tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Tabs,
    Tab,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "En attente",
          name: "pending-disbursements",
        },
        {
          label: "Traités",
          name: "processed-disbursements",
        },
        {
          label: "Payés",
          name: "payed-disbursements",
        },
        {
          label: "Bloqués",
          name: "blocked-disbursements",
        },
        {
          label: "Contestations",
          name: "disbursements-troubles",
        },
      ],
    };
  },
  created() {
    if (this.hasPermission(this.$store.state.user, "DISBURSEMENTS_READ")) {
      if (this.$route.name === "disbursements") {
        this.$router.push({ name: this.tabs[0].name });
      }
      this.setCurrentTab(this.$route.name);
    }
  },
  methods: {
    changeTab(index) {
      if (index >= 0) {
        this.$router.push({ name: this.tabs[index].name });
      }
    },
    setCurrentTab(currentPath) {
      this.activeTab = this.tabs.findIndex((tab) => tab.name === currentPath);
    },
  },
  watch: {
    $route(current) {
      if (this.hasPermission(this.$store.state.user, "DISBURSEMENTS_READ")) {
        if (current.name === "disbursements") {
          this.$router.push({ name: this.tabs[0].name });
        }
        this.setCurrentTab(current.name);
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex justify-content-between">
      <span class="font-weight-bold"> Commercial</span>
      <el-select value="" size="mini" @change="addCommercial($event)">
        <el-option
          :value="commercial.value"
          v-for="commercial in available"
          :key="commercial.value"
          >{{ commercial.pseudo }}</el-option
        >
      </el-select>
    </div>
    <div v-if="related.length > 0" style="padding: 0.5rem 0">
      <div
        v-for="(commercial, index) in related"
        :key="`commercial-${commercial.value}`"
        :class="`d-flex justify-content-between ${
          index === 0 ? 'my-1' : 'mb-1'
        }`"
      >
        <span
          class="text-white rounded-3 mr-1 d-flex align-items-center"
          style="padding: 7px"
          :style="commercial.color && `background-color:${commercial.color}`"
          :class="!commercial.color && 'bg-primary'"
        >
          <span>{{ commercial.pseudo }}</span>
          <span
            class="ml-1"
            style="cursor: pointer"
            @click="confirmRemoveCommercial(commercial.value)"
            ><i class="icon-close"></i
          ></span>
        </span>

        <datepicker
          v-model="commercial.relatedSince"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
          @input="newCommercialRelationDate($event, commercial)"
          v-if="hasPermission($store.state.user, 'COMPANIES_COMMERCIALS_DATE')"
        />
        <span v-else>Ajouté le {{ formatDate(commercial.relatedSince) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  data() {
    return {
      commercials: [],
    };
  },

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.getCommercials();
  },

  computed: {
    related() {
      return this.company.commercials || [];
    },

    available() {
      return this.commercials.filter(
        ({ value }) => !this.related.some((com) => com.value === value)
      );
    },
  },

  methods: {
    async getCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search", {
          params: {
            b2b: true,
          },
        });

        this.commercials = data.map((user) => ({
          pseudo: user.commercial.pseudo,
          value: user.email,
          mobile: user.commercial.mobile,
          color: user.commercial.color,
        }));
      } catch (e) {
        this.commercials = [];
      }
    },

    async addCommercial(event) {
      const dataset = {
        id: this.company.id,
        commercial: event,
      };

      try {
        await this.$api.post("/companies/commercial", dataset);
      } catch (e) {
        this.$errorToast(
          "Impossible d'ajouter le commercial, recharger la page pour vérifier qu'il n'est pas déjà attribué."
        );
      }

      const currentCommercial = this.commercials.find(
        ({ value }) => value === event
      );

      this.company.commercials.push({
        ...currentCommercial,
        relatedSince: moment().startOf("day").toDate(),
      });
    },

    confirmRemoveCommercial(commercial) {
      this.$confirm({
        message: "Supprimer le lien commercial ?",
        onConfirm: () => {
          this.removeCommercial(commercial);
        },
      });
    },

    async removeCommercial(commercial) {
      await this.$api.delete("/companies/commercial", {
        data: {
          id: this.company.id,
          commercial,
        },
      });

      const currentCommercial = this.commercials.find(
        ({ value }) => value === commercial
      );

      this.company.commercials = this.company.commercials.filter(
        ({ value }) => value !== currentCommercial.value
      );
    },

    async newCommercialRelationDate(relatedSince, commercial) {
      const data = {
        id: this.company.id,
        commercial: commercial.value,
        relatedSince,
      };

      await this.$api.put(`/companies/commercial`, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.commercial-relation {
  .vdp-datepicker__calendar {
    right: 0;
  }
}
</style>

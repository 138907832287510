<template>
  <div class="card">
    <Loader :is-visible="loading" />

    <div class="card-header">
      <i class="fa fa-crosshairs"></i> Lieux d'intervention ({{ data.length }})

      <button
        v-if="canAdd"
        type="button"
        class="btn btn-primary btn-sm float-right"
        @click="add"
      >
        <i class="fa fa-plus mr-05"></i>
        Ajouter
      </button>
    </div>

    <div class="card-block">
      <tabs v-model="activeTab">
        <tab :header="activeHeader">
          <UserLocalisationsTable
            :data="active"
            @edit="edit"
            @delete="deleteUserLocalisation"
            @activate="toggleActive"
          />
        </tab>
        <tab :header="inactiveHeader">
          <UserLocalisationsTable
            :data="inactive"
            @edit="edit"
            @delete="deleteUserLocalisation"
            @activate="toggleActive"
          />
        </tab>
      </tabs>
    </div>

    <UserLocalisationModal
      :show="showModal"
      :user-localisation="selectedUserLocalisation"
      @close="closeModal"
      @edit="editUserLocalisation"
      @add="addNewUserLocalisation"
    />
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import UserLocalisationsTable from "./UserLocalisationsTable";
import UserLocalisationModal from "./UserLocalisationModal";
import Loader from "./Loader";

export default {
  components: {
    Tabs,
    Tab,
    UserLocalisationsTable,
    UserLocalisationModal,
    Loader,
  },

  props: {
    userLocalisations: {
      type: Array,
      default: () => [],
    },

    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      data: [],
      activeTab: 0,
      showModal: false,
      selectedUserLocalisation: null,
    };
  },

  computed: {
    active() {
      return this.data.filter((userLoc) => userLoc.active);
    },

    inactive() {
      return this.data.filter((userLoc) => !userLoc.active);
    },

    activeHeader() {
      return `Actifs (${this.active.length})`;
    },

    inactiveHeader() {
      return `Inactifs (${this.inactive.length})`;
    },

    canAdd() {
      return (
        (this.$store.state.user.coach?.isCoach &&
          this.$store.state.user.id === this.userId) ||
        this.hasPermission(this.$store.state.user, "USERS_WRITE")
      );
    },
  },

  watch: {
    userLocalisations(userLocalisations) {
      this.data = userLocalisations || [];
    },
  },

  mounted() {
    this.data = this.userLocalisations;
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.selectedUserLocalisation = null;
    },

    edit(userLocalisation) {
      this.selectedUserLocalisation = userLocalisation;
      this.showModal = true;
    },

    add() {
      this.selectedUserLocalisation = null;
      this.showModal = true;
    },

    async editUserLocalisation(form) {
      try {
        this.loading = true;

        const { localisationId, address, zipcode, city, country, range } = form;

        const { data: userLocalisation } = await this.$api.put(
          `/users/${this.userId}/localisations/${localisationId}`,
          {
            address,
            zipcode,
            city,
            country,
            range,
          }
        );

        this.data = this.data.map((userLoc) => {
          if (userLoc.id === userLocalisation.id) {
            return userLocalisation;
          }

          return userLoc;
        });
      } catch (e) {
        this.$errorToast("Impossible de mettre à jour la localisation");
      } finally {
        this.loading = false;

        this.closeModal();
      }
    },

    async addNewUserLocalisation(form) {
      try {
        this.loading = true;

        const { address, zipcode, city, country, range } = form;

        const { data: userLocalisation } = await this.$api.post(
          `/users/${this.userId}/localisations`,
          {
            address,
            zipcode,
            city,
            country,
            range,
          }
        );

        this.data.unshift(userLocalisation);
      } catch (e) {
        this.$errorToast("Impossible d'ajouter la localisation");
      } finally {
        this.loading = false;

        this.closeModal();
      }
    },

    async deleteUserLocalisation(userLocalisation) {
      try {
        this.loading = true;

        await this.$api.delete(
          `/users/${this.userId}/localisations/${userLocalisation.localisationId}`
        );

        this.data = this.data.filter(
          (userLoc) => userLoc.id !== userLocalisation.id
        );
      } catch (e) {
        this.$errorToast("Impossible de supprimer la localisation");
      } finally {
        this.loading = false;
      }
    },

    async toggleActive(userLocalisation) {
      try {
        const { localisationId, active } = userLocalisation;

        await this.$api.patch(
          `/users/${this.userId}/localisations/${localisationId}/active`,
          {
            active,
          }
        );
      } catch (e) {
        const error = userLocalisation.active
          ? "Impossible d'activer la localisation"
          : "Impossible de désactiver la localisation";

        this.$errorToast(error);

        userLocalisation.active = !userLocalisation.active;
      }
    },
  },
};
</script>

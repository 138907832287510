<template>
  <div class="p-2">
    <h4>Total: {{ data.length }} client{{ data.length > 1 ? "s" : "" }}</h4>
    <template v-for="lostReason of displayLostReasons">
      <div :key="lostReason.value" class="mt-2" v-if="lostReason.count">
        <h6 :style="getReasonColor(lostReason.value)">
          {{ lostReason.label }}
        </h6>
        <p>
          {{ lostReason.count }} client{{ lostReason.count > 1 ? "s" : "" }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: { type: Array, required: true },
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),
    displayLostReasons() {
      return this.lostReasons.map((reason) => {
        return {
          label: reason.label,
          value: reason.value,
          count: this.data.filter((client) => client.reason === reason.value)
            .length,
        };
      });
    },
  },

  methods: {
    getReasonColor(reason) {
      return {
        display: "inline-block",
        "border-radius": "100px",
        padding: "3px 20px",
        "word-break": "break-word",
        color: "white",
        "background-color": this.lostReasons.find((r) => r.value === reason)
          .color,
      };
    },
  },
};
</script>

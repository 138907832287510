<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <div>
        <span><i class="fa fa-files-o"></i> Attestation(s)</span>
        <div class="sap-toggle">
          <span class="txt-sap">Envoie automatique annuel </span>
          <label
            class="switch switch-sm switch-text switch-info float-right ml-1 mb-0"
            v-if="checkPermission"
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="client.sendSAP"
              @click="handleToggleSendSAP"
            />
            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>
          <span v-else>{{ client.sendSAP ? "Activé" : "Désactivé" }}</span>
        </div>
      </div>
      <SapCreateModal :client="client" @addSap="addSap" />
    </div>

    <div class="card-block">
      <ClientSapTable :sapsData="saps" :clientEmail="client.contactEmail" />
    </div>
  </div>
</template>

<script>
import SapCreateModal from "./modal/SapCreateModal";
import ClientSapTable from "./sap/ClientSapTable";

export default {
  components: {
    SapCreateModal,
    ClientSapTable
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      saps: []
    };
  },

  created() {
    this.saps = this.client.saps;
  },

  computed: {
    checkPermission() {
      return (
        this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") &&
        !this.client.deletedAt
      );
    },
  },

  methods: {
    addSap(sap) {
      this.saps.unshift(sap);
    },

    handleToggleSendSAP(event) {
      this.$api.put("/clients/toggle-sap", {
        email: this.client.email,
        sendSAP: event.target.checked,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sap-toggle {
  .txt-sap {
    margin-right: 0.5rem;
  }
}
</style>
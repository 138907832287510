<template>
  <div class="wrapper">
    <div class="card">
      <div class="card-header">
        Sessions Live
      </div>
      <div class="card-block">
        <SessionsCalendar />
      </div>
    </div>
  </div>
</template>

<script>
import SessionsCalendar from "../../components/SessionsCalendar";

export default {
  components: {
    SessionsCalendar
  },

  data() {
    return {
      value: new Date()
    };
  }
};
</script>

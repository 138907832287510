<template>
  <div class="wrapper">
    <router-view></router-view>

    <template v-if="$route.name === 'clientsGroups'">
      <loader :isVisible="loading" />

      <div class="animated fadeIn">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify"></i> Groupes

            <button
              type="button"
              class="btn btn-sm btn-primary float-right"
              @click="modalGroupShow = !modalGroupShow"
              v-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
            >
              <i class="fa fa-plus"></i>&nbsp; Créer un groupe
            </button>
          </div>

          <div class="card-block custom-table">
            <el-table
              :data="paginatedGroups"
              :default-sort="{ prop: 'name', order: 'ascending' }"
              style="width: 100%"
            >
              <el-table-column align="left" width="180" prop="name" label="Nom">
                <template slot-scope="scope">
                  <a :href="groupHref(scope.row.id)">{{ scope.row.name }}</a>
                </template>
              </el-table-column>

              <el-table-column align="left" prop="note" label="Description">
              </el-table-column>

              <el-table-column prop="domains" label="Domaines email">
              </el-table-column>

              <el-table-column align="left" prop="color" label="Couleur">
                <template slot-scope="scope">
                  <div
                    class="color-badge"
                    :style="{ backgroundColor: scope.row.color }"
                  ></div>
                </template>
              </el-table-column>

              <el-table-column align="center" label="Logo">
                <template slot-scope="scope">
                  <img
                    style="width: 120px"
                    :src="getPictureImage(scope.row.id)"
                  />
                </template>
              </el-table-column>

              <el-table-column align="right">
                <template slot-scope="scope">
                  <a
                    v-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
                    :href="groupHref(scope.row.id, { edit: true })"
                  >
                    <button class="btn btn-sm btn-default mr-1">
                      <i class="fa fa-pencil" />
                    </button>
                  </a>
                  <button
                    class="btn btn-sm btn-danger mr-1"
                    @click="deleteGroup(scope.row.id)"
                    v-if="hasPermission($store.state.user, 'GROUP_MENU_WRITE')"
                  >
                    <i class="fa fa-times" />
                  </button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <paginate
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="handleCurrentChange"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
            style="padding: 0 1.25rem"
          ></paginate>
        </div>
      </div>

      <GroupModal :modalShow="modalGroupShow" @close="onModalClose" />
    </template>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import GroupModal from "../components/GroupModal";
import Paginate from "vuejs-paginate";

export default {
  components: {
    Paginate,
    GroupModal,
    Loader,
  },

  data() {
    return {
      loading: false,
      modalGroupShow: false,
      groups: [],
      currentPage: 1,
      limit: 10,
    };
  },

  computed: {
    paginatedGroups() {
      return this.groups.slice(
        this.currentPage * this.limit - this.limit,
        this.currentPage * this.limit
      );
    },

    pageCount() {
      return Math.ceil(this.groups.length / this.limit);
    },
  },

  async mounted() {
    await this.fetchGroups();
  },

  methods: {
    async fetchGroups() {
      this.loading = true;

      try {
        const { data } = await this.$api.get("/groups");

        this.groups = data;
      } catch (e) {
        console.warn(e);
      }

      this.loading = false;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
    },

    deleteGroup(id) {
      this.$confirm({
        message: "Attention, cette action est définitive.",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/groups/${id}`);

            this.groups = this.groups.filter((c) => !(c.id === id));
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    async onModalClose() {
      this.modalGroupShow = false;

      await this.fetchGroups();
    },

    groupHref(id, query) {
      const route = this.$router.resolve({
        name: "clientsGroup-details",
        params: {
          id,
        },
        query,
      });

      return route.href;
    },

    getPictureImage(id) {
      return `${process.env.VUE_APP_API_URL}groups/${id}/logo`;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-badge {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}
</style>

<template>
  <div
    v-if="!loading"
    class="row d-flex flex-wrap justify-content-center pt-1 animated fadeIn"
  >
    <div v-for="dataSet in dataSets" :key="dataSet.name" class="col-sm-4 mb-1">
      <div class="formula-stats-card">
        <div class="formula-stats-card__header">
          <img
            :src="getImgUrl(dataSet.background)"
            class="formula-stats-card__header__background"
          />
          <div class="formula-stats-card__header__filter"></div>
          <div class="formula-stats-card__header__title">
            <img :src="getImgUrl(dataSet.icon)" />
            <p class="mb-0 mt-1 h4">Mode {{ dataSet.mode }}</p>
          </div>
        </div>

        <div class="w-100 p-1 text-center">
          <div class="underlined pb-1">
            <strong>{{ dataSet.data.sessions }}</strong> cours
          </div>

          <div class="underlined py-1">
            <span v-if="dataSet.name !== 'solo'"
              ><strong>{{ dataSet.data.averageParticipants }}</strong>
              participant(s) / cours</span
            >
            <span v-else class="text-muted">1 participant(e) / cours</span>
          </div>

          <div class="pt-1">
            CA = <strong>{{ formatPrice(dataSet.data.ca, true) }}</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="formula-stats-card p-1 text-center font-weight-bold">
        <div class="underlined pt-1 pb-2 h4 text-yellow">Total</div>
        <div class="underlined py-1">
          <strong class="text-yellow">{{ totalSessions }}</strong> cours
        </div>
        <div class="underlined py-1">
          <strong class="text-yellow">{{ totalAverageParticipants }}</strong>
          participant(s) / cours
          <span
            class="fa fa-info-circle text-muted ml-05"
            v-tooltip="{ content: averageParticipantsInfo }"
          ></span>
        </div>
        <div class="pt-1">
          CA =
          <strong class="text-yellow">{{ formatPrice(totalCA, true) }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      cards: [
        {
          name: "live",
          mode: "Live",
          background: "live-background.jpg",
          icon: "video-camera-3.png",
        },
        {
          name: "small",
          mode: "Visio & Live",
          background: "visio-background.jpg",
          icon: "videoconference-2.png",
        },
        {
          name: "solo",
          mode: "Solo",
          background: "solo-background.jpg",
          icon: "webcam.png",
        },
      ],
    };
  },

  computed: {
    dataSets() {
      let dataSets = [];

      if (this.data) {
        for (const [key, value] of Object.entries(this.data)) {
          const card = this.cards.find((c) => c.name === key);

          if (card) {
            dataSets.push({ ...card, data: value });
          }
        }
      }

      return dataSets;
    },

    totalSessions() {
      let total = 0;

      this.dataSets.forEach((dataSet) => {
        total += dataSet.data.sessions;
      });

      return total;
    },

    totalAverageParticipants() {
      const averageLive =
        this.dataSets.find((dataSet) => dataSet.name === "live")?.data
          .averageParticipants || 0;
      const averageSmall =
        this.dataSets.find((dataSet) => dataSet.name === "small")?.data
          .averageParticipants || 0;

      return (averageLive + averageSmall) / 2;
    },

    totalCA() {
      let total = 0;

      this.dataSets.forEach((dataSet) => {
        total += dataSet.data.ca;
      });

      return total;
    },

    averageParticipantsInfo() {
      return "Cette moyenne ne prend en compte que les moyennes des séances Live et Visio, le mode Solo étant par définition individuel";
    },
  },

  methods: {
    getImgUrl(img) {
      return require(`@/assets/${img}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.formula-stats-card {
  border: solid 2px #f7f7f7;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 2px 2px 10px #f7f7f7;
  &__header {
    width: 100%;
    height: 150px;
    position: relative;
    &__background {
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__filter {
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      background-color: #060518;
      opacity: 0.5;
    }
    &__title {
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & img {
        width: 70px;
        height: auto;
      }
      & p {
        color: #ffc000;
        font-weight: bold;
      }
    }
  }
}

.underlined {
  position: relative;
  width: 100%;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #f7f7f7;
  }
}

.text-yellow {
  color: #ffc000;
}
</style>

<template>
  <modal
    title="Commentaire"
    v-model="show"
    cancelText="Annuler"
    okText="Enregistrer"
    effect="fade/zoom"
    @ok="updateComment"
    @cancel="cancel"
  >
    <loader :is-visible="loading" />

    <div v-if="error" class="alert alert-danger">
      Une erreur est survenue lors de la modification du commentaire
    </div>

    <template v-if="client">
      <textarea
        v-if="isProspect"
        v-model="client.prospect.note"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>

      <textarea
        v-else
        v-model="client.commercialNotes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </template>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    client: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      error: false,

      originalNote: null,
    };
  },

  computed: {
    isProspect() {
      return this.client?.prospect?.isProspect;
    },
  },

  watch: {
    show(opened) {
      if (opened && this.client) {
        const originalNote = this.isProspect
          ? this.client.prospect.note
          : this.client.commercialNotes;

        this.originalNote = originalNote?.length > 1 ? originalNote : "";
      }
    },
  },

  methods: {
    async updateComment() {
      if (this.client) {
        let data = {
          email: this.client.email,
        };

        if (this.isProspect) {
          data = {
            ...data,
            prospect: { note: this.client.prospect.note },
          };
        } else {
          data = {
            ...data,
            commercialNotes: this.client.commercialNotes,
          };
        }

        this.error = false;
        this.loading = true;

        try {
          await this.$api.put("/clients", data);

          this.close();
        } catch (e) {
          this.error = true;
        } finally {
          this.loading = false;
        }
      }
    },

    resetNote() {
      if (this.client) {
        if (this.isProspect) {
          this.client.prospect.note = this.originalNote;
        } else {
          this.client.commercialNotes = this.originalNote;
        }
      }
    },

    cancel() {
      this.resetNote();
      this.close();
    },

    close() {
      this.error = false;

      this.$emit("close");
    },
  },
};
</script>

<template>
  <div>
    <div class="card">
      <div class="card-header">
        Urssaf
        <i class="icon-doc"></i>
        <div class="float-right">
          <button class="btn btn-primary btn-sm" @click="toggleModal">
            {{
              requestIsSend ? "Modifier la demande" : "Demande d'inscription"
            }}
          </button>
        </div>
      </div>
      <div class="card-block" v-if="requestIsSend">
        <div class="row">
          <div class="col-sm-12 mb-1">
            <span class="font-weight-bold">Inscription envoyée ✅</span>
          </div>
          <div class="col-sm-3 font-weight-bold">Id client :</div>
          <div class="col-sm-9 text-right">
            {{ client.urssafInscription.idClient }}
          </div>
          <div class="col-sm-4 font-weight-bold">
            Inscription confirmée
            <span
              title="Validation manuelle, confirmer avec le client."
              class="fa fa-info-circle"
            ></span>
            :
          </div>
          <div class="col-sm-8 text-right">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="client.urssafInscription.valid"
                @click="toggleValidation"
              />
              <span class="switch-label" data-on="Oui" data-off="Non"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <modal
      title="Inscription Urssaf"
      class="custom-modal"
      large
      v-model="modalOpened"
      @ok="sendInscriptionForm()"
      effect="fade/zoom"
      cancelText="Fermer"
      okText="Envoyer la demande"
      :backdrop="false"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Inscription Urssaf</h4>
      </div>
      <div class="row">
        <div class="col-sm-12" v-if="formError">
          <div class="alert alert-danger">
            {{ formError }}
          </div>
        </div>
        <div class="form-group has-danger col-sm-3">
          <label>Civilité *</label>
          <multiselect
            v-model="form.civilite"
            :options="civilities"
            label="label"
            track-by="value"
            class="custom-multiselect"
            deselectLabel="X"
            selectLabel=""
          />
        </div>
        <div class="form-group col-sm-3">
          <label>Nom de Naissance *</label>
          <input type="text" class="form-control" v-model="form.nomNaissance" />
        </div>
        <div class="form-group col-sm-3">
          <label>Nom d'Usage *</label>
          <input type="text" class="form-control" v-model="form.nomUsage" />
        </div>
        <div class="form-group col-sm-3">
          <label>Prénom(s) *</label>
          <input type="text" class="form-control" v-model="form.prenoms" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Date de naissance *</label>
          <el-date-picker
            style="display: block; padding-top: 2px"
            v-model="form.dateNaissance"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            type="date"
            size="small"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Pays de naissance *</label>
          <multiselect
            v-model="form.paysNaissance"
            :options="codeInseePays"
            label="label"
            deselectLabel="X"
            selectLabel=""
            placeholder=""
          />
        </div>
        <template v-if="form.paysNaissance.value === '99100'">
          <div class="form-group col-sm-4">
            <label>Dpt. de naissance *</label>
            <multiselect
              v-model="form.departementNaissance"
              :options="getDepartments"
              label="label"
              :searchable="true"
              deselectLabel="X"
              selectLabel=""
              placeholder="Séléctionner un département"
            >
              <template v-slot:noOptions>Rechercher un département.</template>
              <template v-slot:noResult>Aucun département trouvé.</template>
            </multiselect>
          </div>
          <div class="form-group col-sm-4">
            <label>Lieu de naissance *</label>
            <multiselect
              v-model="form.communeNaissance"
              :options="cities"
              label="label"
              :searchable="true"
              :internal-search="false"
              @search-change="searchCities"
              deselectLabel="X"
              selectLabel=""
              placeholder="Séléctionner un lieu de naissance"
            >
              <template v-slot:noOptions>
                Rechercher un lieu de naissance.
              </template>
              <template v-slot:noResult
                >Aucun lieu de naissance trouvé.</template
              >
            </multiselect>
          </div>
          <div v-if="form.communeNaissance" class="form-group col-sm-2">
            <label>Code comm. Naiss.</label>
            <input
              type="text"
              class="form-control"
              v-model="form.communeNaissance.code"
            />
          </div>
        </template>
        <div v-else class="form-group col-sm-4" />

        <div class="form-group col-sm-3">
          <label>Téléphone portable *</label>
          <input
            type="text"
            class="form-control"
            v-model="form.numeroTelephonePortable"
          />
        </div>
        <div class="form-group col-sm-3">
          <label>Email *</label>
          <input type="email" class="form-control" v-model="form.adresseMail" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-2">
          <h5>Adresse</h5>
          <div class="alert alert-warning">
            L'adresse doit être normalisée pour coincider aux informations de
            l'URSSAF, ne pas répéter les informations dans le champ "libelle de
            voie". <br />
            Si un "numéro de voie" est saisie, il faut obligatoirement saisir un
            "type de voie" ou "complément d'adresse" ou "lieu dit"
          </div>
          <div class="alert alert-info">
            L'adresse dans la fiche de l'utilisateur est {{ client.address }} -
            {{ client.zipcode }} - {{ client.city }}
          </div>
        </div>

        <div class="col-sm-2 form-group">
          <label>Numero de voie</label>
          <input
            type="text"
            class="form-control"
            v-model="form.adressePostale.numeroVoie"
          />
        </div>
        <div class="col-sm-2 form-group">
          <label>Lettre de voie</label>
          <multiselect
            v-model="form.adressePostale.lettreVoie"
            :options="lettresVoie"
            label="label"
            track-by="value"
            class="custom-multiselect"
            deselectLabel="X"
            selectLabel=""
          />
        </div>
        <div class="col-sm-3 form-group">
          <label>Type de voie</label>
          <multiselect
            v-model="form.adressePostale.codeTypeVoie"
            :options="codesTypeVoie"
            label="label"
            track-by="code"
            class="custom-multiselect"
            deselectLabel="X"
            selectLabel=""
          />
        </div>
        <div class="col-sm-5 form-group">
          <label>Adresse (Libelle de voie)</label>
          <input
            type="text"
            class="form-control"
            v-model="form.adressePostale.libelleVoie"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 form-group">
          <label>Complément d'adresse</label>
          <input
            type="text"
            class="form-control"
            v-model="form.adressePostale.complement"
          />
        </div>
        <div class="col-sm-2 form-group">
          <label>Code postal *</label>
          <input
            type="text"
            class="form-control"
            v-model="form.adressePostale.codePostal"
          />
        </div>
        <div class="col-sm-4 form-group">
          <label>Commune *</label>
          <multiselect
            v-model="form.adressePostale.commune"
            :options="citiesWithCp"
            label="label"
            :searchable="true"
            :internal-search="false"
            @search-change="searchCitiesWithCp"
            deselectLabel="X"
            selectLabel=""
            placeholder="Séléctionner une commune"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 form-group">
          <label>Lieu dit</label>
          <input
            type="text"
            class="form-control"
            v-model="form.adressePostale.lieuDit"
          />
        </div>
        <div
          class="col-sm-4 offset-sm-2 form-group"
          v-if="form.adressePostale.commune"
        >
          <label>Code commune (si arrondissement)</label>
          <input
            type="text"
            class="form-control"
            v-model="form.adressePostale.commune.code"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-2">
          <h5>Informations bancaires</h5>
        </div>
        <div class="form-group col-sm-2">
          <label>BIC *</label>
          <input
            type="text"
            class="form-control"
            placeholder="AGRIFRPP"
            v-model="form.coordonneeBancaire.bic"
          />
        </div>
        <div class="form-group col-sm-6">
          <label>IBAN *</label>
          <input
            type="text"
            class="form-control"
            placeholder="FR7630006000011234567890189"
            v-model="form.coordonneeBancaire.iban"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Titulaire (Nom Prénoms)*</label>
          <input
            type="text"
            class="form-control"
            :placeholder="`${form.nomNaissance} ${form.prenoms}`"
            v-model="form.coordonneeBancaire.titulaire"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Multiselect from "vue-multiselect";

import debounce from "lodash.debounce";

import { mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
    Modal,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalOpened: false,
      form: {},
      civilities: [
        { label: "Monsieur", value: "1" },
        { label: "Madame", value: "2" },
      ],
      citiesWithCp: [],
      cities: [],
      dpts: [],
      formError: undefined,
    };
  },

  async created() {
    this.form = this.client.urssafInscription?.form || {
      civilite:
        this.client.gender && this.client.gender === "Homme"
          ? this.civilities[0]
          : this.civilities[1],
      nomNaissance: this.client.lastName,
      nomUsage: this.client.lastName,
      prenoms: this.client.firstName,
      paysNaissance: { label: "France", value: "99100" },
      departementNaissance: undefined,
      communeNaissance: undefined,
      dateNaissance: this.client.birthDate,
      adressePostale: {
        numeroVoie: undefined,
        lettreVoie: undefined,
        codeTypeVoie: undefined,
        libelleVoie: undefined,
        complement: undefined,
        lieuDit: undefined,
        commune: undefined,
        codePostal: this.client.zipcode || "",
      },
      numeroTelephonePortable: this.client.phone?.replace(/\s/g, ""),
      adresseMail: this.client.usedEmail,
      coordonneeBancaire: {
        bic: undefined,
        iban: undefined,
        titulaire: `${this.client.lastName} ${this.client.firstName}`,
      },
    };

    if (this.form.adressePostale.codePostal) {
      await this.fetchCitiesOnlyCp();
    }
  },

  methods: {
    toggleModal() {
      this.modalOpened = !this.modalOpened;
    },

    async sendInscriptionForm() {
      if (!this.formIsValid()) {
        this.formError = "Pensez à bien remplir les champs obligatoires *";

        return;
      }

      try {
        const { data } = await this.$api.post("/urssaf/inscription", {
          clientEmail: this.client.email,
          form: {
            ...this.form,
            dateNaissance: new Date(this.form.dateNaissance).toISOString(),
          },
        });

        this.client.urssafInscription = data;

        this.formError = undefined;
        this.modalOpened = false;
      } catch (e) {
        console.log(e);
        this.formError = e;
      }
    },

    formIsValid() {
      const {
        civilite,
        nomNaissance,
        nomUsage,
        prenoms,
        dateNaissance,
        numeroTelephonePortable,
        adresseMail,
        coordonneeBancaire,
        adressePostale,
        paysNaissance,
        departementNaissance,
        communeNaissance,
      } = this.form;

      return (
        civilite.value &&
        nomNaissance &&
        nomUsage &&
        prenoms &&
        dateNaissance &&
        numeroTelephonePortable &&
        adresseMail &&
        coordonneeBancaire.iban &&
        paysNaissance?.value &&
        (paysNaissance?.value !== "99100" ||
          (departementNaissance?.value && communeNaissance?.value)) &&
        adressePostale.commune?.code &&
        coordonneeBancaire.bic &&
        coordonneeBancaire.titulaire
      );
    },

    async toggleValidation() {
      try {
        await this.$api.post("/urssaf/inscription-validation", {
          clientEmail: this.client.email,
          valid: !this.client.urssafInscription.valid,
        });
      } catch (e) {
        this.formError = e;
      }
    },

    async searchCities(search) {
      if (search !== "" && search.length > 2) {
        await this.fetchCities(search, this);
      }
    },

    fetchCities: debounce(async (terms, vm) => {
      const { data } = await vm.$api.get(`https://geo.api.gouv.fr/communes`, {
        params: {
          nom: terms,
          fields: "nom,code,departement",
          format: "json",
          geometry: "centre",
        },
      });

      vm.cities = data.map((city) => ({
        label: `${city.nom} (${city.departement?.code})`,
        value: city.nom,
        code: city.code,
      }));
    }, 300),

    async searchCitiesWithCp(search) {
      if (search !== "" && search.length > 2) {
        await this.fetchCitiesWithCp(search, this);
      }
    },

    fetchCitiesWithCp: debounce(async (terms, vm) => {
      const { data } = await vm.$api.get(`https://geo.api.gouv.fr/communes`, {
        params: {
          nom: terms,
          codePostal: vm.form.adressePostale?.codePostal,
          fields: "nom,code,departement",
          format: "json",
          geometry: "centre",
        },
      });

      vm.citiesWithCp = data.map((city) => ({
        label: `${city.nom} (${city.departement?.code})`,
        value: city.nom,
        code: city.code,
      }));
    }, 300),

    async fetchCitiesOnlyCp() {
      const { data } = await this.$api.get(`https://geo.api.gouv.fr/communes`, {
        params: {
          codePostal: this.form.adressePostale?.codePostal,
          fields: "nom,code,departement",
          format: "json",
          geometry: "centre",
        },
      });

      this.citiesWithCp = data.map((city) => ({
        label: `${city.nom} (${city.departement?.code})`,
        value: city.nom,
        code: city.code,
      }));
    },
  },

  computed: {
    ...mapGetters({
      codesTypeVoie: "getCodesTypeVoie",
      lettresVoie: "getLettresVoie",
      codeInseePays: "getCodeInseePays",
      departments: "getDepartments",
    }),

    requestIsSend() {
      return (
        this.client.urssafInscription &&
        (this.client.urssafInscription.status !== "pending" ||
          this.client.urssafInscription.idClient)
      );
    },

    getDepartments() {
      return this.departments.map((dpt) => ({
        label: `${dpt.name} (${dpt.code})`,
        value: dpt.code?.length === 2 ? "0" + dpt.code : dpt.code,
      })).filter((dpt) => dpt.value !== "all");
    }
  },
};
</script>

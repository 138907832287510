<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-target"></i> Coachs

      <button
        type="button"
        class="btn btn-sm btn-danger float-right"
        @click="deleteAttribution()"
        v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
      >
        <i class="icon-close"></i> Terminer l'attribution
      </button>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label">Filtrer par appli : </label>
            <select
              v-model="form.filterAppli"
              v-on:change="refreshCoachs()"
              class="form-control"
            >
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </select>
          </div>

          <div class="form-group">
            <label class="control-label">Filtrer par genre :</label>
            <select
              v-model="form.filterGender"
              v-on:change="refreshCoachs()"
              class="form-control"
            >
              <option :value="null">Tout</option>
              <option value="Homme">Homme</option>
              <option value="Femme">Femme</option>
            </select>
          </div>

          <div class="form-group" v-if="attributionCoachStatus">
            <label class="control-label">Filtrer par statut :</label>
            <multiselect
              placeholder="Choisir des valeurs"
              v-model="form.filterStatus"
              :options="attributionCoachStatus"
              label="label"
              track-by="label"
              class="custom-multiselect"
              :multiple="true"
              @input="refreshCoachs()"
            >
            </multiselect>
          </div>

          <div class="form-group">
            <label class="control-label"
              >Coachs sportifs uniquement :
              <i
                class="fa fa-info-circle ml-05"
                v-tooltip="{
                  content:
                    'Permet de filtrer les prestataires qui ne sont pas à proprement parler des coachs sportifs (ex: masseurs, diététiciens, profs de méditation, etc.)',
                  placement: 'right',
                }"
              ></i>
            </label>
            <select
              v-model="form.filterSportsCoachOnly"
              v-on:change="refreshCoachs"
              class="form-control"
            >
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </select>
          </div>

          <div class="form-group" v-if="prestations">
            <label class="control-label">Filtrer par prestation :</label>
            <multiselect
              placeholder="Choisir des valeurs"
              v-model="form.filterPrestation"
              :options="prestations"
              label="label"
              track-by="label"
              class="custom-multiselect"
              :multiple="true"
              @input="refreshCoachs"
            >
            </multiselect>
          </div>

          <div class="form-group" v-if="tags">
            <label class="control-label">Filtrer par tags :</label>
            <multiselect
              placeholder="Choisir des valeurs"
              v-model="form.filterTags"
              :options="tags"
              label="label"
              track-by="label"
              class="custom-multiselect"
              :multiple="true"
              @input="refreshCoachs()"
            >
            </multiselect>
          </div>

          <div class="form-group" v-if="langs">
            <label class="control-label">Filtrer par langues :</label>
            <select
              v-model="form.filterLang"
              v-on:change="refreshCoachs()"
              class="form-control"
            >
              <option :value="null">Aucune</option>
              <option v-for="lang in langs" :key="lang.name" :value="lang.name">
                {{ lang.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label">Filtrer par Live activé</label>
            <div>
              <select
                v-model="form.filterLive"
                v-on:change="refreshCoachs()"
                class="form-control"
              >
                <option value="1">Oui</option>
                <option value="0">Non</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">Activer la disponibilité</label>
            <select
              v-model="form.filterAvailability"
              v-on:change="refreshCoachs()"
              class="form-control"
            >
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </select>
          </div>
          <div class="form-group" v-if="form.filterAvailability === '1'">
            <div class="row">
              <div class="col-sm-4">
                <label class="control-label">Jour</label>
                <select v-model="period.index" class="form-control">
                  <option :value="0">Lundi</option>
                  <option :value="1">Mardi</option>
                  <option :value="2">Mercredi</option>
                  <option :value="3">Jeudi</option>
                  <option :value="4">Vendredi</option>
                  <option :value="5">Samedi</option>
                  <option :value="6">Dimanche</option>
                </select>
              </div>
              <div class="col-sm-3">
                <label class="control-label">Début</label>
                <select v-model="period.start" class="form-control">
                  <option
                    v-for="hour in [
                      6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                      21, 22, 23,
                    ]"
                    :key="hour"
                    :value="hour"
                  >
                    {{ hour }}:00
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label class="control-label">Fin</label>
                <select v-model="period.end" class="form-control">
                  <option
                    v-for="hour in [
                      6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                      21, 22, 23,
                    ]"
                    :key="hour"
                    :value="hour"
                  >
                    {{ hour }}:00
                  </option>
                </select>
              </div>
              <div class="col-sm-1">
                <label class="control-label">&nbsp;</label>
                <button
                  class="btn btn-primary"
                  @click="addPeriod"
                  :disabled="
                    !hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                  "
                >
                  <i class="icon-plus"></i>
                </button>
              </div>
            </div>
            <template v-if="form.periods">
              <div
                class="row periods"
                v-for="(period, index) in form.periods"
                :key="index"
              >
                <div class="col-sm-4">
                  <label class="control-label"
                    ><strong>{{ indexToDay(period.index) }}</strong></label
                  >
                </div>
                <div class="col-sm-6">
                  <label class="control-label"
                    ><strong
                      >{{ period.start }}:00 &rarr; {{ period.end }}:00</strong
                    ></label
                  >
                </div>
                <div class="col-sm-1">
                  <button
                    class="btn btn-danger btn-sm"
                    @click="removePeriod(index)"
                    :disabled="
                      !hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                    "
                  >
                    <i class="icon-close"></i>
                  </button>
                </div>
              </div>
            </template>
          </div>

          <div class="form-group">
            <label class="control-label">Activer le rayon d'action</label>
            <select
              v-model="form.filterDelayed"
              v-on:change="refreshCoachs()"
              class="form-control"
            >
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </select>
          </div>

          <div class="form-group" v-if="order && setCredit">
            <label>Credit <u>par séances</u></label>
            <div class="row">
              <div class="col-sm-6">
                <select
                  v-model="form.credit"
                  @input="updateCredit()"
                  class="form-control"
                >
                  <option value="0">0€</option>
                  <option value="15.00">15.00€</option>
                  <option value="20.00">20.00€</option>
                  <option value="27.50">27.50€</option>
                  <option value="30">30€</option>
                  <option value="32.50">32.50€</option>
                  <option value="manual">Autre</option>
                </select>
              </div>
              <div class="input-group col-sm-6" v-if="form.credit == 'manual'">
                <input
                  v-model="form.creditManual"
                  type="text"
                  @input="updateCredit()"
                  class="form-control"
                />
                <span class="input-group-addon"
                  ><i class="fa fa-euro"></i
                ></span>
              </div>
            </div>
            <div class="row save-ctn">
              <div class="col-md-2 col-sm-4">
                <button
                  class="btn btn-primary"
                  @click="() => refreshCoachs()"
                  v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
                >
                  <i class="fa fa-refresh"></i>
                  <span class="hidden-md-down"> Rafraichir</span>
                </button>
              </div>
              <div class="col-md-4 col-sm-4">
                <button
                  class="btn btn-danger"
                  @click="() => cleanCoachs()"
                  v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
                >
                  <i class="icon-trash"></i>
                  <span class="hidden-md-down"> Clean Coachs</span>
                </button>
              </div>
              <div class="col-md-6 col-sm-4">
                <button
                  class="btn btn-secondary"
                  @click="addSelectedCoachs()"
                  v-if="
                    coachs &&
                    coachs.available &&
                    coachs.available.length &&
                    hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                  "
                  :disabled="addingSelectedCoachs"
                >
                  <i class="icon-plus"></i> Ajouter tous les coachs
                </button>
              </div>
            </div>
            <p class="help-block">
              Pour cette commande la séance est à
              {{ formatPrice(order.price / order.session) || "**" }} TTC.
            </p>
          </div>

          <AttributionReminderOptions
            v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
            :attribution="attribution"
            :coachs="queuedCoachs"
            :credit="form.creditManual"
            class="mt-3"
          />
        </div>
        <div class="col-sm-6">
          <table class="table table-condensed">
            <thead v-if="loading">
              <th colspan="3">Chargement des coachs en cours ...</th>
            </thead>
            <template v-else>
              <thead>
                <tr>
                  <th class="text-center" colspan="3" v-if="coachs">
                    <h5>{{ coachs.length }} coachs</h5>
                    <p>
                      <span
                        v-if="acceptedCoachs.length"
                        class="text-success count-info"
                      >
                        <i class="icon-user-following"></i>
                        {{ acceptedCoachs.length }}
                      </span>
                      <span
                        v-if="rejectedCoachs.length"
                        class="text-danger count-info"
                      >
                        <i class="icon-user-unfollow"></i>
                        {{ rejectedCoachs.length }}
                      </span>
                      <span
                        v-if="queuedCoachs.length"
                        class="text-info count-info"
                      >
                        <i class="icon-user-following"></i>
                        {{ queuedCoachs.length }}
                      </span>
                      <span v-if="availableCoachs.length" class="count-info">
                        <i class="icon-user"></i>
                        {{ availableCoachs.length }}
                      </span>
                    </p>
                  </th>
                </tr>
                <tr>
                  <th colspan="3">
                    <input
                      class="form-control"
                      v-model="filterCoach"
                      placeholder="Filter par nom"
                      type="text"
                    />
                  </th>
                </tr>
              </thead>
              <attribution-filters-coach
                v-for="coach in acceptedCoachs"
                :key="coach.id"
                :coach="coach"
                :credit="filteredCredit"
                :accepted="true"
                @unassign="unassignCoach(coach)"
                @assign="assignCoach(coach, filteredCredit)"
              />
              <attribution-filters-coach
                v-for="coach in rejectedCoachs"
                :key="coach.id"
                :coach="coach"
                :credit="filteredCredit"
                :rejected="true"
              />
              <attribution-filters-coach
                v-for="coach in queuedCoachs"
                :key="coach.id"
                :coach="coach"
                :credit="filteredCredit"
                :queued="true"
                @force="assignCoachConfirm(coach, filteredCredit)"
                @remove="removeCoach(coach)"
              />
              <attribution-filters-coach
                v-for="coach in availableCoachs"
                :key="coach.id"
                :coach="coach"
                :credit="filteredCredit"
                @add="addCoach(coach)"
              />
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Langs from "../assets/json/langs.json";
import AttributionFiltersCoach from "./AttributionFiltersCoach";
import Multiselect from "vue-multiselect";
import AttributionReminderOptions from "../components/AttributionReminderOptions";

import { mapGetters } from "vuex";
import debounce from "lodash.debounce";

export default {
  components: {
    AttributionFiltersCoach,
    Multiselect,
    AttributionReminderOptions,
  },
  props: {
    attribution: {
      type: Object,
      default: null,
    },
    order: {
      type: Object,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
    clientAttributions: {
      type: Array,
      default: null,
    },
    setCredit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      addingSelectedCoachs: false,
      prestations: [],
      tags: [],
      coachs: {
        queue: [],
        accepted: [],
        rejected: [],
        available: [],
      },
      filterCoach: null,
      form: {
        filterDelayed: "1",
        filterAppli: "0",
        filterGender: null,
        filterStatus: [],
        filterSportsCoachOnly: "1",
        filterPrestation: [],
        filterTags: [],
        filterLang: null,
        credit: "20.00",
        creditManual: "0.00",
        filterLive: "0",
        filterAvailability: "0",
        periods: null,
      },
      period: {
        index: 0,
        start: null,
        end: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      coachStatus: "getCoachStatus",
    }),

    attributionCoachStatus() {
      return this.coachStatus.filter(
        ({ value }) => value !== "suspended" && value !== "imported"
      );
    },

    langs() {
      return Langs;
    },

    queuedCoachs() {
      return this.filteredAttributionCoachs(this.coachs.queue);
    },

    acceptedCoachs() {
      return this.filteredAttributionCoachs(this.coachs.accepted);
    },

    rejectedCoachs() {
      return this.filteredAttributionCoachs(this.coachs.rejected);
    },

    availableCoachs() {
      return this.filteredAttributionCoachs(this.coachs.available);
    },

    filteredCredit() {
      let credit = null;

      if (this.setCredit) {
        credit =
          this.form.credit === "manual"
            ? this.form.creditManual
            : this.form.credit;
        credit = parseFloat(credit.replace(",", ".")).toFixed(2) * 100;
      }

      return credit;
    },
  },

  async created() {
    const { data } = await this.$api.get("/settings");

    if (data["intervenants_prestations"]) {
      this.prestations = data["intervenants_prestations"].map((prestation) => ({
        label: prestation,
        value: prestation,
      }));
    }

    if (data["intervenants_tags"]) {
      this.tags = data["intervenants_tags"].map((tag) => ({
        label: tag,
        value: tag,
      }));
    }

    if (this.attribution.filters) {
      this.form.filterAppli = this.attribution.filters.appli ? "1" : "0";
      this.form.filterGender = this.attribution.filters.gender;
      this.form.filterSportsCoachOnly = this.attribution.filters.sportsCoachOnly
        ? "1"
        : "0";
      this.form.filterPrestation = this.attribution.filters.prestations
        ? this.attribution.filters.prestations.map((t) => ({
            label: t,
            value: t,
          }))
        : [];
      this.form.filterTags = this.attribution.filters.tags
        ? this.attribution.filters.tags.map((t) => ({ label: t, value: t }))
        : [];
      this.form.filterLang = this.attribution.filters.lang;
      this.form.filterDelayed = this.attribution.filters.range ? "1" : "0";
      this.form.filterLive = this.attribution.filters.live ? "1" : "0";
      this.form.filterAvailability = this.attribution.filters.availability
        ? "1"
        : "0";
      this.form.periods = this.attribution.filters.periods;

      if (this.setCredit && undefined !== this.attribution.filters.credit) {
        this.form.credit = "manual";
        this.form.creditManual = (
          this.attribution.filters.credit / 100
        ).toFixed(2);
      }

      if (this.attribution.filters.status?.length > 0) {
        this.attribution.filters.status.forEach((status) => {
          if (!this.form.filterStatus.some((s) => s.value === status)) {
            const statusToPush = this.attributionCoachStatus.find(
              (s) => s.value === status
            );

            if (statusToPush) {
              this.form.filterStatus.push(statusToPush);
            }
          }
        });
      }
    }

    await this.refreshCoachs();
  },

  methods: {
    async refreshCoachs() {
      this.loading = true;
      const { data } = await this.$api.get(
        `/attributions/${this.attribution.id}/coachs`,
        {
          params: {
            email: this.client.email,
            appli: this.form.filterAppli,
            gender: this.form.filterGender,
            status: this.form.filterStatus.map((s) => s.value),
            sportsCoachOnly: this.form.filterSportsCoachOnly,
            prestations: this.form.filterPrestation.map((t) => t.value),
            tags: this.form.filterTags.map((t) => t.value),
            lang: this.form.filterLang,
            range: this.form.filterDelayed,
            credit: this.filteredCredit,
            live: this.form.filterLive,
            availability: this.form.filterAvailability,
            periods: this.form.periods,
          },
        }
      );

      this.loading = false;
      this.coachs = data;

      await this.updateAttributedCoachs();
    },

    assignCoachConfirm(coach, credit) {
      this.$confirm({
        message: "Attribuer le coach sans attendre la réponse ?",
        onConfirm: async () => {
          const { data } = await this.$api.patch(
            `/attributions/coach/${this.attribution.id}/attribute`,
            {
              email: coach.email,
            }
          );

          this.coachs.queue = this.coachs.queue.filter(
            (c) => c.id !== coach.id
          );
          this.coachs.accepted.push(data);

          await this.assignCoach(coach, credit);
        },
      });
    },

    coachHasClient(coach) {
      return this.client.coachs.find((c) => c.id === coach.id);
    },

    async addCoach(coach) {
      const { data } = await this.$api.post(
        `/attributions/coach/${this.attribution.id}`,
        {
          email: coach.email,
        }
      );

      this.coachs.available = this.coachs.available.filter(
        (c) => c.id !== coach.id
      );

      this.coachs.queue.push(data);
    },

    async removeCoach(coach) {
      await this.$api.delete(`/attributions/coach/${this.attribution.id}`, {
        data: {
          email: coach.email,
        },
      });

      this.coachs.queue = this.coachs.queue.filter((c) => c.id !== coach.id);
    },

    async assignCoach(coach, credit) {
      if (this.setCredit === false) {
        credit = null;
      }

      this.$emit("assign", coach, credit);
    },

    async unassignCoach(coach) {
      const { data } = await this.$api.patch(
        `/attributions/coach/${this.attribution.id}/reject`,
        {
          email: coach.email,
        }
      );

      this.coachs.accepted = this.coachs.accepted.filter(
        (c) => c.id !== coach.id
      );
      this.coachs.rejected.push(data);
    },

    cleanCoachs() {
      this.$confirm({
        message: "Supprimer l'attribution pour tous les coachs ?",
        onConfirm: async () => {
          await this.$api.delete(`/attributions/coachs/${this.attribution.id}`);

          this.coachs = {
            queue: [],
            accepted: [],
            rejected: [],
            available: [],
          };
          await this.refreshCoachs();
        },
      });
    },

    async addSelectedCoachs() {
      this.addingSelectedCoachs = true;

      const emails = this.coachs.available.map((c) => c.email);

      const { data } = await this.$api.post(
        `/attributions/coachs/${this.attribution.id}`,
        {
          emails,
        }
      );

      this.coachs.available = this.coachs.available.filter(
        (c) => !emails.includes(c.email)
      );

      this.coachs.queue.push(...data);

      this.$toast.success("Les coachs ont été ajoutés à l'attribution");

      this.addingSelectedCoachs = false;
    },

    deleteAttribution() {
      this.$confirm({
        message: "Attention, cette action est irreversible !",
        onConfirm: async () => {
          await this.$api.delete(`/attributions/${this.attribution.id}`);

          this.$router.push({ path: "/attributions" }, () => {
            window.location.reload(true);
          });
        },
      });
    },

    updateCredit() {
      this.$nextTick(
        debounce(() => {
          if (this.filteredCredit) {
            this.$api.post(`/attributions/credits/${this.attribution.id}`, {
              credit: this.filteredCredit,
            });
          }
        }, 1000)
      );
    },

    addPeriod() {
      if (
        this.form.filterAvailability !== "1" ||
        !this.period.start ||
        !this.period.end
      ) {
        return;
      }

      this.form.periods = this.form.periods || [];
      this.form.periods.push(this.period);

      this.period = {
        index: 0,
        start: null,
        end: null,
      };

      this.refreshCoachs();
    },

    removePeriod(index) {
      this.form.periods = this.form.periods || [];
      this.form.periods.splice(index, 1);

      this.refreshCoachs();
    },

    filteredAttributionCoachs(coachs) {
      if (this.filterCoach) {
        var regex = new RegExp(this.filterCoach, "i");
        coachs = coachs.filter((c) => c.name.match(regex));
      }

      return coachs.sort((a, b) => {
        if (a.distanceSort === undefined) {
          return 1;
        }

        if (b.distanceSort === undefined) {
          return -1;
        }

        return a.distanceSort > b.distanceSort
          ? 1
          : b.distanceSort > a.distanceSort
          ? -1
          : 0;
      });
    },

    async updateAttributedCoachs() {
      if (
        this.clientAttributions &&
        this.clientAttributions.length > 0 &&
        this.coachs &&
        this.coachs.accepted &&
        this.coachs.accepted.length > 0
      ) {
        const attributedCoachs = [
          ...new Set(this.clientAttributions.map((c) => c.user)),
        ];

        for (const coach of attributedCoachs) {
          const key = this.coachs.accepted.findIndex(
            (coachAccepted) => coachAccepted.email === coach
          );

          if (key !== -1) {
            this.coachs.accepted[key].attributed = true;
          }
        }
      }
    },
  },

  watch: {
    clientAttributions() {
      this.updateAttributedCoachs();
    },
  },
};
</script>

<style scoped lang="scss">
.periods {
  margin-top: 15px;
}

.periods label {
  padding: 5px;
}

.count-info {
  margin: 0 5px;
}

.switch-custom {
  float: right;
}

.custom-multiselect {
  .multiselect__tags {
    border-radius: 0;
    text-transform: capitalize;
    color: #9faecb;
  }
}

.save-ctn {
  padding: 15px 0px;
}
</style>

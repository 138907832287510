<template>
  <div class="animated fadeIn">
    <MarketingKpiFilters :default="filters" @updateFilter="handleFilters" />
    <MarketingKpiStats v-if="!loading" :data="stats" />
    <loader :isVisible="loading" />
  </div>
</template>

<script>
import moment from "moment-timezone";

import MarketingKpiFilters from "../components/MarketingKpiFilters";
import MarketingKpiStats from "../components/MarketingKpiStats";
import Loader from "../components/Loader";

export default {
  components: {
    MarketingKpiFilters,
    MarketingKpiStats,
    Loader,
  },

  data() {
    return {
      stats: {},
      filters: {
        begin: moment().startOf("y").format(),
        end: moment().endOf("y").format(),
        discovery: true,
        marketEstablishment: false,
      },
      loading: false,
    };
  },

  methods: {
    async handleFilters(filters) {
      this.filters = filters;

      await this.loadData();
    },

    async loadData() {
      this.loading = true;

      const { begin, end, discovery, marketEstablishment } = this.filters;
      const { data } = await this.$api.get("/marketings/kpi", {
        params: {
          begin,
          end,
          discovery: discovery ? 1 : 0,
          marketEstablishment: marketEstablishment ? 1 : 0,
        },
      });

      this.stats = data;

      this.loading = false;
    },
  },
};
</script>

<template>
  <div v-if="coach">
    <router-link :to="'/users/' + coach.id" :class="cssClass">
      {{ coach.coach.name }}
    </router-link>
    <a v-if="coach" @click="goToUser" href="#">
      <i class="icon-user"> </i>
    </a>
  </div>
</template>

<script>
export default {
  name: "DisplayCoachName",
  props: {
    coach: {
      type: Object,
      required: true,
    },
    cssClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    goToUser(e) {
      e.preventDefault();

      if (this.coach) {
        const route = this.$router.resolve({
          name: "user-details",
          params: {
            id: this.coach.id,
          },
        });

        window.open(route.href, "_blank");
      }
    },
  },
};
</script>

<template>
  <div>
    <div v-if="promoCodes.length === 0" class="text-center text-muted">
      Aucun code promo
    </div>

    <table v-else class="table table-condensed">
      <thead>
        <tr>
          <th>Code</th>
          <th>Cible</th>
          <th>Taux Commission</th>
          <th>Nbre Ventes</th>
          <th>CA Généré</th>
          <th v-if="!showOnlyAvailable">Commission totale</th>
          <th v-if="isInfluencerAccount">Commission disponible</th>
          <th v-if="showWithdrawButton"></th>
        </tr>
      </thead>
      <tr v-for="(promoCode, index) in promoCodes" :key="index">
        <td>{{ promoCode.code }}</td>
        <td>{{ promoCode.target }}</td>
        <td>
          <template
            v-if="
              promoCode.influencerDiscount && promoCode.influencerDiscount.euros
            "
          >
            {{ formatPrice(promoCode.influencerDiscount.euros, true) }}
          </template>
          <template
            v-else-if="
              promoCode.influencerDiscount &&
                promoCode.influencerDiscount.percentage
            "
          >
            {{ promoCode.influencerDiscount.percentage }}%
          </template>
          <template v-else>Non Renseigné</template>
        </td>
        <td>
          {{
            promoCode.stripe.coupon.times_redeemed
              ? promoCode.stripe.coupon.times_redeemed
              : 0
          }}
        </td>
        <td>{{ formatPrice(promoCode.totalCA, true) }}</td>
        <td v-if="!showOnlyAvailable">
          <template v-if="promoCode.brutCommission">
            {{ formatPrice(promoCode.brutCommission, true) }}
          </template>
          <template
            v-else-if="
              promoCode.influencerDiscount && promoCode.influencerDiscount.euros
            "
          >
            {{
              formatPrice(
                promoCode.influencerDiscount.euros *
                  (promoCode.stripe.coupon.times_redeemed
                    ? promoCode.stripe.coupon.times_redeemed
                    : 0),
                true
              )
            }}
          </template>
          <template
            v-else-if="
              promoCode.influencerDiscount &&
                promoCode.influencerDiscount.percentage
            "
          >
            {{
              formatPrice(
                (promoCode.influencerDiscount.percentage / 100) *
                  promoCode.totalCA,
                true
              )
            }}
          </template>
        </td>
        <td v-if="isInfluencerAccount">
          {{ formatPrice(promoCode.commission || 0, true) }}
        </td>
        <td v-if="showWithdrawButton"></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td class="font-weight-bold">Total</td>
        <td>{{ totalSales }}</td>
        <td>{{ formatPrice(totalCa, true) }}</td>
        <td v-if="!showOnlyAvailable">
          {{ formatPrice(totalCommission, true) }}
        </td>
        <td v-if="isInfluencerAccount">
          {{ formatPrice(totalAvailableCommission, true) }}
        </td>
        <td v-if="showWithdrawButton">
          <button
            v-if="!hold"
            class="btn btn-sm btn-success"
            @click="disburseCode()"
          >
            <i class="fa fa-download"></i>
            Retrait
          </button>
          <span v-else class="badge badge-danger">En cours de traitement</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    promoCodes: {
      type: Array,
      required: true
    },

    canWithdraw: {
      type: Boolean,
      default: false
    },

    hold: {
      type: Boolean,
      default: false
    },

    isInfluencerAccount: {
      type: Boolean,
      default: false
    },

    totalSales: {
      type: Number,
      default: 0
    },

    totalCa: {
      type: Number,
      default: 0
    },

    totalCommission: {
      type: Number,
      default: 0
    },

    totalAvailableCommission: {
      type: Number,
      default: 0
    },

    showOnlyAvailable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showWithdrawButton() {
      return (
        this.canWithdraw &&
        this.totalAvailableCommission > 0 &&
        !this.showOnlyAvailable
      );
    }
  },

  methods: {
    disburseCode() {
      this.$emit("disburse");
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>

    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Date de début</label>
          <datepicker
            v-model="filter.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Date de fin</label>
          <datepicker
            v-model="filter.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Concepts</label>
          <multiselect
            v-model="filter.concepts"
            :options="concepts"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Commerciaux</label>
          <multiselect
            v-model="filter.commercials"
            :options="commercials"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-6">
          <label>Type de commande</label>
          <v-select
            v-model="filter.orderType"
            :options="orderTypes"
            placeholder="Tout"
            :reduce="({ value }) => value"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button type="button" class="btn btn-sm btn-secondary" @click="apply">
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Multiselect,
    Datepicker,
  },

  props: {
    default: {
      type: Object,
      default: null,
    },

    commercials: {
      type: Array,
      default: () => [],
    },

    concepts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filter: {
        begin: null,
        end: null,
        concepts: [],
        commercials: [],
        orderType: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      orderTypes: "getOrderTypes",
    }),
  },

  watch: {
    default() {
      this.filter = {
        ...this.filter,
        ...this.default,
      };
    },
  },

  methods: {
    apply() {
      this.$emit("apply", this.filter);
    },
  },
};
</script>

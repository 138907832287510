<template>
  <modal
    :title="isCoachAccount ? 'Participants' : 'Réservations'"
    v-model="show"
    cancelText="Fermer"
    okText="OK"
    @ok="close()"
    @cancel="close()"
    effect="fade/zoom"
  >
    <SessionBookingsTable
      v-if="isCoachAccount"
      :data="currentBookings"
      is-coach-account
    />
    <tabs v-else>
      <tab :header="currentHeader">
        <SessionBookingsTable :data="currentBookings" />
      </tab>
      <tab :header="canceledHeader">
        <SessionBookingsTable :data="canceledBookings" canceled />
      </tab>
    </tabs>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import SessionBookingsTable from "../components/SessionBookingsTable";

const BOOKING_STATUS = {
  CANCELED: "Canceled",
  BOOKED: "Booked"
};

export default {
  components: {
    Modal,
    Tabs,
    Tab,
    SessionBookingsTable
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    bookings: {
      type: Array,
      required: true
    },
    isCoachAccount: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    currentBookings() {
      return this.bookings
        .filter(booking => booking.status === BOOKING_STATUS.BOOKED)
        .sort((a, b) => {
          if (!a.updatedAt) {
            return 1;
          }

          if (!b.updatedAt) {
            return -1;
          }

          return new Date(b.updatedAt).getTime() - new Date(a.updatedAt);
        });
    },

    canceledBookings() {
      return this.bookings
        .filter(booking => booking.status === BOOKING_STATUS.CANCELED)
        .sort((a, b) => {
          if (!a.updatedAt) {
            return 1;
          }

          if (!b.updatedAt) {
            return -1;
          }

          return new Date(b.updatedAt).getTime() - new Date(a.updatedAt);
        });
    },

    currentHeader() {
      return `Réservé (${this.currentBookings.length})`;
    },

    canceledHeader() {
      return `Annulé (${this.canceledBookings.length})`;
    }
  },

  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

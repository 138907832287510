<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div
        class="card"
        v-if="hasPermission($store.state.user, 'IMPORT_WRITE')"
      >
        <div class="card-header d-flex justify-content-between">
          <h3>Import</h3>
        </div>
        <div class="card-block">
          <Tabs v-model="activeTab" @input="changeTab">
            <Tab
              :key="`tab-${index}`"
              v-for="(item, index) of tabs"
              :header="item.label"
            >
            </Tab>
          </Tabs>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from 'vue-strap/src/Tabs'
import Tab from 'vue-strap/src/Tab'

export default {
  components: {
    Tabs,
    Tab
  },

  data() {
    return {
      activeTab: 0,
      tabs: [
        { label: "Prestataires", name: "import-prestataires" },
      ]
    };
  },

  created() {
    if (this.$route.name === "import") {
      this.$router.push({ name: this.tabs[0].name });
    }

    this.setCurrentTab(this.$route.name);
  },

  methods: {
    changeTab(index) {
      this.$router.push({ name: this.tabs[index].name });
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.tabs.findIndex(tab => tab.name === currentPath);
    }
  },

  watch: {
    $route(current) {
      if (current.name === "import") {
        this.$router.push({ name: this.tabs[0].name });
      }

      this.setCurrentTab(current.name);
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-bullhorn"></i> Parrainage(s)
      <button
        v-if="sponsoredClients.length > 0"
        type="button"
        class="btn btn-sm btn-primary ml-1"
        @click="modalListShow = true"
      >
        <i class="icon-magnifier"></i>&nbsp; Liste des filleul.e.s
      </button>

      <span v-if="sponsor" class="float-right"
        >{{ sponsor.gender === "Femme" ? "Marraine" : "Parrain" }} :
        <a :href="sponsorHref" target="_blank">
          {{ sponsor.fullName }} <i class="icon-user"></i> </a
      ></span>
    </div>

    <div class="card-block">
      <div v-if="concepts.length === 0" class="text-center text-muted">
        Aucun parrainage
        <span v-if="sponsoredClients.length > 0">abouti</span> pour le moment
      </div>
      <tabs v-else style="position: relative">
        <tab
          v-for="concept in concepts"
          :key="concept.id"
          :header="getConceptHeader(concept)"
        >
          <ClientSponsorshipTable
            :data="concept.sponsorships"
            @openNoteModal="openSponsorshipNoteModal"
          />
        </tab>
        <div
          style="position: absolute; top: 0; right: 0; padding: 0.5rem 1.5rem"
        >
          Total ({{ sponsored.length }})
        </div>
      </tabs>
    </div>

    <modal
      title="Liste des filleul.e.s"
      v-model="modalListShow"
      effect="fade/zoom"
    >
      <el-table :data="sponsoredClients" :showHeader="false">
        <el-table-column>
          <template slot-scope="scope">
            <a
              :href="clientHref(scope.row.id)"
              target="_blank"
              :class="{ 'text-muted': isUnsuccessful(scope.row.id) }"
              >{{ scope.row.name }} <i class="icon-user"></i
            ></a>
          </template>
        </el-table-column>
      </el-table>
      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="modalListShow = false"
        >
          Fermer
        </button>
      </div>
    </modal>

    <modal
      title="Note Parrainage"
      v-model="modalUpdateSponsorshipNotes.display"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateSponsorshipNotes()"
      @cancel="closeSponsorshipNoteModal"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateSponsorshipNotes.notes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import ClientSponsorshipTable from "./ClientSponsorshipTable";
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Tabs,
    Tab,
    ClientSponsorshipTable,
    Modal,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sponsor: null,
      sponsored: [],
      modalListShow: false,
      modalUpdateSponsorshipNotes: {
        display: false,
        sponsorship: null,
        notes: null,
      },
    };
  },

  mounted() {
    this.sponsor = this.client.packSponsoredBy?.sponsor;
    this.sponsored = [...this.client.packSponsorships];
  },

  computed: {
    successfulSponsorships() {
      return this.sponsored.filter(
        (sponsorship) => sponsorship.freeSessionsPackId
      );
    },

    concepts() {
      let concepts = [];

      this.successfulSponsorships.forEach((sponsorship) => {
        const orderConcept = sponsorship.freeSessionsPack.refConcept;

        if (orderConcept) {
          const existingConcept = concepts.find(
            (concept) => concept.name === orderConcept.name
          );

          if (existingConcept) {
            existingConcept.sponsorships = [
              ...existingConcept.sponsorships,
              sponsorship,
            ];
          } else {
            concepts.push({
              ...orderConcept,
              sponsorships: [sponsorship],
            });
          }
        }
      });

      return concepts.sort(
        (a, b) => b.sponsorships.length - a.sponsorships.length
      );
    },

    sponsorHref() {
      if (this.sponsor?.id) {
        const route = this.$router.resolve({
          name: "client-details",
          params: {
            id: this.sponsor.id,
          },
        });

        return route.href;
      }

      return "";
    },

    sponsoredClients() {
      let clients = [];

      this.sponsored.forEach((sponsorship) => {
        const id = sponsorship.sponsored?.id;
        const name = sponsorship.sponsored?.fullName;

        if (id && !clients.some((client) => client.id === id)) {
          clients.push({ id, name });
        }
      });

      return clients;
    },
  },

  methods: {
    getConceptHeader(concept) {
      return `${concept.name} (${concept.sponsorships.length})`;
    },

    clientHref(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      return route.href;
    },

    isUnsuccessful(clientId) {
      const clientSponsorships = this.sponsored.filter(
        (sponsorship) => sponsorship.sponsored?.id === clientId
      );

      return !clientSponsorships.some(
        (sponsorship) => sponsorship.freeSessionsPackId
      );
    },

    openSponsorshipNoteModal(sponsorship) {
      this.modalUpdateSponsorshipNotes.sponsorship = sponsorship;
      this.modalUpdateSponsorshipNotes.notes = sponsorship.notes;
      this.modalUpdateSponsorshipNotes.display = true;
    },

    closeSponsorshipNoteModal() {
      this.modalUpdateSponsorshipNotes.sponsorship = null;
      this.modalUpdateSponsorshipNotes.notes = null;
      this.modalUpdateSponsorshipNotes.display = false;
    },

    async updateSponsorshipNotes() {
      if (
        this.modalUpdateSponsorshipNotes.sponsorship.notes !==
        this.modalUpdateSponsorshipNotes.notes
      ) {
        try {
          const notes = this.modalUpdateSponsorshipNotes.notes.trim();

          await this.$api.put(
            `/sponsorships/${this.modalUpdateSponsorshipNotes.sponsorship.id}/notes`,
            {
              notes,
            }
          );

          this.modalUpdateSponsorshipNotes.sponsorship.notes = notes;

          this.closeSponsorshipNoteModal();
        } catch (e) {
          this.closeSponsorshipNoteModal();
        }
      } else {
        this.closeSponsorshipNoteModal();
      }
    },
  },
};
</script>

<template>
  <ReactiveChart :chart-data="chartData" :options="options" />
</template>

<script>
import ReactiveChart from "./ReactiveChart.vue";
export default {
  components: {
    ReactiveChart
  },
  props: {
    concepts: {
      type: Object,
      default: null
    }
  },

  computed: {
    listConcepts() {
      if (this.concepts) {
        return Object.keys(this.concepts);
      }

      return false;
    },
    labels() {
      if (this.concepts[this.listConcepts[0]]?.dataset) {
        return Object.keys(this.concepts[this.listConcepts[0]]?.dataset);
      }

      return [];
    },
    datasets() {
      if (!this.listConcepts) {
        return [];
      }

      let datasets = [];

      this.listConcepts.forEach(concept => {
        let data = [];

        for (let period in this.concepts[concept].dataset) {
          data.push(
            (this.concepts[concept].dataset[period].price / 100).toFixed(2)
          );
        }

        datasets.push({
          label: concept,
          backgroundColor: this.rgba(this.concepts[concept].color, 10),
          borderColor: this.concepts[concept].color,
          pointHoverBackgroundColor: "#fff",
          borderWidth: 2,
          data: data
        });
      });

      return datasets;
    },
    max() {
      if (!this.listConcepts) {
        return 0;
      }

      let max = 0;

      for (const concept of this.listConcepts) {
        for (let period in this.concepts[concept].dataset) {
          if (max < this.concepts[concept].dataset[period].price) {
            max = this.concepts[concept].dataset[period].price;
          }
        }
      }

      return parseInt(max / 100) + 50;
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets
      };
    },
    options() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: value => {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                }
              },
              gridLines: {
                display: true
              }
            }
          ]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      };
    }
  },
  methods: {
    rgba(hex, opacity) {
      hex = hex.replace("#", "");
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
    }
  }
};
</script>

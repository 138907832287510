<template>
  <div class="animated fadeIn">
    <InvoiceFilters
      :filters="filters"
      :concepts="concepts"
      :merchants-options="merchantsOptions"
      :commercials="commercials"
      :zipcodes="zipcodes"
      :invoiceSeries="invoiceSeries"
      stats
      @load="(form) => loadInvoices(form)"
    />

    <div class="card card-inverse card-info text-center" v-if="isLoading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>

    <ConversionCard
      v-if="!isLoading"
      :commercials="filters.commercials"
      :orders="orders"
      :selectedDepartments="filters.zipcodes"
      :departments="zipcodes"
    />
  </div>
</template>

<script>
import InvoiceFilters from "../components/InvoiceFilters";
import ConversionCard from "../components/ConversionCard";

import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: {
    InvoiceFilters,
    ConversionCard,
  },

  data() {
    return {
      isLoading: false,
      orders: [],
      concepts: [],
      merchantsOptions: [],
      commercials: [],
      filters: {
        begin: moment().startOf("month").startOf("day").format("YYYY-MM-DD"),
        end: moment().endOf("month").startOf("day").format("YYYY-MM-DD"),
        concept: null,
        invoiceSerieCode: null,
        client: null,
        type: null,
        zipcodes: [{ label: "Tous", value: "all" }],
        status: [
          { label: "Payée", value: "payed" },
          { label: "Partiel", value: "partial" },
        ],
        commercials: [],
      },
    };
  },

  async created() {
    await this.getConcepts();
    await this.getMerchants();
    await this.getCommercials();

    const currentCommercial = this.commercials.find((c) => {
      if (this.$store.state.user.email === c.value) {
        return c;
      }
    });

    if (currentCommercial) {
      this.filters.commercials.push(currentCommercial);
    }

    await this.loadInvoices();
  },

  computed: {
    ...mapState({
      invoiceSeries: (state) => state.invoiceSeries,
    }),

    ...mapGetters({
      departments: "getDepartments",
    }),

    zipcodes() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },
  },

  methods: {
    async loadInvoices(form) {
      this.isLoading = true;

      if (form) {
        this.filters = form;
      }

      let zipcodes = null;

      if (this.filters.zipcodes?.length > 0) {
        const values = this.filters.zipcodes.map(({ value }) => value);

        if (!values.includes("all")) {
          zipcodes = values;
        }
      }

      let status = null;

      if (this.filters.status?.length > 0) {
        status = this.filters.status.map(({ value }) => value);
      }

      try {
        const { data } = await this.$api.get(`/invoices/for-stats`, {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
            concept: this.filters.concept,
            merchantId: this.filters.merchant
              ? this.filters.merchant.value
              : null,
            invoiceSerieCode: this.filters.invoiceSerieCode,
            client: this.filters.client ? this.filters.client.value : null,
            status: status,
            match: this.filters.match,
            type: this.filters.type ? this.filters.type.value : null,
            zipcodes: zipcodes,
            commercials:
              this.filters.commercials &&
              this.filters.commercials.map((c) => c.value),
          },
        });

        this.orders = data;
      } catch (e) {
        console.log(e);
      }

      this.isLoading = false;
    },

    async getCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((c) => ({
          value: c.email,
          label: c.commercial.pseudo,
          color: c.commercial?.color,
        }));
      } catch (e) {
        console.log(e);
      }
    },

    async getConcepts() {
      try {
        const { data } = await this.$api.get("/concepts");

        this.concepts = data.map((c) => c.name);
      } catch (e) {
        console.log(e);
      }
    },

    async getMerchants() {
      try {
        const { data } = await this.$api.get("/merchants");

        this.merchantsOptions = data.map((merchant) => {
          return { label: merchant.name, value: merchant.id };
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<template>
  <el-table
    :data="data"
    :default-sort="{ prop: 'updatedAt', order: 'descending' }"
    :empty-text="emptyText"
    max-height="305"
  >
    <el-table-column prop="name" label="Client">
      <template slot-scope="scope">
        <span v-if="isCoachAccount">{{ displayClient(scope.row) }}</span>
        <a v-else :href="clientHref(scope.row.clientId)" target="_blank">
          {{ displayClient(scope.row) }}
        </a>
      </template>
    </el-table-column>
    <el-table-column v-if="!isCoachAccount" prop="status" label="Statut">
      <template slot-scope="scope">
        {{ bookingStatus(scope.row) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="updatedAt"
      :label="canceled ? 'Annulé le' : 'Réservé le'"
    >
      <template slot-scope="scope">
        {{ formatDate(scope.row.updatedAt) }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
const BOOKING_STATUS = {
  CANCELED: "Canceled",
  BOOKED: "Booked",
};

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },

    canceled: {
      type: Boolean,
      default: false,
    },

    isCoachAccount: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    emptyText() {
      return this.isCoachAccount
        ? "Aucun participant pour le moment"
        : `Aucune réservation${this.canceled ? " annulée" : ""}`;
    },
  },

  methods: {
    clientHref(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: { id },
      });

      return route.href;
    },

    displayClient(booking) {
      return booking.client?.name || booking.client?.usedEmail || "";
    },

    bookingStatus(booking) {
      switch (booking.status) {
        case BOOKING_STATUS.BOOKED:
          return "Réservé";
        case BOOKING_STATUS.CANCELED:
          return "Annulé";
        default:
          return "";
      }
    },
  },
};
</script>

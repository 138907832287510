<template>
  <div>
    <el-table :data="currentExpenses" style="width: 100%">
      <el-table-column prop="year" label="Année(s)" width="180" sortable>
        <template slot-scope="scope">
          {{ showDateYear(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column prop="month" label="Mois" width="180" sortable>
        <template slot-scope="scope">
          {{ showDateMonth(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Type de dépense(s)" sortable>
      </el-table-column>
      <el-table-column prop="company" label="Société(s)" sortable>
      </el-table-column>
      <el-table-column prop="concept" label="Concept" sortable>
      </el-table-column>
      <el-table-column prop="name" label="Nom de dépense(s)" sortable>
      </el-table-column>
      <el-table-column prop="amount" align="right" label="Montant(s)" sortable>
        <template slot-scope="scope">
          {{ localizedPrice(scope.row.amount) || "0.00€" }}
        </template>
      </el-table-column>
      <el-table-column align="center" width="150">
        <template slot-scope="scope">
          <button
            class="btn btn-default btn-sm"
            @click="handleEditExpense(scope.row)"
          >
            <i class="fa fa-pencil"></i>
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="handleDeleteExpense(scope.row)"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    expenses: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentExpenses: [],
    };
  },

  mounted() {
    this.currentExpenses = this.expenses;
  },

  methods: {
    showDateYear(date) {
      return moment(date).format("YYYY");
    },

    showDateMonth(date) {
      return moment(date).locale("fr").format("MMMM").toUpperCase();
    },

    handleDeleteExpense(expense) {
      this.$confirm({
        message: "Êtes vous sûr de vouloir supprimer cette dépense ?",
        onConfirm: async () => {
          await this.$api.delete(`/expenses/${expense.id}`);

          this.$emit("delete", expense);
        },
      });
    },

    async handleEditExpense(expense) {
      this.$emit("edit", expense);
    },

    localizedPrice(price) {
      return parseFloat(price)
        .toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        })
        ?.replace(",", ".");
    },
  },

  watch: {
    expenses: {
      deep: true,
      handler() {
        this.currentExpenses = this.expenses;
      },
    },
  },
};
</script>

<style></style>

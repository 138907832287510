<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <router-view></router-view>

      <div class="card" v-if="['merchants'].indexOf($route.name) > -1">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Liste des sociétés
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="newMerchant = true"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Informations</th>
                <th>Contact</th>
              </tr>
            </thead>
            <tbody v-if="merchantsData.length > 0">
              <tr
                v-for="(merchant, index) in merchantsData"
                :key="index"
                @click="goToMerchant(merchant)"
                class="click"
              >
                <td>{{ merchant.name }}</td>
                <td>
                  <span v-if="merchant.address">{{ merchant.address }}</span
                  ><br />
                  <span v-if="merchant.zipcode"
                    >{{ merchant.zipcode }} {{ merchant.city }}
                    {{ merchant.country }}</span
                  ><br />
                  <span v-if="merchant.siret">SIRET : {{ merchant.siret }}</span
                  ><br />
                </td>
                <td>
                  <span v-if="merchant.phone"
                    >Téléphone : {{ merchant.phone }}</span
                  ><br />
                  <span v-if="merchant.email"
                    >Email : {{ merchant.email }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal
      title="Ajouter une société"
      large
      v-model="newMerchant"
      @ok="handleNewMerchant()"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ajouter une société</h4>
      </div>

      <div class="form-group has-danger">
        <label>Nom</label>
        <input
          v-model="name"
          type="text"
          class="form-control"
          placeholder="exemple : Just Coaching Company"
        />
      </div>
      <div class="form-group">
        <label>Adresse</label>
        <input v-model="address" type="text" class="form-control" />
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Code postal</label>
          <input v-model="zipcode" type="text" class="form-control" />
        </div>
        <div class="form-group col-sm-4">
          <label>Ville</label>
          <input v-model="city" type="text" class="form-control" />
        </div>
        <div class="form-group col-sm-4">
          <label>Pays</label>
          <input v-model="country" type="text" class="form-control" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Contact téléphone</label>
          <input v-model="phone" type="text" class="form-control" />
        </div>
        <div class="form-group col-sm-6">
          <label>Contact email</label>
          <input v-model="email" type="text" class="form-control" />
        </div>
      </div>
      <div class="form-group">
        <label>IBAN</label>
        <input v-model="iban" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>SIRET N°</label>
        <input v-model="siret" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>TVA N°</label>
        <input v-model="vat" type="text" class="form-control" />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      merchantsData: [],
      newMerchant: false,
      name: null,
      address: null,
      zipcode: null,
      city: null,
      country: null,
      phone: null,
      email: null,
      siret: null,
      iban: null,
      vat: null,
    };
  },
  created() {
    this.$api
      .get("/merchants")
      .catch(() => {
        this.$router.push({
          name: "dashboard",
        });
      })
      .then((res) => {
        this.merchantsData = res.data;
      });
  },
  methods: {
    handleNewMerchant() {
      if (!this.name) {
        return false;
      }

      this.$api
        .post("/merchants", {
          name: this.name,
          address: this.address,
          zipcode: this.zipcode,
          city: this.city,
          country: this.country,
          phone: this.phone,
          email: this.email,
          siret: this.siret,
          iban: this.iban,
          vat: this.vat,
        })
        .then((res) => {
          this.newMerchant = false;
          this.merchantsData.unshift(res.data);
        });
    },
    goToMerchant(merchant) {
      this.$router.push({
        name: "merchant-details",
        params: {
          id: merchant.id,
        },
      });
    },
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <tabs v-model="activeTab" @input="changeTab" class="empty-tab">
        <tab
          v-for="(item, index) of tabs"
          :key="`tab-${index}`"
          :header="item.label"
        ></tab>
      </tabs>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Tabs,
    Tab,
  },

  async created() {
    if (this.hasPermission(this.$store.state.user, "CLIENTS_READ")) {
      this.tabs = [
        ...this.tabs,
        { label: "Prospects", name: "prospects" },
        { label: "Clients", name: "clients" },
      ];
    }

    if (this.tabs.length > 0) {
      let activeTab = sessionStorage.getItem("clientsMenuTab");

      if (this.$route.name === "list") {
        if (activeTab && this.tabs[activeTab]) {
          this.$router.push({ name: this.tabs[activeTab].name });
        } else {
          this.$router.push({ name: this.tabs[0].name });
        }
      }

      this.setCurrentTab(this.$route.name);
    }
  },

  data() {
    return {
      activeTab: 0,
      tabs: [],
    };
  },

  watch: {
    $route(current) {
      this.setCurrentTab(current.name);
    },
  },

  methods: {
    changeTab(index) {
      sessionStorage.setItem("clientsMenuTab", index);

      this.$router.push({ name: this.tabs[index].name });
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.tabs.findIndex((tab) => tab.name === currentPath);
    },
  },
};
</script>

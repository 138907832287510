<template>
  <modal
    :title="`${
      edit ? 'Mofifier la notification coach' : 'Ajouter une notification coach'
    }`"
    v-model="isVisible"
    cancelText="Annuler"
    :okText="`${edit ? 'Mettre à jour' : 'Ajouter'}`"
    @ok="edit ? editContent() : addContent()"
    @cancel="closeModal"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Titre *</label>
          <input type="text" class="form-control" v-model="title" />
          <small v-if="errors && errors.title" class="form-text text-danger">
            {{ errors.title }}</small
          >
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label>Message *</label>
          <textarea class="form-control" v-model="notes" />
          <small v-if="errors && errors.notes" class="form-text text-danger">
            {{ errors.notes }}</small
          >
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    close: {
      type: Function,
      required: true,
    },

    userEvent: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      title: "",
      notes: "",
      sendDate: new Date(),
      errors: {},
      isVisible: false,
    };
  },

  computed: {
    edit() {
      return !!this.userEvent?.id;
    },
  },

  watch: {
    show(value) {
      this.isVisible = value;
    },

    userEvent() {
      if (this.edit) {
        this.title = this.userEvent.title;
        this.notes = this.userEvent.notes;
      } else {
        this.resetData();
      }
    },
  },

  methods: {
    resetData() {
      this.title = "";
      this.notes = "";
      this.errors = {};
    },

    closeModal() {
      this.resetData();
      this.close();
    },

    checkFields() {
      this.errors = {};

      if (!this.title) {
        this.errors.title = "Veuillez saisir un nom";
      }

      if (!this.notes) {
        this.errors.notes = "Veuillez saisir un contenu";
      }
    },

    async addContent() {
      this.checkFields();

      if (Object.values(this.errors).filter((error) => error).length > 0) {
        return;
      }

      try {
        this.loading = true;

        await this.$api.post("/events", {
          title: this.title,
          notes: this.notes,
          type: "COACH_EVENTS",
        });

        this.$emit("added");
        this.closeModal();
        this.$successToast("Contenu créé");
      } catch (e) {
        this.$errorToast("Impossible d'ajouter le contenu dynamique");
      } finally {
        this.loading = false;
      }
    },

    async editContent() {
      if (!this.userEvent?.id) {
        return;
      }

      this.checkFields();

      if (Object.values(this.errors).filter((error) => error).length > 0) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$api.put(`/events/${this.userEvent.id}`, {
          title: this.title,
          notes: this.notes,
        });

        this.closeModal();
        this.$emit("updated", data);
        this.$successToast("Contenu mis à jour");
      } catch (e) {
        this.$errorToast("Impossible de modifier le contenu dynamique");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

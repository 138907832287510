<template>
  <div class="animated fadeIn">
    <div class="row align-items-center bg-secondary">
      <div class="col-md-3" v-if="filters.display.conversionRate">
        <div class="card stat-card">
          <div class="card-body p-1">
            <div class="d-flex justify-content-between">
              <h6>Taux de Conversion</h6>
              <span
                v-if="convertedPercent"
                class="font-weight-bold percent-title"
              >
                {{ convertedPercent }}%
              </span>
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-md-7">
                <Doughnut :options="options" :chart-data="convertedChartData" />
              </div>
              <div class="col-md-5 d-flex flex-column">
                <div>
                  <span class="pills blue">{{ converted }} Client(s)</span>
                  <p>Converti(s)</p>
                </div>
                <div>
                  <span class="pills green">{{ notConverted }} Client(s)</span>
                  <p>Perdu</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="filters.display.fidelisationRate">
        <div class="card stat-card">
          <div class="card-body p-1 bg-gray-500">
            <div class="d-flex justify-content-between">
              <h6>Taux de Fidélisation</h6>
              <span v-if="retainPercent" class="font-weight-bold percent-title"
                >{{ retainPercent }}%</span
              >
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-md-7">
                <Doughnut :options="options" :chart-data="retainedChartData" />
              </div>
              <div class="col-md-5 d-flex flex-column">
                <div>
                  <span class="pills blue">{{ retain }} Client(s)</span>
                  <p>Fidélisé(s)</p>
                </div>
                <div>
                  <span class="pills green">{{ notRetain }} Client(s)</span>
                  <p>Perdu</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="col-md-12" v-if="filters.display.rank">
          <div class="card small-stat-card">
            <div class="card-body p-1">
              <h6>Notes clients</h6>
              <div
                class="d-flex flex-column mt-1 align-items-center justify-content-center"
              >
                <Rates :value="rank" :displayRatingValue="false" size="fa-2x" />
                <span class="text-resume">
                  {{ rank }}
                </span>
                <span class="text-resume-label"> {{ rankQuantity }} Avis </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="filters.display.validate">
          <div class="card small-stat-card">
            <div class="card-body p-1">
              <h6>Validée(s)</h6>
              <div
                class="d-flex flex-column mt-1 align-items-center justify-content-center"
              >
                <span class="text-resume">{{ validate }}</span>
                <span class="text-resume-label">Séances</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="col-md-12" v-if="filters.display.clientSessionLifecycle">
          <div class="card small-stat-card">
            <div class="card-body p-1">
              <h6>Longévité clients</h6>
              <div
                class="d-flex flex-column mt-1 align-items-center justify-content-center"
              >
                <span class="text-resume">{{ clientSessionLifecycle }}</span>
                <span class="text-resume-label">Séances</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="filters.display.packAverage">
          <div class="card small-stat-card">
            <div class="card-body p-1">
              <h6>Pack moyen</h6>
              <div
                class="d-flex flex-column mt-1 align-items-center justify-content-center"
              >
                <span class="text-resume">{{ packAverage }}</span>
                <span class="text-resume-label">Séances</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="col-md-12" v-if="filters.display.frequency">
          <div class="card small-stat-card">
            <div class="card-body p-1">
              <h6>Fréquence</h6>
              <div
                class="d-flex flex-column mt-1 align-items-center justify-content-center"
              >
                <span class="text-resume">{{ frequency }} </span>
                <span class="text-resume-label">Séance / jours</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="filters.display.resubscribe">
          <div class="card small-stat-card">
            <div class="card-body p-1">
              <h6>Réabonnement</h6>
              <div
                class="d-flex flex-column mt-1 align-items-center justify-content-center"
              >
                <span class="text-resume">{{ resubscribe }}</span>
                <span class="text-resume-label">Packs</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Doughnut from "./rank/Doughnut.vue";
import Rates from "./Rates";

export default {
  components: {
    Doughnut,
    Rates,
  },

  data() {
    return {
      options: {
        responsive: true,
        animation: {
          animateRotate: false,
        },
        legend: {
          display: false,
        },
        cutoutPercentage: 75,
      },
      converted: 0,
      notConverted: 0,
      convertedPercent: null,
      retain: 0,
      notRetain: 0,
      retainPercent: null,
      packAverage: 0,
      clientSessionLifecycle: 0,
      resubscribe: 0,
      frequency: 0,
      rank: 0,
      rankQuantity: 0,
      validate: 0,
    };
  },

  props: {
    data: { type: Array, required: true },
    filters: {
      required: true,
      type: Object,
    },
  },

  computed: {
    convertedChartData() {
      return {
        labels: ["Convertis", "Perdu"],
        datasets: [
          {
            backgroundColor: ["#255dc8", "#18bdb4"],
            data: [this.converted, this.notConverted],
          },
        ],
      };
    },
    retainedChartData() {
      return {
        labels: ["Fidélisé", "Perdu"],
        datasets: [
          {
            backgroundColor: ["#255dc8", "#18bdb4"],
            data: [this.retain, this.notRetain],
          },
        ],
      };
    },
  },

  created() {
    const converted = [];
    const notConverted = [];

    const retain = [];
    const notRetain = [];

    const packAverage = [];
    const clientSessionLifecycle = [];
    const resubscribe = [];
    const frequency = [];
    const rank = [];
    const rankQuantity = [];
    const validate = [];
    for (const data of this.data) {
      converted.push(
        data.conversionRate?.details.filter((d) => d.code === "converted")
          .length
      );
      notConverted.push(
        data.conversionRate?.details.filter((d) => d.code === "not_converted")
          .length
      );

      retain.push(data.fidelisationRate?.fidelised || 0);
      notRetain.push(data.fidelisationRate?.lost || 0);

      packAverage.push(data.packAverage?.result);
      clientSessionLifecycle.push(data.clientSessionLifecycle?.result);
      resubscribe.push(data.resubscribe?.result);
      frequency.push(data.frequency?.result);
      rank.push(data.rank?.result);
      rankQuantity.push(data.rank?.details?.length);
      validate.push(data.validate?.result);
    }

    this.converted = converted.reduce((a, b) => a + b, 0);
    this.notConverted = notConverted.reduce((a, b) => a + b, 0);
    this.convertedPercent = (
      (100 * this.converted) /
      (this.converted + this.notConverted)
    ).toFixed(2);

    this.retain = retain.reduce((a, b) => a + b, 0);
    this.notRetain = notRetain.reduce((a, b) => a + b, 0);
    this.retainPercent = (
      (100 * this.retain) /
      (this.retain + this.notRetain)
    ).toFixed(2);

    this.packAverage = (
      packAverage.reduce((a, b) => +a + +b, 0) /
      packAverage.filter((f) => f).length
    ).toFixed(2);
    this.clientSessionLifecycle = (
      clientSessionLifecycle.reduce((a, b) => +a + +b, 0) /
      clientSessionLifecycle.filter((f) => f).length
    ).toFixed(2);
    this.resubscribe = (
      resubscribe.reduce((a, b) => +a + +b, 0) /
      resubscribe.filter((f) => f).length
    ).toFixed(2);
    this.frequency = (
      frequency.reduce((a, b) => +a + +b, 0) / frequency.filter((f) => f).length
    ).toFixed(2);
    this.rank = parseFloat(
      (
        rank.reduce((a, b) => +a + +b, 0) / rank.filter((r) => r).length
      ).toFixed(2)
    );
    this.rankQuantity = rankQuantity.reduce((a, b) => +a + +b, 0);
    this.validate = parseFloat(
      (
        validate.reduce((a, b) => +a + +b, 0) / validate.filter((r) => r).length
      ).toFixed(2)
    );
  },
};
</script>

<style scoped>
.pills {
  border-radius: 100px;
  padding: 3px 20px;
  display: block;
  display: inline-block;
}

.blue {
  background-color: #255dc8;
  color: white;
}

.green {
  background-color: #18bdb4;
  color: white;
}

.text-resume {
  font-weight: bold;
  font-size: 1.8rem;
  color: #445878;
}

.text-resume-label {
  font-size: 1.3rem;
  color: #445878;
}

.stat-card {
  min-height: 310px;
}

.small-stat-card {
  min-height: 100px;
}

.percent-title {
  font-size: 25px;
}
</style>

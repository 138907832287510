<template>
  <modal
    title="Ajouter un type de contenu dynamique"
    v-model="show"
    cancelText="Annuler"
    okText="Ajouter"
    @ok="addType"
    @cancel="closeModal"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Type de contenu *</label>
          <input type="text" class="form-control" v-model="type" />
          <small v-if="errors && errors.type" class="form-text text-danger">
            {{ errors.type }}</small
          >
        </div>
      </div>
      <div class="col-sm-12">
        <label>Métadonnées</label>
        <button
          type="button"
          class="btn btn-sm btn-secondary ml-05"
          @click="addMetadataRow"
        >
          <i class="fa fa-plus"></i>
        </button>

        <p v-if="metadata.length === 0" class="text-center text-muted">
          Aucune métadonnée
        </p>
        <div
          v-else
          v-for="(item, index) in metadata"
          :key="`type-metadata-${index}`"
          class="row align-items-center"
        >
          <div class="col-sm-10">
            <div class="form-group">
              <label
                ><small>Métadonnée {{ index + 1 }}</small></label
              >
              <input
                v-model="item.key"
                type="text"
                :placeholder="item.placeholder"
                class="form-control"
              />
              <small
                v-if="errors && errors.metadata && errors.metadata[index]"
                class="form-text text-danger"
              >
                {{ errors.metadata[index] }}</small
              >
            </div>
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              @click="removeMetadataRow(index)"
            >
              <i class="fa fa-minus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    types: {
      type: Array,
      default: () => [],
    },

    close: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      errors: {},
      type: "",
      metadata: [],
    };
  },

  computed: {
    typeOptions() {
      return this.types.map(({ type }) => ({
        label: type,
        value: type,
      }));
    },
  },

  methods: {
    checkFields() {
      this.errors = {};

      if (!this.type || this.type.trim() === "") {
        this.errors.type = "Veuillez renseigner une valeur pour ce champ";
      } else if (!this.type.trim().match(/^[a-zA-Z _]+$/)) {
        this.errors.type =
          "Seuls les caractères alphabétiques, les espaces et les '_' sont autorisés";
      } else if (
        this.types.some(({ type }) => type === this.formatTypeString(this.type))
      ) {
        this.errors.type = "Ce type de contenu existe déjà";
      }

      if (this.metadata.length > 0) {
        this.errors.metadata = {};

        this.metadata.forEach((item, index) => {
          if (!item.key || item.key.trim() === "") {
            this.errors.metadata[index] =
              "Veuillez saisir une valeur pour la clé de métadonnées";
          } else if (!item.key.match(/^[a-z_]+$/)) {
            this.errors.metadata[index] =
              "La clé de métadonnées ne doit contenir que des lettres minuscules et des underscores";
          }
        });

        if (
          Object.values(this.errors.metadata).filter((error) => error)
            .length === 0
        ) {
          delete this.errors.metadata;
        }
      }
    },

    async addType() {
      this.checkFields();

      if (Object.values(this.errors).filter((error) => error).length > 0) {
        return;
      }

      try {
        this.loading = true;

        const newType = {
          type: this.formatTypeString(this.type),
          metadata: this.metadata.map(({ key }) => key),
        };

        await this.$api.patch("/settings", {
          dynamic_content_types: [...this.types, newType],
        });

        this.$emit("added", newType);

        this.closeModal();
      } catch (e) {
        this.$errorToast("Impossible d'ajouter le type de contenu");
      } finally {
        this.loading = false;
      }
    },

    formatTypeString(str) {
      return str.trim().split(" ").join("_").toUpperCase();
    },

    resetData() {
      this.type = "";
      this.metadata = [];
      this.errors = {};
    },

    closeModal() {
      this.resetData();
      this.close();
    },

    addMetadataRow() {
      this.metadata.push({ placeholder: "exemple_de_cle", key: "" });
    },

    removeMetadataRow(index) {
      this.metadata.splice(index, 1);

      if (this.errors?.metadata && this.errors.metadata[index]) {
        delete this.errors.metadata[index];
      }
    },
  },
};
</script>

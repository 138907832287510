<template>
  <div class="card">
    <div class="card-header"><i class="icon-camrecorder"></i> Activité</div>
    <div class="card-block">
      <div
        v-if="bookingsLive.length === 0 && bookingsSmall.length === 0"
        class="text-muted text-center"
      >
        Aucune réservation pour ce client
      </div>
      <Tabs v-else v-model="activeTab">
        <Tab :header="liveHeader">
          <ClientBookingsTable
            :data="bookingsLive"
            :total="bookingsLivePagination.total"
            :rows="bookingsLivePagination.limit"
            :page="bookingsLivePagination.page"
            @changePage="changePageLive"
          />
        </Tab>
        <Tab :header="smallHeader">
          <ClientBookingsTable
            :data="bookingsSmall"
            :total="bookingsSmallPagination.total"
            :rows="bookingsSmallPagination.limit"
            :page="bookingsSmallPagination.page"
            small
            @changePage="changePageSmall"
          />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import ClientBookingsTable from "../components/ClientBookingsTable";

export default {
  components: {
    Tabs,
    Tab,
    ClientBookingsTable,
  },

  props: {
    bookingsLive: {
      type: Array,
      required: true,
    },

    bookingsSmall: {
      type: Array,
      required: true,
    },

    bookingsLivePagination: {
      type: Object,
      required: true,
    },

    bookingsSmallPagination: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab: 0,
    };
  },

  computed: {
    liveHeader() {
      return `Live (${this.bookingsLivePagination.total} séance${
        this.bookingsLivePagination.total > 1 ? "s" : ""
      })`;
    },

    smallHeader() {
      return `Visio (${this.bookingsSmallPagination.total} séance${
        this.bookingsSmallPagination.total > 1 ? "s" : ""
      })`;
    },
  },

  methods: {
    changePageLive(page) {
      this.$emit("changePageLive", page);
    },

    changePageSmall(page) {
      this.$emit("changePageSmall", page);
    },
  },
};
</script>

<template>
  <modal
    title="Notes de fin de session"
    v-model="show"
    effect="fade/zoom"
    large
  >
    <SessionRatingsTable v-if="session" :session="session" is-last-item />

    <div v-else-if="sessions" style="max-height: 600px; overflow-y: auto">
      <SessionRatingsTable
        v-for="(session, index) in sessions"
        :key="session.id"
        :session="session"
        :is-last-item="index === sessions.length - 1"
      />
    </div>

    <div slot="modal-footer" class="modal-footer">
      <button type="button" class="btn btn-default" @click="close">
        Fermer
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import SessionRatingsTable from "./SessionRatingsTable";

export default {
  components: {
    Modal,
    SessionRatingsTable,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    session: {
      type: Object,
      default: null,
    },

    sessions: {
      type: Array,
      default: null,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

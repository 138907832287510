<template>
  <div>
    <table class="table table-condensed el-table mt-1">
      <thead>
        <tr>
          <th></th>
          <th>
            Prénom
            <span @click="sort('firstName')" class="caret-wrapper"
              ><i
                class="sort-caret ascending"
                :class="{
                  active:
                    sorting &&
                    sorting.orderBy === 'firstName' &&
                    sorting.orderByReverse,
                }"
              ></i
              ><i
                class="sort-caret descending"
                :class="{
                  active:
                    sorting &&
                    sorting.orderBy === 'firstName' &&
                    !sorting.orderByReverse,
                }"
              ></i
            ></span>
          </th>
          <th>
            Nom
            <span @click="sort('lastName')" class="caret-wrapper"
              ><i
                class="sort-caret ascending"
                :class="{
                  active:
                    sorting &&
                    sorting.orderBy === 'lastName' &&
                    sorting.orderByReverse,
                }"
              ></i
              ><i
                class="sort-caret descending"
                :class="{
                  active:
                    sorting &&
                    sorting.orderBy === 'lastName' &&
                    !sorting.orderByReverse,
                }"
              ></i
            ></span>
          </th>
          <th>Ville <span class="caret-wrapper"></span></th>
          <th>Fiche</th>
          <th>Coachs <span class="caret-wrapper"></span></th>
          <th>Commercial <span class="caret-wrapper"></span></th>
          <th>
            Créé le
            <span @click="sort('createdAt')" class="caret-wrapper"
              ><i
                class="sort-caret ascending"
                :class="{
                  active:
                    sorting &&
                    sorting.orderBy === 'createdAt' &&
                    sorting.orderByReverse,
                }"
              ></i
              ><i
                class="sort-caret descending"
                :class="{
                  active:
                    sorting &&
                    sorting.orderBy === 'createdAt' &&
                    !sorting.orderByReverse,
                }"
              ></i
            ></span>
          </th>
          <th>B2B</th>
          <th>Note</th>
          <th v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')">
            Archives
          </th>
        </tr>
      </thead>
      <tbody v-if="clients && clients.length > 0">
        <tr
          v-for="(client, index) in clients"
          :key="index"
          @click="clickHandler(client)"
          class="click"
        >
          <td>
            <template v-if="client.conversion">
              <i
                v-if="client.conversion.rank === 'lost'"
                class="text-danger fa fa-exclamation-circle"
              />
              <i
                v-else-if="client.conversion.rank !== 'pending'"
                class="text-success fa fa-check-circle"
              />
            </template>
            <template v-if="client.urssafInscription">
              <template
                v-if="
                  !client.urssafInscription.idClient &&
                  client.urssafInscription.status === 'pending'
                "
              >
                <i
                  class="fa fa-balance-scale text-danger"
                  aria-hidden="true"
                ></i>
              </template>
              <template v-else-if="client.urssafInscription.idClient">
                <i
                  class="fa fa-balance-scale text-success"
                  aria-hidden="true"
                ></i>
              </template>
              <template
                v-else-if="
                  !client.urssafInscription.idClient &&
                  client.urssafInscription.status === 'send'
                "
              >
                <i
                  class="fa fa-balance-scale text-warning"
                  aria-hidden="true"
                ></i>
              </template>
            </template>
          </td>
          <td v-if="client.company" colspan="2">
            <strong>{{ client.company }}</strong>
          </td>
          <template v-else>
            <td>{{ client.firstName }}</td>
            <td>{{ client.lastName }}</td>
          </template>
          <td>
            <span v-if="client.city">{{ client.city }}</span>
            <span v-if="client.zipcode"
              >({{ client.zipcode.substr(0, 2) }})</span
            >
          </td>
          <td>
            <span @click.stop="goToClient(client)" class="user-link">
              <i class="icon-user"></i>
            </span>
          </td>
          <td>
            <span v-for="(user, index) in client.coachs" :key="index"
              >{{ user.coach.name }}<br
            /></span>
          </td>
          <td>
            <template v-for="(rel, index) of client.commercials">
              <CommercialRel :key="`com-${index}`" :relation="rel" />
            </template>
          </td>
          <td>
            {{ formatDateTime(client.createdAt) }} <br />
            <span v-if="client.inactiveSince" class="text-sm font-weight-bold">
              Inactif depuis le {{ formatDateTime(client.inactiveSince) }}
            </span>
          </td>
          <td>
            <div class="d-flex flex-column align-items-center">
              {{ client.isB2B ? "Oui" : "Non" }}
            </div>
          </td>
          <td>
            <NoteModal
              :notes="client.commercialNotes"
              :key="client.id"
              @showComment="$emit('showComment', client)"
            />
          </td>
          <td v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')">
            <button
              v-if="!client.archived"
              class="btn btn-info"
              @click.stop="$emit('archiveClient', client)"
            >
              <i class="icon icon-briefcase"></i>
            </button>
            <div v-else class="alert alert-info m-0 text-center">Archivé</div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="11">
            Cliquer sur le bouton "Visualiser" pour afficher les clients.
          </td>
        </tr>
      </tbody>
    </table>
    <paginate
      v-model="page"
      ref="paginate"
      :page-count="count"
      :click-handler="paginationHandler"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
    ></paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

import { mapState } from "vuex";
import FrequencyMixin from "../mixin/FrequencyMixin";
import NoteModal from "./form/NoteModal";
import CommercialRel from "../components/CommercialRel";

export default {
  components: {
    Paginate,
    NoteModal,
    CommercialRel,
  },
  mixins: [FrequencyMixin],
  props: {
    clients: {
      type: Array,
      default: null,
    },
    page: {
      type: Number,
      required: true,
    },
    rows: {
      type: Number,
      default: 20,
    },
    totalClients: {
      type: Number,
      default: 0,
    },
    sorting: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      displayArchived: false,
    };
  },
  computed: {
    count() {
      const count = this.displayArchived
        ? this.totalArchived
        : this.totalClients;

      return Math.ceil(count / this.rows);
    },
    ...mapState({
      concepts: (state) => state.concepts,
    }),
  },
  watch: {
    page() {
      this.$refs.paginate.selected = this.page - 1;
    },
  },
  methods: {
    sort(value) {
      this.$emit("sortClients", value);
    },

    paginationHandler(page) {
      this.$emit("changePage", page);
    },

    clientConcepts(client) {
      let data = [];
      if (client.orders) {
        let concepts = {};
        client.orders.forEach((o) => {
          concepts[o.concept] = concepts[o.concept] || 0;
          concepts[o.concept]++;
        });
        for (let name in concepts) {
          if (Object.prototype.hasOwnProperty.call(concepts, name)) {
            data.push(
              (this.conceptLogo(name, this.concepts) || "") +
                " (" +
                concepts[name] +
                ") "
            );
          }
        }
      }
      return data;
    },

    clickHandler(client) {
      this.$emit("click", client);

      this.$router.push({
        name: "client-details",
        params: {
          id: client.id,
        },
      });
    },

    goToClient(client) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id: client.id,
        },
      });
      window.open(route.href, "_blank");
    },

    active(client) {
      let sessions = 0;
      let completed = 0;

      if (client.orders) {
        client.orders.forEach((order) => {
          if (order.completed) {
            return;
          }
          sessions += order.session;
          completed += order.sessions.filter((s) => s.completed).length;
        });
        if (sessions !== completed) {
          return completed + "/" + sessions;
        }
      }
    },
  },
};
</script>

<style scope>
.user-link {
  color: #20a8d8;
}

.el-table__row {
  cursor: pointer;
}

.sort-caret.ascending.active {
  border-bottom-color: #20a8d8;
}

.sort-caret.descending.active {
  border-top-color: #20a8d8;
}
</style>

<template>
  <div v-if="discountUsages.length > 0" class="card">
    <div class="card-header"><i class="icon-wallet"></i> Remises</div>
    <div class="card-block">
      <el-table
        empty-text="Aucune remise n'a encore été utilisée"
        :data="discountUsages"
        :default-sort="{ prop: 'usedAt', order: 'descending' }"
      >
        <el-table-column label="Utilisé le" prop="usedAt" sortable>
          <template slot-scope="scope">
            {{ formatDate(scope.row.usedAt) }}
          </template>
        </el-table-column>
        <el-table-column label="Nom" prop="name" sortable>
          <template slot-scope="scope">
            <span v-if="scope.row.promoCode">
              {{ scope.row.promoCode.code }}
            </span>
            <span v-else-if="scope.row.liveSponsorshipDiscount">
              {{ scope.row.liveSponsorshipDiscount.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Description" prop="description">
          <template slot-scope="scope">
            <span v-if="scope.row.promoCode && scope.row.promoCode.description">
              {{ scope.row.promoCode.description }}
            </span>
            <span v-else-if="scope.row.liveSponsorshipDiscount">
              Parrainage
              {{
                scope.row.liveSponsorshipDiscount.requiredSponsoredClientsCount
              }}
              <span v-if="scope.row.liveSponsorshipDiscount.isMax">+</span>
            </span>
            <i v-else>Aucune description</i>
          </template>
        </el-table-column>
        <el-table-column label="CA généré" prop="amount" sortable>
          <template slot-scope="scope">
            {{ formatPrice(scope.row.amount, true) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      discountUsages: [],
    };
  },

  mounted() {
    this.discountUsages = this.client.discountUsages.filter(
      (usage) => usage.promoCode || usage.liveSponsorshipDiscount
    );
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <ClientSponsorshipCard :client="client" />
    </div>
  </div>
</template>

<script>
import ClientSponsorshipCard from "../components/ClientSponsorshipCard";

export default {
  components: {
    ClientSponsorshipCard,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <div
      class="w-75 m-auto bg-primary text-center py-2 px-1"
      style="border-radius: 0.5rem"
    >
      <div class="font-weight-bold h4">
        <i class="fa" :class="stat.icon"></i>
        <span class="h5" style="margin-left: 0.25rem">
          {{ stat.name === "ca" ? formatPrice(stat.value, true) : stat.value }}
        </span>
      </div>
      <div>
        {{ stat.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stat: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="card hidden-print">
      <div class="card-header">
        <i class="icon-refresh"></i> Filtrer par date
      </div>
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-4">
            <label>Date de début</label>
            <datepicker
              v-model="form.date.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-4">
            <label>Date de fin</label>
            <datepicker
              v-model="form.date.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-4" v-if="invoiceSeries">
            <label>Filtrer par code</label>
            <v-select
              :options="
                invoiceSeries
                  .filter((is) => is.code !== 'VISIO')
                  .map((is) => is.code)
              "
              :clearable="false"
              v-model="form.code"
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-secondary float-right"
              @click="loadPayouts()"
            >
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>

    <div class="card" v-else>
      <div class="card-header">
        <span class="expand-switch pr-3">
          <label class="switch switch-sm switch-text switch-info mb-0">
            <input
              type="checkbox"
              class="switch-input"
              v-model="tableExpandAll"
            />
            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>
          Détails ouvert
        </span>
        <span class="total inline-block">
          Total ({{ formatPrice(total || "0.00€") }})
        </span>
        <button
          type="button"
          class="btn btn-sm btn-primary float-right hidden-print"
          @click="print()"
        >
          <i class="fa fa-print"></i>&nbsp; Imprimer
        </button>
      </div>
      <el-table
        :data="payouts"
        :default-sort="{ prop: 'arrival', order: 'descending' }"
        :default-expand-all="tableExpandAll"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.transactions" style="width: 100%">
              <el-table-column width="30">
                <template slot-scope="scope">
                  <span v-html="conceptLogo(scope.row.concept, concepts)" />
                </template>
              </el-table-column>

              <el-table-column label="Paiement" width="130">
                <template slot-scope="scope">
                  {{ formatPrice(scope.row.amount) }}
                </template>
              </el-table-column>

              <el-table-column label="Payé le" width="130">
                <template slot-scope="scope">
                  {{ unixDate(scope.row.created) }}
                </template>
              </el-table-column>

              <el-table-column label="Client">
                <template slot-scope="scope">
                  <router-link
                    v-if="scope.row.order && scope.row.order.refClient"
                    :to="'/clients/' + scope.row.order.refClient.id"
                  >
                    {{ scope.row.order.refClient.fullName }}
                  </router-link>
                </template>
              </el-table-column>

              <el-table-column label="Pack">
                <template slot-scope="scope">
                  <router-link
                    v-if="scope.row.order"
                    :to="'/orders/' + scope.row.order.id"
                  >
                    {{ formatPack(scope.row.order.session) }} ({{
                      formatPrice(scope.row.order.price)
                    }})
                  </router-link>
                </template>
              </el-table-column>

              <el-table-column label="Référence">
                <template slot-scope="scope">
                  <span v-if="scope.row.order && scope.row.order.invoice">
                    {{ scope.row.order.invoice.filename }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Facture"
                align="right"
                class-name="hidden-print"
              >
                <template slot-scope="scope">
                  <a
                    v-if="
                      scope.row.order &&
                      scope.row.order.invoice &&
                      scope.row.order.invoice.pdf
                    "
                    :href="goToPDF(scope.row.order.invoice.pdf)"
                    class="btn btn-sm btn-outline-primary"
                  >
                    <i class="icon-cloud-download"></i> Télécharger
                  </a>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column
          label="Virement le"
          width="200"
          prop="created"
          sortable
        >
          <template slot-scope="scope">
            {{ unixDate(scope.row.created) }}
          </template>
        </el-table-column>

        <el-table-column
          label="Encaissé le"
          width="200"
          prop="arrival"
          sortable
        >
          <template slot-scope="scope">
            {{ unixDate(scope.row.arrival) }}
          </template>
        </el-table-column>

        <el-table-column label="Montant" prop="amount" sortable>
          <template slot-scope="scope">
            {{ formatPrice(scope.row.amount) }}
          </template>
        </el-table-column>

        <el-table-column label="Compte bancaire">
          <template slot-scope="scope">
            <span v-if="scope.row.bank_account">
              {{ scope.row.bank_account.bank_name }}
              <i class="fa fa-ellipsis-h" />
              {{ scope.row.bank_account.last4 }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";

import moment from "moment-timezone";

export default {
  components: {
    Datepicker,
  },

  data() {
    return {
      loading: false,
      tableExpandAll: false,
      payouts: [],
      form: {
        date: {
          end: moment().format(),
          begin: moment().subtract(30, "days").format(),
        },
        code: "COMP",
      },
    };
  },

  computed: {
    total: function () {
      var total = 0;

      this.payouts.forEach(function (payout) {
        total += payout.amount;
      });

      return total;
    },

    ...mapState({
      merchant: (state) => state.merchant,
      concepts: (state) => state.concepts,
      invoiceSeries: (state) => state.invoiceSeries,
    }),
  },

  methods: {
    loadPayouts() {
      if (this.loading || !this.merchant) {
        return;
      }

      this.loading = true;

      this.$api
        .post("/stripe/payouts", {
          date: this.form.date,
          invoiceSerieCode: this.form.code,
        })
        .then((res) => {
          this.payouts = res.data;
          this.loading = false;
        });
    },

    unixDate(ts) {
      return this.formatDate(moment.unix(ts));
    },

    goToPDF(pdf) {
      return axios.defaults.baseURL + "uploads/invoice/" + pdf + ".pdf";
    },

    print() {
      try {
        document.execCommand("print", false, null);
      } catch (e) {
        window.print();
      }
    },
  },

  watch: {
    tableExpandAll() {
      this.$nextTick(() => this.loadPayouts());
    },
  },
};
</script>

<template>
  <div class="animated fadeIn">
    <div
      class="card"
      v-if="faq && hasPermission($store.state.user, 'FAQ_READ')"
    >
      <div class="card-header">
        <i class="icon-question"></i> FAQ
        <button
          type="button"
          class="btn btn-sm btn-danger float-right"
          @click="removeFaq()"
          :disabled="!hasPermission($store.state.user, 'FAQ_WRITE')"
        >
          Supprimer
        </button>
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Question</dt>
          <dd
            class="col-sm-9 editable"
            edit="question"
            :contenteditable="hasPermission($store.state.user, 'FAQ_WRITE')"
            v-on:blur="edited"
          >
            {{ faq.question }}
          </dd>
          <dt class="col-sm-3">Réponse</dt>
          <dd class="col-sm-9">
            <quill-editor
              v-model="content"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange($event)"
              :disabled="!hasPermission($store.state.user, 'FAQ_WRITE')"
            >
            </quill-editor>
            <button
              class="btn btn-primary"
              @click="save"
              :disabled="!hasPermission($store.state.user, 'FAQ_WRITE')"
            >
              Enregistrer
            </button>
            <p class="badge badge-success" v-if="saved">
              Enregistré avec succès
            </p>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      faq: null,
      saved: false,
      content: "",
      editorOption: {},
    };
  },
  created() {
    this.$api
      .get("/faq/" + this.$route.params.id)
      .catch(() => {
        this.$router.push({
          name: "faq",
        });
      })
      .then((res) => {
        this.faq = res.data;
        this.content = this.faq.response;
      });
  },
  methods: {
    onEditorChange({ html }) {
      this.faq.response = html;
    },
    save: function () {
      this.$api
        .put("/faq/" + this.$route.params.id, {
          response: this.faq.response,
        })
        .then((res) => {
          this.faq = res.data;
          this.saved = true;

          setTimeout(() => {
            this.saved = false;
          }, 3000);
        });
    },
    edited: function (event) {
      var data = {};
      var edit = event.target.getAttribute("edit");
      data[edit] = event.target.innerHTML.replace(/(<([^>]+)>)/gi, "");
      event.target.innerHTML = data[edit];

      this.$api.put("/faq/" + this.$route.params.id, data).then((res) => {
        this.faq = res.data;
      });
    },
    removeFaq() {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.delete(`/faq/${this.$route.params.id}`);

          this.$router.push({
            name: "faq",
          });

          window.location.reload(true);
        },
      });
    },
  },
};
</script>

<template>
  <div v-if="!loading">
    <div class="card mt-2">
      <div class="card-header"><i class="fa fa-list"></i> Coachs</div>

      <div class="card-block">
        <el-table
          :data="sortedCoachs"
          row-key="id"
          :default-sort="{ prop: 'rating.average', order: 'descending' }"
          @sort-change="sortChange"
        >
          <el-table-column
            label="Coach"
            prop="coach.name"
            align="left"
            min-width="100"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Cours"
            prop="live.name"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Type"
            prop="live.type"
            align="center"
            sortable="custom"
          >
            <template v-if="scope.row.live" slot-scope="scope">
              {{ scope.row.live.type === "small" ? "Visio" : "Live" }}
            </template>
          </el-table-column>
          <el-table-column
            label="Prévu le"
            prop="session.scheduledAt"
            align="center"
            sortable="custom"
          >
            <template v-if="scope.row.session" slot-scope="scope">
              {{ formatDate(scope.row.session.scheduledAt) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Réservations"
            prop="bookings"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Avis"
            prop="rating.average"
            align="center"
            sortable="custom"
          >
            <template v-if="scope.row.rating" slot-scope="scope">
              <div
                class="d-flex flex-wrap justify-content-center"
                @click="openRatingsModal(scope.row)"
              >
                <StarRating :rating="scope.row.rating.average" />
                <div style="min-width: min-content">
                  {{ scope.row.rating.average }}/5 ({{
                    scope.row.rating.ratings.length
                  }}
                  avis)
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <SessionRatingsModal
      :show="ratingsModal.show"
      :session="ratingsModal.session"
      :sessions="ratingsModal.sessions"
      @close="closeRatingsModal"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";

import StarRating from "../components/StarRating";
import SessionRatingsModal from "../components/SessionRatingsModal";

export default {
  components: {
    StarRating,
    SessionRatingsModal,
  },

  data() {
    return {
      sort: {
        prop: "rating.average",
        order: "descending",
      },

      ratingsModal: {
        show: false,
        session: null,
        sessions: null,
      },
    };
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: null,
    },
  },

  computed: {
    cards() {
      let cards = [];

      if (this.data) {
        for (const [key, value] of Object.entries(this.data)) {
          const card = this.cardTemplates.find(({ name }) => name === key);

          if (card) {
            cards.push({ ...card, data: value });
          }
        }
      }

      return cards;
    },

    coachs() {
      let coachs = [];

      if (this.data) {
        coachs = this.data.coachs;
      }

      return coachs;
    },

    sortedCoachs() {
      const { prop, order } = this.sort;
      const coachs = [...this.coachs];

      switch (prop) {
        case "coach.name":
          return coachs.sort((a, b) => {
            const { name: nameA } = a.coach;
            const { name: nameB } = b.coach;

            if (order === "ascending") {
              if (nameA < nameB) return -1;

              if (nameA > nameB) return 1;

              return 0;
            }

            if (nameA < nameB) return 1;

            if (nameA > nameB) return -1;

            return 0;
          });
        case "live.name":
          return coachs.map((coach) => ({
            ...coach,
            children: coach.children.sort((a, b) => {
              const { name: nameA } = a.live;
              const { name: nameB } = b.live;

              if (order === "ascending") {
                if (nameA < nameB) return -1;

                if (nameA > nameB) return 1;

                return 0;
              }

              if (nameA < nameB) return 1;

              if (nameA > nameB) return -1;

              return 0;
            }),
          }));
        case "live.type":
          return coachs.map((coach) => ({
            ...coach,
            children: coach.children.sort((a, b) => {
              const { type: typeA } = a.live;
              const { type: typeB } = b.live;

              if (order === "ascending") {
                if (typeA < typeB) return -1;

                if (typeA > typeB) return 1;

                return 0;
              }

              if (typeA < typeB) return 1;

              if (typeA > typeB) return -1;

              return 0;
            }),
          }));
        case "session.scheduledAt":
          return coachs.map((coach) => ({
            ...coach,
            children: coach.children.sort((a, b) => {
              const { scheduledAt: scheduledAtA } = a.session;
              const { scheduledAt: scheduledAtB } = b.session;

              if (order === "ascending") {
                if (moment(scheduledAtA) < moment(scheduledAtB)) return -1;

                if (moment(scheduledAtA) > moment(scheduledAtB)) return 1;

                return 0;
              }

              if (moment(scheduledAtA) < moment(scheduledAtB)) return 1;

              if (moment(scheduledAtA) > moment(scheduledAtB)) return -1;

              return 0;
            }),
          }));
        case "bookings":
          return coachs
            .sort((a, b) => {
              if (order === "ascending") {
                return a.bookings - b.bookings;
              }

              return b.bookings - a.bookings;
            })
            .map((coach) => ({
              ...coach,
              children: coach.children.sort((a, b) => {
                if (order === "ascending") {
                  return a.bookings - b.bookings;
                }

                return b.bookings - a.bookings;
              }),
            }));
        case "rating.average":
          return coachs
            .sort((a, b) => {
              if (!a.rating && !b.rating) return 0;

              if (!a.rating) return 1;

              if (!b.rating) return -1;

              const { average: averageA } = a.rating;
              const { average: averageB } = b.rating;

              if (order === "ascending") {
                return averageA - averageB;
              }

              return averageB - averageA;
            })
            .map((coach) => ({
              ...coach,
              children: coach.children.sort((a, b) => {
                if (!a.rating && !b.rating) return 0;

                if (!a.rating) return 1;

                if (!b.rating) return -1;

                const { average: averageA } = a.rating;
                const { average: averageB } = b.rating;

                if (order === "ascending") {
                  return averageA - averageB;
                }

                return averageB - averageA;
              }),
            }));
        default:
          return coachs;
      }
    },
  },

  methods: {
    openRatingsModal(row) {
      if (row.sessionId) {
        this.ratingsModal.session = {
          ...row,
          scheduledAt: row.session.scheduledAt,
        };
      } else {
        this.ratingsModal.sessions = row.children
          .filter((sessionLive) => sessionLive.rating)
          .map((sessionLive) => ({
            ...sessionLive,
            scheduledAt: sessionLive.session.scheduledAt,
          }));
      }

      this.ratingsModal.show = true;
    },

    closeRatingsModal() {
      this.ratingsModal.show = false;
      this.ratingsModal.session = null;
      this.ratingsModal.sessions = null;
    },

    sortChange({ order, prop }) {
      this.sort = {
        ...this.sort,
        prop,
        order,
      };
    },
  },
};
</script>

<template>
  <div>
    <el-table :data="clients" max-height="250">
      <el-table-column label="Nom">
        <template slot-scope="scope">
          <a href="#" @click="goToClient(scope.row.id)">
            <span v-if="scope.row.firstName && scope.row.lastName"
              >{{ scope.row.firstName }} {{ scope.row.lastName }}</span
            >

            <span v-else>{{ scope.row.email }}</span>
          </a>
        </template>
      </el-table-column>

      <el-table-column align="center" label="Société">
        <template slot-scope="scope">
          {{ scope.row.company }}
        </template>
      </el-table-column>

      <el-table-column label="Statut">
        <template slot-scope="scope">
          <StatusRel v-if="scope.row.status" :status="scope.row.status" />

          <p v-if="scope.row.lostReason">
            {{ lostReasonsLabel(scope.row.lostReason) }}
          </p>
        </template>
      </el-table-column>

      <el-table-column label="Facturation" sortable prop="isB2BBilling">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isB2BBilling"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="toggleBilling(scope.row.email)"
            :disabled="
              !hasPermission($store.state.user, 'ACQUISITIONS_CLIENT_WRITE')
            "
          />
        </template>
      </el-table-column>

      <el-table-column align="center" label="Action">
        <template slot-scope="scope">
          <button
            v-if="
              company.clients.length > 1 &&
              hasPermission($store.state.user, 'ACQUISITIONS_CLIENT_WRITE')
            "
            class="btn btn-danger btn-sm"
            @click="unlink(scope.row.email)"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import StatusRel from "../StatusRel";
import { mapGetters } from "vuex";

export default {
  components: {
    StatusRel,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      clients: this.company.clients,
    };
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
      companyLost: "getCompanyLost",
    }),

    globalLostReasons() {
      return this.companyLost.concat(this.lostReasons);
    },
  },

  methods: {
    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    lostReasonsLabel(lostReasonCode) {
      const lostReason = this.globalLostReasons.find(
        (reason) => reason.value === lostReasonCode
      );

      return lostReason ? lostReason.label : null;
    },

    async unlink(clientEmail) {
      this.$confirm({
        message: `Voulez-vous vraiment délier ce client de ${this.company.name} ?`,
        onConfirm: async () => {
          try {
            await this.$api.patch(`/companies/${this.company.id}/unlink`, {
              clientEmail,
            });

            this.clients = this.clients.filter(
              (client) => client.email !== clientEmail
            );
          } catch (error) {
            console.log(error);
          }
        },
      });
    },

    async toggleBilling(clientEmail) {
      await this.$api.post(`/clients/is-b2b-billing`, {
        clientEmail,
      });
    },
  },
  watch: {
    company: {
      handler() {
        this.clients = this.company.clients;
      },
      deep: true,
    },
  },
};
</script>

<style lang="postcss">
.el-table {
  .default-localisation-row {
    background-color: #f7f7f7;
    cursor: default;
  }
}
</style>

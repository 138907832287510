<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <label
          v-if="hasPermission($store.state.user, 'COACHS_PICTURES_WRITE')"
          :class="`d-flex aligin-items-center mb-0 ${
            !coachs.length ? 'text-muted' : ''
          }`"
          style="cursor: pointer"
          ><input
            type="checkbox"
            v-model="selectAll"
            class="mr-05"
            style="cursor: pointer"
            @change="toggleSelectAll"
            :disabled="!coachs.length"
          />
          Tout sélectionner</label
        >

        <div
          v-if="
            selected.length &&
            hasPermission($store.state.user, 'COACHS_PICTURES_WRITE')
          "
          class="d-flex align-items-center"
        >
          <span class="text-muted mr-1"
            >{{ selected.length }} photo(s) sélectionnée(s)</span
          >

          <button
            v-if="status !== 'rejected'"
            type="button"
            class="btn btn-sm btn-danger mr-05"
            :disabled="!!loadingCoachs.length"
            @click="showRejectModal = true"
          >
            <i class="fa fa-times"></i> Refuser
          </button>
          <button
            v-if="status !== 'pending'"
            type="button"
            class="btn btn-sm btn-primary mr-05"
            :disabled="!!loadingCoachs.length"
            @click="editSelected('pending')"
          >
            <i class="fa fa-rotate-left"></i> Mettre en attente
          </button>
          <button
            v-if="status !== 'validated'"
            type="button"
            class="btn btn-sm btn-success"
            :disabled="!!loadingCoachs.length"
            @click="editSelected('validated')"
          >
            <i class="fa fa-check"></i> Valider
          </button>
        </div>
      </div>
    </div>

    <hr class="w-100" />

    <div v-if="total === 0" class="text-center text-muted p-2">
      <span v-if="status === 'pending'"
        >Aucune photo en attente de validation</span
      >
      <span v-else>Aucune photo</span>
    </div>

    <div v-else class="row px-1 pt-1 align-items-start">
      <CoachsPicturesGridItem
        v-for="coach in coachs"
        :key="coach.id"
        :coach="coach"
        :selected="selected.some((c) => c.id === coach.id)"
        :loading="loadingCoachs.includes(coach.email)"
        class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2"
        @select="selectCoach"
        @editValidityStatus="editValidityStatus"
        @error="removeCoachPicture"
      ></CoachsPicturesGridItem>

      <paginate
        v-if="count"
        ref="paginate"
        :selected="page"
        :page-count="count"
        :click-handler="paginationHandler"
        prev-text="Précédent"
        next-text="Suivant"
        container-class="pagination"
        class="col-12"
      ></paginate>

      <ModalRejectCoachPicture
        :show="showRejectModal"
        :close="() => (showRejectModal = false)"
        @confirm="handleRejectSelected"
      />
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import CoachsPicturesGridItem from "./CoachsPicturesGridItem";
import ModalRejectCoachPicture from "./ModalRejectCoachPicture";

export default {
  components: {
    CoachsPicturesGridItem,
    Paginate,
    ModalRejectCoachPicture,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    status: {
      type: String,
      default: "pending",
    },

    total: {
      type: Number,
      default: 0,
    },

    rows: {
      type: Number,
      default: 20,
    },

    page: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      coachs: [],
      selectAll: false,
      loadingCoachs: [],
      showRejectModal: false,
    };
  },

  computed: {
    count() {
      return Math.ceil(this.total / this.rows);
    },

    selected() {
      return this.coachs.filter((coach) => coach.selected);
    },
  },

  watch: {
    data() {
      this.coachs = this.data.map((coach) => ({
        ...coach,
        selected: false,
      }));
    },

    selected() {
      this.selectAll =
        !!this.coachs.length && this.coachs.length === this.selected.length;
    },

    page() {
      if (
        this.$refs.paginate &&
        this.page - 1 !== this.$refs.paginate.selected
      ) {
        this.$refs.paginate.selected = this.page - 1;
      }
    },
  },

  mounted() {
    this.coachs = this.data.map((coach) => ({
      ...coach,
      selected: false,
    }));
  },

  methods: {
    toggleSelectAll() {
      this.coachs = this.coachs.map((coach) => ({
        ...coach,
        selected: this.selectAll,
      }));
    },

    selectCoach(id) {
      this.coachs = this.coachs.map((coach) => ({
        ...coach,
        selected: coach.id === id ? !coach.selected : coach.selected,
      }));
    },

    removeCoachPicture({ email, status }) {
      this.coachs = this.coachs.filter((coach) => coach.email !== email);

      this.$emit("pictureRemoved", status);
    },

    async editValidityStatus({ email, status, reason }) {
      try {
        this.loadingCoachs.push(email);

        await this.$api.put("/users", {
          email,
          coach: {
            photoValidityStatus: status,
          },
        });

        if (status === "rejected") {
          const { id } = this.coachs.find((coach) => coach.email === email);

          await this.$api.post(`/users/${id}/reject-picture`, {
            email,
            reason,
          });
        }

        this.removeCoachPicture({ email, status });
      } catch (e) {
        this.$errorToast(
          "Impossible de modifier le statut de la photo de profil"
        );
      } finally {
        this.loadingCoachs = this.loadingCoachs.filter((e) => e !== email);
      }
    },

    async editSelected(status, reason) {
      for (const coach of this.selected) {
        await this.editValidityStatus({
          email: coach.email,
          status,
          reason,
        });
      }

      this.$emit("reload");
    },

    handleRejectSelected(reason) {
      this.editSelected("rejected", reason);

      this.showRejectModal = false;
    },

    paginationHandler(page) {
      this.$emit("pageChange", page);
    },
  },
};
</script>

<template>
  <div>
    <el-table :data="files" max-height="250">
      <el-table-column label="Nom" sortable prop="fileName">
        <template slot-scope="scope">
          <span>{{ scope.row.fileName }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Date" sortable prop="createdAt">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.createdAt) }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" width="75" label="Action">
        <template slot-scope="scope">
          <div class="d-flex aligm-items" v-if="files.length > 0">
            <button
              class="btn btn-primary btn-sm"
              @click="downloadFile(scope.row.fileId)"
            >
              <i class="fa fa-download"></i>
            </button>
            <button
              class="btn btn-danger btn-sm"
              @click="deleteFile(scope.row.fileId, scope.row.fileName)"
              v-if="hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')"
            >
              <i class="fa fa-trash-o"></i>
            </button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import DownloadFile from "../../mixin/DownloadFile";

export default {
  components: {},

  mixins: [DownloadFile],

  props: {
    companyName: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },

  methods: {
    async downloadFile(id) {
      const { data } = await this.$api.post(`/companies/download/${id}`);

      this.downloadSecuredFile(`/companies/download/file/${id}?key=${data}`);
    },

    async deleteFile(id, fileName) {
      this.$confirm({
        message: `Voulez-vous vraiment supprimer le fichier ${fileName}, cette action est irreversible ?`,
        onConfirm: async () => {
          this.$emit("deleteFile", id);
        },
      });
    },
  },
};
</script>

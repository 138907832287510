<template>
  <div>
    <form>
      <div class="card" v-if="client">
        <div class="card-header">
          <button
            class="btn btn-small btn-info float-right"
            @click="showClient"
            v-if="hasPermission($store.state.user, 'CLIENTS_READ')"
          >
            Voir la fiche
          </button>
          <i class="icon-users"></i> Client : {{ client.email }}
        </div>
        <div class="card-block">
          <div class="row">
            <div class="col-sm-7">
              <dl class="row">
                <dt class="col-sm-5">Prénom</dt>
                <dd class="col-sm-7" @click="showClient">
                  {{ client.firstName }}
                </dd>
                <dt class="col-sm-5">Nom</dt>
                <dd class="col-sm-7" @click="showClient">
                  {{ client.lastName }}
                </dd>
                <dt class="col-sm-5">Adresse</dt>
                <dd class="col-sm-7">{{ formatAddress(client) }}</dd>
                <dt class="col-sm-5">Téléphone</dt>
                <dd class="col-sm-7">{{ client.phone }}</dd>
                <dt class="col-sm-5" v-if="client.gender">Sexe</dt>
                <dd class="col-sm-7" v-if="client.gender">
                  {{ client.gender }}
                </dd>
                <dt class="col-sm-5" v-if="client.preferedCoachGender">
                  Préférence coach
                </dt>
                <dd class="col-sm-7" v-if="client.preferedCoachGender">
                  {{ client.preferedCoachGender }}
                </dd>
                <dt class="col-sm-5">Attribution des séances</dt>
                <dd class="col-sm-7">
                  <label class="switch switch switch-text switch-info">
                    <input
                      type="checkbox"
                      class="switch-input"
                      v-model="sessionsAttribution"
                    />
                    <span
                      class="switch-label"
                      data-on="On"
                      data-off="Off"
                    ></span>
                    <span class="switch-handle"></span>
                  </label>
                  <div v-if="sessionsAttribution" class="text-muted">
                    <i class="fa fa-info-circle mr-05" /> Attribution des
                    séances non-planifiées
                  </div>
                </dd>
                <dt class="col-sm-5">Attribution temporaire</dt>
                <dd class="col-sm-7">
                  <label class="switch switch switch-text switch-warning">
                    <input
                      type="checkbox"
                      class="switch-input"
                      v-model="client.attribution.temporary"
                      @click="handleTemporary"
                    />
                    <span
                      class="switch-label"
                      data-on="On"
                      data-off="Off"
                    ></span>
                    <span class="switch-handle"></span>
                  </label>
                </dd>
                <template v-if="client.attribution.temporary">
                  <dt class="col-sm-5">Date de début</dt>
                  <dd class="col-sm-7">
                    <datepicker
                      v-model="client.attribution.startAt"
                      :value="client.attribution.startAt"
                      v-on:closed="handleTemporary"
                      format="dd.MM.yyyy"
                      language="fr"
                      input-class="form-control"
                    ></datepicker>
                  </dd>
                  <dt class="col-sm-5">Date de fin</dt>
                  <dd class="col-sm-7">
                    <datepicker
                      v-model="client.attribution.endAt"
                      :value="client.attribution.endAt"
                      v-on:closed="handleTemporary"
                      format="dd.MM.yyyy"
                      language="fr"
                      input-class="form-control"
                    ></datepicker>
                  </dd>
                  <dt class="col-sm-5">Limiter les séances</dt>
                  <dd class="col-sm-7">
                    <select
                      class="form-control"
                      v-model="client.attribution.limitSessions"
                      v-on:change="handleTemporary"
                      :value="client.attribution.limitSessions"
                    >
                      <option value="0">Aucune</option>
                      <option
                        v-for="i in [
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ]"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                  </dd>
                </template>
              </dl>
              <p><u>Remarques</u> :</p>
              <textarea
                v-model="client.notes"
                class="form-control"
                rows="4"
                v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
              ></textarea>
              <span v-else>{{ client.notes }}</span>
              <button
                class="btn btn-secondary btn-sm"
                @click="handlEditNotes"
                v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
              >
                Enregistrer
              </button>
              <p class="badge badge-success" v-if="savedNotes">Enregistré !</p>
            </div>
            <div class="col-sm-5">
              <geoloc :geoloc="geoloc" />
            </div>
          </div>
        </div>
      </div>
    </form>

    <filters
      :client="client"
      :clientAttributions="client.attributions"
      :attribution="client.attribution"
      @assign="assignCoach"
      v-if="client"
    />
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Geoloc from "../components/Geoloc";
import Filters from "../components/AttributionFilters";

import moment from "moment-timezone";

export default {
  components: {
    Datepicker,
    Geoloc,
    Filters,
  },
  data() {
    return {
      client: null,
      geoloc: null,
      savedNotes: false,
      sessionsAttribution: false,
    };
  },
  computed: {},
  created() {
    this.loadAttribution();
  },
  methods: {
    handlEditNotes() {
      this.$api
        .put("/clients", {
          email: this.client.email,
          notes: this.client.notes,
        })
        .then(() => {
          this.savedNotes = true;
        });
    },
    handleTemporary() {
      let data = {
        temporary: false,
      };

      this.$nextTick(() => {
        if (
          this.client.attribution.temporary &&
          this.client.attribution.startAt &&
          this.client.attribution.endAt
        ) {
          data.temporary = true;
          data.limitSessions = this.client.attribution.limitSessions;

          if (this.client.attribution.startAt.length !== 10) {
            data.startAt = this.client.attribution.startAt;
          } else {
            data.startAt = moment(
              this.client.attribution.startAt,
              "DD.MM.YYYY"
            ).format();
          }
          if (this.client.attribution.endAt.length !== 10) {
            data.endAt = this.client.attribution.endAt;
          } else {
            data.endAt = moment(
              this.client.attribution.endAt,
              "DD.MM.YYYY"
            ).format();
          }
        }

        this.$api.put("/attributions/" + this.client.attribution.id, data);
      });
    },
    async assignCoach(coach) {
      await this.$api.put(
        `/clients/attribution/${this.client.attribution.id}`,
        {
          sessionsAttribution: this.sessionsAttribution,
          email: this.client.email,
          user: coach.email,
        }
      );

      await this.loadAttribution();
    },
    loadAttribution() {
      this.$api
        .get("/clients/" + this.$route.params.id)
        .catch(() => {
          this.$router.push({
            name: "attributions",
          });
        })
        .then((res) => {
          this.client = res.data;

          if (this.client.geoloc) {
            this.geoloc = {
              lat: this.client.geoloc.coordinates[1],
              lng: this.client.geoloc.coordinates[0],
            };
          }
          if (!this.client.attribution) {
            this.$router.push({
              name: "attributions",
            });
          }
        });
    },
    showClient() {
      this.$router.push({
        name: "client-details",
        params: {
          id: this.client.id,
        },
      });
    },
  },
  watch: {
    "client.attribution.temporary"(value) {
      if (value && !this.client.attribution.startAt) {
        this.client.attribution.startAt = moment().format();
      }
      if (value && !this.client.attribution.endAt) {
        this.client.attribution.endAt = moment().add(15, "days").format();
      }
    },
  },
};
</script>

// The Vue build version to load with the `import` command
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import mixin from "./mixin";
import axios from "axios";
import vueEventCalendar from "vue-event-calendar";
import moment from "moment-timezone";
import "vue-event-calendar/dist/style.css";
import {
  Calendar,
  ColorPicker,
  DatePicker,
  Input,
  InputNumber,
  Option,
  RadioGroup,
  Radio,
  RadioButton,
  Select,
  Switch,
  TimePicker,
  TimeSelect,
  Tag,
  Table,
  TableColumn,
  Popover,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
} from "element-ui";
import * as VueGoogleMaps from "vue2-google-maps";

import lang from "element-ui/lib/locale/lang/fr";
import locale from "element-ui/lib/locale";
import vSelect from "vue-select";
import vClickOutside from "v-click-outside";

import "vue-select/dist/vue-select.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "./scss/style.scss";

import Tooltip from "vue-directive-tooltip";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";
import Toast from "./plugins/toast";
import checkErrors from "./router/interceptor";
import ConfirmModalPlugin from "./plugins/confirm-modal";

import "flag-icons/css/flag-icons.min.css";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBwheKRqS2Bm2c2dsbBl0KUX1f4CdybKgk",
    libraries: "places",
  },
  installComponents: true,
});

Vue.use(vClickOutside);
Vue.use(Tooltip);
Vue.use(Toast);
Vue.use(ConfirmModalPlugin);

Vue.component("v-select", vSelect);

if (!process.env.VUE_APP_API_URL) {
  console.error("Unknown VUE_APP_API_URL environment variable");
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = process.env.VUE_APP_API_TIMEOUT;

Vue.use(vueEventCalendar, { locale: "fr" });
moment.tz.setDefault("Europe/Paris");

const api = axios.create();

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    await checkErrors(error);

    return Promise.reject(error);
  }
);

Vue.api = api;
Vue.prototype.$api = api;

const token = localStorage.getItem("token");

if (token) {
  store.commit("AUTH_TOKEN", token);
}

locale.use(lang);

Vue.use(Calendar);
Vue.use(ColorPicker);
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Option);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(Select);
Vue.use(Switch);
Vue.use(TimePicker);
Vue.use(TimeSelect);
Vue.use(Tag);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(CheckboxButton);

Vue.mixin(mixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div>
    <SapFilters
      :default="filters"
      @openModal="openModal = true"
      @update="handleFilters"
      @sapAdd="handleSapAdd"
    />

    <Loader :isVisible="isLoading" />

    <SapTable
      :clients="clients"
      :total="total"
      :currentPage="currentPage"
      :limit="limit"
      @updatePagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import Loader from "../components/Loader";
import SapFilters from "../components/settings/SapFilters";
import SapTable from "../components/settings/SapTable";

export default {
  components: {
    SapFilters,
    SapTable,
    Loader,
  },

  data() {
    return {
      clients: [],
      filters: {
        clients: [],
        sapNumber: null,
        year: null,
        emailSended: "all",
      },
      isLoading: false,
      modalSendEmailShow: false,
      modalSendEmailValue: {},
      currentPage: 1,
      offset: 0,
      limit: '10',
      total: 0,
    };
  },

  async created() {
    await this.loadClientsSaps();
  },

  methods: {
    async loadClientsSaps(offset = 0) {
      this.isLoading = true;

      try {
        const { data } = await this.$api.get(`/saps`, {
          params: {
            clientFilter: this.filters.client && this.filters.client.value,
            sapNumber: this.filters.sapNumber,
            year: this.filters.year,
            emailSended: this.filters.emailSended,
            offset,
            limit: this.limit,
          }
      });
        this.clients = data.clients;
        this.total = data.total;
        this.limit = data.limit;
        this.offset = data.offset;
      } catch (e) {
        this.$router.push({
          name: "dashboard"
        });
      }

      this.isLoading = false;
    },

    async handleFilters(newFilters) {
      this.filters = newFilters;

      this.currentPage = 1;
      await this.loadClientsSaps();
    },

    async handleCurrentChange(val) {
      this.currentPage = val;
      this.offset = (val - 1) * this.limit;

      await this.loadClientsSaps(this.offset);
    },

    async handleSapAdd() {
      this.currentPage = 1;
      await this.loadClientsSaps();
    },

    async onModalSendEmailClose() {
      this.modalSendEmailShow = false;
    },
  },
};
</script>

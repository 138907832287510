import Vue from "vue";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(ToastPlugin);

const Toast = {
  install(Vue) {
    Vue.prototype.$errorToast = (message) => {
      Vue.$toast.open({
        message,
        type: "error",
        duration: 4000,
      });
    };
    Vue.prototype.$successToast = (message) => {
      Vue.$toast.open({
        message,
        duration: 4000,
      });
    };
    Vue.prototype.$warningToast = (message) => {
      Vue.$toast.open({
        type: "warning",
        message,
        duration: 4000,
      });
    };
  },
};

export default Toast;

<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <tabs v-model="activeTab">
        <tab
          :key="`tab-${index}`"
          v-for="(tab, index) of tabs"
          :header="tab.header"
        >
          <component
            v-bind:is="tab.component"
            :tags="tags"
            @reload-tags="loadTags"
          ></component>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import FaqTagsCard from "../components/settings/FaqTagsCard";
import FaqCard from "../components/settings/FaqCard";

import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Tabs,
    Tab
  },

  data() {
    return {
      activeTab: 1,
      tabs: [
        {
          header: "Catégories",
          component: FaqTagsCard
        },
        {
          header: "Faq's",
          component: FaqCard
        }
      ],
      tags: []
    };
  },
  async created() {
    await this.loadTags();
  },
  methods: {
    async loadTags() {
      try {
        const { data } = await this.$api.get(`/faq/tags`);
        this.tags = data;
      } catch (e) {
        this.$router.push({
          name: "dashboard"
        });
      }
    }
  }
};
</script>

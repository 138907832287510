<template>
  <modal
    :title="form.id ? 'Éditer' : 'Ajouter'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="form.id ? 'Éditer' : 'Ajouter'"
    @cancel="() => closeModal()"
    @ok="form.id ? handleEditSponsorship() : handleAddSponsorship()"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" :text="loadingText" />
    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">{{ form.id ? "Éditer" : "Ajouter" }}</h4>
    </div>
    <div v-if="formError" class="alert alert-danger">{{ formError }}</div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Nom</label>
          <input
            type="text"
            v-model="form.name"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Parrainages requis *</label>
          <input
            type="number"
            v-model="form.requiredSponsoredClientsCount"
            min="0"
            max="100"
            step="1"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Remise Parrain *</label>
          <div class="input-group">
            <input
              type="number"
              v-model="form.discount"
              min="0"
              max="100"
              step="0.01"
              class="form-control"
              :disabled="editMode"
            />
            <span class="input-group-addon">%</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mb-2">
        <div class="form-group d-flex align-items-center">
          <input
            type="checkbox"
            v-model="form.isMax"
            style="margin-right: .5rem;"
          />
          <label class="mb-0">
            Remise maximum
            <span
              v-tooltip="isMaxTooltip"
              class="fa fa-info-circle"
            ></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row" v-if="form.stripeId">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Stripe ID</label>
          <input
            type="text"
            v-model="form.stripeId"
            class="form-control"
            disabled
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from '../../components/Loader'

export default {
  components: {
    Modal,
    Loader
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    },
    discount: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    form: {
      id: null,
      name: null,
      requiredSponsoredClientsCount: null,
      discount: null,
      stripeId: null,
      isMax: false
    },
    modalOpened: false,
    loading: false,
    formError: false,
    isMaxTooltip: 'En cochant cette option, vous faites du nombre de parrainages requis un minimum. Par exemple, une remise ayant pour minimum 5 s\'appliquera a un client ayant 6 parrainages. ATTENTION: il ne peut y avoir qu\'une seule remise maximum, s\'il en existe déjà une, la création de la remise échouera.'
  }),

  computed: {
    loadingText() {
      return this.form.id
        ? 'Edition de la remise et vérification de l\'éligibilité des bénéficiaires'
        : 'Création de la remise et application aux parrains éligibles'
    }
  },

  async mounted() {
    this.form = { ...this.discount };
  },

  methods: {
    async handleAddSponsorship() {
      if (this.loading) {
        return false;
      }

      this.formError = false
      this.loading = true;

      try {
        this.validateForm()

        if (!this.formError) {
          const data = {
            name: this.form.name,
            requiredSponsoredClientsCount: parseInt(this.form.requiredSponsoredClientsCount),
            discount: parseFloat(parseFloat(this.form.discount).toFixed(2)),
            isMax: this.form.isMax
          }

          await this.$api.post("/live-sponsorships/discounts", data);

          this.closeModal();
        }
      } catch (e) {
        this.formError = `Une erreur s'est produite, veuillez réessayer`;
      }

      this.loading = false;
    },

    async handleEditSponsorship() {
      this.formError = false
      this.loading = true;

      try {
        this.validateForm()

        if (!this.formError) {
          const data = {
            name: this.form.name,
            requiredSponsoredClientsCount: parseInt(this.form.requiredSponsoredClientsCount),
            isMax: this.form.isMax
          }

          await this.$api.put(`/live-sponsorships/discounts/${this.form.id}`, data);

          this.closeModal();
        }
      } catch (e) {
        this.formError = `Une erreur s'est produite, veuillez réessayer`;
      }

      this.loading = false
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;

      this.$emit("close");
    },

    validateForm() {
      if (
        !this.form.requiredSponsoredClientsCount ||
        parseInt(this.form.requiredSponsoredClientsCount) < 1
      ) {
        this.formError = 'Le nombre de parrainages requis doit être renseigné et être un nombre supérieur à 0'
        return
      }

      if (
        !this.editMode &&
        (
          !this.form.discount ||
          parseFloat(this.form.discount) <= 0 ||
          parseFloat(this.form.discount) > 100
        )
      ) {
        this.formError =  'Un pourcentage de remise est requis et doit être un nombre compris entre 0 et 100'
        return
      }
    }
  },

  watch: {
    modalShow(val) {
      this.modalOpened = val;

      if (val) {
        this.form = { ...this.discount }
      }
    }
  }
};
</script>

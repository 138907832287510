<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="card-header d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center" style="gap: 5rem">
            <div>
              <i class="fa fa-commenting" style="margin-right: 0.5rem"></i
              >Notebook
            </div>
            <div class="font-weight-bold">Total ({{ total }})</div>
            <div>Commerciaux ({{ totalCommercial }})</div>
            <div>Coachs ({{ totalCoach }})</div>
          </div>
          <div class="float-right">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click.stop="showNotebook = true"
            >
              <i class="icon-eye"></i>&nbsp; Consulter
            </button>
          </div>
        </div>
      </div>
    </div>

    <ModalNotebook
      :show="showNotebook"
      :notebook="notebook"
      :client="client"
      :order="order"
      @close="showNotebook = false"
      @added="noteAdded"
      @updated="noteUpdated"
      @deleted="noteDeleted"
    />
  </div>
</template>

<script>
import ModalNotebook from "./ModalNotebook.vue";

export default {
  components: {
    ModalNotebook,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    client: {
      type: Object,
      default: null,
    },

    order: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showNotebook: false,
      notebook: [],
    };
  },

  computed: {
    total() {
      return this.notebook.length;
    },

    totalCommercial() {
      return this.notebook.filter((note) => !!note.commercial || !!note.admin)
        .length;
    },

    totalCoach() {
      return this.notebook.filter((note) => !!note.coach).length;
    },
  },

  watch: {
    data(value) {
      this.notebook = value ? [...value] : [];
    },
  },

  created() {
    this.notebook = this.data ? [...this.data] : [];
  },

  methods: {
    noteAdded(note) {
      this.notebook.unshift(note);

      this.$emit("noteAdded", note);
    },

    noteUpdated(note) {
      this.notebook = this.notebook.map((n) => (n.id === note.id ? note : n));

      this.$emit("noteUpdated", note);
    },

    noteDeleted(noteId) {
      this.notebook = this.notebook.filter((note) => note.id !== noteId);

      this.$emit("noteDeleted", noteId);
    },
  },
};
</script>

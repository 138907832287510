import Vue from "vue";

export default {
  AUTH_TOKEN(state, token) {
    state.authToken = token;
    localStorage.setItem("token", token);
    Vue.api.defaults.headers.common["Authorization"] = token;
  },

  AUTH_CHANGE_PASSWORD(state, change) {
    state.changePassword = change;
  },

  AUTH_USER(state, user) {
    state.user = user;
  },

  AUTH_LOGOUT(state) {
    state.authToken = null;
    state.user = null;
    state.merchant = null;
    state.concepts = null;
    state.changePassword = false;

    localStorage.removeItem("token");
    delete Vue.api.defaults.headers.common["Authorization"];
  },

  SWAP_MERCHANT(state, merchant) {
    state.merchant = merchant;
    localStorage.setItem("merchant", state.merchant.id);
  },

  SET_INVOICE_SERIE(state, invoiceSerie) {
    state.invoiceSerie = invoiceSerie;
    localStorage.setItem("invoiceSerieCode", state.invoiceSerie.code);
  },

  MERCHANTS(state, merchants) {
    var merchantId = localStorage.getItem("merchant");
    state.merchants = merchants;

    if (!state.merchant && merchants.length) {
      state.merchant = merchants[0];

      if (merchantId) {
        for (var i in merchants) {
          if (merchants[i].id === merchantId) {
            state.merchant = merchants[i];
          }
        }
      }
    }

    localStorage.setItem("merchant", state.merchant.id);
  },

  INVOICE_SERIES(state, invoiceSeries) {
    state.invoiceSeries = invoiceSeries;

    const invoiceSerieCode = localStorage.getItem("invoiceSerieCode");

    if (!state.invoiceSerie && invoiceSeries.length) {
      state.invoiceSerie = invoiceSeries[0];

      if (invoiceSerieCode) {
        for (var i in invoiceSeries) {
          if (invoiceSeries[i].code === invoiceSerieCode) {
            state.invoiceSerie = invoiceSeries[i];
          }
        }
      }
    }

    localStorage.setItem("invoiceSerieCode", state.invoiceSerie.code);
  },

  CONCEPTS(state, concepts) {
    state.concepts = concepts;
  },
};

<template>
  <div class="card commercial-relation">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <span class="font-weight-bold"> Commercial</span>
        <el-select
          v-if="hasPermission($store.state.user, 'ORDERS_COMMERCIALS_WRITE')"
          value=""
          size="mini"
          @change="addCommercial($event)"
        >
          <el-option
            v-for="commercial in available"
            :key="commercial.value"
            :value="commercial.value"
            >{{ commercial.label }}</el-option
          >
        </el-select>
      </div>
      <div v-if="related.length > 0" style="padding: 0.5rem 0">
        <div
          v-for="(commercial, index) in related"
          :key="`commercial-${commercial.value}`"
          :class="`d-flex justify-content-between ${
            index === 0 ? 'my-1' : 'mb-1'
          }`"
        >
          <span
            class="text-white rounded-3 mr-1 d-flex align-items-center"
            style="padding: 7px"
            :style="commercial.color && `background-color:${commercial.color}`"
            :class="!commercial.color && 'bg-primary'"
          >
            <span>{{ commercial.label }}</span>
            <span
              v-if="
                hasPermission($store.state.user, 'ORDERS_COMMERCIALS_WRITE')
              "
              class="ml-1"
              style="cursor: pointer"
              @click="removeCommercial(commercial.value)"
              ><i class="icon-close"></i
            ></span>
          </span>

          <datepicker
            v-if="hasPermission($store.state.user, 'ORDERS_COMMERCIALS_WRITE')"
            v-model="commercial.relatedSince"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="newCommercialRelationDate($event, commercial.value)"
            :disabled="disabledDates"
          />
          <span v-else>{{ formatDate(commercial.relatedSince) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      commercials: [],
      disabledDates: {
        to: moment().toDate(),
        from: moment().toDate(),
      },
    };
  },

  computed: {
    related() {
      return this.commercials
        .filter((com) =>
          this.order.commercials?.some(
            ({ userEmail, value }) =>
              userEmail === com.value || value === com.value
          )
        )
        .map((com) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { relatedSince } = this.order.commercials?.find(
            ({ userEmail, value }) =>
              userEmail === com.value || value === com.value
          );

          return { ...com, relatedSince };
        });
    },

    available() {
      return this.commercials.filter(
        ({ value }) => !this.related.some((com) => com.value === value)
      );
    },
  },

  mounted() {
    this.fetchCommercials();

    if (
      this.hasPermission(this.$store.state.user, "ORDERS_COMMERCIALS_WRITE") ||
      this.isAdmin(this.$store.state.user)
    ) {
      this.disabledDates = {
        to: moment(this.order.createdAt)
          .endOf("day")
          .subtract(1, "days")
          .toDate(),
        from: moment().endOf("day").toDate(),
      };
    }
  },

  methods: {
    async fetchCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((user) => ({
          label: user.commercial.pseudo,
          value: user.email,
          mobile: user.commercial.mobile,
          color: user.commercial.color,
        }));
      } catch (e) {
        this.commercials = [];
      }
    },

    async addCommercial(commercial) {
      try {
        await this.$api.post("/orders/commercial", {
          orderId: this.order.id,
          commercial,
        });

        const currentCommercial = this.commercials.find(
          ({ value }) => value === commercial
        );

        this.order.commercials.push({
          ...currentCommercial,
          relatedSince: new Date(),
        });
      } catch (e) {
        this.$errorToast(
          "Impossible d'ajouter le commercial, recharger la page pour vérifier qu'il n'est pas déjà attribué."
        );
      }
    },

    removeCommercial(commercial) {
      this.$confirm({
        message: "Supprimer le lien commercial ?",
        onConfirm: async () => {
          await this.$api.delete("/orders/commercial", {
            data: {
              orderId: this.order.id,
              commercial,
            },
          });

          const currentCommercial = this.commercials.find(
            ({ value }) => value === commercial
          );

          this.order.commercials = this.order.commercials.filter(
            ({ userEmail, value }) => {
              if (value) {
                return value !== currentCommercial.value;
              }

              if (userEmail) {
                return userEmail !== currentCommercial.value;
              }
            }
          );
        },
      });
    },

    async newCommercialRelationDate(relatedSince, commercial) {
      if (relatedSince < moment(this.order.createdAt).startOf("day")) {
        this.$errorToast(
          `Impossible d'attribuer la commande au commercial à une date antérieure à la création de la commande (${moment(
            this.order.createdAt
          ).format("DD-MM-YYYY")})`
        );
      } else if (relatedSince > moment().endOf("day")) {
        this.$errorToast(
          "Impossible d'attribuer la commande au commercial à une date supérieure à aujourd'hui"
        );
      } else {
        await this.$api.put(`/orders/${this.order.id}/commercial`, {
          commercial,
          relatedSince,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.commercial-relation {
  .vdp-datepicker__calendar {
    right: 0;
  }
}
</style>

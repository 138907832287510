<template>
  <div
    class="card"
    v-if="
      ['faq'].indexOf($route.name) > -1 &&
      hasPermission($store.state.user, 'FAQ_READ')
    "
  >
    <div class="card-header">
      <i class="fa fa-question-circle-o"></i> Liste des questions/réponses
    </div>
    <div class="card-block">
      <table v-if="currentFaqs.length > 0" class="table table-condensed">
        <thead>
          <tr>
            <th></th>
            <th class="text-center">#</th>
            <th>Question</th>
            <th class="text-center">Catégorie(s)</th>
            <th
              v-if="hasPermission($store.state.user, 'FAQ_WRITE')"
              class="text-center"
            >
              Actions
            </th>
          </tr>
        </thead>
        <draggable
          v-model="currentFaqs"
          tag="tbody"
          handle=".handle"
          @change="dragged"
        >
          <tr
            v-for="(faq, index) in currentFaqs"
            :key="faq.id"
            @click.stop="handleEditFaq(faq)"
            class="click"
          >
            <td class="cursor-move text-center handle">
              <i class="fa fa-arrows"></i>
            </td>
            <td class="text-center">
              {{ index + 1 }}
            </td>
            <td>{{ faq.question }}</td>
            <td class="text-center">
              <span
                class="bg-info"
                style="padding: 5px 10px; margin: 0 3px"
                :key="index"
                v-for="(tag, index) in faq.faqTags"
              >
                {{ tag.refTag.label }}
              </span>
            </td>
            <td
              v-if="hasPermission($store.state.user, 'FAQ_WRITE')"
              class="d-flex justify-content-center"
              style="gap: 5px"
            >
              <button
                class="btn btn-primary btn-sm"
                @click.stop="handleEditFaq(faq)"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                class="btn btn-danger btn-sm"
                @click.stop="handleDeleteFaq(faq)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </td>
          </tr>
        </draggable>
      </table>

      <p v-else class="w-100 p-1 text-center">
        Cette catégorie est vide, veuillez en séléctionner une autre pour
        afficher les questions/réponses associées.
      </p>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },

  props: {
    faqs: {
      type: Array,
      required: true,
    },

    tag: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      currentFaqs: [],
    };
  },

  watch: {
    faqs: {
      deep: true,
      handler() {
        this.currentFaqs = this.faqs;
      },
    },
  },

  mounted() {
    this.currentFaqs = this.faqs;
  },

  methods: {
    handleDeleteFaq(faq) {
      this.$confirm({
        message: "Êtes vous sûr de vouloir supprimer cette FAQ ?",
        onConfirm: async () => {
          await this.$api.delete(`/faq/${faq.id}`);

          this.$emit("delete");
        },
      });
    },

    async handleEditFaq(faq) {
      if (this.hasPermission(this.$store.state.user, "FAQ_WRITE")) {
        this.$emit("edit", faq);
      }
    },

    async dragged({ moved }) {
      const { element, newIndex } = moved;

      try {
        await this.$api.patch(`/faq/${element.id}/list-index`, {
          listIndex: newIndex + 1,
          tagId: this.tag.id,
        });
      } catch (e) {
        this.$emit("reload");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-move {
  cursor: move;
}

.sortable-chosen {
  background: #f7f7f7;
}

.sortable-drag {
  background: #f7f7f7;
}
</style>

<template>
  <el-table :data="subscriptions" emptyText="Aucun abonnement">
    <el-table-column label="Début" prop="created">
      <template slot-scope="scope">
        {{ formatDate(scope.row.created * 1000) }}
      </template>
    </el-table-column>
    <el-table-column label="Mode" prop="type" align="center"></el-table-column>
    <el-table-column label="Statut" prop="status" align="center">
      {{ trialing ? "Essai" : canceled ? "Annulé" : "Actif" }}
    </el-table-column>
    <el-table-column
      v-if="showPromoCodeCol"
      label="Promo"
      prop="promoCode.code"
      align="center"
    ></el-table-column>
    <el-table-column label="Factures" prop="invoices" align="center" width="80">
      <template v-if="scope.row.invoices" slot-scope="scope">
        {{ scope.row.invoices.length }}
      </template>
    </el-table-column>
    <el-table-column
      v-if="trialing"
      label="Fin essai"
      prop="trial_end"
      align="right"
    >
      <template slot-scope="scope">
        {{ formatDate(scope.row.trial_end * 1000) }}
      </template>
    </el-table-column>
    <el-table-column
      v-else-if="canceled"
      label="Date de fin"
      prop="canceled_at"
      align="right"
    >
      <template slot-scope="scope">
        {{ formatDate(scope.row.canceled_at * 1000) }}
      </template>
    </el-table-column>
    <el-table-column
      v-else
      label="Prochaine échéance"
      prop="current_period_end"
      align="right"
      min-width="120"
    >
      <template slot-scope="scope">
        {{ formatDate(scope.row.current_period_end * 1000) }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    subscriptions: {
      type: Array,
      default: () => [],
    },

    trialing: {
      type: Boolean,
      default: false,
    },

    canceled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showPromoCodeCol() {
      return this.subscriptions.some((sub) => sub.promoCode);
    },
  },
};
</script>

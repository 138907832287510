<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: {
    height: {
      type: Number,
      default: 200,
    },

    clients: {
      type: Object,
      default: null,
    },

    total: {
      type: Number,
      default: 0,
    },

    lostReasons: {
      type: Array,
      required: true,
    },

    chartData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      options: {
        events: [],
        cutoutPercentage: 70,
      },
    };
  },

  computed: {
    datasets() {
      let datasets = [];
      let dataset = {
        backgroundColor: [],
        data: [],
      };

      if (this.clients) {
        for (const [key, value] of Object.entries(this.clients)) {
          if (key === "no-reason") {
            dataset.backgroundColor.push("#d3d3d3");
          } else {
            const lostReason = this.lostReasons.find(
              (reason) => reason.value === key
            );

            dataset.backgroundColor.push(lostReason.color);
          }

          dataset.data.push(value.length);
        }
      }

      datasets.push(dataset);

      return datasets;
    },

    computedChartData() {
      return {
        datasets: this.datasets,
      };
    },
  },

  mounted() {
    this.renderChart(this.computedChartData, this.options);
  },

  watch: {
    clients() {
      this.renderChart(this.computedChartData, this.options);
    },
  },
};
</script>

<template>
  <div class="animated fadeIn">
    <FormExpenseFilters
      :default="filters"
      @update="handleFilters"
      @load="loadData"
    />

    <FormExpenseTable
      v-if="formExpenseData.length > 0"
      :form-expense-data="formExpenseData"
    />
  </div>
</template>

<script>
import FormExpenseFilters from "../components/FormExpenseFilters";
import FormExpenseTable from "../components/FormExpenseTable";

import moment from "moment";

export default {
  components: {
    FormExpenseFilters,
    FormExpenseTable,
  },
  data() {
    return {
      filters: {
        begin: "",
        end: "",
      },
      formExpenseData: [],
    };
  },
  async created() {
    this.filters.begin = moment().subtract(6, "month").startOf("day");
    this.filters.end = moment().endOf("day");
  },

  methods: {
    handleFilters(newFilters) {
      this.filters = newFilters;
    },

    async loadData() {
      await this.fetchClients();
    },

    async fetchClients() {
      try {
        const { begin, end } = this.filters;

        const { data } = await this.$api.get("/marketings/form-expense", {
          params: { begin, end },
        });

        this.formExpenseData = data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

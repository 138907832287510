<template>
  <modal
    :title="form.id ? 'Éditer' : 'Ajouter'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="form.id ? 'Éditer' : 'Ajouter'"
    @cancel="() => closeModal()"
    @ok="form.id ? handleEditType() : handleAddType()"
    effect="fade/zoom"
  >
    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">{{ form.id ? "Éditer" : "Ajouter" }}</h4>
    </div>
    <div v-if="formError" class="alert alert-danger">{{ formError }}</div>
    <div class="form-group">
      <label>Nom *</label>
      <input
        v-model="form.name"
        type="text"
        class="form-control"
        placeholder="exemple : Yoga"
      />
    </div>
    <div class="form-group">
      <label>Photo</label>
      <a
        v-if="form.photoId"
        class="pull-right"
        target="_blank"
        :href="`${apiUrl}live-origins/${form.id}/photo`"
        >Voir l'image</a
      >
      <dropzone
        :url="apiUploads()"
        :headers="apiAuthorization()"
        id="photo"
        v-on:vdropzone-success="photoUploaded"
      >
        <div class="dz-default dz-message">
          <span
            ><i class="icon-cloud-upload"></i><br />Glissez l'image ici</span
          >
        </div>
      </dropzone>
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea v-model="form.description" class="form-control" />
    </div>
    <div class="form-group">
      <label>Couleur Calendrier</label>
      <div>
        <el-color-picker v-model="form.colorCalendar"></el-color-picker>
      </div>
    </div>
    <div class="form-group">
      <label>
        Durée du cours (minutes)
      </label>
      <input v-model="form.duration" type="number" class="form-control" />
    </div>
    <div class="form-group">
      <label>
        Quotas de personne
        <span v-if="form.type === 'small'">(9 max.)</span>
      </label>
      <input v-model="form.quotas" type="number" class="form-control" />
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "vue-strap/src/Modal";
import Dropzone from "vue2-dropzone";

export default {
  components: {
    Modal,
    Dropzone
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true
    },
    origin: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    form: {
      id: null,
      name: null,
      photoId: null,
      description: null,
      colorCalendar: null,
      duration: 60,
      quotas: null
    },
    modalOpened: false,
    loading: false,
    formError: false
  }),

  mounted() {
    this.form = { ...this.origin };
    this.modalOpened = this.modalShow;
  },

  methods: {
    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token")
      };
    },

    photoUploaded(file, response) {
      this.form.photoId = response.id;
    },

    async handleAddType() {
      if (this.loading) {
        return false;
      }

      if (!this.form.name) {
        this.formError = `Le champ Nom est obligatoire`;

        return false;
      }

      this.loading = true;

      try {
        await this.$api.post("/live-origins", this.form);
        this.closeModal();
      } catch (e) {
        this.formError = `Une erreur s'est produite`;
      }
    },

    async handleEditType() {
      this.loading = true;

      await this.$api.patch(`/live-origins/${this.form.id}`, this.form);

      this.closeModal();
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;
      this.form = {};

      this.$emit("close");
    }
  },

  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL || "";
    }
  },

  watch: {
    modalShow: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
      }
    },
    origin: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.form = newVal;
      }
    }
  }
};
</script>

<template>
  <div class="wrapper">
    <Tabs v-model="activeTab" @input="changeTab" class="empty-tab">
      <Tab
        :key="`tab-${index}`"
        v-for="(item, index) of tabs"
        :header="item.label"
      >
      </Tab>
    </Tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

import { mapState } from "vuex";

export default {
  components: {
    Tabs,
    Tab,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        { label: "Liste", name: "list", children: ["prospects", "clients"] },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    if (this.hasPermission(this.user, "SUBSTITUTIONS_READ")) {
      this.tabs.push({ label: "Remplacements", name: "clientSubstitutions" });
    }

    if (this.$route.name === "clientsNav") {
      this.$router.push({ name: this.tabs[0].name });
    }

    this.setCurrentTab(this.$route.name);
  },
  methods: {
    changeTab(index) {
      this.$router.push({ name: this.tabs[index].name });
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.tabs.findIndex((tab) => tab.name === currentPath);
    },
  },
  watch: {
    $route(current) {
      if (current.name === "clientsNav") {
        this.$router.push({ name: this.tabs[0].name });
      }

      const currentTab = this.tabs.find((tab) => tab.name === current.name);

      if (currentTab?.children) {
        this.$router.push({ name: currentTab.children[0] });
      }

      const parentTab = this.tabs.find((tab) =>
        tab.children?.includes(current.name)
      );

      this.setCurrentTab(parentTab ? parentTab.name : current.name);
    },
  },
};
</script>

<style lang="scss">
.empty-tab {
  .nav.nav-tabs {
    margin-bottom: 15px;
  }

  & > .tab-content {
    display: none !important;
  }
}
</style>

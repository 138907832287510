<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-ban"></i> Bans
      <button
        type="button"
        class="btn btn-sm btn-primary float-right"
        @click="modalLiveBan.show = true"
        :disabled="!hasPermission($store.state.user, 'CLIENTS_WRITE')"
      >
        <i class="fa fa-plus"></i>&nbsp; Ajouter
      </button>
    </div>
    <div class="card-block">
      <div v-if="error" class="alert alert-danger">
        Un problème est survenu lors du chargement des bans du client.
      </div>
      <div v-else-if="bans.length === 0" class="text-muted text-center">
        Aucun ban pour ce client
      </div>
      <Tabs v-else v-model="activeTab">
        <Tab :header="currentHeader">
          <ClientBanishmentList
            :data="currentBanishments"
            @delete-ban="deleteBanishment"
          />
        </Tab>
        <Tab :header="pastHeader">
          <ClientBanishmentList
            :data="pastBanishments"
            past
            @delete-ban="deleteBanishment"
          />
        </Tab>
      </Tabs>
    </div>

    <modal
      title="Bannir le client des services Live"
      v-model="modalLiveBan.show"
      :backdrop="false"
      cancelText="Annuler"
      okText="Appliquer"
      @ok="banClientFromLive()"
      @cancel="closeBanModal()"
      effect="fade/zoom"
    >
      <div v-if="modalLiveBan.error" class="alert alert-danger">
        Un problème est survenu, veuillez réessayer.
      </div>

      <div v-else>
        <div class="row mb-1">
          <div class="col-12 d-flex flex-column">
            <label class="font-weight-bold">Mode</label>
            <el-switch
              v-model="modalLiveBan.hourMode"
              active-text="Heures"
              inactive-text="Date"
            />
          </div>
        </div>

        <div v-if="modalLiveBan.hourMode" class="row mb-1">
          <div class="col-12 d-flex flex-column">
            <label class="font-weight-bold">Durée du ban (heures)</label>
            <el-radio-group v-model="modalLiveBan.duration">
              <el-radio
                v-for="duration in liveBan.durations"
                :key="duration"
                :label="duration"
              >
                {{ duration }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>

        <div v-else class="row mb-1">
          <div class="col-12 d-flex flex-column">
            <label class="font-weight-bold">Date de fin</label>
            <datepicker
              v-model="modalLiveBan.until"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            />
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-12">
            <label class="font-weight-bold">Raison</label>
            <v-select :options="liveBan.reasons" v-model="modalLiveBan.reason">
            </v-select>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label class="font-weight-bold">Notes</label>
            <el-input v-model="modalLiveBan.notes" type="textarea" :rows="3" />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";
import ClientBanishmentList from "../components/ClientBanishmentList";

export default {
  components: {
    Tabs,
    Tab,
    Modal,
    Datepicker,
    ClientBanishmentList,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },

    banishments: {
      type: Array,
      required: true,
    },

    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeTab: 0,
      bans: [],
      modalLiveBan: {
        show: false,
        error: false,
        hourMode: false,
        duration: 1,
        until: new Date(),
        reason: null,
        notes: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      liveBan: "getLiveBanSettings",
    }),

    currentBanishments() {
      return this.bans.filter(
        (ban) => new Date(ban.until).getTime() > new Date().getTime()
      );
    },

    pastBanishments() {
      return this.bans.filter(
        (ban) => new Date(ban.until).getTime() <= new Date().getTime()
      );
    },

    currentHeader() {
      return `En cours (${this.currentBanishments.length})`;
    },

    pastHeader() {
      return `Passés (${this.pastBanishments.length})`;
    },
  },

  mounted() {
    this.bans = [...this.banishments];
  },

  methods: {
    deleteBanishment(banId) {
      this.$confirm({
        message:
          "Voulez-vous vraiment supprimer le ban pour ce client ? Cette action est irréversible.",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/clients/banishment/${banId}`);

            this.bans = this.bans.filter(({ id }) => id !== banId);
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    async banClientFromLive() {
      try {
        this.modalLiveBan.error = false;

        const until = this.modalLiveBan.hourMode
          ? moment().add(this.modalLiveBan.duration, "hours").toDate()
          : this.modalLiveBan.until;
        const reason = this.modalLiveBan.reason?.value;

        const { data } = await this.$api.post("/clients/banishment", {
          email: this.client.email,
          until,
          reason,
          notes: this.modalLiveBan.notes,
        });

        this.bans.push(data);
        this.closeBanModal();
      } catch (e) {
        this.modalLiveBan.error = true;
      }
    },

    closeBanModal() {
      this.modalLiveBan.show = false;
      this.modalLiveBan.error = false;
    },
  },
};
</script>

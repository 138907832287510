<template>
  <div class="wrapper">
    <div class="card">
      <div class="card-header">
        Facture globale Live
      </div>

      <div class="card-block">
        <div class="row">
          <div class="col-12 text-muted mb-1">
            Génère une facture globale des commandes Live (abonnements) pendant
            la période sélectionnée, avec les totaux journaliers non détaillés.
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-6">
            <label>Date de début</label>
            <datepicker
              v-model="begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
              :disabled="disabledBegin"
            ></datepicker>
          </div>
          <div class="form-group col-sm-6">
            <label>Date de fin</label>
            <datepicker
              v-model="end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
              :disabled="disabledEnd"
              @input="newEndDate"
            ></datepicker>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 d-flex align-items-center">
            <button
              type="button"
              class="btn btn-sm btn-primary float-left"
              @click="downloadFile"
              :disabled="!hasPermission($store.state.user, 'LIVE_MENU_READ')"
            >
              <i class="fa fa-download"></i>&nbsp; Télécharger
            </button>

            <div v-if="loading" class="pl-1">Chargement en cours...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker
  },

  data() {
    return {
      loading: false,
      begin: moment()
        .startOf("month")
        .toDate(),
      end: moment().toDate(),
      disabledEnd: {
        from: moment()
          .endOf("day")
          .toDate()
      },
      disabledBegin: {
        from: moment()
          .endOf("day")
          .toDate()
      }
    };
  },

  methods: {
    async downloadFile() {
      try {
        this.loading = true;

        const { data } = await this.$api.post("/invoices/live/global-invoice", {
          begin: this.begin,
          end: this.end
        });

        window.location.href = axios.defaults.baseURL + data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    newEndDate(date) {
      if (moment(this.begin).isAfter(date)) {
        this.begin = moment(date)
          .startOf("month")
          .toDate();
      }

      this.disabledBegin.from = date;
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},_vm._l((_vm.data),function(stat,index){return _c('div',{key:index,staticClass:"col-md-6"},[_c('div',{staticClass:"card",staticStyle:{"border-radius":"25px"}},[_c('div',{staticClass:"card-header text-white",style:(_vm.getCss(index))},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.utm(index).label))])]),_c('div',{staticClass:"card-block"},[_c('el-table',{attrs:{"data":stat,"max-height":"400"}},[_c('el-table-column',{attrs:{"label":"","prop":"label"}}),_c('el-table-column',{attrs:{"label":"B2B","prop":"b2b"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  ['ca', 'averageBasket', 'caWithoutDiscovery'].includes(
                    scope.row.key
                  )
                )?_c('strong',[_vm._v(_vm._s(_vm.formatPrice(scope.row.b2b, true)))]):_c('strong',[_vm._v(_vm._s(scope.row.b2b))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"B2C","prop":"b2c"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  ['ca', 'averageBasket', 'caWithoutDiscovery'].includes(
                    scope.row.key
                  )
                )?_c('strong',[_vm._v(_vm._s(_vm.formatPrice(scope.row.b2c, true)))]):_c('strong',[_vm._v(_vm._s(scope.row.b2c))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Total","prop":"total"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  ['ca', 'averageBasket', 'caWithoutDiscovery'].includes(
                    scope.row.key
                  )
                )?_c('strong',[_vm._v(_vm._s(_vm.formatPrice(scope.row.total, true)))]):_c('strong',[_vm._v(_vm._s(scope.row.total))])]}}],null,true)})],1)],1)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div style="margin-top: 20px; margin-bottom: 20px" class="row">
      <div class="col-md-8">
        <el-radio-group
          v-model="editRank"
          :disabled="!hasPermission($store.state.user, 'FIDELISATION_WRITE')"
        >
          <el-radio-button label="pending">En attente</el-radio-button>
          <el-radio-button v-if="conversion" label="conversion"
            >Converti</el-radio-button
          >
          <el-radio-button v-else label="fidelisation"
            >Fidélisé</el-radio-button
          >
          <el-radio-button label="lost">Perdu Coach</el-radio-button>
          <el-radio-button label="lost_other">Perdu</el-radio-button>
        </el-radio-group>
      </div>
      <div class="col-md-8" v-if="isLost">
        <el-select
          v-if="isProspect"
          v-model="editClientLostReason"
          size="mini"
          :disabled="!hasPermission($store.state.user, 'CLIENTS_WRITE')"
          class="w-100"
        >
          <el-option
            v-for="reason in prospectLostReasons"
            :key="reason.value"
            :value="reason.value"
            :label="reason.label"
          />
        </el-select>

        <el-select
          v-else
          v-model="editClientLostReason"
          size="mini"
          :disabled="!hasPermission($store.state.user, 'CLIENTS_WRITE')"
          class="w-100"
        >
          <el-option
            v-for="reason in lostReasons"
            :key="reason.value"
            :value="reason.value"
            :label="reason.label"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    id: String,
    notes: String,
    sessions: Number,
    price: String,
    rank: String,
    clientIsLost: {
      type: Boolean,
      default: false,
    },
    clientLostReason: {
      type: String,
      default: null,
    },
    coachs: {
      type: Array,
      default: () => [],
    },
    conversion: {
      type: Boolean,
      default: false,
    },
    isProspect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showUpdateClient: false,
      editNotes: this.notes,
      editSessions: this.sessions,
      editPrice: this.price,
      editRank: this.rank,
      editClientIsLost: this.clientIsLost,
      editClientLostReason: this.clientLostReason,
    };
  },
  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),

    prospectLostReasons() {
      return this.lostReasons
        .filter((reason) => !reason.clientsOnly)
        .map(({ label, value }) => ({ label, value }));
    },

    isLost() {
      return this.editRank === "lost" || this.editRank === "lost_other";
    },
  },
  watch: {
    id() {
      this.showUpdateClient = false;
    },
    notes(value) {
      this.editNotes = value;
    },
    sessions(value) {
      this.editSessions = value;
    },
    price(value) {
      this.editPrice = value;
    },
    rank(value) {
      this.editRank = value;
    },
    clientLostReason(value) {
      this.editClientLostReason = value;
    },
    clientIsLost(value) {
      this.editClientIsLost = value;
    },
    editRank(value) {
      this.editClientIsLost = value === "lost" || value === "lost_other";
      this.$emit("update:rank", value);
    },
    editClientLostReason(value) {
      this.$emit("update:clientLostReason", value);
    },
    editClientIsLost(value) {
      if (!value) {
        this.editClientLostReason = null;
      }
      this.$emit("update:clientIsLost", value);
    },
  },
};
</script>

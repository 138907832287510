<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-trophy"></i> Parrainage(s)
      <span v-if="code">
        -
        <span class="font-weight-bold">{{ code }}</span>
      </span>

      <span v-if="sponsoredBy" class="float-right"
        >Parrain :
        <a :href="clientHref(sponsoredBy.id)" target="_blank">
          {{ sponsoredBy.fullName }} <i class="icon-user"></i> </a
      ></span>
    </div>

    <div class="card-block">
      <div
        v-if="clientDiscount && clientDiscount.discount"
        class="alert alert-info"
      >
        Remise pour le parrain :
        <span class="font-weight-bold"> {{ clientDiscount.discount }}% </span>
      </div>
      <table class="table table-condensed mb-0">
        <div
          v-if="sponsoredClients.length === 0"
          class="text-center text-muted"
        >
          Aucun parrainage pour le moment
        </div>
        <tr
          v-else
          v-for="(sponsoredClient, index) in sponsoredClients"
          :key="sponsoredClient.id"
        >
          <td class="d-flex">
            <div class="w-25 text-muted">Filleul(e) {{ ++index }}</div>
            <div class="w-75">
              <a
                :href="clientHref(sponsoredClient.id)"
                target="_blank"
                :class="`text-${clientSubscriptionStatus(
                  sponsoredClient.liveSubscriptionStatus
                )}`"
              >
                {{ sponsoredClient.fullName }}
                <i class="icon-user"></i>
              </a>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      code: null,
      sponsoredClients: [],
      sponsoredBy: null,
      clientDiscount: null,
    };
  },

  mounted() {
    this.code = this.client.liveSponsorshipCode;
    this.sponsoredBy = this.client.liveSponsors[0]?.sponsor;
    this.clientDiscount = this.client.liveSponsorshipDiscount;

    const sponsoredClients = [];

    this.client.liveSponsorships.forEach((sponsorship) => {
      if (
        !sponsoredClients.some(
          (client) => client.email === sponsorship.sponsored.email
        )
      ) {
        sponsoredClients.push(sponsorship.sponsored);
      }
    });

    this.sponsoredClients = sponsoredClients;
  },

  methods: {
    clientHref(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      return route.href;
    },

    clientSubscriptionStatus(status) {
      switch (status) {
        case "active":
          return "active";
        case "trialing":
          return "warning";
        case "inactive":
          return "danger";
        default:
          return "danger";
      }
    },
  },
};
</script>

<style lang="css" scoped>
.text-active {
  color: #3a9a6e;
}
</style>

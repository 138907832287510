import { render, staticRenderFns } from "./AttributionsLive.vue?vue&type=template&id=267b634e&scoped=true"
import script from "./AttributionsLive.vue?vue&type=script&lang=js"
export * from "./AttributionsLive.vue?vue&type=script&lang=js"
import style0 from "./AttributionsLive.vue?vue&type=style&index=0&id=267b634e&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "267b634e",
  null
  
)

export default component.exports
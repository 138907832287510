<template>
  <div class="d-flex flex-wrap">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h6 class="card-title">Commandes payées / partiellement payées</h6>
          <span>Total CA: {{ formatPrice(payedOrdersCa) }}</span>
        </div>
        <div class="card-block">
          <Orders :orders="payedOrders" :invoice="false" :notes="false" />
        </div>
      </div>
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h6 class="card-title">Commandes en attente de paiement</h6>
          <span>Total CA: {{ formatPrice(pendingOrdersCa) }}</span>
        </div>
        <div class="card-block">
          <Orders :orders="pendingOrders" :invoice="false" :notes="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Orders from "../../../Orders";
export default {
  components: {
    Orders,
  },

  props: {
    orders: { type: Array, required: true },
  },

  computed: {
    pendingOrders() {
      return this.orders.filter((o) => o.status === "pending");
    },

    pendingOrdersCa() {
      return this.pendingOrders.reduce((acc, o) => acc + o.price, 0);
    },

    payedOrders() {
      return this.orders.filter((o) => o.status !== "pending");
    },

    payedOrdersCa() {
      return this.payedOrders.reduce((acc, o) => acc + o.price, 0);
    },
  },

  methods: {},
};
</script>

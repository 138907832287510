<template>
  <div class="wrapper animated fadeIn">
    <Loader :is-visible="loading" />

    <DisbursementsTroublesFilters :default="filters" @load="applyFilters" />

    <div class="card">
      <div class="card-header">
        <i class="fa fa-hand-stop-o"></i> Contestations de facturation ({{
          total
        }})
      </div>

      <div class="card-block">
        <DisbursementsTroublesTable
          :data="disbursementsTroubles"
          @select="openModal"
        />

        <paginate
          v-if="pageCount"
          v-model="page"
          ref="paginate"
          :page-count="pageCount"
          :click-handler="changePage"
          prev-text="Précédent"
          next-text="Suivant"
          container-class="pagination"
          class="mt-1"
        ></paginate>
      </div>
    </div>

    <modal
      :title="selectedModalTitle"
      v-model="showModal"
      @cancel="closeModal"
      effect="fade/zoom"
    >
      <div slot="modal-body" class="modal-body">
        <div v-if="selected" class="row">
          <dt class="col-sm-4">Créée le</dt>
          <dd class="col-sm-8">{{ formatDate(selected.createdAt) }}</dd>
          <dt class="col-sm-4">Coach</dt>
          <dd class="col-sm-8">{{ selected.user.coach.name }}</dd>
          <dt class="col-sm-4">Société</dt>
          <dd class="col-sm-8">{{ selected.merchant.name }}</dd>
          <dt class="col-sm-4">Message</dt>
          <dd class="col-sm-8">{{ selected.note }}</dd>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-default" @click="closeModal">
          Fermer
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";

import DisbursementsTroublesFilters from "../components/DisbursementsTroublesFilters";
import DisbursementsTroublesTable from "../components/DisbursementsTroublesTable";
import Modal from "vue-strap/src/Modal";
import Paginate from "vuejs-paginate";
import Loader from "../components/Loader";

export default {
  components: {
    DisbursementsTroublesFilters,
    DisbursementsTroublesTable,
    Modal,
    Paginate,
    Loader,
  },

  data() {
    return {
      loading: false,
      disbursementsTroubles: [],
      total: 0,
      offset: 0,
      limit: 20,
      page: 1,
      count: true,
      selected: null,
      showModal: false,

      filters: {
        begin: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
        coach: null,
        merchant: null,
      },
    };
  },

  computed: {
    selectedModalTitle() {
      return this.selected?.user?.coach?.name || "";
    },

    pageCount() {
      return Math.ceil(this.total / this.limit);
    },
  },

  watch: {
    page() {
      if (this.page - 1 !== this.$refs.paginate.selected) {
        this.$refs.paginate.selected = this.page - 1;
      }
    },
  },

  async mounted() {
    await this.fetchDisbursementsTroubles();

    const { dtid } = this.$route.query;

    if (dtid) {
      const disbursementTrouble = this.disbursementsTroubles.find(
        ({ id }) => id === dtid
      );

      if (disbursementTrouble) {
        this.openModal(disbursementTrouble);
      }
    }
  },

  methods: {
    async fetchDisbursementsTroubles() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/disbursements/troubles", {
          params: {
            filters: this.filters,
            total: this.total,
            offset: this.offset,
            limit: this.limit,
            count: this.count,
          },
        });

        const { data: disbursementsTroubles, total } = data;

        this.disbursementsTroubles = disbursementsTroubles;
        this.total = total;
        this.count = false;
      } catch (e) {
        this.$errorToast(
          "Impossible de charger les contestations de facturation"
        );
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters,
      };
      this.count = true;

      this.changePage(1);
    },

    openModal(disbursementTrouble) {
      this.selected = disbursementTrouble;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      this.selected = null;
    },

    changePage(page) {
      this.page = page;
      this.offset = page * this.limit - this.limit;

      this.fetchDisbursementsTroubles();
    },
  },
};
</script>

<template>
  <div>
    <div class="card">
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-3">
            <label>Date de création comprise entre le</label>
            <datepicker
              v-model="filters.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>et le</label>
            <datepicker
              v-model="filters.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>Concept(s)</label>
            <multiselect
              v-model="filters.concepts"
              :options="conceptOptions"
              label="label"
              track-by="label"
              :multiple="true"
              v-bind="internationnalizationValues"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-3">
            <label>Genre(s)</label>
            <multiselect
              v-model="filters.genders"
              :options="gendersOptions"
              :multiple="true"
              v-bind="internationnalizationValues"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-3">
            <label>Raison</label>
            <multiselect
              v-model="filters.lostReasons"
              :options="lostReasons"
              label="label"
              track-by="label"
              :multiple="true"
              v-bind="internationnalizationValues"
            >
            </multiselect>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button class="btn btn-default" @click="applyFilters">
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Multiselect,
    Datepicker,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: [],
      conceptOptions: [],
      gendersOptions: ["Homme", "Femme"],
    };
  },

  async created() {
    this.filters = this.default;

    await this.getConcepts();
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),
  },

  methods: {
    applyFilters() {
      this.$emit("update", { ...this.filters });
    },

    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.conceptOptions = data.map((c) => {
        return { label: c.name, value: c.name };
      });
    },
  },
};
</script>

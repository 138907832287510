<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-globe"></i> Statistiques et classement
    </div>
    <div class="card-block">
      <div class="row" v-if="stat">
        <div class="col-md-3 mt-1" v-if="stat.conversion">
          <div class="custom-card">
            <div class="d-flex justify-content-between">
              <h6>Taux de conversion</h6>
              <span v-if="stat.conversionRank" class="text-muted">
                #{{ stat.conversionRank }}
              </span>
            </div>
            <span class="font-weight-bold percent-title">
              {{ stat.conversion }}%
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-1" v-if="stat.fidelisation">
          <div class="custom-card">
            <div class="d-flex justify-content-between">
              <h6>Taux de fidélisation</h6>
              <span v-if="stat.fidelisationRank" class="text-muted">
                #{{ stat.fidelisationRank }}
              </span>
            </div>
            <span class="font-weight-bold percent-title"
              >{{ stat.fidelisation }}%</span
            >
          </div>
        </div>
        <div class="col-md-3 mt-1" v-if="stat.resubscribeRank">
          <div class="custom-card">
            <div class="d-flex justify-content-between">
              <h6>Réabonnement</h6>
              <span class="text-muted"> #{{ stat.resubscribeRank }} </span>
            </div>
            <span
              v-if="stat.resubscribe"
              class="font-weight-bold percent-title"
            >
              {{ stat.resubscribe }} pack(s)
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-1" v-if="stat.packAverageRank">
          <div class="custom-card">
            <div class="d-flex justify-content-between">
              <h6>Pack moyen</h6>
              <span class="text-muted"> #{{ stat.packAverageRank }} </span>
            </div>

            <span
              v-if="stat.packAverage"
              class="font-weight-bold percent-title"
            >
              {{ stat.packAverage }}
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-1" v-if="stat.clientSessionLifecycle">
          <div class="custom-card">
            <div class="d-flex justify-content-between">
              <h6>Longévité clients</h6>
              <span v-if="stat.clientSessionLifecycleRank" class="text-muted">
                #{{ stat.clientSessionLifecycleRank }}
              </span>
            </div>

            <span class="font-weight-bold percent-title">
              {{ stat.clientSessionLifecycle }}
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-1" v-if="stat.rank">
          <div class="custom-card">
            <div class="d-flex justify-content-between">
              <h6>Notes clients</h6>
              <span v-if="stat.rankRank" class="text-muted">
                #{{ stat.rankRank }}
              </span>
            </div>

            <span class="font-weight-bold percent-title">
              <Rates :value="stat.rank" size="" />
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-1" v-if="stat.frequency">
          <div class="custom-card">
            <div class="d-flex justify-content-between">
              <h6>Fréquence</h6>
              <span v-if="stat.frequencyRank" class="text-muted">
                #{{ stat.frequencyRank }}
              </span>
            </div>
            <span class="font-weight-bold percent-title">
              {{ stat.frequency }}
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-1" v-if="stat.validateResult">
          <div class="custom-card">
            <div class="d-flex justify-content-between">
              <h6>Validée(s)</h6>
              <span v-if="stat.validateRank" class="text-muted">
                #{{ stat.validateRank }}
              </span>
            </div>
            <span class="font-weight-bold percent-title">
              {{ stat.validateResult }}
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        Aucune donnée de statistique
      </div>
    </div>
  </div>
</template>

<script>
import Rates from "../components/Rates";

export default {
  components: {
    Rates
  },
  props: {
    coach: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      stat: null
    };
  },

  async created() {
    const { data } = await this.$api.get(`/rank/${this.coach.id}`);

    this.stat = data;
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
.custom-card {
  padding: 7px;
  border: 1px solid #e1e6ef;
  text-align: center;
}
</style>

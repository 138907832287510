<template>
  <div class="animated fadeIn">
    <InvoiceFilters
      :filters="filters"
      :concepts="concepts"
      :merchants-options="merchantsOptions"
      :commercials="commercials"
      :zipcodes="zipcodes"
      :invoiceSeries="invoiceSeries"
      @load="(form) => loadInvoices(form)"
    />

    <div class="card card-inverse card-info text-center" v-if="isLoading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>

    <InvoicesTable
      v-if="!isLoading"
      :orders="orders"
      :showRefundedTotal="showRefundedTotal"
      :showPayedTotal="showPayedTotal"
      :showPartialTotal="showPartialTotal"
      :showUnpaidTotal="showUnpaidTotal"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";

import InvoiceFilters from "../components/InvoiceFilters";
import InvoicesTable from "../components/InvoicesTable";

export default {
  components: {
    InvoiceFilters,
    InvoicesTable,
  },
  data() {
    return {
      isLoading: false,
      concepts: [],
      merchantsOptions: [],
      commercials: [],
      orders: [],
      showRefundedTotal: false,
      showPayedTotal: false,
      showPartialTotal: false,
      showUnpaidTotal: false,
      filters: {
        begin: moment().startOf("month").startOf("day").format("YYYY-MM-DD"),
        end: moment().endOf("month").startOf("day").format("YYYY-MM-DD"),
        concept: null,
        invoiceSerieCode: null,
        client: null,
        type: null,
        zipcodes: null,
        status: [
          { label: "Payée", value: "payed" },
          { label: "Partiel", value: "partial" },
          { label: "Avoir", value: "refunded" },
          { label: "Impayé (traité)", value: "unpaid" },
        ],
        commercials: [],
      },
    };
  },

  async created() {
    this.filters.begin = moment().startOf("month").format("YYYY-MM-DD hh:mm");
    this.filters.end = moment().endOf("month").format("YYYY-MM-DD hh:mm");

    await this.getConcepts();
    await this.getMerchants();
    await this.getCommercials();

    const currentCommercial = this.commercials.find((c) => {
      if (this.$store.state.user.email === c.value) {
        return c;
      }
    });

    if (currentCommercial) {
      this.filters.commercials.push(currentCommercial);
    }

    await this.loadInvoices();
  },

  computed: {
    ...mapState({
      merchant: (state) => state.merchant,
      invoiceSeries: (state) => state.invoiceSeries,
    }),

    ...mapGetters({
      departments: "getDepartments",
    }),

    zipcodes() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },
  },

  methods: {
    async loadInvoices(form) {
      this.isLoading = true;

      if (form) {
        this.filters = form;
      }

      let zipcodes = null;

      if (this.filters.zipcodes && this.filters.zipcodes.length > 0) {
        const values = this.filters.zipcodes.map(({ value }) => value);
        if (!values.includes("all")) {
          zipcodes = values;
        }
      }

      let status = null;

      if (this.filters.status && this.filters.status.length > 0) {
        status = this.filters.status.map(({ value }) => value);
      }

      const { data } = await this.$api.get(`/invoices`, {
        params: {
          begin: this.filters.begin,
          end: this.filters.end,
          concept: this.filters.concept,
          merchantId: this.filters.merchant
            ? this.filters.merchant.value
            : null,
          invoiceSerieCode: this.filters.invoiceSerieCode,
          client: this.filters.client ? this.filters.client.value : null,
          status: status,
          match: this.filters.match,
          type: this.filters.type ? this.filters.type.value : null,
          zipcodes: zipcodes,
          commercials:
            this.filters.commercials &&
            this.filters.commercials.map((c) => c.value),
        },
      });

      this.orders = data;

      if (status) {
        this.showPayedTotal = status.includes("payed") ? true : false;
        this.showPartialTotal = status.includes("partial") ? true : false;
        this.showUnpaidTotal = status.includes("unpaid") ? true : false;
        this.showRefundedTotal = status.includes("refunded") ? true : false;
      } else {
        this.showRefundedTotal = true;
        this.showPayedTotal = true;
        this.showPartialTotal = true;
        this.showUnpaidTotal = true;
      }

      this.isLoading = false;
    },

    async getCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.concepts = data.map((c) => c.name);
    },

    async getMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantsOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },
  },
};
</script>

<style lang="scss">
.custom-multiselect {
  .multiselect {
    &__tags {
      min-height: 34px;
    }

    &__tag {
      margin-bottom: 0;
    }

    &__placeholder {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
}
</style>

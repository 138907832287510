<template>
  <div>
    <div class="d-flex justify-content-between align-items-center py-1">
      <div
        class="d-flex align-items-center"
        @click="toggleShowFiles"
        role="button"
      >
        <dt>Documents</dt>
        <i class="fa fa-chevron-down pl-1" v-if="!showFiles"></i>
        <i class="fa fa-chevron-up pl-1" v-else></i>
      </div>
      <button class="btn btn-primary btn-sm float-right" @click="isOpen = true">
        Ajouter document(s)
      </button>
    </div>
    <div style="max-height: 500px; overflow: auto" v-if="showFiles">
      <CompanyFileListCard
        :companyName="companyName"
        :files="files"
        @deleteFile="deleteFile"
      />
    </div>

    <modal
      :title="`${
        hidde
          ? 'Modifier le nom du/des document(s)'
          : 'Déposer votre/vos document(s)'
      }`"
      v-model="isOpen"
      @cancel="closeModal"
      effect="fade/zoom"
    >
      <dropzone
        class="d-flex flex-wrap justify-content-center"
        ref="dropzone"
        :url="apiUploads()"
        :headers="apiAuthorization()"
        id="file"
        v-on:vdropzone-success="fileUploaded"
        v-on:vdropzone-removed-file="removeFile"
        :thumbnailHeight="80"
        :thumbnailWidth="80"
        v-if="!hidde"
      >
        <div class="dz-default dz-message">
          <span
            ><i class="icon-cloud-upload"></i><br />Glissez les document ici (5
            document max.)</span
          >
        </div>
      </dropzone>

      <div id="form" v-if="hidde">
        <div class="d-grid gap-5" v-for="each in uploadedFiles" :key="each.id">
          <dt>Nom actuel</dt>
          <dd>
            <el-input disabled size="mini" :value="each.name" />
          </dd>
          <dt>Nouveau nom</dt>
          <dd class="mb-2">
            <el-input
              size="mini"
              v-model="form.parent_id[uploadedFiles.indexOf(each)]"
            />
          </dd>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer d-flex justify-end">
        <button class="btn btn-secondary" @click="closeModal">Fermer</button>
        <button
          class="btn btn-primary"
          @click="hidde = true"
          v-if="uploadedFiles.length > 0"
        >
          Modifier le nom des fichiers
        </button>
        <button
          class="btn btn-primary"
          @click="sendDocuments"
          v-if="uploadedFiles.length > 0"
        >
          Envoyer
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Dropzone from "vue2-dropzone";
import Modal from "vue-strap/src/Modal";
import axios from "axios";
import CompanyFileListCard from "./CompanyFileListCard.vue";

export default {
  components: {
    Dropzone,
    Modal,
    CompanyFileListCard,
  },

  props: {
    companyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      showFiles: false,
      hidde: false,
      isUpload: false,
      uploadedFiles: [],
      form: {
        parent_id: [],
      },
      files: [],
      companyName: "",
    };
  },

  async created() {
    await this.getFilesCompany();
  },

  methods: {
    apiUploads() {
      return `${axios.defaults.baseURL}uploads`;
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    fileUploaded(_, response) {
      this.uploadedFiles = [...this.uploadedFiles, response];
      this.isUpload = true;
    },

    async sendDocuments() {
      const newFormData = [];

      for (let i = 0; i < this.uploadedFiles.length; i++) {
        newFormData.push({
          fileId: this.uploadedFiles[i].id,
          fileName: this.form.parent_id[i]
            ? this.form.parent_id[i]
            : this.uploadedFiles[i].name,
        });
      }

      await this.$api
        .post(`/companies/${this.companyId}/documents`, newFormData)
        .catch((e) => console.log(e));

      this.form.parent_id = [];
      this.isOpen = false;
      this.isUpload = false;
      this.hidde = false;

      window.location.reload();
    },

    async closeModal() {
      this.hidde = false;

      if (this.uploadedFiles.length > 0) {
        this.$confirm({
          message:
            "Attention, en fermant cette fenêtre les documents déposé ne seront pas sauvergardé !",
          onConfirm: async () => {
            this.$refs.dropzone.removeAllFiles(true);

            this.isOpen = false;
            this.hidde = false;
            this.isUpload = false;
          },
        });
      } else {
        this.isOpen = false;
        this.hidde = false;
      }
    },

    async getFilesCompany() {
      const { data } = await this.$api.get(
        `/companies/${this.companyId}/documents`
      );

      this.files = data.files;
      this.companyName = data.company;
    },

    toggleShowFiles() {
      this.showFiles = this.showFiles ? false : true;
    },

    removeFile(fileName) {
      if (!this.hidde) {
        const { id } = this.uploadedFiles.find(
          (file) => file.name === fileName.name
        );

        this.uploadedFiles = this.uploadedFiles.filter(
          (file) => file.id !== id
        );

        this.deleteFile(id);
      }
    },

    async deleteFile(id) {
      try {
        await this.$api.delete(`/companies/document/${id}`);

        this.files = this.files.filter((file) => file.fileId !== id);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<template>
  <div class="animated fadeIn">
    <loader :isVisible="isLoading" />
    <i>
      Ce menu permet d'obtenir un export csv de l'ensemble des clients &
      prospect créés sur la période sélectionnée.
      <br />
      Avec les données relatives à la source de création du lead.
    </i>
    <div class="row mt-1">
      <div class="form-group col-sm-4">
        <label>Date de début</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{
            content: 'Date de création du lead',
            placement: 'right',
          }"
        ></i>
        <datepicker
          v-model="filters.dateBegin"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group col-sm-4">
        <label>Date de fin</label>
        <datepicker
          v-model="filters.dateEnd"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-sm btn-primary float-right mx-1"
          @click="download()"
        >
          <i class="icon-cloud-download"></i>&nbsp; Télécharger
        </button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-sm-12">
        <h5>En-tête du fichier Excel</h5>
      </div>
      <div class="col-sm-12">
        <ul>
          <li>Créé le</li>
          <li>Type (Prospect ou Client)</li>
          <li>Nom</li>
          <li>Prénom</li>
          <li>Email</li>
          <li>Statut</li>
          <li>Cause perte</li>
          <li>CA</li>
          <li>Converti en client le</li>
          <li>Commercial</li>
          <li>utm_source</li>
          <li>utm_medium</li>
          <li>utm_term</li>
          <li>utm_content</li>
          <li>utm_campaign</li>
          <li>referer</li>
          <li>sourceUrl</li>
          <li>gclid</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";
import Loader from "../components/Loader";
import DownloadFile from "../mixin/DownloadFile";
import { mapGetters } from "vuex";

export default {
  components: {
    Datepicker,
    Loader,
  },
  mixins: [DownloadFile],
  data() {
    return {
      isLoading: false,
      filters: {
        dateBegin: moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DD"),
      },
      advanced: false,
    };
  },

  async created() {
    await this.getConcepts();
    await this.getDepartment();
  },

  computed: {
    ...mapGetters({
      statusOptions: "getClientStatus",
    }),

    sanitarizeMinimalFilters() {
      return {
        dateBegin: this.filters.dateBegin,
        dateEnd: this.filters.dateEnd,
      };
    },

    sanitarizeFilters() {
      return {
        ...this.sanitarizeMinimalFilters,
      };
    },
  },

  methods: {
    async download() {
      this.isLoading = true;

      const params = this.advanced
        ? this.sanitarizeFilters
        : this.sanitarizeMinimalFilters;

      const { data } = await this.$api.get("/exports/marketing-form", {
        params,
      });

      this.downloadXLSX(data, "client.xlsx");
      this.isLoading = false;
    },
  },
};
</script>

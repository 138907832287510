<template>
  <div class="animated fadeIn">
    <el-popover trigger="click" placement="top" :width="700">
      <div class="row">
        <div class="col-md-12">
          <p>
            <span class="h4">{{ name }}</span> - {{ row.result }} séance(s)
          </p>
          <hr />
        </div>
        <div class="col-md-12">
          <ReactiveChart
            :chart-data="chartData"
            :options="options"
            class="chart-wrapper"
            style="height: 300px; margin-top: 40px"
            :height="300"
          />
        </div>
        <div class="col-md-12">
          <el-table :data="row.details" style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="row">
                  <div class="col-12">
                    <el-table :data="scope.row.orders" style="width: 100%">
                      <el-table-column
                        prop="createdAt"
                        label="Commande créée le"
                      >
                        <template slot-scope="scope">
                          <a
                            href="#"
                            @click="(e) => goToOrder(e, scope.row.id)"
                            >{{ formatDate(scope.row.createdAt) }}</a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column label="Nombre de séance(s)">
                        <template slot-scope="scope">
                          <a href="#" @click="(e) => goToOrder(e, scope.row.id)"
                            >{{ scope.row.total }} séance<template
                              v-if="scope.row.total > 1"
                              >s</template
                            ></a
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="fullname">
              <template slot-scope="scope">
                <a href="#" @click="(e) => goToClient(e, scope.row.id)">{{
                  scope.row.fullname
                }}</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div slot="reference" class="name-wrapper">
        <span v-if="row.result"> #{{ row.rank }} - </span>
        {{ row.result }}
      </div>
    </el-popover>
  </div>
</template>

<script>
import ReactiveChart from "./../../views/Dashboard/ReactiveChart.vue";
import moment from "moment-timezone";

export default {
  components: {
    ReactiveChart,
  },

  props: {
    row: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    labels() {
      return this.graphData.map((g) => g.date);
    },

    results() {
      return this.graphData.map((g) => g.result);
    },

    graphData() {
      const graphArray = [];
      const begin = this.filters.begin;
      const end = this.filters.end;
      const dateDifference = moment(end).diff(moment(begin), "week");

      let periodEndDate = moment(end);
      let period = periodEndDate.format("w YYYY");

      for (let i = 0; i <= dateDifference; i++) {
        const objIndex = this.row.graphData.findIndex((g) => g.date === period);
        let result = NaN;

        if (this.row.graphData[objIndex]) {
          result = this.row.graphData[objIndex].result;
        }

        graphArray.push({
          date: period,
          result,
        });

        period = periodEndDate.subtract(1, "week").format("w YYYY");
      }

      return graphArray;
    },

    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: this.results,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
          },
        ],
      };
    },
    options() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        spanGaps: true,
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1,
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                stepSize: 1,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      };
    },
  },

  methods: {
    goToClient(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },

    goToOrder(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: id,
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.pills {
  border-radius: 100px;
  background-color: #f5f5f5;
  padding: 3px 20px;
  cursor: pointer;
  word-break: break-word;
  display: inline-block;
}

.blue {
  background-color: #255dc8;
  color: white;
}

.green {
  background-color: #18bdb4;
  color: white;
}

.red {
  background-color: #dc2626;
  color: white;
}

.violet {
  background-color: #7c3aed;
  color: white;
}

.divider {
  width: 100%;
  border-top: 1px solid #131721;
}
</style>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="col-sm-12">
          <span class="small">
            Changer les dates, supprimera les filtres (car ils sont générés a
            partir des dates)
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Date de création du prospect entre</label>
          <datepicker
            v-model="dates.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>et</label>
          <datepicker
            v-model="dates.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Commercial</label>
          <multiselect
            placeholder="Choisir un ou des commerciaux"
            v-model="filtersCommercials.commercials"
            :options="commercials"
            label="label"
            track-by="label"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="formFilters"
          >
            <i class="icon-eye"></i>&nbsp; Visualiser
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="small">
            Appliquer les filtres pour voir les résultats ({{ countClient }}
            prospects)
          </div>
          <label for="">Filtres</label>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Sources</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.sources"
            :options="values.utm_sources"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Campaigns</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.campaigns"
            :options="values.utm_campaigns"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>

        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Mediums</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.mediums"
            :options="values.utm_mediums"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Terms</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.terms"
            :options="values.utm_terms"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Contents</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.contents"
            :options="values.utm_contents"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label>Landing pages (source)</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.sourceUrls"
            :options="values.sourceUrls"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
        <div class="form-group col-md-4">
          <label>Referer pages (origine)</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.referers"
            :options="values.referers"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Multiselect,
    Datepicker,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
    defaultDates: {
      type: Object,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    countClient: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dates: {},
      filters: {},
      allOptions: {
        source: false,
        campaigns: false,
      },
      filtersCommercials: { commercials: [] },
      commercials: [],
    };
  },

  async created() {
    this.filters = this.default;
    this.dates = this.defaultDates;
    this.filtersCommercials = this.default;

    await this.fetchCommercials();

    if (this.filtersCommercials.length > 0) {
      this.formFilters();
    }
  },

  methods: {
    formFilters() {
      this.$emit("formFilters", this.dates, { ...this.filtersCommercials });
    },

    apply() {
      this.$emit("updateFilter", this.filters);
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },
  },
};
</script>

<style lang="scss">
.custom-multiselect {
  &.custom-no-wrap {
    .multiselect__tags {
      max-height: 68px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .multiselect__tag {
      white-space: normal;
    }
  }
}
</style>

<template>
  <div v-if="displaySession" class="container">
    <div class="group row">
      <div class="col-sm-4">
        <img
          :src="getPictureImage(displaySession.live.id)"
          class="img-responsive"
        />
      </div>
      <div class="col-sm-8">
        <div class="title">
          {{ displaySession.live.name }} -
          {{ displaySession.live.type === "small" ? "Visio" : "Live" }}
        </div>
      </div>
    </div>
    <div class="row group">
      <div class="col-sm-4 text-center">
        <img
          class="avatar"
          v-if="!!displaySession.coachs[0]"
          :src="coachImg(displaySession.coachs[0])"
        />
        <div class="text-center">
          {{
            displaySession.coachs[0]
              ? displaySession.coachs[0].name
              : "Aucun coach"
          }}
        </div>
      </div>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-6">
            <div class="group">
              <div class="font-bold">Date :</div>
              <div>
                {{ formatDate(displaySession.scheduledAt) }}
              </div>
            </div>
            <div class="group">
              <div class="font-bold">Horaire :</div>
              <div>
                {{
                  `${displaySession.live.hour}:${
                    displaySession.live.minutes > 10
                      ? displaySession.live.minutes
                      : "0" + displaySession.live.minutes
                  }`
                }}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="group">
              <div class="font-bold">Niveau :</div>
              <div>
                {{ lvlText(displaySession.live.lvl) }}
              </div>
            </div>
            <div class="group">
              <div class="font-bold">Durée :</div>
              <div>{{ displaySession.live.duration }} min</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="group">
              <div class="font-bold">Réservations :</div>
              <div>
                {{ displaySession.countBookings }}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="group">
              <div class="font-bold">Quotas :</div>
              <div>
                {{ displaySession.live.quotas }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-primary" @click="openOrder">
        Voir la commande
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displaySession: {
      type: Object,
      required: false,
    },
  },
  methods: {
    openOrder() {
      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: this.displaySession.live.orderId,
        },
      });

      window.open(route.href, "_blank");
    },

    lvlText(lvl) {
      switch (lvl) {
        case 0:
          return "Débutant";

        case 1:
          return "Intermédiaire";

        case 2:
          return "Confirmé";

        default:
          return "Tous niveaux";
      }
    },

    coachImg(coach) {
      return coach
        ? `${process.env.VUE_APP_API_URL}users/${coach.id}/live-picture`
        : null;
    },

    getPictureImage(id) {
      return `${process.env.VUE_APP_API_URL}lives/${id}/photo`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  padding: 15px;
  z-index: 50;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 500px;

  .avatar {
    background: #ddd;
    margin: 1rem;
    display: inline-block;
    border-radius: 9999px;
    height: 100px;
    width: 100px;
  }

  .group {
    margin-bottom: 1rem;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-bold {
  font-weight: 600;
}
</style>

<template>
  <modal
    title="Raison du refus"
    v-model="visible"
    cancelText="Annuler"
    okText="Confirmer"
    @ok="confirm"
    @cancel="closeModal"
    effect="fade/zoom"
  >
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <textarea
            v-model="reason"
            class="form-control"
            rows="5"
            placeholder="Veuillez décrire brièvement la raison du refus de la photo..."
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    close: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
      reason: "",
    };
  },

  watch: {
    show(value) {
      this.visible = value;
    },
  },

  methods: {
    confirm() {
      this.$emit("confirm", this.reason?.trim());

      this.reason = "";
    },

    closeModal() {
      this.close();

      this.reason = "";
    },
  },
};
</script>

import { render, staticRenderFns } from "./RingoverCard.vue?vue&type=template&id=18ed955c"
import script from "./RingoverCard.vue?vue&type=script&lang=js"
export * from "./RingoverCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div>
    <div class="card">
      <div class="card-header">
        <i class="icon-briefcase"></i>
        <span v-if="!company"> Regroupement "client B2B" </span>
        <span class="font-bold" v-else-if="company"
          >"Groupe client B2B" - {{ company.name }}
        </span>
        <label
          class="switch switch-sm switch-text switch-info float-right mb-0"
          v-if="!company"
        >
          <input
            type="checkbox"
            class="switch-input"
            v-model="isCompany"
            @click="toggleCompany"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>

        <div v-if="company">
          <hr />
          <CompanyCommercial :company="company" />
        </div>
      </div>
      <div class="card-block" v-if="isCompany">
        <div
          v-if="!company"
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <label for="companyName">Choisir une société existante</label>
            <el-select
              :value="company.statut"
              size="mini"
              @change="selectCompany($event)"
              :disabled="
                !hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')
              "
              class="w-100"
              style="border-radius: 5px"
            >
              <el-option
                v-for="company in existingCompany"
                :key="company.id"
                :value="company.id"
                :label="company.name"
              />
            </el-select>
          </div>
          <div class="p-1">OU</div>
          <div>
            <label for="companyName">Créer une société</label>
            <div class="d-flex">
              <el-input
                v-model="form.companyName"
                size="mini"
                placeholder="Nom de la société"
              ></el-input>
              <button class="btn btn-primary btn-sm" @click="createCompany">
                Créer
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <dl class="row">
            <dt class="col-3">Statut</dt>
            <dd class="col-sm-9">
              <el-select
                :value="company.statut"
                size="mini"
                @change="openStatusConfirm($event)"
                :disabled="
                  !hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')
                "
                class="w-100"
                style="border-radius: 5px"
                :class="`status-color__${company.statut}`"
              >
                <el-option
                  v-for="status in companyStatuts"
                  :key="status.label"
                  :value="status.value"
                  :label="status.label"
                  :class="`status-color__${status.value}`"
                />
              </el-select>
            </dd>

            <dt class="col-sm-3">Nom</dt>
            <dd class="col-sm-9">
              <el-input
                @change="updateCompany()"
                v-model="company.name"
                size="mini"
                placeholder="Nom de la société ou collectivité"
              />
            </dd>

            <dt class="col-sm-3">Description</dt>
            <dd class="col-sm-9">
              <el-input
                @change="updateCompany()"
                v-model="company.description"
                type="textarea"
                autosize
              />
            </dd>

            <dt class="col-sm-3">Note menu entreprise</dt>
            <dd class="col-sm-9">
              <el-input
                @change="updateCompany()"
                v-model="company.note"
                size="mini"
              />
            </dd>
          </dl>

          <div class="row">
            <div class="col-sm-12 mb-1">
              <v-select
                v-model="form.clientSearch"
                :options="clients"
                @search="searchClients"
                class="float-right"
                style="width: 100%"
              ></v-select>
            </div>
            <div class="col-sm-12">
              <button
                class="btn btn-primary btn-sm float-right"
                :disabled="
                  !hasPermission(
                    $store.state.user,
                    'COMPANIES_COMPANY_WRITE'
                  ) || !(form.clientSearch && form.clientSearch.value)
                "
                @click="linkCompany(company.id, form.clientSearch.value)"
              >
                Ajouter une fiche client
              </button>
            </div>
          </div>

          <hr class="mb-0" />

          <div style="max-height: 500px; overflow: auto">
            <CompanyListCard :company="company" />
          </div>

          <div style="max-height: 500px; overflow: auto">
            <CompanyDropFilesCard :companyId="company.id" />
          </div>
        </div>
      </div>
    </div>
    <CompanyDetailsModal
      :statusConfirmModal="statusConfirmModal"
      @update="editCompanyStatut"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";

import CompanyListCard from "./company/CompanyListCard";
import CompanyDetailsModal from "./company/CompanyDetailsModal";
import CompanyCommercial from "./CompanyCommercial";
import CompanyDropFilesCard from "./company/CompanyDropFilesCard.vue";

export default {
  components: {
    CompanyListCard,
    CompanyDetailsModal,
    CompanyCommercial,
    CompanyDropFilesCard,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isCompany: false,
      clients: [],
      form: {
        companyName: "",
        clientSearch: {},
        selectedClient: null,
      },
      existingCompany: [],
      company: false,
      defaultCompanyDescription: `🏭 : 
@ : 
🗓️ : 
🎯 : 
🔂 : 
👩‍👩‍👧‍👧: 
📭 : 
💰 : 
👺 : 
🗒️ : `,
      activeTab: 0,
      statusConfirmModal: {
        show: false,
        statut: null,
      },
    };
  },

  async created() {
    await this.getExistingCompany();
    if (this.client.companyId) {
      await this.getCompany(this.client.companyId);
    }
  },

  computed: {
    ...mapGetters({
      companyStatuts: "getCompanyStatuts",
      lostReasons: "getLostReasons",
      companyLost: "getCompanyLost",
    }),
  },

  methods: {
    goToCompany(companyId) {
      const route = this.$router.resolve({
        name: "company-details",
        params: {
          id: companyId,
        },
      });

      window.open(route.href, "_blank");
    },

    async getExistingCompany() {
      const { data } = await this.$api.get("/companies/simples");

      this.existingCompany = data;
    },

    async getCompany(companyId) {
      const { data } = await this.$api.get(`/companies/${companyId}`);

      this.isCompany = true;

      this.company = {
        ...data,
        description: data.description || this.defaultCompanyDescription,
      };
    },

    async createCompany() {
      const { data } = await this.$api.post("/companies", {
        name: this.form.companyName,
        clientEmail: this.client.email,
      });

      await this.getCompany(data.id);
      this.form.companyName = "";
    },

    selectCompany(value) {
      this.linkCompany(value);
    },

    async linkCompany(companyId, clientEmail = this.client.email) {
      await this.$api.patch(`/companies/${companyId}/link`, {
        clientEmail,
      });

      if (this.form.clientSearch) {
        this.form.clientSearch = {};
      }

      await this.getCompany(companyId);
    },

    toggleCompany() {
      this.isCompany = !this.isCompany;
    },

    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    openStatusConfirm(value) {
      if (value) {
        this.statusConfirmModal.show = true;
        this.statusConfirmModal.statut = value || null;
      }
    },

    async updateCompany() {
      await this.$api.put(`/companies/${this.company.id}`, {
        name: this.company.name,
        description: this.company.description,
        note: this.company.note,
      });
    },

    async editCompanyStatut({ statut, lostReason }) {
      if (lostReason && lostReason.value) {
        lostReason = lostReason.value;
      }

      await this.$api.put(`/companies/${this.company.id}`, {
        statut,
        lostReason,
      });
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.length > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter?q=${search}`);

      vm.clients = data.map((c) => ({
        label: c.name + " (" + c.email + ")",
        value: c.email,
        usedEmail: c.usedEmail,
      }));

      loading(false);

      return vm.clients;
    }, 300),
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-white d-flex flex-column align-items-stretch",style:(`
    background-color: ${_vm.label.color};
    padding: .5rem;
    padding-left: 1rem;
    border-radius: 1rem;
    margin-bottom: .3rem;
    margin-top: .3rem;
  `)},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(" "+_vm._s(_vm.label.name)+" "),(_vm.label.details)?[(!_vm.expanded)?_c('i',{staticClass:"fa fa-caret-down px-1",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.expanded = 1}}}):_c('i',{staticClass:"fa fa-caret-up px-1",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.expanded = 0}}})]:_vm._e()],2),(_vm.label.details)?_c('div',{class:`label-details px-1 pb-${_vm.expanded}`,style:(`transform: scaleY(${_vm.expanded}); max-height: ${
      _vm.expanded ? '600px' : '0'
    }`)},[(_vm.label.details.sources)?[_c('div',{staticClass:"font-weight-bold my-1"},[_vm._v("UTM Sources")]),_vm._l((_vm.label.details.sources),function(source){return _c('div',{key:source.label,staticClass:"text-right"},[_vm._v(" dont ("+_vm._s(source.value)+") "+_vm._s(source.label)+" ("+_vm._s(source.percentage)+"%) ")])})]:_vm._e(),(_vm.label.details.campaigns)?[_c('div',{staticClass:"font-weight-bold my-1"},[_vm._v("UTM Campaigns")]),_vm._l((_vm.label.details.campaigns),function(campaign){return _c('div',{key:campaign.label,staticClass:"text-right"},[_vm._v(" dont ("+_vm._s(campaign.value)+") "+_vm._s(campaign.label)+" ("+_vm._s(campaign.percentage)+"%) ")])})]:_vm._e(),(_vm.label.details.contents)?[_c('div',{staticClass:"font-weight-bold my-1"},[_vm._v("UTM Contents")]),_vm._l((_vm.label.details.contents),function(content){return _c('div',{key:content.label,staticClass:"text-right"},[_vm._v(" dont ("+_vm._s(content.value)+") "+_vm._s(content.label)+" ("+_vm._s(content.percentage)+"%) ")])})]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="animated fadeIn">
    <modal
      title="Modifier les paramètres"
      v-model="modalSettings.display"
      cancelText="Fermer"
      okText="Modifier"
      @ok="applyFilters"
      effect="fade/zoom"
      large
    >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.display.conversionRate"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="ml-1">Taux de conversion</span>
            <el-popover trigger="hover" placement="top" :width="510">
              <div class="p-1 text-break">
                <p>
                  Le taux de conversion correspond au pourcentage de clients
                  convertis par le coach.
                </p>
                <ul>
                  <li class="mt-1">
                    Si le coach a fait la commande découverte et la commande
                    conversion alors le poucentage augmente.
                  </li>
                  <li class="mt-1">
                    Si le coach a fait la découverte et le client n'a pas repris
                    de commande conversion alors le pourcentage diminue.
                  </li>
                  <li class="mt-1">
                    Si le coach n'a pas fait la commande découverte et/ou la
                    commande conversion alors le client est exclu du calcul.
                  </li>
                </ul>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </div>
          <div class="form-group">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.display.fidelisationRate"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="ml-1">Taux de fidélisation</span>
            <el-popover trigger="hover" placement="top" :width="510">
              <div class="p-1 text-break">
                <p>
                  Le taux de fidélisation correspond au poucentage de clients
                  fidélisé par le coach.
                </p>
                <ul>
                  <li class="mt-1">
                    Si la commande a été traitée en fidélisé dans le menu
                    `Fidélisation` alors le pourcentage augmente
                  </li>
                  <li class="mt-1">
                    Si la commande a été traitée en `Perdu` ou `Perdu coach`
                    dans le menu `Fidélisation` alors le pourcentage s'ajuste
                  </li>
                </ul>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </div>
          <div class="form-group">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.display.resubscribe"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="ml-1">Réabonnement</span>
            <el-popover trigger="hover" placement="top" :width="510">
              <div class="p-1 text-break">
                <p>
                  Le réabonnement est la quantité additionnée de toutes les
                  commandes dont le coach est référent (découverte exclu)
                </p>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </div>
          <div class="form-group">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.display.packAverage"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="ml-1">Pack moyen</span>
            <el-popover trigger="hover" placement="top" :width="510">
              <div class="p-1 text-break">
                <p>
                  Le pack moyen est la moyenne de toutes les séances des
                  commandes dont le coach est référent.
                </p>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </div>
          <div class="form-group">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.display.clientSessionLifecycle"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="ml-1">Longévité client</span>
            <el-popover trigger="hover" placement="top" :width="510">
              <div class="p-1 text-break">
                <p>
                  Pour la longévité client on calcule pour chaque client la
                  moyenne des séances de toutes ses commandes, puis on fait la
                  moyenne de tous les clients
                </p>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </div>
          <div class="form-group">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.display.frequency"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="ml-1">Fréquence</span>
            <el-popover trigger="hover" placement="top" :width="510">
              <div class="p-1 text-break">
                <p>
                  La fréquence correspond à l'interval de temps entre 2 séances en moyenne.
                  (Ce champ n'est pas définit par la période)
                </p>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </div>
          <div class="form-group">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.display.rank"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="ml-1">Note</span>
            <el-popover trigger="hover" placement="top" :width="500">
              <div class="p-1 text-break">
                <p>
                  La note correspond à la moyenne de toute les notes reçu par le
                  coach. (Ce champ n'est pas définit par la période)
                </p>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </div>
          <div class="form-group">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="filters.display.validate"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="ml-1">Validée(s)</span>
            <el-popover trigger="hover" placement="top" :width="510">
              <div class="p-1 text-break">
                <p>
                  Décompte du nombre de séance(s) marquée(s) comme `Validée(s)`
                  par le coach sur l'application
                </p>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
export default {
  components: {
    Modal
  },

  props: {
    default: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      filters: [],
      modalSettings: {
        display: false
      }
    };
  },

  async created() {
    this.filters = this.default;
  },

  methods: {
    applyFilters() {
      this.$emit("update", { ...this.filters });
    },

    openSettingModal() {
      this.modalSettings.display = true;
    },

    closeSettingModal() {
      this.modalSettings.display = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="card">
    <div class="card-header"><i class="fa fa-list"></i> Abonnements</div>

    <div class="card-block">
      <tabs v-model="activeTab">
        <tab :header="activeHeader">
          <ClientSubscriptionsTable :subscriptions="activeSubs" />
        </tab>
        <tab :header="trialingHeader">
          <ClientSubscriptionsTable :subscriptions="trialingSubs" trialing />
        </tab>
        <tab :header="canceledHeader">
          <ClientSubscriptionsTable :subscriptions="canceledSubs" canceled />
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import ClientSubscriptionsTable from "../components/ClientSubscriptionsTable";

export default {
  components: {
    Tabs,
    Tab,
    ClientSubscriptionsTable,
  },

  props: {
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: 0,
    };
  },

  computed: {
    activeSubs() {
      return this.subscriptions.filter(
        (sub) => sub.status === "active" && !sub.cancel_at_period_end
      );
    },

    trialingSubs() {
      return this.subscriptions.filter(
        (sub) => sub.status === "trialing" && !sub.cancel_at_period_end
      );
    },

    canceledSubs() {
      return this.subscriptions
        .filter((sub) => sub.status === "canceled" || sub.cancel_at_period_end)
        .map((sub) => {
          if (!sub.canceled_at && sub.cancel_at_period_end) {
            sub.canceled_at = sub.current_period_end;
          }

          return sub;
        });
    },

    activeHeader() {
      return `En cours (${this.activeSubs.length})`;
    },

    trialingHeader() {
      return `Essai (${this.trialingSubs.length})`;
    },

    canceledHeader() {
      return `Annulé (${this.canceledSubs.length})`;
    },
  },
};
</script>

<template>
  <div v-if="!loading" class="pt-2 animated fadeIn">
    <div v-if="sponsorshipsStats.length > 0" class="row">
      <div class="col-12 h5 mb-0">Parrainages</div>
      <hr class="w-100 mb-2" />
      <StatsSmallCard
        v-for="stat in sponsorshipsStats"
        :key="stat.label"
        :stat="stat"
        class="col-sm-3 mb-3"
      />
    </div>

    <div v-if="promoStats.length > 0" class="row">
      <div class="col-12 h5 mb-0">Codes Promo</div>
      <hr class="w-100 mb-2" />
      <StatsSmallCard
        v-for="stat in promoStats"
        :key="stat.label"
        :stat="stat"
        class="col-sm-3 mb-3"
      />
    </div>

    <div v-if="freeAccessStats.length > 0" class="row">
      <div class="col-12 h5 mb-0">Accès offerts</div>
      <hr class="w-100 mb-2" />
      <StatsSmallCard
        v-for="stat in freeAccessStats"
        :key="stat.label"
        :stat="stat"
        class="col-sm-3 mb-3"
      />
    </div>
  </div>
</template>

<script>
import StatsSmallCard from "../components/StatsSmallCard";

export default {
  components: {
    StatsSmallCard,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      icons: {
        sponsorship: "fa-group",
        promo: "fa-tag",
        freeAccess: "fa-gift",
      },
    };
  },

  computed: {
    sponsorshipsStats() {
      return this.data
        ? this.data
            .filter(({ type }) => type === "sponsorship")
            .map((stat) => ({ ...stat, icon: this.icons[stat.type] }))
        : [];
    },

    promoStats() {
      return this.data
        ? this.data
            .filter(({ type }) => type === "promo")
            .map((stat) => ({ ...stat, icon: this.icons[stat.type] }))
        : [];
    },

    freeAccessStats() {
      return this.data
        ? this.data
            .filter(({ type }) => type === "freeAccess")
            .map((stat) => ({ ...stat, icon: this.icons[stat.type] }))
        : [];
    },
  },
};
</script>

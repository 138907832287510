<template>
  <div class="wrapper">
    <router-view></router-view>
    <loader :isVisible="isLoading" />

    <div class="animated fadeIn">
      <ClientsProspectsFilters
        type="client"
        :form="filter"
        :defaultCommercials="defaultCommercials"
        :commercials="commercials"
        :concepts="concepts"
        @applyFilters="applyFilters"
        @addNew="showNewClientModal = true"
      />

      <div class="card" v-if="['clients'].indexOf($route.name) > -1">
        <div class="card-block" v-if="clients.all">
          <tabs v-model="activeTab" @input="handleNewActiveTab">
            <tab :header="allHeader">
              <clients
                :clients="clients.all"
                :rows="limit"
                :totalClients="totals.all"
                :page="page"
                :sorting="{
                  orderBy: filter.orderBy,
                  orderByReverse: filter.orderByReverse,
                }"
                @archiveClient="archiveClientModalShow"
                @showComment="displayComment"
                @changePage="changePage"
                @sortClients="sortClients"
              />
            </tab>
            <tab :header="activeHeader">
              <clients
                :clients="clients.active"
                :rows="limit"
                :totalClients="totals.active"
                :page="page"
                :sorting="{
                  orderBy: filter.orderBy,
                  orderByReverse: filter.orderByReverse,
                }"
                @archiveClient="archiveClientModalShow"
                @showComment="displayComment"
                @changePage="changePage"
                @sortClients="sortClients"
              />
            </tab>
            <tab :header="inactiveHeader">
              <clients
                :clients="clients.inactive"
                :rows="limit"
                :totalClients="totals.inactive"
                :page="page"
                :sorting="{
                  orderBy: filter.orderBy,
                  orderByReverse: filter.orderByReverse,
                }"
                @archiveClient="archiveClientModalShow"
                @showComment="displayComment"
                @changePage="changePage"
                @sortClients="sortClients"
              />
            </tab>
            <tab :header="lastInactiveHeader">
              <clients
                :clients="clients.lastInactive"
                :rows="limit"
                :totalClients="totals.lastInactive"
                :page="page"
                :sorting="{
                  orderBy: filter.orderBy,
                  orderByReverse: filter.orderByReverse,
                }"
                @archiveClient="archiveClientModalShow"
                @showComment="displayComment"
                @changePage="changePage"
                @sortClients="sortClients"
              />
            </tab>
            <tab :header="suspendedHeader">
              <clients
                :clients="clients.suspended"
                :rows="limit"
                :totalClients="totals.suspended"
                :page="page"
                :sorting="{
                  orderBy: filter.orderBy,
                  orderByReverse: filter.orderByReverse,
                }"
                @archiveClient="archiveClientModalShow"
                @showComment="displayComment"
                @changePage="changePage"
                @sortClients="sortClients"
              />
            </tab>
            <tab :header="lostHeader">
              <clients
                :clients="clients.lost"
                :rows="limit"
                :totalClients="totals.lost"
                :page="page"
                :sorting="{
                  orderBy: filter.orderBy,
                  orderByReverse: filter.orderByReverse,
                }"
                @archiveClient="archiveClientModalShow"
                @showComment="displayComment"
                @changePage="changePage"
                @sortClients="sortClients"
              />
            </tab>
          </tabs>
        </div>
      </div>
    </div>

    <modal
      title="Archiver un client"
      v-model="archiveClientModal.display"
      cancelText="Fermer"
      okText="Archiver"
      @ok="archiveClient()"
      effect="fade/zoom"
    >
      <div v-if="archiveClientModal.client">
        <p>
          Voulez-vous vraiment archiver le client
          <strong
            >{{ archiveClientModal.client.firstName }}
            {{ archiveClientModal.client.lastName }}
          </strong>
          ?
        </p>
      </div>
    </modal>

    <NewClientModal
      :show="showNewClientModal"
      :default="form"
      :commercials="commercials"
      :concepts="concepts"
      @close="showNewClientModal = false"
    />

    <ClientNoteModal
      :show="modalUpdateNote.show"
      :client="modalUpdateNote.client"
      @close="modalUpdateNote.show = false"
    />
  </div>
</template>

<script>
import Loader from "../components/Loader";
import Clients from "../components/Clients";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import ClientsProspectsFilters from "../components/ClientsProspectsFilters";
import Modal from "vue-strap/src/Modal";
import NewClientModal from "../components/NewClientModal";
import ClientNoteModal from "../components/ClientNoteModal";

import moment from "moment-timezone";

export default {
  components: {
    Clients,
    Loader,
    Tabs,
    Tab,
    ClientsProspectsFilters,
    Modal,
    NewClientModal,
    ClientNoteModal,
  },

  data() {
    return {
      activeTab: 0,
      clients: {
        all: [],
        active: [],
        inactive: [],
        lastInactive: [],
        suspended: [],
        lost: [],
      },
      totals: {
        all: 0,
        active: 0,
        inactive: 0,
        lastInactive: 0,
        suspended: 0,
        lost: 0,
      },
      newClient: false,
      form: {
        email: null,
        password: null,
        commercial: null,
        status: "active",
      },
      page: 1,
      offset: 0,
      limit: 15,
      countClients: true,
      getTotal: true,
      isLoading: false,
      filter: {
        begin: undefined,
        end: undefined,
        archives: "hidden",
        name: undefined,
        concepts: [],
        commercials: [],
        orderBy: "createdAt",
        orderByReverse: false,
        aiStatus: "all",
        isB2B: false,
      },
      defaultCommercials: [],
      commercials: [],
      concepts: [],
      archiveClientModal: {
        display: false,
        client: null,
      },
      showNewClientModal: false,
      modalUpdateNote: {
        show: false,
        client: null,
      },
    };
  },

  computed: {
    clientStatus() {
      switch (this.activeTab) {
        case 0:
          return "all";
        case 1:
          return "active";
        case 2:
          return "inactive";
        case 3:
          return "lastInactive";
        case 4:
          return "suspended";
        case 5:
          return "lost";
        default:
          return null;
      }
    },

    allHeader() {
      return `<i class="icon-user"></i> Tous ${
        this.totals.all !== 0 ? "(" + this.totals.all + ")" : ""
      }`;
    },

    activeHeader() {
      return `<i class="icon-user"></i> Actif ${
        this.totals.active !== 0 ? "(" + this.totals.active + ")" : ""
      }`;
    },

    lastInactiveHeader() {
      return `<i class="icon-user"></i> Inactif -30J ${
        this.totals.lastInactive !== 0
          ? "(" + this.totals.lastInactive + ")"
          : ""
      }`;
    },

    inactiveHeader() {
      return `<i class="icon-user"></i> Inactif ${
        this.totals.inactive !== 0 ? "(" + this.totals.inactive + ")" : ""
      }`;
    },

    suspendedHeader() {
      return `<i class="icon-user"></i> Bloqué ${
        this.totals.suspended !== 0 ? "(" + this.totals.suspended + ")" : ""
      }`;
    },

    lostHeader() {
      return `<i class="icon-user"></i> Perdu ${
        this.totals.lost !== 0 ? "(" + this.totals.lost + ")" : ""
      }`;
    },
  },

  async created() {
    await this.getCommercials();
    await this.getConcepts();

    let activeTab =
      this.$route.query.tab ?? sessionStorage.getItem("clientsTab");

    if (activeTab && this.activeTab !== parseInt(activeTab)) {
      this.activeTab = parseInt(activeTab);
    }

    if (this.$route.query.page !== undefined) {
      this.page = parseInt(this.$route.query.page);
      this.offset = this.limit * this.page - this.limit;

      this.totals = Object.fromEntries(
        this.$route.query.totals.map(([key, value]) => [key, parseInt(value)])
      );
      this.countClients = !!this.$route.query.count;
      this.getTotal = !!this.$route.query.getTotal;

      let commercials = [];
      let concepts = [];

      if (this.$route.query.commercials) {
        commercials = Array.isArray(this.$route.query.commercials)
          ? this.$route.query.commercials
          : [this.$route.query.commercials];
      }

      if (this.$route.query.concepts) {
        concepts = Array.isArray(this.$route.query.concepts)
          ? this.$route.query.concepts
          : [this.$route.query.concepts];
      }

      this.filter = {
        archives: this.$route.query.archives || "hidden",
        concepts: concepts
          .map((c) => this.concepts.find((concept) => concept.name === c))
          .filter((c) => c),
        commercials: commercials
          .map((c) =>
            this.commercials.find((commercial) => commercial.value === c)
          )
          .filter((c) => c),
        orderBy: this.$route.query.orderBy || "createdAt",
        orderByReverse: this.$route.query.orderByReverse || false,
        name: this.$route.query.name || "",
        aiStatus: this.$route.query.aiStatus || "all",
        isB2B: this.$route.query.isB2B || false,
        begin: this.$route.query.begin
          ? moment(this.$route.query.begin).toDate()
          : undefined,
        end: this.$route.query.end
          ? moment(this.$route.query.end).toDate()
          : undefined,
      };

      this.getClients(this.offset);
    } else {
      let currentCommercial = this.commercials.filter(
        (c) => this.$store.state.user.email === c.value
      );

      if (currentCommercial.length === 0) {
        currentCommercial = this.commercials.filter(
          (c) => c.data && c.data.commercial && !c.data.commercial.hidden
        );
      }

      if (currentCommercial.length > 0) {
        this.filter.commercials = currentCommercial;
        this.form.commercial = currentCommercial[0];
        this.defaultCommercials = currentCommercial;
      }
    }
  },

  methods: {
    async getClients(offset) {
      this.isLoading = true;

      let filter = {
        ...this.filter,
        commercials: this.filter.commercials.map(({ value }) => value),
        concepts: this.filter.concepts.map((c) => c.name),
      };

      if (this.$route.query.coachId) {
        filter.coachId = this.$route.query.coachId;
      } else if (this.$route.query.coachEmpty) {
        filter.coachEmpty = this.$route.query.coachEmpty;
      }

      try {
        const { data } = await this.$api.get("/clients/info", {
          params: {
            filter: {
              ...filter,
              status: this.clientStatus,
            },
            offset,
            limit: this.limit,
            count: this.countClients,
            currentTotals: this.totals,
            getTotal: this.getTotal,
          },
        });

        this.totals = data.totals;

        switch (this.clientStatus) {
          case "all":
            this.clients.all = data.data;
            break;
          case "active":
            this.clients.active = data.data;
            break;
          case "inactive":
            this.clients.inactive = data.data;
            break;
          case "lastInactive":
            this.clients.lastInactive = data.data;
            break;
          case "suspended":
            this.clients.suspended = data.data;
            break;
          case "lost":
            this.clients.lost = data.data;
            break;
        }

        this.countClients = false;
        this.getTotal = false;

        this.isLoading = false;
      } catch (e) {
        this.$router.push({
          name: "dashboard",
        });
      }
    },

    async applyFilters(form) {
      this.filter = {
        ...this.filter,
        ...form,
      };
      this.countClients = true;

      await this.changePage(1);
    },

    async getCommercials() {
      try {
        this.isLoading = true;

        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((c) => ({
          value: c.email,
          label: c.commercial.pseudo,
          color: c.commercial.color,
          data: c,
        }));
      } catch (e) {
        this.commercials = [];
      } finally {
        this.isLoading = false;
      }
    },

    async getConcepts() {
      try {
        this.isLoading = true;

        const { data } = await this.$api.get(`/concepts`);

        this.concepts = data.map(({ name }) => ({ name }));
      } catch (e) {
        this.concepts = [];
      } finally {
        this.isLoading = false;
      }
    },

    handleNewClient() {
      this.$emit("handleClient", this.form);
    },

    archiveClientModalShow(client) {
      this.archiveClientModal.display = true;
      this.archiveClientModal.client = client;
    },

    async archiveClient() {
      await this.$api.put("/clients", {
        email: this.archiveClientModal.client.email,
        archived: true,
      });

      this.archiveClientModal.client.archived = true;
      this.archiveClientModal.display = false;
      this.countClients = true;
    },

    displayComment(client) {
      this.modalUpdateNote = {
        ...this.modalUpdateNote,
        client,
        show: true,
      };
    },

    async changePage(page) {
      this.$router.push({
        name: "clients",
        query: {
          page,
          count: this.countClients,
          tab: this.activeTab,
          ...this.filter,
          begin: this.filter.begin
            ? moment(this.filter.begin).format("YYYY-MM-DD")
            : undefined,
          end: this.filter.end
            ? moment(this.filter.end).format("YYYY-MM-DD")
            : undefined,
          orderBy: this.filter.orderBy,
          orderByReverse: this.filter.orderByReverse,
          concepts: this.filter.concepts.map((c) => c.name),
          commercials: this.filter.commercials.map((c) => c.value),
          totals: Object.entries(this.totals),
          getTotal: this.getTotal,
        },
      });
    },

    async handleNewActiveTab(index) {
      this.getTotal = true;

      sessionStorage.setItem("clientsTab", index);
    },

    sortClients(value) {
      if (this.filter.orderBy === value) {
        this.filter.orderByReverse = !this.filter.orderByReverse;
      }

      this.filter.orderBy = value;
      this.countClients = true;

      this.changePage(1);
    },
  },
};
</script>
<style type="text/css">
.card-header .float-right {
  margin-left: 10px;
}
.switch.float-right {
  margin-left: 0;
  margin-right: 25px;
}
</style>

<template>
  <footer class="app-footer">
    Just Coaching &copy; 2017 -
    {{ new Date().getFullYear() }}
    : v.1.19.0 - Juin 2024
  </footer>
</template>

<script>
export default {};
</script>

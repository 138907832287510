<template>
  <div class="card-block custom-table">
    <el-table :data="sessions" style="width: 100%">
      <el-table-column label="Nom">
        <template
          v-if="scope.row.sessionLive && scope.row.sessionLive.live"
          slot-scope="scope"
        >
          {{ scope.row.sessionLive.live.name }}
        </template>
      </el-table-column>
      <el-table-column label="Coachs">
        <template v-if="scope.row.sessionLive" slot-scope="scope">
          <div
            v-for="(coach, index) of showCoachs(scope.row.sessionLive)"
            :key="index"
          >
            {{ coach }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Niveau">
        <template
          v-if="scope.row.sessionLive && scope.row.sessionLive.live"
          slot-scope="scope"
        >
          {{ lvlText(scope.row.sessionLive.live.lvl) }}
        </template>
      </el-table-column>
      <el-table-column label="Date du cours">
        <template
          v-if="scope.row.sessionLive && scope.row.sessionLive.session"
          slot-scope="scope"
        >
          {{ formatDateTime(scope.row.sessionLive.session.scheduledAt) }}
        </template>
      </el-table-column>
      <el-table-column v-if="vonage" label="Lien vers l'enregistrement Vonage">
        <template slot-scope="scope">
          <video :src="scope.row.url" controls width="200px"></video>
        </template>
      </el-table-column>
      <el-table-column v-else label="Vimeo">
        <template slot-scope="scope">
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              :src="`${scope.row.vimeo.url}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            ></iframe>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        v-if="vonage"
        label="Envoyer vers vimeo ?"
      >
        <template slot-scope="scope">
          <div :key="vimeoStatusRender">
            <span v-if="scope.row.loading">
              Envoi en cours
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </span>
            <template v-else>
              <button
                v-if="scope.row.loaded && !scope.row.loading"
                class="btn btn-outline-primary"
                disabled
              >
                Envoyé
                <i class="fa fa-check" aria-hidden="true"></i>
              </button>

              <button
                v-else-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
                class="btn btn-primary"
                @click="sendToVimeo(scope.row)"
              >
                Envoyer vers vimeo
              </button>
            </template>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-else align="center" label="Publier">
        <template slot-scope="scope">
          <label
            class="switch switch-sm switch-text switch-info mb-0"
            v-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="scope.row.published"
              @click="publishReplay(scope.row)"
            />
            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>
          <span v-else>{{ scope.row.published ? "Oui" : "Non" }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="Supprimer">
        <template slot-scope="scope">
          <div :key="deleteStatusRender">
            <span v-if="scope.row.suppressing">
              Suppression en cours
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </span>
            <template v-else>
              <button
                v-if="scope.row.suppressed && !scope.row.suppressing"
                class="btn btn-outline-danger"
                disabled
              >
                Supprimé
              </button>

              <button
                v-else-if="hasPermission($store.state.user, 'LIVE_MENU_WRITE')"
                class="btn btn-danger"
                @click="deleteReplay(scope.row)"
              >
                Supprimer
              </button>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vimeoStatusRender: false,
      deleteStatusRender: false,
    };
  },
  props: {
    sessions: {
      type: Array,
      required: true,
    },
    vonage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },

    async publishReplay(replay) {
      await this.$api.put(`/replays/publish/${replay.id}`);
    },

    canBeDeleted(session) {
      return !!session.url;
    },

    sendToVimeo(replay) {
      this.$confirm({
        message: "Êtes vous sûr de vouloir envoyer la video sur Vimeo ?",
        onConfirm: async () => {
          replay.loading = true;

          this.toggleVimeoStatusRender();

          await this.$api.post(`/replays/to-vimeo/${replay.id}`);

          replay.loading = false;
          replay.loaded = true;

          this.toggleVimeoStatusRender();
          this.$emit("sentToVimeo");
        },
      });
    },

    deleteReplay(replay) {
      this.$confirm({
        message: "Êtes-vous sûr.e de vouloir supprimer cette vidéo ?",
        onConfirm: async () => {
          try {
            replay.suppressing = true;

            this.toggleDeleteStatusRender();

            await this.$api.delete(`/replays/${replay.id}`);

            replay.suppressed = true;

            this.$emit("replayDeleted");
          } catch (e) {
            replay.suppressed = false;
          } finally {
            replay.suppressing = false;

            this.toggleDeleteStatusRender();
          }
        },
      });
    },

    showCoachs(sessionLive) {
      const coachs = [];

      sessionLive.session?.coachs?.forEach((coach) => {
        coachs.push(coach.coach.name);
      });

      return coachs;
    },

    lvlText(lvl) {
      switch (lvl) {
        case 0:
          return "Débutant";

        case 1:
          return "Intermédiaire";

        case 2:
          return "Confirmé";

        default:
          return "Tous niveaux";
      }
    },

    toggleVimeoStatusRender() {
      this.vimeoStatusRender = !this.vimeoStatusRender;
    },

    toggleDeleteStatusRender() {
      this.deleteStatusRender = !this.deleteStatusRender;
    },
  },
};
</script>

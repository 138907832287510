<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Date de début</label>
          <datepicker
            v-model="dates.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="updateDate()"
          ></datepicker>
        </div>
        <div class="form-group col-sm-6">
          <label>Date de fin</label>
          <datepicker
            v-model="dates.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="updateDate()"
          ></datepicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker
  },

  props: {
    defaultDates: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      dates: {}
    };
  },

  async created() {
    this.dates = this.defaultDates;
  },

  methods: {
    updateDate() {
      this.$emit("updateDate", this.dates);
    }
  }
};
</script>

<template>
  <div class="animated fadeIn">
    <loader :isVisible="isLoading" />
    <div class="row">
      <div class="col-md-3">
        <el-switch
          v-model="advanced"
          active-text="Avancé"
          inactive-text="Minimal"
          size="mini"
        />
      </div>
    </div>
    <div class="row mt-1">
      <div class="form-group col-sm-4">
        <label>Date de début</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.dataBegin, placement: 'right' }"
        ></i>
        <datepicker
          v-model="filters.dateBegin"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group col-sm-4">
        <label>Date de fin</label>
        <datepicker
          v-model="filters.dateEnd"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Concept</label>
        <multiselect
          v-model="filters.concepts"
          :options="conceptOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Status</label>
        <multiselect
          v-model="filters.status"
          :options="statusOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Prestations</label>
        <multiselect
          v-model="filters.prestations"
          :options="prestationsOptions"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Tags</label>
        <multiselect
          v-model="filters.tags"
          :options="tagsOptions"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Inactif</label>
        <multiselect
          v-model="filters.inactiveSince"
          :options="inactiveSinceOptions"
          label="label"
          track-by="label"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Département</label>
        <multiselect
          v-model="filters.departments"
          :options="departmentsOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Sexe</label>
        <multiselect
          v-model="filters.genders"
          :options="gendersOptions"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Type de prestataire</label>
        <multiselect
          v-model="filters.types"
          :options="typesOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Application mobile téléchargé</label>
        <multiselect
          v-model="filters.applicationDownloaded"
          :options="applicationDownloadedOptions"
          label="label"
          track-by="label"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Offre SMS / Mail</label>
        <multiselect
          v-model="filters.newsletter"
          :options="newsletterOptions"
          label="label"
          track-by="label"
        >
        </multiselect>
      </div>
      <div class="col-sm-3" v-if="advanced">
        <label>Fréquence</label>
        <div class="row">
          <div class="form-group col-sm-12">
            <el-input-number
              placeholder="min"
              v-model="filters.frequencyMin"
              size="small"
              controls-position="right"
            ></el-input-number>
            <el-input-number
              placeholder="max"
              v-model="filters.frequencyMax"
              size="small"
              controls-position="right"
            ></el-input-number>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-4 d-flex flex-column">
        <label class="mb-1"
          >Archives
          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{ content: helpers.archive, placement: 'right' }"
          ></i>
        </label>
        <el-radio-group v-model="filters.archives">
          <el-radio :label="'hidden'">Sans</el-radio>
          <el-radio :label="'displayed'">Avec</el-radio>
          <el-radio :label="'archivesOnly'">Archives uniquement</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-sm btn-primary float-right mx-1"
          @click="download()"
        >
          <i class="icon-cloud-download"></i>&nbsp; Télécharger
        </button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-sm-12">
        <h5>En-tête du fichier Excel</h5>
      </div>
      <div class="col-sm-12">
        <ul>
          <li>Nom</li>
          <li>Prénom</li>
          <li>Email</li>
          <li>Adresse</li>
          <li>Code postal</li>
          <li>Ville</li>
          <li>Téléphone</li>
          <li>Créé le</li>
          <li>Sexe</li>
          <li>Statut</li>
          <li class="text-primary" v-if="advanced">Fréquence</li>
          <li>Intervenant</li>
          <li>Formateur</li>
          <li>Influenceur</li>
          <li>Offre SMS/email</li>
          <li>Prestations</li>
          <li>Tags</li>
          <li>Application téléchargée</li>
          <li class="text-primary" v-if="advanced">Nombre de jours inactifs</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";
import Multiselect from "vue-multiselect";
import Loader from "../components/Loader";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Datepicker,
    Multiselect,
    Loader,
  },
  mixins: [DownloadFile],
  data() {
    return {
      isLoading: false,
      filters: {
        dateBegin: moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DD"),
        concepts: [],
        departments: [],
        genders: [],
        applicationDownloaded: [],
        status: [],
        prestations: [],
        tags: [],
        types: [],
        frequencyMin: undefined,
        frequencyMax: undefined,
        inactiveSince: [],
        newsletter: [],
        archives: "hidden",
      },
      conceptOptions: [],
      prestationsOptions: [],
      tagsOptions: [],
      statusOptions: [
        { label: "Actif", value: "active" },
        { label: "Inactif", value: "inactive" },
        { label: "Bloqué", value: "suspended" },
        { label: "Recrutement", value: "recruiting" },
        { label: "Recruté", value: "recruited" },
        { label: "Contacté", value: "contact" },
      ],
      departmentsOptions: [],
      gendersOptions: ["Homme", "Femme"],
      applicationDownloadedOptions: [
        { label: "Oui", value: 1 },
        { label: "Non", value: 0 },
      ],
      newsletterOptions: [
        { label: "Oui", value: 1 },
        { label: "Non", value: 0 },
      ],
      inactiveSinceOptions: [
        { label: "Tous les inactifs", value: "all" },
        { label: "Moins d'1 mois", value: "less_then_one_month" },
        { label: "1 mois et plus", value: "one_month_and_more" },
        { label: "2 mois et plus", value: "two_month" },
        { label: "3 mois et plus", value: "three_month" },
        { label: "4 mois et plus", value: "four_month" },
        { label: "5 mois et plus", value: "five_month" },
        { label: "6 mois et plus", value: "six_month" },
      ],
      typesOptions: [
        { label: "Intervenant", value: "intervenant" },
        { label: "Formateur", value: "formateur" },
        { label: "Influenceur", value: "influenceur" },
      ],
      helpers: {
        dataBegin: "Date de creation de l'intervenant.",
        archive: "Intervenant.es archivé.es pour inactivité.",
      },
      advanced: false,
    };
  },

  async created() {
    await this.getConcepts();
    await this.getDepartment();
    await this.getSettings();
  },

  computed: {
    sanitarizeMinimalFilters() {
      return {
        dateBegin: this.filters.dateBegin,
        dateEnd: this.filters.dateEnd,
        status: this.filters.status.map((s) => s.value),
        genders: this.filters.genders,
        applicationDownloaded:
          this.filters.applicationDownloaded &&
          this.filters.applicationDownloaded.value,
        prestations: this.filters.prestations,
        tags: this.filters.tags,
        newsletter: this.filters.newsletter?.value,
        archives: this.filters.archives,
        filter: this.advanced ? 1 : 0,
      };
    },

    sanitarizeFilters() {
      return {
        ...this.sanitarizeMinimalFilters,
        concepts: this.filters.concepts.map((c) => c.value),
        departments: this.filters.departments.map((d) => d.value),
        types: this.filters.types.map((d) => d.value),
        frequencyMin:
          this.filters.frequencyMin !== 0 ? this.filters.frequencyMin : null,
        frequencyMax:
          this.filters.frequencyMax !== 0 ? this.filters.frequencyMax : null,
        inactiveSince:
          this.filters.inactiveSince && this.filters.inactiveSince.value,
      };
    },
  },

  methods: {
    async download() {
      this.isLoading = true;

      const params = this.advanced
        ? this.sanitarizeFilters
        : this.sanitarizeMinimalFilters;

      const { data } = await this.$api.get("/exports/prestataires", {
        params,
      });

      this.downloadXLSX(data, "prestataires.xlsx");
      this.isLoading = false;
    },
    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.conceptOptions = data.map((c) => {
        return { label: c.name, value: c.name };
      });
    },

    async getSettings() {
      const { data } = await this.$api.get("/settings");

      this.prestationsOptions = data["intervenants_prestations"];
      this.tagsOptions = data["intervenants_tags"];
    },

    async getDepartment() {
      const { data } = await this.$api.get("/users/departments");

      this.departmentsOptions = data.map((z) => {
        return { label: z, value: z };
      });
    },
  },
};
</script>

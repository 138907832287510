import { render, staticRenderFns } from "./TeamMemberPicture.vue?vue&type=template&id=677f1dd9&scoped=true"
import script from "./TeamMemberPicture.vue?vue&type=script&lang=js"
export * from "./TeamMemberPicture.vue?vue&type=script&lang=js"
import style0 from "./TeamMemberPicture.vue?vue&type=style&index=0&id=677f1dd9&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677f1dd9",
  null
  
)

export default component.exports
<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Date de début</label>
          <datepicker
            v-model="filters.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Date de fin</label>
          <datepicker
            v-model="filters.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label class="control-label">Société</label>
          <v-select
            v-model="filters.merchant"
            :options="merchantsOptions"
            @input="updateConcepts"
          ></v-select>
        </div>
        <div class="form-group col-sm-4">
          <label>Concept</label>
          <v-select v-model="filters.concept" :options="concepts">
            <span slot="no-options">Sélectionnez une option pour Société.</span>
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: {},
      merchantsOptions: [],
      concepts: [],
    };
  },

  async created() {
    this.filters = this.default;
    await this.fetchMerchants();
  },

  methods: {
    applyFilters() {
      this.$emit("update", this.filters);
    },

    async fetchMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantsOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },

    async updateConcepts(merchant) {
      this.concepts = [];
      this.filters.concept = null;

      if (merchant && merchant.value) {
        const params = { merchantId: merchant.value };
        const { data } = await this.$api.get("/concepts", { params });

        this.concepts = data.map((concept) => {
          return {
            value: concept.id,
            label: concept.name,
          };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

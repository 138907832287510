<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Date de début</label>
          <datepicker
            v-model="form.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Date de fin</label>
          <datepicker
            v-model="form.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Commandes crées depuis le</label>
          <datepicker
            v-model="form.orderCreatedAfter"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4" v-if="merchantsOptions">
          <label>Filtrer par société</label>
          <v-select
            :options="merchantsOptions"
            v-model="form.merchant"
          ></v-select>
        </div>

        <div class="form-group col-sm-4" v-if="concepts">
          <label>Filtrer par concept</label>
          <v-select :options="concepts" v-model="form.concept"></v-select>
        </div>

        <div class="form-group col-sm-4">
          <label>Commercial</label>
          <multiselect
            v-model="form.commercials"
            :options="commercials"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="loadPayments()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  components: {
    vSelect,
    Datepicker,
    Multiselect,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    commercials: {
      type: Array,
      required: true,
    },
    concepts: {
      type: Array,
      required: true,
    },
    merchantsOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      form: {},
    };
  },

  async mounted() {
    this.form = this.filters;
  },

  methods: {
    loadPayments() {
      this.$emit("load", this.form);
    },
  },
};
</script>

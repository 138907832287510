<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Date de création</label>
          <datepicker
            v-model="filters.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>et</label>
          <datepicker
            v-model="filters.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="col-md-2 d-flex flex-column">
          <label for="discovery-filter">Commandes découvertes</label>
          <el-switch
            id="discovery-filter"
            v-model="filters.discovery"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
          />
        </div>
        <div class="col-md-2 d-flex flex-column">
          <label for="discovery-filter">
            Établissement marchés
            <el-popover trigger="hover" placement="top" :width="400">
              <div class="p-1 text-justify">
                <p>
                  Inclure les commandes des clients dont le champ "Établissement
                  marchés" est à oui.
                </p>
              </div>
              <span slot="reference">
                <i class="fa fa-info-circle ml-05"></i>
              </span>
            </el-popover>
          </label>
          <el-switch
            id="establishment-market"
            v-model="filters.marketEstablishment"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
          />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-sm-4">
          <button type="button" class="btn btn-sm btn-secondary" @click="apply">
            <i class="icon-eye"></i>&nbsp; Visualiser
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: {
        discovery: false,
        marketEstablishment: false,
      },
    };
  },

  async created() {
    this.filters = this.default;
  },

  methods: {
    apply() {
      this.$emit("updateFilter", this.filters);
    },
  },
};
</script>

<style lang="scss">
.custom-multiselect {
  &.custom-no-wrap {
    .multiselect__tags {
      max-height: 68px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .multiselect__tag {
      white-space: normal;
    }
  }
}
</style>

<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card-group mb-0" v-show="!loading">
            <div class="card card-inverse py-3 hidden-md-down" v-if="order">
              <div class="card-block text-center">
                <img
                  class="logo img-fluid"
                  style="max-width: 50% !important"
                  :src="order.concept.logo"
                />
                <div
                  style="
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    color: black;
                  "
                >
                  <h6>{{ order.merchant.name }}</h6>
                  <p>
                    <small>
                      Capital social {{ order.merchant.capital }}
                      <br />
                      Siret : {{ order.merchant.siret }}
                      <br />
                      {{ formatAddress(order.merchant) }}
                      <br />
                      <span v-if="order.merchant.sap"
                        >N° agrément service à la personne : SAP
                        {{ order.merchant.sap.number }} délivré le
                        {{
                          new Date(
                            order.merchant.sap.deliveredAt
                          ).toLocaleDateString()
                        }}</span
                      >
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <form class="card p-2" @submit="submitForm">
              <div class="card-block">
                <div v-if="order">
                  <h6>
                    <span v-if="success">Montant réglé : </span>
                    <span v-else>Montant à payer : </span>
                    <strong>{{ formatPrice(order.order.price) }}</strong>
                  </h6>

                  <p v-if="order.order.description">
                    {{ order.order.description }}
                  </p>
                  <p class="text-muted">
                    Client : {{ order.client.name }}
                    <br />
                    E-mail : {{ order.client.email }}
                    <br />
                    Adresse : {{ formatAddress(order.client) }}
                    <br />
                    Pack : {{ formatPack(order.meta.nb) }}
                  </p>
                </div>
                <div v-show="success">
                  <p class="text-success">
                    Paiement effectué avec succès !
                    <br />Vous allez recevoir votre facture par e-mail.
                  </p>
                </div>
                <div v-show="!success">
                  <div class="row">
                    <div class="col-sm-12" v-if="order">
                      <div class="form-group">
                        <label for="cardholderName"
                          >Titulaire de la carte</label
                        >
                        <input
                          type="text"
                          ref="cardholderName"
                          class="form-control"
                          style="font-size: 12px"
                          v-model="clientName"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="cardNumber">Numéro de carte</label>
                        <div ref="cardNumber" class="form-control"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="cardExpiry">Date d'expiration</label>
                        <div ref="cardExpiry" class="form-control"></div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="cardCvc">CVC</label>
                        <div ref="cardCvc" class="form-control"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-0 text-center">
                    <div class="col-sm-12" v-if="order">
                      <div class="checkbox">
                        <input type="checkbox" v-model="cgv" id="checkbox_1" />
                        <label for="checkbox_1">
                          J'accepte les
                          <a :href="order.concept.termsUrl" target="_blank"
                            >Conditions Générales de Vente</a
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-1 text-center">
                    <div class="col-sm-12">
                      <button type="submit" class="btn btn-primary px-2">
                        Payer
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row pt-1 text-center" v-show="error">
                  <div class="col-sm-12">
                    <p class="text-danger">
                      <b>{{ error }}</b>
                    </p>
                    <div v-if="error && !error.includes('CGV')">
                      <p class="text-danger">
                        Votre paiement a été refusé et n’a pas été pris en
                        compte.
                      </p>
                      <p class="text-danger">
                        Merci de réitérer la transaction.
                      </p>
                      <p class="text-danger">
                        Besoin d'assistance ? N'hésitez pas à contacter
                        <span v-if="commercial">votre commercial.</span
                        ><span v-else
                          >Just Coaching par mail à l'adresse
                          contact@justcoaching.fr ou par téléphone au 07 57 93
                          40 48.</span
                        >
                      </p>
                      <CommercialCard
                        v-if="commercial"
                        :email="commercial.email"
                        :name="commercial.name"
                        :phone="commercial.phone"
                        :job="commercial.job"
                        :photo="commercial.photo"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pt-1 text-center">
                  <div class="col-sm-12">
                    <img
                      src="static/img/secure-stripe-payment-logo.png"
                      style="max-width: 100%; height: 50px; margin: auto"
                    />
                    <p class="text-success pt-2 text-left">
                      L'adresse de notre site de paiement préfixée par HTTPS
                      indique que vous êtes sur un site sécurisé et que vous
                      pouvez régler votre achat en toute tranquillité
                    </p>
                    <button
                      type="button"
                      class="btn btn-primary px-2"
                      v-if="order"
                    >
                      <a :href="order.concept.websiteUrl" style="color: white"
                        >Retour à l'accueil</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-group mb-0" v-show="loading">
            <form class="card p-2">
              <div class="card-block text-center">{{ text }}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import CommercialCard from "../components/CommercialCard";

export default {
  components: {
    CommercialCard,
  },

  data() {
    return {
      cgv: false,
      clientName: "",
      orderData: null,
      text: "Chargement du paiement en cours...",
      loading: true,
      success: false,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      stripe: null,
      error: null,
    };
  },
  mounted() {
    this.success = this.$route.params.success;
    this.loading = true;

    this.$api
      .get("/stripe/" + this.$route.params.id)
      .then((res) => {
        this.orderData = res.data;
        this.loading = false;
        let style = {
          base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "12px",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        };
        /* eslint-disable no-undef */
        this.stripe = Stripe(this.order.stripe.publishable_key);
        let elements = this.stripe.elements();
        this.cardNumber = elements.create("cardNumber", { style });
        this.cardNumber.mount(this.$refs.cardNumber);
        this.cardExpiry = elements.create("cardExpiry", { style });
        this.cardExpiry.mount(this.$refs.cardExpiry);
        this.cardCvc = elements.create("cardCvc", { style });
        this.cardCvc.mount(this.$refs.cardCvc);
        this.clientName = this.order.client.name.toUpperCase();
      })
      .catch((res) => {
        this.text = res.toString().includes("400")
          ? "Erreur : Les clés Stripe ne sont pas configurées."
          : "Ce paiement n'existe plus.";
      });
  },
  computed: {
    order() {
      return this.orderData;
    },

    conceptColor() {
      if (this.orderData && this.orderData.concept.color) {
        return (
          "width:44%; " +
          "background-color: " +
          this.orderData.concept.color +
          "; " +
          "border-color: " +
          this.orderData.concept.color
        );
      }

      return "width:44%;";
    },

    commercial() {
      const commercial = this.orderData?.client?.commercials[0]?.commercial;

      if (commercial?.commercial?.mobile) {
        const photo = `${axios.defaults.baseURL}users/${commercial.id}/commercial-picture`;

        return {
          email: commercial.email,
          name: commercial.commercial.pseudo || commercial.firstName,
          phone: commercial.commercial.mobile,
          job: commercial.commercial.job || "Commercial",
          photo,
        };
      }

      return false;
    },
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();

      let allreadyPayed = false;

      try {
        await this.$api.post(`/payments/check-payed/${this.$route.params.id}`);
      } catch (e) {
        if (e.response?.data === "allready payed") {
          allreadyPayed = true;
        }
      }

      if (allreadyPayed) {
        this.error = "Vous avez déjà réglé ce paiement";

        return;
      }

      if (!this.cgv) {
        this.error = "Vous devez accepter les CGV pour payer";

        return;
      }

      this.loading = true;
      this.text = "Paiement en cours...";

      this.stripe
        .confirmCardPayment(this.order.stripe.client_secret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {
              name: this.$refs.cardholderName.value,
            },
          },
          setup_future_usage: "off_session",
        })
        .then((result) => {
          this.loading = false;

          if (result.error) {
            this.error = result.error.message;
            this.$api.post(`/stripe/fail/${this.$route.params.id}`, {
              error: this.error,
            });
          } else {
            this.success = true;
            this.error = null;

            this.$api.post(`/stripe/success/${this.$route.params.id}`, {
              payment_method: result.paymentIntent.payment_method,
              customer_id: this.order.client.stripe.customerId, // legacy security
              client_id: this.order.client.id,
              merchant_id: this.order.meta.merchantId,
              concept: this.order.concept.name,
            });
          }
        });
    },
  },
};
</script>

<template>
  <modal
    :title="groupToEdit ? 'Editer' : 'Créer'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="groupToEdit ? 'Editer' : 'Créer'"
    @cancel="() => closeModal()"
    @ok="handleAddGroup()"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">
        {{ groupToEdit ? "Editer" : "Créer" }} un groupe
      </h4>
    </div>

    <div v-if="formError" class="alert alert-danger">{{ formError }}</div>

    <div class="row">
      <div class="col-sm-4 form-group">
        <label>Nom</label>
        <input type="text" class="form-control" v-model="form.name" />
      </div>
      <div class="col-sm-8 form-group">
        <label>Description</label>
        <input type="textarea" class="form-control" v-model="form.note" />
      </div>
      <div class="col-sm-12 form-group">
        <label>Couleur</label>
        <el-color-picker class="picker" v-model="form.color"></el-color-picker>
      </div>
      <div class="col-sm-12 form-group">
        <label>Logo</label>
        <dropzone
          :url="apiUploads()"
          :headers="apiAuthorization()"
          id="photo"
          v-on:vdropzone-success="logoUploaded"
        >
          <div class="dz-default dz-message">
            <span
              ><i class="icon-cloud-upload"></i><br />Glissez l'image ici</span
            >
          </div>
        </dropzone>
      </div>
      <div class="col-sm-12 form-group">
        <label>Nom de domaines (séparé d'une virgule ",")</label>
        <input
          type="textarea"
          class="form-control"
          v-model="form.domains"
          placeholder="justcoaching.fr, justcorporate.fr"
        />
      </div>
      <div class="col-sm-12 form-group">
        <label
          >Restreint par domaines
          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Seul les utilisateurs avec un email contenant un domaine valide pourront avoir accès au groupe',
            }"
          ></i
        ></label>
        <el-switch
          style="display: block"
          v-model="form.restrictDomains"
          active-text="Oui"
          inactive-text="Non"
          size="mini"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Dropzone from "vue2-dropzone";

import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";

export default {
  components: {
    Modal,
    Loader,
    Dropzone,
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    groupToEdit: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data: () => ({
    form: {
      name: null,
      note: null,
      color: null,
      logoId: null,
      domains: null,
      restrictDomains: false,
    },
    modalOpened: false,
    formError: false,
    loading: false,
  }),

  mounted() {
    this.modalOpened = this.modalShow;
  },

  methods: {
    async handleAddGroup() {
      if (this.loading) {
        return false;
      }

      this.formError = false;
      this.loading = true;

      try {
        this.validateForm();

        if (!this.formError) {
          if (this.form.id) {
            await this.$api.put(`/groups/${this.form.id}`, {
              ...this.form,
            });
          } else {
            await this.$api.post("/groups", {
              ...this.form,
            });
          }

          this.closeModal(true);
        }
      } catch (e) {
        this.formError = `Une erreur s'est produite, veuillez réessayer`;
      }

      this.loading = false;
    },

    closeModal(updated) {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;

      this.$emit("close", updated && this.form);

      this.form = {
        name: null,
        note: null,
        color: null,
        logoId: null,
      };
    },

    validateForm() {
      if (!this.form.name || !this.form.note) {
        this.formError = "Tous les champs sont obligatoires";

        return;
      }
    },

    logoUploaded(f, response) {
      this.form.logoId = response.id;
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },
  },

  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;

        if (this.groupToEdit) {
          this.form = { ...this.groupToEdit };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picker {
  display: block;
}
</style>

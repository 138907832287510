<template>
  <span
    class="badge"
    v-if="statusObj"
    :style="`color: white;background-color:${statusObj.color}`"
  >
    {{ statusObj.label }}
  </span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      coachClientStatus: "getCoachClientStatus",
    }),

    statusObj() {
      const statusObj = this.coachClientStatus.find(
        (s) => s.value === this.status
      );

      if (this.status === "prospect") {
        return {
          label: "Prospect",
          color: "#ec7c31",
        };
      }

      return statusObj;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div
          class="form-group"
          :class="type === 'uncompleted' ? 'col-sm-4' : 'col-sm-6'"
        >
          <label>Date de début</label>
          <datepicker
            v-model="form.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            :disabled="{ from: form.end }"
          ></datepicker>
        </div>
        <div
          class="form-group"
          :class="type === 'uncompleted' ? 'col-sm-4' : 'col-sm-6'"
        >
          <label>Date de fin</label>
          <datepicker
            v-model="form.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            :disabled="{ to: form.begin }"
          ></datepicker>
        </div>
        <div v-if="type === 'uncompleted'" class="form-group col-sm-4">
          <label>Clients</label>
          <v-select
            :options="filterClientsOptions"
            v-model="form.filterClients"
            :reduce="({ value }) => value"
            class="no-search"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
      <button type="button" class="btn btn-sm btn-primary" @click="onExport">
        <i class="icon-cloud-download"></i>&nbsp; Exporter
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    defaultDates: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: "completed",
    },
  },

  data() {
    return {
      form: {
        begin: null,
        end: null,
        filterClients: null,
      },
      filterClientsOptions: [
        {
          label: "Tous",
          value: "all",
        },
        {
          label: "Avec séances non validées seulement",
          value: "withUncompleted",
        },
      ],
    };
  },

  mounted() {
    const { begin, end } = this.defaultDates;

    this.form = {
      ...this.form,
      begin,
      end,
      filterClients: this.type === "uncompleted" && "withUncompleted",
    };
  },

  methods: {
    applyFilters() {
      this.$emit("apply", this.form);
    },

    onExport() {
      this.$emit("onExport", this.form);
    },
  },
};
</script>

<style lang="css">
.v-select.no-search input[type="search"] {
  width: 0 !important;
}
</style>
